module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-hotels">\n	<div class="b-hotels__map-wrapper hotels-map-closed">\n		<div class="b-hotels__map"></div>\n		<div class="b-hotels__map-open hotels-map-open">\n			<span class="b-hotels__map-open-link">' +
((__t = ( L10N.get('hotels.showOnMap') )) == null ? '' : __t) +
'</span>\n		</div>\n	</div>\n	<div class="b-hotels__results--map"></div>\n	<div class="b-hotels__search-progress-bar"></div>\n    <div class="b-hotels__result-counter"></div>\n	<div class="b-hotels__results"></div>\n\n	<div class="b-hotels__search dn">\n		' +
((__t = ( L10N.get('hotels.totalFound') )) == null ? '' : __t) +
': <span class="b-hotels__search-count">-</span>\n		<div class="b-hotels__search-all">' +
((__t = ( L10N.get('hotels.showMore') )) == null ? '' : __t) +
' <span class="b-hotels__search-all-count">-</span></div>\n		<span class="b-hotels__search-close"></span>\n	</div>\n\n	<div class="b-message b-hotels__search-no-results dn">\n		' +
((__t = ( L10N.get('hotels.nothingFound') )) == null ? '' : __t) +
' <span class="action-link b-hotels__search-filters-reset">' +
((__t = ( L10N.get('hotels.dropFilters') )) == null ? '' : __t) +
'</span>.\n	</div>\n</div>\n';

}
return __p
}