module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


const { caption: label } = model.get('notificationType') || {};
;
__p += '\n<div>\n    <span class="js-widget"  onclick=\'return new Widgets.Checkbox({ bindingProperty: "disabled", label: "' + label + '" })\'></span>\n</div>\n';

}
return __p
}