module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-search-route-address__collection-element" data-cid="' +
((__t = ( cid )) == null ? '' : __t) +
'">\n    <div class="b-search-route-address__swap">\n        <div class="b-search-route-address__swap-img"></div>\n    </div>\n    <span class="js-widget"  onclick=\'return new Widgets.GeocoderInput({ bindingProperty: "location", placeholder: L10N.get("searchForm.to"), label: "" })\'></span>\n    <span class="b-search-route-address__remove"></span>\n</div>\n';

}
return __p
}