// eslint-disable-next-line banned-modules
'use strict';

import BaseCollection from '@/classes/base.collection';
import RangeModel from '@/blocks/elements/b-filters/b-filters-transfers/range/model';
import SelectModel from '@/blocks/elements/b-filters/b-filters-transfers/select/model';

export default BaseCollection.extend({

	snapshotName: 'transfers:filter',

	initialize(models, options) {
		this.results = options.results;
		this.currency = options.currencyCode || 'RUB';
		if (_.isEmpty(this.results)) {
			return;
		}

		const travelPolicyComplianceFilter = new SelectModel({
			label: L10N.get('filters.travelPolicy'),
			field: `travelPolicyCompliance`,
		});
		travelPolicyComplianceFilter.formatter = (values) => {
			values.models = _.sortBy(values.models, (el) => {
				let value = 0;
				switch (el.get('uid')) {
					case 'RECOMMENDED':
						value = -1;
						break;
					case 'VIOLATION':
						value = 1;
						break;
					case 'STRICT_VIOLATION':
						value = 2;
						break;
					case 'AUTHORIZATION_REQUIRED':
						value = 3;
						break;
				}
				return value;
			});
			return values;
		};
		this.add(travelPolicyComplianceFilter);

		this.add(new RangeModel({
			label: L10N.get('filters.price'),
			field: 'transfer.totalPrice.amount',
			classes: 'b-filters-transfers__price',
			formatter: (value) => {
				return `${parseInt(value, 10)} ${this.currency}`;
			},
		}, {
			showInputValue: true,
			formatter: (value) => parseInt(value, 10),
		}));

		this.add(new SelectModel({
			label: L10N.get('filters.category'),
			field: 'transfer.carClass',
		}));

		this.collect();
		this.bind();
	},

	bind() {
		_.each(this.models, (model) => {
			model.on('change:value', this.triggerChangeEvent.bind(this));
		});
	},

	collectSnapshot() {
		const snapshot = _.map(this.models, (m) => {
			if (m instanceof SelectModel) {
				return {
					field: m.get('field'),
					values: _.map(m.get('values').models, (v) => {
						return {
							uid: v.get('uid'),
							value: v.get('value'),
						};
					}),
				};
			}

			return {
				field: m.get('field'),
				value: m.get('value'),
			};
		});
		STATE.setFormState(this.snapshotName, snapshot);
	},

	applySnapshot() {
		const snapshot = STATE.getFormState(this.snapshotName);
		_.each(snapshot, ({ field, value, values }) => {
			const model = _.find(this.models, (m) => m.get('field') === field);
			if (!model) {
				return;
			}

			if (model instanceof SelectModel && _.isArray(values)) {
				const valueModels = model.get('values').models;
				_.each(values, (v) => {
					const valueModel = _.find(valueModels, (vm) => vm.get('uid') === v.uid);
					if (!valueModel) {
						return;
					}

					valueModel.set('value', v.value);
				});
			} else if (model instanceof RangeModel && _.isArray(value)) {
				model.set('value', value);
				_.defer(() => model.trigger(`change:value`, model, value));
			} else {
				model.set('value', value);
			}
		});
	},

	filterResults() {
		this.collectSnapshot();

		_.each(this.results, (item) => {
			item.filtered = _.some(this.models, (model) => model.filter(item) !== true);
		});

		this.filteredResult = _.filter(this.results, (item) => item.filtered === true);
		this.visibleResults = _.filter(this.results, (item) => item.filtered !== true);
	},

	triggerChangeEvent() {
		clearTimeout(this.timer);
		STATE.getPageView().getPageModel().set('isFilteringResults', true);
		this.timer = setTimeout(() => {
			this.filterResults();
			this.trigger('filterCollection');
			_.defer(() => {
				if (STATE.getPageView()) STATE.getPageView().getPageModel().set('isFilteringResults', false);
			});
		}, 1000);
	},

	collect() {
		if (this.results && this.models) {
			_.each(this.results, (item) => {
				this.models.forEach(model => model.collect(item));
			});
		}

		this.applySnapshot();
	},

});
