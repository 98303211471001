// eslint-disable-next-line banned-modules
'use strict';

import FilterBaseModel from '@/blocks/elements/b-filters/base.model';
import View from './view';

export default FilterBaseModel.extend({

	defaults() {
		return {
			label: '',
			min: Number.MAX_SAFE_INTEGER,
			max: 0,
			value: [0, 1],
		};
	},

	View,

	initialize() {},

	parse(value) {
		return parseFloat(value);
	},

	filter(model) {
		const field = this.get('field');
		const value = this.parse(model.get(field));
		const range = this.get('value');
		return range[0] <= value && value <= range[1];
	},

	isValid() {
		return this.get('max') !== this.get('min');
	},

	collect(model) {
		const field = this.get('field');
		if (field) {
			const value = this.parse(model.get(field));
			const val = this.get('value');
			if (this.get('min') > value) {
				this.set('min', value);
				val[0] = value;
				this.set('value', val);
			}
			if (this.get('max') < value) {
				this.set('max', value);
				val[1] = value;
				this.set('value', val);
			}
		}
	},

});
