module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<label class="b-checkbox ' +
((__t = (classes )) == null ? '' : __t) +
'">\r\n	<input type="checkbox" class="b-checkbox__input">\r\n	<span class="b-checkbox__view">' +
((__t = (label)) == null ? '' : __t) +
'</span>\r\n</label>\r\n';

}
return __p
}