// eslint-disable-next-line banned-modules
import BaseView from '@/classes/base.view';
import template from './template.ejs';
import GlUl from '@/utils/global-utils';
import './styles.less';

export default BaseView.extend({

	template,

	events: {
		'click .b-fare-rules__rule-booking-open': 'openRuleBooking',
		'click .b-fare-rules__rule-carrier-open': 'openRuleCarrier',
		'click .b-fare-rules__rule-security-open': 'openRuleSecurity',
	},

	openRuleBooking(e) {
		e.preventDefault();
		const {thirdStepSettings = {}} = STATE.getSettings() || {};
		let content = '';
		switch (this.options.travelSubjectUid) {
			case 'TRANSFER':
				content = thirdStepSettings.fullBookingRulesTransfers;
				break;
			case 'RAIL':
				content = thirdStepSettings.fullBookingRulesRail;
				break;
			case 'HOTEL':
				content = thirdStepSettings.fullBookingRulesHotels;
				break;
			case 'AIR':
			default:
				content = thirdStepSettings.fullBookingRules;
				break;
		}
		const popup = new Widgets.Popup({
			content: `<div class="b-fare-rules__rule-booking">${content}</div>`,
			title: L10N.get('bookingForm.bookingRulesPopupTitle'),
			type: 'info',
			actions: [{
				label: L10N.get('bookingForm.bookingRulesPopupAccept'),
				action: () => {
					const input = this.$(e.currentTarget).parents('.b-checkbox').find('input');
					if (!input.is(':checked')) {
						input.trigger('click');
					}
					popup.hide();
				},
			}],
			classes: 'b-fare-rules__popup-rule-booking',
		});
		popup.show();
		return false;
	},

	openRuleSecurity(e) {
		e.preventDefault();
		const popup = new Widgets.Popup({
			content: `<div class="b-fare-rules__rule-booking">${STATE.getSettings().thirdStepSettings.securityRulesInfo}</div>`,
			title: L10N.get('bookingForm.securityRulesPopupTitle'),
			type: 'info',
			actions: [{
				label: L10N.get('bookingForm.securityRulesPopupAccept'),
				action: () => {
					const input = this.$(e.currentTarget).parents('.b-checkbox').find('input');
					if (!input.is(':checked')) {
						input.trigger('click');
					}
					popup.hide();
				},
			}],
			classes: 'b-fare-rules__popup-rule-booking',
		});
		popup.show();
		return false;
	},

	openRuleCarrier(e) {
		e.preventDefault();

		if (this.options.travelSubjectUid === 'RAIL') {
			const content = (_.find(STATE.getSettings().thirdStepSettings.customerRulesRail || [], (el) => {
				const value = el && (el.customer || {uid: ''}).uid.toLowerCase() || '';
				const gdsUid = (this.options.gdsUid || '').toLowerCase();
				return value === gdsUid || value.includes(gdsUid) || gdsUid.includes(value);
			}) || {rulesText: ''}).rulesText;
			const popup = new Widgets.Popup({
				content: `<div class="b-fare-rules__rule-booking"><div style="text-align: initial">${content.replace(/\n/g, '<br />')}</div></div>`,
				title: L10N.get('bookingForm.carrierRulesPopupTitle'),
				type: 'info',
				actions: [{
					label: L10N.get('bookingForm.carrierRulesPopupAccept'),
					action: () => {
						const input = this.$(e.currentTarget).parents('.b-checkbox').find('input');
						if (!input.is(':checked')) {
							input.trigger('click');
						}
						popup.hide();
					},
				}],
				classes: 'b-fare-rules__popup-rule-carrier',
			});
			popup.show();
			return false;
		}

		const params = {
			token: this.options.token,
			bookingFile: this.model.get('bookingToPay'),
		};

		GlUl.openRuleCarrier.call(this, e, params);
		return false;
	},

});
