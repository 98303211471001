import axios from "axios";
import { NewItemDetails } from "./interfaces";

export const fetchNewsItemDetails = async (uid: string) => {
	try {
		const { data = {} } = await axios.get(
			"/midoffice/ibecorp-b2b/notices/get",
			{
				params: { uid },
			}
		);
		return data.result as NewItemDetails;
	} catch (err) {
		logger.error(err);
		return { uid } as NewItemDetails;
	}
};

export const fetchMarkRead = async (uid: string) => {
	try {
		await axios.post("/midoffice/ibecorp-b2b/notices/markRead", {
			parameters: { uid },
		});
	} catch (err) {
		logger.error(err);
	}
};
