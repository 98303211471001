// eslint-disable-next-line banned-modules
'use strict';

import './style.less';

import BaseView from '@/classes/base.view';
import template from './template.ejs';

import ToggleModel from '@/blocks/elements/b-filters/widgets/toggle/model';
import RangeModel from '@/blocks/elements/b-filters/widgets/range/model';
import SelectModel from '@/blocks/elements/b-filters/widgets/select/model';
import InputModel from '@/blocks/elements/b-filters/widgets/input/model';

import GlUl from '@/utils/global-utils';
import $ from 'jquery';

export default BaseView.extend({

	template,

	ui: {
		dropFilters: '.b-filters__drop-filters-button',
		sorting: '.b-filters__sorting',
		toggle: '.b-filters__toggles',
		range: '.b-filters__range',
		select: '.b-filters__select',
		input: '.b-filters__input',
		common: '.b-filters__common',
		legs: '.b-filters__leg',
		travelPolicy: '.b-filters__travel_policy',
		trainLegs: '.b-filters__train_leg',
	},

	events: {
		'click .b-filters__mobile-apply': 'closeFiltersMobile',
		'click .b-filters__mobile-reset': 'reset',
		'click .b-filters__drop-filters-button': 'resetDesktop',
	},

	preinitialize(options) {
		BaseView.prototype.preinitialize.call(this, options);
		this.windowEventListenerList.push({
			name: 'scroll',
			callback: GlUl.resetButtonOnScroll,
		});
	},

	initialize() {
		this.render();
	},

	addSorting(view) {
		this.ui.sorting.append(view.el);
	},

	closeFiltersMobile() {
		$('.l-layout').removeClass('show-filters');
	},

	reset() {
		this.collection.models.forEach(model => {
			if (model instanceof ToggleModel) {
				model.set('value', false);
			} else if (model instanceof RangeModel) {
				model.set('value', [model.get('min'), model.get('max')]);
			} else if (model instanceof SelectModel) {
				model.get('values').forEach(item => item.set('value', false));
			} else if (model instanceof InputModel) {
				model.set('value', '');
			}
		});
		this.closeFiltersMobile();
	},

	resetDesktop() {
		this.reset();
		window.scrollTo(0, 0);
	},

	render() {
		this.setElement(this.template());

		const temp = [];
		const remove = [];

		this.collection.models.forEach(model => {
			let $container = null;

			if (model instanceof RangeModel) {
				if (!model.isValid()) {
					remove.push(model);
					return;
				}
			}
			if (model.has('trainLegs')) {
				this.ui.trainLegs.eq(model.get('leg')).css({
					display: 'block',
				});
				$container = this.ui.trainLegs.find('.b-filters__filter-lines-container').eq(model.get('leg'));
			} else if (model.has('legsCount') && model.get('legsCount') > 1) {
				this.ui.legs.css({
					display: 'block',
				});
				$container = this.ui.legs.find('.b-filters__filter-lines-container').eq(model.get('leg'));
			} else if (model instanceof ToggleModel) {
				$container = this.ui.toggle;
			} else if (model instanceof RangeModel) {
				$container = this.ui.range;
			} else if (model instanceof SelectModel && (model.get('field').includes('travelPolicyCompliance'))) {
				$container = this.ui.travelPolicy;
			} else if (model instanceof SelectModel) {
				$container = this.ui.select;
			} else if (model instanceof InputModel) {
				$container = this.ui.input;
			}

			if (model.has('common')) {
				$container = this.ui.common;
			}

			if ($container != null) {
				const content = model.render();

				if (content != null) {
					$container.append(content.$el);
				}
			}
		});
		_.each([this.ui.legs, this.ui.toggle, this.ui.range, this.ui.travelPolicy,
			this.ui.select, this.ui.input, this.ui.common], (el) => {
			if (el.is(':empty')) {
				el.hide();
			} else {
				el.show();
			}
		});
		temp.forEach(item => {
			this.collection.models.push(item);
		});
		this.collection.remove(remove);
		this.collection.bind();
	},

});
