// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';

export default BaseModel.extend({

	toJSON() {
		const result = BaseModel.prototype.toJSON.call(this);
		const json = {};

		let value = result.value;
		
		_.each(['costCodeCategoryUid', 'costCodePropertyUid', 'organization'], (field) => {
			if (result[field] != null) {
				json[field] = result[field];
			}
		});

		// fix autocomplete
		if (value && value.uid && (value.uid.localValue || value.uid.englishValue)) {
			value = value.uid;
		}

		json.name = result.name;
		json.value = value || {};

		return json;
	},
});
