// eslint-disable-next-line banned-modules
'use strict';

import BaseView from '@/classes/base.view';
import Model from './model';
import template from './template.ejs';
import './style.less';

import BaseModel from '@/classes/base.model';

import EmailsCollectionView from '@/blocks/elements/b-ticket/b-ticket-popup-offers/b-emails-collection/index';
import EmailsCollection from '@/blocks/elements/b-ticket/b-ticket-popup-offers/b-emails-collection/collection';
import EmailModel from '@/blocks/elements/b-ticket/b-ticket-popup-offers/b-emails-collection/b-email/model';

import GlUl from '@/utils/global-utils';
import axios from 'axios';
import $ from 'jquery';

export default BaseView.extend({
	el: 'body',

	ui: {
		emailsContainer: '.js-emails-container',
		modal: '.b-booking-docs-modal__content',
	},

	template,

	events: {
		'click .js-close-modal': 'closeModal',
		'click .js-send-booking-docs': 'send',
		'click .js-download-booking-docs': 'download',
		'click .js-download-single': 'downloadSingle',
	},

	initialize(options) {
		this.options = options;
		this.documents = options.documents;
		this.model = new Model();
		this.model.set('emails', new EmailsCollection([], { model: EmailModel }));
		this.model.set('autoSuggestEmails', this.options.autoSuggestEmails);
		this.model.set(
			'selectedDocuments',
			new BaseModel({
				allDocuments: true,
			}),
		);
		this.selectedDocuments = this.model.get('selectedDocuments');
		_.each(this.documents, (d) => {
			if (!d) return;
			const model = new BaseModel({ selected: true, className: d.className });
			this.listenTo(
				model,
				'change:selected',
				this.toggleSelectedDocument.bind(this),
			);
			this.selectedDocuments.set(d.uid, model);
		});
		this.listenTo(
			this.selectedDocuments,
			'change',
			this.toggleSelectedDocument.bind(this),
		);

		if (_.isEmpty(this.model.get('autoSuggestEmails'))) this.getEmails();

		this.render();
	},

	toggleSelectedDocument(e) {
		const model = this.selectedDocuments;
		const documents = _.omit(model.attributes, 'allDocuments');

		if (!_.isEmpty(e.changed) && e.changed.allDocuments != null) {
			const allSelected = e.changed.allDocuments === true;

			_.each(documents, (d, key) => {
				model.set(`${key}.selected`, allSelected);
				this.model.trigger(
					`change:selectedDocuments.${key}.selected`,
					d,
					allSelected,
				);
			});
		} else {
			const allSelected = _.every(
				documents,
				(el) => el.get('selected') === true,
			);

			model.set('allDocuments', allSelected, { silent: true });
			this.model.trigger(
				`change:selectedDocuments.allDocuments`,
				model,
				allSelected,
			);
		}
	},

	render(...args) {
		BaseView.prototype.render.call(this, ...args);

		this.ui.emailsContainer.empty().append(
			new EmailsCollectionView({
				autoSuggestEmails: this.model.get('autoSuggestEmails') || [],
				collection: this.model.get('emails'),
				container: this.ui.emailsContainer,
				model: this.model,
			}),
		);

		return this;
	},

	closeModal(e) {
		if (e) e.preventDefault();
		this.$el.remove();
	},

	getEmails() {
		let travellers = [];
		_.each(this.options.reservations, (r) => {
			_.each(r.services, (p) => {
				_.each(p.travellers, (t) => {
					if (t.person != null) travellers.push(t.person);
				});
			});
		});
		if (_.isEmpty(travellers)) return;

		travellers = _.uniq(travellers, (t) => t.uid);

		this.clientToSend = this.options.client;
		this.passengersToSend = travellers;

		GlUl.getEmails.call(this);
	},

	getSelectedDocumentsUids() {
		const result = [];
		_.each(
			_.omit(this.selectedDocuments.toJSON(), 'allDocuments'),
			(value, key) => {
				if (value.selected) result.push(key);
			},
		);
		return result;
	},

	getSelectedDocumentsRefs() {
		const result = [];
		_.each(
			_.omit(this.selectedDocuments.toJSON(), 'allDocuments'),
			(value, key) => {
				if (value.selected) {
					result.push({
						uid: key,
						className: value.className,
					});
				}
			},
		);
		return result;
	},

	send(e) {
		if (e) e.preventDefault();

		const params = {
			parameters: {
				currentBooking: this.options.currentBooking,
				refs: this.getSelectedDocumentsRefs(),
				emails: this.model.get('emails').toJSON(),
				sendItineraryReceiptWithComment: [
					{ uid: '1', comments: this.model.get('comment') },
				],
			},
		};

		STATE.showLoader();
		this.disableElements(e);
		axios
			.post('midoffice/ibecorp-b2b/cabin/sendBookingDocuments', params)
			.then(() => {
				STATE.hideLoader();
				const popup = new Widgets.Popup({
					content: L10N.get('cabinet.orders.bookingDocumentsSentSuccessfully'),
					type: 'info',
					actions: [
						{
							label: L10N.get('bookingForm.continue'),
							action: () => {
								this.closeModal.call(this);
								popup.hide();
							},
						},
					],
				});
				popup.show();
			});
	},

	downloadSingle(e) {
		if (!e) return;
		e.preventDefault();
		const uid = $(e.currentTarget).data('docUid');
		const className = $(e.currentTarget).data('className');
		if (!uid || !uid.length) return;
		STATE.showLoader();
		const params = {
			parameters: {
				ref: {
					uid,
					className,
				},
			},
		};
		axios({
			method: 'POST',
			url: 'midoffice/ibecorp-b2b/cabin/downloadBookingDocument',
			responseType: 'arraybuffer',
			data: params,
		})
			.then((res) => {
				STATE.hideLoader();
				GlUl.downloadFile(res, 'bookingDocument');
			});
	},

	download(e) {
		if (e) e.preventDefault();
		const params = {
			parameters: {
				refs: this.getSelectedDocumentsRefs(),
			},
		};
		STATE.showLoader();
		this.disableElements(e);
		axios({
			method: 'POST',
			url: 'midoffice/ibecorp-b2b/cabin/downloadBookingDocuments',
			responseType: 'arraybuffer',
			data: params,
		})
			.then((res) => {
				STATE.hideLoader();
				GlUl.downloadFile(res, 'bookingDocuments');
			});
	},
});
