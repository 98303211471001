module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (STATE.checkSiteType('B2B')) { ;
__p += '\n    <div class="p-login__button-container js-login-button-container">\n        <button class="p-login__button js-login" disabled="true">\n            <a href="#" class="p-login__button-link">' +
((__t = ( L10N.get('AccountLoginForm.buttons.signIn') )) == null ? '' : __t) +
'</a>\n        </button>\n        <button class="p-login__button js-restore" disabled="true">\n            <a href="#" class="p-login__button-link">' +
((__t = ( L10N.get('AccountLoginForm.buttons.sendRecovery') )) == null ? '' : __t) +
'</a>\n        </button>\n        <button class="p-login__button js-register" disabled="true">\n            <a href="#" class="p-login__button-link">' +
((__t = ( L10N.get('AccountLoginForm.buttons.register') )) == null ? '' : __t) +
'</a>\n        </button>\n    </div>\n';
 } ;
__p += '\n';

}
return __p
}