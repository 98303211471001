// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import PricingView from '@/blocks/elements/b-hotel/b-hotel-pricing/index';
import template from './template.ejs';

export default BaseView.extend({

	template,

	ui: {
		pricing: '.b-hotel-info__pricing',
	},

	initialize() {
		this.hotel = this.options.hotel;
		this.showGdsAccountName = this.options.showGdsAccountName;
		
		const {address} = this.hotel;
		if (address != null && address.cityInfo != null) {
			const locationHotel = _.pick(address, ['latitude', 'longitude']);
			const locationCity = _.pick(address.cityInfo, ['latitude', 'longitude']);

			if (!_.isEmpty(locationHotel) && !_.isEmpty(locationCity)) {
				try {
					const distance = window.google.maps ? Math.ceil(window.google.maps.geometry.spherical.computeDistanceBetween(
						new window.google.maps.LatLng(locationHotel.latitude, locationHotel.longitude),
						new window.google.maps.LatLng(locationCity.latitude, locationCity.longitude),
					)) : NaN;

					const distanceValue = (distance / 1000);
					if (!isNaN(distanceValue)) {
						this.hotel.distance = distanceValue;
					}
				} catch (e) {
					throw e;
				}
			}
		}

		this.render();
	},

	render(...args) {
		BaseView.prototype.render.apply(this, args);

		const pricingView = new PricingView({
			offers: this.hotel.hotelOffers,
		});
		this.ui.pricing.html(pricingView.$el);
	},

});
