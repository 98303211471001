// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import suggestionTemplate from './suggestion.ejs';
import selectedItemTemplate from './selectedItem.ejs';
import 'corejs-typeahead/dist/typeahead.jquery';
import $ from 'jquery';

import BaseCollection from '@/classes/base.collection';
import BaseModel from '@/classes/base.model';

const FinanceSelectModel = BaseModel.extend({
	idAttribute: 'uid',
});

const FinanceSelectCollection = BaseCollection.extend({
	model: FinanceSelectModel,
});

const FinanceSelect = Widget.extend({

	template,

	viewName: 'b-finance-select',

	ui: {
		selected: '.b-finance-select__selected',
		all: '.b-finance-select',
		popup: '.b-finance-select__popup',
		ctrl: '.b-finance-select__label',
	},

	events: {
		'click .b-finance-select__label,.b-finance-select__select': 'onLabelClicked',
		'focus .b-finance-select__input': 'onFocus',
		'blur .b-finance-select__input': 'onBlur',
		'typeahead:beforeclose': 'typeaheadBeforeClose',

		'mousedown .b-finance-select__label,.b-finance-select__select': 'onLabelMouseDown',
		'mousedown .b-finance-select__selected': 'selectedDocumentMouseDown',
		'mousedown .b-finance-select__item-code': 'removeSelected',
	},

	lastClick: '',

	initialize(options) {
		this.options = options;
		this.values = FinanceSelect.getStoreCollection(options.values).toJSON();
		this.selectedDocuments = [];
		this.lastSelectedDocumentUid = '';
		this.render();
		this.$typeahead = this.getElements('input');
		this.$typeahead.typeahead(
			{
				hint: false,
				highlight: false,
				minLength: 0,
				limit: 1,
				classNames: {
					menu: 'b-finance-select__items',
					wrapper: 'b-finance-select__wrapper',
					input: 'b-finance-select__input',
					hint: 'b-finance-select__hint',
					dataset: 'b-finance-select__dataset',
					suggestion: 'b-finance-select__item',
					selectable: 'b-finance-select__selectable',
					empty: 'b-finance-select__empty',
					open: 'b-finance-select__open',
					cursor: 'b-finance-select__cursor',
					highlight: 'b-finance-select__highlight',
				},
			},
			{
				name: 'documentSelect',
				limit: 10,
				source: (q, cb) => {
					const selected = this.selectedDocuments;
					cb(this.values.reduce((result, item) => {
						if (item && item.caption && item.caption.toUpperCase().includes(q.toUpperCase())) {
							if (_.isEmpty(selected) || !selected.find(el => el.uid === item.uid)) result.push(item);
						}
						return result;
					}, []));
				},
				display: ' get display ',
				async: true,
				templates: {
					suggestion: suggestionTemplate,
				},
			});
	},

	applyBinding(model) {
		if (!this.model.get(this.options.bindingProperty)) {
			this.model.set(this.options.bindingProperty, []);
		}

		this.$typeahead.on('typeahead:select', (ev, suggestion) => {
			let modelDocuments = model.get(this.options.bindingProperty);
			this.lastSelectedDocumentUid = suggestion.uid;
			if (!modelDocuments.find(element => element.uid === suggestion.uid)) {
				modelDocuments = modelDocuments.concat({
					uid: suggestion.uid,
					caption: `${suggestion.caption}`,
				});
				model.set(this.options.bindingProperty, modelDocuments);
			}
			this.lastClick = 'suggestion';
		});

		this.listenTo(model, `validationError:${this.options.bindingProperty}`, () => {
			this.$el.addClass('validation-error');
		});

		this.listenTo(model, `change:${this.options.bindingProperty}`, (__model, documents) => {
			this.ui.selected.empty();
			if (!documents) {
				return;
			}
			documents.forEach(document => {
				this.ui.selected.append(selectedItemTemplate(document));
			});
			this.selectedDocuments = documents;

			this.showSelectedFinanceDocumentsCount();
			this.changeSuggestedDocsVisibility();
		});
		model.trigger(`change:${this.options.bindingProperty}`, model, model.get(this.options.bindingProperty));
	},

	changeSuggestedDocsVisibility() {
		const suggestions = this.$el.find(`[data-uid]`);
		const selectedSuggestions = _.filter(suggestions, (el) => {
			return _.find(this.selectedDocuments, document => document.uid === $(el).attr('data-uid'));
		});
		const unselectedSuggestions = _.filter(suggestions, (el) => selectedSuggestions.indexOf(el) === -1);
		$(selectedSuggestions).addClass('dn');
		$(unselectedSuggestions).removeClass('dn');
	},

	displaySuggestions() {
		this.$typeahead.val('').trigger('input');
	},

	removeSelected(e) {
		const uid = this.$(e.currentTarget).attr('uid');
		this.lastSelectedDocumentUid = uid;
		this.model.set(this.options.bindingProperty, this.model.get(this.options.bindingProperty).filter(item => item.uid !== uid));
		this.lastClick = 'remove';
		this.displaySuggestions();
	},

	onBlur() {
		if (!(this.lastClick === 'remove')) {
			this.hideSelected();
			this.$el.removeClass('open');
		} else {
			setTimeout(() => {
				this.$typeahead.focus();
			}, 0);
		}
	},

	hideSelected() {
		this.ui.popup.hide();
	},

	showSelectedFinanceDocumentsCount() {
		const value = this.model.get(this.options.bindingProperty);
		if (!value || value.length === 0) {
			this.ui.ctrl.html(`${L10N.get('cabinet.finance.documentType')}`);
			this.$el.removeClass('active');
			return;
		}
		this.selectedDocuments = value;
		this.$el.addClass('active');
		const selectedValues = value.reduce((prev, cur) => {
			prev.push(cur.caption);
			return prev;
		}, []).join(', ');
		this.ui.ctrl.html(selectedValues);
	},

	typeaheadBeforeClose() {
		if (this.lastClick === 'remove') {
			this.lastClick = '';
			return false;
		}
		if (this.lastClick === 'suggestion') {
			this.lastClick = '';
			return false;
		}
		return true;
	},

	onLabelClicked() {
		if (this.$el.hasClass('open') || this.lastClick === 'label') {
			this.$el.removeClass('open');
			this.lastClick = '';
			return;
		}
		this.$el.addClass('open');
		this.ui.popup.show();
		this.$typeahead.focus();
	},

	onLabelMouseDown() {
		if (this.$el.hasClass('open')) {
			this.lastClick = 'label';
		}
	},

	selectedDocumentMouseDown() {
		this.lastClick = 'remove';
	},

}, {
	dictionaryToCollection(data) {
		return new FinanceSelectCollection(data);
	},

	arrayToCollection(data) {
		return FinanceSelect.dictionaryToCollection(data);
	},

	storeCollection(collection) {
		if (!FinanceSelect.stores) {
			FinanceSelect.stores = {};
		}
		if (!FinanceSelect.stores[collection.cid]) {
			FinanceSelect.stores[collection.cid] = collection;
		}
		return collection.cid;
	},

	getStoreCollection(cid) {
		return FinanceSelect.stores[cid];
	},
});

export default FinanceSelect;
