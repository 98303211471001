module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<section class="b-payment-cash">\n	<header class="b-payment-cash__header">' +
((__t = ( L10N.get("paymentCash.orderTitle") )) == null ? '' : __t) +
'</header>\n	<div class="b-payment-cash__content">\n		<header class="b-payment-cash__content-header">' +
((__t = ( L10N.get("paymentCash.orderNumber") )) == null ? '' : __t) +
'<span class="b-payment-cash__content-header-number">&nbsp;' +
((__t = ( pnr )) == null ? '' : __t) +
'</span></header>\n		<span class="b-payment-cash__content-price">' +
((__t = ( L10N.get("paymentCash.orderPrice") )) == null ? '' : __t) +
'&nbsp;<span class="b-payment-cash__content-price-amount">' +
((__t = ( total.amount )) == null ? '' : __t) +
'' +
((__t = ( Formatter.formatCurrency(total.currencyCode) )) == null ? '' : __t) +
'</span></span>\n		<span class="b-payment-cash__content-text">' +
((__t = ( text )) == null ? '' : __t) +
'</span>\n	</div>\n</section>\n\n';

}
return __p
}