// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';
import $ from 'jquery';

export default Widget.extend({

	validationTemplate,

	template,

	events: {
		click: 'setActive',
		'click .b-numerable-picker__item-minus': 'minus',
		'click .b-numerable-picker__item-plus': 'plus',
		'click .b-numerable-picker__wrapper': 'open',
	},

	initialize() {
		this.options.max = parseInt(this.options.max);
		this.options.min = parseInt(this.options.min);
		this.options.default = parseInt(this.options.default);
		this.render();
		if (this.options.additionalData) {
			this.$el.get(0).dataset.additional = this.options.additionalData;
		}
	},

	setActive() {
		this.isActive = true;
	},

	open() {
		this.$el.toggleClass('open');
		this.$('.b-numerable-picker__validation').toggleClass('b-numerable-picker__validation-visible');
		$('html').on('click.numerablePicker', () => {
			if (this.$el.hasClass('disabled')) {
				return;
			}

			if (!this.isActive) {
				this.$el.removeClass('open');
				this.$('.b-numerable-picker__validation').removeClass('b-numerable-picker__validation-visible');
				$('html').off('click.numerablePicker');
			}
			this.isActive = false;
		});
	},

	updateLabel(value = parseInt(this.model.get(this.options.bindingProperty))) {
		value = parseInt(value);
		let label = value;

		if (_.isNaN(value) || !value) {
			label = `<span class="placeholder">${L10N.get('Widgets.select.notSelected')}</span>`;
		} else if (L10N.getUnescaped(this.options.plurarForm) != null) {
			label += ` ${L10N.getPluralForm(this.options.plurarForm, value)}`;
		}

		this.$('.b-numerable-picker__input').html(label);

		return this;
	},

	minus() {
		const value = parseInt(this.model.get(this.options.bindingProperty));
		if (value > (this.options.min || 0)) {
			this.model.set(this.options.bindingProperty, value - 1);
		}
	},

	plus() {
		const value = parseInt(this.model.get(this.options.bindingProperty));
		if (value < (this.options.max || 8)) {
			this.model.set(this.options.bindingProperty, value + 1);
		}
	},

	applyBinding(model) {
		this.model = model;

		this.model.on(`change:${this.options.bindingProperty}`, (m, value) => {
			this.$el.find('.b-numerable-picker__item-value').text(value);
			this.updateLabel(value);

			if (value) {
				this.$el.addClass('has-value');
			} else {
				this.$el.removeClass('has-value');
			}
		});

		this.model.on(`validationError:${this.options.bindingProperty}`, (errors) => {
			this.showValidation(errors);
		});

		if (this.options.enabledProperty != null) {
			this.model.on(`change:${this.options.enabledProperty}`, (m, value) => {
				if (value) {
					this.$el.removeClass('disabled');
				} else {
					this.$el.addClass('disabled');
				}
			});

			if (!this.model.get(this.options.enabledProperty)) {
				this.$el.addClass('disabled');
			}
		}

		if (!this.model.has(this.options.bindingProperty)) {
			this.model.set(this.options.bindingProperty, this.options.default || this.options.min || 0);
		}

		this.$el.find('.b-numerable-picker__item-value').text(this.model.get(this.options.bindingProperty));

		this.updateLabel();
	},

});
