module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var remarks = remarks || [];
    var dateTimeRemarks = _.filter(remarks, function(r) { return r && (r.dataType || {}).uid === 'DATETIME'});
    var stringRemarks = _.filter(remarks, function(r) { return r && (r.dataType || {}).uid === 'STRING'});
;
__p += '\n<div class="b-remarks">\n    <div class="b-remarks__datetime-section-container">\n        ';
 _.each(dateTimeRemarks, function (r) {
            if (_.isEmpty(r)) return;
        ;
__p += '\n        <div class="b-remarks__remark-row">\n            <span class="js-widget"  onclick=\'return new Widgets.Time({bindingProperty: "remarksValue.'+ r.id +'", label: "'+ r.title +'"})\'></span>\n        </div>\n        ';
 }) ;
__p += '\n    </div>\n    <div class="b-remarks__string-section-header">\n        ' +
((__t = ( L10N.get('hotels.preferences') )) == null ? '' : __t) +
'\n        <span class="g-icon-attention"\n              data-toggle="tooltip"\n              data-html="true"\n              data-position="bottom"\n              data-original-title="<div>' +
((__t = ( L10N.get('hotels.preferencesAttention') )) == null ? '' : __t) +
'</div>"></span>\n    </div>\n    <div class="b-remarks__string-section-container">\n        ';
 _.each(stringRemarks, function (r) {
            if (_.isEmpty(r)) return;
        ;
__p += '\n        <div class="b-remarks__remark-row">\n            <span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "remarksValue.'+ r.id +'", label: "'+ r.title +'"})\'></span>\n        </div>\n        ';
 }) ;
__p += '\n    </div>\n</div>\n';

}
return __p
}