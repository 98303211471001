/* eslint-disable banned-modules */
'use strict';

import './style.less';
import $ from 'jquery';
import BaseView from '@/classes/base.view';
import MapView from '@/blocks/elements/b-train/b-train-maps/index';
import template from './template.ejs';
import carriageMap from './carriage-map.ejs';
import carriageMapDescription from './carriage-map-description.ejs';
import axios from 'axios';

export default BaseView.extend({

	template,

	events: {
		'click .b-train__carriage' : 'showMap',
		'click .b-carriage-maps__slide-left' : 'showLeft',
		'click .b-carriage-maps__slide-right' : 'showRight',
	},

	ui: {
		carriages: '.b-train__carriage',
		mapsArea: '.b-carriage-maps__area',
		arrowLeft: '.b-carriage-maps__slide-left',
		arrowRight: '.b-carriage-maps__slide-left',
	},

	maps: null,
	openedMap: null,

	initialize(options) {
		this.maps = {};
		this.inmobCarSchemeIdList = [];
		this.filtered = options.filtered;
		this.carriages = options.carriages;
		this.carriagesGroups = this.buildCarriagesGroups(this.carriages);
		this.currencyCode = options.currencyCode;
		this.parent = options.parent;
		this.trainPreviewInmobMap = options.trainPreviewInmobMap;
		this.seatsAmount = this.parent.seatsAmount;
		this.model = this.parent.model;
		this.render();
		this.initializeMaps();
	},

	buildCarriagesGroups(carriages) {
		const carriagesGroups = {
			map: {},
			groups: [],
			genders: [],
		};
		const travelPolicyComplianceVector = [];

		carriages.sort((c1, c2) => {
			const n1 = parseInt(c1.number, 10);
			const n2 = parseInt(c2.number, 10);
			if (n1 < n2) return -1;
			if (n1 > n2) return 1;
			return 0;
		});

		carriages.forEach(car => {
			let travelPolicyIndex = _.findIndex(travelPolicyComplianceVector, (el) => _.isEqual(el, car.travelPolicyCompliance));
			if (_.isEmpty(travelPolicyComplianceVector) || travelPolicyIndex === -1) {
				travelPolicyComplianceVector.push(car.travelPolicyCompliance);
				travelPolicyIndex = travelPolicyComplianceVector.length - 1;
			}

			const price = car.price.priceRange != null ? `${car.price.priceRange.min}_${car.price.priceRange.max}` : car.price.price;
			const key = `${price}_${(car.serviceClass && car.serviceClass.uid)}_${travelPolicyIndex}`;
			if (!(key in carriagesGroups.map)) {
				carriagesGroups.map[key] = [];
			}
			car._groupId = key;
			carriagesGroups.map[key].push(car);
		});
		Object.keys(carriagesGroups.map).forEach(key => {
			carriagesGroups.groups.push(carriagesGroups.map[key]);
		});
		carriagesGroups.groups.sort((c1, c2) => {
			const price1 = c1[0].price.priceRange != null ? c1[0].price.priceRange.min : c1[0].price.price;
			const price2 = c2[0].price.priceRange != null ? c2[0].price.priceRange.min : c2[0].price.price;
			return price1 - price2;
		});
		carriagesGroups.groups.forEach(group => {
			const car = group;
			const genders = [];
			car.forEach(c => {
				const gender = {};
				c.seatsAvailable.forEach(s => {
					if (s.gender != null) {
						if (s.gender.uid === 'MALE' || s.gender.uid === 'FEMALE' || s.gender.uid === 'NOT_SELECTED' || s.gender.uid === 'MIX') {
							gender[s.gender.uid] = true;
						}
					}
				});
				genders.push(gender);
			});
			carriagesGroups.genders.push(genders);
		});
		return carriagesGroups;
	},

	initializeMaps() {
		_.each(this.carriagesGroups.map, (carriages, key) => {
			this.maps[key] = this.maps[key] || [];
			_.each(carriages, (c) => {
				if (c.providerCarSchemeId && !this.trainPreviewInmobMap) return;
				if (this.trainPreviewInmobMap && this.trainPreviewInmobMap.carNumber === c.number) {
					this.currentGroupId = key;
					this.inmobCarSchemeIdList.push({id: c.providerCarSchemeId, map: this.trainPreviewInmobMap.map});
					this.maps[key].push(new MapView({
						parent: this,
						carriage: c,
						seatsAmount: this.seatsAmount,
						passengersTypes: this.options.parent.passengersTypes,
						train: this.model.toJSON(),
						inMobMap: this.trainPreviewInmobMap.map,
						providerCarSchemeId: c.providerCarSchemeId,
					}));
					this.maps[key].sort((m1, m2) => {
						const n1 = parseInt(m1.carriage.number, 10);
						const n2 = parseInt(m2.carriage.number, 10);
						if (n1 < n2) return -1;
						if (n1 > n2) return 1;
						return 0;
					});
					return;
				}
				if (this.trainPreviewInmobMap && this.trainPreviewInmobMap.carNumber !== c.number && c.providerCarSchemeId != null) return;
				this.maps[key].push(new MapView({
					parent: this,
					carriage: c,
					seatsAmount: this.seatsAmount,
					passengersTypes: this.options.parent.passengersTypes,
					train: this.model.toJSON(),
				}));
			});
		});
	},

	showMap(e) {
		const $currentTarget = $(e.currentTarget);
		const groupId = $currentTarget.data('id');
		this.currentGroupId = groupId;
		const id = this.carriagesGroups.map[groupId][0]._id;
		this.isEmptyMap = this.carriagesGroups.map[groupId][0].carriageCardId === 'EMPTY_MAP';
		this.noSeatSelection = this.carriagesGroups.map[groupId][0].noSeatSelection;
		// drop prev selected gender
		this.options.parent.setSelectedGender(null);
		return this.showToggleMap(this._getMapId(null, {
			id,
			groupId,
		}));
	},

	handleInmobMap(mapId) {
		const _this = this;
		const groupId = this.currentGroupId;
		if (!groupId) return Promise.resolve();
		const {providerCarSchemeId, twoStoreyed} = this.carriagesGroups.map[groupId][mapId];
		return new Promise((resolve) => {
			if (!providerCarSchemeId) {
				logger.error('missing providerCarSchemeId property', _this.parent.model.toJSON());
				resolve();
				return;
			}
			const exactMap = _this.inmobCarSchemeIdList.find(el => el.id === providerCarSchemeId);
			if (exactMap == null) {
				_this.getInmobMap(providerCarSchemeId, twoStoreyed).then((inMobMap) => {
					_this.inmobCarSchemeIdList.push({id: providerCarSchemeId, map: inMobMap});
					_this.initializeInmobMap(providerCarSchemeId, groupId, inMobMap, mapId);
					resolve();
				});
			} else {
				if (!_this.maps[groupId] || !_this.maps[groupId].find(el => el.options.providerCarSchemeId === providerCarSchemeId)) {
					_this.initializeInmobMap(providerCarSchemeId, groupId, exactMap.map, mapId);
				}
				resolve();
			}
		});
	},

	initializeInmobMap(schemeId, groupId, inMobMap, mapId) {
		this.maps[groupId] = this.maps[groupId] || [];
		_.each(this.carriagesGroups.map[groupId], (c) => {
			if (c.providerCarSchemeId !== schemeId) return;
			this.maps[groupId].push(new MapView({
				parent: this,
				carriage: c,
				seatsAmount: this.seatsAmount,
				passengersTypes: this.options.parent.passengersTypes,
				train: this.model.toJSON(),
				inMobMap,
				mapId,
				providerCarSchemeId: schemeId,
			}));
			this.maps[groupId].sort((m1, m2) => {
				const n1 = parseInt(m1.carriage.number, 10);
				const n2 = parseInt(m2.carriage.number, 10);
				if (n1 < n2) return -1;
				if (n1 > n2) return 1;
				return 0;
			});
		});
	},

	getInmobMap(id, twoStoreyed) {
		STATE.showLoader();
		if (twoStoreyed) {
			const promises = [];
			promises.push(axios.post('/midoffice/ibecorp-b2b/rail/car/scheme', {
				parameters: {
					providerCarSchemeId: id,
					schemeStorey: 1,
					gdsAccount: this.options.gdsAccount,
				},
			}));
			promises.push(axios.post('/midoffice/ibecorp-b2b/rail/car/scheme', {
				parameters: {
					providerCarSchemeId: id,
					schemeStorey: 2,
					gdsAccount: this.options.gdsAccount,
				},
			}));
			return Promise.all(promises).then(responses => {
				STATE.hideLoader();
				return `${responses[1].data}</br>${responses[0].data}`;
			});
		}
		return axios.post('/midoffice/ibecorp-b2b/rail/car/scheme', {
			parameters: {
				providerCarSchemeId: id,
				schemeStorey: 1,
				gdsAccount: this.options.gdsAccount,
			},
		}).then(res => {
			STATE.hideLoader();
			return res.data;
		});
	},

	_getMapId(next, attrs) {
		const groupId = _.isObject(attrs) ? attrs.groupId : this.$el.find('.b-train__carriage.open').data('id');
		const id = _.isObject(attrs) ? attrs.id : parseInt(this.$el.find('.b-train__carriage-map:visible').data('id'), 10);

		const maps = _.map(this.carriagesGroups.map[groupId], (el) => el._id);
		const currentIndex = maps.indexOf(id);
		let mapId = currentIndex;

		if (next != null) {
			if (maps.length === 1 || (currentIndex === maps.length - 1 && next)) {
				mapId = 0;
			} else if (currentIndex === 0 && !next) {
				mapId = maps.length - 1;
			} else {
				mapId = next ? currentIndex + 1 : currentIndex - 1;
			}
		}

		return {
			hasLeft: maps[mapId - 1] != null,
			hasRight: maps[mapId + 1] != null,
			mapId,
			groupId,
		};
	},

	showLeft() {
		if (this.$el.find('.b-train__carriage.open').length > 0) {
			const options = this._getMapId(false);
			return this.showToggleMap(options, true);
		}
		return Promise.resolve();
	},

	showRight() {
		if (this.$el.find('.b-train__carriage.open').length > 0) {
			const options = this._getMapId(true);
			return this.showToggleMap(options, true);
		}
		return Promise.resolve();
	},

	showToggleMap(options, slide = false, hideMap = false) {
		this.openedMap = null;

		const {mapId = 0, groupId, hasLeft, hasRight} = options;

		return this.handleInmobMap(mapId).then(() => {
			const isMobile = STATE.checkViewport('(max-width: 768px)');

			const hasOpened = this.$el.find('.b-train__carriage-map:visible').length > 0;
			const currentGroupOpened = _.some(this.maps[groupId], (map) => map.$el.is(':visible'));

			this.selectPlace(null);

			if (_.isEmpty(this.maps[groupId])) {
				return this;
			}

			this.ui.carriages.removeClass('open');
			this.ui.carriages.find('.b-train__carriage-number span').removeClass('open');

			this.$('.b-carriage-maps__wrapper').remove();

			// toggle currently opened group
			if (!hideMap && this.options.gds.uid !== 'INMOB') {
				this.options.parent.hideAllMapContainers();
			}

			this.options.parent.selectedGender = null;
			if (hideMap || (hasOpened && currentGroupOpened && !slide)) {
				return this;
			}

			// highlight current group
			const $carriage = this.ui.carriages.filter(`[data-id="${groupId}"]`);
			let $carriageMapContainer = null;

			if ($carriage.next('.b-carriage-maps__wrapper').length > 0) {
				$carriageMapContainer = $carriage.next('.b-carriage-maps__wrapper');
			} else {
				$carriageMapContainer = $($.parseHTML(carriageMap()));
				$carriage.after($carriageMapContainer);
			}

			if (!hasLeft && !hasRight) {
				$carriageMapContainer.find('.b-carriage-maps__controls').hide();
				$carriageMapContainer.addClass('without-arrows');
			} else {
				$carriageMapContainer.find('.b-carriage-maps__controls').show();
				$carriageMapContainer.removeClass('without-arrows');
			}

			if (!hasLeft) {
				$carriageMapContainer.find('.b-carriage-maps__slide-left').addClass('disabled');
			} else {
				$carriageMapContainer.find('.b-carriage-maps__slide-left').removeClass('disabled');
			}

			if (!hasRight) {
				$carriageMapContainer.find('.b-carriage-maps__slide-right').addClass('disabled');
			} else {
				$carriageMapContainer.find('.b-carriage-maps__slide-right').removeClass('disabled');
			}

			$carriage.addClass('open');
			$carriageMapContainer.find('.b-carriage-maps__container').addClass('open');

			const genderSeats = this.maps[groupId][mapId].carriage.seatsAvailable.filter(s => s.gender != null);
			const carPlaceData = this.maps[groupId][mapId].carriage.carPlaceData;
			const seatsGroup = {
				seatsGroupSize: this.maps[groupId][mapId].carriage.seatsGroupSize,
				seatsGroupSizeRestricted: this.maps[groupId][mapId].carriage.seatsGroupSizeRestricted === true,
				seatsAmount: this.seatsAmount,
			};

			const $description = $carriageMapContainer.find('.b-carriage-map__description');
			$description.find('.b-map-description__genders').html('');

			if (genderSeats.length > 0 || (carPlaceData != null && carPlaceData.length > 0)) {
				$description.addClass('open');

				if (carPlaceData != null && carPlaceData.length > 0) {
					const mapDescriptionTemplate = carriageMapDescription({
						carPlaceData,
						currencyCode: this.currencyCode,
						seatsGroup,
						groupId,
						carriage: this.maps[groupId][mapId].carriage,
						trainModel: this.maps[groupId][mapId].parent.model,
						isMobileTemplate: isMobile,
					});

					$description.find('.b-map-description__prices').append(mapDescriptionTemplate);

					const placeHoverToggle = ($currentPlace, off) => {
						const $currentMap = $(`[data-id="${$currentPlace.data('groupid')}"]`)
							.next('.b-carriage-maps__wrapper').find('.b-carriage-maps__container.open');

						const highlightPlaces = `${$currentPlace.data('places')}`.split(',');
						highlightPlaces.forEach(place => {
							const $place = $currentMap.find(`[data-number="${place}"]:not(.unavailable)`);
							if (off) {
								$place.removeClass('hovered');
							} else {
								$place.addClass('hovered');
							}
						});
					};
					const mouseOverHandler = function mouseOverHandler() {
						const $place = $(this);
						$place.hover(() => {
							placeHoverToggle($(this), false);
						}, () => {
							placeHoverToggle($(this), true);
						});
					};
					$description.find('.b-car-place').each(mouseOverHandler);

					if (isMobile && this.options.parent.mobileOfferPreview != null) {
						this.options.parent.mobileOfferPreview.renderCarPrice(mapDescriptionTemplate, mouseOverHandler);
					}
				}

				if (genderSeats.length > 0) {
					const genders = genderSeats.reduce((obj, item) => {
						obj[item.gender.uid] = item.gender.caption;
						return obj;
					}, {});
					_.sortBy(_.keys(genders)).forEach(k => {
						let text = '';
						let caption = '';
						switch (k) {
							case 'MALE':
								text = 'М';
								caption = L10N.get('trains.seatTypes.MALE');
								break;
							case 'FEMALE':
								text = 'Ж';
								caption = L10N.get('trains.seatTypes.FEMALE');
								break;
							case 'MIX':
								text = 'С';
								caption = L10N.get('trains.seatTypes.MIX');
								break;
							case 'NOT_SELECTED':
								text = _.filter(genderSeats, (el) => el.gender.uid === 'NOT_SELECTED').length;
								caption = L10N.get('trains.seatTypes.NOT_SELECTED');
								break;
						}
						$description.find('.b-map-description__genders').append(`<div class="b-gender"><div
						data-toggle="tooltip" data-placement="bottom" title="${caption}"
						class="b-gender-icon b-gender-icon-${k}">${text}</div>
						<div class="b-gender-caption">${caption}</div>
						</div>`);

						if (isMobile && this.options.parent.mobileOfferPreview != null) {
							this.options.parent.mobileOfferPreview.renderGenderDesc(`<div class="b-gender"><div
						class="b-gender-icon b-gender-icon-${k}">${text}</div>
						<div class="b-gender-caption">${caption}</div>
						</div>`);
						}
					});
				}

				$description.find('[data-toggle="tooltip"]').tooltip({
					container: 'body',
				});
			} else {
				$carriageMapContainer.find('.b-carriage-map__description').removeClass('open');
				$description.find('.b-map-description__genders').html('');
				if (isMobile && this.options.parent.mobileOfferPreview != null) this.options.parent.mobileOfferPreview.clearCarInfo();
			}

			// show selected map and update places
			const $areaContainer = $carriageMapContainer.find('.b-carriage-maps__area').empty();
			if (this.options.gds.uid === 'INMOB') {
				_.each(this.maps, (value, key) => {
					_.each(this.maps[key], (map) => {
						// temporarily remove other inmob maps style tags
						map.toggleInmobMapStyles(false);
					});
				});
			}

			_.each(this.maps[groupId], (map) => {
				$areaContainer.append(map.$el.hide());

				if (this.options.gds.uid === 'INMOB' && this.maps[groupId][mapId].options.providerCarSchemeId === map.options.providerCarSchemeId) {
					map.toggleInmobMapStyles(true);
				}
			});

			// show current carriage services
			const openedMapId = this.maps[groupId][mapId].$el.data('id');
			const carriageNumber = parseInt(this.maps[groupId][mapId]?.carriage.number, 10);

			const openedCarriage = $carriage.filter('.open');

			$('.b-train-ticket__wrapper.selected').removeClass('selected');
			openedCarriage.closest('.b-train-ticket__wrapper').addClass('selected');

			openedCarriage.find('.b-train__carriage-number span').each(function updateOpenedGroup() {
				$(this).removeClass('open');
				if (parseInt($(this).data('number'), 10) === carriageNumber) {
					$(this).addClass('open');
				}
			});

			openedCarriage.find('.b-train__carriage-services-group').each(function updateOpenedGroup() {
				$(this).removeClass('open');
				if ($(this).data('carriage-id') === openedMapId) {
					$(this).addClass('open');
				}
			});

			openedCarriage.find('.b-train__carriages-refundable-group').each(function updateOpenedGroup() {
				$(this).removeClass('open');
				if ($(this).data('carriage-id') === openedMapId) {
					$(this).addClass('open');
				}
			});

			openedCarriage.find('.b-train__carriage-gender').each(function updateOpenedGroup() {
				$(this).removeClass('open');
				if ($(this).data('carriage-id') === openedMapId) {
					$(this).addClass('open');
				}
			});

			// highlight current group by place number hover
			if (carPlaceData != null && carPlaceData.length > 0) {
				const highlightPlacesData = (currentNumber, off) => {
					$description.find('.b-car-place').each(function updateHover() {
						const $that = $(this);
						$that.removeClass('hovered');
						if (!off) {
							const places = `${$that.data('places')}`;
							if (places.split(',').indexOf(`${currentNumber}`) > -1) {
								$that.addClass('hovered');
							}
						}
					});
				};
				this.maps[groupId][mapId].$el.find('[data-number]:not(.place-sold_out):not(.unavailable)').off('hover').hover((e) => {
					const $currentTarget = $(e.currentTarget);
					const currentNumber = $currentTarget.data('number');
					highlightPlacesData(currentNumber, false);
				}, (e) => {
					const $currentTarget = $(e.currentTarget);
					const currentNumber = $currentTarget.data('number');
					highlightPlacesData(currentNumber, true);
				});
			}

			this.maps[groupId][mapId].$el.show();
			this.maps[groupId][mapId].delegateEvents().updateMap();

			this.openedMap = {
				groupId,
				mapId,
			};

			if (this.noSeatSelection) {
				const carriageView = this.maps[groupId][mapId];
				$carriageMapContainer.find('.b-map-description__prices').addClass('dn');
				if (this.isEmptyMap) {
					$carriageMapContainer.find(':not(.b-carriage__selected-variant)').addClass('dn');
					$carriageMapContainer.find('.b-carriage__selected-variant').addClass('border-top');
				}
				this.selectPlace({carriageView, carriage: carriageView.carriage});
			}

			return this;
		});
	},

	selectPlace(data) {
		const place = _.extend({
			carriageView: null,
			carriage: null,
			numbers: [],
		}, data);

		this.$el.find('.b-map-description__messages').empty();
		if (place.availableStatus != null && place.availableStatus !== 'DEFAULT') {
			let message = '';

			switch (place.availableStatus) {
				case 'MOTHER_AND_BABY_SELECTED':
					message = `<div class="b-message b-message-warning">
									${L10N.get('trains.seatStatus.MOTHER_AND_BABY_SELECTED')}
								</div>`;
					break;
				case 'WITH_CHILD_SELECTED':
					message = `<div class="b-message b-message-warning">
					${L10N.get('trains.seatStatus.WITH_CHILD_SELECTED')}
								</div>`;
					break;
				case 'SEATS_GROUP_SIZE':
					if (place.carriage == null || place.carriage.extraChildOnly !== true) {
						message = `<div class="b-message b-message-warning">${L10N.get('trains.seatStatus.SEATS_GROUP_SIZE')}</div>`;
					}
					break;
			}

			this.$el.find('.b-map-description__messages').html(message);
		}

		this.parent.selectPlace(place, this.noSeatSelection);
	},

	setSelectedGender(value) {
		return this.parent.setSelectedGender(value);
	},

	getSelectedGender() {
		return this.parent.getSelectedGender();
	},

	showMapByDefault(groupId) {
		const id = this.carriagesGroups.map[groupId][0]._id;
		// drop prev selected gender
		this.options.parent.setSelectedGender(null);
		this.showToggleMap(this._getMapId(null, {
			id,
			groupId,
		}));
	},

});
