// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import noUiSlider from 'nouislider';
import $ from 'jquery';

const Range = Widget.extend({

	template,

	events: {
		'change .b-input__value': 'change',
	},

	ui: {
		input: '.b-range__input-interective',
	},

	initialize() {
		this.render();
		noUiSlider.create(this.ui.input[0], {
			start: [this.options.min, this.options.max],
			connect: true,
			range: {
				min: this.options.min,
				max: this.options.max,
			},
		});

		const lower = $('<span class="noUi-lower-value" />');
		const upper = $('<span class="noUi-lower-upper" />');

		const lowerHandler = this.$('.noUi-handle-lower');
		const upperHandler = this.$('.noUi-handle-upper');

		lowerHandler.append(lower);
		upperHandler.append(upper);

		const formatter = this.options.formatter ? Range[this.options.formatter] : null;

		this.slideTimer = null;

		this.ui.input[0].noUiSlider.on('update', (values, handle) => {
			if (handle) {
				upper.text(_.isFunction(formatter) ? formatter(values[handle]) : values[handle]);
				// valueInput.value = values[handle];
			} else {
				lower.text(_.isFunction(formatter) ? formatter(values[handle]) : values[handle]);
				// valueSpan.innerHTML = values[handle];
			}
			if (this.model) {
				clearInterval(this.slideTimer);
				this.slideTimer = setTimeout(() => {
					this.change([parseFloat(values[0]), parseFloat(values[1])]);
					this.slideTimer = null;
				}, 200);
			}
		});

		if (this.$el.hasClass('b-filters-hotels-range')) {
			this.ui.input[0].noUiSlider.on('slide', this.setOffsetHandles.bind(this));
		}
	},

	/* change: _.debounce(function a(values) {
		this.model.set(this.options.bindingProperty, values, { isTriggered: true });
	}, 200),*/

	change(values) {
		this.model.set(this.options.bindingProperty, values, { isTriggered: true });
	},

	applyBinding(model) {
		this.model = model;
		this.listenTo(model, 'reset:range', (values) => {
			this.ui.input[0].noUiSlider.set(values);
		});
		// this.ui.input[0].noUiSlider.set(this.model.get(this.options.bindingProperty));
		this.listenTo(model, `change:${this.options.bindingProperty}`, (thisModel, value, options) => {
			if (options && options.isTriggered) {
				return;
			}

			if (this.$el.hasClass('b-filters-hotels-range')) {
				this.ui.input[0].noUiSlider.updateOptions({
					animate: false,
				});

				this.ui.input[0].noUiSlider.set(value);
				this.setOffsetHandles(value, 0);
				this.setOffsetHandles(value, 1);
			} else {
				this.ui.input[0].noUiSlider.set(value);
			}
		});
	},

	setOffsetHandles(v, handle) {
		if (this.ui.input[0].noUiSlider.options === false) {
			this.ui.input[0].noUiSlider.updateOptions({
				animate: true,
			});
		}

		const lower = this.$el.find('.noUi-lower-value');
		const upper = this.$el.find('.noUi-lower-upper');

		const totalWidth = this.$('.noUi-base').outerWidth();
		const offset = this.$('.noUi-target').offset().left;

		const element = (handle === 0) ? lower : upper;
		const elementWidth = element.outerWidth();
		const elementPosition = element.closest('.noUi-origin').offset().left;
		const elementCenter = (elementWidth / 2) - 6;

		if (handle === 0) {
			if (elementPosition < 6) {
				const lowerOffset = totalWidth - Math.abs(elementPosition) - offset;
				if (lowerOffset > elementCenter) {
					element.css({
						left: `-${elementCenter}px`,
						right: 'unset',
					});
				} else {
					element.css({
						left: `-${lowerOffset}px`,
						right: 'unset',
					});
				}
			} else {
				let lowerOffset = parseInt(elementCenter - (elementPosition - 6), 10);
				if (lowerOffset < 0) {
					lowerOffset = 0;
				}
				element.css({
					left: 'unset',
					right: `-${lowerOffset}px`,
				});
			}
		} else {
			let upperOffset = elementPosition;
			if (upperOffset > 0) {
				upperOffset = offset - elementPosition;
			} else {
				upperOffset = Math.abs(elementPosition - offset);
			}

			if (upperOffset < 196) {
				if (upperOffset > elementCenter) {
					element.css({
						right: `-${elementCenter}px`,
						left: 'unset',
					});
				} else {
					element.css({
						right: `-${upperOffset}px`,
						left: 'unset',
					});
				}
			} else {
				upperOffset = parseInt(totalWidth - upperOffset, 10);
				element.css({
					right: 'unset',
					left: `-${upperOffset}px`,
				});
			}
		}
	},

});

export default Range;
