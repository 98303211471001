// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import suggestionTemplate from './suggestion.ejs';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';
import 'corejs-typeahead/dist/typeahead.jquery';
import axios from 'axios';

export default Widget.extend({

	validationTemplate,

	template,

	viewName: 'b-employee-autocomplete',

	initialize(options) {
		this.options = options;
		this.options.label = this.options.label || '';
		this.options.placeholder = this.options.placeholder || '';
		this.options.deletableField = (this.options.deletableField === true);
		this.queryHandler = this.queryHandler.bind(this);
		this.render();

		this.$typeahead = this.$('.b-employee-autocomplete__value');
		this.$typeahead.typeahead({
			hint: false,
			highlight: false,
			minLength: 0,
			limit: 1,
			classNames: {
				menu: 'b-employee-autocomplete__items',
				wrapper: 'b-employee-autocomplete__wrapper',
				input: 'b-employee-autocomplete__input',
				hint: 'b-employee-autocomplete__hint',
				dataset: 'b-employee-autocomplete__dataset',
				suggestion: 'b-employee-autocomplete__item',
				selectable: 'b-employee-autocomplete__selectable',
				empty: 'b-employee-autocomplete__empty',
				open: 'b-employee-autocomplete__open',
				cursor: 'b-employee-autocomplete__cursor',
				highlight: 'b-employee-autocomplete__highlight',
			},
		}, {
			limit: 100,
			name: 'employee',
			source: (q, cb, async) => {
				clearTimeout(this.timer);
				this.timer = setTimeout(this.queryHandler, STATE.QUERY_DELAY, q, cb, async);
			},
			display: (item) => `${item.caption}`,
			async: true,
			templates: {
				suggestion: suggestionTemplate,
			},
		});
	},

	queryHandler(q, cb, async) {
		axios.get('/midoffice/ibecorp-b2b/autocomplete/organization-employees', {
			params: {
				pattern: encodeURI(q),
				limit: null,
				uid: this.options.client,
			},
		}).then((resp) => {
			let response = resp.data.result;
			if (_.isFunction(this.options.formatter)) {
				response = this.options.formatter.call(this, response);
			}
			async(response.reduce((result, item) => {
				result.push(item);
				return result;
			}, []));
			this.$('[data-toggle="tooltip"]').tooltip({
				container: 'body',
				trigger: 'hover',
			});
		});
	},

	applyBinding(model) {
		this.$typeahead.on('typeahead:change', (ev, suggestion) => {
			if (suggestion.replace(/\s/g, '').length === 0) {
				this.model.set(this.options.bindingProperty, null);
			}
		});

		this.$typeahead.on('typeahead:select', (ev, suggestion) => {
			this.model.set(this.options.bindingProperty, suggestion);
		});

		this.listenTo(model, `validationError:${this.options.bindingProperty}`, (value) => {
			if (value != null) {
				this.$el.addClass('validation-error');
			}
		});

		this.listenTo(model, `change:${this.options.bindingProperty}`, (__model, item) => {
			this.$typeahead.typeahead('val', (item || {}).caption);
		});

		this.listenTo(model, `destroy`, () => this.$el.tooltip('dispose'));

		if (_.isObject(model.get(this.options.bindingProperty))) {
			this.$typeahead.typeahead('val', model.get(this.options.bindingProperty).caption);
		}
	},

});
