module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var exchangeParams = exchangeParams || {};
;
__p += '\n\n<div class="b-exchange-step2">\n    <div class="b-exchange-step2__header">' +
((__t = ( L10N.get('avia.ticketExchange') )) == null ? '' : __t) +
'</div>\n    ';
 _.each(exchangeParams.selectedServices, function(service) { ;
__p += '\n        <div class="b-exchange__traveller-service">\n            <span class="b-exchange__traveller-service__ticketNumber">' +
((__t = ( service.ticketNumber )) == null ? '' : __t) +
'</span>\n            <span class="b-exchange__traveller-service__fullName">' +
((__t = ( _.map(service.travellers, function(t) { return t.fullName }).join(' | '))) == null ? '' : __t) +
'</span>\n        </div>\n    ';
 }) ;
__p += '\n</div>\n';

}
return __p
}