// eslint-disable-next-line banned-modules
'use strict';

import $ from 'jquery';

class SideClickOutsideAdapter {
	_isSideOutsideClickHandlerApplied = false;

	constructor(params = {}) {
		this._isSideOutsideClickHandlerApplied = params.applyHandlerOnStart || false;
		if (this._isSideOutsideClickHandlerApplied) {
			this.addListener();
		}
	}

	handleSideOutsideClickHandler = () => {
		if (this._isSideOutsideClickHandlerApplied) {
			this.removeListener();
		} else {
			this.addListener();
		}
	};

	addListener = () => this.handleListener(true);

	removeListener = () => this.handleListener(false);

	handleListener = (add = false) => {
		window[add ? 'addEventListener' : 'removeEventListener']('click', this.contentSideOutsideClickHandler);
		this._isSideOutsideClickHandlerApplied = add;
	};

	contentSideOutsideClickHandler = (e) => {
		const $target = $(e.target);
		const $side = $('.l-layout .l-layout__content-side');
		if (!$target.closest($side).length && !$target.closest('.js-show-content-side').length) {
			$('.l-layout').removeClass('show-filters');
		}
	};
}

export default SideClickOutsideAdapter;
