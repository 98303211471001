// eslint-disable-next-line banned-modules
'use strict';

import BaseView from '@/classes/base.view';
import Widgets from '@/widgets/widgets';

import template from './template.ejs';
import $ from 'jquery';

export default BaseView.extend({

	template,

	initialize() {
		this.options = _.extend(this.options, {
			showInputValue: this.model.options.showInputValue === true,
			showRange: this.model.options.showRange === true,
			activateWidgetByToggle: this.model.options.activateWidgetByToggle || false,
			toggleLabel: this.model.options.toggleLabel || L10N.get('filters.activate'),
		});

		this.render();
		this.addEventListeners();
	},

	updateValues() {
		const $inputs = this.$el.find('.b-input__value');
		_.each($inputs, (el) => {
			const $el = $(el);
			let value;
			if ($el.parent().hasClass('b-filters__range-min')) {
				value = this.getValue(this.model.get('getMinFromValue') ? this.model.get('value')[0] : this.model.get('min'));
				this.model.set('__min', value);
				if (this.model.get('__minManualTyping')) return;
				$el.val(value);
			}
			if ($el.parent().hasClass('b-filters__range-max')) {
				value = this.getValue(this.model.get('getMaxFromValue') ? this.model.get('value')[1] : this.model.get('max'));
				this.model.set('__max', value);
				if (this.model.get('__maxManualTyping')) return;
				$el.val(value);
			}
		});
	},

	getValue(value) {
		if (_.isFunction(this.options.formatter)) {
			return this.options.formatter.call(this, value);
		}
		return value && parseInt(value) || '';
	},

	addEventListeners() {
		if (this.options.showInputValue === true) {
			this.start = {
				min: this.model.get('getMinFromValue') ? this.model.get('value')[0] : this.model.get('min'),
				max: this.model.get('getMaxFromValue') ? this.model.get('value')[1] : this.model.get('max'),
			};

			const getValue = (_.isFunction(this.options.formatter)) ? this.options.formatter.bind(this) : (value) => value && parseInt(value) || '';
			const change = ($element, increment) => {
				const value = this.getValue($element.val()) + (increment || 1);

				if ($element.parent().hasClass('b-filters__range-min')) {
					this.model.set('__min', value);
				} else if ($element.parent().hasClass('b-filters__range-max')) {
					this.model.set('__max', value);
				}

				$element.val(value);
			};

			this.model.set('__min', this.start.min);
			this.model.set('__max', this.start.max);

			this.listenTo(this.model, 'change:__min change:__max', () => {
				const min = getValue(this.model.get('__min'));
				const max = getValue(this.model.get('__max'));

				this.model.set('value', [Number(min), Number(max)]);
			});
			if (this.options.showRange === true) {
				this.listenTo(this.model, 'change:value', (model, value) => {
					if (!_.isEmpty(value)) {
						this.model.set('__min', getValue(value[0]));
						this.model.set('__max', getValue(value[1]));
					}
				});
			}
			const widgetContainer = this.$el.find('.js-widget-container');
			if (this.model.get('active')) widgetContainer.removeClass('dn');

			this.listenTo(this.model, 'change:active', (model, value) => {
				widgetContainer[value ? 'removeClass' : 'addClass']('dn');

				/*
					Должно работать в ситуациях, когда мы переводим свитчер в off и
					сбрасываем значения на дефолтовые.
				*/
				if (!value) {
					this.model.set('__min', this.model.get('min'));
					this.model.set('__max', this.model.get('max'));
				}
			});

			this.$el.find('.b-input__value').on('change mousedown mouseup', (e) => {
				const $target = $(e.currentTarget);
				const value = getValue($target.val());

				$target.val(value);
			}).on('keydown', (e) => {
				const keyCode = e.keyCode || e.which;
				const arrows = { left: 37, up: 38, right: 39, down: 40 };
				const $target = $(e.currentTarget);
				const value = getValue($target.val());
				if ($target.parent().hasClass('b-filters__range-min')) {
					this.model.set('__minManualTyping', true);
					this.model.set('getMinFromValue', true);
				} else if ($target.parent().hasClass('b-filters__range-max')) {
					this.model.set('__maxManualTyping', true);
					this.model.set('getMaxFromValue', true);
				}

				switch (keyCode) {
					case arrows.up:
						if (value < this.start.max) {
							change($target, 1);
						}
						break;
					case arrows.down:
						if (value > this.start.min) {
							change($target, -1);
						}
						break;
				}
			}).on('blur', (e) => {
				const $target = $(e.currentTarget);
				if ($target.parent().hasClass('b-filters__range-min')) {
					this.model.set('__minManualTyping', false);
				} else if ($target.parent().hasClass('b-filters__range-max')) {
					this.model.set('__maxManualTyping', false);
				}
			})
				.trigger('change');
		}
	},

	render(...args) {
		if (this.model.attributes.formatter) {
			Widgets.Range[this.cid] = this.model.attributes.formatter;
			this.model.set('formatter', this.cid);
		} else {
			this.model.attributes.formatter = null;
		}
		BaseView.prototype.render.apply(this, args);
	},

});
