// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import FlightView from './b-schedule-flight';
import template from './template.ejs';
import Model from './model';
import $ from 'jquery';
import axios from 'axios';

export default BaseView.extend({

	template,

	events: {
		'click .p-search__submit': 'search',
		'click .b-search-form__select-type-label-wrapper': 'toggleType',
		'click .b-search-form__select-type-item': 'selectType',
	},

	ui: {
		flightLegs: '.p-search__direction',
		flightTypeSelect: '.b-search-form__select-type-list',
		flightTypeSelectItem: '.b-search-form__select-type-item',
		flightTypeSelectLabel: '.b-search-form__select-type-label',
		submit: '.p-search__submit',
	},

	attachmentViews: null,

	initialize() {
		this.model = new Model();
		this.render();
	},

	toggleType(e) {
		const item = $(e.currentTarget);
		const container = item.parents('.b-search-form__select-type:first');
		const select = container.find('.b-search-form__select-type-list');
		select.isActive = true;
		select.slideToggle(300);
	},

	selectType(e) {
		const item = $(e.currentTarget);
		const container = item.parents('.b-search-form__select-type:first');
		const select = container.find('.b-search-form__select-type-list');
		const selectLabel = container.find('.b-search-form__select-type-label');
		selectLabel.text(item.text());
		if (this.model && this.model.changeType) {
			this.model.changeType(item.attr('data-type'));
		}
		select.slideUp(300);
	},

	search(e) {
		if (e) {
			e.preventDefault();
		}
		STATE.showLoader();
		const resultContainer = this.$el.find('.p-schedule-results');
		resultContainer.html('');
		this.disableElements(e);
		axios.post('/midoffice/ibecorp-b2b/avia/searchFlightsSchedule', this.model.toJSON()).then(result => {
			result.data.result.flights.forEach(f => {
				const flightView = new FlightView({
					flight: f,
				});
				resultContainer.append(flightView.$el);
			});
			STATE.hideLoader();
		});
	},

});
