/* eslint-disable banned-modules */
'use strict';

import './style.less';
import axios from 'axios';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

export default BaseView.extend({

	template,

	initialize(options) {
		this.train = options.train;
		STATE.showLoader();
		axios.post('/midoffice/ibecorp-b2b/rail/train/route', {
			parameters: this.train,
		}).then(result => {
			STATE.hideLoader();
			const routes = result.data.result[0];
			const routesTemplate = this.template(Object.assign({}, routes));
			const departureLocation = this.train.segments[0].trainDepartureLocation || this.train.segments[0].departureLocation.caption;
			const arrivalLocation = this.train.segments[0].trainArrivalLocation || this.train.segments[0].arrivalLocation.caption;

			const titleParts = [L10N.get('trains.trainRoute'), `${departureLocation} - ${arrivalLocation}`];
			if (this.train.number != null) {
				titleParts[0] += ` ${this.train.number}`;
			}

			const popup = new Widgets.Popup({
				overlayFixed: true,
				content: routesTemplate,
				title: titleParts.join(', '),
				type: 'info',
				actions: [{
					label: L10N.get('trains.back'),
					action: () => {
						popup.hide();
					},
				}],
				classes: 'b-train-route-popup',
			});
			popup.show();
		});
	},

});
