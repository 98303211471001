module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var reservationStatuses = reservationStatuses || []
;
__p += '\r\n<div class="p-cabinet-orders">\r\n	<div class="b-block-container">\r\n		<div class="b-block-container__header">\r\n			<div class="b-block-container__header-label">' +
((__t = ( L10N.get("cabinet.orders.title") )) == null ? '' : __t) +
'</div>\r\n		</div>\r\n		<div class="b-block-container__top-controls">\r\n			<div class="b-block-container__element b-block-container__element-dates">\r\n				<div class="p-search__options-date-element">\r\n					<div class="p-search__options-date-label">' +
((__t = ( L10N.get("cabinet.orders.period") )) == null ? '' : __t) +
'</div>\r\n					<span class="js-widget"  onclick=\'return new Widgets.Datepicker({ bindingProperty: "startDate", dateFormat: "DD MMM YYYY", removable: true, noMinDate: true, placeholder: L10N.get("searchForm.departureDate") })\'></span>\r\n				</div>\r\n				<div class="p-search__options-date-element">\r\n					<span class="js-widget"  onclick=\'return new Widgets.Datepicker({ bindingProperty: "endDate", dateFormat: "DD MMM YYYY", noMinDate: true, depend: "startDate", diff: 7, removable: true, placeholder: L10N.get("searchForm.departureDate") })\'></span>\r\n				</div>\r\n			</div>\r\n\r\n			<div class="b-block-container__element b-block-container__element-dates">\r\n				<div class="p-search__options-date-element">\r\n					<div class="p-search__options-date-label">' +
((__t = ( L10N.get("cabinet.orders.issuePeriod") )) == null ? '' : __t) +
'</div>\r\n					<span class="js-widget"  onclick=\'return new Widgets.Datepicker({ bindingProperty: "issueStartDate", dateFormat: "DD MMM YYYY", removable: true, noMinDate: true, placeholder: L10N.get("searchForm.departureDate") })\'></span>\r\n				</div>\r\n				<div class="p-search__options-date-element">\r\n					<span class="js-widget"  onclick=\'return new Widgets.Datepicker({ bindingProperty: "issueEndDate", dateFormat: "DD MMM YYYY", noMinDate: true, depend: "issueStartDate", diff: 7, removable: true, placeholder: L10N.get("searchForm.departureDate") })\'></span>\r\n				</div>\r\n			</div>\r\n			';
 if (STATE.checkSiteType('B2B')) { ;
__p += '\r\n				<div class="b-block-container__element">\r\n					<span class="js-widget"  onclick=\'return new Widgets.CompanyAutocomplete({bindingProperty: "client", label: L10N.get("cabinet.finance.client"), deletableField: true, placeholder: L10N.get("cabinet.finance.allClients") })\'></span>\r\n				</div>\r\n\r\n				<div class="b-block-container__element">\r\n					<span class="js-widget"  onclick=\'return new Widgets.CompanyAutocomplete({bindingProperty: "subagency", label: L10N.get("cabinet.orders.subAgencies"), deletableField: true, placeholder: L10N.get("cabinet.orders.allSubAgencies"), useAcl: true, findOnlyAgencies: true})\'></span>\r\n				</div>\r\n			';
 } ;
__p += '\r\n\r\n			<div class="b-block-container__element b-block-container__element--search">\r\n				<span class="js-widget"  onclick=\'return new Widgets.Input({bindingProperty: "pattern", label: L10N.get("cabinet.finance.pattern"), placeholder: L10N.get("cabinet.finance.patternRemark"), classes: "b-input__search" })\'></span>\r\n                <div class="b-block-container__filters-trigger"></div>\r\n			</div>\r\n\r\n			';
 if (_.size(reservationStatuses) !== 0) { ;
__p += '\r\n				<div class="b-block-container__element">\r\n					<span class="js-widget"  onclick=\'return new Widgets.FinanceAutocomplete({bindingProperty: "reservationStatus", values: "' + reservationStatuses + '", deletableField: true, label: L10N.get("cabinet.orders.orderStatus"), placeholder: L10N.get("cabinet.orders.chooseStatus") })\'></span>\r\n				</div>\r\n			';
 } ;
__p += '\r\n\r\n            <div class="b-block-container__element">\r\n                <span class="js-widget"  onclick=\'return new Widgets.ContactAutocomplete({bindingProperty: "contactPerson", deletableField: true, limit: 100, displayType: "fullName", label: L10N.get("bookingForm.customer"), placeholder: L10N.get("bookingForm.customer") })\'></span>\r\n            </div>\r\n\r\n			<div class="b-block-container__element">\r\n				<div class="p-search__submit">\r\n					<a href="#" class="p-search__submit-button">' +
((__t = ( L10N.get("cabinet.finance.apply") )) == null ? '' : __t) +
'</a>\r\n				</div>\r\n			</div>\r\n		</div>\r\n\r\n		<div class="b-block-container__content-orders">\r\n			<div class="b-block-container__content"></div>\r\n			<div class="b-block-container__footer"></div>\r\n		</div>\r\n	</div>\r\n</div>\r\n';

}
return __p
}