// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';
import template from './template.ejs';
import Pikaday from 'pikaday';
import $ from 'jquery';

export default Widget.extend({

	validationTemplate,

	template,

	ui: {
		input: '.b-datepicker__field',
		icon: '.b-datepicker__icon',
	},

	events: {
		'click .b-datepicker__open': 'show',
		'click .b-datepicker__remove': 'removeDate',
	},

	initialize() {
		const time = new Time();
		this.dateFormat = this.options.dateFormat || 'DD MMM';
		this.options.label = this.options.label || '';
		this.render();
		this.date = null;

		let minDate = this.options.noMinDate ? null : new Date();
		if (this.options.minDate) {
			minDate = this.options.minDate;
		} else if (this.options.depend && this.options.dependOffset) {
			const offset = this.options.dependOffset.split(' ');

			if (offset.length === 2) {
				minDate = new Time().getMoment()
					.add(parseInt(offset[0]), offset[1])
					.toDate();
			}
		}

		if (this.options.container != null && _.isString(this.options.container)) this.options.container = $(this.options.container).get(0);

		this.picker = new Pikaday({
			format: this.dateFormat,
			field: this.ui.input[0],
			onSelect: (date) => {
				this.date = new Time(date);
				if (this.model) {
					this.model.set(this.options.bindingProperty, new Time(date).toServerDate());
					this.removable();
				}

				if (this.options.bindingProperty !== 'dateTo') {
					return;
				}

				const routes = this.getSearchRoutesModel();
				const index = _.findIndex(routes, (r) => r === this.model);

				_.each(routes.slice(index), (r) => {
					if (!r.get('dateTo')) {
						return;
					}

					const routeDate = new Time(r.get('dateTo')).getMoment();
					if (this.date.getMoment().isAfter(routeDate)) {
						r.set('dateTo', null);
					}
				});
			},
			onOpen: () => {
				if (this.options.bindingProperty !== 'dateTo') {
					return;
				}

				const routes = this.getSearchRoutesModel();
				const index = _.findIndex(routes, (r) => r === this.model);

				if (index <= 0) {
					return;
				}

				const lastRoute = routes[index - 1];

				// .add(0, 'd') - start date in the picker !!!
				if (lastRoute.get('dateTo')) {
					const _minDate = new Time(lastRoute.get('dateTo'))
						.getMoment()
						.add(0, 'd')
						.toDate();
					this.picker.setMinDate(_minDate);
				}
			},
			minDate,
			firstDay: 1,
			showDaysInNextAndPreviousMonths: this.options.showDaysInNextAndPreviousMonths != null ?
				this.options.showDaysInNextAndPreviousMonths : true,
			enableSelectionDaysInNextAndPreviousMonths: true,
			numberOfMonths: this.options.numberOfMonths || 1,
			container: this.options.container,
			bound: this.options.bound,
			keyboardInput: this.options.keyboardInput != null ? this.options.keyboardInput : true,
			i18n: {
				months: time.getMoment().localeData().months().standalone || time.getMoment().localeData().months(),
				weekdays: time.getMoment().localeData().weekdays().standalone || time.getMoment().localeData().weekdays(),
				weekdaysShort: time.getMoment().localeData().weekdaysShort().standalone || time.getMoment().localeData().weekdaysShort(),
			},
		});

		if (this.options.disabled) {
			this.disable(true);
		}
	},

	getSearchRoutesModel() {
		const model = STATE.getSearchModel();
		if (model && model.isComplex() && model.has('routes') && model.get('routes').length >= 2) {
			return model.get('routes');
		}

		return [];
	},

	removable() {
		if (this.options.removable) {
			this.ui.icon.addClass('b-datepicker__remove');
			this.ui.icon.removeClass('b-datepicker__open');
		}
	},

	unremovable() {
		this.ui.icon.removeClass('b-datepicker__remove');
		this.ui.icon.addClass('b-datepicker__open');
	},

	removeDate() {
		this.picker.setDate(null);
		this.model.set(this.options.bindingProperty, null);
		this.date = null;
		this.unremovable();
	},

	disable(disabled) {
		this.disabled = disabled;
		this.$el.find('.p-search__direction-date-input').val('').attr('disabled', disabled);
	},

	show() {
		this.picker.show();
	},

	applyBinding(model) {
		this.model = model;

		this.listenTo(model, `validationError:${this.options.bindingProperty}`, () => {
			this.$el.addClass('validation-error');
		});

		this.listenTo(model, `changeSettings:${this.options.bindingProperty}`, (settings) => {
			if (!_.isObject(settings)) {
				return;
			}

			if (!_.isUndefined(settings.maxDate)) {
				const date = settings.maxDate;
				this.picker.setMaxDate(date);

				if (date != null && this.model.get(this.options.bindingProperty) && new Time(date).getMoment().isBefore(this.date.getMoment())) {
					this.picker.setDate(null);
				}
			}
		});

		this.listenTo(model, `change:${this.options.bindingProperty}`, (__model, date) => {
			this.date = new Time(date);
			this.picker.setDate(date);
		});

		this.$(this.ui.input[0]).on('change', (e) => {
			const target = this.$(e.target);
			if (target.val().replace(/\s/g, '').length === 0) {
				this.model.set(this.options.bindingProperty, null);
			}
		});
		if (model.has(this.options.bindingProperty)) {
			this.picker.setDate(model.get(this.options.bindingProperty));
			this.date = new Time(model.get(this.options.bindingProperty));
		}
		this.dependOn();
	},

	dependOn() {
		if (this.options.depend) {
			this.listenTo(this.model, `change:${this.options.depend}`, (__model, date) => {
				this.applyDependency(new Time(date));
			});
			if (this.model.has(this.options.depend)) {
				this.applyDependency(new Time(this.model.get(this.options.depend)));
			}
		}
		if (this.options.dependTime) {
			this.listenTo(this.model, `change:${this.options.bindingProperty}`, (__model) => {
				const currnetModel = __model.get(this.options.bindingProperty);
				if (currnetModel === undefined) {
					this.model.unset(this.options.dependTime);
				}
			});
		}
		if (this.options.dependent) {
			this.listenTo(this.model, `change:${this.options.dependent}`, () => {
				this.applyDependency();
			});
			if (this.model.has(this.options.dependent)) {
				this.applyDependency();
			}
		}
	},

	applyDependency(dependDate) {
		if (dependDate) {
			const dependDateMoment = dependDate.getMoment();
			const searchModel = STATE.getSearchModel();

			if (this.options.dependOffset) {
				const offset = this.options.dependOffset.split(' ');

				if (offset.length === 2) {
					dependDateMoment.add(parseInt(offset[0]), offset[1]);
				}
			}

			this.picker.setMinDate(dependDateMoment.toDate());
			this.picker.gotoDate(dependDateMoment.toDate());

			if (this.date != null && searchModel.get('routeType') === 'ROUNDTRIP') {
				const dateMoment = this.date.getMoment();
				if (dependDateMoment.isAfter(dateMoment)) {
					dependDateMoment.add(parseInt(this.options.diff, 10), 'd');
					this.picker.setDate(dependDateMoment.toDate());
				}
			}
		}

		if (this.options.showDependDate) this.showDependDate();
		if (this.options.showDependentDate) this.showDependentDate();
	},

	showDependDate() {
		if (!this.options.depend || !this.model) return;
		this.picker.setStartRange(new Time(this.model.get(this.options.depend)).toDate());
		this.picker.draw();
	},

	showDependentDate() {
		if (!this.options.dependent || !this.model) return;
		this.picker.setEndRange(new Time(this.model.get(this.options.dependent)).toDate());
		this.picker.draw();
	},
});
