module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-header__info-currency">\n    <p class="b-header__info-currency-text">\n        ' +
((__t = (L10N.get("header.availableFunds"))) == null ? '' : __t) +
': ' +
((__t = ( balanceInfo.availableFunds )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(balanceInfo.availableFundsCurrency) )) == null ? '' : __t) +
'\n        ';
 if (balanceInfo.availableFundsSubagentCurrency != null && balanceInfo.availableFundsCurrency !== balanceInfo.availableFundsSubagentCurrency) { ;
__p += '\n            <span class="b-header__info-currency-text b-header__info-currency-text_color">\n                ' +
((__t = ( balanceInfo.availableFundsSubagent )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(balanceInfo.availableFundsSubagentCurrency) )) == null ? '' : __t) +
'\n            </span>\n        ';
 } ;
__p += '\n    </p>\n</div>\n';

}
return __p
}