// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';
import View from './index';

const Model = BaseModel.extend({

	initialize() {
		const temp = Object.assign({
			countryCode: STATE.getDefaultCountryCode(),
			cityCode: '',
			number: '',
		}, this.attributes);
		this.attributes = {
			phones: temp,
		};
	},

	toJSON() {
		return this.get('phones');
	},

	View,

});

export default Model;
