module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


  var unitStatus = (unit && unit.status && unit.status.uid) || null;
  var unitStatusClsName = view.getStatusClassNameSuffix(unitStatus);
  var authorizationSendPersons = unit.authorizationSendPersons || [];
;
__p += '\n<div class=\'b-cabinet-order-auth-step-unit b-cabinet-order-auth-step-unit--' +
((__t = ( unitStatusClsName )) == null ? '' : __t) +
'\'>\n  <div class="b-cabinet-order-auth-step-unit__step-line-container">\n    <div class="b-cabinet-order-auth-step-unit__step-line-point"></div>\n    <div class="b-cabinet-order-auth-step-unit__step-line-tail"></div>\n    <div class="b-cabinet-order-auth-step-unit__step-line-connect"></div>\n  </div>\n  <article class="b-cabinet-order-auth-step-unit__content-container">\n    ';
 if (unitStatus !== null) { ;
__p += '\n      ' +
((__t = ( require('./template-status-chip.ejs')({ unitStatus: unit.status, operationDate: unit.operationDate }) )) == null ? '' : __t) +
'\n    ';
 } ;
__p += '\n    ';
 if (unit.ruleDescription) { ;
__p += '\n      <div class="b-cabinet-order-auth-step-unit__rule-descr-container">\n        <div class="b-cabinet-order-auth-step-unit__rule-descr-title">\n          ' +
((__t = ( L10N.get("cabinet.orders.authConditions") )) == null ? '' : __t) +
'\n        </div>\n        <p class="b-cabinet-order-auth-step-unit__rule-descr">\n          ' +
((__t = ( unit.ruleDescription )) == null ? '' : __t) +
'\n        </p>\n      </div>\n    ';
 } ;
__p += '\n    ';
 if (authorizationSendPersons.length) { ;
__p += '\n      <ul class="b-cabinet-order-auth-step-unit__auth-persons-list">\n        ';
 _.each(authorizationSendPersons, function(person) { ;
__p += '\n          <li class="b-cabinet-order-auth-step-unit__auth-persons-list-item">\n            ';

              var isAuthorizer = !!(unit.authorizer && unit.authorizer.caption && person.caption === unit.authorizer.caption);
            ;
__p += '\n            <span\n              class=\'b-cabinet-order-auth-step-unit__auth-person-name ' +
((__t = ( isAuthorizer ? "b-cabinet-order-auth-step-unit__auth-person-name--is-authorizer" : "" )) == null ? '' : __t) +
'\'\n            >\n              ' +
((__t = ( person && person.caption || "" )) == null ? '' : __t) +
'\n            </span>\n          </li>\n        ';
 }) ;
__p += '\n      </ul>\n    ';
 } ;
__p += '\n  </article>\n</div>';

}
return __p
}