module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


_.each(routes, function(route, i){
;
__p += '\r\n	<li class="b-breadcrumbs__item ' +
((__t = ( i == 0 ? ('b-breadcrumbs__item_' + routeType) : '' )) == null ? '' : __t) +
'" data-route="' +
((__t = (route)) == null ? '' : __t) +
'">\r\n		' +
((__t = (L10N.get('pages.' + route))) == null ? '' : __t) +
'\r\n	</li>\r\n';

});
;


}
return __p
}