module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div>\n	<div class="b-error-popup__message">\n		' +
((__t = ( errorMessage.replace(/\n/ig, '<br/>') )) == null ? '' : __t) +
'\n	</div>\n\n	';
 if (exceptionStackTrace != null || exceptionMessage != null) { ;
__p += '\n		<div class="b-error-popup__more-wrapper" style="display: none;">\n			<div class="toggle-b-error-popup__more">\n				<div class="b-error-popup__more-in">\n					<em class="">' +
((__t = ( L10N.get('Widgets.popup.clickMessage') )) == null ? '' : __t) +
'</em><br /><br />\n					' +
((__t = ( _.escape(exceptionMessage).replace(/\n/ig, '<br/>') )) == null ? '' : __t) +
'\n					' +
((__t = ( _.escape(exceptionStackTrace).replace(/\n/ig, '<br/>') )) == null ? '' : __t) +
'\n				</div>\n			</div>\n		</div>\n	';
 } ;
__p += '\n</div>';

}
return __p
}