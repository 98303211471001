// eslint-disable-next-line banned-modules
'use strict';

import BasePageView from '@/pages/base.page-view';
import TicketView from '@/blocks/elements/b-ticket/index';
import TicketModel from '@/blocks/elements/b-ticket/model';

import FiltersView from '@/blocks/elements/b-filters/b-filters-avia/index';
import SortingView from '@/blocks/elements/b-sorting/index';

import AviaSearchFormView from '@/blocks/elements/b-ticket/b-ticket-search-form/index';
import FiltersCollection from '@/blocks/elements/b-filters/b-filters-avia/collection';
import MatrixControlsView from '@/blocks/elements/b-matrix/b-matrix-controls/index';
import CurrentDateMatrix from '@/blocks/elements/b-matrix/b-matrix-current-date';
import NearestDatesMatrix from '@/blocks/elements/b-matrix/b-matrix-nearest-dates';

import TicketPopupOffers from '@/blocks/elements/b-ticket/b-ticket-popup-offers/b-ticket-popup-offers';
import TicketModalOffersView from '@/blocks/elements/b-ticket/b-ticket-popup-offers/index';

import ResultView from './view';
import SearchRouteModel from '@/blocks/elements/b-search-route/model';
import SideClickOutsideAdapter from '@/blocks/utils/b-side-click-outside-adapter';

import TemplateExchangeBlockStep2 from '@/blocks/elements/b-exchange-modal/template-exchange-block-step2.ejs';

import $ from 'jquery';
import axios from 'axios';
import Backbone from 'backbone';

const TicketPageView = BasePageView.extend({

	tickets: new Backbone.Collection(),

	ui: {
		controls: '.l-layout__content-controls',
		content: '.b-tickets-container',
		sidebar: '.l-layout__content-side',
		top: '.l-layout__top',
	},

	preinitialize(options) {
		BasePageView.prototype.preinitialize.call(this, options);
		this.sideOutsideClickAdapter = new SideClickOutsideAdapter({
			applyHandlerOnStart: window.matchMedia('(min-width: 768px)').matches,
		});
		this.windowEventListenerList.push(
			{
				mediaQuery: '(max-width: 768px)',
				name: 'change',
				callback: this.adjustMobileTemplate.bind(this),
				isMatchMedia: true,
			},
			{
				name: 'change',
				callback: this.sideOutsideClickAdapter.handleSideOutsideClickHandler,
				mediaQuery: '(min-width: 768px)',
				isMatchMedia: true,
			},
			{
				name: 'keyup',
				callback: this.contentSideEscHandler.bind(this),
			},
		);
	},

	initialize(options) {
		this.options = options;
		this.approvalAllowed = options.approvalAllowed;
		this.issueAllowed = options.issueAllowed;
		this.isExchange = options.isExchange;
		this.originalExchangeFlights = options.originalExchangeFlights;

		this.results = options && options.tickets || options;
		this.bestPrices = this.results.bestPrices;
		this.hasTravelPolicy = this.results.hasTravelPolicy === true;

		this.approvalOffers = [];

		this.render();
		STATE.hideLoader();

		this.promiseSearch = Promise.resolve();

		this.listenTo(this.getPageModel(), 'change:newSearch', (model, value) => {
			if (value) {
				STATE.showLoader();
				this.promiseSearch = axios.post('/midoffice/ibecorp-b2b/search/getTransport', STATE.getSearchModel().toJSON()).then(result => {
					this.remove();
					new ResultView({
						tickets: result.data.result.avia[0],
						approvalAllowed: result.data.result.approvalAllowed === true,
						issueAllowed: result.data.result.issueAllowed === true,

					});
					STATE.hideLoader();
					STATE.getSearchModel().set('changed', false);
				});
				this.$('.l-content-inner').empty();
				this.results.forEach(item => {
					this.$('.l-content-inner').append(new TicketView({ model: item, isExchange: this.isExchange }).el);
				});
			}
		});

		this.listenTo(this.getPageModel(), 'change:isFilteringResults', (model, value) => {
			if (value) {
				this.ui.content.addClass('g-process');
			} else {
				this.ui.content.removeClass('g-process');
			}
		});

		this.listenTo(this.getPageModel(), 'change:currentDateMatrix', (model, value) => {
			if (value) {
				this.currentDateMatrix.show();
			} else {
				this.currentDateMatrix.hide();
			}
		});

		this.listenTo(this.getPageModel(), 'change:nearestDatesMatrix', (model, value) => {
			if (this.nearestDatesMatrix == null) {
				return;
			}

			if (value) {
				this.nearestDatesMatrix.show();
			} else {
				this.nearestDatesMatrix.hide();
			}
		});
		const errors = [];
		let timer = null;
		const mergeDictionaryErrorsCallback = (e) => {
			if (!e || !e.detail) return;
			errors.push(e.detail);
			clearTimeout(timer);
			timer = setTimeout(() => {
				const {errorMessage, stack} = _.reduce(errors, (prev, acc) => {
					prev.errorMessage = `${prev.errorMessage || ''}\n\n${acc.errorMessage}`;
					prev.stack = `${prev.stack || ''}\n\n${acc.stack}`;
					return prev;
				}, {});
				const error = window.ErrorHandler.getLineException(stack);
				window.ErrorHandler.callbackErrorHandler(_.extend({}, error, {
					message: errorMessage,
					error: {
						stack,
						isSilentError: false,
					},
				}));
			}, 500);
		};
		window.addEventListener('mergeDictionaryErrors', mergeDictionaryErrorsCallback);
		this.windowEventListenerList.push({
			name: 'mergeDictionaryErrors',
			callback: mergeDictionaryErrorsCallback,
		});
	},

	delegateEvents(...args) {
		BasePageView.prototype.delegateEvents.apply(this, args);
	},

	undelegateEvents(...args) {
		BasePageView.prototype.undelegateEvents.apply(this, args);
	},

	adjustMobileTemplate(e) {
		if (!e) return;
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			_.each(this.ticketsView, (v) => v && v.adjustMobileTemplate(e.matches));
		}, 100);
	},

	contentSideEscHandler(e) {
		if (e.code === 'Escape') {
			$('.l-layout').removeClass('show-filters');
		}
	},

	render(...args) {
		BasePageView.prototype.render.apply(this, args);
		const top = parseInt(this.$el.css('padding-top'), 10);
		this.$el.attr('paddingTop', top);
		this.ui.content.addClass('b-tickets-container-avia');
		if (this.isExchange) {
			$('.l-layout__top').prepend(TemplateExchangeBlockStep2({exchangeParams: this.options.exchangeParameters}));
		}

		if (!STATE.getIsFromHash()) {
			this.aviaSearchFormView = new AviaSearchFormView({
				parent: this,
				isExchange: this.options.isExchange,
				exchangeParameters: this.options.exchangeParameters,
			});
		}
		this.ui.controls.append(`<div class="l-content-table-title-avia">${L10N.get('avia.fullPrice')}</div>`);
		this.renderTickets();
		this.adjustMobileTemplate(window.matchMedia('(max-width: 768px)'));

		if (this.aviaSearchFormView) this.ui.top.append(this.aviaSearchFormView.$el);
	},

	renderTickets() {
		this.ui.content.empty();
		this.ui.sidebar.empty();

		this.ticketsView = [];
		const renderQueue = [];

		this.ui.sidebar.append(`<div class="l-offer-preview-container"></div>`);
		if (this.isExchange) {
			this.originalExchangeFlights = this.originalExchangeFlights.sort((a, b) => {
				return a.flightItem.flight.price.total.amount - b.flightItem.flight.price.total.amount;
			});
		}
		this.results.flights.sort((a, b) => a.flight.price.total.amount - b.flight.price.total.amount);
		this.hasTravelPolicy = this.results.hasTravelPolicy === true;
		this.results = this.results.flights.map(item => new TicketModel(item, {
			hasTravelPolicy: this.hasTravelPolicy,
		}));
		this.filtersCollection = new FiltersCollection(null, {
			tickets: this.results,
		});

		if (!_.isEmpty(this.results)) {
			this.searchParams = {
				isRoundTrip: (() => {
					const flight = _.first(this.results).get('flight');
					const firstLeg = _.first(flight.legs);
					const lastLeg = _.last(flight.legs);

					return (
						flight.legs.length === 2 &&
						firstLeg && lastLeg &&
						((_.first(firstLeg.segments).departureCity || {}).uid === (_.last(lastLeg.segments).arrivalCity || {}).uid) &&
						((_.last(firstLeg.segments).arrivalCity || {}).uid === (_.first(lastLeg.segments).departureCity || {}).uid)
					);
				})(),
				legs: _.first(this.results).get('flight').legs,
			};
		} else if (STATE.getSearchModel() != null) {
			const model = STATE.getSearchModel();
			this.searchParams = {
				isRoundTrip: model.get('routeType') === 'ROUNDTRIP',
				legs: _.map(model.get('routes'), (r) => r.toJSON()),
			};
		}

		this.matrixControls = new MatrixControlsView();
		if (this.results.length === 0) {
			this.nearestDatesMatrix = new NearestDatesMatrix({
				isOpen: true, // open if no tickets in result
				parent: this,
				matrixControls: this.matrixControls,
			});
		}
		this.ui.sidebar.append(this.matrixControls.el);

		this.currentDateMatrix = new CurrentDateMatrix({
			bestPrices: this.bestPrices,
			filters: this.filtersCollection,
		});

		this.results.forEach((item, idx) => {
			const v = new TicketView({
				model: item,
				index: idx,
				isExchange: this.isExchange,
				exchangeParameters: this.options.exchangeParameters,
				renderQueue: idx >= 50 ? renderQueue : null,
				approvalAllowed: this.approvalAllowed,
				issueAllowed: this.issueAllowed,
				parent: this,
			});

			this.ticketsView.push(v);
		});

		if (renderQueue.length > 0) {
			window.setTimeout(() => {
				renderQueue.forEach(fn => fn());
			}, 300);
		}

		this.$el.find('.b-matrix-current').append(this.currentDateMatrix.el);
		if (this.nearestDatesMatrix != null) {
			this.$el.find('.b-matrix-nearest').append(this.nearestDatesMatrix.el);
		}

		if (this.results.length !== 0) {
			const filterView = new FiltersView(_.extend({
				collection: this.filtersCollection,
			}, this.searchParams));
			const sortingView = new SortingView({
				container: this.ui.content,
				hasTravelPolicy: this.hasTravelPolicy,
				defaultSortOption: this.options.searchResultSortType,
				views: this.ticketsView,
				type: 'AVIA',
			});
			sortingView.sort();
			filterView.addSorting(sortingView);
			this.ui.sidebar.append(filterView.el);
			this.filtersCollection.applyFilters();
		}
		_.defer(() => this.model.trigger('calculateContentSidePosition'));
	},

	addOffer(e, { flight, flightToken }, _exclude = false) {
		const $target = $(e.currentTarget);
		let exclude = _exclude;

		if ($target.length !== 0) {
			if (!$target.hasClass('b-ticket-offer__cancel')) {
				const width = $target.outerWidth();

				$target.attr('data-original-caption', _.escape($target.html()));
				$target.attr('data-flight-token', _.escape(flightToken));
				$target.text(L10N.get('avia.remove'));

				$target.addClass('b-ticket-offer__cancel');
				$target.css('width', width);
			} else {
				const originalCaption = $target.attr('data-original-caption');
				exclude = true;

				$target.html(originalCaption);
				$target.removeAttr('data-original-caption').removeClass('b-ticket-offer__cancel');
				$target.removeAttr('data-flight-token');
				$target.css('width', '');
			}
		}

		if (exclude !== true) {
			this.approvalOffers.push({ flight, flightToken });
		} else {
			const indexFlight = _.findIndex(this.approvalOffers, (o) => o != null && o.flightToken === flightToken);

			if (indexFlight !== -1) {
				this.approvalOffers.splice(indexFlight, 1);
			}
		}

		this.ui.content.find('.b-ticket-popup-offers').detach();
		if (this.approvalOffers.length) {
			this.ui.content.append(TicketPopupOffers({
				data: this.approvalOffers,
			}));
			this.ui.content.find('.b-ticket-popup-offers').on('click', this.sendOffers.bind(this));
		}
	},

	sendOffers(e) {
		if (e != null) {
			e.preventDefault();
		}

		// First ticket card for sync width and postion right
		const $ticketContainer = this.ui.content.find('.b-ticket.b-ticket__visible').first();
		const { order } = this.options || {};
		if (this.ticketModalOffers == null) {
			this.ticketModalOffers = new TicketModalOffersView({ data: this.approvalOffers, parent: this, $ticketContainer, order });
		} else {
			this.ticketModalOffers.setOptions({ data: this.approvalOffers, $ticketContainer }).render();
		}
	},

	remove() {
		if (this.sideOutsideClickAdapter) {
			this.sideOutsideClickAdapter.removeListener();
		}
		BasePageView.prototype.remove.call(this);
	},

}, {

	loadFromHash(hash) {
		// CL|ECONOMY!D|true!R|MOW|LED|2023-05-20|TWS15:00:00|TWE20:00:00!R|LED|MOW|2023-05-26!PA|ADULT|1!RO|ROUNDTRIP!CH|false +
		// !C|07e9360d-542d-4518-a494-87c5589f0d3a!PR|!S|AVIA
		STATE.showLoader();
		return new Promise(async (resolve) => {
			try {
				const attrs = {
					routes: [],
					preferredAirlines: [],
					passengersTypes: {},
					direct: false,
					routeType: 'CUSTOM',
				};

				STATE.checkHash(hash, 'AVIA');
				STATE.getParsedHash(hash, attrs);

				attrs.routes = attrs.routes.sort((a, b) => {
					return new Time(a.date).getMoment().isAfter(new Time(b.date).getMoment()) ? 1 : -1;
				});

				const appSearchModel = STATE.getSearchModel();

				if (attrs.classOfService) {
					appSearchModel.set('classOfService', attrs.classOfService);
				}

				let leg_ = appSearchModel.get('routes').pop();
				while (leg_) {
					leg_.destroy();
					leg_ = appSearchModel.get('routes').pop();
				}

				let routes = attrs.routes;
				if (routes.length === 2) {
					if (routes[0].departure === routes[1].arrival && routes[0].arrival === routes[1].departure) {
						routes[0].dateFrom = routes[1].date;
						routes = [routes[0]];
						appSearchModel.set('routeType', 'ROUNDTRIP');
					}
				}

				for (const leg of routes) {
					appSearchModel.addRoute(new SearchRouteModel({
						departure: await STATE.getLocations(leg.departure, attrs.searchType, 20),
						arrival: await STATE.getLocations(leg.arrival, attrs.searchType, 20),
						dateTo: new Time(leg.date).toServerDate(),
						dateFrom: leg.dateFrom ? new Time(leg.dateFrom).toServerDate() : undefined,
						departureTimeWindowStart: leg.departureTimeWindowStart,
						departureTimeWindowEnd: leg.departureTimeWindowEnd,
					}));
				}

				if (!_.isEmpty(attrs.passengersTypes)) {
					Object.keys(attrs.passengersTypes).forEach(key => {
						appSearchModel.get('passengersTypes').set(key, attrs.passengersTypes[key]);
					});
				}

				if (attrs.passengers && attrs.passengers.length) {
					Object.keys(attrs.passengers).forEach(key => {
						appSearchModel.get('passengersTypes').set(key, attrs.passengers[key]);
					});
				}

				appSearchModel.set('direct', attrs.direct);
				appSearchModel.set('changed', attrs.changed || false);
				appSearchModel.get('serviceTypes').reset([{uid:attrs.searchType, caption: attrs.searchType}]);
				const url = '/midoffice/ibecorp-b2b/search/getTransport';
				const params = appSearchModel.toJSON();
				STORE.set(STATE.ROUTES.MAIN_SEARCH, params.parameters, 14400);
				axios.post(url, params, appSearchModel)
					.then(result => {
						STATE.setSearchResult(result.data.result);
						STATE.removeHeader();
						resolve({});
					}).catch(e => {
						STORE.remove(STATE.ROUTES.TICKETS);
						logger.error(e);
						resolve();
					});
			} catch (e) {
				logger.error(e);
				resolve();
			}
		});
	},

	load(params) {
		return new Promise((resolve) => {
			const results = STATE.getSearchResults();

			if (_.isEmpty(results)) {
				STATE.navigate(STATE.ROUTES.INDEX);
				return;
			}

			if (params.fromHash) {
				STATE.setIsFromHash(true);
				STATE.disableFirstStep();
			}

			if (results.order != null) {
				STATE.layout.header.setAdditionalOrder(results.order);
			}

			const approvalAllowed = results.approvalAllowed === true;
			const issueAllowed = results.issueAllowed === true;
			let tickets = _.isEmpty(results.avia) ? {
				flights: [],
			} : results.avia[0];
			const {searchResultSortType} = results;
			const isExchange = results.exchangeParameters != null;
			const originalExchangeFlights = results.flights;
			if (isExchange) {
				tickets = {
					flights: _.map(results.flights, (item) => {
						item.flightItem.flight.selected = item.selected;
						return {
							flight: item.flightItem.flight,
							flightToken: item.flightItem.flightToken,
							hasExtendedFare: item.flightItem.hasExtendedFare,
						};
					}),
					bestPrices: [],
				};
			}

			resolve({
				tickets,
				approvalAllowed,
				issueAllowed,
				isExchange,
				searchResultSortType,
				originalExchangeFlights,
				exchangeParameters: results.exchangeParameters,
				order: results.order,
				initScrollTopButton: true,
			});
		});
	},

	noTickets() {
		const noTicketsPopup = new Widgets.Popup({
			content: L10N.get('errors.noTicketsAvia'),
			type: 'danger',
			actions: [{
				label: L10N.get('errors.noTicketsButton'),
				action: () => {
					noTicketsPopup.hide();
				},
			}],
			onClose: () => {
				noTicketsPopup.hide();
				STATE.navigate(STATE.ROUTES.INDEX);
			},
		});
		noTicketsPopup.show();
	},
},
);

export default TicketPageView;
