module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var service = this.model.attributes || {};
	var tUid = tUid || '';
	var selectedTravellerUid = selectedTravellerUid || '';
	var selectedTypeUid = selectedTypeUid || '';

	function handleServiceVisibility(type, segments) {
        const correctSegments = _.some(segments, function(item) {
            return item.legIndex === 0 && item.segmentIndex === 0;
        });
        return selectedTypeUid === type.uid && selectedTravellerUid === tUid && correctSegments ? '' : 'dn';
    }
;
__p += '\n<div class="b-ancillary-fees__service-content-item b-service js-service-content-item ' +
((__t = ( handleServiceVisibility(service.type, service.segments) )) == null ? '' : __t) +
'"\n     data-service-type="' +
((__t = ( service.type.uid )) == null ? '' : __t) +
'"\n     data-traveller-uid="' +
((__t = ( tUid )) == null ? '' : __t) +
'"\n     data-legs-segments="' +
((__t = ( encodeURIComponent(JSON.stringify(service.segments)) )) == null ? '' : __t) +
'"\n>\n    <span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "selected", disabled: "'+ !service.enabled +'", label: "'+ service.description +'"})\'></span>\n\n    <div class="b-service__price-block">\n        ';
 if (service.maxQuantityPerPassenger > 1) { ;
__p += '\n        <span class="js-widget"  onclick=\'return new Widgets.NumerablePicker({bindingProperty: "quantity", plurarForm: "avia.ancillaryFeesPlurar", enabledProperty: "selected", default: "'+ service.quantity +'", min: 1, max: "' + service.maxQuantityPerPassenger + '"})\'></span>\n        ';
 } ;
__p += '\n\n        <span class="b-service__price">\n            ' +
((__t = ( Formatter.formatMoney(service.price.amount, Formatter.getDecimal(service.price.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(service.price.currencyCode) )) == null ? '' : __t) +
'\n        </span>\n    </div>\n</div>\n';

}
return __p
}