// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

import GlUl from '@/utils/global-utils';
import axios from 'axios';
import $ from 'jquery';

export default BaseView.extend({

	template,

	events: {
		'click .b-train-search-mobile__filters-trigger': 'openFiltersMobile',
		'click .b-train-search-form-mobile__close': 'toggleSearchFormMobile',
		'click .b-train-search-mobile__search-form': 'toggleSearchFormMobile',
		'click .b-train-search__options-date-element': 'showPikadayModal',
		'click .b-train-search-form-mobile__background': 'toggleSearchFormMobile',
		'click .b-train-search__button': 'search',
		'click .b-train-search-swap': 'swapLocations',
	},

	ui: {
		mobileSearchFormBackground: '.b-train-search-form-mobile__background',
	},

	initialize() {
		this.searchModel = STATE.getSearchModel();
		this.model = this.searchModel.get('routes')[0];

		this.listenTo(this.model, 'change:dateFrom', (model, value) => {
			if (!value) {
				this.searchModel.set('routeType', 'ONEWAY');
			} else {
				this.searchModel.set('routeType', 'ROUNDTRIP');
			}
		});

		this.render();
	},

	render() {
		const params = _.extend(
			{},
			this.model ? this.model.attributes : {},
			this.searchModel.attributes,
			this.options,
		);
		const container = this.$el;
		this.setElement(this.template.call(this, params));
		container.append(this.$el);
	},

	// EVENTS
	swapLocations(e) {
		if (e != null) {
			e.preventDefault();
		}

		this.searchModel.swapLocations();
	},

	search(e) {
		if (e != null) {
			e.preventDefault();
		}

		const model = this.searchModel.toJSON();
		const {parent} = this.options;

		STATE.showLoader();
		this.disableElements(e);
		axios.post('/midoffice/ibecorp-b2b/search/getTransport', model, this.searchModel).then((response) => {
			const {result} = response.data;

			STATE.hideLoader();
			STATE.setTrainsSearchResult(result);
			STORE.set(STATE.ROUTES.MAIN_SEARCH, STATE.getSearchModel().attributes, 14400);
			STATE.setFormState('trains:filter', null);
			STATE.navigate('trains/tickets/route/1');
			this.model.set('changed', false);

			const searchParameters = STATE.getSearchParametrs();
			parent.isRoundTrip = (searchParameters.routeType === 'ROUNDTRIP');
			parent.hasTravelPolicy = (result.rail[0] && result.rail[0].hasTravelPolicy === true);
			parent.results = result.rail[0];
			parent.routeId = 1;
			parent.approvalAllowed = result.approvalAllowed;
			parent.issueAllowed = result.issueAllowed;
			parent.options.searchResultSortType = result.searchResultSortType;

			STATE.router.breadcrumbs.$el.show();
			STATE.router.breadcrumbs.setRouteType(parent.isRoundTrip ? 'RAILWAYS_ROUNDTRIP' : 'RAILWAYS_ONEWAY');

			parent.renderTrains();
		});
	},

	openFiltersMobile() {
		$('.l-layout').addClass('show-filters');
	},

	toggleSearchFormMobile(e) {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		const lLayout = $('.l-layout');
		const shouldOpen = !lLayout.hasClass('show-search-form');
		this.ui.mobileSearchFormBackground[shouldOpen ? 'show' : 'hide']();
		lLayout.toggleClass('show-search-form', shouldOpen);
	},

	createPikadayModalView() {
		this.pikadayModal = GlUl.createPikadayModalView(this.model);
	},

	clearPikadayModalView() {
		this.pikadayModal.destroy();
		this.pikadayModal = null;
	},

	destroy(...args) {
		this.clearPikadayModalView();
		BaseView.prototype.destroy.apply(this, args);
	},

	showPikadayModal(e) {
		if (this.pikadayModal == null) this.createPikadayModalView();
		GlUl.showPikadayModal(e, this.pikadayModal);
	},

});
