module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-hotel-info__wrapper">\n	<div class="b-hotel-info">\n		<div class="b-hotel-info__header">\n			<div class="b-hotel-info__name">' +
((__t = ( hotel.name )) == null ? '' : __t) +
'\n                ';

                var travelPolicyCompliance = {rulesApplied: _.flatten(_.map(hotel.hotelOffers, function (o) { return o.travelPolicyCompliance && o.travelPolicyCompliance.rulesApplied || [] }))};
                var tripartiteContract = _.some(hotel.hotelOffers, function (o) { return o.tripartiteContract });
                ;
__p += '\n                ';
 if (!_.isEmpty(travelPolicyCompliance.rulesApplied) || tripartiteContract === true) { ;
__p += '\n                    <span class="b-travel-policy">\n                            ' +
((__t = ( require('@/blocks/pages/b-hotels/b-hotels-hotel/b-travel-policy.ejs')({
                                travelPolicyCompliance: travelPolicyCompliance,
                                tripartiteContract: tripartiteContract
                            }) )) == null ? '' : __t) +
'\n                    </span>\n                ';
 } ;
__p += '\n            </div>\n			';
 if (!_.isEmpty(hotel.hotelOffers[0].provider) && showGdsAccountName) { ;
__p += '\n				<div class="b-hotel-info__provider">' +
((__t = ( hotel.hotelOffers[0].provider.caption )) == null ? '' : __t) +
'</div>\n			';
 } ;
__p += '\n		</div>\n		<div class="b-hotel-info__footer">\n			';
 if (hotel.distance != null && parseFloat(hotel.distance) !== 0 && !isNaN(hotel.distance)) { ;
__p += '\n				<div class="b-hotel-info__footer-row">' +
((__t = ( hotel.distance.toFixed(1).replace('.', ',') )) == null ? '' : __t) +
' ' +
((__t = ( L10N.get('hotels.distanceFromCenter') )) == null ? '' : __t) +
'</div>\n			';
 } ;
__p += '\n			';
 if (hotel.address != null) { ;
__p += '\n				<div class="b-hotel-info__footer-row">\n					';

						var address = hotel.address;
						var addressString = _.compact([address.address, address.zipCode, (address.cityInfo && address.cityInfo.city || {}).caption, (address.country || {}).caption]).join(', ');
					;
__p += '\n					' +
((__t = ( addressString )) == null ? '' : __t) +
'\n				</div>\n			';
 } ;
__p += '\n            ';
 if (hotel.checkInDateTime && hotel.checkOutDateTime) { ;
__p += '\n                <div class="b-hotel-info__footer-row">\n                    ' +
((__t = ( L10N.get('hotels.checkin') )) == null ? '' : __t) +
': ' +
((__t = ( new Time(hotel.checkInDateTime).toDateString() )) == null ? '' : __t) +
'\n                </div>\n                <div class="b-hotel-info__footer-row">\n                    ' +
((__t = ( L10N.get('hotels.checkout') )) == null ? '' : __t) +
': ' +
((__t = ( new Time(hotel.checkOutDateTime).toDateString() )) == null ? '' : __t) +
'\n                </div>\n            ';
 } ;
__p += '\n		</div>\n	</div>\n	<div class="b-hotel-info__pricing"></div>\n</div>\n\n';

}
return __p
}