import styled from "styled-components";
import CollapserUp from "~assets/img/collapser_up.svg";
import CollapserDown from "~assets/img/collapser_down.svg";

export const Container = styled.div`
	border-radius: 6px;
	background-color: #fff;
	padding: 16px;
	box-shadow: 0px 0px 10px 0px #00000021;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

export const CostCodes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;

`

export const Header = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-size: 14px;
	font-weight: 500;
  cursor: pointer;
`;

export const HeaderFullName = styled.div`
	color: #4a5767;
`;


export const HeaderCollapse = styled.div<{
	$collapsed: boolean;
}>`
	user-select: none;
	width: 24px;
	height: 24px;
	background: url(${(props) =>`${props.$collapsed ? CollapserDown : CollapserUp}`}) no-repeat center;
`;

