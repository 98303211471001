module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	var approvalAllowed = approvalAllowed || false;
	var issueAllowed  = issueAllowed  || false;
;
__p += '\n\n<div class="b-ticket-standart-fares b-standart-fares">\n	';

		_.each(flightFares, function(fare, i) {
			var showTravelPolicyBlock = hasTravelPolicy === true && (fare.travelPolicyCompliance != null && !_.isEmpty(fare.travelPolicyCompliance.rulesApplied)) || fare.isTripartiteContractDiscountApplied;
			var showAvailableSeats = fare.availableSeats != null;
			var showExtraInfoBlock = showAvailableSeats || showTravelPolicyBlock;
	;
__p += '\n		<div class="b-standart-fares__item">\n			<div class="b-standart-fares__item-column">\n				<div class="b-standart-fares__inner-flex-container">\n					<span class="action-link b-fare-rules__rule-carrier-open" data-token="' +
((__t = ( fare.flightToken )) == null ? '' : __t) +
'">\n						<span class="b-standart-fares__item-class">' +
((__t = ( fare.classOfService.caption )) == null ? '' : __t) +
'</span>\n						<span class="b-standart-fares__item-basis">(' +
((__t = ( fare.fareBasis != null ? fare.fareBasis.join(', ') : '' )) == null ? '' : __t) +
')</span>\n					</span>\n					<span class="b-standart-fares__item-services">\n						' +
((__t = ( require('./b-ticket-services.ejs')({
							leg: fare.leg,
							flight: fare.flight,
							legIndex: i,
						}) )) == null ? '' : __t) +
'\n					</span>\n				</div>\n			</div>\n			<div class="b-standart-fares__item-column b-standart-fares__item-column--type-full">\n				<div class="b-standart-fares__item-border"></div>\n			</div>\n			<div class="b-standart-fares__item-column">\n				';
 if (showExtraInfoBlock) { ;
__p += '\n					<div class="b-standart-fares__extra-info-container">\n						';
 if (showAvailableSeats) { ;
__p += '\n							' +
((__t = ( require('./b-ticket-available-seats.ejs')({
									availableSeats: fare.availableSeats,
							}) )) == null ? '' : __t) +
'\n						';
 } ;
__p += '\n						';
 if (showTravelPolicyBlock) { ;
__p += '\n							<div class="b-standart-fares__travel-policy b-travel-policy">\n								' +
((__t = ( require('./b-travel-policy.ejs')({
									flight: fare,
								}) )) == null ? '' : __t) +
'\n							</div>\n						';
 } ;
__p += '\n					</div>\n				';
 } ;
__p += '\n				';
 if (issueAllowed ) { ;
__p += '\n					<div class="btn btn-dark b-standart-fares__select" data-index="' +
((__t = ( fare._flightIndex )) == null ? '' : __t) +
'" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="' +
((__t = ( _.escape(require('./b-ticket-pricing.ejs')({ price: fare.price })) )) == null ? '' : __t) +
'">\n						' +
((__t = ( Formatter.formatMoney(fare.totalPrice.amount, 0) )) == null ? '' : __t) +
'\n						' +
((__t = ( Formatter.formatCurrency(fare.totalPrice.currencyCode) )) == null ? '' : __t) +
'\n					</div>\n				';
 } ;
__p += '\n				';
 if (approvalAllowed ) { ;
__p += '\n					<div class="b-standart-fares__select-container">\n						<div class="btn b-standart-fares__select--offer" data-index="' +
((__t = ( fare._flightIndex )) == null ? '' : __t) +
'">' +
((__t = ( L10N.get('ticket.add') )) == null ? '' : __t) +
'</div>\n						';
 if (!issueAllowed ) { ;
__p += '\n							<div class="b-standart-fares__select-price" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="' +
((__t = ( _.escape(require('./b-ticket-pricing.ejs')({ price: fare.price })) )) == null ? '' : __t) +
'">\n								' +
((__t = ( Formatter.formatMoney(fare.totalPrice.amount, 0) )) == null ? '' : __t) +
'\n								' +
((__t = ( Formatter.formatCurrency(fare.totalPrice.currencyCode) )) == null ? '' : __t) +
'\n							</div>\n						';
 } ;
__p += '\n					</div>\n				';
 } ;
__p += '\n			</div>\n		</div>\n	';
 }); ;
__p += '\n</div>\n';

}
return __p
}