// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';

const PassengerModel = BaseModel.extend({

	defaults() {
		return {
			ADULT: 1,
			CHILD: 0,
			INFANT: 0,
		};
	},

	minus(type) {
		const total = Object.keys(this.defaults())
			.reduce((result, item) => result + parseInt(this.get(item)), 0);
		if (total > 1) {
			const value = parseInt(this.get(type));
			if (value > 0) {
				this.set(type, value - 1);
			}

			if (this.get('ADULT') < this.get('INFANT')) {
				this.set('INFANT', this.get('ADULT'));
				if (this.get('ADULT') === 0) {
					this.set('ADULT', 1);
				}
			}
		}
		this.trigger('change:passengersTypes');
	},

	plus(type) {
		const total = Object.keys(this.defaults())
			.reduce((result, item) => result + parseInt(this.get(item)), 0);

		if (total < 4) {
			const value = parseInt(this.get(type));
			const enabled = (type !== 'INFANT') || (parseInt(this.get('ADULT')) > value);

			if (value < 4 && enabled) {
				this.set(type, value + 1);

				return true;
			}

			if (this.get('ADULT') < this.get('INFANT')) {
				this.set('INFANT', this.get('ADULT'));
			}
		}
		this.trigger('change:passengersTypes');

		return false;
	},

	toJSON() {
		return Object.keys(this.attributes).reduce((result, item) => {
			if (parseInt(this.get(item)) > 0 && _.keys(this.defaults()).includes(item)) {
				/*eslint-disable */
				result[item] = this.get(item);
				/*eslint-enable */
			}
			return result;
		}, {});
	},

});

export default PassengerModel;
