import "./style.less";
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import NewsSearchForm from "./NewsSearchForm/NewsSearchForm";
import {
	NewsItem,
	NewsSearchResponse,
	SearchFormStatusEnum,
} from "./helpers/interfaces";
import NewsCard from "./NewsCard/NewsCard";
import { fetchNewsItems } from "./helpers/services";
import Pagination, {
	PaginationInfo,
} from "../../components/form/Pagination/Pagination";
import { ReactAppGlobalContext } from "../../../js/utils/react-utils";

export interface SearchFormParams {
	_dates?: [Date | undefined, Date | undefined];
	_status?: SearchFormStatusEnum;
	pattern?: string;
	startDate?: string;
	endDate?: string;
	important?: boolean;
	read?: boolean;
	page?: number;
	pageSize?: number;
}

const NewsPage: React.FC<unknown> = () => {
	const { L10N } = useContext(ReactAppGlobalContext);

	const defaultPaginationInfo = useMemo(() => {
		return {
			currentPage: 0,
			pageSize: 10,
		} as PaginationInfo;
	}, []);

  const [pageSize, setPageSize] = useState<number>(
		defaultPaginationInfo.pageSize
	);
	const [currentPage, setCurrentPage] = useState<number>(
		defaultPaginationInfo.currentPage
	);

	const [searchFormParams, setSearchFormParams] = useState<SearchFormParams>({
		page:currentPage,
		pageSize,
	});

	const [totalCount, setTotalCount] = useState<number>(0);
	const [newsItems, setNewsItems] = useState<NewsItem[]>([]);

	const loadNews = useCallback(async (searchFormParams: SearchFormParams) => {
		const searchResults: NewsSearchResponse = await fetchNewsItems(
			searchFormParams, {pageSize, currentPage}
		);
		setTotalCount(searchResults.totalCount);
		setNewsItems(searchResults.notices);
	}, [pageSize, currentPage]);

	// load news
	useEffect(() => {
		loadNews(searchFormParams);
	}, [searchFormParams, loadNews]);

	return (
		<div className="p-cabinet-news">
			<div className="b-block-container">
				<div className="b-block-container__header">
					<div className="b-block-container__header-label">
						{L10N.get("news.newsPage.title")}
					</div>
				</div>
				<NewsSearchForm
					updateSearchParams={(filtersSearchParams: SearchFormParams) => {
						setSearchFormParams(filtersSearchParams);
					}}
					setCurrentPage={setCurrentPage}
				/>
				<div className="b-block-container__content">
					{newsItems.map((newsItem: NewsItem) => (
						<NewsCard key={newsItem.uid} item={newsItem} />
					))}
				</div>
				<div className="b-block-container__footer">
					<Pagination
						totalCount={totalCount}
            /*
						onChange={({pageSize, currentPage}) => {
              const newSearchParams = {...searchFormParams};
              newSearchParams.page = currentPage;
              newSearchParams.pageSize = pageSize;
              setSearchFormParams(newSearchParams);
            }}*/
            pageSize={pageSize}
            currentPage={currentPage}
						setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
					/>
				</div>
			</div>
		</div>
	);
};

export default NewsPage;
