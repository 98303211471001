// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';
import TransliterationPopup from '@/widgets/b-transliteration-popup';

export default Widget.extend({

	validationTemplate,

	template,

	events: {
		'change .b-input__value': 'change',
	},

	ui: {
		input: '.b-input__value',
	},

	initialize() {
		this.options.deletableField = (this.options.deletableField === true);
		this.options.label = this.options.label || '';
		this.options.autocomplete = this.options.autocomplete || null;
		this.options.elementId = this.options.elementId || null;
		this.options.elementName = this.options.elementName || null;
		this.options.disabled = [true, 'true'].includes(this.options.disabled);
		this.options.transliterationOn = [true, 'true'].includes(this.options.transliterationOn);
		this.options.runTransliterationOnFirstRender = [true, 'true'].includes(this.options.runTransliterationOnFirstRender);
		this.transliterationPopup = null;

		this.render(Object.assign({
			type: 'text',
		}, this.options));

		if (_.isObject(this.options.attrs)) {
			this.applyAttrs(this.options.attrs);
		}
		if (this.options.disabled) {
			this.disable(this.options.disabled);
		}
	},

	applyAttrs(attrs) {
		_.each(attrs, (value, key) => {
			if (['true', 'false'].includes(value)) value = value === 'true';
			this.ui.input.attr(key, value);
			if (key === 'pattern') {
				this.ui.input.attr('oninput', `this.value = this.value.replace(${new RegExp(value, 'g')}, '')`);
			}
		});
	},

	disable(disabled) {
		this.disabled = disabled;
		this.ui.input.attr('disabled', disabled);
	},

	change() {
		this.model.set(this.options.bindingProperty, this.ui.input.val());
	},

	initTransliteration(model) {
		if (this.transliterationPopup == null) {
			this.transliterationPopup = new TransliterationPopup({
				bindingProperty: this.options.bindingProperty,
				targetInput: this.ui.input,
				autoCorrectionAdapter: this.options.autoCorrectionAdapter,
			});
			this.transliterationPopup.applyBinding(model);
			if (this.options.runTransliterationOnFirstRender) this.transliterationPopup.transliterate();
		}
	},

	applyBinding(model) {
		this.model = model;

		if (this.options.transliterationOn) this.initTransliteration(model);

		this.listenTo(model, `change:${this.options.bindingProperty}`, (thisModel, value) => {
			this.ui.input.val(value || '');
		});
		this.ui.input.val(model.get(this.options.bindingProperty));
	},

	remove() {
		if (this.transliterationPopup?.remove) {
			this.transliterationPopup.remove();
		}
		Widget.prototype.remove.call(this);
	},
	
});
