// eslint-disable-next-line banned-modules
'use strict';

import axios from 'axios';
import config from '@/config';
import STATE from '../classes/application-state';

let cache = {};

class L10N {
	static load() {
		return new Promise((resolve, reject) => {
			axios.get(`${config.labelsURL}${STATE.getLanguage()}.json`)
				.then((labels) => {
					cache = labels.data;
					resolve(cache);
				})
				.catch(reject);
		});
	}

	static resolve(path, obj) {
		return path.split('.').reduce((prev, curr) => {
			if (prev) {
				return prev[curr];
			}
			return undefined;
		}, obj);
	}

	static get(key) {
		return STATE.escape(L10N.getUnescaped(key));
	}

	static getUnescaped(key) {
		const val = L10N.resolve(key, cache);
		// turn off showLabelsKeys
		// ((cookies.get('showLabelsKeys') ? `{{${key}}}` : '') + val);
		return val === undefined ? `{{${key}}}` : val;
	}

	static getPluralForm(key, count) {
		const variants = L10N.resolve(key, cache);
		if (count === undefined || count === null) {
			return L10N.get(`${key}.default`);
		}
		return Object.keys(variants).filter(item => item !== 'default').map(i => parseFloat(i))
			.reduce((result, item) => {
				if (result === '' && count <= item) {
					return STATE.escape(variants[item]);
				}
				return result;
			}, '');
	}
}

export default L10N;
