// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';
import BaseCollection from '@/classes/base.collection';
import View from './index';

export default BaseModel.extend({

	View,

	initialize() {
		this.attributes = _.extend({
			organization: null,
			mainEmployment: false,
			employeeCategoryInfos: new BaseCollection([], {
				model: BaseModel,
			}),
			roles: new BaseCollection([], {
				model: BaseModel,
			}),
			costCodesInfo: new BaseCollection([], {
				model: BaseModel,
			}),
			disabledNotificationTypes: new BaseCollection([], {
				model: BaseModel,
			}),
		}, this.attributes);
	},

	toJSON(...args) {
		const result = BaseModel.prototype.toJSON.apply(this, args);
		result.mainEmployment = Boolean(result.mainEmployment);
		result.employeeCategoryInfo = result.category || result.employeeCategoryInfo;
		delete result.category;
		delete result.roles;
		delete result.employeeCategoryInfos;
		delete result.employerSettings;

		return result;
	},

	parse(...args) {
		const data = BaseModel.prototype.parse.apply(this, args);

		data.employeeCategoryInfos = new BaseCollection(data.employeeCategoryInfos, {
			model: BaseModel,
			parse: true,
		});
		data.roles = new BaseCollection(data.roles, {
			model: BaseModel,
			parse: true,
		});
		data.costCodesInfo = new BaseCollection(data.costCodesInfo, {
			model: BaseModel,
			parse: true,
		});
		data.disabledNotificationTypes = new BaseCollection(data.disabledNotificationTypes, {
			model: BaseModel,
			parse: true,
		});
		return data;
	},

	handleValidation(...args) {
		if (_.isEmpty(args)) return;
		if (args[0].roles != null) {
			args[0].role = args[0].roles;
			delete args[0].roles;
		}

		if (args[0].employeeCategoryInfo != null) {
			args[0].category = args[0].employeeCategoryInfo;
		}

		BaseModel.prototype.handleValidation.apply(this, args);
	},

});
