module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-slider">\n	';
 if (_.isEmpty(images)) { ;
__p += '\n		<div class="b-slider__image no_image">\n			<div class="b-hotel__image-not-available b-slider__image-not-available"></div>\n		</div>\n	';
 } else { ;
__p += '\n		<div class="b-slider__image">\n			';
 if (_.size(images) > 1) { ;
__p += '\n				<div class="b-slider__control b-slider__control--type-prev" style="display: none"></div>\n				<div class="b-slider__control b-slider__control--type-next" style="display: none"></div>\n			';
 } ;
__p += '\n			<div class="b-slider__container">\n				<div class="b-slider__list">\n					';
 _.each(images, function (image) { ;
__p += '\n						<div class="b-slider__slide" data-image-uid="' +
((__t = ( image.uid )) == null ? '' : __t) +
'">\n							<div class="lds-ring"><div></div><div></div><div></div><div></div></div>\n						</div>\n					';
 }) ;
__p += '\n				</div>\n			</div>\n		</div>\n	';
 } ;
__p += '\n</div>';

}
return __p
}