import React, { useState } from "react";
import { Container, Header, HeaderCollapse, HeaderNumber } from "./styled";
import { Service } from "../../../../../../../../helpers/interfaces/Service";
import { ServiceTravellerStatistics } from "../../../../../../../../helpers/interfaces/ServiceTravellerStatistics";
import StatisticsPopupServiceTraveller from "./components/StatisticsPopupServiceTraveller";

interface StatisticsPopupServiceProps {
	service: Service;
}

const StatisticsPopupService: React.FC<StatisticsPopupServiceProps> = (
	props
) => {
	const { service,} = props;
	const { statistics } = service;

	const [collapsed, setCollapsed] = useState<boolean>(statistics.travellerStatistics.length > 1);

	return (
		<Container>
			<Header onClick={() => setCollapsed(!collapsed)}>
				<HeaderNumber>{service.ticketNumber}</HeaderNumber>
				<HeaderCollapse>
					{collapsed ? "Развернуть" : "Свернуть"}
				</HeaderCollapse>
			</Header>
			{!collapsed && (
				<>
					{statistics.travellerStatistics.map(
						(traveller: ServiceTravellerStatistics) => {
							return (
								<StatisticsPopupServiceTraveller
									key={traveller.travellerUid}
									traveller={traveller}
                  commonCostCodes={statistics.commonCostCodes}
								/>
							);
						}
					)}
				</>
			)}
		</Container>
	);
};

export default StatisticsPopupService;
