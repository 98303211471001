// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

import axios from 'axios';
import $ from 'jquery';

export default BaseView.extend({

	template,

	ui: {
		selected: '.b-categories__selected',
		dropdown: '.b-categories__drop-down',
	},

	events: {
		'click .b-categories__selected': 'toggle',
		'click .b-categories__drop-down-item': 'select',
	},

	isOpen: false,

	nameMap: {
		CHEAPEST: L10N.get('hotels.cheapest'),
		ALL_ROOMS: L10N.get('hotels.allRooms'),
		NO_PENALTIES: L10N.get('hotels.noPenalties'),
	},

	initialize() {
		this.categoriesOptions = [{
			value: 'CHEAPEST',
			label: L10N.get('hotels.cheapest'),
		}, {
			value: 'ALL_ROOMS',
			label: L10N.get('hotels.allRooms'),
		}, {
			value: 'NO_PENALTIES',
			label: L10N.get('hotels.noPenalties'),
		}];

		this.render({ variants: this.categoriesOptions });

		let defaultCategory = this.categoriesOptions[0];

		// --------------- Fix filter (слетает после ререндера)
		if (!window.categoryChoice) {
			if (this.options.parent != null && this.options.parent.configuration != null) {
				const { roomType = {} } = this.options.parent.configuration;
				const category = _.find(this.categoriesOptions, (c) => c.value === roomType.uid);

				if (category != null) {
					defaultCategory = category;
				}
			}
		} else {
			defaultCategory = window.categoryChoice;
		}

		this.setActive(defaultCategory, true);
	},

	setActive(item, silent) {
		this.active = item;
		this.ui.selected.text(item.label);
		this.close();

		if (!silent) {
			if (this.options.parent && this.options.parent.hotelsView && _.isFunction(this.options.parent.hotelsView.setCategoryHotels)) {
				this.options.parent.hotelsView.setCategoryHotels(item);
			}

			axios.put('/midoffice/ibecorp-b2b/hotels/searchPreferences', {
				parameters: {
					roomType: { uid: item.value, caption: item.value },
				},
			});

			// --------------- Fix filter (слетает после ререндера)
			window.categoryChoice = {label: this.nameMap[item.value], value: item.value};
			// ---------------
		}

		if (this.options.parent && this.options.parent.hotelsView) {
			this.options.parent.hotelsView.category = item;
		}
	},

	select(e) {
		const val = this.$el.find(e.currentTarget).attr('type');

		if (val !== this.active.value) {
			this.setActive(this.categoriesOptions.reduce((result, item) => {
				if (val === item.value) {
					result = item;
				}
				return result;
			}, null));
			this.close();
		}
	},

	toggle() {
		if (this.isOpen) {
			this.close();
		} else {
			this.open();
		}
	},

	open() {
		this.isOpen = true;
		this.isOuter = false;
		this.$el.addClass('b-categories__open');
		this.ui.dropdown.stop().clearQueue().fadeIn();
		$('html').on('click.categories', () => {
			if (this.isOuter) {
				$('html').off('click.categories');
				this.close();
			}
			this.isOuter = true;
		});
	},

	close() {
		this.$el.removeClass('b-categories__open');
		if (this.isOpen) {
			this.ui.dropdown.stop().clearQueue().fadeOut();
			this.isOpen = false;
		} else {
			this.ui.dropdown.stop().clearQueue().hide();
		}
	},

});
