// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

import GlUl from '@/utils/global-utils';
import axios from 'axios';
import $ from 'jquery';

export default BaseView.extend({

	template,

	events: {
		'click .b-avia-search-mobile__filters-trigger': 'openFiltersMobile',
		'click .b-avia-search-form-mobile__close': 'toggleSearchFormMobile',
		'click .b-avia-search-mobile__search-form': 'toggleSearchFormMobile',
		'click .b-avia-search__options-date-element': 'showPikadayModal',
		'click .b-avia-search-form-mobile__background': 'toggleSearchFormMobile',
		'click .b-avia-search__button': 'search',
		'click .b-avia-search-swap': 'swapLocations',
	},

	ui: {
		mobileSearchFormBackground: '.b-avia-search-form-mobile__background',
	},

	initialize() {
		this.searchModel = STATE.getSearchModel();
		if (this.options.parent.isExchange) {
			const exchangeParams = this.options.exchangeParameters;
			const route = this.searchModel.get('routes')[0];

			route.set('departure', exchangeParams.parameters.route[0].departure);
			route.set('arrival', exchangeParams.parameters.route[0].arrival);
			route.set('dateTo', exchangeParams.parameters.route[0].date);
			route.set('changed', exchangeParams.parameters.route[0].changed);
		}
		this.model = this.searchModel.get('routes')[0];

		this.listenTo(this.model, 'change:dateFrom', (model, value) => {
			if (!value) {
				this.searchModel.set('routeType', 'ONEWAY');
			} else {
				this.searchModel.set('routeType', 'ROUNDTRIP');
			}
		});
		this.render();
	},

	render() {
		const params = _.extend(
			{},
			this.model ? this.model.attributes : {},
			this.searchModel.attributes,
			this.options,
		);
		const container = this.$el;
		this.setElement(this.template.call(this, params));
		container.append(this.$el);
	},

	// EVENTS
	swapLocations(e) {
		if (e != null) {
			e.preventDefault();
		}

		this.searchModel.swapLocations();
	},

	search(e) {
		if (e != null) {
			e.preventDefault();
		}

		const {parent} = this.options;
		let model = this.searchModel.toJSON();

		let url = '/midoffice/ibecorp-b2b/search/getTransport';

		if (parent.isExchange) {
			url = '/midoffice/ibecorp-b2b/booking/exchange/flights';
			model = {
				parameters: {
					route: _.map(model.parameters.routes, (r) => {
						return {
							departureCode: r.departure.uid,
							arrivalCode: r.arrival.uid,
							date: r.date,
							changed: true,
						};
					}),
					productUids: this.options.exchangeParameters.parameters.productUids,
					booking: this.options.exchangeParameters.parameters.booking,
				},
			};
		}

		STATE.showLoader();
		this.disableElements(e);

		axios.post(url, model, this.searchModel).then((response) => {
			let {result} = response.data;
			if (STATE.checkViewport('(max-width: 768px)')) this.toggleSearchFormMobile();

			if (result != null && result.messages != null && result.messages.length > 0) {
				const messages = result.messages.reduce((prev, curr) => {
					let resultMessages = prev;
					resultMessages += `${curr.text || curr}<br/>`;
					return resultMessages;
				}, '');
				const popup = new Widgets.Popup({
					content: `${L10N.get('cabinet.menu.reportAgency')}<br />${messages}`,
					closeOnlyOnAction: false,
					type: 'info',
					actions: [{
						label: L10N.get('Common.close'),
						action: () => {
							popup.hide();
						},
					}],
					classes: 'b-reload-popup',
				});
				popup.show();
			}
			STATE.hideLoader();
			if (this.options.isExchange) {
				const routes = this.searchModel.get('routes');
				this.options.exchangeParameters.parameters.route = routes;
				STATE.setSearchResult(
					_.extend({}, {flights: result}, {exchangeParameters: this.options.exchangeParameters}),
				);
				parent.originalExchangeFlights = result;
				result = {
					flights: _.map(result, (item) => {
						item.flightItem.flight.selected = item.selected;
						return {
							flight: item.flightItem.flight,
							flightToken: item.flightItem.flightToken,
							hasExtendedFare: item.flightItem.hasExtendedFare,
						};
					}),
					bestPrices: [],
				};
			} else {
				STATE.setSearchResult(result);
			}

			if (!this.isExchange) STORE.set(STATE.ROUTES.MAIN_SEARCH, STATE.getSearchModel().attributes, 14400);
			STATE.setFormState('avia:filter', null);

			STATE.navigate('avia/tickets');
			this.model.set('changed', false);

			const searchParameters = STATE.getSearchParametrs();
			parent.isRoundTrip = (searchParameters.routeType === 'ROUNDTRIP');

			parent.hasTravelPolicy = (result.hasTravelPolicy === true);
			parent.approvalAllowed = (result.approvalAllowed === true);
			parent.issueAllowed = (result.issueAllowed === true);

			parent.results = this.options.isExchange ? result : result.avia[0];
			parent.bestPrices = parent.results.bestPrices;

			parent.renderTickets();
		});
	},

	openFiltersMobile() {
		$('.l-layout').addClass('show-filters');
	},

	toggleSearchFormMobile() {
		const lLayout = $('.l-layout');
		const shouldOpen = !lLayout.hasClass('show-search-form');
		this.ui.mobileSearchFormBackground[shouldOpen ? 'show' : 'hide']();
		lLayout.toggleClass('show-search-form', shouldOpen);
	},

	createPikadayModalView() {
		this.pikadayModal = GlUl.createPikadayModalView(this.model);
	},

	clearPikadayModalView() {
		this.pikadayModal.destroy();
		this.pikadayModal = null;
	},

	destroy(...args) {
		this.clearPikadayModalView();
		BaseView.prototype.destroy.apply(this, args);
	},

	showPikadayModal(e) {
		if (this.pikadayModal == null) this.createPikadayModalView();
		GlUl.showPikadayModal(e, this.pikadayModal);
	},
});
