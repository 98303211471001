import React from "react";
import Formatter from "../../../js/utils/formatter";
import { Money } from "../../helpers/interfaces/Money";

interface FormattedPriceProps {
	price?: Money;
}

const FormattedPrice: React.FC<FormattedPriceProps> = (props) => {
	const { price } = props;

	if (!price) {
		return null;
	}
	return (
		<>
			{Formatter.formatMoney(price.amount, Formatter.getDecimal(price.amount))}{" "}
			<span className="currency">
				{Formatter.formatCurrency(price.currencyCode, true)}
			</span>
		</>
	);
};
export default FormattedPrice;
