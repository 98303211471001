// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';
import TripServicesModel from './tripServicesModel';

export default BaseModel.extend({

	defaults() {
		return {
			to: new TripServicesModel(),
			from: new TripServicesModel(),
			additionalServiceTypes: {
				to: {},
				from: {},
			},
		};
	},

	toJSON() {
		const data = BaseModel.prototype.toJSON.call(this);
		const additionalServiceTypes = [];
		const tableCaptions = [];
		_.each([data.additionalServiceTypes.to, data.additionalServiceTypes.from], (item, i) => {
			if (_.isEmpty(item)) {
				additionalServiceTypes.push([]);
				return;
			}
			const result = [];
			_.each(item, (value, key) => {
				if (key === 'TABLE') tableCaptions.push(data[i === 0 ? 'to' : 'from'].tableCaption);
				result.push({uid: key, caption: value});
			});

			additionalServiceTypes.push(result);
		});
		data.additionalServiceTypes = additionalServiceTypes;

		return {types: data.additionalServiceTypes, tableCaptions};
	},
});
