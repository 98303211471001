module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-passengers  ' +
((__t = ( classes )) == null ? '' : __t) +
'">\n	<div class="b-passengers__wrapper">\n		<span class="b-passengers__input"></span>\n		<div class="b-passengers__select">\n			<div class="b-passengers__select-img"></div>\n		</div>\n	</div>\n	<div class="b-passengers__popup">\n		<ul class="b-passengers__list">\n			';
 _.each(types, function (type) { ;
__p += '\n				<li class="b-passengers__item" data-type="' +
((__t = ( type.uid )) == null ? '' : __t) +
'">\n					<div class="b-passengers__item-content">\n						<div class="b-passengers__item-label">\n							' +
((__t = ( type.caption )) == null ? '' : __t) +
'\n						</div>\n\n						<div class="b-passengers__item-controls">\n							<span class="b-passengers__item-minus">&#8211;</span>\n							<span class="b-passengers__item-value"></span>\n							<span class="b-passengers__item-plus">+</span>\n						</div>\n					</div>\n\n					';
 if (type.uid === 'CHILD') { ;
__p += '\n						<div class="b-passengers-hotels__child-age dni">\n							<div class="b-passengers-hotels__child-age-title">\n								' +
((__t = ( L10N.get('Widgets.hotels.childrenAges') )) == null ? '' : __t) +
':\n							</div>\n							<div class="b-passengers-hotels__child-age__content"></div>\n						</div>\n					';
 } ;
__p += '\n				</li>\n			';
 }); ;
__p += '\n			<div class="b-passengers__info-extra-age b-passengers__info-extra-age--hotel"></div>\n		</ul>\n	</div>\n</div>\n';

}
return __p
}