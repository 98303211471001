// eslint-disable-next-line banned-modules
'use strict';

import BaseView from '@/classes/base.view';
import template from './template.ejs';
import './style.less';

export default BaseView.extend({

	template,

	initialize() {
		this.render();
	},

});
