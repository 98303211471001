// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

import AdditionalServicesModel from './model';

export default BaseView.extend({

	template,

	initialize(options) {
		this.options = options;
		this.parent = this.options.parent;
		this.parentModel = this.parent.model;
		this.baseView = this.options.baseView;
		this.additionalServices = options;

		const {to, from} = this.options;

		if (!this.model) {
			this.model = new AdditionalServicesModel({to, from});
		}

		this.render({
			model: this.model,
			to,
			from,
		});

		this.addEventListeners();
	},

	addEventListeners() {
		const model = this.model;
		const paths = ['to', 'from'];

		const additionalServiceTypesHandler = (services, path) => {
			this.listenTo(model.get(path).get('serviceTypes'), `change`, (thisModel) => {
				const servicesTypes = thisModel.attributes;
				_.each(thisModel.changed, (value, key) => {
					if (value === true) {
						const service = _.find(services, (s) => s && s.type.uid === key);
						servicesTypes[key] = service.type.caption;
					} else {
						delete servicesTypes[key];
					}

					if (key === 'TABLE') {
						model.set(`${path}.showTableCaption`, value);

						const $tableCaptionContainer = this.$(`.b-additional-services__table-service__table-caption-container.${path}`);
						if (value === true) {
							$tableCaptionContainer.find('input').removeAttr('disabled');
							$tableCaptionContainer.find('textarea').removeAttr('disabled');
						} else {
							$tableCaptionContainer.find('input').attr('disabled', 'disabled');
							$tableCaptionContainer.find('textarea').attr('disabled', 'disabled');
						}
						if (STATE.checkViewport('(max-width: 768px)')) {
							$tableCaptionContainer[value ? 'show' : 'hide'](400, 'swing');
						}
					}
				});
				model.set(`additionalServiceTypes.${path}`, servicesTypes);
				model.trigger('change:additionalServiceTypes');
			});

			_.each(services, (service) => {
				const serviceTypes = model.get(path).get('serviceTypes');
				serviceTypes.set(service.type.uid, serviceTypes.get(service.type.uid) != null ? true : service.defaultInclude);
			});
		};

		_.each(paths, (path) => {
			if (_.isEmpty(this.options[path])) return;

			const serviceTypes = model.get(path).get('serviceTypes');
			const additionalServices = this.options[path].additionalServices;
			additionalServiceTypesHandler(additionalServices, path);
			const clearSeatTypes = () => {
				const seatTypes = ['SEAT', 'INFANT_SEAT', 'BOOSTER'];
				const temp = serviceTypes.attributes;
				_.each(seatTypes, (t) => {
					serviceTypes.changed[t] = false;
				});
				serviceTypes.trigger('change', serviceTypes, temp);
			};
			const seatTypesContainer = this.$el.find(`.b-additional-services__seat-services--${path}`);

			this.listenTo(model.get(path), `change:showSeatTypes`, (thisModel, value) => {
				if (value) {
					seatTypesContainer.addClass('open');
				} else {
					clearSeatTypes();
					seatTypesContainer.find('.b-checkbox__input').prop('checked', false);
					seatTypesContainer.removeClass('open');
				}
			});

			if (model.get(`${path}.showSeatTypes`)) {
				seatTypesContainer.addClass('open');
			}

			if (STATE.checkViewport('(max-width: 768px)')) {
				const showTableCaption = model.get(`${path}.showTableCaption`);
				const $tableCaptionContainer = this.$(`.b-additional-services__table-service__table-caption-container.${path}`);
				$tableCaptionContainer[showTableCaption ? 'show' : 'hide'](400, 'swing');
			}
		});

		this.listenTo(model, 'change:additionalServiceTypes', this.reprice.bind(this));
	},

	removeListeners() {
		_.each(['to', 'from'], (path) => {
			this.stopListening(this.model.get(path).get('serviceTypes'));
			this.stopListening(this.model.get(path));
		});
		this.stopListening(this.model);
	},

	reprice() {
		this.parent.trigger('reprice');
	},

});
