'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';
import suggestionTemplate from './suggestion.ejs';
import 'corejs-typeahead/dist/typeahead.jquery';
import axios from 'axios';

export default Widget.extend({

	validationTemplate,

	template,

	viewName: 'b-airlines-input',

	ui: {
		all: '.b-airlines-input',
		removeButton: '.b-input__select__remove',
	},

	events: {
		'click .b-input__select__remove': 'removeData',
	},

	/* el: 'div',*/

	initialize(options) {
		this.options = options;
		this.options.placeholder = this.options.placeholder != null ? this.options.placeholder : null;
		this.queryHandler = this.queryHandler.bind(this);
		this.render();
		this.$typeahead = this.getElements('input');
		this.$typeahead.typeahead(
			{
				hint: false,
				highlight: false,
				minLength: 0,
				limit: 1,
				classNames: {
					menu: 'b-airlines-input__items',
					wrapper: 'b-airlines-input__wrapper',
					input: 'b-airlines-input__input',
					hint: 'b-airlines-input__hint',
					dataset: 'b-airlines-input__dataset',
					suggestion: 'b-airlines-input__item',
					selectable: 'b-airlines-input__selectable',
					empty: 'b-airlines-input__empty',
					open: 'b-airlines-input__open',
					cursor: 'b-airlines-input__cursor',
					highlight: 'b-airlines-input__highlight',
				},
			},
			{
				name: 'airlines',
				limit: 200,
				source: (q, cb, async) => {
					clearTimeout(this.timer);
					this.timer = setTimeout(this.queryHandler, STATE.QUERY_DELAY, q, cb, async);
				},
				display: (item) => `${item.airportCaption} (${item.code})`,
				async: true,
				templates: {
					suggestion: suggestionTemplate,
				},
			});
	},

	queryHandler(q, cb, async) {
		axios.post('/midoffice/ibecorp-b2b/avia/getAirlines', {
			parameters: {
				pattern: q,
				limit: 100,
			},
		})
			.then((resp) => {
				async(resp.data.result.airlines.reduce((result, item) => {
					result.push(item);
					return result;
				}, []));
			});
	},

	removeData() {
		this.model.set(this.options.bindingProperty, null);
	},

	applyBinding(model) {
		this.$typeahead.on('typeahead:select', (ev, suggestion) => {
			model.set(this.options.bindingProperty, suggestion);
		});

		this.listenTo(model, `validationError:${this.options.bindingProperty}`, () => {
			this.$el.addClass('validation-error');
		});

		this.listenTo(model, `change:${this.options.bindingProperty}`, (__model, airline) => {
			if (airline) {
				this.$typeahead.trigger('typeahead:select', airline);

				if (airline.caption != null) {
					this.$typeahead.typeahead('val', airline.caption);
				}
				this.ui.removeButton.removeClass('dn');
			} else {
				this.$typeahead.typeahead('val', '');
				this.ui.removeButton.addClass('dn');
			}
		});

		const value = model.get(this.options.bindingProperty);
		if (value) {
			const caption = model.get(this.options.bindingProperty).caption ||
				`${model.get(this.options.bindingProperty).airportCaption} (${model.get(this.options.bindingProperty).code})`;
			this.$typeahead.typeahead('val', caption);
			this.ui.removeButton.removeClass('dn');
		}
	},

});
