module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<span class="b-airlines-input b-input ' +
((__t = (classes )) == null ? '' : __t) +
'">\r\n	<span class="b-input__label">' +
((__t = (label )) == null ? '' : __t) +
'</span>\r\n	<input type="text" placeholder="' +
((__t = ( placeholder || L10N.get('searchForm.selectAirlines') )) == null ? '' : __t) +
'" class="b-airlines-input__input b-input__value" />\r\n    <div class="b-input__select__remove dn"></div>\r\n</span>\r\n';

}
return __p
}