module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var checkboxId = typeof id !== 'undefined' ? id : '' ;
__p += '\r\n\r\n<label class="image-checkbox__label ' +
((__t = (classes )) == null ? '' : __t) +
'">\r\n	<input id="' +
((__t = ( checkboxId )) == null ? '' : __t) +
'" type="checkbox" class="image-checkbox__input visually-hidden">\r\n	<div class="image-checkbox__icon image-checkbox__icon--' +
((__t = ( modifier )) == null ? '' : __t) +
'">\r\n		<!-- ' +
((__t = ( desc )) == null ? '' : __t) +
' -->\r\n		\r\n		' +
((__t = ( price )) == null ? '' : __t) +
' ' +
((__t = ( currency )) == null ? '' : __t) +
'\r\n		<span class="image-checkbox__add-block">Добавить</span>\r\n	</div>\r\n</label>';

}
return __p
}