// eslint-disable-next-line banned-modules
'use strict';

import MultipleSelect from '@/widgets/b-multiple-select/index';

export default (() => {
	// пока сделан только для мультиселектов

	class CabinetProductsWidgetsHelper {
		$parentViewEl = null;

		parentModel = null;

		widgetsParams = [];

		constructor(options) {
			this.$parentViewEl = options.$parentViewEl;
			this.parentModel = options.parentModel;
			this.widgetsParams = options.widgetsParams;
		}

		getWidget = ({ key, items, label, placeholder, classes }) => {
			const widgetParams = {
				bindingProperty: key,
				label,
				placeholder,
				classes,
			};
  
			const widget = new MultipleSelect(
				_.extend({}, widgetParams, {
					removable: true,
					values: MultipleSelect.storeCollection(
						MultipleSelect.dictionaryToCollection(this.getWidgetCollection(items)),
					),
				}),
			);
  
			return widget;
		};
  
		addWidgetElement = (widget, selector) => {
			if (widget == null) {
				return;
			}
  
			widget.applyBinding(this.parentModel);
			this.$parentViewEl.find(selector).append(widget.$el);
		};
  
		getWidgetCollection = (data) => {
			return _.compact(
				_.map(data.slice(), (item) => {
					if (_.isObject(item) && item.uid != null && item.caption != null) {
						return _.pick(item, ['uid', 'caption']);
					}
  
					return null;
				}),
			);
		};
  
		renderWidgets = () => {
			if (this.$parentViewEl === null || this.parentModel === null) return;
			this.widgetsParams.forEach((widgetParams) => {
				const {
					key,
					items,
					label,
					placeholder,
					classes,
					selector,
				} = widgetParams;
				const widget = this.getWidget({
					key,
					items,
					label,
					placeholder,
					classes,
				});
				this.addWidgetElement(widget, selector);
				widget.delegateEvents();
			});
		};
	}

	return CabinetProductsWidgetsHelper;
})();
