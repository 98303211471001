// eslint-disable-next-line banned-modules
'use strict';

import ReasonCodeView from '@/blocks/elements/b-reason-code/index';
import KeyContactView from '@/blocks/elements/b-key-contact/index';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

export default BaseView.extend({
	template,

	ui: {
		reasonCode: '.b-booking__reason-code',
		keyContact: '.b-booking__key-contact',
	},

	initialize() {
		const { options = {} } = this;
		const { model } = options;
		if (!model) {
			throw new Error('Model not found');
		}
		this.model = options.model;
		
		this.render();
	},

	update(data) {
		const { reasonCode, keyContact } = data;

		this.reasonCode = reasonCode;
		this.keyContact = keyContact;
		this.isVisible = !!reasonCode || !!keyContact;

		if (this.isVisible) {
			this.$el.show();
		} else {
			this.$el.hide();
		}

		this.renderReasonCode();
		this.renderKeyContact();
	},

	renderReasonCode() {
		if (this.reasonCode && this.reasonCode.values) {
			this.ui.reasonCode.html(
				new ReasonCodeView({
					model: this.model,
					reasonCode: this.reasonCode,
				}).$el,
			);
			this.ui.reasonCode.show();
		} else {
			this.model.unset('reasonCode');
			this.ui.reasonCode.hide();
		}
	},
  
	renderKeyContact() {
		if (this.keyContact && this.keyContact.values) {
			this.ui.keyContact.html(
				new KeyContactView({
					model: this.model,
					keyContact: this.keyContact,
				}).$el,
			);
			this.ui.keyContact.show();
		} else {
			this.model.unset('keyContact');
			this.ui.keyContact.hide();
		}
	},

	render(...args) {
		this.setElement(this.template());
		BaseView.prototype.render.apply(this, args);
	},
});
