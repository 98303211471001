// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';
import View from './index';

export default BaseModel.extend({

	View,

	toJSON() {
		return BaseModel.prototype.toJSON.call(this);
	},

});
