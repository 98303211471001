/* eslint-disable no-use-before-define */
/* eslint-disable banned-modules */
/* eslint-disable-next-line banned-modules */
'use strict';

import axios from 'axios';
import './utils/axios';
import '../assets/css/styles.less';
import Time from './utils/time';
import $ from 'jquery';

/* fix for es6 import syntax and providePlugin */
import STATE from './classes/application-state';
import GlUl from './utils/global-utils';
import Widgets from './widgets/widgets';
import L10N from './utils/L10N';
import STORE from './utils/store';

window.Widgets = Widgets;
window.STORE = STORE;
window.GlUl = GlUl;
window.L10N = L10N;
/* end fix */
const App = () => {
	L10N.load().then(() => {
		axios.get('/midoffice/ibecorp-b2b/site/getGeneralSettings').then((response) => {
			const {settings, siteType, b2cAsB2b, siteTitle, siteIcon} = response.data.result;
			STATE.generalSettings = settings;
			STATE.setDefaultLocale();
			STATE.setSiteTypeSettings(_.extend({}, {siteType, b2cAsB2b}));

			if (siteTitle) {
				STATE.setSiteTitle(siteTitle);
				$('title').html(siteTitle);
			}

			if (siteIcon) {
				const contentType = siteIcon.slice(siteIcon.indexOf(':') + 1, siteIcon.indexOf(';'));
				const $link = $(`<link rel="icon" type="${contentType}" href="${siteIcon}">`);
				if (contentType === 'image/x-icon') {
					$link.attr('rel', 'shortcut icon');
				}
				$('head').append($link);
			}

			let firedEvents = [];
			let timeout = null;
			window.addEventListener('scrollToValidatedParameters', (e) => {
				firedEvents.push(e.detail);
				clearTimeout(timeout);
				timeout = setTimeout(() => {
					const lowestNumber = {left: Number.POSITIVE_INFINITY, top: Number.POSITIVE_INFINITY};
					_.each(firedEvents, (el) => {
						const offset = el.offset;
						if (offset.top < lowestNumber.top) lowestNumber.top = offset.top;
					});
					_.each(_.filter(firedEvents, (el) => el.offset.top <= lowestNumber.top), (el) => {
						const offset = el.offset;
						if (offset.left < lowestNumber.left) lowestNumber.left = offset.left;
					});
					const firstOnPageWithError = _.find(firedEvents, (el) => {
						return el.offset.top === lowestNumber.top && el.offset.left === lowestNumber.left;
					});

					if (firstOnPageWithError) {
						firstOnPageWithError.view.el.scrollIntoView({block: 'center', behavior: 'smooth'});
						const $input = firstOnPageWithError.view.$el.find('input');
						if ($input.length) {
							$input.focus();
						}
					}
					firedEvents = [];
					timeout = null;
				}, 0);
			});

			window.addEventListener('pageViewInitialized', (e) => {
				const pageView = STATE.getPageView();
				if (!e || !e.detail || !e.detail.view || !pageView) return;
				const {view} = e.detail;
				if ((view !== STATE.layout) && (view !== STATE.loginView) && !(view.$el.hasClass('b-popup'))) {
					STATE.setPageViewRelatedViews([...(STATE.getPageViewRelatedViews() || []), {
						pageView,
						view: e.detail.view,
					}]);
				}
			});
			let interval;
			$('body').tooltip({selector: '[data-toggle=tooltip]'}).on('shown.bs.tooltip', () => {
				if (interval) {
					return;
				}

				interval = setInterval(() => {
					const $tooltips = $('body > .tooltip');
					if ($tooltips.length === 0) {
						clearInterval(interval);
						interval = null;
						return;
					}

					$tooltips.each((i, tooltip) => {
						if ($(`[aria-describedby="${tooltip.id}"]`).length === 0) tooltip.remove();
					});
				}, 1500);
			});

			setInterval(() => {
				Object.keys(localStorage).forEach((key) => {
					if (!key) return;
					if (key.length >= 60) STORE.remove(key);
				});
			}, 60000);

			Time.loadLanguage(STATE.getLanguage());

			return STATE.generalSettings;
		}).then(() => {
			const AppWrapper = require('./appView').default;
			new AppWrapper();
		});
	});
};

const AppModule = new App();

// eslint-disable-next-line no-undef
export default AppModule;
