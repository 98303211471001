module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var createPersonAllowed = createPersonAllowed != null ? createPersonAllowed : true ;
__p += '\n<div class="p-cabinet-passengers">\n	<div class="b-block-container">\n		<div class="b-block-container__header">\n			<div class="b-block-container__header-label">' +
((__t = ( L10N.get('cabinet.passenger.title') )) == null ? '' : __t) +
'</div>\n			<div class="b-block-container__header-right js-passenger-add-container">\n                ';
 if (createPersonAllowed) { ;
__p += '\n                    <button class="btn btn-icon btn-icon-plus b-passenger-add js-passenger-add ml-auto">\n                        ' +
((__t = ( L10N.get('cabinet.passenger.addPassenger') )) == null ? '' : __t) +
'\n                    </button>\n                ';
 } ;
__p += '\n			</div>\n		</div>\n		<div class="b-block-container__top-controls">\n			<div class="b-block-container__element b-block-container__element--search">\n				<span class="js-widget"  onclick=\'return new Widgets.Input({bindingProperty: "pattern", label: L10N.get("cabinet.finance.pattern"), placeholder: L10N.get("cabinet.finance.patternRemark"), classes: "b-input__search" })\'></span>\n			</div>\n			<div class="b-block-container__element js-passenger-add-mobile-container"></div>\n		</div>\n		<div class="b-block-container__content-passengers">\n			<div class="b-block-container__content"></div>\n			<div class="b-block-container__footer"></div>\n		</div>\n	</div>\n</div>\n';

}
return __p
}