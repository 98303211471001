// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';

export default Widget.extend({

	template,

	events: {
		'change .b-radiobutton__input': 'change',
	},

	ui: {
		input: '.b-radiobutton__input',
		label: '.b-radiobutton__label',
	},

	initialize() {
		this.filterOptions(this.options);
		this.render();

		if (this.options.disabled) {
			this.disable(true);
		}
	},

	disable(disabled) {
		this.disabled = disabled;
		this.ui.input.attr('disabled', disabled);
	},

	change() {
		this.model.set(this.options.bindingProperty, this.ui.input.val());
	},

	filterOptions(options) {
		Object.keys(options).forEach((key) => {
			if (options.hasOwnProperty(key) && options[key] != null) {
				options[key] = options[key].replace(/(?:\r\n|\r|\n)/g, '');
			}
		});
	},

	applyBinding(model) {
		this.model = model;
		if (this.model.has(this.options.bindingProperty) && String(this.model.get(this.options.bindingProperty)) === String(this.ui.input.val())) {
			this.ui.input.prop('checked', this.ui.input.val());
		}
	},

});
