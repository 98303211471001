module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<table class="b-nearest-dates-matrix__table b-nearest-dates-matrix__oneway">\r\n	<tr class="b-nearest-dates-matrix__row-direction">\r\n		<th class="b-nearest-dates-matrix__table-from" colspan="7">' +
((__t = ( L10N.get('nearestMatrix.to') )) == null ? '' : __t) +
'</th>\r\n	</tr>\r\n	<tr class="b-nearest-dates-matrix__row-dates">\r\n		';
 _.each(matrixDaysTo, function (matrixDateTo) { ;
__p += '\r\n			<th class="b-nearest-dates-matrix__table-date ' +
((__t = ( matrixDateTo == dateFrom ? 'current' : '' )) == null ? '' : __t) +
'">\r\n				' +
((__t = ( new Time(matrixDateTo).format(L10N.get('nearestMatrix.dateFormat')) )) == null ? '' : __t) +
'\r\n			</th>\r\n		';
 }) ;
__p += '\r\n	</tr>\r\n	<tr class="b-nearest-dates-matrix__row-prices">\r\n	';
  _.each(matrixDaysTo, function(day, i) {  ;
__p += '\r\n		';

			function gfindDayFlight(cDay) {
				for (var i = 0; i < datesMatrix.length; i++) {
					for (var j = 0; j < datesMatrix[i].length; j++) {
						if (datesMatrix[i][j] && datesMatrix[i][j].departureTo === cDay) {
							return datesMatrix[i][j];
						}
					}
				}
			}

			var flight = gfindDayFlight(day);
 		;
__p += '\r\n		';
 if (flight) { ;
__p += '\r\n			';

				var classes = [];
				classes.push("b-nearest-dates-matrix__table-price");
				classes.push("b-nearest-dates-matrix__table-price" + i);
			;
__p += '\r\n\r\n			<td class="' +
((__t = (classes.join(' ') )) == null ? '' : __t) +
'" data-to="' +
((__t = ( flight.departureTo )) == null ? '' : __t) +
'" data-price="' +
((__t = ( flight.amount )) == null ? '' : __t) +
'" >\r\n				<span>\r\n					' +
((__t = ( L10N.get('nearestMatrix.up') )) == null ? '' : __t) +
'&nbsp;\r\n					' +
((__t = ( flight.amount )) == null ? '' : __t) +
'&nbsp;\r\n					' +
((__t = ( Formatter.formatCurrency(flight.currencyCode) )) == null ? '' : __t) +
'\r\n				</span>\r\n			</td>\r\n		';
 } else  { ;
__p += '\r\n			<td></td>\r\n		';
 } ;
__p += '\r\n	';
 }) ;
__p += '\r\n	</tr>\r\n</table>\r\n';

}
return __p
}