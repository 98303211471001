module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-filters__line ' +
((__t = ( classes )) == null ? '' : __t) +
'">\n	<div class="b-filters__block b-selection">\n		<span class="b-selection__label">' +
((__t = ( label )) == null ? '' : __t) +
'</span>\n		<div class="b-selection__values"></div>\n		<div class="b-selection__more dn collapsed"></div>\n	</div>\n</div>\n';

}
return __p
}