// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';
import templateSmall from './template-small.ejs';

const CollectionView = BaseView.extend({

	template,

	events: {
		'click .b-cabinet-collection__add': 'addNewItem',
	},

	initialize() {
		this.options.aclCreateGranted = true;
		_.each(['aclEditGranted', 'aclViewGranted', 'aclDeleteGranted', 'aclCreateGranted'], (el) => {
			this.collection.each((model) => {
				const value = model.get(el);
				model.set(el, value != null ? value : true);
			});
			if (el === 'aclCreateGranted' && _.some(this.collection.models, (m) => m.get(el) === false)) this.options.aclCreateGranted = false;
		});
		this.render();
		this.listenTo(this.collection, 'add', () => {
			_.each(['aclEditGranted', 'aclViewGranted', 'aclDeleteGranted', 'aclCreateGranted'], (el) => {
				this.collection.each((model) => {
					const value = model.get(el);
					model.set(el, value != null ? value : true);
				});
				if (el === 'aclCreateGranted' && _.some(this.collection.models, (m) => m.get(el) === false)) this.options.aclCreateGranted = false;
			});
			this.render();
		});
		this.listenTo(this.collection, 'remove', () => {
			if (STATE.getPageView().removeProcess === true) return;
			this.render();
		});
		this.listenTo(this.collection, 'reset', () => {
			this.render();
		});
		this.listenTo(this.collection, 'noWidgetListening', (key, value) => {
			this.addNewItem();
			const model = this.collection.models[this.collection.models.length - 1];
			model.triggerValidation(key, value);
		});
	},

	addNewItem() {
		this.collection.add(new this.collection.model());
	},

	render() {
		if (this.options.render) {
			this.options.render.call(this);
			return;
		}
		this.renderTemplate(this.options.type !== 'small');
	},

	renderTemplate(isCommon) {
		this.setElement(isCommon ? template(this.options) : templateSmall(this.options));
		const content = this.$('.b-cabinet-collection__content');
		this.collection.each(item => {
			// IBECORP-6499: отображаем блок организации только если нужный флаг выставлен в модели
			if (item.get('aclViewGranted')) {
				const options = _.extend({}, {model: item}, this.options.collectionViewOptions);
				const tempView = new item.View(options);
				if (tempView.applyBinding) {
					tempView.applyBinding(item);
				}
				content.before(tempView.$el);
			}
		});
		this.options.container.empty().append(this.$el);
	},

});

export default CollectionView;
