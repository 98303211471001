// eslint-disable-next-line banned-modules
'use strict';

import BaseView from '@/classes/base.view.js';
import template from './item.ejs';

export default BaseView.extend({

	template,

	ul: {
		price: '.b-selection__value-price',
	},

	initialize(options) {
		const { disabled = false } = options;
		this.render(!this.model.has('icon') ? {
			icon: '',
			disabled,
		} : {disabled});

		this.listenTo(this.model, 'change:price', (model, value) => {
			this.ui.price.text(value);
		});
		this.listenTo(this.model, 'change:value', (model, value) => {
			if (value) {
				this.$el.addClass('active');
			} else {
				this.$el.removeClass('active');
			}
			this.model.get('parent').trigger('change:value');
		});
	},

});
