// eslint-disable-next-line banned-modules
'use strict';

import Input from './b-input';
import Airlines from './w-airlines/index';
import AirlinesInput from './b-airlines-input/index';
import Places from './b-places/index';
import Hotels from './b-hotels-locations/index';
import Datepicker from './b-datepicker/index';
import Range from './b-range/index';
import Checkbox from './b-checkbox/index';
import Section from './w-section/index';
import Login from './w-login/index';
import Password from './w-password/index';
import Language from './w-language/index';
import Logo from './w-logo/index';
import Title from './w-title/index';
import PassengersAvia from './w-passengers/w-passengers-avia/index';
import PassengersRail from './w-passengers/w-passengers-rail/index';
import PassengersHotel from './w-passengers/w-passengers-hotel/index';
import PassengersTransfer from './w-passengers/w-passengers-transfer/index';
import Timerange from './w-timerange/index';
import Time from './w-time/index';
import ToggleButtonDouble from './w-toggle-double/index';
import ToggleButton from './w-toggle/index';
import Select from './b-select/index';
import ServiceType from './b-service-type/index';
import Phone from './b-phone';
import Gender from './b-gender';
import MiddleName from './b-middle-name';

import MaskedDate from './b-masked-input-date';
import Popup from './b-popup';
import PageSize from './w-page-size';

import Radiobutton from './b-radiobutton';
import Pagination from './w-pagination';
import Textarea from './b-textarea';

import NumerablePicker from './w-numerable-picker';
import Seats from './w-seats/index';
import CheckboxImage from './b-checkbox-image';
import RailwayCarriersInput from './b-railway-input';
import GeocoderInput from './b-geocoder-input';

import FinanceSelect from './b-finance-select';
import Autocomplete from './autocomplete-input/noquery-autocomplete';
import AgentAutocomplete from './autocomplete-input/b-agent';
import CompanyAutocomplete from './autocomplete-input/b-company';
import EmployeeAutocomplete from './autocomplete-input/b-employee';
import PassengerAutocomplete from './autocomplete-input/b-passenger';
import ContactAutocomplete from './autocomplete-input/b-booking-form-contacts';
import EmailsAutocomplete from './autocomplete-input/b-email';
import FinanceAutocomplete from './autocomplete-input/b-finance';
import TransliterationPopup from './b-transliteration-popup/index';

const Widgets = {
	Input,
	Airlines,
	AirlinesInput,
	RailwayCarriersInput,
	Places,
	Datepicker,
	Range,
	Checkbox,
	Section,
	Login,
	Password,
	Language,
	Logo,
	Title,
	PassengersAvia,
	PassengersRail,
	PassengersHotel,
	PassengersTransfer,
	Timerange,
	Time,
	ToggleButtonDouble,
	ToggleButton,
	Select,
	ServiceType,
	Phone,
	Gender,
	MiddleName,
	MaskedDate,
	Popup,
	PageSize,
	Radiobutton,
	Pagination,
	Textarea,
	FinanceSelect,
	Autocomplete,
	FinanceAutocomplete,
	AgentAutocomplete,
	CompanyAutocomplete,
	EmployeeAutocomplete,
	PassengerAutocomplete,
	ContactAutocomplete,
	EmailsAutocomplete,
	NumerablePicker,
	Hotels,
	Seats,
	CheckboxImage,
	GeocoderInput,
	TransliterationPopup,
};

// Fix for ProvidePlugin which properly works only with old module definition
/*eslint-disable */
// module.exports = Widgets;
/*eslint-enable */

export default Widgets;
