// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';
import CreateBookingModel from '@/blocks/pages/b-passengers-data/avia/createBookingModel';

const BookingModel = BaseModel.extend({

	defaults() {
		return {
			flightInfo: new BaseModel(),
			createBooking: new CreateBookingModel(),
		};
	},

	toJSON() {
		return {
			parameters: this.attributes.createBooking.toJSON.call(this),
		};
	},

	parse(resp) {
		let temp = resp;
		while (temp && temp.parameters) {
			temp = temp.parameters;
		}
		const result = {
			flightInfo: new BaseModel(temp.flightInfo),
			createBooking: new CreateBookingModel(temp.createBooking, {
				parse: true,
			}),
		};
		return result;
	},

});

export default BookingModel;
