module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    // Payment and approval buttons are showing/hiding in index.js (not in template, not here)
    var selectedTab = selectedTab || "TICKETS";

	var uncancelledReservations = _.filter(order.reservations, function (reservation) {
		return reservation.status.uid !== 'CANCELED';
	});
    var uncancelledSellReservations = _.filter(uncancelledReservations, function (reservation) {
        return reservation.issueAllowed;
    });
    var uncancelledSellReservationsCount = _.size(uncancelledSellReservations);

	var cancelledReservations = _.filter(order.reservations, function (reservation) {
		return reservation.status.uid === 'CANCELED';
	});
    var cancelledSellReservations = _.filter(cancelledReservations, function (reservation) {
        return reservation.issueAllowed;
    });
    var cancelledSellReservationsCount = _.size(cancelledSellReservations);

	var notFullFilledWarning = _.find(messages, function (message) {
		return message.code === 'notFilled';
	});

	var additionalServices = additionalServices || {};

    function renderReservationTemplate(options, reservation, travelSubject) {
        let result;
        if (travelSubject === 'HOTEL_RESERVATION') {
            result = require('./b-cabinet-order-info/b-cabinet-order-info-hotel.ejs')(options);
        } else if (['AIR', 'RAILWAY'].includes(travelSubject)) {
            result = require('./b-cabinet-order-info/b-cabinet-order-info.ejs')(_.extend(
				{}, options, {selectedTab: selectedTab, ancillaryFeesAvailable: reservation.getAncillaryFeesAvailable === true}
            ));
        } else if (travelSubject === 'TRANSFER') {
            result = require('./b-cabinet-order-info/b-cabinet-order-info-transfer.ejs')(options);
        } else if (travelSubject === 'VISA') {
            result = require('./b-cabinet-order-info/b-cabinet-order-info-visa.ejs')(options);
        } else if (travelSubject === 'BUS') {
            result = require('./b-cabinet-order-info/b-cabinet-order-info-bus.ejs')(options);
        } else if (travelSubject === 'AEROEXPRESS') {
            result = require('./b-cabinet-order-info/b-cabinet-order-info-aeroexpress.ejs')(options);
        } else if (travelSubject === 'INSURANCE_ACCIDENT') {
            result = require('./b-cabinet-order-info/b-cabinet-order-info-insurance_accident.ejs')(options);
        } else if (travelSubject === 'INSURANCE_TOURISTIC') {
            result = require('./b-cabinet-order-info/b-cabinet-order-info-insurance_touristic.ejs')(options);
        } else if (travelSubject === 'OTHER') {
            result = require('./b-cabinet-order-info/b-cabinet-order-info-other.ejs')(options);
        } else if (travelSubject === 'TAXI') {
            result = require('./b-cabinet-order-info/b-cabinet-order-info-taxi.ejs')(options);
        } else if (travelSubject === 'CAR_RENTAL') {
            result = require('./b-cabinet-order-info/b-cabinet-order-info-car_rental.ejs')(options);
        } else {
            result = require('./b-cabinet-order-info/b-cabinet-order-info-unknown.ejs')(options);
        }
        return result;
    }
;
__p += '\n<div class="b-order">\n    <button class="desktop-hide b-navigate-back-button js-navigate-back">' +
((__t = ( L10N.get('avia.goBack') )) == null ? '' : __t) +
'</button>\n	<div class="b-order__header-wrapper">\n		<div class="b-order__header">\n			<span class="b-order__header-title">\n				' +
((__t = ( L10N.get('cabinet.orders.order') )) == null ? '' : __t) +
' № ' +
((__t = ( (order.number != null) ? order.number : '-' )) == null ? '' : __t) +
'\n			</span>\n			<span class="b-order__header-info">\n                <span class="b-order__header-date">\n                    ' +
((__t = ( new Time(order.created).format('DD.MM.YY') )) == null ? '' : __t) +
'\n                </span>\n				';
 if (notFullFilledWarning) { ;
__p += '\n					<span class="missing-info-warning"\n					   data-toggle="tooltip"\n					   data-placement="bottom"\n					   data-html="true"\n					   data-original-title="<div>' +
((__t = ( notFullFilledWarning.text )) == null ? '' : __t) +
'</div>"\n					></span>\n				';
 } ;
__p += '\n			</span>\n            <span class="b-order__header-info_right">\n                ';
 if (order.client) {;
__p += '<span class="b-order__header-info-client">' +
((__t = ( order.client.caption )) == null ? '' : __t) +
'</span>';
 } ;
__p += '\n            </span>\n		</div>\n	</div>\n\n	';

		_.each(uncancelledReservations, function(reservation, index) {
			if (!reservation) return;
			var travelSubject = reservation.travelSubject.uid || '';

            var useAuthSteps = reservation.travelPolicyStatus !== undefined
                && (reservation.services
                && _.every(reservation.services, function(service) {
                    return service.authorizationSteps && _.every(service.authorizationSteps, function(aS) {
                        return aS.units && aS.units.length > 0;
                    })
                })
            );

			const options = {
                order: order,
                reservation: reservation,
                index: index,
                sellReservationsCount: uncancelledSellReservationsCount,
                useAuthSteps: useAuthSteps,
            };
	;
__p += '\n		<div\n            class="b-order-reservation ' +
((__t = ( (reservation.status.uid === 'ERROR') ? 'b-order-reservation--status-error' : '' )) == null ? '' : __t) +
'"\n            data-reservation-id="' +
((__t = ( reservation._index )) == null ? '' : __t) +
'"\n            data-reservation-subject="' +
((__t = ( travelSubject && travelSubject.toLowerCase() )) == null ? '' : __t) +
'"\n        >\n			<!-- TOTAL AND PASSENGERS INFO -->\n            ';
 if (useAuthSteps) { ;
__p += '\n                ' +
((__t = ( require('./b-cabinet-order-travel-policy-status/template.ejs')({ travelPolicyStatus: reservation.travelPolicyStatus }) )) == null ? '' : __t) +
'\n            ';
 } ;
__p += '\n			<div class="b-order__container b-order__info" data-copy-container="' +
((__t = ( index )) == null ? '' : __t) +
'">\n				' +
((__t = ( renderReservationTemplate(options, reservation, travelSubject) )) == null ? '' : __t) +
'\n			</div>\n		</div>\n	';
 }); ;
__p += '\n\n	';
 if (cancelledReservations && cancelledReservations.length > 0) { ;
__p += '\n		<div class="b-order__container b-order__cancelled">\n			<div class="b-order__container-title collapse--with-anchor mobile-hide ' +
((__t = ( (uncancelledReservations && uncancelledReservations.length > 0) ? 'collapsed' : '' )) == null ? '' : __t) +
'" data-toggle="collapse" data-target=".toggle-order__cancelled-container">\n				' +
((__t = ( L10N.get('cabinet.orders.cancellations') )) == null ? '' : __t) +
'\n			</div>\n            <div class="b-order__container-title b-order__header collapse--with-anchor--white desktop-hide ' +
((__t = ( (uncancelledReservations && uncancelledReservations.length > 0) ? 'collapsed' : '' )) == null ? '' : __t) +
'" data-toggle="collapse" data-target=".toggle-order__cancelled-container">\n                ' +
((__t = ( L10N.get('cabinet.orders.cancellations') )) == null ? '' : __t) +
'\n            </div>\n\n			<div class="toggle-order__cancelled-container collapse ' +
((__t = ( (uncancelledReservations && uncancelledReservations.length > 0) ? '' : 'show' )) == null ? '' : __t) +
'">\n				';

					_.each(cancelledReservations, function(reservation, index) {
						var travelSubject = reservation.travelSubject.uid;

                        var useAuthSteps = reservation.travelPolicyStatus !== undefined
                            && (reservation.services
                            && _.every(reservation.services, function(s) {
                                return s.authorizationSteps && _.every(s.authorizationSteps, function(aS) {
                                    return aS.units && aS.units.length > 0;
                                })
                            })
                        );

						const options = {
                            order: order,
                            reservation: reservation,
                            index: index,
                            sellReservationsCount: cancelledSellReservationsCount,
                            useAuthSteps: useAuthSteps,
                        };
				;
__p += '\n					<div\n                        class="b-order-reservation ' +
((__t = ( (reservation.status.uid === 'ERROR') ? 'b-order-reservation--status-error' : '' )) == null ? '' : __t) +
'"\n                        data-reservation-id="' +
((__t = ( reservation._index )) == null ? '' : __t) +
'"\n                        data-reservation-subject="' +
((__t = ( travelSubject && travelSubject.toLowerCase() )) == null ? '' : __t) +
'"\n                    >\n\n						<!-- CANCELLED RESERVATIONS -->\n                        ';
 if (useAuthSteps) { ;
__p += '\n                            ' +
((__t = ( require('./b-cabinet-order-travel-policy-status/template.ejs')({ travelPolicyStatus: reservation.travelPolicyStatus }) )) == null ? '' : __t) +
'\n                        ';
 } ;
__p += '\n						<div class="b-order__container  b-order__info">\n                            ' +
((__t = ( renderReservationTemplate(options, reservation, travelSubject) )) == null ? '' : __t) +
'\n						</div>\n					</div>\n				';
 }); ;
__p += '\n			</div>\n		</div>\n	';
 } ;
__p += '\n\n	<!-- ADDITIONAL SERVICES CONTAINER -->\n	<div class="b-order__container b-order__additional-services"></div>\n\n	<div class="b-order__footer">\n		<!-- COMMENT -->\n		';
 if (STATE.checkSiteType('B2B') && order.addCommentAllowed) { ;
__p += '\n            <div class="b-order__container b-order__container--type-simple b-order__notes"></div>\n        ';
 } ;
__p += '\n\n		<!-- PAYMENT -->\n		<div class="b-order__container b-order__container--type-simple b-order__payment">\n			<div class="b-order__payment-hidden" style="display: none">\n				<div class="b-order__container-header">\n					' +
((__t = ( L10N.get('cabinet.orders.payment') )) == null ? '' : __t) +
': <span class="b-order__payment-reservations-prn"></span>\n				</div>\n\n				<div class="b-order__payment-in-hotel-container"></div>\n				<div class="b-order__payment-container"></div>\n				<div class="b-order__price-container"></div>\n			</div>\n\n			<div class="b-block-container__footer">\n				<div class="b-block-container__footer-rightbar">\n                    <div class="b-block-container__footer-controls">\n                    ';
 if (order.financeDocumentsData) { ;
__p += '\n                        ';
 if (order.financeDocumentsData.clientDocumentsCreated || order.financeDocumentsData.clientDocumentsCreationPossible) { ;
__p += '\n                            ';
 if (order.financeDocumentsData.clientDocumentsCreationPossible && !_.isEmpty(this.options.booking)) { ;
__p += '\n								<button\n                                    type="button"\n                                    class="btn b-block-container__footer-control b-create-finance-documents js-create-client-finance-documents"\n                                    data-contract-type="CLIENT"\n                                >\n									' +
((__t = ( L10N.get('cabinet.orders.createClientFinanceDocuments') )) == null ? '' : __t) +
'\n								</button>\n							';
 } ;
__p += '\n                            ';
 if (order.financeDocumentsData.clientDocumentsCreated) { ;
__p += '\n								<div class="b-block-container__footer-control">\n									<span\n                                        class="b-order__info-footer-item action-link js-get-client-finance-documents"\n                                        data-contract-type="CLIENT"\n                                    >\n										' +
((__t = ( L10N.get('cabinet.orders.clientFinanceDocuments') )) == null ? '' : __t) +
' <i class="g-icon-download"></i>\n									</span>\n								</div>\n							';
 } ;
__p += '\n                        ';
 } ;
__p += '\n                        ';
 if (order.financeDocumentsData.subagentDocumentsCreated || order.financeDocumentsData.subagentDocumentsCreationPossible) { ;
__p += '\n                            ';
 if (order.financeDocumentsData.subagentDocumentsCreationPossible && !_.isEmpty(this.options.booking)) { ;
__p += '\n								<button\n                                    type="button"\n                                    class="btn b-block-container__footer-control b-create-finance-documents js-create-subagent-finance-documents"\n                                    data-contract-type="SUBAGENCY"\n                                >\n									' +
((__t = ( L10N.get('cabinet.orders.createSubagentFinanceDocuments') )) == null ? '' : __t) +
'\n								</button>\n							';
 } ;
__p += '\n                            ';
 if (order.financeDocumentsData.subagentDocumentsCreated) { ;
__p += '\n								<div class="b-block-container__footer-control">\n									<span\n                                        class="b-order__info-footer-item action-link js-get-subagent-finance-documents"\n                                        data-contract-type="SUBAGENCY"\n                                    >\n										' +
((__t = ( L10N.get('cabinet.orders.subagentFinanceDocuments') )) == null ? '' : __t) +
' <i class="g-icon-download"></i>\n									</span>\n								</div>\n							';
 } ;
__p += '\n                        ';
 } ;
__p += '\n                    ';
 } ;
__p += '\n                    ';
 if (!_.isEmpty(order.reservations) && _.some(order.reservations, function (r) {return ['REFUND', 'PAYED', 'ISSUED'].indexOf(r.status.uid) !== -1;})) { ;
__p += '\n                            <span class="b-block-container__footer-control">\n                                <span class="b-order__info-footer-item action-link js-show-booking-docs-widget">\n                                    ' +
((__t = ( L10N.get('cabinet.orders.bookingDocuments') )) == null ? '' : __t) +
'\n                                    <i class="g-icon-download"></i>\n                                </span>\n                            </span>\n                    ';
 } ;
__p += '\n                    </div>\n                    <div class="b-order__info-footer-controls-mobile js-show-mobile-controls">\n                        <span>' +
((__t = ( L10N.get('cabinet.contacts.documentsTitle') )) == null ? '' : __t) +
'</span>\n                    </div>\n                    <div class="b-order__info-footer-controls-popup js-mobile-controls desktop-hide">\n                        <div\n                            class="b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn"\n                            data-target-class=".js-create-client-finance-documents"\n                        >\n                            <span class="b-order__info-footer-controls-mobile-oneline-content">\n                                ' +
((__t = ( L10N.get('cabinet.orders.createClientFinanceDocuments') )) == null ? '' : __t) +
'\n                            </span>\n                        </div>\n                        <div\n                            class="b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn"\n                            data-target-class=".js-get-client-finance-documents"\n                            data-contract-type="CLIENT"\n                        >\n                            <span class="b-order__info-footer-controls-mobile-oneline-content">\n                                ' +
((__t = ( L10N.get('cabinet.orders.clientFinanceDocuments') )) == null ? '' : __t) +
'\n                            </span>\n                        </div>\n                        <div\n                            class="b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn"\n                            data-target-class=".js-create-subagent-finance-documents"\n                        >\n                            <span class="b-order__info-footer-controls-mobile-oneline-content">\n                                ' +
((__t = ( L10N.get('cabinet.orders.createSubagentFinanceDocuments') )) == null ? '' : __t) +
'\n                            </span>\n                        </div>\n                        <div\n                            class="b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn"\n                            data-target-class=".js-get-subagent-finance-documents"\n                            data-contract-type="SUBAGENCY"\n                        >\n                            <span class="b-order__info-footer-controls-mobile-oneline-content">\n                                ' +
((__t = ( L10N.get('cabinet.orders.subagentFinanceDocuments') )) == null ? '' : __t) +
'\n                            </span>\n                        </div>\n                        <div\n                            class="b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn"\n                            data-target-class=".js-show-booking-docs-widget"\n                        >\n                            ' +
((__t = ( L10N.get('cabinet.orders.bookingDocuments') )) == null ? '' : __t) +
'\n                        </div>\n<!--                        <div class="b-order__info-footer-controls-mobile&#45;&#45;mobile-btn js-footer-controls-btn" data-target-class=".js-copy-to-clipboard">-->\n<!--                            ' +
((__t = ( L10N.get('Common.copyToClipboard') )) == null ? '' : __t) +
'-->\n<!--                        </div>-->\n                    </div>\n                   <div class="b-block-container">\n                       <button type="button" class="btn b-order__copy-to-clipboard js-copy-to-clipboard mobile-hide">' +
((__t = ( L10N.get('Common.copyToClipboard') )) == null ? '' : __t) +
'</button>\n                       ';
 if (!_.isEmpty(additionalServices) && !_.isEmpty(this.options.booking)) { ;
__p += '\n                           <div class="b-order__additional-select">\n                               <select class="b-input__select" type="text" name="type" placeholder="' +
((__t = ( L10N.get('cabinet.orders.addService') )) == null ? '' : __t) +
'">\n                                   <option value="hide">' +
((__t = ( L10N.get('cabinet.orders.addService') )) == null ? '' : __t) +
'</option>\n                                   ';
 _.each(additionalServices, function (item)  { ;
__p += '\n                                       <option value="' +
((__t = ( item.uid )) == null ? '' : __t) +
'">\n                                           <i class="g-icon-service g-icon-service--type-' +
((__t = ( item.uid.toLowerCase() )) == null ? '' : __t) +
'"></i> ' +
((__t = ( item.caption )) == null ? '' : __t) +
'\n                                       </option>\n                                   ';
 }); ;
__p += '\n                               </select>\n                           </div>\n                       ';
 } ;
__p += '\n                       <button type="button" class="btn b-order__payment-authorization b-payment__authorization" style="display: none">\n                           <span class="mobile-hide">' +
((__t = ( L10N.get('cabinet.orders.sendToAuthorization') )) == null ? '' : __t) +
'</span>\n                           <span class="desktop-hide">' +
((__t = ( L10N.get('cabinet.orders.authorization') )) == null ? '' : __t) +
'</span>\n                       </button>\n                       <button type="button" class="btn b-order__payment-approval b-payment__approval" style="display: none">\n                           <span class="mobile-hide">' +
((__t = ( L10N.get('cabinet.orders.sendToConfirmationShort') )) == null ? '' : __t) +
'</span>\n                           <span class="desktop-hide">' +
((__t = ( L10N.get('cabinet.orders.approval') )) == null ? '' : __t) +
'</span>\n                       </button>\n                       <button type="button" class="btn b-order__payment-pay b-payment__pay b-order__payment-hidden" style="display: none">\n                           ' +
((__t = ( L10N.get('cabinet.orders.doPayment') )) == null ? '' : __t) +
'\n                       </button>\n                   </div>\n				</div>\n			</div>\n		</div>\n	</div>\n  <div class="react-reservation-statistics-popup"></div>\n</div>\n';

}
return __p
}