// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';
import BaseCollection from '@/classes/base.collection';
import RoomModel from '@/blocks/elements/b-hotel/b-hotel-room/model';

export default BaseModel.extend({

	defaults() {
		return {
			customer: new BaseModel({
				phone: {
					cityCode: '',
					countryCode: STATE.getDefaultCountryCode(),
					number: '',
				},
			}),
			rooms: new BaseCollection([], {
				model: RoomModel,
			}),
		};
	},

	toJSON() {
		const data = BaseModel.prototype.toJSON.call(this);

		if (!_.isEmpty(data.customer)) {
			if (data.customer.overrideCustomer === true) {
				data.overrideCustomer = true;
			}

			delete data.customer.disabled;
			delete data.customer.overrideCustomer;
		}

		delete data.noVisaInfo;

		/*
			Логика частично взята отсюда:
			 midoffice/ibecorp/frontend/app-b2b/js/blocks/pages/b-passengers-data/train/createBookingModel.js
			Берем клиентскую модель и адаптируем ее к модели для реста.
		*/
		if (data?.rooms?.length) {
			_.each(data.rooms, (room) => {
				if (room?.travellers?.length) {
					_.each(room.travellers, (traveller) => {
						if (typeof traveller.passport === 'object') {
							traveller.passport.withoutMiddleName = (traveller.withoutMiddleName === true);
						}
						delete traveller.withoutMiddleName;
					});
				}
			});
		}

		return {
			parameters: data,
		};
	},

	parse(resp) {
		let temp = resp;

		while (temp && temp.parameters) {
			temp = temp.parameters;
		}

		while (temp.customer && temp.customer.parameters) {
			temp.customer = temp.customer.parameters;
		}

		return Object.assign(
			{},
			temp,
			{
				customer: new BaseModel(temp.customer),
				rooms: new BaseCollection(temp.rooms, {
					model: RoomModel,
					parse: true,
				}),
			},
		);
	},

});
