// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';
import template from './template.ejs';
import Inputmask from 'jquery.inputmask/dist/inputmask/inputmask';
import 'jquery.inputmask/dist/inputmask/inputmask.extensions';
import 'jquery.inputmask/dist/inputmask/inputmask.date.extensions';

export default Widget.extend({

	validationTemplate,

	template,

	disabled: false,

	ui: {
		timerange: '.b-input__value',
		removeButton: '.w-time__remove',
	},

	events: {
		'change .b-input__value': 'change',
		'click .w-time__remove': 'removeValue',
	},

	masks: {
		timerange: {
			mask: '24 : 59',
			label: '__ : __',
		},
	},

	initialize() {
		this.render(this.options);

		this.$el.attr('timerange-id', this.cid);

		// Создаем маску
		this.inputMask = new Inputmask({
			hourFormat: '24',
			// Указываем чтобы в часах числа не первышали 24 часа в сутки
			definitions: {
				5: {validator: '[0-5]'},
				9: {validator: '[0-9]'},
				2: {validator: '[0-2]'},
				4: {
					validator: function validator(chrs, maskset) {
						// Валидатор inputmask для проверки второго значения в часах.
						// Если первый час 2 то второй не может быть больше 4 т.к. в сутках 24 часа.
						// Каждый раз при изменении второго часа приложение проваливается в этот метод.
						if (maskset.buffer[maskset.p - 1] !== '2') {
							return true;
						} else if (maskset.buffer[maskset.p - 1] === '2' && chrs < '5') {
							return true;
						}
						return false;
					},
				},
			},
			mask: this.masks.timerange.mask,
			placeholder: this.masks.timerange.label,
			clearMaskOnLostFocus: false,
			clearIncomplete: true,
		});
		this.inputMask.mask(this.ui.timerange[0]);
		this.applyInputmask();

		if (this.options.disabled) {
			this.disable(true);
		}
	},

	render(parameters) {
		const templateObject = Object.assign(
			{
				label: '',
				classes: '',
				placeholder: '',
			},
			this.options,
			parameters,
		);
		this.setElement(this.template(templateObject));
	},

	disable(disabled) {
		this.disabled = disabled;
		this.$el.find('.b-input__value').val('').attr('disabled', disabled);
	},

	dependOn() {
		if (this.options.depend) {
			this.listenTo(this.model, `change:${this.options.depend}`, (__model, value) => {
				this.applyDependency(value);
			});
			if (this.model.has(this.options.depend)) {
				this.applyDependency(this.model.get(this.options.depend));
			}
		}
	},

	applyDependency() {
	},

	applyInputmask() {
		const mask = this.masks.timerange.mask;
		const label = this.masks.timerange.label;
		const dataInputmask = document.createAttribute('data-inputmask');

		dataInputmask.value = `"mask": "${mask}", "placeholder" : "${label}"`;
		this.ui.timerange[0].attributes.setNamedItem(dataInputmask);
		this.inputMask.mask(this.ui.timerange[0]);
	},

	removeValue() {
		this.model.set(this.options.bindingProperty, null);
	},

	applyBinding(model) {
		this.model = model;
		this.listenTo(model, `change:${this.options.bindingProperty}`, (__model, value) => {
			this.$el.find('.b-input__value').val(value || '').trigger('mouseenter');

			if (value) {
				this.ui.removeButton.removeClass('dn');
			} else {
				this.ui.removeButton.addClass('dn');
			}
		});

		if (this.model.has(this.options.bindingProperty)) {
			this.$el.find('.b-input__value').val(this.model.get(this.options.bindingProperty));
			this.ui.removeButton.removeClass('dn');
		}

		this.listenTo(this.model, `validationError:${this.options.bindingProperty}`, () => {
			this.$el.addClass('validation-error');
		});
		this.dependOn();
	},

	change() {
		this.model.set(this.options.bindingProperty, this.$el.find('.b-input__value').val());
	},

});
