// eslint-disable-next-line banned-modules
'use strict';

import BaseView from '@/classes/base.view';
import BaseModel from '@/classes/base.model';
import template from './template.ejs';
import ToggleWidget from '@/widgets/w-toggle/index';
import $ from 'jquery';

import './style.less';

export default BaseView.extend({

	template,

	ui: {
		optionsContainer: '.b-train-options-container',
	},

	initialize(options) {
		this.model = new BaseModel();
		this.bookingModel = options.parent.model;
		this.formSettings = options.formSettings;

		this.render();
		this.addModelListener();

		this.addToggleWidget('beddingEnabled', true);
		this.addToggleWidget('beddingBackEnabled', true);
	},

	addModelListener() {
		this.listenTo(this.model, 'change', (model) => {
			if (!_.isObject(model.changed)) {
				return;
			}

			for (const [key, value] of Object.entries(model.changed)) {
				if (!model.changed.hasOwnProperty(key)) {
					continue;
				}

				let route;
				if (key === 'beddingEnabled') {
					route = 'routeInfo';
				} else if (key === 'beddingBackEnabled') {
					route = 'routeInfoBack';
				}

				if (route) {
					const routeInfo = this.bookingModel.get(route);

					if (!routeInfo.seatsRequest) {
						continue;
					}

					routeInfo.seatsRequest.withBed = value;
					this.bookingModel.set(route, routeInfo);
					this.bookingModel.trigger('updateTrainOptions');
				}
			}
		});
	},

	addToggleWidget(key, defaultValue = false) {
		if (this.formSettings[key]) {
			const widget = new ToggleWidget({
				bindingProperty: key,
				label: L10N.get(`trains.${key}`),
			});
			widget.applyBinding(this.model);

			this.model.set(key, defaultValue);
			const container = $('<div class="l-grid-layout-33"></div>');
			this.ui.optionsContainer.append(container);

			container.append(widget.$el);
		}
	},

});
