// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

import PassengerModel from './model.js';
import InputWidget from '@/widgets/b-input/index';
import PassengerAutocompleteWidget from '@/widgets/autocomplete-input/b-passenger/index';
import BookingAutoCorrectionAdapter from '../../../../utils/b-booking-auto-correction-adapter/index.js';
import { getAutoCorrectionWidgetsLabels } from '../../../../utils/b-booking-auto-correction-input-labels/index.js';
import TravellerCostCodesAdapter from '../../../b-cost-codes/b-traveller-cost-codes-adapter/index.js';
import Deferred from '@/utils/Deferred';
import GlUl from '@/utils/global-utils';
import axios from 'axios';

export default BaseView.extend({

	ui: {
		statisticsContainer: '.b-traveller-cost-codes__container',
		removeTravellerButton: '.b-traveller__controls',
	},

	events: {
		'click .b-traveller__remove': 'removeTraveller',
	},

	initialize(options) {
		this.index = options.index;
		this.forbidTravellerUpdate = (this.options.bookingSettings || {}).forbidTravellerUpdate;
		this.corporatePassengers = options.bookingSettings.corporatePassengers;
		this.transferView = options.transferView;
		this.addPreValidateDeferred = this.transferView.addPreValidateDeferred;
		this.removePreValidateDeferred = this.transferView.removePreValidateDeferred;
		this.organizationCostCodes = options?.bookingSettings?.organizationCostCodes; // при инициализации используем общие (глобальные) кост коды
		this.getFormSettings = this.getBookingFormSettings.bind(this);
		this.bookingFormSettingsToken = options.bookingFormSettingsToken || undefined;

		if (!this.model) {
			this.model = new PassengerModel();
		}

		if (options.isContact != null) this.model.set('isContact', options.isContact);

		this.isAdult = this.model.get('type') != null && this.model.get('type').uid === 'ADULT';

		this.autoCompleteData(_.clone(this.options.passenger));

		this.bookingAutoCorrectionAdapter = new BookingAutoCorrectionAdapter({
			routeType: STATE.router.breadcrumbs.routeType,
			transliterationOpts: this.getTransliterationOpts(),
		});

		this.listenTo(this.model.get('passport'), 'change:lastName change:firstName change:secondName change:middleName', () => {
			if (this.bookingAutoCorrectionAdapter) {
				this.bookingAutoCorrectionAdapter.updateTransliterationOpts(this.getTransliterationOpts());
			}
		});

		// LISTENERS
		this.listenTo(this.model, `typeahead:passengerSelect`, async (model, value) => {
			const dfd = new Deferred();
			this.addPreValidateDeferred(dfd);

			try {
				this.resetNonCommonCostCodes();
				await this.getFormSettings(); // <- обновляем список костКодов
	
				const container = this.$el;
				this.options.passenger = _.omit(value, 'uid');
	
				this.autoCompleteData(_.clone(this.options.passenger));
	
				this.$el.empty();
				this.render();
				container.append(this.$el);
				this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));
				dfd.resolve();
				this.removePreValidateDeferred();
			} catch (e) {
				dfd.reject(e);
			}
		});

		this.render();
	},

	getBookingFormSettings() {
		const traveller = this.model.toJSON();
		if (
			traveller.passport &&
			traveller.passport.type &&
			traveller.passport.type.uid.indexOf('.') !== -1
		) {
			traveller.passport.type.uid = traveller.passport.type.uid.split('.')[0];
		}
		const params = {
			parameters: {
				traveller,
				token: this.options.token,
				bookingFormSettingsToken: this.bookingFormSettingsToken,
			},
		};
		return axios
			.post(
				'/midoffice/ibecorp-b2b/transfers/getBookingFormSettings',
				params,
				this.model,
			)
			.then((res) => {
				this.organizationCostCodes = res.data.result?.organizationCostCodes || [];
				return res.data.result;
			});
	},

	getTransliterationOpts() {
		return {
			traveller: {
				...this.model.toJSON(),
				langRegistrationFullName: this.options.langRegistrationFullName,
			},
		};
	},

	adjustMobileTemplate(matches) {
		const $travellerHeader = this.$('.b-traveller__title');
		const $travellerInfo = this.$('.b-traveller-info');
		const $setAsContactEl = this.$('.b-traveller__title-contact');
		const $tooltips = this.$('[data-toggle="tooltip"]');
		const $mobileCollapseHeaders = this.$('.mobile-collapse');

		if (matches) {
			$setAsContactEl.insertBefore($travellerInfo.find('.b-traveller__controls'));
			_.each($tooltips, (el) => el && this.$(el).tooltip('disable'));
			_.each($mobileCollapseHeaders, (el) => el && this.$(el).attr('data-toggle', 'collapse'));
		} else {
			$setAsContactEl.insertBefore($travellerHeader.find('.b-traveller__controls'));
			_.each($tooltips, (el) => el && this.$(el).tooltip('enable'));
			_.each($mobileCollapseHeaders, (el) => {
				const $target = this.$(el);
				if ($target.hasClass('collapsed')) $target.click();
				$target.removeAttr('data-toggle');
			});
		}
	},

	autoCompleteData(passenger) {
		if (!_.isObject(passenger)) {
			return;
		}

		if (!passenger.phones && !passenger.emails) {
			GlUl.convertToValidPassenger(passenger);
		}

		const langRegistrationFullName = this.options.bookingSettings.langRegistrationFullName || {};
		const nameFields = ['firstName', 'lastName', 'secondName'];
		const passengerFields = _.flatten(_.map(nameFields, (el) => {
			switch (langRegistrationFullName.uid) {
				case 'LATIN':
					return `${el}Lat`;
				case 'CYRILLIC':
					return el;
				case 'LATIN_AND_CYRILLIC':
					return [`${el}Lat`, el];
				default:
					return el;
			}
		}));
		const data = GlUl.autocompletePassengerFields.call(this, passenger, nameFields, langRegistrationFullName, passengerFields);

		_.each(data, (value, key) => {
			this.model.set(`${key}`, value);
		});

		this.model.set('passport.email', passenger.emails[0]);
		if (_.isObject(passenger.phones[0])) {
			this.model.set('passport.phone', passenger.phones[0]);
		} else {
			this.model.set('passport.phone', {
				cityCode: '',
				countryCode: STATE.getDefaultCountryCode(),
				number: '',
			});
		}
	},

	render() {
		const {
			organizationCostCodes,
			langRegistrationFullName,
			contactPersonDisableFreeTextInput,
			contactPersonEditGranted,
		} = this.options.bookingSettings;

		this.setElement(template({
			index: this.index,
			forbidTravellerUpdate: this.forbidTravellerUpdate,
			model: this.model,
			cid: this.model.cid,
			isAdult: this.isAdult,
			organizationCostCodes,
			langRegistrationFullName,
			contactPersonDisableFreeTextInput,
			contactPersonEditGranted,
		}));

		this.renderAutoCorrectionWidgets();

		/* Statistic container */
		this.renderCostCodesView();

		if (this.options.parent.model.get('travellers').length > 1) {
			this.ui.removeTravellerButton.show();
		}
	},

	/* Cost Codes */

	resetNonCommonCostCodes() {
		if (this.costCodesAdapter) this.costCodesAdapter.resetNonCommonCostCodes();
	},

	getCommonCostCodesRenderParams() {
		return {
			renderContainer: this.ui.statisticsContainer,
			passenger: this.options.passenger,
			organizationCostCodes: this.organizationCostCodes,
		};
	},

	renderCostCodesView() {
		if (!_.isEmpty(this.organizationCostCodes)) {
			if (!this.costCodesAdapter) {
				this.costCodesAdapter = new TravellerCostCodesAdapter({
					parentView: this,
				});
			} else {
				this.costCodesAdapter.removeCostCodesView();
			}
			this.costCodesAdapter.renderCostCodesView({
				...this.getCommonCostCodesRenderParams(),
			});
		}
	},

	removeCostCodesAdapter() {
		if (this.costCodesAdapter) {
			this.costCodesAdapter.removeCostCodesView();
			this.costCodesAdapter = null;
		}
	},

	/* EOF Cost Codes */

	renderAutoCorrectionWidgets() {
		this.clearBookingAutoCorrectionAdapter();

		const {
			l10nLastNamePlaceholder,
			l10nFirstNamePlaceholder,
			l10nSecondNamePlaceholder,
		} = getAutoCorrectionWidgetsLabels({
			langRegistrationFullNameUid: this.options?.langRegistrationFullName?.uid,
			forbidTravellerUpdate: this.forbidTravellerUpdate,
		});

		if (STATE.getLoggedInUser() && !this.forbidTravellerUpdate) {
			const widget = new PassengerAutocompleteWidget({
				bindingProperty: 'passport.lastName',
				transliterationOn: true,
				label: L10N.get('bookingForm.lastName'),
				langRegistrationFullName: this.options?.langRegistrationFullName?.uid,
				placeholder: l10nLastNamePlaceholder,
				autoCorrectionAdapter: this.bookingAutoCorrectionAdapter,
				corporatePassengers: this.corporatePassengers,
			});
			widget.applyBinding(this.model);
			this.$el.find('.js-lastName-container').append(widget.$el);
			this.bookingAutoCorrectionAdapter.addWidget(widget);
		} else {
			const widget = new InputWidget({
				bindingProperty: 'passport.lastName',
				transliterationOn: true,
				runTransliterationOnFirstRender: true,
				label: L10N.get('bookingForm.lastName'),
				placeholder: l10nLastNamePlaceholder,
				disabled: this.forbidTravellerUpdate,
				autoCorrectionAdapter: this.bookingAutoCorrectionAdapter,
			});
			widget.applyBinding(this.model);
			this.$el.find('.js-lastName-container').append(widget.$el);
			this.bookingAutoCorrectionAdapter.addWidget(widget);
		}

		{
			const widget = new InputWidget({
				bindingProperty: 'passport.firstName',
				transliterationOn: true,
				runTransliterationOnFirstRender: true,
				label: L10N.get('bookingForm.firstName'),
				placeholder: l10nFirstNamePlaceholder,
				disabled: this.forbidTravellerUpdate,
				autoCorrectionAdapter: this.bookingAutoCorrectionAdapter,
			});
			widget.applyBinding(this.model);
			this.$el.find('.js-firstName-container').append(widget.$el);
			this.bookingAutoCorrectionAdapter.addWidget(widget);
		}

		{
			const widget = new InputWidget({
				bindingProperty: 'passport.secondName',
				transliterationOn: true,
				runTransliterationOnFirstRender: true,
				label: L10N.get('bookingForm.secondName'),
				placeholder: l10nSecondNamePlaceholder,
				disabled: this.forbidTravellerUpdate,
				autoCorrectionAdapter: this.bookingAutoCorrectionAdapter,
			});
			widget.applyBinding(this.model);
			this.$el.find('.js-secondName-container').append(widget.$el);
			this.bookingAutoCorrectionAdapter.addWidget(widget);
		}
	},

	removeTraveller(e) {
		e.preventDefault();
		this.options.parent.removeTraveller(this.options.index);
	},

	clearBookingAutoCorrectionAdapter() {
		if (this.bookingAutoCorrectionAdapter) {
			this.bookingAutoCorrectionAdapter.clearRunningRequest();
			this.bookingAutoCorrectionAdapter.removeAllWidgets();
		}
	},

	remove() {
		this.clearBookingAutoCorrectionAdapter();
		this.removeCostCodesAdapter();
		BaseView.prototype.remove.call(this);
	},

});
