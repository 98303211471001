'use strict';

import BaseModel from '@/classes/base.model';

export default BaseModel.extend({

	initialize(options) {
		this.set('ancillaryFees', options.ancillaryFees);
		this.set('reservationUid', options.reservationUid);
		this.set('currentBooking', options.currentBooking);
		this.set('currencyCode',
			options.ancillaryFees[0] &&
			options.ancillaryFees[0].ancillaryFees[0] &&
			options.ancillaryFees[0].ancillaryFees[0].price.currencyCode || 'RUB',
		);
	},

	getTypes(services = this.get('ancillaryFees')) {
		const travellersServices = _.flatten(_.map(services, (item) => item.ancillaryFees));
		const sorted = this.sortByType(travellersServices);
		return _.uniq(_.map(sorted, (s) => s.type.uid));
	},

	sortByType(services = this.collectAllServices()) {
		return _.sortBy(services, ['type']);
	},

	collectAllServices() {
		const services = this.get('ancillaryFees');
		return _.flatten(_.map(services, (item) => item.ancillaryFees));
	},

	sortByTraveller() {
		const ancillaryFees = this.get('ancillaryFees');
		const result = {};
		_.each(ancillaryFees, (travellerServices) => {
			result[travellerServices.travellerUid] = travellerServices.ancillaryFees;
		});

		return result;
	},

	sortByTypeAndDescription(services) {
		return _.sortBy(services, ['type', 'description']);
	},

	getSelectedServices(uid) {
		const services = this.get('selectedTravellersServices');

		return _.filter(services[uid], (s) => s.get('selected'));
	},

	getServicesSum(services) {
		return _.toString(_.reduce(services, (prev, curr) => {
			return parseFloat(curr.price.amount) + prev;
		}, 0));
	},

	toJSON() {
		const result = BaseModel.prototype.toJSON.call(this);
		const {selectedTravellersServices} = result;
		const fields = ['currentBooking', 'reservationUid', 'travellerAncillaryFees'];
		result.travellerAncillaryFees = [];

		_.each(_.keys(selectedTravellersServices), (k) => {
			result.travellerAncillaryFees.push({travellerUid: k, ancillaryFees: selectedTravellersServices[k]});
		});

		return {
			parameters: _.pick(result, fields),
		};
	},

});
