// eslint-disable-next-line banned-modules
'use strict';

import BaseView from '@/classes/base.view';
import template from './template.ejs';

export default BaseView.extend({

	template,

	initialize(options) {
		this.render(options);
	},

	render(data) {
		this.$el.append(this.template(data));
	},

});
