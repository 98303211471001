// eslint-disable-next-line banned-modules
'use strict';

import Widget from '@/widgets/base.widget';
import template from './template.ejs';

export default Widget.extend({

	template,

	spread: 2,
	separator: '<div class="b-footer-page">...</div>',
	reducedSeparator: '<div class="b-footer-page" style="min-width: 15px; max-width: 15px">.</div>',
	activeClass: 'current',

	initialize(options) {
		this.render(options);
	},

	applyBinding(model) {
		logger.debug(this.options.bindingProperty, model);
		this.model = model;
		this.listenTo(model, `change:${this.options.bindingProperty}`, (thisModel, value) => {
			this.select(value);
		});
		if (this.model.has(this.options.bindingProperty)) {
			this.select(this.model.get(this.options.bindingProperty));
		} else {
			try {
				const value = this.collection.get(this.ui.input.find('option:selected').attr('value')).attributes;
				this.model.set(this.options.bindingProperty, value);
			} catch (e) {
				logger.debug(e);
			}
		}
	},

	build(limit, count, currentPage = 1) {
		limit = parseInt(limit, 10);
		count = parseInt(count, 10);
		currentPage = parseInt(currentPage, 10);
		const isMobile = STATE.checkViewport('(max-width: 768px)');

		if (limit < 1 || count <= limit) {
			return '';
		}
		const countPages = Math.ceil(count / limit);
		if (currentPage > countPages) {
			return '';
		}

		this.spread = isMobile && countPages >= 900 ? 1 : 2;

		if (currentPage < 1) {
			currentPage = 1;
		}
		let shiftStart = Math.max(currentPage - this.spread, 2);
		let shiftEnd = Math.min(currentPage + this.spread, countPages - 1);

		if (shiftEnd < this.spread * 2) {
			shiftEnd = Math.min(this.spread * 2, countPages - 1);
		}
		if (shiftEnd === (countPages - 1) && shiftStart > 3) {
			shiftStart = Math.max(3, Math.min(countPages - (this.spread * 2) + 1, shiftStart));
		}

		let list = this.getItem(1);

		if (shiftStart === 3) {
			list += this.getItem(2);
		} else if (shiftStart > 3) {
			list += isMobile ? this[shiftEnd >= 900 ? 'reducedSeparator' : 'separator'] : this.separator;
		}

		for (let i = shiftStart; i <= shiftEnd; i++) {
			list += this.getItem(i);
		}

		const lastPage = countPages - 1;

		if (shiftEnd === lastPage - 1) {
			list += this.getItem(lastPage);
		} else if (shiftEnd < lastPage) {
			list += isMobile ? this[shiftEnd >= 900 ? 'reducedSeparator' : 'separator'] : this.separator;
		}
		list += this.getItem(countPages);

		return list;
	},

	getItem(pageNum) {
		const currentPage = parseInt(this.model.get('page'), 10) + 1;
		if (currentPage === pageNum) {
			return `<div class="b-footer-page current">${pageNum}</div>`;
		}
		return `<div class="b-footer-page js-select-page" page="${pageNum - 1}">${pageNum}</div>`;
	},

	select(page) {
		const pageSize = this.model.get('pageSize');
		const count = this.model.get('count');
		const pageNum = parseInt(page, 10) + 1;
		this.$el.html(this.build(pageSize, count, pageNum));
	},
});
