// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';
import View from './index';

export default BaseModel.extend({

	View,

	toJSON() {
		return this.get('passengers');
	},

	initialize(data) {
		this.attributes = {
			passengers: data,
		};
	},

});
