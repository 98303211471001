module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-attachment-avia__group-checkboxes--container">\n  <span class="b-search-form__param-classes">\n      <span class="js-widget"  onclick=\'return new Widgets.ToggleButtonDouble({ bindingProperty: "classOfService", valueFirst: "ECONOMY", valueSecond: "BUSINESS", labelFirst: "", labelSecond: L10N.get("searchForm.business"), classes: "as-single" })\'></span>\n  </span>\n  ';
 if (settings.firstStepSettings && settings.firstStepSettings.displayMultiTicketOption) { ;
__p += '\n      <span class="b-search-form__param-multiticket">\n          <span class="js-widget"  onclick=\'return new Widgets.ToggleButton({ bindingProperty: "multiTicket", label: L10N.get("searchForm.multiTicket") })\'></span>\n      </span>\n  ';
 } ;
__p += '\n</div>\n<div class="b-attachment-avia__group-checkboxes--container">\n  ';
 if (settings.firstStepSettings && settings.firstStepSettings.directFilterOptionEnabled) { ;
__p += '\n      <span class="b-search-form__param-direct">\n          <span class="js-widget"  onclick=\'return new Widgets.ToggleButton({ bindingProperty: "direct", label: L10N.get("searchForm.directFlight") })\'></span>\n      </span>\n  ';
 } ;
__p += '\n  ';
 if (settings.firstStepSettings && settings.firstStepSettings.displayExcludeBaglessFares) { ;
__p += '\n      <span class="b-search-form__param-bagless-fares">\n          <span class="js-widget"  onclick=\'return new Widgets.ToggleButton({ bindingProperty: "excludeBaglessFares", label: L10N.get("searchForm.luggageIncluded") })\'></span>\n      </span>\n  ';
 } ;
__p += '\n</div>';

}
return __p
}