// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

export default BaseView.extend({

	el: 'body',

	preinitialize(options) {
		if (options && options.el) {
			this.el = options.el;
		}
	},

	initialize() {
		this.render();
		this.listenTo(STATE, 'change:loading', (model, isLoading) => {
			if (isLoading) {
				this.$el.addClass('open');
			} else {
				this.$el.removeClass('open');
			}
		});
	},

	render() {
		const el = this.$el;

		this.setElement(template());
		el.append(this.$el);
	},

});
