module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-block-table-footer">\r\n	<div class="b-footer-element b-footer-count">\r\n		' +
((__t = ( L10N.get('cabinet.table.footer.entriesCounter') )) == null ? '' : __t) +
': <span>' +
((__t = ( this.data.count )) == null ? '' : __t) +
'</span>\r\n	</div>\r\n	<div class="b-footer-element b-footer-show">\r\n		<span class="js-widget"  onclick=\'return new Widgets.PageSize({ bindingProperty: "pageSize", depend: "page" })\'></span>\r\n        <div class="b-footer-show-more desktop-hide">' +
((__t = ( L10N.get('cabinet.table.footer.showMore') )) == null ? '' : __t) +
'</div>\r\n	</div>\r\n	<div class="b-footer-element b-footer-pages-container">';

		var pagesCount = this.getPagesCount();
		var page = this.model.get('page');
		var count = this.model.get('count');
		if (pagesCount > 1) {
			;
__p += '<span class="js-widget"  onclick=\'return new Widgets.Pagination({ bindingProperty: "page" })\'></span>';

		}
	;
__p += '</div>\r\n	';

    if (!this.showSummaryOnTop) {
        if (this.data.amountSum != null) {
            ;
__p += '<div class="b-footer-element b-footer-total">' +
((__t = ( L10N.get('cabinet.table.footer.amountSum') )) == null ? '' : __t) +
': <strong>' +
((__t = ( this.data.amountSum )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(this.data.currency) )) == null ? '' : __t) +
'</strong></div>';

        }
        if (this.data.incomeSum != null) {
            ;
__p += '<div class="b-footer-element b-footer-total">' +
((__t = ( L10N.get('cabinet.table.footer.incomeSum') )) == null ? '' : __t) +
': <strong>' +
((__t = ( this.data.incomeSum )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(this.data.currency) )) == null ? '' : __t) +
'</strong></div>';

        }
        if (this.data.debitSum != null) {
            ;
__p += '<div class="b-footer-element b-footer-total">' +
((__t = ( L10N.get('cabinet.table.footer.debitSum') )) == null ? '' : __t) +
': <strong>' +
((__t = ( this.data.debitSum )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(this.data.currency) )) == null ? '' : __t) +
'</strong></div>';

        }
        if (this.data.creditSum != null) {
            ;
__p += '<div class="b-footer-element b-footer-total">' +
((__t = ( L10N.get('cabinet.table.footer.creditSum') )) == null ? '' : __t) +
': <strong>' +
((__t = ( this.data.creditSum )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(this.data.currency) )) == null ? '' : __t) +
'</strong></div>';

        }
    }
	;
__p += '\r\n</div>\r\n\r\n';

}
return __p
}