// eslint-disable-next-line banned-modules
'use strict';

import BasePageView from '@/pages/base.page-view';
import CabinetMenu from '@/blocks/pages/b-cabinet/b-cabinet-menu/index';
import EmployeeView from '@/blocks/pages/b-cabinet/b-cabinet-employees/b-cabinet-employee/index';

import axios from 'axios';

const loadEmployeeInfo = async (employeeRef) => {
	if (!STATE.getCountries()) {
		await STATE.loadCountries();
	}

	if (!employeeRef || employeeRef === 'new') {
		return Promise.resolve({});
	}

	return new Promise((resolve, reject) => {
		axios.post('/midoffice/ibecorp-b2b/cabinet/getEmployeeInfo', {
			parameters: {
				employeeRef,
			},
		}).then((employeeInfoResponse) => {
			resolve({
				employeeInfo: employeeInfoResponse.data.result,
			});
		}).catch(reject);
	});
};

export default BasePageView.extend({

	initialize(options) {
		const { employeeInfo } = options;
		this.reRenderPage(employeeInfo);
	},

	reRenderPage(employeeInfo) {
		const container = this.$el;
		this.render();

		container.html(this.$el);
		this.ui.sidebar.append(new CabinetMenu().$el);
		this.ui.content.append(new EmployeeView({
			employeeInfo,
			loadEmployeeInfo,
			reRenderPage: this.reRenderPage.bind(this),
		}).$el);
	},

}, {
	async load(employeeRef) {
		return loadEmployeeInfo(employeeRef);
	},
});
