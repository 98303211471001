// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import $ from 'jquery';
import BaseView from '@/classes/base.view';
import template from './table.ejs';

export default BaseView.extend({

	template,

	events: {
		'click .b-block-table__row.clickable' : 'clickHandler',
	},

	initialize(options) {
		this.fields = options.fields;
		this.clickField = options.clickField;
		this.data = options.data;
		this.showEmptyCellsOnSides = options.showEmptyCellsOnSides;
		this.footerData = options.footerData || {};
		this.showSummaryOnTop = options.showSummaryOnTop;
		this.render();
	},

	clickHandler(e) {
		if (e) {
			e.preventDefault();
		}
		const $value = $(e.currentTarget).data('value');
		STATE.navigate(`${this.clickField.path}${$value}`);
	},

});
