// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import suggestionTemplate from './suggestion.ejs';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';
import 'corejs-typeahead/dist/typeahead.jquery';
import axios from 'axios';
import Formatter from '@/utils/formatter';

export default Widget.extend({

	template,

	validationTemplate,

	viewName: 'b-employee-autocomplete',

	initialize(options) {
		this.options = options;
		this.options.label = this.options.label || '';
		this.options.placeholder = this.options.placeholder || '';
		this.options.deletableField = (this.options.deletableField === true);
		this.queryHandler = this.queryHandler.bind(this);
		this.render();

		this.$typeahead = this.$('.b-employee-autocomplete__value');
		this.$typeahead.typeahead({
			hint: false,
			highlight: false,
			minLength: 0,
			limit: 1,
			classNames: {
				menu: 'b-employee-autocomplete__items',
				wrapper: 'b-employee-autocomplete__wrapper',
				input: 'b-employee-autocomplete__input',
				hint: 'b-employee-autocomplete__hint',
				dataset: 'b-employee-autocomplete__dataset',
				suggestion: 'b-employee-autocomplete__item',
				selectable: 'b-employee-autocomplete__selectable',
				empty: 'b-employee-autocomplete__empty',
				open: 'b-employee-autocomplete__open',
				cursor: 'b-employee-autocomplete__cursor',
				highlight: 'b-employee-autocomplete__highlight',
			},
		}, {
			name: 'employee',
			source: (q, cb, async) => {
				clearTimeout(this.timer);
				this.timer = setTimeout(this.queryHandler, STATE.QUERY_DELAY, q, cb, async);
			},
			display: (item) => `${item.caption}`,
			async: true,
			templates: {
				suggestion: suggestionTemplate,
			},
		});
	},

	applyBinding(model) {
		this.$typeahead.on('typeahead:change', (ev, suggestion) => {
			if (suggestion.replace(/\s/g, '').length === 0) {
				this.model.set(this.options.bindingProperty, null);
			}
		});

		this.$typeahead.on('typeahead:select', (ev, suggestion) => {
			this.model.set(this.options.bindingProperty, suggestion);
		});

		this.listenTo(model, `validationError:${this.options.bindingProperty}`, () => {
			this.$el.addClass('validation-error');
		});

		this.listenTo(model, `change:${this.options.bindingProperty}`, (__model, item) => {
			this.$typeahead.typeahead('val', (item || {}).caption);
		});

		if (_.isObject(model.get(this.options.bindingProperty))) {
			this.$typeahead.typeahead('val', model.get(this.options.bindingProperty).caption);
		}
	},

	queryHandler(q, cb, async) {
		axios.post('/midoffice/ibecorp-b2b/cabinetNew/getAgents', {
			parameters: {
				pattern: q,
				limit: 20,
			},
		}).then((resp) => {
			async(resp.data.result.agents.reduce((result, item) => {
				result.push({
					uid: item.containerUid,
					caption: Formatter.displayFullName(item),
				});
				return result;
			}, []));
		});
	},

});
