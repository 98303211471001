module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div>\r\n	<div class="b-passengers-block b-passengers-info">\r\n		<h4 class="b-passengers-block__title">' +
((__t = ( L10N.get("passengers.customerTitle") )) == null ? '' : __t) +
'</h4>\r\n		<p class="b-passengers-info__item">\r\n			' +
((__t = (customer.firstName )) == null ? '' : __t) +
' ' +
((__t = (customer.lastName )) == null ? '' : __t) +
' <br>\r\n            +' +
((__t = (customer.phone.countryCode )) == null ? '' : __t) +
'(' +
((__t = (customer.phone.cityCode )) == null ? '' : __t) +
')' +
((__t = (customer.phone.number )) == null ? '' : __t) +
' <br>\r\n            ' +
((__t = (customer.email )) == null ? '' : __t) +
'\r\n		</p>\r\n	</div>\r\n    ';

	for (var i = 0; i < passengers.length; i++) {
		var passenger = passengers[i];

    ;
__p += '\r\n	<div class="b-passengers-block b-passengers-info-ext">\r\n		<h4 class="b-passengers-block__title">' +
((__t = ( L10N.get("passengers.passengersTitle") )) == null ? '' : __t) +
'</h4>\r\n		<span class="b-passengers-info-ext__number">' +
((__t = ( L10N.get("passengers.passengerTitle") )) == null ? '' : __t) +
' ' +
((__t = ( i+1 )) == null ? '' : __t) +
' (' +
((__t = ( passenger.type.caption )) == null ? '' : __t) +
')</span>\r\n		<p class="b-passengers-info-ext__item">\r\n            ' +
((__t = ( Formatter.displayFullName(passenger.passport) )) == null ? '' : __t) +
'<br>\r\n            ' +
((__t = (passenger.passport.gender.uid.substr(0,1) )) == null ? '' : __t) +
'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
((__t = (passenger.passport.birthDate )) == null ? '' : __t) +
'\r\n			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
((__t = (passenger.passport.citizenship.uid )) == null ? '' : __t) +
'<br/>\r\n            ' +
((__t = (passenger.email )) == null ? '' : __t) +
'\r\n		</p>\r\n	</div>\r\n	';
 } ;
__p += '\r\n</div>\r\n';

}
return __p
}