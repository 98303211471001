import "./style.less";
import sanitizeHtml, { Transformer } from "sanitize-html";
import React, {
	Attributes,
	MouseEvent,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import { NewsItem } from "../helpers/interfaces";
import { fetchMarkRead, fetchNewsItemDetails } from "./helpers/services";
import { NewItemDetails } from "./helpers/interfaces";
import { fetchNoticesSummary } from "../../../../js/blocks/layout/b-header/react/helpers/services";
import { useNoticesStore } from "../../../store/notices";
import Button from "../../../components/form/Button/Button";
import { ReactAppGlobalContext } from "../../../../js/utils/react-utils";

interface NewsCardProps {
	item: NewsItem;
}

const NewsCard: React.FC<NewsCardProps> = (props) => {
	const { item } = props;

	const { L10N } = useContext(ReactAppGlobalContext);

	const { updateSummary } = useNoticesStore();

	const [details, setDetails] = useState<NewItemDetails>();
	const [collapsed, setCollapsed] = useState<boolean>(true);
	const [fetching, setFetching] = useState<boolean>(false);
	const [read, setRead] = useState<boolean | undefined>();

  const updateSummaryData = useCallback(async () => {
    const newSummary = await fetchNoticesSummary();
    updateSummary(newSummary);
  }, [])

	const fetchDetails = useCallback(
		async (skipSummary = false) => {
			setFetching(true);
			const newsItemDetails = await fetchNewsItemDetails(item.uid);
			if (!skipSummary) {
        updateSummaryData();
				setRead(true);
			}
			setDetails(newsItemDetails);
			setFetching(false);
		},
		[item.uid]
	);

	const toggleNewsDetails = useCallback(
		(event: MouseEvent<HTMLElement>) => {
			event.preventDefault();
			if (!fetching) {
        fetchDetails(read);
      }
		},
		[fetching, read]
	);

	const handleMarkRead = useCallback(async () => {
		await fetchMarkRead(item.uid);
    await updateSummaryData();
		setRead(true);
	}, [item.uid]);

	useEffect(() => {
		if (details) {
			setCollapsed(false);
		} else if (item.important && !item.read) {
			fetchDetails(true);
		}
	}, [details, item.important, item.read]);

	useEffect(() => {
		setRead(item.read);
	}, [item.read]);

	return (
		<div className="b-news-card">
			<div className="b-news-card-date">
				<div className="b-news-card-date__formatted">
					{item.created &&
						item.created.format &&
						item.created.format("DD.MM.YYYY, HH:mm")}
				</div>{" "}
				{item.important && (
					<div className="b-news-card-date__important">
						{L10N.get("news.newsCard.important")}
					</div>
				)}
				{read !== undefined && (
					<>
						{read && (
							<div className="b-news-card-date__read">
								{L10N.get("news.newsCard.read")}
							</div>
						)}
						{!read && (
							<div className="b-news-card-date__unread">
								{L10N.get("news.newsCard.unread")}
							</div>
						)}
					</>
				)}
			</div>
			<div className="b-news-card-title">
				{item.title}
				<br />
				{collapsed && (
					<a
						className="b-news-card-read-more"
						href="#"
						onClick={toggleNewsDetails}
					>
						{L10N.get("news.newsCard.readMore")}
					</a>
				)}
			</div>
			{!collapsed && (
				<>
					<div
						className="b-news-card-content"
						dangerouslySetInnerHTML={{
							__html: sanitizeHtml(details?.content || ""),
						}}
					/>
					{item.important && !read && (
						<div className="b-news-card-button-wrapper">
							<Button style={{ height: "28px" }} onClick={handleMarkRead}>
								{L10N.get("news.newsCard.markAsRead")}
							</Button>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default NewsCard;
