module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var checkInDate = checkInDate || '';
    var checkOutDate = checkOutDate || '';
    var rooms = rooms || {};
    var passengerTypes = passengerTypes || {};
;
__p += '\n\n<div class="b-hotels-search-form b-hotels-search">\n	<div class="b-hotels-search__row">\n		<div class="b-hotels-search__direction">\n			<span class="js-widget"  onclick=\'return new Widgets.Hotels({ bindingProperty: "city", bindingPropertyForHotel: "hotelCardNumber", placeholder:  L10N.get("hotels.cityAndHotel") })\'></span>\n		</div>\n		<div class="b-hotels-search__dates-container">\n			<div class="b-hotels-search__options-date-element b-hotels-search__options-date-element--type-dateto checkindate-from-datepicker">\n				<span class="js-widget"  onclick=\'return new Widgets.Datepicker({ bindingProperty: "checkInDate", dateFormat: "DD MMM", placeholder: L10N.get("hotels.checkInDate") })\'></span>\n			</div>\n			<div class="b-hotels-search__options-date-element b-hotels-search__options-date-element--type-datefrom checkoutdate-from-datepicker">\n				<span class="js-widget"  onclick=\'return new Widgets.Datepicker({ bindingProperty: "checkOutDate", dateFormat: "DD MMM", depend: "checkInDate", dependOffset: "1 d", removable: true, placeholder: L10N.get("hotels.checkOutDate") })\'></span>\n			</div>\n		</div>\n		<div class="b-hotels-search__button-container">\n			<div class="b-hotels-search__button">' +
((__t = ( L10N.get('hotels.search') )) == null ? '' : __t) +
'</div>\n		</div>\n	</div>\n    <div class="b-hotels-search-mobile">\n        <div class="b-hotels-search-mobile__search-form">\n            <div class="b-hotels-search-mobile__route">\n                ';
 if (city != null && city.caption != null) { ;
__p += '\n                    ' +
((__t = ( city.caption )) == null ? '' : __t) +
'\n                ';
 } ;
__p += '\n            </div>\n            <div class="b-hotels-search-mobile__route-info">\n                ';
 if (checkInDate != null && checkOutDate != null) { ;
__p += '\n                    ' +
((__t = ( new Time(checkInDate).toDay() )) == null ? '' : __t) +
' - ' +
((__t = ( new Time(checkOutDate).toMonthDay() )) == null ? '' : __t) +
',\n                ';
 } ;
__p += '\n                ';
 var passengersCount = 0;
                if (_.size(rooms.models)) {
                     _.each(rooms.models, function (m) {
						if (m.attributes.travellers != null) passengersCount += _.size(m.attributes.travellers.models);
                    });
                 } else {
                    passengersCount = _.reduce(Object.keys(passengersTypes.attributes), function (prev, acc) {
                        return prev + passengersTypes.attributes[acc];
                    }, 0);
                 } ;
__p += '\n                ' +
((__t = ( passengersCount )) == null ? '' : __t) +
' ' +
((__t = ( L10N.getPluralForm('searchForm.passenger', passengersCount) )) == null ? '' : __t) +
',\n            </div>\n        </div>\n        <div class="b-hotels-search-mobile__map-trigger"></div>\n        <div class="b-hotels-search-mobile__filters-trigger"></div>\n    </div>\n    <div class="b-hotels-search-form-mobile__background"></div>\n</div>\n';

}
return __p
}