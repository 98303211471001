module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (!_.isArray(price)) {
	price = [price];
}
    var totalPrice = totalPrice || null;

	function renderRates(rates) {
        var template = '';
		if (_.isArray(rates)) {
            _.each(rates, function(rate) {
               template += ' <span>' + rate.total.amount + ' ' + '<span class="currency">' + rate.symbol + '</span></span>';
            });
        }
		return template;
    }
;
__p += '\n\n\n<div class="b-ticket-pricing ' +
((__t = ( (_.size(price) > 1) ? 'no-max-width' : '' )) == null ? '' : __t) +
'">\n	';
 _.each(price, function (p, i) { ;
__p += '\n		<div class="b-ticket-pricing__item">\n			';
 if (_.size(price) > 1) { ;
__p += '\n				<div class="b-ticket-pricing__direction">\n					<span>' +
((__t = ( (i === 0) ? L10N.get("trains.there") : L10N.get("trains.backward") )) == null ? '' : __t) +
'</span>\n                    <span class="js-copy-content-tooltip copy-content">' +
((__t = ( (i === 0) ? L10N.get("trains.there") : L10N.get("trains.backward") )) == null ? '' : __t) +
'</span>\n				</div>\n			';
 } ;
__p += '\n\n			';
 if (!_.isEmpty(p.rates)) { ;
__p += '\n                <div class="b-ticket-pricing__header mobile-hide">' +
((__t = ( renderRates(p.rates) )) == null ? '' : __t) +
'</div>\n                <span class="js-copy-content-tooltip copy-content"><span data-copy-content-from=".b-ticket-pricing__header"></span></span>\n			';
 } ;
__p += '\n\n			<div class="b-ticket-pricing__content">\n				';

				var types = ['tariff', 'fee', 'taxes', 'techFees', 'supplierFees', 'agencyFees', 'feeAndTaxes', 'penalty', 'discount', 'ancillaryFees'];

				p.passengerPrices = _.toArray(p.passengerPrices).sort(function(a,b) {return a.passengerType.uid > b.passengerType.uid});

				_.each(p.passengerPrices, function(value) {
					var passengerPrice = _.extend({}, value, value.feeDetails);
					var ticketPrice = 0; ;
__p += '\n\n					<div class="b-ticket-pricing__section">\n						<span class="b-ticket-pricing__section-title">\n							';
 if (passengerPrice && passengerPrice.passengerType) { ;
__p += '\n								' +
((__t = ( passengerPrice.passengerType.caption )) == null ? '' : __t) +
'\n								';
 if (passengerPrice.passengerCount > 1) { ;
__p += 'x' +
((__t = ( passengerPrice.passengerCount )) == null ? '' : __t);
 } ;
__p += '\n                                ';
 if (passengerPrice.group) { ;
__p += ' ' +
((__t = ( ' ' + passengerPrice.group )) == null ? '' : __t) +
' ';
 } ;
__p += '\n                                <span class="js-copy-content-tooltip copy-content">' +
((__t = ( passengerPrice.passengerType.caption )) == null ? '' : __t);
 if (passengerPrice.passengerCount > 1) { ;
__p += ' x' +
((__t = ( passengerPrice.passengerCount )) == null ? '' : __t) +
'/';
 } else { ;
__p += '/';
 } ;

 if (passengerPrice.group) { ;
__p += ' ' +
((__t = ( ' ' + passengerPrice.group )) == null ? '' : __t) +
' ';
 } ;
__p += '</span>\n							';
 } ;
__p += '\n						</span>\n						';
 _.each(types, function (type) {
							var priceValue = passengerPrice[type];
							if (priceValue != null && parseFloat(priceValue.amount) !== 0 && type !== 'ancillaryFees') {
								ticketPrice += priceValue.amount; ;
__p += '\n								<div class="b-ticket-pricing__section-info">\n									<span class="b-ticket-pricing__section-passenger">' +
((__t = ( L10N.get("pricing." + type) )) == null ? '' : __t) +
'</span>\n									<span class="b-ticket-pricing__section-price">\n										' +
((__t = ( Formatter.formatMoney(priceValue.amount, Formatter.getDecimal(priceValue.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(priceValue.currencyCode) )) == null ? '' : __t) +
'\n									</span>\n                                    <span class="js-copy-content-tooltip copy-content">' +
((__t = ( L10N.get("pricing." + type) )) == null ? '' : __t) +
': ' +
((__t = ( Formatter.formatMoney(priceValue.amount, Formatter.getDecimal(priceValue.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(priceValue.currencyCode) )) == null ? '' : __t) +
'/</span>\n								</div>\n							';
 } ;
__p += '\n\n							';
 if (type === 'tariff') { ;
__p += '\n								';
 if (!_.isEmpty(passengerPrice.additionalServices) && _.compact(_.values(passengerPrice.additionalServices)).length) { ;
__p += '\n									<div class="b-ticket-pricing__section">\n										';
 if (passengerPrice.additionalServices.checkInAmount) { ;
__p += '\n											<div class="b-ticket-pricing__section-info">\n												<span class="b-ticket-pricing__section-passenger">' +
((__t = ( L10N.get('hotels.addServices.earlyCheckIn') )) == null ? '' : __t) +
'</span>\n												<span class="b-ticket-pricing__section-price">\n													' +
((__t = ( Formatter.formatMoney(passengerPrice.additionalServices.checkInAmount, Formatter.getDecimal(passengerPrice.additionalServices.checkInAmount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(p.total.currencyCode) )) == null ? '' : __t) +
'\n												</span>\n                                                <span class="js-copy-content-tooltip copy-content">' +
((__t = ( L10N.get('hotels.addServices.earlyCheckIn') )) == null ? '' : __t) +
': ' +
((__t = ( Formatter.formatMoney(passengerPrice.additionalServices.checkInAmount, Formatter.getDecimal(passengerPrice.additionalServices.checkInAmount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(p.total.currencyCode) )) == null ? '' : __t) +
'/</span>\n											</div>\n										';
 } ;
__p += '\n										';
 if (passengerPrice.additionalServices.checkOutAmount) { ;
__p += '\n											<div class="b-ticket-pricing__section-info">\n												<span class="b-ticket-pricing__section-passenger">' +
((__t = ( L10N.get('hotels.addServices.lateCheckout') )) == null ? '' : __t) +
'</span>\n												<span class="b-ticket-pricing__section-price">\n													' +
((__t = ( Formatter.formatMoney(passengerPrice.additionalServices.checkOutAmount, Formatter.getDecimal(passengerPrice.additionalServices.checkOutAmount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(p.total.currencyCode) )) == null ? '' : __t) +
'\n												</span>\n                                                <span class="js-copy-content-tooltip copy-content">' +
((__t = ( L10N.get('hotels.addServices.lateCheckout') )) == null ? '' : __t) +
': ' +
((__t = ( Formatter.formatMoney(passengerPrice.additionalServices.checkOutAmount, Formatter.getDecimal(passengerPrice.additionalServices.checkOutAmount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(p.total.currencyCode) )) == null ? '' : __t) +
'/</span>\n											</div>\n										';
 } ;
__p += '\n										';
 if (passengerPrice.additionalServices.mealAmount) { ;
__p += '\n											<div class="b-ticket-pricing__section-info">\n												<span class="b-ticket-pricing__section-passenger">' +
((__t = ( L10N.get('hotels.meal') )) == null ? '' : __t) +
'</span>\n												<span class="b-ticket-pricing__section-price">\n													' +
((__t = ( Formatter.formatMoney(passengerPrice.additionalServices.mealAmount, Formatter.getDecimal(passengerPrice.additionalServices.mealAmount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(p.total.currencyCode) )) == null ? '' : __t) +
'\n												</span>\n                                                <span class="js-copy-content-tooltip copy-content">' +
((__t = ( L10N.get('hotels.addServices.meal') )) == null ? '' : __t) +
': ' +
((__t = ( Formatter.formatMoney(passengerPrice.additionalServices.mealAmount, Formatter.getDecimal(passengerPrice.additionalServices.mealAmount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(p.total.currencyCode) )) == null ? '' : __t) +
'/</span>\n											</div>\n										';
 } ;
__p += '\n									</div>\n								';
 } ;
__p += '\n							';
 } ;
__p += '\n                            ';
 if (type === 'ancillaryFees' && !_.isEmpty(passengerPrice[type])) { ;
__p += '\n                            <div class="b-ticket-pricing__section">\n                                <span class="b-ticket-pricing__section-title">\n                                    ' +
((__t = ( L10N.get('hotels.additionalServiceShort') )) == null ? '' : __t) +
'\n                                    <span class="js-copy-content-tooltip copy-content">' +
((__t = ( L10N.get('hotels.additionalServiceShort') )) == null ? '' : __t) +
'/</span>\n                                </span>\n                            </div>\n                            ';
 _.each(passengerPrice[type], function (item) { ;
__p += '\n                                <div class="b-ticket-pricing__section-info">\n                                    <span class="b-ticket-pricing__section-passenger">' +
((__t = ( item.caption )) == null ? '' : __t) +
'</span>\n                                    <span class="b-ticket-pricing__section-price">\n                                        ' +
((__t = ( item.number + 'x' )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatMoney(item.price.amount, Formatter.getDecimal(item.price.amount)) )) == null ? '' : __t) +
'\n                                        ' +
((__t = ( Formatter.formatCurrency(item.price.currencyCode) )) == null ? '' : __t) +
'\n							        </span>\n                                    <span class="js-copy-content-tooltip copy-content">' +
((__t = ( item.caption )) == null ? '' : __t) +
' ' +
((__t = ( item.number + 'x' )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatMoney(item.price.amount, Formatter.getDecimal(item.price.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(item.price.currencyCode) )) == null ? '' : __t) +
'/</span>\n                                </div>\n                            ';
 }); ;
__p += '\n                            ';
 } ;
__p += '\n						';
 }); ;
__p += '\n					</div>\n				';
 }); ;
__p += '\n\n				';
 if (p.ancillaryFees != null && p.ancillaryFees.price.amount > 0) { ;
__p += '\n					<div class="b-ticket-pricing__section">\n						<span class="b-ticket-pricing__section-title">\n							' +
((__t = ( L10N.get('hotels.additionalServiceShort') )) == null ? '' : __t) +
'\n						</span>\n						<div class="b-ticket-pricing__section-info">\n							<span class="b-ticket-pricing__section-passenger">' +
((__t = ( L10N.get('hotels.cost') )) == null ? '' : __t) +
'</span>\n							<span class="b-ticket-pricing__section-price">\n								' +
((__t = ( Formatter.formatMoney(p.ancillaryFees.price.amount, Formatter.getDecimal(p.ancillaryFees.price.amount)) )) == null ? '' : __t) +
'\n								' +
((__t = ( Formatter.formatCurrency(p.ancillaryFees.price.currencyCode) )) == null ? '' : __t) +
'\n							</span>\n						</div>\n						';
 if (p.ancillaryFees.supplierFees.amount > 0) { ;
__p += '\n							<div class="b-ticket-pricing__section-info">\n								<span class="b-ticket-pricing__section-passenger">' +
((__t = ( L10N.get('pricing.supplierFees') )) == null ? '' : __t) +
'</span>\n								<span class="b-ticket-pricing__section-price">\n									' +
((__t = ( Formatter.formatMoney(p.ancillaryFees.supplierFees.amount, Formatter.getDecimal(p.ancillaryFees.supplierFees.amount)) )) == null ? '' : __t) +
'\n									' +
((__t = ( Formatter.formatCurrency(p.ancillaryFees.supplierFees.currencyCode) )) == null ? '' : __t) +
'\n								</span>\n							</div>\n						';
 } ;
__p += '\n						';
 if (p.ancillaryFees.agencyFees.amount > 0) { ;
__p += '\n							<div class="b-ticket-pricing__section-info">\n								<span class="b-ticket-pricing__section-passenger">' +
((__t = ( L10N.get('pricing.agencyFees') )) == null ? '' : __t) +
'</span>\n								<span class="b-ticket-pricing__section-price">\n									' +
((__t = ( Formatter.formatMoney(p.ancillaryFees.agencyFees.amount, Formatter.getDecimal(p.ancillaryFees.agencyFees.amount)) )) == null ? '' : __t) +
'\n									' +
((__t = ( Formatter.formatCurrency(p.ancillaryFees.agencyFees.currencyCode) )) == null ? '' : __t) +
'\n								</span>\n							</div>\n						';
 } ;
__p += '\n					</div>\n				';
 } ;
__p += '\n			</div>\n\n            <div class="b-pricing__total-section--mobile desktop-hide">\n                ';
 if (!_.isEmpty(p.rates)) { ;
__p += '\n                    ' +
((__t = ( renderRates(p.rates) )) == null ? '' : __t) +
'\n                ';
 } ;
__p += '\n                ';
 if (_.size(price) > 1 && p.total != null) { ;
__p += '\n                    <span class="b-pricing__total-price">\n                        ' +
((__t = ( Formatter.formatMoney(p.total.amount, Formatter.getDecimal(p.total.amount)))) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(p.total.currencyCode) )) == null ? '' : __t) +
'\n            	    </span>\n                ';
 } else if (totalPrice != null) { ;
__p += '\n                    <span class="b-pricing__total-price">\n                        ' +
((__t = ( Formatter.formatMoney(totalPrice.amount, Formatter.getDecimal(totalPrice.amount)))) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(totalPrice.currencyCode) )) == null ? '' : __t) +
'\n                    </span>\n                ';
 } ;
__p += '\n            </div>\n			';
 if (_.size(price) > 1 && p.total != null) { ;
__p += '\n				<div class="b-pricing__total-section clearfix mobile-hide">\n					<span class="b-pricing__section-total-title">' +
((__t = ( L10N.get("pricing.total") )) == null ? '' : __t) +
':</span>\n					<span class="b-pricing__section-total-price">\n						';
 if (p.isMinPriceRangeComponent === true) { ;
__p +=
((__t = ( L10N.get('pricing.from') )) == null ? '' : __t) +
' ';
 } ;
__p += '\n						' +
((__t = ( Formatter.formatMoney(p.total.amount, Formatter.getDecimal(p.total.amount)))) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(p.total.currencyCode) )) == null ? '' : __t) +
'\n					</span>\n                    <span class="js-copy-content-tooltip copy-content">' +
((__t = ( L10N.get("pricing.total") )) == null ? '' : __t) +
': ';
 if (p.isMinPriceRangeComponent === true) { ;
__p +=
((__t = ( L10N.get('pricing.from') )) == null ? '' : __t) +
' ';
 } ;
__p += ' ' +
((__t = ( Formatter.formatMoney(p.total.amount, Formatter.getDecimal(p.total.amount)))) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(p.total.currencyCode) )) == null ? '' : __t) +
'</span>\n				</div>\n			';
 } ;
__p += '\n		</div>\n	';
 }); ;
__p += '\n</div>\n';

}
return __p
}