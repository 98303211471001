module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (showRange === true) { ;
__p += '\n  <span class="js-widget"  onclick=\'return new Widgets.Range({ bindingProperty: "value", label: "' + label + '", min: ' + min + ', max: ' + max + ', formatter: "' + formatter + '", classes: "b-filters-hotels-range"  })\'></span>\n';
 } else { ;
__p += '\n  <div class="b-filters__range-input-label">' +
((__t = ( label )) == null ? '' : __t) +
'</div>\n';
 } ;
__p += '\n';
 if (showInputValue === true) { ;
__p += '\n  <div class="b-filters__range-input-container">\n    <div class="b-filters__range-input">\n      ' +
((__t = ( L10N.get('filters.up') )) == null ? '' : __t) +
' <span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "__min", classes: "b-filters__range-min", placeholder: "0" })\'></span>\n    </div>\n    <div class="b-filters__range-input">\n      ' +
((__t = ( L10N.get('filters.to') )) == null ? '' : __t) +
' <span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "__max", classes: "b-filters__range-max", placeholder: "0" })\'></span>\n    </div>\n  </div>\n';
 } ;


}
return __p
}