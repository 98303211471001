// eslint-disable-next-line banned-modules
'use strict';

import BaseView from '@/classes/base.view';
import template from './template.ejs';

export default BaseView.extend({

	template,

	initialize(options) {
		this.options = options;
		this.model = options.model;
		this.render();
	},

	render(args) {
		BaseView.prototype.render.apply(this, args);

		return this;
	},

});
