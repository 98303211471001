module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-offers-avia-modal">\n	<div class="b-offers-avia-modal__title">\n		';
 if (this.passengers != null) { ;
__p += '\n			' +
((__t = ( L10N.get('ticket.variantsToCoordination') )) == null ? '' : __t) +
' ' +
((__t = ( L10N.get('Common.for') )) == null ? '' : __t) +
': <span class="b-offers-avia-modal__title-person"></span>\n		';
 } else { ;
__p += '\n			' +
((__t = ( L10N.get('ticket.variantsToCoordination') )) == null ? '' : __t) +
'\n		';
 } ;
__p += '\n	</div>\n	<div class="b-offers-avia-modal__list">\n		';
 _.each(this.offers, function (offer, i) {
            var airlinesList = [];

            _.each(offer.flight.legs, function(l) {
                _. each(l.segments, function(s) {
                    if (s.airline && s.airline.airlineCode && s.airline.codeSystem) {
                        airlinesList.push(s.airline);
                    }
                });
            });
            airlinesList = _.uniq(airlinesList, false, function(a) { return a.airlineCode; });
        ;
__p += '\n\n				<div class="b-offers-avia-modal__list-item " data-flight-token="' +
((__t = ( offer.flightToken )) == null ? '' : __t) +
'" data-copy-container="' +
((__t = ( i )) == null ? '' : __t) +
'">\n                    <span class="js-copy-content copy-content"><span>' +
((__t = ( L10N.get('cabinet.orders.serviceName') )) == null ? '' : __t) +
': ' +
((__t = ( L10N.get('cabinet.orders.serviceTypes.AVIA') )) == null ? '' : __t) +
'</span></span>\n                    <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('hotels.cost') )) == null ? '' : __t) +
': <span data-replace-newline=" " data-copy-content-from=".b-offers-avia-modal__list-item-price-container" data-copy-title="' +
((__t = ( L10N.get('cabinet.orders.cost') )) == null ? '' : __t) +
'"></span></span>\n                    ';
 if (offer.flight.refund || offer.flight.exchange) { ;
__p += '\n                        <span class="js-refund-exchange-tooltip" style="display: none" data-toggle="tooltip" data-placement="bottom" data-html="true"\n                              data-original-title="' +
((__t = ( _.escape(require('@/blocks/elements/b-ticket/b-ticket-refund-exchange.ejs')({ services: {refund: offer.flight.refund || {}, exchange: offer.flight.exchange || {}} })) )) == null ? '' : __t) +
'"></span>\n                        <span class="js-copy-content copy-content"><span data-find-copy-in-tooltip data-copy-title-from=".js-refund-exchange-tooltip"></span></span>\n                    ';
 } ;
__p += '\n					<div class="b-offers-avia-modal__list-item-drag"></div>\n					<div class="b-offers-avia-modal__list-item-legs">\n                        <div class="b-flight-ticket__mobile-company-logos">\n                            ';
 _.each(airlinesList, function(airline) { ;
__p += '\n                                ';
 var companyForIcon = airline.codeSystem !== 'IATA' ? airline.airlineCode + '-' + airline.codeSystem : airline.airlineCode; ;
__p += '\n\n                                <div class="b-leg__company-logo-container">\n                                    <div class="b-leg__company-logo g-icon" style="background-image: url(\'/assets/img/logos/logos-m/' +
((__t = ( companyForIcon )) == null ? '' : __t) +
'-black.svg\')" data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( _.escape(airline.caption) )) == null ? '' : __t) +
'"></div>\n                                </div>\n                            ';
 }); ;
__p += '\n                        </div>\n						' +
((__t = ( require('../b-ticket-legs/b-ticket-legs.ejs')({
							flight: offer.flight,
							flightToken: offer.flightToken
						}) )) == null ? '' : __t) +
'\n					</div>\n					<div class="b-offers-avia-modal__list-item-price">\n						<div class="b-offers-avia-modal__list-item-price-container">\n							<span class="b-offers-avia-modal__list-item-price-amount">\n								' +
((__t = ( Formatter.formatMoney(offer.flight.price.total.amount, 0) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(offer.flight.price.total.currencyCode) )) == null ? '' : __t) +
'\n							</span>\n							<span>\n								| ' +
((__t = ( L10N.get('Common.behind') )) == null ? '' : __t) +
' <span class="b-offers-avia-modal__title-person-count"></span> ' +
((__t = ( L10N.get('Common.pers') )) == null ? '' : __t) +
'\n							</span>\n						</div>\n						<span class="b-offers-avia-modal__list-item-remove"></span>\n					</div>\n					<div class="break"></div>\n				</div>\n				<div class="b-offers-avia-modal__form b-offers-avia-modal__form-control b-offers-avia-modal__list-item-comment" data-copy-comment data-copy-title="' +
((__t = ( L10N.get("ticket.comment") )) == null ? '' : __t) +
'">\n					<span class="js-widget"  onclick=\'return new Widgets.Input({bindingProperty: "comment'+ i +'", placeholder: L10N.get("ticket.comment") })\'></span>\n				</div>\n				<div class="b-offers-avia-modal__list-item-line">\n				</div>\n		';
 }); ;
__p += '\n	</div>\n\n	<div class="b-offers-avia-modal__no-offers ' +
((__t = ( !_.isEmpty(this.offers) ? 'dn' : '' )) == null ? '' : __t) +
'">\n		<span>' +
((__t = ( L10N.get('ticket.noOffers') )) == null ? '' : __t) +
'</span>\n	</div>\n\n	<div class="b-offers-avia-modal__emails">\n\n	</div>\n\n    <div class="b-offers-avia-modal__form">\n	';
 if (this.options != null && !_.isEmpty(this.options.order)) { ;
__p += '\n        <div class="b-message b-message-warning">\n            ' +
((__t = ( L10N.get('ticket.youCanSendOfferToCoordinationOnOrderPage') )) == null ? '' : __t) +
'\n        </div>\n\n        <div class="b-offers-avia-modal__form-buttons">\n            <div class="b-offers-avia-modal__form-button b-offers-avia-modal__order">' +
((__t = ( L10N.get('ticket.putAsideOrder') )) == null ? '' : __t) +
'</div>\n	';
 } else { ;
__p += '\n        <div class="b-offers-avia-modal__form-buttons">\n            <div class="b-offers-avia-modal__form-button b-offers-avia-modal__submit">' +
((__t = ( L10N.get('ticket.submit') )) == null ? '' : __t) +
'</div>\n            <div class="b-offers-avia-modal__form-button b-offers-avia-modal__order">' +
((__t = ( L10N.get('ticket.putAsideOrder') )) == null ? '' : __t) +
'</div>\n	';
 } ;
__p += '\n            <div class="b-offers-avia-modal__form-button copy-btn js-copy-to-clipboard mobile-hide">' +
((__t = ( L10N.get('Common.copyToClipboard') )) == null ? '' : __t) +
'</div>\n        </div>\n    </div>\n\n	<div class="b-offers-avia-modal__close"></div>\n</div>\n';

}
return __p
}