module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-payment">\n	';
 if (checkBooking.paymentInfo && checkBooking.paymentInfo.status) { ;
__p += '\n		<div class="b-payment__block">\n			';
 if (checkBooking.paymentInfo.status.uid === 'SUCCESS') { ;
__p += '\n				<div class="b-payment__block-header-status-ok">' +
((__t = ( L10N.get("payment.paymentSuccess") )) == null ? '' : __t) +
'</div>\n				<span class="b-payment__block-header-status-text">' +
((__t = ( L10N.get("payment.paymentSuccessMessage") )) == null ? '' : __t) +
'</span>\n			';
 }
			if (checkBooking.paymentInfo.status.uid === 'FAILURE') { ;
__p += '\n				<div class="b-payment__block-header-status-fail">' +
((__t = ( L10N.get("payment.paymentFailure") )) == null ? '' : __t) +
'</div>\n				<span class="b-payment__block-header-status-text">' +
__e( L10N.get("payment.paymentFailureMessage") ) +
'</span>\n			';
 }
			if (checkBooking.paymentInfo.status.uid === 'ERROR') { ;
__p += '\n				<div class="b-payment__block-header-status-fail">' +
((__t = ( L10N.get("payment.paymentError") )) == null ? '' : __t) +
'</div>\n				<span class="b-payment__block-header-status-text">' +
((__t = ( L10N.get("payment.paymentErrorMessage") )) == null ? '' : __t) +
'</span>\n				\n			';
 }
			if (checkBooking.paymentInfo.status.uid === 'UNKNOWN') { ;
__p += '\n				<div class="b-payment__block-header-status-fail">' +
((__t = ( L10N.get("payment.paymentUnknown") )) == null ? '' : __t) +
'</div>\n				<span class="b-payment__block-header-status-text">' +
((__t = ( L10N.get("payment.paymentUnknownMessage") )) == null ? '' : __t) +
'</span>\n			';
 }
			if (checkBooking.paymentInfo.status.uid === 'UNKNOWN_BUT_COMPLETED') { ;
__p += '\n				<div class="b-payment__block-header-status-fail">' +
((__t = ( L10N.get("payment.paymentUnknownButCompleted") )) == null ? '' : __t) +
'</div>\n				<span class="b-payment__block-header-status-text">' +
((__t = ( L10N.get("payment.paymentUnknownButCompletedMessage") )) == null ? '' : __t) +
'</span>\n			';
 } ;
__p += '\n		</div>\n	';
 } else if (checkBooking.bookingInfo && checkBooking.bookingInfo.status && checkBooking.bookingInfo.status.uid !== 'BOOKING'){ ;
__p += '\n		<div class="b-payment__block">\n			';
 if (checkBooking.bookingInfo.status.uid === 'CANCELED') { ;
__p += '\n				<div class="b-payment__block-header-status-fail">' +
((__t = ( L10N.get("payment.orderCanceled") )) == null ? '' : __t) +
'</div>\n				<span class="b-payment__block-header-status-text">' +
((__t = ( checkBooking.bookingInfo.message )) == null ? '' : __t) +
'</span>\n			';
 } ;
__p += '\n            ';
 if (checkBooking.bookingInfo.status.uid === 'ERROR') { ;
__p += '\n				<div class="b-payment__block-header-status-fail">' +
((__t = ( L10N.get("payment.orderError") )) == null ? '' : __t) +
'</div>\n				<span class="b-payment__block-header-status-text">' +
((__t = ( checkBooking.bookingInfo.message )) == null ? '' : __t) +
'</span>\n			';
 } ;
__p += '\n            ';
 if (checkBooking.bookingInfo.status.uid === 'ISSUED') { ;
__p += '\n				<div class="b-payment__block-header-status-ok">' +
((__t = ( L10N.get("payment.orderIssued") )) == null ? '' : __t) +
'</div>\n				<span class="b-payment__block-header-status-text">' +
((__t = ( checkBooking.bookingInfo.message )) == null ? '' : __t) +
'</span>\n			';
 } ;
__p += '\n		</div>\n	';
 } ;
__p += '\n</div>\n';

}
return __p
}