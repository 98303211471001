// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import hotelTemplate from './hotel.ejs';
import locationTemplate from './location.ejs';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';

import 'corejs-typeahead/dist/typeahead.jquery';
import axios from 'axios';

export default Widget.extend({

	validationTemplate,

	template,

	viewName: 'p-hotels-locations',

	el: 'div',

	events: {
		'click input': 'selectInput',
		'click .p-search__direction-suggest': 'selectInput',
	},

	initialize() {
		this.queryHandler = this.queryHandler.bind(this);
		this.render();
		this.getLocationPromise = Promise.resolve();
		this.$typeahead = this.getElements('input');
		this.$typeahead.typeahead(
			{
				hint: false,
				highlight: false,
				minLength: 0,
				limit: 1,
				classNames: {
					menu: 'b-hotels-locations__items',
					wrapper: 'b-hotels-locations__wrapper',
					input: 'b-hotels-locations__input',
					hint: 'b-hotels-locations__hint',
					dataset: 'b-hotels-locations__dataset',
					suggestion: 'b-hotels-locations__item',
					selectable: 'b-hotels-locations__selectable',
					empty: 'b-hotels-locations__empty',
					open: 'b-hotels-locations__open',
					cursor: 'b-hotels-locations__cursor',
					highlight: 'b-hotels-locations__highlight',
				},
			},
			{
				name: 'locations',
				limit: 10,
				source: (q, cb, async) => {
					clearTimeout(this.timer);
					this.timer = setTimeout(this.queryHandler, STATE.QUERY_DELAY, q, cb, async);
				},
				async: true,
				display: (item) => item.caption,
				templates: {
					header: `<h3 class="league-name">${L10N.get('Widgets.hotels.city')}</h3>`,
					suggestion: locationTemplate.bind(this),
				},
			},
			{
				name: 'hotels',
				limit: 10,
				source: (q, cb, async) => {
					clearTimeout(this.timer1);
					this.timer1 = setTimeout(() => {
						this.getLocationPromise.then((resp) => {
							const {result} = resp.data;
							async(result.hotels);
						});
					}, STATE.QUERY_DELAY);
				},
				async: true,
				display: this.getCaptionForHotel.bind(this),
				templates: {
					header: `<h3 class="league-name">${L10N.get('Widgets.hotels.hotel')}</h3>`,
					suggestion: hotelTemplate.bind(this),
				},
			});
	},

	queryHandler(q, cb, async) {
		this.getLocationPromise = axios.post('/midoffice/ibecorp-b2b/autocomplete/getLocations', {
			parameters: {
				pattern: q,
				searchType: 'HOTEL',
				limit: 20,
			},
		});
		this.getLocationPromise.then((resp) => {
			const {result} = resp.data;
			async(result.locations);
		});
	},

	selectInput() {
		this.$typeahead[0].select();
	},

	getCaptionForHotel(hotel, withWrapper = false) {
		const additionalInfo = [];

		if (hotel.city != null) {
			additionalInfo.push(hotel.city.caption);
		}

		if (hotel.country != null) {
			additionalInfo.push(hotel.country.caption);
		}

		if (hotel.hotelStars != null && parseInt(hotel.hotelStars, 10) !== -1) {
			additionalInfo[_.size(additionalInfo) - 1] += ` — ${hotel.hotelStars}*`;
		}

		if (withWrapper === true) {
			return `${hotel.name}<span>, ${additionalInfo.join(', ')}</span>`;
		}

		return `${hotel.name}, ${additionalInfo.join(', ')}`;
	},

	applyBinding(model) {
		this.$typeahead.on('typeahead:select', (ev, suggestion, dataset) => {
			if (dataset === 'hotels') {
				model.set(this.options.bindingProperty, null, {silent: true});
				model.set(this.options.bindingPropertyForHotel, _.extend({}, suggestion, {
					dataset,
				}));
			} else if (dataset === 'locations') {
				model.set(this.options.bindingPropertyForHotel, null, {silent: true});
				model.set(this.options.bindingProperty, _.extend({}, suggestion, {
					dataset,
				}));
			}

			if (suggestion && suggestion.additionalInfo) {
				this.$typeahead.tooltip({
					container: 'body',
					html: true,
					trigger: 'manual',
					placement: 'bottom',
					title: suggestion.additionalInfo,
					template: `<div class="tooltip tooltip-hotels-locations-error" role="tooltip">
									<div class="arrow"></div>
									<div class="tooltip-inner"></div>
								</div>`,
				}).tooltip('show');

				this.$el.addClass('validation-error');
			} else {
				this.$typeahead.tooltip('dispose');
				this.$el.removeClass('validation-error');
			}
		});

		this.$typeahead.on('typeahead:change', (e, value) => {
			if (!value) {
				model.set(this.options.bindingProperty, null, {silent: true});
				model.set(this.options.bindingPropertyForHotel, null, {silent: true});
			}
		});

		// Listen change service type for reset dropdown list
		this.listenTo(STATE.getSearchModel().get('serviceTypes'), `add`, () => {
			if (this.$typeahead.data('ttTypeahead') != null) {
				this.$typeahead.data('ttTypeahead').menu.query = null;
				this.$typeahead.data('ttTypeahead').menu.update('');
			}
			this.$typeahead.tooltip('dispose');
			this.$el.removeClass('validation-error');
		});

		this.listenTo(model, `validationError:${this.options.bindingProperty} validationError:${this.options.bindingPropertyForHotel}`, () => {
			this.$el.addClass('validation-error');
		});

		this.listenTo(model, `change:${this.options.bindingProperty} change:${this.options.bindingPropertyForHotel}`, (m, item) => {
			if (_.isObject(item)) {
				if (item.dataset === 'hotels') {
					this.$typeahead.typeahead('val', this.getCaptionForHotel(item));
				} else if (item.dataset === 'locations') {
					this.$typeahead.typeahead('val', item.caption);
				}
			}
		});

		const value = model.get(this.options.bindingProperty) || model.get(this.options.bindingPropertyForHotel);
		if (!_.isEmpty(value)) {
			this.model.trigger(`change:${this.options.bindingProperty}`, this.model, value);
			this.$typeahead.trigger('typeahead:select', value, value.dataset);
		}
	},

});
