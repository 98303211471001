module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-email-autocomplete__item ' +
((__t = ( obj.email && obj.email.length || obj.fio && obj.fio.length ? '' : 'dn' )) == null ? '' : __t) +
'">\n    <span class="b-email-autocomplete__item-caption">' +
((__t = ( obj.email + ' ' + obj.fio )) == null ? '' : __t) +
'</span>\n</div>\n';

}
return __p
}