import ConfirmationModeMsgHandler from './ConfirmationModeMsgHandler';

class ConfirmationModeHandler {
	popupHandler = null;

	constructor() {
		this.popupHandler = new ConfirmationModeMsgHandler();
	}
}

export default ConfirmationModeHandler;
