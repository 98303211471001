// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import suggestionTemplate from './suggestion.ejs';
import selectedItemTemplate from './selectedItem.ejs';
import 'corejs-typeahead/dist/typeahead.jquery';
import axios from 'axios';

export default Widget.extend({

	template,

	viewName: 'b-airlines',

	ui: {
		selected: '.b-airlines__selected',
		all: '.b-airlines',
		popup: '.b-airlines__popup',
		ctrl: '.b-airlines__label',
	},

	events: {
		'click .b-airlines__label,.b-airlines__select': 'onLabelClicked',
		'focus .b-airlines__input': 'onFocus',
		'blur .b-airlines__input': 'onBlur',
		'typeahead:beforeclose': 'typeaheadBeforeClose',

		'mousedown .b-airlines__label,.b-airlines__select': 'onLabelMouseDown',
		'mousedown .b-airlines__selected': 'selectedAirlineMouseDown',
		'mousedown .b-airlines__item-code': 'removeSelected',
	},

	/* el: 'div',*/

	// В событии OnBlur мы должны знать куда было нажатие, и в зависимости от этого скрывать всю всплывающую часть (popup)
	// либо препятствовать скрытию typeahed.suggestions
	// возможны варианты :
	// 'label' (видимая на форме невсплываемая часть),
	// 'suggestion' (выбор авиакомпании)
	// 'remove' (удаление авиакомпании из списка выбранных)
	lastClick: '',

	initialize(options) {
		this.options = options;
		this.queryHandler = this.queryHandler.bind(this);
		this.render();
		this.$typeahead = this.getElements('input');
		this.$typeahead.typeahead(
			{
				hint: false,
				highlight: false,
				minLength: 0,
				limit: 1,
				classNames: {
					menu: 'b-airlines__items',
					wrapper: 'b-airlines__wrapper',
					input: 'b-airlines__input',
					hint: 'b-airlines__hint',
					dataset: 'b-airlines__dataset',
					suggestion: 'b-airlines__item',
					selectable: 'b-airlines__selectable',
					empty: 'b-airlines__empty',
					open: 'b-airlines__open',
					cursor: 'b-airlines__cursor',
					highlight: 'b-airlines__highlight',
				},
			},
			{
				name: 'airlinesFilter',
				limit: 200,
				source: (q, cb, async) => {
					clearTimeout(this.timer);
					this.timer = setTimeout(this.queryHandler, STATE.QUERY_DELAY, q, cb, async);
				},
				display: ' get display ',
				async: true,
				templates: {
					suggestion: suggestionTemplate,
				},
			});
	},

	queryHandler(q, cb, async) {
		axios.post('/midoffice/ibecorp-b2b/avia/getAirlines', {
			parameters: {
				pattern: q,
				limit: 100,
			},
		})
			.then((resp) => {
				async(resp.data.result.airlines.reduce((result, item) => {
					result.push(item);
					return result;
				}, []));
			});
	},

	applyBinding(model) {
		if (!this.model.get(this.options.bindingProperty)) {
			this.model.set(this.options.bindingProperty, []);
		}

		this.$typeahead.on('typeahead:select', (ev, suggestion) => {
			let modelAirlines = model.get(this.options.bindingProperty);

			if (modelAirlines.length < 3
				&& !modelAirlines.find((element) => {
					return element.uid === suggestion.uid;
				})) {
				modelAirlines = modelAirlines.concat({
					uid: suggestion.uid,
					airportCaption: `${suggestion.airportCaption}`,
				});
				model.set(this.options.bindingProperty, modelAirlines);
				model.trigger(`change:${this.options.bindingProperty}`, model, model.get(this.options.bindingProperty));
			}

			// не закрываем suggestion
			this.lastClick = 'suggestion';
		});

		this.listenTo(model, `validationError:${this.options.bindingProperty}`, () => {
			this.$el.addClass('validation-error');
		});

		this.listenTo(model, `change:${this.options.bindingProperty}`, (__model, airlines) => {
			this.ui.selected.empty();
			if (!airlines) {
				return;
			}
			airlines.forEach(airline => {
				this.ui.selected.append(selectedItemTemplate(airline));
			});

			this.showSelectedAirlinesCount();
		});

		// set up selected airlines
		model.trigger(`change:${this.options.bindingProperty}`, model, model.get(this.options.bindingProperty));
	},

	removeSelected(e) {
		const uid = this.$(e.currentTarget).attr('uid');
		this.model.set(this.options.bindingProperty, this.model.get(this.options.bindingProperty).filter(item => item.uid !== uid));
		this.lastClick = 'remove';
	},

	onBlur() {
		if (!(this.lastClick === 'remove')) {
			// при потере фокуса скрываем список выбранных авиакомпаний,
			this.hideSelected();
			this.$el.removeClass('open');
		} else {
			// если потеря фокуса произошла вследствие удаления выбранной авиакомпании - проставляем фокус обратно.
			setTimeout(() => {
				this.$typeahead.focus();
			}, 0);
		}
	},

	hideSelected() {
		this.ui.popup.hide();
	},

	showSelectedAirlinesCount() {
		const value = this.model.get(this.options.bindingProperty);
		if (!value || value.length === 0) {
			this.ui.ctrl.html(`${L10N.get('searchForm.selected2')}`);
			this.$el.removeClass('active');
			return;
		}
		this.$el.addClass('active');
		const selectedValues = value.reduce((prev, cur) => {
			prev.push(cur.airportCaption);
			return prev;
		}, []).join(', ');
		this.ui.ctrl.html(selectedValues);
	},

	typeaheadBeforeClose() {
		if (this.lastClick === 'remove') {
			this.lastClick = '';
			return false;
		}
		if (this.lastClick === 'suggestion') {
			this.lastClick = '';
			return false;
		}
		return true;
	},

	onLabelClicked() {
		if (this.$el.hasClass('open') || this.lastClick === 'label') {
			this.$el.removeClass('open');
			this.lastClick = '';
			return;
		}
		this.$el.addClass('open');
		this.ui.popup.show();
		this.$typeahead.focus();
	},

	onLabelMouseDown() {
		if (this.$el.hasClass('open')) {
			this.lastClick = 'label';
		}
	},

	selectedAirlineMouseDown() {
		this.lastClick = 'remove';
	},
});
