// eslint-disable-next-line banned-modules
'use strict';

import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import 'corejs-typeahead/dist/typeahead.jquery';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';

export default Widget.extend({

	validationTemplate,

	template,

	events: {
		'change .p-login__form-input': 'change',
	},

	ui: {
		input: '.p-login__form-input',
	},

	initialize() {
		this.options.withLabel = !STATE.checkSiteType('B2C');
		this.render(Object.assign({
			type: 'text',
		}, this.options));
	},

	change() {
		this.model.set(this.options.bindingProperty, this.ui.input.val());
	},

	applyBinding(model) {
		this.model = model;
		this.listenTo(model, `change:${this.options.bindingProperty}`, (thisModel, value) => {
			this.ui.input.val(value || '');
		});
		this.ui.input.val(model.get(this.options.bindingProperty));
	},
});
