// eslint-disable-next-line banned-modules
'use strict';

import './style.less';

import BaseView from '@/classes/base.view';
import EmployeesView from './b-employees/index';
import template from './template.ejs';

export default BaseView.extend({

	template,

	ui: {
		employeesContainer: '.b-search-form__employees',
	},

	employeesView: null,

	prevCompany: null,

	initialize() {
		this.model = this.options.model;
		this.render();

		if (this.model.get('client') == null && _.isObject(this.options.default)) {
			this.model.set('client', this.options.default);
		}
		this.listenTo(this.model, 'change:client', this.renderEmployeesContainer.bind(this));
		this.listenTo(this.model, 'change:updatePassengersByServiceType', () => {
			this.renderEmployeesContainer(null, this.model.get('client'));
		});
		this.renderEmployeesContainer(null, this.model.get('client'));
	},

	resetPassengers(cleanupContainer = false) {
		this.model.get('passengers').reset([]);
		this.model.set('clientHasChanged', true);
		if (cleanupContainer) this.ui.employeesContainer.empty();
	},

	renderEmployeesContainer(model, value) {
		if (value == null) {
			if (model == null) return this;
			if ((model.previous('client') && this.options.retailClient) && model.previous('client').uid !== this.options.retailClient.uid) {
				this.resetPassengers(true);
			}
			return this;
		}

		if (this.prevCompany != null && this.prevCompany.uid !== value.uid) {
			this.resetPassengers();
		}

		this.prevCompany = value;

		const RETAIL_CLIENT = this.options.retailClient;

		if (STATE.layout) {
			STATE.layout.header.resetAdditionalClient();
			if (((RETAIL_CLIENT != null && value.uid !== RETAIL_CLIENT.uid) || RETAIL_CLIENT == null) && STATE.checkSiteType('B2B')) {
				STATE.layout.header.setAdditionalClient(value);
			}
		}

		this.employeesView = new EmployeesView({
			model: this.model,
			isRetailClient: STATE.checkSiteType('B2C') ? true : (RETAIL_CLIENT != null) && (value.uid === RETAIL_CLIENT.uid),
		});

		this.ui.employeesContainer.html(this.employeesView.$el);

		return this;
	},

});
