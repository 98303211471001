// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

export default BaseView.extend({

	template,

	events: {
		'click .b-employees-collection__add': 'addNewItem',
	},

	initialize() {
		if (this.collection.length === 0) {
			this.collection.reset();
			this.addNewItem();
		} else if (this.options.maxEmployees && this.collection.length > this.options.maxEmployees) {
			this.collection.reset(this.collection.slice(0, this.options.maxEmployees));
		}

		this.render();
		this.listenTo(this.collection, 'add remove reset', () => {
			this.render();
		});
	},

	addNewItem() {
		this.collection.add(new this.collection.model(null));
	},

	render() {
		if (this.options.render) {
			this.options.render.call(this);
		} else {
			this.setElement(template.call(this, this.options));
			const content = this.$('.b-employees-collection__content');
			this.collection.each(item => {
				const tempView = new item.View({
					model: item,
					company: this.options.company,
					bindingProperty: this.options.bindingProperty || 'passengers',
				});
				if (tempView.applyBinding) {
					tempView.applyBinding(item);
				}
				content.before(tempView.$el);
			});
			this.options.container.empty().append(this.$el);
		}

		if (this.collection.length === 1) {
			this.$el.find('.l-grid-layout-33:first .b-input__remove').hide();
		}
	},

});
