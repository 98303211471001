module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	var sameOffersSet = sameOffersSet || new Set();
    var sameOffersProviders = sameOffersProviders || [];
	var manyRooms = roomsCount > 1;
	var showGdsAccountName = showGdsAccountName || false;
	// IBECORP-3752 ->
	var searchParams = STATE.getSearchParametrs();
	var checkIn = this.model.get('checkInDateTime') || searchParams.checkInDate + 'T14:00:00';
	var checkOut = this.model.get('checkOutDateTime') || searchParams.checkOutDate + 'T12:00:00';
	// IBECORP-3752 -|

	function repriceSameOffers(offer, sameOffersSet, canHaveSameOffers) {
    if (!offer || offer.repriced === true || !offer.price || !canHaveSameOffers || !Array.from(sameOffersSet).map((sameOffer) => sameOffer.id).includes(offer.id)) {
      return offer;
    };
		var result = _.clone(offer);
		var fields = ['total', 'totalFee', 'totalTaxes', 'totalFare'];
		var sameOffers = _.filter(Array.from(sameOffersSet), function (o) { return o.id === result.id && !o.repriced});
		var priceMultiplier = canHaveSameOffers && !offer.repriced && sameOffers.length || 1;

		_.each(fields, function(key) {
			if (!_.isEmpty(result.price[key]) && parseInt(result.price[key].amount) > 0) {
				result.price[key].oldAmount = result.price[key].amount;
				result.price[key].amount = (parseFloat(result.price[key].amount) * priceMultiplier).toFixed(Formatter.getDecimal(result.price[key].amount));
			}
		});

		if (!_.isEmpty(result.price.rates)) {
			_.each(result.price.rates, function(rate) {
				rate.total.oldAmount = rate.total.amount;
				rate.total.amount = (parseFloat(rate.total.amount) * priceMultiplier).toFixed(Formatter.getDecimal(rate.total.amount));
			});
		}
		result.repriced = true;
		// _.each(sameOffers, function (o) {o.repriced = true});
     
        return result;
    }
;
__p += '\n\n';
 if (!_.isEmpty(groups)) { ;
__p += '\n	<div class="b-hotel-extended__offers b-hotel-offers ' +
((__t = ( manyRooms ? 'b-hotel-offers--selection-room' : '' )) == null ? '' : __t) +
'">\n\n		';
 if (checkIn || checkOut) { ;
__p += '\n			<div class="b-hotel-offers__check-date-wrapper">\n				<div class="b-hotel-offers__check-date-container">\n					';
 if (checkIn) { ;
__p += '\n						<div class="b-hotel-offers__check-date">\n							' +
((__t = ( L10N.get('hotels.checkin') )) == null ? '' : __t) +
':\n							<strong>\n								' +
((__t = ( new Time(checkIn).toDateString() )) == null ? '' : __t) +
',\n								' +
((__t = ( L10N.get('Common.with') )) == null ? '' : __t) +
' ' +
((__t = ( new Time(checkIn).toShortTime() )) == null ? '' : __t) +
'\n							</strong>\n						</div>\n					';
 } ;
__p += '\n					';
 if (checkOut) { ;
__p += '\n						<div class="b-hotel-offers__check-date">\n							' +
((__t = ( L10N.get('hotels.checkout') )) == null ? '' : __t) +
':\n							<strong>\n								' +
((__t = ( new Time(checkOut).toDateString() )) == null ? '' : __t) +
',\n								' +
((__t = ( L10N.get('Common.until') )) == null ? '' : __t) +
' ' +
((__t = ( new Time(checkOut).toShortTime() )) == null ? '' : __t) +
'\n							</strong>\n						</div>\n					';
 } ;
__p += '\n				</div>\n			</div>\n		';
 } ;
__p += '\n\n		';
 _.each(groups, function (group) {
            var canHaveSameOffers = group[0] && group[0].provider && group[0].provider.uid ? sameOffersProviders.includes(group[0].provider.uid) : false;
            var offer = repriceSameOffers(group[0], sameOffersSet, canHaveSameOffers);
		;
__p += '\n			<div class="b-hotel-offers-group ' +
((__t = ( offer.filtered ? 'b-hotel-offers-group--filtered' : '' )) == null ? '' : __t) +
'">\n				<div class="b-hotel-offers-offer__wrapper">\n					' +
((__t = ( require('./b-offers-offer.ejs')({
						offer: offer,
						group: group,
						manyRooms: canHaveSameOffers ? false : manyRooms,
						roomsCount: roomsCount,
						approvalAllowed: approvalAllowed,
						issueAllowed: issueAllowed,
                        showGdsAccountName: showGdsAccountName,
					}) )) == null ? '' : __t) +
'\n				</div>\n\n				';
 if (group.length > 1) { ;
__p += '\n					<div class="b-hotel-offers-grouped" style="display: none;">\n						<div class="b-hotel-offers-grouped__inner">\n							';
 
                                _.each(group.slice(1), function(goffer) {
                                    var canHaveSameOffers = goffer.provider && goffer.provider.uid ? sameOffersProviders.includes(goffer.provider.uid) : false;
                            ;
__p += '\n								' +
((__t = ( require('./b-offers-offer.ejs')({
                                    offer: repriceSameOffers(goffer, sameOffersSet, canHaveSameOffers),
									group: [],
									manyRooms: canHaveSameOffers ? false : manyRooms,
									roomsCount: roomsCount,
									approvalAllowed: approvalAllowed,
									issueAllowed: issueAllowed,
                                    showGdsAccountName: showGdsAccountName,
								}) )) == null ? '' : __t) +
'\n							';
 }); ;
__p += '\n						</div>\n						<div class="b-expand b-hotel-offer__additional-prices"></div>\n					</div>\n				';
 } ;
__p += '\n			</div>\n		';
 }) ;
__p += '\n	</div>\n';
 } ;


}
return __p
}