module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var isPassportModel = this.options.isPassportModel; ;
__p += '\r\n<label class="' +
((__t = ( isPassportModel && withoutMiddleNameLat ? 'b-middle-name-lat' : 'b-middle-name' )) == null ? '' : __t) +
' ' +
((__t = (isPassportModel ? 'b-middle-name__pass' : 'b-middle-name__person')) == null ? '' : __t) +
' ' +
((__t = ( classes )) == null ? '' : __t) +
' b-input">\r\n	<div class="b-input__label-container">\r\n		<span class="b-input__label b-middle-name__label">' +
((__t = ( label )) == null ? '' : __t) +
'</span>\r\n		';
 if (withoutMiddleName || withoutMiddleNameLat ) { ;
__p += '\r\n			<span class="b-input__label ' +
((__t = ( isPassportModel && withoutMiddleNameLat ? 'b-middle-name__without-lat' : 'b-middle-name__without' )) == null ? '' : __t) +
' action-link">' +
((__t = ( L10N.get("bookingForm.withoutMiddleName") )) == null ? '' : __t) +
'</span>\r\n		';
 } ;
__p += '\r\n	</div>\r\n	<input class="b-input__value b-input-value b-middle-name__value b-middle-name__validation-checkbox" type="text" placeholder="' +
((__t = ( placeholder )) == null ? '' : __t) +
'">\r\n</label>\r\n';

}
return __p
}