module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	var disabledEdit = this.model.get('disabled') === true;
  var contactPersonEditGranted = this.contactPersonEditGranted;
  var contactPersonDisableFreeTextInput = this.contactPersonDisableFreeTextInput;
	var clientUid = (client || {}).uid || null;
;
__p += '\r\n\r\n<section class="b-customer ' +
((__t = ( disabledEdit ? 'b-customer--disabled' : '' )) == null ? '' : __t) +
'">\r\n	<header class="b-customer__header collapse--with-anchor--white" data-toggle="collapse" data-target=".toggle-customer">' +
((__t = ( L10N.get("bookingForm.customer") )) == null ? '' : __t) +
'</header>\r\n\r\n	';
 if (disabledEdit && contactPersonEditGranted) { ;
__p += '\r\n		<div class="b-customer__info toggle-customer show">\r\n			<div class="l-grid-container">\r\n				<div class="l-grid-layout-33">\r\n					<span class="pos-rel">\r\n						';
 if (this.contactAutocompleteAvailable) { ;
__p += '\r\n                        <span class="js-widget"  onclick=\'return new Widgets.ContactAutocomplete({ bindingProperty: "lastName", label: L10N.get("bookingForm.lastName"), placeholder: L10N.get("bookingForm.lastNamePlaceholder"), attrs: {readonly: true, disabled: true}, clientUid: "'+ clientUid +'"})\'></span>\r\n                        ';
 } else { ;
__p += '\r\n                        <span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "lastName", label: L10N.get("bookingForm.lastName"), placeholder: L10N.get("bookingForm.lastNamePlaceholder"), attrs: {readonly: true} })\'></span>\r\n                        ';
 } ;
__p += '\r\n						<i class="b-customer__allow-edit g-icon-edit"></i>\r\n					</span>\r\n				</div>\r\n				<div class="l-grid-layout-33">\r\n					<span class="pos-rel">\r\n						<span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "firstName", label: L10N.get("bookingForm.firstName"), placeholder: L10N.get("bookingForm.firstNamePlaceholder"), attrs: {readonly: true} })\'></span>\r\n						<i class="b-customer__allow-edit g-icon-edit"></i>\r\n					</span>\r\n				</div>\r\n			</div>\r\n			<div class="l-grid-container">\r\n				<div class="l-grid-layout-33">\r\n					<span class="pos-rel">\r\n						<span class="js-widget"  onclick=\'return new Widgets.Phone({ bindingProperty: "phone", label: L10N.get("bookingForm.phone"), placeholder: L10N.get("bookingForm.phonePlaceholder"), attrs: {readonly: true} })\'></span>\r\n						<i class="b-customer__allow-edit g-icon-edit"></i>\r\n					</span>\r\n				</div>\r\n				<div class="l-grid-layout-33">\r\n					<span class="pos-rel">\r\n						<span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "email", label: L10N.get("bookingForm.email"), classes: "b-email", placeholder: L10N.get("bookingForm.emailPlaceholder"), attrs: {readonly: true} })\'></span>\r\n						<i class="b-customer__allow-edit g-icon-edit"></i>\r\n					</span>\r\n				</div>\r\n			</div>\r\n		</div>\r\n	';
 } else { ;
__p += '\r\n		<div class="b-customer__info toggle-customer show">\r\n			<div class="l-grid-container">\r\n				<div class="l-grid-layout-33">\r\n                    ';
 if (this.contactAutocompleteAvailable) { ;
__p += '\r\n                        <span class="js-widget"  onclick=\'return new Widgets.ContactAutocomplete({ bindingProperty: "lastName", label: L10N.get("bookingForm.lastName"), placeholder: L10N.get("bookingForm.lastNamePlaceholder"), clientUid: "'+ clientUid +'", disableFreeTextInput: "'+ contactPersonDisableFreeTextInput +'", attrs: {readonly: "' + !contactPersonEditGranted + '", disabled: "' + !contactPersonEditGranted + '"} })\'></span>\r\n                    ';
 } else { ;
__p += '\r\n                        <span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "lastName", label: L10N.get("bookingForm.lastName"), placeholder: L10N.get("bookingForm.lastNamePlaceholder"), attrs: {readonly: "' + !contactPersonEditGranted + '"} })\'></span>\r\n                    ';
 } ;
__p += '\r\n				</div>\r\n				<div class="l-grid-layout-33">\r\n					<span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "firstName", label: L10N.get("bookingForm.firstName"), placeholder: L10N.get("bookingForm.firstNamePlaceholder"), attrs: {readonly: "' + !contactPersonEditGranted + '"}, disabled: "'+ contactPersonDisableFreeTextInput +'" })\'></span>\r\n				</div>\r\n\r\n				';
 if (this.model.get('overrideCustomer') === true) { ;
__p += '\r\n					<div class="l-grid-layout-33">\r\n						<i class="b-customer__attention g-icon-attention" data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( L10N.get('bookingForm.customerDataWillBeChanged)') )) == null ? '' : __t) +
'"></i>\r\n					</div>\r\n				';
 } ;
__p += '\r\n			</div>\r\n			<div class="l-grid-container">\r\n				<div class="l-grid-layout-33">\r\n					<span class="js-widget"  onclick=\'return new Widgets.Phone({ bindingProperty: "phone", label: L10N.get("bookingForm.phone"), placeholder: L10N.get("bookingForm.phonePlaceholder"), disabled: "' + !contactPersonEditGranted + '" })\'></span>\r\n				</div>\r\n				<div class="l-grid-layout-33">\r\n					<span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "email", label: L10N.get("bookingForm.email"), classes: "b-email", placeholder: L10N.get("bookingForm.emailPlaceholder"), attrs: {readonly: "' + !contactPersonEditGranted + '"} })\'></span>\r\n				</div>\r\n			</div>\r\n		</div>\r\n	';
 } ;
__p += '\r\n</section>\r\n';

}
return __p
}