module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


  var serviceAuthStatusClsName = view.getServiceAuthStatusPlaceholderClassNameSuffix(serviceAuthStatus);
;
__p += '\n<div class=\'b-cabinet-order-auth-step b-cabinet-order-auth-step--' +
((__t = ( serviceAuthStatusClsName )) == null ? '' : __t) +
'\'>\n  ' +
((__t = ( require('./template-header.ejs')({ label: label }) )) == null ? '' : __t) +
'\n</div>';

}
return __p
}