// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';
import InitiatePaymentModel from '@/blocks/elements/b-payment/model';

const OrderModel = BaseModel.extend({
	defaults() {
		return _.extend({}, {
			checkBooking: new BaseModel(),
			initiatePayment: new InitiatePaymentModel(),
		});
	},

	toJSON() {
		return {
			parameters: this.initiatePayment.toJSON.call(this),
		};
	},

});

export default OrderModel;
