import React, { useContext, useEffect, useState } from "react";
import Pages from "./Pages/Pages";
import PageSize from "./PageSize/PageSize";
import { ReactAppGlobalContext } from "../../../../js/utils/react-utils";

export type PaginationInfo = {
	currentPage: number;
	pageSize: number;
};

interface PaginationProps {
	totalCount: number;
	pageSize?: number;
	currentPage?: number;
	setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
	setPageSize?: React.Dispatch<React.SetStateAction<number>>;
	onChange?: (paginationInfo: PaginationInfo) => void;
}

interface PaginationContextValue {
	totalCount?: number;
	pageSize?: number;
	currentPage?: number;
	onChange?: (paginationInfo: PaginationInfo) => void;
	setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
	setPageSize?: React.Dispatch<React.SetStateAction<number>>;
}

export const PaginationContext = React.createContext<PaginationContextValue>(
	{}
);
const Pagination: React.FC<PaginationProps> = (props) => {
	const {
		pageSize,
		currentPage,
		totalCount,
		onChange,
		setCurrentPage,
		setPageSize,
	} = props;

	const { L10N } = useContext(ReactAppGlobalContext);

	return (
		<PaginationContext.Provider
			value={{
				onChange,
				setCurrentPage,
				setPageSize,
				pageSize,
				currentPage,
				totalCount,
			}}
		>
			<div className="b-block-table-footer">
				<div className="b-footer-element b-footer-count">
					{L10N.get("cabinet.table.footer.entriesCounter")}:{" "}
					<span>{totalCount}</span>
				</div>
				<div className="b-footer-element b-footer-show">
					<PageSize />
				</div>
				<div className="b-footer-element b-footer-pages-container">
					<div className="b-footer-pages">
						<Pages />
					</div>
				</div>
			</div>
		</PaginationContext.Provider>
	);
};
export default Pagination;
