// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import suggestionTemplate from './suggestion.ejs';
import 'corejs-typeahead/dist/typeahead.jquery';
import axios from 'axios';

export default Widget.extend({

	template,

	viewName: 'b-railway-carriers-input',

	ui: {
		all: '.b-railway-carriers-input',
	},

	initialize(options) {
		this.options = options;
		this.hasBonusCard = options.hasBonusCard;
		this.queryHandler = this.queryHandler.bind(this);

		this.render();
		this.$typeahead = this.getElements('input');
		this.$typeahead.typeahead(
			{
				hint: false,
				highlight: false,
				minLength: 0,
				limit: 1,
				classNames: {
					menu: 'b-railway-carriers-input__items',
					wrapper: 'b-railway-carriers-input__wrapper',
					input: 'b-railway-carriers-input__input',
					hint: 'b-railway-carriers-input__hint',
					dataset: 'b-railway-carriers-input__dataset',
					suggestion: 'b-railway-carriers-input__item',
					selectable: 'b-railway-carriers-input__selectable',
					empty: 'b-railway-carriers-input__empty',
					open: 'b-railway-carriers-input__open',
					cursor: 'b-railway-carriers-input__cursor',
					highlight: 'b-railway-carriers-input__highlight',
				},
			},
			{
				name: 'railwayCarriers',
				limit: 200,
				source: (q, cb, async) => {
					clearTimeout(this.timer);
					this.timer = setTimeout(this.queryHandler, STATE.QUERY_DELAY, q, cb, async);
				},
				display: (item) => {
					return `${item.railwayCarrier.caption} (${item.railwayCarrier.uid})`;
				},
				async: true,
				templates: {
					suggestion: suggestionTemplate,
				},
			});
	},

	queryHandler(q, cb, async) {
		axios.get('/midoffice/ibecorp-b2b/rail/getRailwayCarriers', {
			params: {
				hasBonusCard: true,
			},
		}).then((resp) => {
			async(resp.data.result.reduce((result, item) => {
				result.push(item);
				return result;
			}, []));
		});
	},

	applyBinding(model) {
		this.$typeahead.on('typeahead:select', (ev, suggestion) => {
			model.set(this.options.bindingProperty, suggestion);
		});

		this.listenTo(model, `validationError:${this.options.bindingProperty}`, () => {
			this.$el.addClass('validation-error');
		});

		this.listenTo(model, `change:${this.options.bindingProperty}`, (__model, railwayCarrier) => {
			if (railwayCarrier) {
				this.$typeahead.trigger('typeahead:select', railwayCarrier);

				if (railwayCarrier.caption != null) {
					this.$typeahead.typeahead('val', railwayCarrier.caption);
				}
			} else {
				this.$typeahead.typeahead('val', '');
			}
		});

		const value = model.get(this.options.bindingProperty);
		if (value) {
			const caption = model.get(this.options.bindingProperty);
			this.$typeahead.typeahead('val', caption);
		}
	},

});
