module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


  var t = this;
  var service = t.model.get("service");
  var travellers = service.travellers || [];
  var authorizationSteps = service.authorizationSteps || [];
;
__p += '\n<div class="b-cabinet-order-auth-steps__modal">\n  <section class="b-cabinet-order-auth-steps">\n    <div class="b-cabinet-order-auth-steps__modal-content-container">\n      <div class="b-cabinet-order-auth-steps__modal-header">\n        <div class="b-cabinet-order-auth-steps__header-content">\n          <h5 class="b-cabinet-order-auth-steps__header-title">\n            ' +
((__t = ( L10N.get('cabinet.orders.authorization') )) == null ? '' : __t) +
'\n          </h5>\n          <div\n            class=\'b-cabinet-order-auth-steps__header-row ' +
((__t = ( travellers.length > 1 ? "" : "b-cabinet-order-auth-steps__header-row--al-i-c" )) == null ? '' : __t) +
'\'\n          >\n            <span class="b-cabinet-order-auth-steps__header-row-label">\n              ' +
((__t = ( L10N.get('Common.for') )) == null ? '' : __t) +
'\n            </span>\n            ';
  ;
__p += '\n            <span class="b-cabinet-order-auth-steps__header-row-value">\n              ';
  _.each(travellers, function (traveller) { ;
__p += '\n                <span>' +
((__t = ( traveller.fullName )) == null ? '' : __t) +
'</span>\n              ';
 }) ;
__p += '\n            </span>\n          </div>\n        </div>\n        <button type="button" class="b-cabinet-order-auth-steps__header-close-btn js-close-modal"></button>\n      </div>\n      <div class="b-cabinet-order-auth-steps__modal-content">\n        <div class="b-cabinet-order-auth-steps__content-wrapper">\n          <div class="b-cabinet-order-auth-steps__content-scroll-wrapper">\n            <ul class="b-cabinet-order-auth-steps__steps-list">\n              ';
  _.each(authorizationSteps, function (authStep, index) { ;
__p += '\n                <li class="b-cabinet-order-auth-steps__steps-list-item">\n                  ';

                    var stepOptions = {
                      index: index,
                      label: authStep.name || L10N.get("cabinet.orders.step") + " " + String(index + 1),
                      isCollapsible: true,
                      view: t,
                      stepData: authStep,
                    }
                  ;
__p += '\n                  ' +
((__t = ( require('./b-step/template.ejs')(stepOptions) )) == null ? '' : __t) +
'\n                </li>\n              ';
 }) ;
__p += '\n              <li class="b-cabinet-order-auth-steps__steps-list-item">\n                ';

                  var stepOptions = {
                    label: L10N.get("cabinet.orders.authorizationCompleted"),
                    view: t,
                    serviceAuthStatus: (service && service.authorizationStatus && service.authorizationStatus.uid) || null,
                  }
                ;
__p += '\n                ' +
((__t = ( require('./b-step/b-step-placeholder/template.ejs')(stepOptions) )) == null ? '' : __t) +
'\n              </li>\n            </ul>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class="b-cabinet-order-auth-steps__modal-footer-btns">\n      <button\n        class="b-cabinet-order-auth-steps__modal-footer-btn b-cabinet-order-auth-steps__modal-footer-btn--close js-close-modal"\n        title="' +
((__t = ( L10N.get('settings.errorPopupButton') )) == null ? '' : __t) +
'"\n        type="button"\n      >\n				<span>' +
((__t = ( L10N.get('settings.errorPopupButton') )) == null ? '' : __t) +
'</span>\n			</button>\n    </div>\n  </section>\n</div>';

}
return __p
}