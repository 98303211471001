module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var offer = offer || {}
    const isSameDate = new Time().getMoment().isSame(offer.freeCancellationTerm, 'day');
    const showGetTermsBtn = (!offer.freeCancellationTerm && !offer.cancellationPolicyText) || offer.cancellationPolicyNextStep;
;
__p += '\n';
 if (offer.freeCancellationTerm && !isSameDate) { ;
__p += '\n    <div class="b-hotel-offer__checked-wrapper">\n        <span class="b-hotel-offer__checked"></span>\n        <span class="b-hotel-offer__checked-label">\n            ' +
((__t = ( L10N.get('hotels.freeCancellation'))) == null ? '' : __t) +
' ' +
((__t = ( L10N.get('cabinet.orders.until') )) == null ? '' : __t) +
' ' +
((__t = ( new Time(offer.freeCancellationTerm).toDateString() )) == null ? '' : __t) +
'\n        </span>\n    </div>\n';
 } else if (!showGetTermsBtn && (offer.cancellationPolicyNextStep || isSameDate)) { ;
__p += '\n    <div class="b-hotel-offer__cancel-wrapper">\n        <span class="b-hotel-offer__cancel"></span>\n        <span class="b-hotel-offer__cancel-label">' +
((__t = ( L10N.get('hotels.noFreeCancellation') )) == null ? '' : __t) +
'</span>\n    </div>\n';
 } ;
__p += '\n';
 if (showGetTermsBtn) { ;
__p += '\n    <div class="b-hotel-offer__request-info-wrapper">\n        <span\n            class="b-hotel-offer__terms-link js-getIntentionFormSettings"\n            role="button"\n            title="' +
((__t = ( L10N.get('hotels.getCancellationTerms') )) == null ? '' : __t) +
'"\n            data-offer-id="' +
((__t = ( offer.id )) == null ? '' : __t) +
'"\n            data-offer-index="' +
((__t = ( offer.__index )) == null ? '' : __t) +
'"\n        >\n            ' +
((__t = ( L10N.get('hotels.getCancellationTerms') )) == null ? '' : __t) +
'\n        </span>\n    </div>\n';
 } else { ;
__p += '\n    <div class="b-hotel-offer__info-wrapper">\n        <span class="b-hotel-offer__info"></span>\n        <span class="b-hotel-offer__info-label" data-html="true" data-toggle="tooltip" data-original-title="<div>' +
((__t = ( offer.cancellationPolicyText )) == null ? '' : __t) +
'</div>">' +
((__t = ( L10N.get('hotels.cancellationTerms') )) == null ? '' : __t) +
' </span>\n    </div>\n';
 } ;


}
return __p
}