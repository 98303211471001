// eslint-disable-next-line banned-modules
'use strict';

import CostCodesView from '@/blocks/elements/b-cost-codes/index.js';
import Deferred from '@/utils/Deferred';

const PrefilledStatus = {
	FALSE : 'false',
	FETCHING : 'fetching',
	PREFILLED : 'prefilled',
};

class TravellerCostCodesAdapter {
	costCodesView = null;

	parentView = null;

	prefilled = PrefilledStatus.FALSE;

	constructor(opts = {}) {
		this.parentView = opts.parentView;
	}

	renderCostCodesView = (opts = {}) => {
		const {
			applyCurrentCostCodesCollectionValues = false,
			renderContainer,
			passenger,
			organizationCostCodes = [],
		} = opts;
		if (!_.isEmpty(organizationCostCodes) && renderContainer) {
			this.removeCostCodesView();
			this.costCodesView = new CostCodesView({
				collection: this.parentView.model.get('costCodes'),
				passenger,
				organizationCostCodes,
				applyCurrentCostCodesCollectionValues,
			});
			renderContainer.html(this.costCodesView.$el);
			if (this.shouldApplyDependentCostCodesListener()) {
				this.applyDependentCostCodesListener();
			}

			this.parentView.trigger('costCodesViewRendered', this.parentView);
		}
	};

	shouldApplyDependentCostCodesListener = () => {
		let shouldApplyListener = false;
		if (this.parentView.model.get('costCodes').length) {
			for (let i = 0; i < this.parentView.model.get('costCodes').length; i++) {
				const costCodeModel = this.parentView.model.get('costCodes').at(i);
				if (costCodeModel && costCodeModel.get('hasDependantCostCodes')) {
					shouldApplyListener = true;
					break;
				}
			}
		}
		return shouldApplyListener;
	};

	applyDependentCostCodesListener = async () => {
		this.removeDependentCostCodesListener();
		const costCodes = this.parentView.model.get('costCodes');
		
		// Вызываем при первой отрисовки, чтобы сразу актуализировать зависимые поля
		// Ищем первые cost code с зависимостями и предзаполненным значением
		const firstFilledCostCode = costCodes?.find((costCode) => costCode.get('hasDependantCostCodes') && costCode.get('value'));
		if (firstFilledCostCode) {
			if (this.prefilled === PrefilledStatus.FALSE) {
				this.prefilled = PrefilledStatus.FETCHING;
				try {
					await this.handleDependentCostCodeChange(firstFilledCostCode);
				} catch (error) {
					logger(error);
				}
				this.prefilled = PrefilledStatus.PREFILLED;
			}
		} else {
			this.prefilled = PrefilledStatus.PREFILLED;
		}
		if (this.prefilled === PrefilledStatus.PREFILLED) {
			// Вешаем слушателя
			costCodes.on('change:value', this.handleDependentCostCodeChange);
		}
	};

	removeDependentCostCodesListener = () => {
		this.parentView.model
			.get('costCodes')
			.off('change:value', this.handleDependentCostCodeChange);
	};

	handleDependentCostCodeChange = async (model) => {
		if (model.get('hasDependantCostCodes')) {
			await this.fetchNUpdateTravellerCostCodes();
		}
	};

	fetchNUpdateTravellerCostCodes = async () => {
		const exec = async () => {
			await this.parentView.getFormSettings();
			this.renderCostCodesView({
				applyCurrentCostCodesCollectionValues: true,
				...(this.parentView?.getCommonCostCodesRenderParams() || {}),
			});
		};
		if (this.parentView.addPreValidateDeferred) {
			const dfd = new Deferred();
			this.parentView.addPreValidateDeferred(dfd);
			try {
				await exec();
				dfd.resolve();
				this.parentView.removePreValidateDeferred();
			} catch (e) {
				dfd.reject(e);
			}
		} else {
			await exec();
		}
	};

	resetNonCommonCostCodes = () => {
		const costCodes = this.parentView.model.get('costCodes');
		// const modelsToDelete = [];
		if (costCodes?.length) {
			costCodes.each((costCodeModel) => {
				if (!costCodeModel.get('common')) {
					costCodeModel.set('value', null, { silent: true });
					this.prefilled = PrefilledStatus.FALSE;
					// modelsToDelete.push(costCodeModel);
				}
			});
		}
		// if (modelsToDelete.length) {
		// 	/*
		//     silent == true, чтобы предотвратить лишние вызовы, т.к. после вызова данной функции и так
		//     должны последовать вызовы функций перезапроса данных.
		//   */
		// 	costCodes.remove(modelsToDelete, { silent: true }); 
		// }
	};

	removeCostCodesView = () => {
		if (this.costCodesView) {
			this.removeDependentCostCodesListener();
			this.costCodesView.destroy();
			this.costCodesView = null;
		}
	};
}

export default TravellerCostCodesAdapter;
