import "./style.less";
import sanitizeHtml from "sanitize-html";
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { NewsItem } from "../helpers/interfaces";
import moment from "moment";
import {
	fetchMarkRead,
	fetchNewsItemDetails,
} from "../NewsCard/helpers/services";
import { NewItemDetails } from "../NewsCard/helpers/interfaces";
import Button from "../../../components/form/Button/Button";
import { ReactAppGlobalContext } from "../../../../js/utils/react-utils";
import { fetchNoticesSummary } from "../../../../js/blocks/layout/b-header/react/helpers/services";
import { useNoticesStore } from "../../../store/notices";

interface ImportantNewsProps {
	news: NewsItem[];
	close: () => void;
}

const ImportantNews: React.FC<ImportantNewsProps> = (props) => {
	const { news = [], close } = props;

	const { L10N } = useContext(ReactAppGlobalContext);

	const { updateSummary } = useNoticesStore();

	const [details, setDetails] = useState<NewItemDetails>();

	const currentNewsItem = useMemo(() => news[0], [news]);

	const loadSummary = useCallback(async () => {
		const newSummary = await fetchNoticesSummary();
		updateSummary(newSummary);
		return newSummary;
	}, []);

	const fetchDetails = useCallback(async (uid: string) => {
		const newsItemDetails = await fetchNewsItemDetails(uid);
		setDetails(newsItemDetails);
	}, []);

	const handleReadClick = useCallback(async () => {
		if (currentNewsItem) {
			await fetchMarkRead(currentNewsItem.uid);
			const summary = await loadSummary();
			const { unreadImportantCount = 0 } = summary;
			if (unreadImportantCount === 0) {
				close && close();
			}
		}
	}, [currentNewsItem, news, close]);

	useEffect(() => {
		if (currentNewsItem) {
			fetchDetails(currentNewsItem.uid);
		}
	}, [currentNewsItem]);

	return (
		<div className="r-important-news">
			{currentNewsItem && (
				<>
					<div className="r-important-news-item__title">
						{currentNewsItem.title}
					</div>
					<div className="r-important-news-item__date">
						{(currentNewsItem.created &&
							moment(currentNewsItem.created).format("DD.MM.YYYY, HH:mm")) ||
							""}
					</div>
					<div
						className="r-important-news-item__text"
						dangerouslySetInnerHTML={{
							__html: sanitizeHtml(details?.content || ""),
						}}
					/>
					<Button style={{ alignSelf: "center" }} onClick={handleReadClick}>
						{L10N.get("news.importantNews.markAsRead")}
					</Button>
				</>
			)}
		</div>
	);
};

export default ImportantNews;
