module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var start = trip.startPlaceInfo;
    var end = trip.endPlaceInfo;
    var address = index === 1 ? location.end.address + ' - ' + location.start.address : location.start.address + ' - ' + location.end.address;
;
__p += '\n\n<div class="b-transfer__trip">\n    ';
 if (passengersAmountCollection != null && (!isRoundTrip || index === 0)) { ;
__p += '\n        <div class="b-transfer__trip-row b-transfer__passengers-amount">\n            <span class="js-widget"  onclick=\'return new Widgets.Select({ bindingProperty: "travellersCount", values: "' + passengersAmountCollection + '", label: L10N.get("transfers.passengersAmount") })\'></span>\n        </div>\n    ';
 } ;
__p += '\n    ';
 if (isRoundTrip) { ;
__p += '\n        <div class="b-transfer__trip-address">' +
((__t = ( address )) == null ? '' : __t) +
'</div>\n    ';
 } ;
__p += '\n    <div class="b-transfer__trip-container">\n        ';
 if (start && start.type.uid === 'RAILWAY_STATION') { ;
__p += '\n        <div class="b-transfer__trip-row">\n            <span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "start.trainNumber", label: L10N.get("transfers.trainNumber") })\'></span>\n        </div>\n        <div class="b-transfer__trip-row">\n            <span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "start.trainCarriageNumber", label: L10N.get("transfers.trainCarriageNumber") })\'></span>\n        </div>\n        <div class="b-transfer__trip-row">\n            <span class="js-widget"  onclick=\'return new Widgets.Datepicker({ bindingProperty: "start.date", label: L10N.get("transfers.arrivalDate"), classes: "b-transfer__date-time-element" })\'></span>\n        </div>\n        <div class="b-transfer__trip-row">\n            <span class="js-widget"  onclick=\'return new Widgets.Time({ bindingProperty: "start.time", label: L10N.get("transfers.arrivalTime"), classes: "b-transfer__date-time-element" })\'></span>\n        </div>\n        ';
 } else if (start && start.type.uid === "AIRPORT") { ;
__p += '\n        <div class="b-transfer__trip-row">\n            <span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "start.flightNumber", label: L10N.get("transfers.flightNumber") })\'></span>\n        </div>\n        ';
 if (!_.isEmpty(start.terminals)) { ;
__p += '\n        <div class="b-transfer__trip-row">\n            <span class="js-widget"  onclick=\'return new Widgets.Select({ bindingProperty: "start.terminalNumber", values: "' + start.terminals + '", label: L10N.get("transfers.terminal") })\'></span>\n        </div>\n        ';
 } ;
__p += '\n        <div class="b-transfer__trip-row">\n            <span class="js-widget"  onclick=\'return new Widgets.Datepicker({ bindingProperty: "start.date", label: L10N.get("transfers.arrivalDate"), classes: "b-transfer__date-time-element" })\'></span>\n        </div>\n        <div class="b-transfer__trip-row">\n            <span class="js-widget"  onclick=\'return new Widgets.Time({ bindingProperty: "start.time", label: L10N.get("transfers.arrivalTime"), classes: "b-transfer__date-time-element" })\'></span>\n        </div>\n        ';
 } else { ;
__p += '\n        <div class="b-transfer__trip-row">\n            <span class="js-widget"  onclick=\'return new Widgets.Datepicker({ bindingProperty: "start.date", label: L10N.get("transfers.tripDate"), classes: "b-transfer__date-time-element" })\'></span>\n        </div>\n        <div class="b-transfer__trip-row">\n            <span class="js-widget"  onclick=\'return new Widgets.Time({ bindingProperty: "start.time", label: L10N.get("transfers.tripTime"), classes: "b-transfer__date-time-element" })\'></span>\n        </div>\n        ';
 } ;
__p += '\n\n        ';
 if (end && end.type.uid === 'AIRPORT') { ;
__p += '\n            ';
 if (!_.isEmpty(end.terminals)) { ;
__p += '\n            <div class="b-transfer__trip-row">\n                <span class="js-widget"  onclick=\'return new Widgets.Select({ bindingProperty: "end.terminalNumber", values: "' + end.terminals + '", label: L10N.get("transfers.terminal") })\'></span>\n            </div>\n            ';
 } ;
__p += '\n        <div class="b-transfer__trip-row">\n            <span class="js-widget"  onclick=\'return new Widgets.Datepicker({ bindingProperty: "end.date", label: L10N.get("transfers.departureDate"), classes: "b-transfer__date-time-element" })\'></span>\n        </div>\n        <div class="b-transfer__trip-row">\n            <span class="js-widget"  onclick=\'return new Widgets.Time({ bindingProperty: "end.time", label: L10N.get("transfers.departureTime"), classes: "b-transfer__date-time-element" })\'></span>\n        </div>\n        ';
 } else if (end && end.type.uid === 'RAILWAY_STATION') { ;
__p += '\n        <div class="b-transfer__trip-row">\n            <span class="js-widget"  onclick=\'return new Widgets.Datepicker({ bindingProperty: "end.date", label: L10N.get("transfers.departureDate"), classes: "b-transfer__date-time-element" })\'></span>\n        </div>\n        <div class="b-transfer__trip-row">\n            <span class="js-widget"  onclick=\'return new Widgets.Time({ bindingProperty: "end.time", label: L10N.get("transfers.departureTime"), classes: "b-transfer__date-time-element" })\'></span>\n        </div>\n        ';
 } ;
__p += '\n    </div>\n</div>\n';

}
return __p
}