// eslint-disable-next-line banned-modules
'use strict';

import FilterCollection from '@/blocks/elements/b-filters/b-filters-avia/collection';

import RangeModel from '@/blocks/elements/b-filters/b-filters-trains/range/model';
import SelectModel from '@/blocks/elements/b-filters/b-filters-trains/select/model';

const timeFormatter = (value) => {
	const minutes = Math.round(value % 60);
	return `${Math.floor(value / 60)}:${minutes > 9 ? minutes : `0${minutes}`}`;
};

export default FilterCollection.extend({

	snapshotName: 'trains:filter',

	initialize(models, options) {
		this.legIdx = options.routeId != null ? options.routeId - 1 : 0;
		this.tickets = options.tickets;
		this.currency = options.currencyCode || 'RUB';
		this.sourceCarriages = {};
		this.tickets.forEach(t => {
			this.sourceCarriages[t.cid] = _.clone(t.get('carriageInfos'));
		});

		if (this.tickets.length === 0) return;

		this.add(new RangeModel({
			label: L10N.get('filters.price'),
			field: '_train.prices',
			formatter: (value) => {
				return Math.round(value);
			},
			type: 'PRICE',
		}));

		this.add(new SelectModel({
			label: L10N.get('filters.carriageType'),
			field: '_train.types',
			type: 'CARRIAGE_TYPE',
		}));

		this.add(new RangeModel({
			label: L10N.get('filters.travelDuration'),
			field: '_train.totalDurationInMinutes',
			formatter: timeFormatter,
			type: 'TRAVEL_DURATION',
			leg: this.legIdx,
			trainLegs: true,
		}));

		this.add(new RangeModel({
			label: L10N.get('filters.departureTime'),
			field: '_train.departureDate',
			formatter: timeFormatter,
			type: 'DEPARTURE_DATE',
			leg: this.legIdx,
			trainLegs: true,
		}));

		this.add(new RangeModel({
			label: L10N.get('filters.arrivalTime'),
			field: '_train.arrivalDate',
			formatter: timeFormatter,
			type: 'ARRIVAL_DATE',
			leg: this.legIdx,
			trainLegs: true,
		}));

		this.add(new SelectModel({
			label: L10N.get('filters.departureLocation'),
			field: '_train.departureLocations',
			type: 'DEPARTURE_LOCATIONS',
		}));

		this.add(new SelectModel({
			label: L10N.get('filters.arrivalLocation'),
			field: '_train.arrivalLocations',
			type: 'ARRIVAL_LOCATIONS',
		}));

		this.add(new SelectModel({
			label: L10N.get('filters.services'),
			field: 'services',
			type: 'SERVICES',
		}));
		this.add(new SelectModel({
			label: L10N.get('filters.brand'),
			field: '_brand',
			type: 'BRAND',
		}));
		this.add(new SelectModel({
			label: L10N.get('filters.categoryTrain'),
			field: '_category',
			type: 'CATEGORY_LOCALIZED',
		}));

		const travelPolicyComplianceFilter = new SelectModel({
			label: L10N.get('filters.travelPolicy'),
			field: `_train.travelPolicyCompliance`,
		});
		travelPolicyComplianceFilter.formatter = (values) => {
			values.models = _.sortBy(values.models, (el) => {
				let value = 0;
				switch (el.get('uid')) {
					case 'RECOMMENDED':
						value = -1;
						break;
					case 'VIOLATION':
						value = 1;
						break;
					case 'STRICT_VIOLATION':
						value = 2;
						break;
					case 'AUTHORIZATION_REQUIRED':
						value = 3;
						break;
				}
				return value;
			});
			return values;
		};

		this.add(travelPolicyComplianceFilter);

		this.collect();
		this.bind();
	},

	filterTickets() {
		this.collectSnapshot();
		this.tickets.forEach(ticket => {
			ticket.set('carriageInfos', _.clone(this.sourceCarriages[ticket.cid]));
			let carriageInfos = ticket.get('carriageInfos');
			this.models.forEach(filter => {
				switch (filter.get('type')) {
					case 'PRICE': {
						const min = filter.get('value')[0];
						const max = filter.get('value')[1];
						carriageInfos = _.filter(ticket.get('carriageInfos'), c => {
							const price = parseFloat(c.price);
							return price >= min && price <= max;
						});
						ticket.set('carriageInfos', carriageInfos);
						break;
					}
					case 'CARRIAGE_TYPE': {
						const selected = filter.get('values').where({value: true}).map(s => s.get('uid'));
						if (selected.length > 0) {
							carriageInfos = _.filter(ticket.get('carriageInfos'), c => {
								return selected.findIndex(s => s === c.type.uid) > -1;
							});
							ticket.set('carriageInfos', carriageInfos);
						}
						break;
					}
					default:
						if (!filter.filter(ticket)) {
							ticket.set('carriageInfos', []);
						}
						break;
				}
			});
			if (ticket.get('carriageInfos').length === 0) {
				ticket.set('visibility', false);
			} else {
				if (ticket.get('_train').carriageInfosLength !== ticket.get('carriageInfos').length) {
					ticket.trigger('change:renderCarriages');
				}
				ticket.set('visibility', true);
			}
		});
	},

});
