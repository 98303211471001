import styled from "styled-components";

export const Container = styled.div`
	padding: 24px;
	border-radius: 9px;
	background-color: #f3f3f3;
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

export const Header = styled.div`
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-size: 14px;
	font-weight: 500;
`;

export const HeaderNumber = styled.div`
	color: #a4abb3;
`;
export const HeaderCollapse = styled.div`
	color: #4882bd;
	text-decoration: none;
	user-select: none;
`;
