// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import PersonView from '@/blocks/pages/b-cabinet/b-cabinet-person';
import CabinetCollectionView from '@/blocks/pages/b-cabinet/b-cabinet-collection';
import template from './template.ejs';
import Model from './model';

import axios from 'axios';

export default BaseView.extend({

	template,

	ui: {
		container: '.b-cabinet-holder__content',
		organizations: '.b-cabinet-holder__organizations',
		person: '.b-cabinet-holder__person',
	},

	events: {
		'click .b-cabinet-holder__save': 'save',
	},

	initialize() {
		const { profileInfo } = this.options;
		this.model = !_.isEmpty(profileInfo) ? new Model(profileInfo.holder, {parse: true}) : new Model();
		this.model.set('siteType', STATE.getSiteTypeSettings().siteType);
		this.render();
	},

	render() {
		this.setElement(template(this.model.attributes));
		const person = new PersonView({
			model: this.model,
			personType: 'profile',
			advancedPersonForm: true,
			useProfileCostCodes: true,
			siteType: this.model.get('siteType'),
		});

		this.ui.person.html(person.$el);
		if (!STATE.checkSiteType('B2C')) {
			this.organizationView = new CabinetCollectionView({
				collection: this.model.get('organizationInfos'),
				title: L10N.get('cabinet.contacts.documentsTitle'),
				container: this.ui.organizations,
			});

			this.$('.b-person-organization__categories-wrapper').hide();
		}
	},

	save(e) {
		const holder = this.model.toJSON();

		STATE.showLoader();
		this.disableElements(e);
		axios.post('/midoffice/ibecorp-b2b/cabinet/updateCabinetHolder', {
			parameters: {
				holder,
			},
		}, this.model).then(() => {
			STATE.hideLoader();
		}).catch((ex) => {
			logger.debug(e);
			if (ex && ex.error === 'validationError') {
				const popup = new Widgets.Popup({
					type: 'danger',
					content: `
						<div>
							${L10N.get('cabinet.successfulSavedPopup.failed')}
						</div>
					`,
					actions: [{
						label: L10N.get('cabinet.orders.close'),
						action: () => {
							popup.hide();
						},
					}],
				});
				popup.show();
			}
		});
	},

});
