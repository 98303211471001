// eslint-disable-next-line banned-modules
'use strict';

import BasePageView from '@/pages/base.page-view';
import CabinetMenu from '@/blocks/pages/b-cabinet/b-cabinet-menu/index';
import SearchForm from '@/blocks/pages/b-search-form/index';

export default BasePageView.extend({

	initialize(options) {
		this.options = options;

		this.model = STATE.getSearchModel();
		STATE.clearLocalStorageForRoute([
			STATE.ROUTES.AVIA_PASSENGERS,
			STATE.ROUTES.AVIA_TICKETS,
			STATE.ROUTES.AVIA_PAYMENT,
			STATE.ROUTES.AVIA_BRANDED_FARES,
			STATE.ROUTES.HOTELS_OFFERS,
			STATE.ROUTES.HOTELS_PASSENGERS,
			STATE.ROUTES.HOTELS_PREVIEW,
			STATE.ROUTES.HOTELS_PAYMENT,
			STATE.ROUTES.RAILWAYS_TICKETS,
			STATE.ROUTES.RAILWAYS_PASSENGERS,
			STATE.ROUTES.RAILWAYS_TICKETS_PRICING,
			STATE.ROUTES.RAILWAYS_TICKETS_1,
			STATE.ROUTES.RAILWAYS_TICKETS_2,
			STATE.ROUTES.RAILWAYS_PAYMENT,
			STATE.ROUTES.RAILWAYS_PREVIEW,
			STATE.ROUTES.TRANSFERS_OFFERS,
			STATE.ROUTES.TRANSFERS_PASSENGERS,
			STATE.ROUTES.TRANSFERS_PAYMENT,
			'formState:transfers:filter',
			'formState:trains:filter',
			'formState:hotels:filter',
			'formState:avia:filter',
			'SORTING_VIEW_OPTION',
		]);
		this.render();
	},

	render(...args) {
		BasePageView.prototype.render.apply(this, args);

		this.searchForm = new SearchForm({
			model: this.model,
			params: this.options,
		});

		this.ui.content.append(this.searchForm.$el);
		this.ui.sidebar.append(new CabinetMenu().$el);

		this.searchForm.drawAttachments();
	},

}, {
	load(params = {}) {
		return Promise.resolve(params);
	},
});
