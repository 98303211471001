// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import BaseModel from '@/classes/base.model';
import template from './template.ejs';

import EmailView from './b-email/index';

export default BaseView.extend({

	template,

	events: {
		'click .b-emails-collection__add': 'addNewItem',
	},

	initialize() {
		if (this.collection.length === 0) {
			this.collection.reset();
			this.addNewItem();
		}

		if (this.options.model) {
			this.model = this.options.model;
			this.model.handleValidation = this.handleValidation.bind(this.model);
		}

		this.render();
		this.listenTo(this.collection, 'add remove reset', () => {
			this.render();
		});
	},

	addNewItem() {
		this.collection.add(new this.collection.model(null));
	},

	render() {
		this.setElement(template(this.options));
		const container = this.$('.b-emails-collection__content');
		this.collection.forEach((item) => {
			const emailView = new EmailView({
				model: item,
				autoSuggestEmails: this.options.autoSuggestEmails,
				container,
				bindingProperty: `value`,
				collection: this.collection,
			});
			container.before(emailView);
		});
		this.options.container.empty().append(this.$el);

		if (this.collection.length === 1) {
			this.$el.find('.b-input__remove').hide();
		}
	},

	handleValidation(...args) {
		if (args != null && !_.isEmpty(args[0]) && !_.isEmpty(args[0].emails) && this.get('emails') != null) {
			_.each(_.keys(args[0].emails), (key) => {
				const index = Number(key);
				this.get('emails').at(index).triggerValidation(`value`, args[0].emails[key]);
			});
			delete args[0].emails;
		}

		BaseModel.prototype.handleValidation.apply(this, args);
	},

});
