module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<section class="b-train__options">\n	<header class="b-train__options-header">' +
((__t = ( L10N.get('trains.additionalOptions') )) == null ? '' : __t) +
'</header>\n	<div class="l-grid-container b-train-options-container"></div>\n</section>';

}
return __p
}