// eslint-disable-next-line banned-modules
'use strict';

import BaseView from '@/classes/base.view';
import SelectWidget from '@/widgets/b-select';
import template from './template.ejs';

export default BaseView.extend({

	template,

	companies: null,

	events: {
		'click .js-login': 'login',
	},

	ui: {
		salesPointsContainer: '.js-salesPointsContainer',
	},

	initialize() {
		this.parent = this.options.parent;
		this.model = this.options.model;
		this.companies = this.options.employers;
		this.personTypes = this.options.personTypes;
		this.salesPoints = this.options.salesPoints;

		this.listenTo(this.model, 'change:selectedPreferences.employer', (model, value) => {
			this.renderEmployerSalesPointWidget(value);
		});
		this.render();
	},

	renderEmployerSalesPointWidget(employer) {
		if (_.isEmpty(this.salesPoints)) return;
		const salesPoints = employer ? this.salesPoints[employer.uid] : this.salesPoints[_.keys(this.salesPoints)[0]];
		this.model.unset('selectedPreferences.defaultSalesPoint');
		if (!salesPoints) {
			if (this.ui.salesPointsContainer) this.ui.salesPointsContainer.empty();
			return;
		}
		if (!_.isEmpty(salesPoints.defaultSalesPoint)) {
			salesPoints.salesPoints = [...salesPoints.salesPoints, salesPoints.defaultSalesPoint];
			this.model.set('selectedPreferences.defaultSalesPoint', salesPoints.defaultSalesPoint);
		}
		salesPoints.salesPoints = [{uid: 'NOT_SELECTED', caption: L10N.get('AccountLoginForm.company.salesPoints__notSelected')}, ...salesPoints.salesPoints];
		const widget = new SelectWidget({
			bindingProperty: 'selectedPreferences.defaultSalesPoint',
			values: SelectWidget.storeCollection(SelectWidget.dictionaryToCollection(salesPoints.salesPoints)),
			label: L10N.get('AccountLoginForm.company.salesPoint__label'),
			placeholder: L10N.get('AccountLoginForm.company.salesPoints__placeholder'),
			noValidationClass: 'ignore-validation',
		});
		widget.applyBinding(this.model);
		this.ui.salesPointsContainer.empty().html(widget.$el);
		this.listenTo(this.model, 'change:selectedPreferences.defaultSalesPoint', () => {
			this.parent.$el.find('.js-login').prop('disabled', false);
		});
		if (this.model.get('selectedPreferences').defaultSalesPoint) {
			this.parent.$el.find('.js-login').prop('disabled', false);
		}
	},

	render() {
		const container = this.$el;
		const countriesCollection = SelectWidget.dictionaryToCollection(this.companies);
		const personTypesCollection = SelectWidget.dictionaryToCollection(this.personTypes);
		this.setElement(this.template(
			Object.assign(
				{},
				this.model.attributes,
				{
					employer: SelectWidget.storeCollection(countriesCollection),
					personTypes: SelectWidget.storeCollection(personTypesCollection),
				},
			),
		));
		if (!_.isEmpty(this.salesPoints) && _.isEmpty(this.companies)) {
			this.renderEmployerSalesPointWidget();
		}
		container.html(this.$el);
		this.$el.find('.p-login__form-name-input').prop('disabled', true);
	},
});
