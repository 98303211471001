// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';
import suggestionTemplate from './suggestion.ejs';
import axios from 'axios';

export default Widget.extend({

	validationTemplate,

	template,

	viewName: 'b-geocoder-autocomplete',

	initialize(options) {
		const {generalSettings = {}} = STATE.getSettings() || {};
		this.options = options;
		this.options.label = this.options.label || '';
		this.options.placeholder = this.options.placeholder || '';
		this.options.deletableField = this.options.deletableField || false;
		this.apiUrl = `https://geocode-maps.yandex.ru/1.x/`;
		this.apikey = generalSettings.geocoderApiToken && generalSettings.geocoderApiToken.token;
		this.geocoderType = generalSettings.geocoderApiToken && generalSettings.geocoderApiToken.type || {};
		if (this.geocoderType.uid === 'IWAY') {
			this.queryHandler = this.iwayQueryHandler.bind(this);
		} else {
			this.queryHandler = this.queryHandler.bind(this);
		}
		this.render();

		this.$typeahead = this.$('.b-geocoder-autocomplete__value');
		this.$typeahead.typeahead({
			hint: false,
			highlight: false,
			minLength: 0,
			limit: 1,
			classNames: {
				menu: 'b-geocoder-autocomplete__items',
				wrapper: 'b-geocoder-autocomplete__wrapper',
				input: 'b-geocoder-autocomplete__input',
				hint: 'b-geocoder-autocomplete__hint',
				dataset: 'b-geocoder-autocomplete__dataset',
				suggestion: 'b-geocoder-autocomplete__item',
				selectable: 'b-geocoder-autocomplete__selectable',
				empty: 'b-geocoder-autocomplete__empty',
				open: 'b-geocoder-autocomplete__open',
				cursor: 'b-geocoder-autocomplete__cursor',
				highlight: 'b-geocoder-autocomplete__highlight',
			},
		}, {
			limit: 100,
			name: 'geocoder',
			source: (q, cb, async) => {
				clearTimeout(this.timer);
				this.timer = setTimeout(this.queryHandler, STATE.QUERY_DELAY, q, cb, async);
			},
			display: (item) => `${item.address}`,
			async: true,
			templates: {
				suggestion: suggestionTemplate,
			},
		});
	},

	queryHandler(q, cb, async) {
		if ((!q || q === ' ' || q === '') || (q && q.length < 4)) return;
		if (this.model.get(this.options.bindingProperty) && this.model.get(this.options.bindingProperty).address === q) return;
		if (!this.apikey) return;
		axios.get(this.apiUrl, {
			params: {
				apikey: this.apikey,
				geocode: this.encode(q),
				format: 'json',
			},
		}, {mode: 'cors'}).then((response) => {
			const data = this.apiCallback(response);
			if (data) {
				async(_.reduce(data, (result, item) => {
					result.push(item);
					return result;
				}, []));
			}
		});
	},

	iwayQueryHandler(q, cb, async) {
		if ((!q || q === ' ' || q === '') || (q && q.length < 4)) return;
		if (this.model.get(this.options.bindingProperty) && this.model.get(this.options.bindingProperty).address === q) return;
		axios.get('/midoffice/ibecorp-b2b/geocoder/geocode', {
			params: {
				geocode: this.encode(q),
			},
		}).then((response) => {
			const data = this.iwayApiCallback(response);
			if (data) {
				async(_.reduce(data, (result, item) => {
					result.push(item);
					return result;
				}, []));
			}
		});
	},

	apiCallback(response) {
		if (response && response.data && response.data.response) {
			const {GeoObjectCollection} = response.data.response;
			const result = _.map(GeoObjectCollection.featureMember, (place) => {
				const res = {};
				const latLong = place.GeoObject.Point.pos.split(' ');
				res.longitude = latLong[0];
				res.latitude = latLong[1];
				res.address = place.GeoObject.metaDataProperty.GeocoderMetaData.text;
				return res;
			});
			return result;
		}
		return null;
	},

	iwayApiCallback(response) {
		const {result} = response.data;
		return result.map(item => ({
			...item,
			address: item.name,
		}));
	},

	encode(str) {
		str = str.toString();

		return str.replace(' ', '+')
			.replace('&', '%26')
			.replace('?', '%3F');
	},

	applyBinding(model) {
		this.model = model;

		this.$typeahead.on('typeahead:change', (ev, suggestion) => {
			if (suggestion.replace(/\s/g, '').length === 0) {
				this.model.set(this.options.bindingProperty, null);
			}
		});

		this.$typeahead.on('typeahead:select', (ev, suggestion) => {
			this.model.set(this.options.bindingProperty, suggestion);
		});

		this.listenTo(model, `change:${this.options.bindingProperty}`, (__model, item) => {
			this.$typeahead.typeahead('val', (item || {}).address);
		});

		this.listenTo(model, `validationError:${this.options.bindingProperty}`, () => {
			this.$el.addClass('validation-error');
		});

		if (_.isObject(model.get(this.options.bindingProperty))) {
			this.$typeahead.typeahead('val', model.get(this.options.bindingProperty).address);
		}
		this.$('.b-input__remove').on('click', () => this.removeItem(model));
	},

	removeItem(model) {
		model.set(this.options.bindingProperty, null);
	},
});
