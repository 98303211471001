module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	var providerList = model.get('providerList');
	var provider = model.get('provider');
	var minPrice = !_.isEmpty(offers) ? offers.slice(0, 1) : [{
	price: model.get('minPriceObject'),
	provider: provider,
	providerList: providerList,
	dailyAveragePrice: model.get('offerDailyAveragePrice'),
	taxInfoList: model.get('mintaxInfoList'),
}];
	var showGdsAccountName = showGdsAccountName || false;
;
__p += '\n';
 _.each(minPrice, function (offer, i) {
	var price = offer != null && offer.price != null ? parseFloat(offer.price.total.oldAmount || offer.price.total.amount) : null;
	
	if (price != null && !isNaN(price) && price !== 0) { ;
__p += '\n		<div class="b-hotel__providers-item b-hotel__provider ' +
((__t = ( i === 0 ? 'b-hotel__provider--best-price' : '' )) == null ? '' : __t) +
'" data-offer-id="' +
((__t = ( offer.__index )) == null ? '' : __t) +
'" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="' +
((__t = ( _.escape(require('./b-offer-pricing.ejs')({ price: offer.price, taxInfoList: offer.taxInfoList, offer: offer, showOldAmount: true })) )) == null ? '' : __t) +
'">\n			<div class="b-hotel__provider-wrapper">\n				';

					if (showGdsAccountName) {
						var providersListToShow = parentView.filterHotelOfferProviderList(offer);
				;
__p += '\n					<div class="b-hotel__provider-name">\n						' +
((__t = ( providersListToShow.length ? providersListToShow[0] : offer.provider.caption )) == null ? '' : __t) +
'\n						';

							if (providersListToShow.length > 1) {
								var restProvidersList = providersListToShow.slice(1);
						;
__p += '\n							<span class="b-hotel__hotel-offer-provider-list"\n										data-original-title="<div>' +
((__t = ( _.map(restProvidersList, function(p) { return p || ''; }).join(', ') )) == null ? '' : __t) +
'</div>"\n										data-position="bottom"\n										data-toggle="tooltip"\n										data-html="true"\n							>+' +
((__t = ( restProvidersList.length )) == null ? '' : __t) +
'\n							</span>\n						';
 } ;
__p += '\n					</div>\n				';
 } ;
__p += '\n\n				<div class="b-hotel__provider-price-wrapper">\n					<div class="b-hotel__provider-price">\n						<span class="b-hotel__provider-price-amount">\n							' +
((__t = ( Formatter.formatMoney(offer.price.total.oldAmount || offer.price.total.amount, Formatter.getDecimal(offer.price.total.oldAmount || offer.price.total.amount)) )) == null ? '' : __t) +
'\n						</span>\n						' +
((__t = ( Formatter.formatCurrency(offer.price.total.currencyCode) )) == null ? '' : __t) +
'\n					</div>\n						';
 if (offer.dailyAveragePrice != null) { ;
__p += '\n							<div class="b-hotel__provider-daily-average-price">\n								' +
((__t = ( L10N.get('filters.pricePerDay') )) == null ? '' : __t) +
':\n								<span class="b-hotel__provider-daily-average-price-amount">\n									' +
((__t = ( Formatter.formatMoney(offer.dailyAveragePrice.amount, Formatter.getDecimal(offer.dailyAveragePrice.amount)) )) == null ? '' : __t) +
'\n									' +
((__t = ( Formatter.formatCurrency(offer.dailyAveragePrice.currency) )) == null ? '' : __t) +
'\n								</span>\n							</div>\n					';
 } ;
__p += '\n					<div class="b-hotel__provider-taxation ' +
((__t = ( (!_.isEmpty(offer.taxInfoList)) ? 'extra-taxs' : '' )) == null ? '' : __t) +
'">\n						';
 if (!_.isEmpty(offer.taxInfoList)) {
							var sum = _.reduce(offer.taxInfoList, function(acc, tax) {
								return +tax.amount.amount + acc;
							}, 0);
							var currencyCode = offer.taxInfoList[0].amount.currencyCode;
						;
__p += '\n							' +
((__t = ( offer.taxInfoList.length === 1 ? L10N.get('hotels.taxInfoList_1') : L10N.get('hotels.taxInfoList_2') )) == null ? '' : __t) +
' -\n							' +
((__t = ( Formatter.formatMoney(sum, 0) )) == null ? '' : __t) +
'\n							' +
((__t = ( Formatter.formatCurrency(currencyCode) )) == null ? '' : __t) +
'\n						';
 } else { ;
__p += '\n							';
 var vat = parseInt(offer.price.vat, 10); ;
__p += '\n							' +
((__t = ( ((!vat && vat !== 0) || _.isNaN(vat)) ? L10N.get('hotels.withOutVat') :  L10N.get('hotels.vatBraces') + ' ' + vat + '%)' )) == null ? '' : __t) +
'\n						';
 } ;
__p += '\n					</div>\n					<div class="b-hotel__provider-arrow"> </div>\n				</div>\n			</div>\n		</div>\n	';
 } else { ;
__p += '\n			<div class="b-hotel__providers-item b-hotel__provider ' +
((__t = ( i === 0 ? 'b-hotel__provider--best-price' : '' )) == null ? '' : __t) +
'" data-offer-id="' +
((__t = ( offer.id )) == null ? '' : __t) +
'" data-offer-index="' +
((__t = ( offer.__index )) == null ? '' : __t) +
'">\n				<div class="b-hotel__provider-wrapper">\n					';
 if (showGdsAccountName) { ;
__p += '\n						<div class="b-hotel__provider-name">\n							' +
((__t = ( (offer.provider != null) ? offer.provider.caption : '' )) == null ? '' : __t) +
'\n						</div>\n					';
 } ;
__p += '\n				</div>\n				<div class="b-hotel__unknown-price">\n					' +
((__t = ( L10N.get('hotels.unknownPrice') )) == null ? '' : __t) +
'\n				</div>\n		</div>\n	';
 } ;
__p += '\n';
 }) ;


}
return __p
}