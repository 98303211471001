module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 _.each(attachments, function(attachment) { ;
__p += '\n    <div class="b-order-issue__post-attachment-container">\n        <i class="g-icon-download"></i>\n        <span class="b-order-issue__post-attachment">\n            <span\n                class="action-link b-order-issue-attachment-download"\n                data-attachment-uid="' +
((__t = ( attachment.uid )) == null ? '' : __t) +
'">\n                ' +
((__t = ( attachment.name )) == null ? '' : __t) +
'\n            </span>\n            ';
 if (attachment.size) { ;
__p += '\n                <em class="b-order-issue__post-attachment-size">\n                    (' +
((__t = ( attachment.size )) == null ? '' : __t) +
')\n                </em>\n            ';
 } ;
__p += '\n        </span>\n    </div>\n';
 }) ;


}
return __p
}