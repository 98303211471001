// eslint-disable-next-line banned-modules
'use strict';

import Backbone from 'backbone';
import axios from 'axios';
import SearchView from './pages/search/view';
import BreadcrumbsView from './blocks/layout/b-breadcrumbs';
import ScheduleView from './pages/schedule/view';
import ContractsView from './pages/contracts/view';

import AviaResultView from './pages/results/avia/view';
import AviaPassengersDataView from './pages/passengers-data/avia/view';

import TrainsResultView from './pages/results/trains/view';
import TrainsPassengersDataView from './pages/passengers-data/trains/view';
import TrainPassengersPreview from './pages/passengers-data/trains-preview/view';
import HotelPreview from './pages/passengers-data/hotels-preview/view';

import HotelsResultView from './pages/results/hotels/view';
import HotelsPassengersDataView from './pages/passengers-data/hotels/view';

import TransfersResultView from './pages/results/transfer/view';
import TransfersPassengerDataView from './pages/passengers-data/transfers/view';

import CabinetOrders from './pages/cabinet/cabinet-orders/view';
import CabinetOrder from './pages/cabinet/cabinet-orders/cabinet-order/view';
import CabinetProducts from './pages/cabinet/cabinet-products/view';
import CabinetPassengers from './pages/cabinet/cabinet-passengers/view';
import CabinetPassenger from './pages/cabinet/cabinet-passengers/cabinet-passenger/view';
import CabinetEmployees from './pages/cabinet/cabinet-employees/view';
import CabinetEmployee from './pages/cabinet/cabinet-employees/cabinet-employee/view';
import CabinetProfile from './pages/cabinet/cabinet-profile/view';
import CabinetFinance from './pages/cabinet/cabinet-finance/view';
import NewsPage from './pages/news/view';

import $ from 'jquery';
import STATE from './classes/application-state';
import { ReactUtils } from './utils/react-utils';

export default Backbone.Router.extend({

	routes: {

		// CABINET
		'cabinet/order/:uid': 'cabinetOrder',
		payment: 'PAYMENT_payment',

		// AVIA
		'avia/tickets': 'aviaTickets',
		'avia/tickets/:searchString': 'aviaTickets',
		'avia/passengers': 'aviaPassengersData',
		// TRAINS
		'trains/tickets/route/:id': 'trainTickets',
		'trains/tickets/route/:id/:searchString': 'trainTickets',
		'trains/passengers': 'trainPassengers',
		'trains/preview': 'trainPreview',

		// HOTELS
		'hotels/offers': 'hotelsOffers',
		'hotels/offers/:searchString': 'hotelsOffers',
		'hotels/travellers': 'hotelsTravellers',
		'hotels/preview': 'hotelPreview',
		// TRANSFERS
		'transfers/offers': 'transfersOffers',
		'transfers/offers/:searchString': 'transfersOffers',
		'transfers/travellers': 'transferTravellers',

		// OTHERS
		'authorization/recovery/:token': 'recovery',

		// NEWS
		news : 'newsPage',
	},

	// CONFIGURABLE ROUTES
	configurableRoutes: {
		schedule: 'SCHEDULE_schedule',
		contracts: 'CONTRACTS_AND_FEES_contracts',
		'': 'search',
		'/': 'search',
		// CABINET
		'cabinet/orders': 'ORDERS_cabinetOrders',
		'cabinet/products': 'SALES_cabinetProducts',
		'cabinet/passengers': 'PASSENGERS_cabinetPassengers',
		'cabinet/passenger(/)(:uid)': 'PASSENGERS_cabinetPassenger',
		'cabinet/employees': 'EMPLOYEES_cabinetEmployees',
		'cabinet/employee(/)(:uid)': 'EMPLOYEES_cabinetEmployee',
		'cabinet/profile': 'USER_DATA_cabinetProfile',
		'cabinet/finance': 'FINANCES_cabinetFinance',
	},

	preinitialize() {
		_.each(_.keys(this.configurableRoutes), (k) => {
			const {cabinetMenuSettings = {}} = STATE.getSettings() || {};
			const searchSection = _.find(cabinetMenuSettings.allowedSections, (el) => el.uid === 'SEARCH');

			if (k === '' || k === '/') {
				if (!searchSection) {
					this.routes[k] = this.configurableRoutes['cabinet/profile'];
				} else {
					this.routes[k] = this.configurableRoutes[k];
				}
			}

			_.each(cabinetMenuSettings.allowedSections, (el) => {
				if (this.configurableRoutes[k].includes(el.uid)) {
					this.routes[k] = this.configurableRoutes[k];
				}
			});
		});
	},

	initialize() {
		this.breadcrumbs = new BreadcrumbsView({
			router: this,
		});
		this.breadcrumbs.$el.hide();

		STATE.navigate = (route) => {
			STATE.setPreviousLocation(Backbone.history.location);
			STATE.setBookingInProcess(false);
			const classes = [
				'show-menu',
				'show-filters',
				'show-search-form',
				'show-pikaday-modal',
				'show-offer-preview',
				'show-hotel-offer-preview',
			];
			_.each(classes, el => $('.l-layout').removeClass(el));
			return this.navigate.call(this, route, { trigger: true });
		};

		this.on('route', (route) => {
			Backbone.$('body').attr('data-route-id', route);
			Backbone.$('[role="tooltip"].show').removeClass('show');
		});

		STATE.router = this;
		const originalCheckUrl = Backbone.history.checkUrl;
		// eslint-disable-next-line consistent-return
		Backbone.history.checkUrl = (e) => {
			if (!STATE.shouldNavigate()) {
				// prevent go to prev page when modal opened
				const oldUrl = e.oldURL;
				const currentRoute = new URL(oldUrl).hash;
				STATE.navigate(currentRoute);
				history.pushState({}, document.title, currentRoute);
				return false;
			}
			if (STATE.shouldNavigateToIndex(e)) {
				STATE.navigate(`#${STATE.ROUTES.INDEX}/`);
				return false;
			}

			if (!STATE.getBookingInProcess() && !STATE.getIsTablePage()) {
				return originalCheckUrl.call(this, e);
			}

			history.pushState({}, document.title, `#${Backbone.history.fragment}`);
			return false;
		};
		Backbone.history.start();
	},

	current(route) {
		if (route && Backbone.History.started) {
			const Router = this;
			const fragment = Backbone.history.fragment;
			const routes = _.pairs(Router.routes);

			const matched = _.find(routes, (handler) => {
				let _route = handler[0];

				_route = _.isRegExp(_route) ? _route : Router._routeToRegExp(_route);
				return _route.test(fragment);
			});

			return matched ? matched[1] : false;
		}

		return Backbone.history.fragment || Backbone.history.getHash();
	},

	reloadPage() {
		Backbone.history.loadUrl(Backbone.history.fragment);
	},

	destroy() {
		Backbone.history.stop();
		this.off();
		delete STATE.router;
	},

	loadPage(PageView, params) {
		STATE.hideAdditionalOrder();
		STATE.hideMenu();
		STATE.showLoader();

		if (STATE.getPageView()) {
			STATE.getPageView().remove();
		}

		return PageView.load(params).then(result => {
			STATE.hideLoader();

			// cleanup react roots on route change
			ReactUtils.unmountRemovedRoots();

			return (() => {
				const view = new PageView(result);
				if (STATE.checkSiteType('B2C') && !STATE.getSettings().generalSettings.personalAccountEnabled) {
					const $contentSide = $(document).find('.l-layout__content-side');
					if (PageView === SearchView) {
						$contentSide.addClass('hide-personal-cabinet');
					} else {
						$contentSide.removeClass('hide-personal-cabinet');
					}
				}
				return view;
			})();
		}).catch((err) => {
			STATE.hideLoader();
			logger.error(`Error load page:`, err, PageView);
			throw err;
		});
	},

	recovery(token) {
		if (STORE.get('recoveryToken') === token) {
			STORE.remove('recoveryToken');
			window.location.href = STATE.getRootUrl();
		}
		if (token) {
			axios.post('/midoffice/ibecorp-b2b/authorization/recovery', {
				parameters: {
					recoveryToken: token,
				},
			}).then(() => {
				STATE.loginView.$el.css('display', 'flex');
				STATE.loginView.showRestoreSuccess();
				STORE.set('recoveryToken', token);
			});
		} else {
			this.search();
		}
	},

	search(params) {
		this.loadPage(SearchView, params).then(() => {
			this.breadcrumbs.$el.hide();
		});
	},

	SCHEDULE_schedule() {
		this.loadPage(ScheduleView);
	},

	CONTRACTS_AND_FEES_contracts() {
		this.loadPage(ContractsView);
	},

	// AVIA
	aviaTickets(hash) {
		const load = (fromHash = false) => {
			this.loadPage(AviaResultView, {fromHash}).then(() => {
				this.breadcrumbs.setRouteType('AVIA');
				this.breadcrumbs.$el.show();
			});
		};
		if (hash) {
			STATE.removeHeader();
			AviaResultView.loadFromHash(hash).then(() => {
				load(true);
			});
		} else {
			load();
		}
	},

	aviaPassengersData() {
		this.loadPage(AviaPassengersDataView).then(() => {
			this.breadcrumbs.setRouteType('AVIA');
			this.breadcrumbs.$el.show();
		});
	},

	// TRAINS
	trainTickets(routeId, hash) {
		const load = (fromHash = false) => {
			this.loadPage(TrainsResultView, {
				routeId,
				fromHash,
			}).then(view => {
				this.breadcrumbs.$el.show();
				this.breadcrumbs.setRouteType(view.isRoundTrip ? 'RAILWAYS_ROUNDTRIP' : 'RAILWAYS_ONEWAY');
			});
		};
		if (hash) {
			STATE.removeHeader();
			TrainsResultView.loadFromHash(hash).then(() => {
				load(true);
			});
		} else {
			load();
		}
	},

	trainPassengers() {
		this.loadPage(TrainsPassengersDataView).then(() => {
			const pricingParameters = STORE.get(STATE.ROUTES.RAILWAYS_PASSENGERS);
			const isRoundTrip = pricingParameters && pricingParameters.routeInfoBack != null;

			this.breadcrumbs.$el.show();
			this.breadcrumbs.setRouteType(isRoundTrip ? 'RAILWAYS_ROUNDTRIP' : 'RAILWAYS_ONEWAY');
		});
	},

	trainPreview() {
		this.loadPage(TrainPassengersPreview);
	},

	// HOTELS
	hotelsOffers(hash) {
		const load = (fromHash) => {
			this.loadPage(HotelsResultView, {fromHash}).then(() => {
				this.breadcrumbs.setRouteType('HOTELS');
				this.breadcrumbs.$el.show();
			});
		};
		if (hash) {
			HotelsResultView.loadFromHash(hash).then(() => {
				load(true);
			});
		} else {
			load();
		}
	},

	hotelsTravellers() {
		this.loadPage(HotelsPassengersDataView).then(() => {
			this.breadcrumbs.setRouteType('HOTELS');
			this.breadcrumbs.$el.show();
		});
	},

	hotelPreview() {
		this.loadPage(HotelPreview);
	},

	// TRANSFERS
	transfersOffers(hash) {
		const load = (fromHash) => {
			this.loadPage(TransfersResultView, {fromHash}).then(() => {
				this.breadcrumbs.setRouteType('TRANSFERS');
				this.breadcrumbs.$el.show();
			});
		};
		if (hash) {
			TransfersResultView.loadFromHash(hash).then(() => {
				load(true);
			});
		} else {
			load();
		}
	},

	transferTravellers() {
		this.loadPage(TransfersPassengerDataView).then(() => {
			this.breadcrumbs.setRouteType('TRANSFERS');
			this.breadcrumbs.$el.show();
		});
	},

	// CABINET
	ORDERS_cabinetOrders() {
		this.loadPage(CabinetOrders);
	},

	PAYMENT_payment() {
		this.loadPage(CabinetOrder).then(() => {
			this.breadcrumbs.$el.hide();
		});
	},

	cabinetOrder(orderUid) {
		if (orderUid != null && orderUid !== '') {
			this.loadPage(CabinetOrder, orderUid).then(() => {
				this.breadcrumbs.$el.hide();
			});
		} else {
			this.ORDERS_cabinetOrders();
		}
	},

	SALES_cabinetProducts() {
		this.loadPage(CabinetProducts);
	},

	EMPLOYEES_cabinetEmployees() {
		this.loadPage(CabinetEmployees);
	},

	EMPLOYEES_cabinetEmployee(params) {
		this.loadPage(CabinetEmployee, params);
	},

	PASSENGERS_cabinetPassengers() {
		this.loadPage(CabinetPassengers);
	},

	PASSENGERS_cabinetPassenger(params) {
		this.loadPage(CabinetPassenger, params);
	},

	USER_DATA_cabinetProfile() {
		this.loadPage(CabinetProfile);
	},

	FINANCES_cabinetFinance() {
		this.loadPage(CabinetFinance);
	},

	newsPage() {
		this.loadPage(NewsPage);
	},

});
