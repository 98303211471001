module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {



if (!_.isArray(price)) {
	price = [price];
}

var services = services;

function getTaxesSummary(taxes) {
	var currencyCode;
	var taxSummary = taxes.reduce(function (prev, curr) {
		currencyCode = curr.amount.currencyCode;
		return !_.isEmpty(prev) ? prev + parseInt(curr.amount.amount) : parseInt(curr.amount.amount);
	}, {});
	return {amount: taxSummary, currencyCode: currencyCode};
}

function getDailyDetailsDataToRender(dailyDetails) {
    var output = [];
    if (!dailyDetails) return output;
    _.each(dailyDetails, function(dailyDetail) {
        var renderedDate = dailyDetail.date ? new Time(dailyDetail.date).toDateString(): "";
        var renderedPrice = dailyDetail.price ?
            (
                Formatter.formatMoney(dailyDetail.price.amount, Formatter.getDecimal(dailyDetail.price.amount))
                + " "
                + Formatter.formatCurrency(dailyDetail.price.currencyCode)
            )
            :
            "";
        output.push({
            renderedDate: renderedDate,
            renderedPrice: renderedPrice,
        });
    });
    return output;
}

;
__p += '\n\n<div class="b-ticket-pricing ' +
((__t = ( (_.size(price) > 1) ? 'no-max-width' : '' )) == null ? '' : __t) +
' b-hotel-pricing">\n	';
 _.each(price, function (p, i) { ;
__p += '\n		<div class="b-ticket-pricing__item">\n			';
 if (_.size(price) > 1) { ;
__p += '\n				<div class="b-ticket-pricing__direction">\n					<span>' +
((__t = ( (i === 0) ? L10N.get("trains.there") : L10N.get("trains.backward") )) == null ? '' : __t) +
'</span>\n				</div>\n			';
 } ;
__p += '\n\n			';
 if (!_.isEmpty(p.rates) && _.isArray(p.rates)) { ;
__p += '\n				<div class="b-ticket-pricing__header">\n                    ';
 _.each(p.rates, function(rate) { ;
__p += '\n                        <span>' +
((__t = ( rate.total.amount + ' ' )) == null ? '' : __t) +
'<span class="currency">' +
((__t = ( rate.symbol )) == null ? '' : __t) +
'</span></span>\n                    ';
 }); ;
__p += '\n				</div>\n			';
 } ;
__p += '\n\n			<div class="b-ticket-pricing__content" data-copy-container="' +
((__t = ( i )) == null ? '' : __t) +
'">\n				';

				var types = ['tariff', 'taxes', 'supplierFees', 'agencyFees', 'feeAndTaxes', 'penalty', 'discount', 'ancillaryFees'];

				p.passengerPrices = _.toArray(p.passengerPrices).sort(function(a,b) {return a.passengerType.uid > b.passengerType.uid});

				_.each(p.passengerPrices, function(value) {
					var passengerPrice = _.extend({}, value, value.feeDetails);
					var ticketPrice = 0;
                    var dailyDetailsDataToRender = getDailyDetailsDataToRender(value.dailyDetails);
                ;
__p += '\n					<div class="b-ticket-pricing__section">\n						<span class="b-ticket-pricing__section-title">\n							';
 if (passengerPrice && passengerPrice.passengerType) { ;
__p += '\n								' +
((__t = ( passengerPrice.passengerType.caption )) == null ? '' : __t) +
'\n								';
 if (passengerPrice.passengerCount > 1) { ;
__p += 'x' +
((__t = ( passengerPrice.passengerCount )) == null ? '' : __t);
 } ;
__p += '\n                                <span class="js-copy-content-tooltip copy-content">' +
((__t = ( passengerPrice.passengerType.caption )) == null ? '' : __t);
 if (passengerPrice.passengerCount > 1) { ;
__p += ' x' +
((__t = ( passengerPrice.passengerCount )) == null ? '' : __t) +
'/';
 } else { ;
__p += '/';
 } ;
__p += '</span>\n							';
 } ;
__p += '\n						</span>\n						';
  _.each(types, function (type, i) {
							var priceValue = passengerPrice[type];
							if (type !== 'ancillaryFees' && priceValue != null && parseFloat(priceValue.amount) !== 0) {
								ticketPrice += priceValue.amount;
                        ;
__p += '\n								<div class="b-ticket-pricing__section-info">\n									<span class="b-ticket-pricing__section-passenger">' +
((__t = ( L10N.get("pricing." + type) )) == null ? '' : __t) +
'</span>\n									<span class="b-ticket-pricing__section-price">\n										' +
((__t = ( Formatter.formatMoney(priceValue.amount, Formatter.getDecimal(priceValue.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(priceValue.currencyCode) )) == null ? '' : __t) +
'\n									</span>\n								</div>\n                                <span class="js-copy-content-tooltip copy-content">' +
((__t = ( L10N.get("pricing." + type) )) == null ? '' : __t) +
': ' +
((__t = ( Formatter.formatMoney(priceValue.amount, Formatter.getDecimal(priceValue.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(priceValue.currencyCode) )) == null ? '' : __t) +
'/</span>\n							';
 } ;
__p += '\n\n							';
 if (type === 'tariff') { ;
__p += '\n                                ';
 if (dailyDetailsDataToRender.length) { ;
__p += '\n                                    <span class="js-copy-content-tooltip copy-content" data-no-trim-start="true">' +
((__t = ( _.map(dailyDetailsDataToRender, function(dailyDetailsData) {return "\n" + dailyDetailsData.renderedDate + " " + dailyDetailsData.renderedPrice;}).join("") )) == null ? '' : __t) +
'</span>\n                                ';
 } ;
__p += '\n								';
 if (!_.isEmpty(services)) { ;
__p += '\n									';
 _.each(services, function (s) {
										var offer = s.offer;
										var provider = s.offer.provider;
										if (provider && provider.uid === 'SABRE') {
											if (!_.isEmpty(offer.taxInfoList)) { ;
__p += '\n												';
 var taxSummary = getTaxesSummary(offer.taxInfoList); ;
__p += '\n												<div class="b-ticket-pricing__section-info">\n													<span class="b-ticket-pricing__section-passenger">' +
((__t = ( L10N.get('pricing.HOTEL_RESERVATION.taxes') )) == null ? '' : __t) +
'</span>\n													<span class="b-ticket-pricing__section-price">\n														' +
((__t = ( Formatter.formatMoney(taxSummary.amount, 2) )) == null ? '' : __t) +
'\n														' +
((__t = ( Formatter.formatCurrency(taxSummary.currencyCode) )) == null ? '' : __t) +
'\n													</span>\n												</div>\n                                                <span class="js-copy-content-tooltip copy-content">' +
((__t = ( L10N.get('pricing.HOTEL_RESERVATION.taxes') )) == null ? '' : __t) +
': ' +
((__t = ( Formatter.formatMoney(taxSummary.amount, 2) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(taxSummary.currencyCode) )) == null ? '' : __t) +
'/</span>\n											';
 } ;
__p += '\n										';
 } ;
__p += '\n									';
 }) ;
__p += '\n								';
 } ;
__p += '\n								';
 if (!_.isEmpty(passengerPrice.additionalServices) && _.compact(_.values(passengerPrice.additionalServices)).length) { ;
__p += '\n									<div class="b-ticket-pricing__section">\n                                        <span class="js-copy-content-tooltip copy-content">' +
((__t = ( L10N.get('hotels.hotelAddServices') )) == null ? '' : __t) +
':/</span>\n										';
 if (passengerPrice.additionalServices.checkInAmount) { ;
__p += '\n											<div class="b-ticket-pricing__section-info">\n												<span class="b-ticket-pricing__section-passenger">' +
((__t = ( L10N.get('hotels.addServices.earlyCheckIn') )) == null ? '' : __t) +
'</span>\n												<span class="b-ticket-pricing__section-price">\n													' +
((__t = ( Formatter.formatMoney(passengerPrice.additionalServices.checkInAmount, Formatter.getDecimal(passengerPrice.additionalServices.checkInAmount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(p.total.currencyCode) )) == null ? '' : __t) +
'\n												</span>\n											</div>\n                                            <span class="js-copy-content-tooltip copy-content">' +
((__t = ( L10N.get('hotels.addServices.earlyCheckIn') )) == null ? '' : __t) +
': ' +
((__t = ( Formatter.formatMoney(passengerPrice.additionalServices.checkInAmount, Formatter.getDecimal(passengerPrice.additionalServices.checkInAmount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(p.total.currencyCode) )) == null ? '' : __t) +
'/</span>\n										';
 } ;
__p += '\n										';
 if (passengerPrice.additionalServices.checkOutAmount) { ;
__p += '\n											<div class="b-ticket-pricing__section-info">\n												<span class="b-ticket-pricing__section-passenger">' +
((__t = ( L10N.get('hotels.addServices.lateCheckout') )) == null ? '' : __t) +
'</span>\n												<span class="b-ticket-pricing__section-price">\n													' +
((__t = ( Formatter.formatMoney(passengerPrice.additionalServices.checkOutAmount, Formatter.getDecimal(passengerPrice.additionalServices.checkOutAmount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(p.total.currencyCode) )) == null ? '' : __t) +
'\n												</span>\n											</div>\n                                            <span class="js-copy-content-tooltip copy-content">' +
((__t = ( L10N.get('hotels.addServices.lateCheckout') )) == null ? '' : __t) +
': ' +
((__t = ( Formatter.formatMoney(passengerPrice.additionalServices.checkOutAmount, Formatter.getDecimal(passengerPrice.additionalServices.checkOutAmount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(p.total.currencyCode) )) == null ? '' : __t) +
'/</span>\n										';
 } ;
__p += '\n										';
 if (passengerPrice.additionalServices.mealAmount) { ;
__p += '\n											<div class="b-ticket-pricing__section-info">\n												<span class="b-ticket-pricing__section-passenger">' +
((__t = ( L10N.get('hotels.meal') )) == null ? '' : __t) +
'</span>\n												<span class="b-ticket-pricing__section-price">\n													' +
((__t = ( Formatter.formatMoney(passengerPrice.additionalServices.mealAmount, Formatter.getDecimal(passengerPrice.additionalServices.mealAmount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(p.total.currencyCode) )) == null ? '' : __t) +
'\n												</span>\n											</div>\n                                            <span class="js-copy-content-tooltip copy-content">' +
((__t = ( L10N.get('hotels.meal') )) == null ? '' : __t) +
': ' +
((__t = ( Formatter.formatMoney(passengerPrice.additionalServices.mealAmount, Formatter.getDecimal(passengerPrice.additionalServices.mealAmount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(p.total.currencyCode) )) == null ? '' : __t) +
'/</span>\n										';
 } ;
__p += '\n									</div>\n								';
 } ;
__p += '\n							';
 } ;
__p += '\n                            ';
 if (type === 'ancillaryFees' && !_.isEmpty(priceValue)) { ;
__p += '\n                                <span class="b-ticket-pricing__section-title">\n                                    ' +
((__t = ( L10N.get('hotels.additionalServiceShort') )) == null ? '' : __t) +
'\n                                </span>\n                                ';
 _.each(priceValue, function (item) { ;
__p += '\n                                    <div class="b-ticket-pricing__section-info">\n                                        <span class="b-ticket-pricing__section-passenger">' +
((__t = ( item.caption )) == null ? '' : __t) +
'</span>\n                                        <span class="b-ticket-pricing__section-price">\n                                        ' +
((__t = ( item.number + 'x' )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatMoney(item.price.amount, Formatter.getDecimal(item.price.amount)) )) == null ? '' : __t) +
'\n                                            ' +
((__t = ( Formatter.formatCurrency(item.price.currencyCode) )) == null ? '' : __t) +
'\n                                        </span>\n                                    </div>\n                                ';
 }) ;
__p += '\n                            ';
 } ;
__p += '\n						';
 }); ;
__p += '\n                        ';
 if (dailyDetailsDataToRender.length) { ;
__p += '\n                            <div class="b-ticket-pricing__bordered-wrapper b-ticket-pricing__bordered-wrapper--with-bb">\n                                ';
 _.each(dailyDetailsDataToRender, function(dailyDetailsData) { ;
__p += '\n                                    <div class="b-ticket-pricing__section-info">\n                                        <span class="b-ticket-pricing__section-passenger">\n                                            ' +
((__t = ( dailyDetailsData.renderedDate )) == null ? '' : __t) +
'\n                                        </span>\n                                        <span class="b-ticket-pricing__section-price">\n                                            ' +
((__t = ( dailyDetailsData.renderedPrice )) == null ? '' : __t) +
'\n                                        </span>\n                                    </div>\n                                ';
 }) ;
__p += '\n                            </div>\n                        ';
 } ;
__p += '\n					</div>\n				';
 }); ;
__p += '\n\n				';
 if (!_.isEmpty(services)) { ;
__p += '\n					';
 _.each(services, function (s) {
                        var offer = s.offer;
                        var provider = s.offer.provider;
                        if (!_.isEmpty(offer.taxInfoList)) {
							if (provider && provider.uid !== 'SABRE') { ;
__p += '\n								<div class="b-ticket-pricing__section">\n									<div class="b-ticket-pricing__section-title">\n										' +
((__t = ( L10N.get('pricing.HOTEL_RESERVATION.taxNotIncluded') )) == null ? '' : __t) +
'\n									</div>\n									';
 var taxSummary = getTaxesSummary(offer.taxInfoList); ;
__p += '\n                                    <div class="b-ticket-pricing__section-info">\n                                        <span class="b-ticket-pricing__section-passenger">' +
((__t = ( L10N.get('pricing.HOTEL_RESERVATION.taxes') )) == null ? '' : __t) +
'</span>\n                                        <span class="b-ticket-pricing__section-price">\n                                            ' +
((__t = ( Formatter.formatMoney(taxSummary.amount, 2) )) == null ? '' : __t) +
'\n                                            ' +
((__t = ( Formatter.formatCurrency(taxSummary.currencyCode) )) == null ? '' : __t) +
'\n                                        </span>\n                                    </div>\n                            	</div>\n                                <span class="js-copy-content-tooltip copy-content">' +
((__t = ( L10N.get('pricing.HOTEL_RESERVATION.taxNotIncluded') )) == null ? '' : __t) +
'/</span>\n                                <span class="js-copy-content-tooltip copy-content">' +
((__t = ( L10N.get('pricing.HOTEL_RESERVATION.taxes') )) == null ? '' : __t) +
':  ' +
((__t = ( Formatter.formatMoney(taxSummary.amount, 2) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(taxSummary.currencyCode) )) == null ? '' : __t) +
'/</span>\n                        	';
 } ;
__p += '\n						';
 } ;
__p += '\n					';
 }) ;
__p += '\n				';
 } ;
__p += '\n			</div>\n\n			';
 if (_.size(price) > 1 && p.total != null) { ;
__p += '\n				<div class="b-pricing__total-section clearfix">\n					<span class="b-pricing__section-total-title">' +
((__t = ( L10N.get("pricing.total") )) == null ? '' : __t) +
':</span>\n					<span class="b-pricing__section-total-price">\n						';
 if (p.isMinPriceRangeComponent === true) { ;
__p +=
((__t = ( L10N.get('pricing.from') )) == null ? '' : __t) +
' ';
 } ;
__p += '\n						' +
((__t = ( Formatter.formatMoney(p.total.amount, Formatter.getDecimal(p.total.amount)))) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(p.total.currencyCode) )) == null ? '' : __t) +
'\n					</span>\n				</div>\n                <span class="js-copy-content-tooltip copy-content">' +
((__t = ( L10N.get("pricing.total") )) == null ? '' : __t) +
': ';
 if (p.isMinPriceRangeComponent === true) { ;
__p +=
((__t = ( L10N.get('pricing.from') )) == null ? '' : __t) +
' ';
 } ;
__p += ' ' +
((__t = ( Formatter.formatMoney(p.total.amount, Formatter.getDecimal(p.total.amount)))) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(p.total.currencyCode) )) == null ? '' : __t) +
'/</span>\n			';
 } ;
__p += '\n		</div>\n	';
 }); ;
__p += '\n</div>\n';

}
return __p
}