// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';
import Inputmask from 'jquery.inputmask/dist/inputmask/inputmask';
import 'jquery.inputmask/dist/inputmask/inputmask.extensions';
import 'jquery.inputmask/dist/inputmask/inputmask.date.extensions';

export default Widget.extend({

	validationTemplate,

	template,

	ui: {
		country: '.b-input-phone__country',
		countryVisible: '.b-input-phone__country-visible',
		value: '.b-input-phone__value',
	},

	masks: {
		7: {
			mask: '(999) 999-99-99',
			label: '(___) ___-__-__',
		},
		0: {
			mask: '',
			label: '',
		},
		
	},

	selectedMask: 7,

	events: {
		'change .b-input-phone__country': 'changeCountry',
	},

	initialize() {
		this.disabled = String(this.options.disabled) === 'true';

		this.render({
			countries: STATE.getCountries().sort((a, b) => {
				return a.caption - b.caption;
			}),
			deletableField: (this.options.deletableField === true),
		});

		this.inputMask = new Inputmask({
			mask: this.masks[7].mask,
			placeholder: this.masks[7].label,
			clearMaskOnLostFocus: false,
			clearIncomplete: true,
			minChars: 0,
			width: 100,
			oncomplete: () => {
				this.change();
			},
			onincomplete: () => {
				this.change();
			},
		});

		if (this.disabled) {
			const disabledAttribute = document.createAttribute('disabled');
			disabledAttribute.value = 'disabled';
			this.ui.value[0].attributes.setNamedItem(disabledAttribute);
			this.ui.country.attr('disabled', true);
		}

		this.inputMask.mask(this.ui.value[0]);
	},

	changeCountry() {
		this.change(null, true);
		this.ui.value[0].focus();
	},

	change(e, clearValue) {
		this.applyInputmask();
		let values = /\((.*)\) (.*)/.exec(this.ui.value.val());

		if (!values || values.length !== 3 || clearValue) {
			values = ['', '', ''];
		} 
		if (this.ui.country.val() === '7') {
			this.model.set(this.options.bindingProperty, {
				countryCode: this.ui.country.val().replace(/\D/g, ''),
				cityCode: values[1].replace(/\D/g, ''),
				number: values[2].replace(/\D/g, ''),
			});
		} else {
			this.model.set(this.options.bindingProperty, {
				countryCode: this.ui.country.val().replace(/\D/g, ''),
				cityCode: '',
				number: clearValue ? values[0] : this.ui.value.val(),
			});
		}

		this.ui.countryVisible.text(`+${this.ui.country.val().replace(/\D/g, '')}`);
		// this.ui.country.typeahead('close');
		// this.ui.value[0].focus();
	},

	applyInputmask(newMask) {
		let mask = this.masks[this.selectedMask]?.mask;
		let label = this.masks[this.selectedMask]?.label;
		const dataInputmask = document.createAttribute('data-inputmask');

		let countryCode = this.ui.country.val();
		if (newMask != null && newMask !== '') {
			if (this.selectedMask !== parseInt(newMask, 10)) {
				countryCode = parseInt(newMask, 10);
			} else {
				return;
			}
		}

		if (!this.masks[countryCode]) {
			countryCode = 0;
		}

		mask = this.masks[countryCode]?.mask;
		label = this.masks[countryCode]?.label;
		this.selectedMask = countryCode;

		dataInputmask.value = `"mask": "${mask}", "placeholder" : "${label}"`;
		this.ui.value[0].attributes.setNamedItem(dataInputmask);

		this.inputMask.mask(this.ui.value[0]);
		if (countryCode === 0 && this.ui.value[0].inputmask) {
			this.ui.value[0].inputmask.remove();
			this.ui.value.on('change', this.change.bind(this));
		}		
	},

	applyBinding(model) {
		this.model = model;
		this.listenTo(model, `change:${this.options.bindingProperty}`, (thisModel, value) => {
			if (value) {
				this.applyInputmask(value.countryCode);
				this.ui.country.val(value.countryCode);
				this.ui.countryVisible.text(`+${value.countryCode}`);
				this.ui.value.val(value.cityCode + value.number);
			}
		});
		if (model.has(this.options.bindingProperty)) {
			const phone = model.get(this.options.bindingProperty);
			this.applyInputmask(phone.countryCode);
			this.ui.country.val(phone.countryCode);
			this.ui.countryVisible.text(`+${phone.countryCode}`);
			this.ui.value.val(phone.cityCode + phone.number);
		}
	},
});
