module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-filters__line ' +
((__t = ( classes )) == null ? '' : __t) +
'">\r\n	<span class="js-widget"  onclick=\'return new Widgets.Range({ bindingProperty: "value", label: "' + label + '", min: ' + min + ', max: ' + max + ', formatter: "' + formatter + '"  })\'></span>\r\n</div>\r\n';

}
return __p
}