// eslint-disable-next-line banned-modules
'use strict';

import $ from 'jquery';
import './style.less';
import Widget from '@/widgets/base.widget';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';
import template from './template.ejs';
import Inputmask from 'jquery.inputmask/dist/inputmask/inputmask';
import 'jquery.inputmask/dist/inputmask/inputmask.extensions';
import 'jquery.inputmask/dist/inputmask/inputmask.date.extensions';

export default Widget.extend({

	validationTemplate,

	template,

	disabled: false,

	ui: {
		timerange: '.w-timerange__input',
		select: '.w-timerange__select',
		suggest: '.w-timerange__suggest',
		suggestItem: '.w-timerange__suggest-list-point',
	},

	daytypes: {
		morning: '00 : 00 - 06 : 00',
		noon: '06:00 – 12:00',
		evening: '12:00 – 18:00',
		night: '18:00 – 00:00',
	},

	events: {
		'click .w-timerange__select': 'showHideSuggest',
		'click .w-timerange__suggest-list-point': 'selectSuggestPoint',
		'change .w-timerange__input': 'change',
	},

	masks: {
		timerange: {
			mask: '24 : 00 – 24 : 00',
			label: '__ : __ – __ : __',
		},
	},

	initialize() {
		this.render(this.options);

		this.$el.attr('timerange-id', this.cid);

		// Создаем маску
		this.inputMask = new Inputmask({
			hourFormat: '24',
			// Указываем чтобы в часах числа не первышали 24 часа в сутки
			definitions: {
				5: { validator: '[5-5]' },
				9: { validator: '[9-9]' },
				0: { validator: '[0-0]' },
				2: { validator: '[0-2]' },
				4: { validator: function validator(chrs, maskset) {
					// Валидатор inputmask для проверки второго значения в часах.
					// Если первый час 2 то второй не может быть больше 4 т.к. в сутках 24 часа.
					// Каждый раз при изменении второго часа приложение проваливается в этот метод.
					if (maskset.buffer[maskset.p - 1] !== '2') {
						return true;
					} else if (maskset.buffer[maskset.p - 1] === '2' && chrs < '5') {
						return true;
					}
					return false;
				} },
			},
			mask: this.masks.timerange.mask,
			placeholder: this.masks.timerange.label,
			clearMaskOnLostFocus: false,
			clearIncomplete: true,
		});
		this.inputMask.mask(this.ui.timerange[0]);
		this.applyInputmask();

		if (this.options.disabled) {
			this.disable(true);
		}

		// hide modal when click outside
		$(document).click((e) => {
			const $timerange = $(e.target).parents('.w-timerange:first');
			const $openedTimeRage = $('.w-timerange.open');
			if ($timerange.length === 0 && $openedTimeRage.length > 0) {
				$openedTimeRage.removeClass('open');
			} else if ($timerange.length > 0 && $openedTimeRage.length > 0) {
				$(`.w-timerange[timerange-id!="${$timerange.attr('timerange-id')}"]`).removeClass('open');
			}
		});
	},

	disable(disabled) {
		this.disabled = disabled;
		// UTSS-943: вероятно, сносить value мы должны только, если дизейблим инпут.
		const $input = this.$el.find('.w-timerange__input').attr('disabled', disabled);
		if (disabled) $input.val('');
	},

	dependOn() {
		if (this.options.depend) {
			this.listenTo(this.model, `change:${this.options.depend}`, (__model, value) => {
				this.applyDependency(value);
			});
			if (this.model.has(this.options.depend)) {
				this.applyDependency(this.model.get(this.options.depend));
			}
		}
	},

	applyDependency(value) {
		this.disable(value != null && value === 'ONEWAY');
	},

	applyInputmask() {
		const mask = this.masks.timerange.mask;
		const label = this.masks.timerange.label;
		const dataInputmask = document.createAttribute('data-inputmask');

		dataInputmask.value = `"mask": "${mask}", "placeholder" : "${label}"`;
		this.ui.timerange[0].attributes.setNamedItem(dataInputmask);
		this.inputMask.mask(this.ui.timerange[0]);
	},

	applyBinding(model) {
		this.model = model;
		this.listenTo(model, `change:${this.options.bindingProperty}`, (__model, value) => {
			// Fix placeholder hide bug
			this.$el.find('input').val(value || '').trigger('mouseenter');
		});

		if (this.model.has(this.options.bindingProperty)) {
			this.$el.find('input').val(this.model.get(this.options.bindingProperty));
		}

		this.listenTo(this.model, `validationError:${this.options.bindingProperty}`, () => {
			this.$el.addClass('validation-error');
		});
		this.dependOn();
	},

	change() {
		this.model.set(this.options.bindingProperty, this.$el.find('.w-timerange__input').val());
	},

	showHideSuggest() {
		if (this.disabled) {
			return;
		}
		if (this.$el.hasClass('open')) {
			this.$el.removeClass('open');
			this.model.unset(this.options.bindingProperty);
		} else {
			this.$el.addClass('open');
		}
	},

	selectSuggestPoint(event) {
		const selectTimeRange = event.currentTarget.dataset.type;
		const curTimeRange = this.daytypes[selectTimeRange];
		this.$el.find('input').val(curTimeRange);
		this.$el.find('input').change();
		this.$el.removeClass('open');
	},
});
