module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-popup b-button-container ' +
((__t = ( classes )) == null ? '' : __t) +
' ' +
((__t = ( (!title && !showPrintAction) ? 'without-title' : '' )) == null ? '' : __t) +
' ' +
((__t = ( (type) ? 'b-popup--type-' + type : '' )) == null ? '' : __t) +
'">\r\n	<div class="b-button-container__top">\r\n		<div class="b-popup__header">\r\n			<h3 class="b-popup__title">' +
((__t = ( title )) == null ? '' : __t) +
'</h3>\r\n			';
 if (!closeOnlyOnAction) { ;
__p += '\r\n				<span class="b-popup__close"></span>\r\n			';
 } ;
__p += '\r\n			';
 if (showPrintAction) { ;
__p += '\r\n				<span class="b-popup__print ' +
((__t = ( !closeOnlyOnAction ? 'b-popup__print-wc' : '' )) == null ? '' : __t) +
'"></span>\r\n			';
 } ;
__p += '\r\n		</div>\r\n\r\n		<div class="b-popup__content"></div>\r\n	</div>\r\n	<div class="b-button-container__footer">\r\n		';
 _.each(_.compact(actions), function(item, i) { ;
__p += '\r\n			<span class="b-button-container__button b-popup__action ' +
((__t = ( item.classes )) == null ? '' : __t) +
'" data-id="' +
((__t = ( i )) == null ? '' : __t) +
'" title="' +
((__t = ( _.escape(item.label) )) == null ? '' : __t) +
'">\r\n				<span>' +
((__t = ( item.label )) == null ? '' : __t) +
'</span>\r\n			</span>\r\n		';
 }); ;
__p += '\r\n	</div>\r\n</div>\r\n';

}
return __p
}