// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

export default BaseView.extend({

	template,

	tagName: 'article',

	className: 'b-ticket b-ticket__visible',

	events: {
		'click .b-ticket-leg__open': 'open',
		'click .b-ticket__order': 'select',
		'click .b-ticket__branded-fares': 'selectBrandedFares',
	},

	initialize() {
		const duration = this.options.flight.segments.reduce((a, b) => {
			return { duration: a.travelDuration + b.travelDuration };
		});
		this.options.flight.duration = duration.travelDuration != null ? duration.travelDuration : duration.duration;
		this.flight = {
			legs: [this.options.flight],
		};
		this.render({
			flight: this.flight,
			carrier: this.flight.legs[0].segments[0].airline,
		});
	},

	open(e) {
		e.preventDefault();
		if (this.$el.hasClass('b-ticket__is-open')) {
			this.$el.removeClass('b-ticket__is-open');
			this.$el.parent().find('.b-ticket__common-services').stop()
				.slideUp();
			this.$('.b-ticket-leg__extended-visible').stop().slideUp();
		} else {
			this.$el.addClass('b-ticket__is-open');
			this.$el.parent().find('.b-ticket__common-services').stop()
				.slideDown();
			this.$('.b-ticket-leg__extended-visible').stop().slideDown();
		}
	},

});
