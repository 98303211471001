module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var model = model || {};
    var useToggle = STATE.checkViewport('(max-width: 768px)');
    var additionalServicesTo = to && to.additionalServices || [];
    var additionalServicesFrom = from && from.additionalServices || [];
    var isRoundTrip = !_.isEmpty(additionalServicesFrom);
    var startAddress = tripInfo && tripInfo.startPlace && tripInfo.startPlace.address;
    var endAddress = tripInfo && tripInfo.endPlace && tripInfo.endPlace.address;

    var seatServicesFields = ["INFANT_SEAT", "SEAT", "BOOSTER"];

    var seatServicesTo = _.filter(additionalServicesTo, function (s) { return s && s.type && seatServicesFields.includes(s.type.uid); });
    var seatServicesFrom = isRoundTrip ? _.filter(additionalServicesFrom, function (s) { return s && s.type && seatServicesFields.includes(s.type.uid); }) : [];

    if (!_.isEmpty(seatServicesTo)) {
        additionalServicesTo = _.filter(additionalServicesTo, function (s) { return s && s.type && !seatServicesFields.includes(s.type.uid); });
    }

    if (!_.isEmpty(seatServicesFrom)) {
        additionalServicesFrom = isRoundTrip ? _.filter(additionalServicesFrom, function (s) { return s && s.type && !seatServicesFields.includes(s.type.uid); }) : [];
    }
;
__p += '\n<div class="b-additional-services">\n    ';
 if (isRoundTrip) { ;
__p += '\n        <div class="b-additional-services__header">' +
((__t = ( L10N.get("transfers.ride") )) == null ? '' : __t) +
' ' +
((__t = ( startAddress )) == null ? '' : __t) +
' - ' +
((__t = ( endAddress)) == null ? '' : __t) +
'</div>\n    ';
 } ;
__p += '\n        <div class="b-additional-services__content flex-grid-container">\n            ';
 if (!_.isEmpty(additionalServicesTo)) { ;
__p += '\n                ';
 _.each(additionalServicesTo, function(service) { ;
__p += '\n                ';
 if (!_.isEmpty(service)) { ;
__p += '\n                    ';
 var label = L10N.get("transfers.additionalServices." + service.type.uid) ;
__p += '\n                    ';
 if (service.type.uid === "TABLE") { ;
__p += '\n                        <div class="b-additional-services__table-service b-additional-services__service">\n                            <div class="b-additional-services__table-service__checkbox-container">\n                                ';
 if (useToggle) { ;
__p += '\n                                    <span class="js-widget"  onclick=\'return new Widgets.ToggleButton({bindingProperty: "to.serviceTypes.' + service.type.uid + '",  label: "' + service.type.caption + '", disabled: "' + service.isFixed + '" })\'></span>\n                                ';
 } else { ;
__p += '\n                                    <span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "to.serviceTypes.' + service.type.uid + '",  label: "' + service.type.caption + '" , disabled: "' + service.isFixed + '" })\'></span>\n                                ';
 } ;
__p += '\n                                <span class="b-additional-services__service-price">+ ' +
((__t = ( Formatter.formatMoney(service.price) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(service.currencyCode) )) == null ? '' : __t) +
'</span>\n                            </div>\n                            <div class="b-additional-services__table-service__table-caption-container to">\n                                ';
 if (useToggle) { ;
__p += '\n                                <span class="js-widget"  onclick=\'return new Widgets.Textarea({bindingProperty: "to.tableCaption", placeholder: L10N.get("transfers.additionalServices.tableCaption"), disabled: "' + service.isFixed + '" })\'></span>\n                                ';
 } else { ;
__p += '\n                                <span class="js-widget"  onclick=\'return new Widgets.Input({bindingProperty: "to.tableCaption", placeholder: L10N.get("transfers.additionalServices.tableCaption"), disabled: "' + service.isFixed + '" })\'></span>\n                                ';
 } ;
__p += '\n                            </div>\n                        </div>\n                        ';
 } else { ;
__p += '\n                        <div class="b-additional-services__service">\n                            ';
 if (useToggle) { ;
__p += '\n                                <span class="js-widget"  onclick=\'return new Widgets.ToggleButton({ bindingProperty: "to.serviceTypes.' + service.type.uid + '", label: "' + service.type.caption + '", disabled: "' + service.isFixed + '" })\'></span>\n                            ';
 } else { ;
__p += '\n                                <span class="js-widget"  onclick=\'return new Widgets.Checkbox({ bindingProperty: "to.serviceTypes.' + service.type.uid + '", label: "' + service.type.caption + '", disabled: "' + service.isFixed + '" })\'></span>\n                            ';
 } ;
__p += '\n                            <span class="b-additional-services__service-price">+ ' +
((__t = ( Formatter.formatMoney(service.price) )) == null ? '' : __t) +
'  ' +
((__t = ( Formatter.formatCurrency(service.currencyCode) )) == null ? '' : __t) +
'</span>\n                        </div>\n                        ';
 } ;
__p += '\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            ';
 } ;
__p += '\n            ';
 if (!_.isEmpty(seatServicesTo)) { ;
__p += '\n                ';
 var babyChair = _.find(seatServicesTo, function (s) { return s && s.type && s.type.uid === 'SEAT'}) ;
__p += '\n                <div class="b-additional-services__service fc">\n                    <div class="b-additional-services__seat-services-checkbox">\n                        ';
 if (useToggle) { ;
__p += '\n                            <span class="js-widget"  onclick=\'return new Widgets.ToggleButton({bindingProperty: "to.showSeatTypes", label: L10N.get("transfers.additionalServices.babyChair") })\'></span>\n                        ';
 } else { ;
__p += '\n                            <span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "to.showSeatTypes", label: L10N.get("transfers.additionalServices.babyChair") })\'></span>\n                        ';
 } ;
__p += '\n                        <span class="b-additional-services__service-price">' +
((__t = ( L10N.get('transfers.from') )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatMoney(babyChair.price) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(babyChair.currencyCode) )) == null ? '' : __t) +
'</span>\n                    </div>\n                    <div class="b-additional-services__seat-services b-additional-services__seat-services--to">\n                        ';
 _.each(seatServicesTo, function(s) { ;
__p += '\n                        ';
 if (!_.isEmpty(s)) { ;
__p += '\n                            ';
 var label = L10N.get("transfers.additionalServices." + s.type.uid); ;
__p += '\n                            <div class="b-additional-services__seat-services__service">\n                                <div class="b-additional-services__seat-services__service-row">\n                                    ';
 if (useToggle) { ;
__p += '\n                                        <span class="js-widget"  onclick=\'return new Widgets.ToggleButton({ bindingProperty: "to.serviceTypes.' + s.type.uid + '", label: "'+ label +'", disabled: "' + s.isFixed + '" })\'></span>\n                                    ';
 } else { ;
__p += '\n                                        <span class="js-widget"  onclick=\'return new Widgets.Checkbox({ bindingProperty: "to.serviceTypes.' + s.type.uid + '", label: "'+ label +'", disabled: "' + s.isFixed + '" })\'></span>\n                                    ';
 } ;
__p += '\n                                    <span class="b-additional-services__service-price">+ ' +
((__t = ( Formatter.formatMoney(s.price) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(s.currencyCode) )) == null ? '' : __t) +
'</span>\n                                </div>\n                                <div class="b-additional-services__seat-services__service-description">' +
((__t = ( s.type.caption )) == null ? '' : __t) +
'</div>\n                            </div>\n                        ';
 } ;
__p += '\n                        ';
 }); ;
__p += '\n                    </div>\n                </div>\n            ';
 } ;
__p += '\n        </div>\n        ';
 if (isRoundTrip) { ;
__p += '\n            <div class="b-additional-services__header">' +
((__t = ( L10N.get("transfers.ride") )) == null ? '' : __t) +
' ' +
((__t = ( endAddress )) == null ? '' : __t) +
' - ' +
((__t = ( startAddress)) == null ? '' : __t) +
'</div></div>\n            <div class="b-additional-services__content flex-grid-container">\n            ';
 if (!_.isEmpty(additionalServicesFrom)) { ;
__p += '\n                ';
 _.each(additionalServicesFrom, function(service) { ;
__p += '\n                    ';
 if (!_.isEmpty(service)) { ;
__p += '\n                        ';
 if (service.type.uid === "TABLE") { ;
__p += '\n                        <div class="b-additional-services__table-service b-additional-services__service">\n                            <div class="b-additional-services__table-service__checkbox-container">\n                                ';
 if (useToggle) { ;
__p += '\n                                    <span class="js-widget"  onclick=\'return new Widgets.ToggleButton({bindingProperty: "from.serviceTypes.' + service.type.uid + '",  label: "' + service.type.caption + '" , disabled: "' + service.isFixed + '" })\'></span>\n                                ';
 } else { ;
__p += '\n                                    <span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "from.serviceTypes.' + service.type.uid + '",  label: "' + service.type.caption + '" , disabled: "' + service.isFixed + '" })\'></span>\n                                ';
 } ;
__p += '\n                                <span class="b-additional-services__service-price">+ ' +
((__t = ( Formatter.formatMoney(service.price) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(service.currencyCode) )) == null ? '' : __t) +
'</span>\n                            </div>\n                            <div class="b-additional-services__table-service__table-caption-container from">\n                                ';
 if (useToggle) { ;
__p += '\n                                    <span class="js-widget"  onclick=\'return new Widgets.Textarea({bindingProperty: "from.tableCaption", placeholder: L10N.get("transfers.additionalServices.tableCaption"), disabled: "' + service.isFixed + '" })\'></span>\n                                ';
 } else { ;
__p += '\n                                    <span class="js-widget"  onclick=\'return new Widgets.Input({bindingProperty: "from.tableCaption", placeholder: L10N.get("transfers.additionalServices.tableCaption"), disabled: "' + service.isFixed + '" })\'></span>\n                                ';
 } ;
__p += '\n                            </div>\n                        </div>\n                        ';
 } else { ;
__p += '\n                        <div class="b-additional-services__service">\n                            ';
 if (useToggle) { ;
__p += '\n                                <span class="js-widget"  onclick=\'return new Widgets.ToggleButton({ bindingProperty: "from.serviceTypes.' + service.type.uid + '", label: "' + service.type.caption + '", disabled: "' + service.isFixed + '" })\'></span>\n                            ';
 } else { ;
__p += '\n                                <span class="js-widget"  onclick=\'return new Widgets.Checkbox({ bindingProperty: "from.serviceTypes.' + service.type.uid + '", label: "' + service.type.caption + '", disabled: "' + service.isFixed + '" })\'></span>\n                            ';
 } ;
__p += '\n                            <span class="b-additional-services__service-price">+ ' +
((__t = ( Formatter.formatMoney(service.price) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(service.currencyCode) )) == null ? '' : __t) +
'</span>\n                        </div>\n                        ';
 } ;
__p += '\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            ';
 } ;
__p += '\n                ';
 if (!_.isEmpty(seatServicesFrom)) { ;
__p += '\n                    ';
 babyChair = _.find(seatServicesFrom, function (s) { return s && s.type && s.type.uid === 'SEAT'}) ;
__p += '\n                    <div class="b-additional-services__service fc">\n                    <div class="b-additional-services__seat-services-checkbox">\n                        ';
 if (useToggle) { ;
__p += '\n                            <span class="js-widget"  onclick=\'return new Widgets.ToggleButton({bindingProperty: "from.showSeatTypes", label:  L10N.get("transfers.additionalServices.babyChair") })\'></span>\n                        ';
 } else { ;
__p += '\n                            <span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "from.showSeatTypes", label:  L10N.get("transfers.additionalServices.babyChair") })\'></span>\n                        ';
 } ;
__p += '\n                        <span class="b-additional-services__service-price">' +
((__t = ( L10N.get('transfers.from') )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatMoney(babyChair.price) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(babyChair.currencyCode) )) == null ? '' : __t) +
'</span>\n                    </div>\n                    <div class="b-additional-services__seat-services b-additional-services__seat-services--from">\n                        ';
 _.each(seatServicesFrom, function(s) { ;
__p += '\n                        ';
 if (!_.isEmpty(s)) { ;
__p += '\n                            ';
 var label = L10N.get("transfers.additionalServices." + s.type.uid);
__p += '\n                            <div class="b-additional-services__seat-services__service">\n                                <div class="b-additional-services__seat-services__service-row">\n                                    ';
 if (useToggle) { ;
__p += '\n                                        <span class="js-widget"  onclick=\'return new Widgets.ToggleButton({ bindingProperty: "from.serviceTypes.' + s.type.uid + '", label: "' + label + '", disabled: "' + s.isFixed + '" })\'></span>\n                                    ';
 } else { ;
__p += '\n                                        <span class="js-widget"  onclick=\'return new Widgets.Checkbox({ bindingProperty: "from.serviceTypes.' + s.type.uid + '", label: "' + label + '", disabled: "' + s.isFixed + '" })\'></span>\n                                    ';
 } ;
__p += '\n                                    <span class="b-additional-services__service-price">+ ' +
((__t = ( Formatter.formatMoney(s.price) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(s.currencyCode) )) == null ? '' : __t) +
'</span>\n                                </div>\n                                <div class="b-additional-services__seat-services__service-description">' +
((__t = ( s.type.caption )) == null ? '' : __t) +
'</div>\n                            </div>\n                        ';
 } ;
__p += '\n                        ';
 }); ;
__p += '\n                    </div>\n                </div>\n                ';
 } ;
__p += '\n            </div>\n        ';
 } ;
__p += '\n</div>\n\n';

}
return __p
}