module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-block-container">\r\n	<div class="b-block-container__header">\r\n		<div class="b-block-container__header-label">' +
((__t = ( L10N.get('cabinet.contracts.contracts') )) == null ? '' : __t) +
'</div>\r\n		<div class="b-block-container__header-right"></div>\r\n	</div>\r\n	<div class="b-block-container__top-controls">\r\n	</div>\r\n	<div class="b-block-container__content">\r\n	</div>\r\n	<div class="b-block-container__footer">\r\n	</div>\r\n</div>\r\n';

}
return __p
}