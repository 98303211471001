module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form autocomplete="on" class="p-login__form-registration">\n		<span class="js-widget"  onclick=\'return new Widgets.Login({bindingProperty: "login", type: "text", emailOnly: true, classes: "'+widgetClasses+'"})\'></span>\n		<span class="js-widget"  onclick=\'return new Widgets.Password({bindingProperty: "password", type: "password", classes: "'+widgetClasses+'"})\'></span>\n\n	<div class="b-login__rules-container">\n		<span class="b-login__rules">\n			<span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "acceptRules", label:L10N.get("loginForm.rules")})\'></span>\n			<span class="b-login__rules-more-btn">' +
((__t = (L10N.get("loginForm.rulesMore"))) == null ? '' : __t) +
'</span>\n		</span>\n	</div>\n\n	<div class="p-login__form-button-container">\n		<div class="p-login__form-button">\n			<a href="#" class="p-login__form-button-link js-auth-link" tabindex="-1">' +
((__t = ( L10N.get('AccountLoginForm.authorization') )) == null ? '' : __t) +
'</a>\n		</div>\n	</div>\n</form>\n';

}
return __p
}