module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	var createdDate = new Time(order.created).format('DD.MM.YY');
	var limitDate = new Time(reservation.timeLimit).format('DD.MM.YYYY-HH:mm');
	var travelSubject = reservation.travelSubject.uid;

	var showFooterOrderInfo = (['REFUND', 'PAYED', 'ISSUED'].indexOf(reservation.status.uid) !== -1);

    function isBlurred(service) {
        return service.aclViewGranted ? '' : 'content-blur';
    }
;
__p += '\n\n<div>\n	<div class="b-order__container-header">\n		<div class="b-order__container-header-label b-order-label">\n			<i class="g-icon-service g-icon-service--type-unknown" data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( reservation.travelSubject && reservation.travelSubject.caption )) == null ? '' : __t) +
'"></i>\n			';
 if (reservation.status.uid === 'ERROR') { ;
__p += '\n				<span class="b-order__info-error" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="<div class=\'b-car-place-tooltip\'><div class=\'tooltip__header\'>' +
((__t = ( L10N.get('settings.error') )) == null ? '' : __t) +
'</div><div class=\'tooltip__body\'>' +
((__t = ( L10N.get('settings.errorTechSupport') )) == null ? '' : __t) +
'</div>">' +
((__t = ( L10N.get('settings.error') )) == null ? '' : __t) +
'</span>\n			';
 } ;
__p += '\n			<div class="b-order-label__list">\n\n				<span class="b-order-label__item b-order-label__route">\n					<span class="b-order__route-location">' +
((__t = ( reservation.travelSubject && reservation.travelSubject.caption )) == null ? '' : __t) +
'</span>\n				</span>\n				<span class="b-order-label__item b-order-label__create-date">\n					' +
((__t = ( createdDate )) == null ? '' : __t) +
'\n				</span>\n\n				';
 if (reservation.prn != null) { ;
__p += '\n					<span class="b-order-label__item">\n						<span class="b-order-label__item-simple">' +
((__t = ( L10N.get('cabinet.orders.reservationPnr') )) == null ? '' : __t) +
':</span>\n						' +
((__t = ( reservation.prn )) == null ? '' : __t) +
'\n					</span>\n				';
 } ;
__p += '\n			</div>\n            ';
 if (reservation.incomplete) { ;
__p += '\n                <span class="missing-info-warning"\n                      data-toggle="tooltip"\n                      data-placement="bottom"\n                      data-html="true"\n                      data-original-title="<div>' +
((__t = ( L10N.get('cabinet.orders.incompleteReservationWarning') )) == null ? '' : __t) +
'</div>"\n                ></span>\n            ';
 } ;
__p += '\n		</div>\n		<div class="b-order__container-header-right">\n			';
 if (!_.isEmpty(reservation.price)) { ;
__p += '\n				<div class="b-order-price">\n					<span data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="' +
((__t = ( _.escape(require('@/blocks/elements/b-ticket/b-ticket-pricing.ejs')({ price: reservation._price || reservation.price })) )) == null ? '' : __t) +
'">\n						' +
((__t = ( Formatter.formatMoney(reservation.price.total.amount, Formatter.getDecimal(reservation.price.total.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(reservation.price.total.currencyCode) )) == null ? '' : __t) +
'\n					</span>\n				</div>\n			';
 } ;
__p += '\n			';
 if (!(reservation.status && reservation.status.uid === 'ERROR')) { ;
__p += '\n				';
 if (reservation.issueAllowed && sellReservationsCount > 1) { ;
__p += '\n					<span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "selectedReservation.' + index + '.selected"})\'></span>\n				';
 } ;
__p += '\n			';
 } ;
__p += '\n		</div>\n	</div>\n	<div class="b-order__container-content">\n		<table class="b-block-table">\n			<tr class="b-block-table__row b-block-table__row-header">\n				<th class="b-block-table__cell b-block-table__cell-empty"></th>\n				<th class="b-block-table__cell">' +
((__t = ( L10N.get('cabinet.orders.productNumber') )) == null ? '' : __t) +
'</th>\n				<th class="b-block-table__cell">' +
((__t = ( L10N.get('cabinet.orders.hotelFullName') )) == null ? '' : __t) +
'</th>\n				<th class="b-block-table__cell b-block-table__cell--width-auto">\n                    ' +
((__t = ( L10N.get('cabinet.orders.hotelStatus') )) == null ? '' : __t) +
'\n                </th>\n				<th class="b-block-table__cell"></th>\n			</tr>\n\n			';
 _.each(reservation.services, function(p) {
				if (!p) return;
				var passengers = p.travellers;
				var aclEditGranted = p.aclEditGranted;
			;
__p += '\n				<tr class="b-block-table__row" data-service-uid="' +
((__t = ( p.uid )) == null ? '' : __t) +
'" data-service-status="' +
((__t = ( p.status && p.status.uid )) == null ? '' : __t) +
'">\n					<td class="b-block-table__cell b-block-table__cell-empty"></td>\n\n					<td class="b-block-table__cell ' +
((__t = ( isBlurred(p) )) == null ? '' : __t) +
'">\n						<span class="b-order__info-ticket-number">\n							' +
((__t = ( (p.ticketNumber != null) ? p.ticketNumber : '一' )) == null ? '' : __t) +
'\n						</span>\n					</td>\n					<td class="b-block-table__cell b-block-table__cell-fullname ' +
((__t = ( isBlurred(p) )) == null ? '' : __t) +
'">\n						';
 _.each(passengers, function (passenger) { ;
__p += '\n							<div>' +
((__t = ( passenger && passenger.fullName || '-' )) == null ? '' : __t) +
'</div>\n						';
 }); ;
__p += '\n					</td>\n					<td class="b-block-table__cell">\n						<div class="b-order__info-status-container">\n                            <span class="b-order__info-status b-order__info-status--' +
((__t = ( p.status && p.status.uid )) == null ? '' : __t) +
'">\n                                ' +
((__t = ( p.status && p.status.caption )) == null ? '' : __t) +
'\n                            </span>\n                            ';
 if (useAuthSteps) { ;
__p += '\n                                <button\n                                    type="button"\n                                    class="b-order__show-detailed js-open-auth-steps-modal"\n                                    data-reservation-uid="' +
((__t = ( reservation.uid )) == null ? '' : __t) +
'"\n                                    data-service-uid="' +
((__t = ( p.uid )) == null ? '' : __t) +
'"\n                                >\n                                    ' +
((__t = ( L10N.get('cabinet.orders.showDetails') )) == null ? '' : __t) +
'\n                                </button>\n                            ';
 } ;
__p += '\n                        </div>\n					</td>\n                    <td class="b-block-table__cell b-block-table__cell-travel-policy">\n                        <div class="b-block-table__cell-content-container order-reservation__travel-policy-container b-order-reservation__travel-policy">\n                            ';
 if (p.travelPolicyCompliance && !_.isEmpty(p.travelPolicyCompliance.rulesApplied)) { ;
__p += '\n                              ' +
((__t = ( require('@/blocks/elements/b-hotel/b-hotel-order/b-hotel-info/b-travel-policy.ejs')({
                                travelPolicyCompliance: p.travelPolicyCompliance,
                              }) )) == null ? '' : __t) +
'\n                            ';
 } ;
__p += '\n                            ';
 if (p.isTripartiteDiscount) { ;
__p += '\n                                <span class="b-order-reservation__travel-policy--3D g-icon-features-3D-contract" data-toggle="tooltip" data-placement="bottom" data-title="' +
((__t = ( L10N.get('filters.tripartite'))) == null ? '' : __t) +
'"></span>\n                            ';
 } ;
__p += '\n                        </div>\n                    </td>\n				</tr>\n			';
 }); ;
__p += '\n			';
 if (showFooterOrderInfo === true) { ;
__p += '\n				<tr class="b-block-table__row b-order__info-footer">\n					<td class="b-block-table__cell b-block-table__cell-empty"></td>\n					<td colspan="2" class="b-block-table__cell"></td>\n					<td colspan="1" class="b-block-table__cell b-order__info-footer-right">\n						';
 if (['REFUND', 'PAYED', 'ISSUED'].indexOf(reservation.status.uid) !== -1) { ;
__p += '\n							<span class="b-order__info-footer-item b-route-receipt-link mobile-hide">\n								<span class="action-link">' +
((__t = ( L10N.get('cabinet.orders.attachedDocuments') )) == null ? '' : __t) +
'</span>\n								<i class="g-icon-download"></i>\n							</span>\n						';
 } ;
__p += '\n					</td>\n                    <td class="b-block-table__cell mobile-cell">\n                        <div class="b-order__info-footer-controls-mobile js-show-mobile-controls desktop-hide expand-only"></div>\n                        <div class="b-order__info-footer-controls-popup js-mobile-controls desktop-hide">\n                            <div class="b-order__info-footer-controls-mobile--mobile-btn" data-target-class=".b-route-receipt-link"></div>\n                        </div>\n                    </td>\n					<td class="b-block-table__cell b-block-table__cell-empty"></td>\n				</tr>\n			';
 } ;
__p += '\n		</table>\n	</div>\n</div>\n';

}
return __p
}