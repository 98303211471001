export interface TravelSubject {
	uid: TravelSubjectType;
	caption: string;
}

export enum TravelSubjectType {
	AIR = "AIR",
	INSURANCE_ACCIDENT = "INSURANCE_ACCIDENT",
	TRANSFER = "TRANSFER",
	TAXI = "TAXI",
	OTHER = "OTHER",
	AEROEXPRESS = "AEROEXPRESS",
	INSURANCE_TOURISTIC = "INSURANCE_TOURISTIC",
	HOTEL_RESERVATION = "HOTEL_RESERVATION",
	CAR_RENTAL = "CAR_RENTAL",
	VISA = "VISA",
	ADDITIONAL_SERVICE = "ADDITIONAL_SERVICE",
	RAILWAY = "RAILWAY",
	BUS = "BUS",
}
