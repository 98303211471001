'use strict';

import BaseModel from '@/classes/base.model';
import CostCodesModel from '@/blocks/elements/b-cost-codes/model';
import PassportModel from '@/blocks/elements/b-passport/model';
import BaseCollection from '@/classes/base.collection';

export default BaseModel.extend({

	defaults() {
		return {
			passport: new PassportModel(),
			costCodes: new BaseCollection([], {
				model: CostCodesModel,
			}),
		};
	},

	initialize() {
		if (!this.has('passport')) {
			this.set('passport', new BaseModel({
				citizenship: STATE.getSettings().generalSettings.defaultCitizenship,
				type: '',
				originCountry: STATE.getSettings().generalSettings.defaultCitizenship,
			}));
		}
		if (this.has('passport')) {
			this.set('phone', {
				cityCode: '',
				countryCode: STATE.getDefaultCountryCode(),
				number: '',
			});
		}
	},

	toJSON() {
		const data = BaseModel.prototype.toJSON.call(this);
		data.phone = data.passport.phone;
		data.email = data.passport.email;

		return data;
	},
});
