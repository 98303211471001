// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import 'corejs-typeahead/dist/typeahead.jquery';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';

export default Widget.extend({

	validationTemplate,

	template,

	events: {
		'change .p-login__form-input': 'change',
		'mousedown .b-password__show': 'showPassword',
		'mouseup .b-password__show': 'hidePassword',
		'mouseout .b-password__show': 'hidePassword',
	},

	ui: {
		input: '.p-login__form-input',
	},

	initialize() {
		this.options.autocomplete = this.options.autocomplete || null;
		this.options.elementId = this.options.elementId || null;
		this.options.elementName = this.options.elementName || null;
		this.options.placeholder = this.options.placeholder || L10N.get('AccountLoginForm.enterPassword');
		this.options.showPasswordButton = this.options.showPasswordButton === true;
		this.options.withLabel = this.options.withLabel != null ? this.options.withLabel : !STATE.checkSiteType('B2C');

		this.render(Object.assign({
			type: 'text',
		}, this.options));
	},

	change() {
		this.model.set(this.options.bindingProperty, this.ui.input.val());
	},

	applyBinding(model) {
		this.model = model;
		this.listenTo(model, `change:${this.options.bindingProperty}`, (thisModel, value) => {
			this.ui.input.val(value || '');
		});
		this.ui.input.val(model.get(this.options.bindingProperty));
	},

	showPassword() {
		this.ui.input.blur();
		this.ui.input.attr('type', 'text');
	},

	hidePassword() {
		this.ui.input.attr('type', this.options.type);
	},

});
