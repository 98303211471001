module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-range ' +
((__t = ( classes )) == null ? '' : __t) +
'">\r\n	<span class="b-range__label">' +
((__t = (label )) == null ? '' : __t) +
'</span>\r\n	<div class="b-range__input">\r\n		<div class="b-range__input-interective"></div>\r\n	</div>\r\n</div>\r\n';

}
return __p
}