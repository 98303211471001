// eslint-disable-next-line banned-modules
'use strict';

export function hasCommonCostCodes(organizationCostCodes = []) {
	/*
    пока считаем, что переданные при инициализации organizationCostCodes - это все
    возможные варианты костКодов.
  */
	if (!organizationCostCodes.length) return false;
	return organizationCostCodes.some((cc) => !!cc?.common);
}

/*
Игнорируем проверку, если common costcode заполнен и добавляем продукт в уже существующий заказа.
В этом случае мы disable common costcode, т.к. он должен быть "общим для заказа"
*/
export function hasOwnValueInCostCodes(travellerViewList = [], isAddProductToOrder = false) {
	let output = false;
	Object.keys(travellerViewList).forEach((key) => {
		if (!output) {
			const view = travellerViewList[key];
			if (view?.model?.get) {
				const costCodes = view.model.get('costCodes');
				if (costCodes?.length) {
					costCodes.each((costCodeModel) => {
						if (costCodeModel.get('value') && (costCodeModel.get('common') && !isAddProductToOrder)) {
							output = true;
						}
					});
				}
			}
		}
	});
	return output;
}

export function shouldHandleTravellers(
	travellerViewList = [],
	organizationCostCodes = [],
	isAddProductToOrder = false,
) {
	return (
		hasCommonCostCodes(organizationCostCodes) &&
		!hasOwnValueInCostCodes(travellerViewList, isAddProductToOrder)
	);
}
