// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import BaseModel from '@/classes/base.model';
import MultipleSelect from '@/widgets/b-multiple-select/index';
import TimeWidget from '@/widgets/w-time/index';
import template from './template.ejs';
import Formatter from '@/utils/formatter';

import $ from 'jquery';
import axios from 'axios';

export default BaseView.extend({

	template,

	events: {
		'click .js-reprice-warning-link': 'scrollToPayment',
	},

	ui: {
		widgetsContainer: '.b-hotel-additional-services__list > .l-grid-container',
		submitButton: '.b-hotel-additional-services__reprice',
	},

	initialize() {
		if (this.options.model != null) {
			this.model = new BaseModel(this.options.model.get('offer').additionalServices);
			this.options.model.set('additionalServices', this.model);
		} else {
			this.model = new BaseModel();
		}

		this.services = this.options.offer.options || {};
		this.baseView = this.options.baseView;
		this.options.showRoomInfo = this.options.showRoomInfo || false;

		this.render();

		if (!_.isEmpty(this.model.attributes)) {
			this.$el.find('.b-hotel-additional-services__total').show();
		}

		this.listenTo(this.model, 'change', () => {
			this.repriceOffer();
		});
	},

	render(...args) {
		BaseView.prototype.render.apply(this, args);
		const isMobile = STATE.checkViewport('(max-width: 768px)');

		const getWidget = (key, items) => {
			const widgetParams = {
				bindingProperty: key,
				label: this.getWidgetName(key, this.model.has(`${key}ByRequest`) && this.model.get(`${key}ByRequest`) === true),
			};

			let widget = null;
			if (['checkIn', 'checkOut'].includes(key)) {
				widget = new TimeWidget(widgetParams);

				let title = '';
				_.each(items, (item) => {
					if (item.guaranteed === true && item.from && item.to && item.percent) {
						title += `<div>${item.from} - ${item.to} ー <strong>${item.percent}%</strong></div>`;
					}
				});

				if (title) {
					title = `<div class="tooltip-options-check">
							<strong>${L10N.get('hotels.cost')}
							${(key === 'checkIn') ? L10N.get('hotels.earlyCheckIn') : L10N.get('hotels.lateCheckout')}
							</strong>
							 ${title}</div>`;
					const tooltipOptions = {
						container: 'body',
						html: true,
						title,
					};

					if (isMobile) {
						const widgetLabelIcon = widget.$el.find('.b-input__label');
						widgetLabelIcon.tooltip(tooltipOptions);
					} else {
						widget.$el.tooltip(tooltipOptions).on('mouseleave blur', () => widget.$el.tooltip('hide'));
					}
				}
			} else {
				widget = new MultipleSelect(_.extend({}, widgetParams, {
					removable: true,
					values: MultipleSelect.storeCollection(
						MultipleSelect.dictionaryToCollection(this.getWidgetCollection(items)),
					),
				}));
			}

			return widget;
		};

		const addWidgetElement = (widget) => {
			if (widget == null) {
				return;
			}

			widget.applyBinding(this.model);
			this.ui.widgetsContainer.append($('<div class="l-grid-layout-33"></div>').append(widget.$el));
		};

		if (!_.isEmpty(this.services.earlierCheckIn)) {
			const widget = getWidget('checkIn', this.services.earlierCheckIn);
			addWidgetElement(widget);
		}

		if (!_.isEmpty(this.services.laterCheckOut)) {
			const widget = getWidget('checkOut', this.services.laterCheckOut);
			addWidgetElement(widget);
		}

		if (!_.isEmpty(this.services.meals)) {
			const widget = getWidget('meals', this.services.meals);
			addWidgetElement(widget);
			widget.delegateEvents();
		}
	},

	getWidgetName(key, operation) {
		let result = key;

		switch (key) {
			case 'checkIn': result = L10N.get('hotels.addServices.earlyCheckIn'); break;
			case 'checkOut': result = L10N.get('hotels.addServices.lateCheckout'); break;
			case 'meals': result = L10N.get('hotels.meal'); break;
		}

		if (operation === true) {
			result += ` - ${L10N.get('hotels.byRequest')}`;
			result = `<span class="important">${result}</span>`;
		}

		return result;
	},

	getWidgetCollection(data) {
		return _.compact(_.map(data.slice(), (item) => {
			if (_.isObject(item) && item.uid != null && item.caption != null) {
				return _.pick(item, ['uid', 'caption']);
			}

			return null;
		}));
	},

	disableSubmitButton() {
		const submitButton = this.options.baseView.$el.find('.b-booking__submit');
		submitButton.addClass('btn-disabled').attr('disabled', 'disabled');
	},

	repriceOffer(e) {
		const parameters = {
			parameters: {
				rooms: this.baseView.model.get('rooms').toJSON(),
			},
		};

		STATE.showLoader();
		if (e) this.disableElements(e);
		this.disableSubmitButton();
		return axios.post('/midoffice/ibecorp-b2b/hotels/pricingWithFormSettings', parameters, this.baseView.model).then((response) => {
			const { result } = response.data;
			if (result != null) {
				$('.tooltip').remove();
				this.baseView.renderInfoView(result);
				if (this.options && this.options.baseView && this.options.baseView.reasonCodeKeyContanctView) {
					this.options.baseView.reasonCode = result.reasonCode;
					this.options.baseView.keyContact = result.keyContact;

					this.options.baseView.reasonCodeKeyContanctView.update({
						reasonCode: result.reasonCode,
						keyContact: result.keyContact,
					});
				}
				const {hotelCard = {} } = result;
				const {hotelOffers} = hotelCard;
				if (hotelOffers && hotelOffers.length === 1) {
					const offer = hotelOffers[0];
					this.$el.find('.b-hotel-additional-services__total')
						.html(`${Formatter.formatMoney(offer.price.total.amount, 0)} 
            ${Formatter.formatCurrency(offer.price.total.currencyCode)}`);
					this.$el.find('.b-hotel-additional-services__total').show();
				} else {
					this.$el.find('.b-hotel-additional-services__total').hide();
				}
			}
		}).finally(() => STATE.hideLoader());
	},

	scrollToPayment() {
		const scrollPos = this.baseView.$el.find('.b-booking__price-info').position();
		$(window).scrollTop(scrollPos.top);
	},
});
