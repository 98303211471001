module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	var segments = this.model.get('segments');
	var dateDeparture = new Time(segments[0].departureDate);
	var dateArrival = new Time(segments[0].arrivalDate);

	var dateDepartureLocal = segments[0].localDepartureDate ? new Time(segments[0].localDepartureDate) : null;
	var dateArrivalLocal = segments[0].localArrivalDate ? new Time(segments[0].localArrivalDate) : null;

	var totalDurationInMinutes = 0;
	segments.forEach(function(s) {
		totalDurationInMinutes += s.durationInMinutes;
	});
	var isRouteAvailable = this.model.get('isRouteAvailable')
;
__p += '\n\n<div class="b-train-ticket__wrapper b-train-ticket__visible ' +
((__t = ( (this.model.get('disabled') === true) ? 'disabled' : '' )) == null ? '' : __t) +
'">\n	<div class="b-train-ticket" data-number="' +
((__t = ( this.model.get('number') )) == null ? '' : __t) +
'">\n		';
 if (this.model.get('showGdsAccountName')) { ;
__p += '\n            <div class="b-train-ticket__gds-info">' +
((__t = ( gds.uid )) == null ? '' : __t) +
' - ' +
((__t = ( gdsAccountName )) == null ? '' : __t) +
'</div>\n        ';
 } ;
__p += '\n		<div class="b-train-ticket__header">\n			<div class="b-train-ticket__info-train b-info-train">\n				<span class="b-info-train__element">\n                    <span class="b-info-train__number">\n					№ ' +
((__t = ( this.model.get('number') )) == null ? '' : __t) +
'' +
((__t = ( !this.model.get('brand') && this.model.get('category') ? ',' : '' )) == null ? '' : __t) +
'\n				</span>\n                    ';
 if (this.model.get('brand') != null) { ;
__p += '\n                        &mdash;\n                        <span class="b-info-train__brand">\n						' +
((__t = ( this.model.get('brand') )) == null ? '' : __t) +
'' +
((__t = ( this.model.get('category') ? ',' : '' )) == null ? '' : __t) +
'\n					</span>\n                    ';
 } ;
__p += '\n                </span>\n                <span class="b-info-train__element">\n                    ';
 if (this.model.get('category')) { ;
__p += '\n                        <span class="b-info-train__description">\n						' +
((__t = ( this.model.get('category'))) == null ? '' : __t) +
'' +
((__t = ( ((segments[0].trainDepartureLocation && segments[0].trainArrivalLocation)) ? ',' : '' )) == null ? '' : __t) +
'\n					</span>\n                    ';
 } ;
__p += '\n                    ';
 if ((segments[0].trainDepartureLocation && segments[0].trainArrivalLocation)) { ;
__p += '\n                        <span class="b-info-train__description">\n						' +
((__t = ( segments[0].trainDepartureLocation )) == null ? '' : __t) +
' - ' +
((__t = ( segments[0].trainArrivalLocation )) == null ? '' : __t) +
'\n						</span>\n                    ';
 } ;
__p += '\n                </span>\n                ';
 if (isRouteAvailable === true) { ;
__p += '\n                    <div class="b-route__info-route desktop-hide">\n                        <span class="b-route__info-route-link">' +
((__t = ( L10N.get('trains.route') )) == null ? '' : __t) +
'</span>\n                    </div>\n                ';
 } ;
__p += '\n			</div>\n\n			';
 if (this.model.get('disabled') === true) { ;
__p += '\n				<div class="b-train-ticket__info-disabled">\n					<span class="b-train-ticket__info-disabled-icon"\n						  data-toggle="tooltip" data-placement="bottom"\n						  title="' +
((__t = ( STATE.escape(this.model.get('disableReason')) )) == null ? '' : __t) +
'"></span>\n				</div>\n			';
 } ;
__p += '\n			<div class="b-train-ticket__info-services train-services-gray mobile-hide">\n				';
 _.each(_.sortBy(this.model.get('services'), function (s) {
					return s.uid;
				}), function(service) { ;
__p += '\n					<div class="train-service__img train-service__img--color-gray train-service__' +
((__t = ( service.uid )) == null ? '' : __t) +
'" data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( STATE.escape(service.caption) )) == null ? '' : __t) +
'"></div>\n				';
 }); ;
__p += '\n			</div>\n			<div class="b-train-ticket__info-common">\n				';
 if (this.model.get('isERegister') === true) { ;
__p += '\n					<span class="b-train-ticket__info-common-tags b-train-ticket__tags">\n						<span class="red-tag" data-toggle="tooltip" data-html="true" data-placement="bottom" data-original-title="<div class=\'tooltip-left\'>' +
((__t = ( L10N.get('trains.eRegister') )) == null ? '' : __t) +
'</div>">' +
((__t = ( L10N.get('trains.eRegisterShort') )) == null ? '' : __t) +
'</span>\n					</span>\n				';
 } ;
__p += '\n			</div>\n		</div>\n        <div class="b-train-ticket__mobile desktop-hide">\n            <div class="b-train-ticket__mobile-dates">\n					<span class="b-train-ticket__mobile-date-time">\n						' +
((__t = ( dateDepartureLocal.toShortTime() )) == null ? '' : __t) +
'\n					</span>\n                <span class="b-train-ticket__mobile-duration">' +
((__t = ( Formatter.formatDurationFromPattern(totalDurationInMinutes) )) == null ? '' : __t) +
'</span>\n                <span class="b-train-ticket__mobile-date-time">\n						' +
((__t = ( dateArrivalLocal.toShortTime() )) == null ? '' : __t) +
'\n					</span>\n            </div>\n            <div class="b-train-ticket__mobile-route-line"></div>\n            <div class="b-train-ticket__mobile-route-info">\n                <span class="b-train-ticket__mobile-route-location">\n                    ';
 if (segments[0] && segments[0].departureLocation) { ;
__p += '\n                        ' +
((__t = ( segments[0].departureLocation.caption.trim() )) == null ? '' : __t) +
'\n                    ';
 } ;
__p += '\n                </span>\n                <span class="b-train-ticket__mobile-route-location">\n                    ';
 if (segments[0] && segments[0].arrivalLocation) { ;
__p += '\n                        ' +
((__t = ( segments[0].arrivalLocation.caption.trim() )) == null ? '' : __t) +
'\n                    ';
 } ;
__p += '\n                </span>\n            </div>\n        </div>\n        <div class="b-train-ticket__info-services train-services-gray desktop-hide">\n                ';
 _.each(_.sortBy(this.model.get('services'), function (s) {
                    return s.uid;
                }), function(service) { ;
__p += '\n                    <div class="train-service__img train-service__img--color-gray train-service__' +
((__t = ( service.uid )) == null ? '' : __t) +
'" data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( STATE.escape(service.caption) )) == null ? '' : __t) +
'"></div>\n                ';
 }); ;
__p += '\n            </div>\n		<div class="b-train-ticket__route b-route mobile-hide">\n			';

				var dateDepartureTooltip = "<div class='text-align-center'>" + L10N.get('trains.cardLocalInfo');
				if (segments[0].departureMoscowTimeZone === true) {
					dateDepartureTooltip += '<br />' + L10N.get('trains.moscowTime') + ' - <strong>' + dateDeparture.toShortTime() + ' ' + dateDeparture.toShortDate() + '</strong>';
				}
				dateDepartureTooltip += '</div>';

				var dateArrivalTooltip = "<div class='text-align-center'>" + L10N.get('trains.cardLocalInfo');
				if (segments[0].arrivalMoscowTimeZone === true) {
					dateArrivalTooltip += '<br />' + L10N.get('trains.moscowTime') + ' - <strong>' + dateArrival.toShortTime() + ' ' + dateArrival.toShortDate() + '</strong>';
				}
				dateArrivalTooltip += '</div>';
			;
__p += '\n\n			<div class="b-route__departure">\n				<div class="b-route__date" data-toggle="tooltip" data-html="true" data-placement="bottom" data-original-title="' +
((__t = ( dateDepartureTooltip )) == null ? '' : __t) +
'">\n					<div class="b-route__date-time">' +
((__t = ( (dateDepartureLocal || dateDeparture).toShortTime() )) == null ? '' : __t) +
'</div>\n					<div class="b-route__date-info">' +
((__t = ( (dateDepartureLocal || dateDeparture).toShortDate() )) == null ? '' : __t) +
'</div>\n				</div>\n				<div class="b-route__terminal">' +
((__t = ( segments[0].departureLocation.caption )) == null ? '' : __t) +
'</div>\n			</div>\n			<div class="b-route__info">\n				<div class="b-route__info-duration">' +
((__t = ( Formatter.formatDurationFromPattern(totalDurationInMinutes) )) == null ? '' : __t) +
'</div>\n				';
 if (isRouteAvailable === true) { ;
__p += '\n					<div class="b-route__info-route">\n						<span class="b-route__info-route-link">' +
((__t = ( L10N.get('trains.route') )) == null ? '' : __t) +
'</span>\n					</div>\n				';
 } ;
__p += '\n			</div>\n			<div class="b-route__arrival">\n				<div class="b-route__date">\n					<div class="b-route__date" data-toggle="tooltip" data-html="true" data-placement="bottom" data-original-title="' +
((__t = ( dateArrivalTooltip )) == null ? '' : __t) +
'">\n						<div class="b-route__date-time">' +
((__t = ( (dateArrivalLocal || dateArrival).toShortTime() )) == null ? '' : __t) +
'</div>\n						<div class="b-route__date-info">' +
((__t = ( (dateArrivalLocal || dateArrival).toShortDate() )) == null ? '' : __t) +
'</div>\n					</div>\n					';
 if (segments[0].carWithOtherArrivalTimeExists === true) { ;
__p += '\n						<div class="b-route__arrival-notification" data-toggle="tooltip" data-html="true" data-placement="bottom" data-original-title="<div class=\'text-align-center\'>' +
((__t = ( L10N.get('trains.carriageWithDiffTime') )) == null ? '' : __t) +
'</div>"></div>\n					';
 } ;
__p += '\n				</div>\n\n				<div class="b-route__terminal">' +
((__t = ( segments[0].arrivalLocation.caption )) == null ? '' : __t) +
'</div>\n			</div>\n\n			<!-- FOR 1920px -->\n			<div class="b-train-ticket__info-services train-services-gray">\n				';
 _.each(_.sortBy(this.model.get('services'), function (s) {
					return s.uid;
				}), function(service) { ;
__p += '\n					<div class="train-service__img train-service__img--color-gray train-service__' +
((__t = ( service.uid )) == null ? '' : __t) +
'" data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( STATE.escape(service.caption) )) == null ? '' : __t) +
'"></div>\n				';
 }); ;
__p += '\n			</div>\n			<div class="b-train-ticket__info-common">\n				';
 if (this.model.get('isERegister') === true) { ;
__p += '\n					<span class="b-train-ticket__info-common-tags b-train-ticket__tags">\n						<span class="red-tag" data-toggle="tooltip" data-html="true" data-placement="bottom" data-original-title="<div class=\'tooltip-left\'>' +
((__t = ( L10N.get('trains.eRegister') )) == null ? '' : __t) +
'</div>">' +
((__t = ( L10N.get('trains.eRegisterShort') )) == null ? '' : __t) +
'</span>\n					</span>\n				';
 } ;
__p += '\n			</div>\n			<!-- /FOR 1920px -->\n		</div>\n	</div>\n	<div class="b-train__carriage-seats"></div>\n	<div class="b-carriage__selected-variant"></div>\n</div>\n';

}
return __p
}