module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-order__container-content b-cabinet-order__notes">\n    <div class="b-order__container-title collapse--with-anchor mobile-hide" data-toggle="collapse" data-target=".toggle-order__notes-container">\n        ' +
((__t = ( L10N.get('cabinet.orders.addComment') )) == null ? '' : __t) +
'\n    </div>\n    <div class="b-order__container-title b-order__header collapse--with-anchor--white desktop-hide" data-toggle="collapse" data-target=".toggle-order__notes-container">\n        ' +
((__t = ( L10N.get('cabinet.orders.addComment') )) == null ? '' : __t) +
'\n    </div>\n\n    <div class="toggle-order__notes-container collapse show">\n        <div class="b-order__notes-container-issues b-order-issues">\n            <div class="b-order-issues__empty">\n                <em>' +
((__t = ( L10N.get('cabinet.orders.notes.loading') )) == null ? '' : __t) +
'</em>\n            </div>\n        </div>\n        <div class="b-order__notes-container js-empty-issues-template">\n            <div class="b-order-notes__drop-background mobile-hide">\n                ' +
((__t = ( L10N.get('cabinet.orders.dropFilesHere') )) == null ? '' : __t) +
'\n            </div>\n            <div style="width: 100%">\n                <span class="js-widget"  onclick=\'return new Widgets.Textarea({bindingProperty: "notes.default", placeholder: L10N.get("cabinet.orders.commentWithAttachment"), classes: "vertical-resize" })\'></span>\n                <div class="b-order__attachments-container js-attachments-container"></div>\n            </div>\n\n            <div class="b-order__notes-container-footer">\n                <button class="b-order__notes-attachment-btn js-notes-attachment-btn desktop-hide" type="button">\n                    <i class="g-icon-file desktop-hide"></i>\n                </button>\n                <button type="button" class="btn b-cabinet-order__notes-create btn-dark">\n                    <span class="mobile-hide">' +
((__t = ( L10N.get('cabinet.orders.save') )) == null ? '' : __t) +
'</span> <i class="g-icon-ok mobile-hide"></i>\n                    <i class="g-icon-send desktop-hide"></i>\n                </button>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
}