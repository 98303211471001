// eslint-disable-next-line banned-modules
'use strict';

import BaseView from '@/classes/base.view';
import Rules from '@/blocks/elements/b-fare-rules/index';
import template from './template.ejs';
import SelectWidget from '@/widgets/b-select/index';
import './style.less';

export default BaseView.extend({

	el: '.l-page__container',

	ui: {
		rules: '.b-payment__rules',
		offices: '.js-offices',
	},

	template,

	initialize() {
		const sabrePaymentCards = !_.isEmpty(this.options.checkBooking.paymentInfo.sabrePaymentType) &&
		!_.isEmpty(this.options.checkBooking.paymentInfo.sabrePaymentType.cards) ?
			this.options.checkBooking.paymentInfo.sabrePaymentType.cards : [];
		const sabrePaymentCardsCollection = SelectWidget.storeCollection(
			SelectWidget.dictionaryToCollection(this.getWidgetCollection(sabrePaymentCards)),
		);

		this.render.call(this, {
			offices: STATE.getSettings().fourthStepSettings.cashSalesOfficesDetails.reduce((result, item) => {
				if (!item || !item.city || !item.city.uid) {
					return result;
				}
				if (!result[item.city.uid]) {
					result[item.city.uid] = [];
				}
				result[item.city.uid].push(item);
				return result;
			}, {}),
			sabrePaymentCardsCollection,
			hasPaymentInHotel: this.options.hasPaymentInHotel,
		});

		const fromEmail = window.location.href.indexOf('pnr=') > 0;
		if (fromEmail) {
			this.ui.rules.append(new Rules({
				model: this.model.get('initiatePayment'),
			}).el);
		} else {
			this.model.get('initiatePayment').set('acceptFareRemarks', true);
			this.model.get('initiatePayment').set('acceptBookingRules', true);
			this.model.get('initiatePayment').set('acceptIataRules', true);
			this.model.get('initiatePayment').set('acceptAllRules', true);
		}

		this.listenTo(this.model.get('initiatePayment'), 'change:paymentType', (thisModel, value) => {
			this.ui.offices[value && value === 'CASH' ? 'show' : 'hide'](400);
		});
	},

	getWidgetCollection(data) {
		return _.compact(_.map(data.slice(), (item) => {
			return _.extend(item, {
				caption: `${item.cardNumber} ${item.cardHolder}`,
			});
		}));
	},

});
