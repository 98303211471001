// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BlockContainerView from '@/blocks/layout/b-block-container/index';

export default BlockContainerView.extend({

	initialize() {

	},

});
