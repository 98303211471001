// eslint-disable-next-line banned-modules
'use strict';

import BasePageView from '@/pages/base.page-view';
import CabinetMenu from '@/blocks/pages/b-cabinet/b-cabinet-menu/index';
import ProfileView from '@/blocks/pages/b-cabinet/b-cabinet-profile/index';
import axios from 'axios';

export default BasePageView.extend({

	initialize(options) {
		const { profileInfo } = options;
		const container = this.$el;
		this.render();

		container.append(this.$el);
		this.ui.sidebar.append(new CabinetMenu().$el);
		this.ui.content.append(new ProfileView({
			profileInfo,
		}).$el);
	},

}, {
	async load() {
		if (!STATE.getCountries()) {
			await STATE.loadCountries();
		}

		return new Promise((resolve, reject) => {
			axios.get('/midoffice/ibecorp-b2b/cabinet/getCabinetHolder').then((profileInfoResponse) => {
				STATE.login(profileInfoResponse.data.result.holder);
				resolve({
					profileInfo: profileInfoResponse.data.result,
				});
			}).catch(reject);
		});
	},
});
