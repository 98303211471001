// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import BookingModel from './model';
import RoomView from '@/blocks/elements/b-hotel/b-hotel-room/index';
import CustomerView from '@/blocks/elements/b-customer/b-customer-hotel/index';
import template from './template.ejs';
import Rules from '@/blocks/elements/b-fare-rules/index';
import ReasonCodeKeyContactView from './b-reason-code-key-contact/index';
import TravellersCostCodesHandler from '@/blocks/utils/b-travellers-cost-codes-handler';
import { shouldHandleTravellers } from '@/blocks/utils/b-travellers-cost-codes-handler/utils';
import BookingDuplicatesHandler from '@/blocks/utils/b-booking-duplicates-handler';
import axios from 'axios';
import ConfirmationModeHandler from '../../../utils/b-cofirmation-mode-handler';
import L10N from '../../../../utils/L10N';
import ErrorsUtils from '../../../../utils/errors-utils';

export default BaseView.extend({
	template,

	el: '.l-page__container',

	events: {
		'click .b-booking__submit': 'submitBooking',
		'click .b-booking__back': 'back',
	},

	ui: {
		roomsContainer: '.b-booking-hotels__rooms',
		commentContainer: '.b-booking-hotels__notes',
		customerContainer: '.b-booking__customer',
		rules: '.b-booking__rules',
		submitButton: '.b-booking__submit',
		reasonCodeKeyContactContainer: '.b-reason-code-key-contact',
	},

	roomViewList: [],

	preValidateDeferred: null,

	preinitialize(options) {
		BaseView.prototype.preinitialize.call(this, options);
		this.windowEventListenerList.push({
			mediaQuery: '(max-width: 768px)',
			name: 'change',
			callback: this.adjustMobileTemplate.bind(this),
			isMatchMedia: true,
		});
	},

	initialize() {
		const { order } = this.options.bookingSettings || {};

		this.keyContact = this.options.bookingSettings.keyContact;
		this.reasonCode = this.options.bookingSettings.reasonCode;
		this.externalSystems = this.options.bookingSettings.externalSystems;

		this.addPreValidateDeferred = this.addPreValidateDeferred.bind(this);
		this.removePreValidateDeferred = this.removePreValidateDeferred.bind(this);

		this.forbidTravellerUpdate =
			this.options.bookingSettings.forbidTravellerUpdate;

		if (order != null) {
			this.order = order;
			STATE.layout.header.setAdditionalOrder(order);
		}

		this.hotel = this.options.bookingSettings.hotelCard;
		this.model = new BookingModel();
		this.isIntention = this.options.isIntention;

		this.reasonCodeKeyContanctView = new ReasonCodeKeyContactView({
			model: this.model,
			reasonCode: this.reasonCode,
			keyContact: this.keyContact,
		});

		this.bookingDuplicatesHandler = new BookingDuplicatesHandler({ bookingSettings: this.options.bookingSettings });
    
		this.render();
		this.addEventListeners();

		this.confirmationModeHandler = new ConfirmationModeHandler();

		if (this.options.bookingSettings?.searchDuplicatesMessage) {
			this.bookingDuplicatesHandler.showSearchDuplicatesPopup(
				{
					...this.options.bookingSettings.searchDuplicatesMessage,
					onCancel: () => this.back(),
				},
			);
		}
	},

	updateData() {
		const { reasonCode, keyContact, hotelCard, forbidTravellerUpdate } =
			this.options.bookingSettings;
		this.hotel = hotelCard;
		this.reasonCode = reasonCode;
		this.keyContact = keyContact;
		this.forbidTravellerUpdate = forbidTravellerUpdate;

		// update rooms list offers;
		const rooms = this.model.get('rooms');
		_.each(this.hotel.hotelOffers, (o, i) => {
			const roomModel = rooms.at(i);
			if (roomModel && roomModel.get('offer')) {
				roomModel.set('offer', o);
			}
			if (this.roomViewList[i] && this.roomViewList[i].model) {
				this.roomViewList[i].model.set('offer', o);
			}
		});

		// check disabled
		const bookingDisabledByTP = _.some(this.hotel.hotelOffers, (o) => {
			return (
				o.travelPolicyCompliance &&
				_.some(
					o.travelPolicyCompliance.rulesApplied,
					(el) => el.action.uid === 'HIDE',
				)
			);
		});
		this.bookingDisabledByTP = bookingDisabledByTP;

		this.reasonCodeKeyContanctView.update({
			reasonCode: this.reasonCode,
			keyContact: this.keyContact,
		});

		this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));
		if (bookingDisabledByTP) {
			const popup = new Widgets.Popup({
				content: L10N.get('bookingForm.bookingNotAllowedByTP'),
				type: 'danger',
				actions: [
					{
						label: L10N.get('Common.close'),
						action: () => {
							popup.hide();
						},
					},
				],
				onClose: () => {
					popup.hide();
				},
			});
			popup.show();
			this.ui.submitButton.attr('disabled', true).addClass('btn-disabled');
		} else {
			this.ui.submitButton.attr('disabled', false).removeClass('btn-disabled');
		}
	},

	renderInfoView(options) {
		this.options.parent.renderInfoView(options);
	},

	addEventListeners() {
		const handler = this.validateAsync.bind(this);
		this.listenTo(
			this.model.get('customer'),
			'change',
			handler,
		);
		this.listenTo(this.model, 'change', handler);
	},

	delegateEvents(...args) {
		BaseView.prototype.delegateEvents.apply(this, args);
	},

	undelegateEvents(...args) {
		BaseView.prototype.undelegateEvents.apply(this, args);
	},

	adjustMobileTemplate(matches) {
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			if (_.isObject(matches)) matches = matches.matches;

			if (matches) {
				this.$('.js-price-info-container').html(this.options.hotelInfoView.$el);
			} else {
				this.options.parent.ui.sidebar.html(this.options.hotelInfoView.$el);
			}

			_.each(this.roomViewList, (v) => v && v.adjustMobileTemplate(matches));
		}, 100);
	},

	async validateAsync() {
		if (_.isFunction(this.cancelValidateRequest)) {
			this.cancelValidateRequest();
		}
		if (this.preValidateDeferred != null) {
			return this.preValidateDeferred.promise.then(() => {
				this.removePreValidateDeferred();
				return this.validate();
			}).catch((e) => {
				if (e !== 'Cancel') {
					throw e;
				}
			});
		}
		return this.validate();
	},

	validate() {
		this.hideValidations();

		const isSearchDuplicatesMessage = this.options.bookingSettings?.searchDuplicatesMessage;
		const CancelToken = axios.CancelToken;
		const parameters = this.getBookingRequestObj(false); // <- здесь объект валидации должен быть как в формате для /createIntention запроса
    
		// skip validation is not accepted
		if (isSearchDuplicatesMessage && !parameters.parameters.acceptSearchDuplicatesMessageId) {
			return Promise.resolve({});
		}

		if (_.isFunction(this.cancelValidateRequest)) {
			this.cancelValidateRequest();
		}

		this.hideValidations();
		return axios
			.post(
				'/midoffice/ibecorp-b2b/hotels/createIntention/validate',
				parameters,
				this.model,
				{
					cancelToken: new CancelToken((c) => {
						this.cancelValidateRequest = c;
					}),
				},
			)
			.then((response) => {
				const { result } = response.data;
				const disabled = result && !_.isEmpty(result.validatedParameters);

				if (disabled) {
					this.ui.submitButton.attr('disabled', true).addClass('btn-disabled');
				} else {
					if (!this.bookingDisabledByTP) {
						this.ui.submitButton
							.attr('disabled', false)
							.removeClass('btn-disabled'); 
					}
				}

				return response;
			})
			.catch((e) => {
				this.ui.submitButton.attr('disabled', true).addClass('btn-disabled');
				if (!ErrorsUtils.isUserError(e)) {
					throw e;
				}
			}).finally(() => {
				this.cancelValidateRequest = null;
			});
	},

	render(...args) {
		this.showCommentBlock = _.some(
			this.hotel.hotelOffers,
			(o) => o.commentAllowed === true,
		);

		BaseView.prototype.render.apply(this, args);

		// Customer info
		const { customer, contactFillingType, contactPersonEditGranted, contactPersonDisableFreeTextInput } =
			this.options.bookingSettings || {};

		if (customer != null) {
			const BaseModel = this.model.get('customer').constructor;
			this.model.set(
				'customer',
				new BaseModel(
					_.extend({}, customer, {
						disabled: true,
					}),
				),
			);
		}

		// Rooms
		this.ui.roomsContainer.empty();
		const offersCount = this.hotel.hotelOffers.length;
		_.each(this.hotel.hotelOffers, (offer, index) => {
			const roomView = new RoomView({
				offersCount,
				index,
				offer,
				offers: this.hotel.hotelOffers,
				forbidTravellerUpdate: this.forbidTravellerUpdate,
				bookingSettings: _.omit(
					this.options.bookingSettings || {},
					'hotelCard',
				),
				travellersInfo:
					this.options.bookingSettings.hotelCard.hotelOffers[0]
						.travellersInfo || [],
				collection: this.model.get('rooms'),
				parent: this,
			});
			this.ui.roomsContainer.append(roomView.$el);
			this.roomViewList.push(roomView);
		});

		// проверяем и инициализиуем обработчик common костКодов пассажиров
		this.checkNInitTravellersCostCodesHandler();

		this.customerView = new CustomerView({
			contactFillingType,
			contactPersonEditGranted,
			contactPersonDisableFreeTextInput,
			travellersModel: this.model.get('rooms').at(0).get('travellers'),
			contactAutocompleteAvailable:
				this.options.bookingSettings.contactAutocompleteAvailable,
			model: this.model.get('customer'),
			langRegistrationFullName:
				this.options.bookingSettings.langRegistrationFullName,
		});
		this.ui.customerContainer.html(this.customerView.$el);

		this.ui.reasonCodeKeyContactContainer.html(
			this.reasonCodeKeyContanctView.$el,
		);

		this.reasonCodeKeyContanctView.update({
			reasonCode: this.reasonCode,
			keyContact: this.keyContact,
		});

		const rules = new Rules({
			model: this.model,
			travelSubjectUid: 'HOTEL',
		});

		this.ui.rules.html(rules.el);

		this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));
	},

	checkNInitTravellersCostCodesHandler() {
		const {bookingSettings = {}} = this.options;
		const {organizationCostCodes, order = {}} = bookingSettings;
		if (organizationCostCodes?.length && this.roomViewList.length) {
			const travellerViewsList = [];
			this.roomViewList.forEach((room) => {
				if (room?.travellerViewList?.length) {
					room.travellerViewList.forEach((roomTraveller) => travellerViewsList.push(roomTraveller));
				}
			});
			const { bookingUid } = order;
			const isAddProductToOrder = !!bookingUid;
			if (travellerViewsList.length > 1
				&& shouldHandleTravellers(travellerViewsList, organizationCostCodes, isAddProductToOrder)) {
				this.travellersCostCodesHandler = new TravellersCostCodesHandler({
					disableAllFilledCommons: isAddProductToOrder,
				});

				// добавляем в обработчик вьюшки путешественников и их модели
				travellerViewsList.forEach((view) => {
					this.travellersCostCodesHandler.addTravellerView(view);
				});		
			}
		}
	},

	getBookingRequestObj(isIntention = false, additionalParameters = {}) {
		let parameters = this.model.toJSON();
		if (isIntention) {
			parameters = {
				parameters: {
					reservationUid: this.options.bookingSettings.reservation.uid,
					currentBooking: this.options.bookingSettings.currentBooking,
					hotelData: {
						createParameters: {
							...this.model.toJSON().parameters,
							...this.bookingDuplicatesHandler.getBookingDataObj(),
						},
					},
				},
			};
		} else {
			parameters.parameters = {
				...parameters.parameters,
				...this.bookingDuplicatesHandler.getBookingDataObj(),
			};
		}
		parameters.parameters = {...parameters.parameters, ...additionalParameters};
		return parameters;
	},

	// EVENTS
	async submitBooking(e, additionalParameters = {}) {
		if (e != null) {
			e.preventDefault();
		}
		
		await this.validateAsync();
		this.ui.submitButton.attr('disabled', true).addClass('btn-disabled');
		STATE.setBookingInProcess(true);
		STATE.showLoader();

		this.disableElements(e);

		const parameters = this.getBookingRequestObj(this.isIntention, additionalParameters);

		if (!this.isIntention) {
			_.each(_.keys(parameters.parameters), (key) => { 
				const params = parameters.parameters;
				if (key.indexOf('externalSystem') !== -1) {
					if (params.externalSystemCodes !== undefined) {
						params.externalSystemCodes.push({uid: key.split('__')[1], code: params[key]});
					} else {
						params.externalSystemCodes = [{uid: key.split('__')[1], code: params[key]}];
					}
					delete params[key];
				}
			});
		}
		
		const rest = this.isIntention
			? `/midoffice/ibecorp-b2b/booking/approveIntention`
			: `/midoffice/ibecorp-b2b/hotels/createIntention`;

		axios
			.post(rest, parameters, this.model, { enableCashing: true })
			.then((response) => {
				const { result } = response.data;

				const resultObjToUse = this.isIntention ? result.hotelBookingResult : result;
				const confirmationModeChanged = result.confirmationModeChanged === true;

				if (confirmationModeChanged) {
					this.confirmationModeHandler.popupHandler.showPopup({
						title: L10N.get('confirmationMode.defaultTitle'),
						text: L10N.get('confirmationMode.defaultText'),
						cancelBtnText: L10N.get('confirmationMode.backToSearch'),
						okBtnText: L10N.get('confirmationMode.doBooking'),
						onOk: () => this.submitBooking(e, {confirmationModeChangeAccepted: true}),
						onCancel: () => this.back(),
					});
					return result;
				} else if (this.bookingDuplicatesHandler.shouldShowProductDuplicatesPopup(resultObjToUse)) {
					this.bookingDuplicatesHandler.showProductDuplicatesPopup({
						...resultObjToUse,
						onOk: () => this.submitBooking(),
						onCancel: () => this.back(),
					});
					return result;
				}

				return this.submitBookingCallback(response);
			})
			.catch((ex) => {
				STATE.hideLoader();
				this.ui.submitButton
					.attr('disabled', false)
					.removeClass('btn-disabled');
				throw ex;
			})
			.finally(() => {
				this.ui.submitButton
					.attr('disabled', false)
					.removeClass('btn-disabled');
				STATE.hideLoader();
			});
	},

	submitBookingCallback(response) {
		const { result } = response.data;
		// booking and hotelBookingResult for booking and booking from intention
		if (result.booking != null || result.hotelBookingResult != null) {
			const newUid = this.isIntention
				? result.hotelBookingResult.booking.uid
				: result.booking.uid;

			STORE.set(STATE.ROUTES.CABINET_ORDER, {
				orderUid: newUid,
			});

			if (result.skipGetBookingInfo) {
				const popup = Widgets.Popup({
					content: result.messages.length && result.messages.join('</br>'),
					type: 'info',
					actions: [
						{
							label: L10N.get('bookingForm.dupesPopup.back'),
							action: () => {
								window.location.href = STATE.getPreviousLocation().href;
								popup.hide();
							},
						},
					],
				});
				popup.show();
				return this;
			}

			if (STATE.getQueryVariable('pnr') != null) {
				window.location.href = `${STATE.getRootUrl()}#${
					STATE.ROUTES.CABINET_ORDER
				}/${newUid}`;
			} else {
				STATE.navigate([STATE.ROUTES.CABINET_ORDER, newUid].join('/'));
			}
		}
		return this;
	},

	back(e) {
		if (e != null) {
			e.preventDefault();
		}

		STATE.navigate(STATE.ROUTES.HOTELS_OFFERS);
	},

	/* preValidateDeferred handlers */

	addPreValidateDeferred(deferred) {
		this.cancelPreValidateDeferred();
		this.preValidateDeferred = deferred;
	},

	removePreValidateDeferred() {
		this.preValidateDeferred = null;
	},

	cancelPreValidateDeferred() {
		if (this.preValidateDeferred) this.preValidateDeferred.reject('Cancel');
		this.removePreValidateDeferred();
	},

	/* EOF preValidateDeferred handlers */

	clearTravellersCostCodesHandler() {
		if (this.travellersCostCodesHandler) {
			this.travellersCostCodesHandler.clear();
		}
	},

	remove() {
		this.clearTravellersCostCodesHandler();
		this.cancelPreValidateDeferred();
		BaseView.prototype.remove.call(this);
	},

});
