// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

import $ from 'jquery';

export default BaseView.extend({

	template,

	events: {
		'click .b-datepicker-modal__close-button': 'hide',
		'click .b-datepicker-modal__one-way-button': 'backWithoutReturnDate',
	},

	ui: {
		controlsContainer: '.b-datepicker-modal__controls',
		pikadayContainer: '.js-pikaday-container',
		oneWayButton: '.b-datepicker-modal__one-way-button',
		title: '.b-datepicker-modal__title',
	},

	initialize(options) {
		this.model = options.model;

		this.render();
		this.createControls();
		this.$el.appendTo($('body'));
	},

	createControls() {
		this.classToSetShowFlag = this.options.classToSetShowFlag || '.l-layout';
		const {travelSubject, searchModel} = this.options;
		const options = {
			numberOfMonths: 1,
			bound: false,
			container: this.ui.pikadayContainer.get(0),
			keyboardInput: false,
			showDaysInNextAndPreviousMonths: false,
		};
		this.dateTo = null;
		this.dateFrom = null;

		if (travelSubject.uid === 'HOTEL') {
			this.dateTo = new Widgets.Datepicker({
				bindingProperty: 'checkInDate',
				showDependentDate: true,
				dependent: 'checkOutDate',
				dateFormat: 'DD MMM',
				placeholder: L10N.get('searchForm.arrivalDate'),
				...options,
			});
			this.dateFrom = new Widgets.Datepicker({
				bindingProperty: 'checkOutDate',
				showDependDate: true,
				dateFormat: 'DD MMM',
				depend: 'checkInDate',
				dependOffset: '1 d',
				removable: true,
				placeholder: L10N.get('searchForm.dateOfDeparture'),
				...options,
			});
		} else if (travelSubject.uid === 'TRANSFER') {
			this.dateTo = new Widgets.Datepicker({
				bindingProperty: 'date',
				showDependentDate: true,
				dependent: 'backDate',
				dateFormat: 'DD MMM',
				placeholder: L10N.get('transfers.fromDate'),
				...options,
			});
			this.dateFrom = new Widgets.Datepicker({
				bindingProperty: 'backDate',
				showDependDate: true,
				dateFormat: 'DD MMM',
				removable: true,
				depend: 'date',
				diff: 7,
				placeholder: L10N.get('transfers.backDate'),
				classes: 'backdate-from-datepicker',
				...options,
			});
		} else {
			if (travelSubject.uid === 'AVIA' && searchModel.get('isComplex')) {
				this.dateTo = new Widgets.Datepicker({
					bindingProperty: 'dateTo',
					dateFormat: 'DD MMM YYYY',
					placeholder: L10N.get('searchForm.departureDateComplexFlight'),
					...options,
				});
				this.$('.dateFrom').remove();
			} else {
				this.dateTo = new Widgets.Datepicker({
					bindingProperty: 'dateTo',
					showDependentDate: true,
					dependent: 'dateFrom',
					dateFormat: 'DD MMM',
					placeholder: L10N.get('searchForm.placeholderThereDate'),
					...options,
				});
				this.dateFrom = new Widgets.Datepicker({
					bindingProperty: 'dateFrom',
					showDependDate: true,
					dateFormat: 'DD MMM',
					depend: 'dateTo',
					dependTime: 'timeTo',
					diff: 7,
					removable: true,
					placeholder: L10N.get('searchForm.placeholderBackDate'),
					...options,
				});
			}
		}

		this.dateTo.applyBinding(this.model);
		this.dateTo.ui.input.on('focus', () => {
			this.ui.title.html(this.dateTo.options.placeholder);
			this.ui.pikadayContainer.removeClass('show-date-from');
			this.ui.pikadayContainer.addClass('show-date-to');
			this.dateTo.ui.input.parent().addClass('active');
			if (this.dateFrom != null) {
				this.dateFrom.ui.input.parent().removeClass('active');
			}
		});
		this.ui.controlsContainer.find('.dateTo').append(this.dateTo.$el);

		if (this.dateFrom != null) {
			if (this.ui.oneWayButton != null) this.ui.oneWayButton.show();
			this.dateFrom.applyBinding(this.model);
			this.listenTo(this.model, `change:${this.dateTo.options.bindingProperty}`, () => this.dateFrom.ui.input.focus());
			this.listenTo(this.model, `change:${this.dateFrom.options.bindingProperty}`, () => this.hide());
			this.dateFrom.ui.input.on('focus', () => {
				this.ui.title.html(this.dateFrom.options.placeholder);
				this.ui.pikadayContainer.removeClass('show-date-to');
				this.ui.pikadayContainer.addClass('show-date-from');
				this.dateTo.ui.input.parent().removeClass('active');
				this.dateFrom.ui.input.parent().addClass('active');
			});
			this.ui.controlsContainer.find('.dateFrom').append(this.dateFrom.$el);
		} else {
			this.listenTo(this.model, `change:${this.dateTo.options.bindingProperty}`, () => {
				if (searchModel.get('isComplex')) {
					if (this.dateTo.options.bindingProperty !== 'dateTo') {
						return;
					}

					const routes = this.dateTo.getSearchRoutesModel();
					const index = _.findIndex(routes, (r) => r === this.model);

					if (index <= 0) {
						return;
					}

					const lastRoute = routes[index - 1];

					if (lastRoute.get('dateTo')) {
						const _minDate = new Time(lastRoute.get('dateTo'))
							.getMoment()
							.add(0, 'd')
							.toDate();
						this.dateTo.picker.setMinDate(_minDate);
					}
				}

				this.hide();
			});
		}
	},

	backWithoutReturnDate() {
		this.model.set(this.dateFrom.options.bindingProperty, null);
		this.hide();
	},

	show(isShowDateTo) {
		this.$el.show();
		/*
			Т.к. мобильный календарь рендерится и открывается сразу и потом мы просто показываем/скрываем его
			с помощью this.show()/this.hide(), вместо стандартного метода API onOpen(), нам приходится
			"листать" календарь до нужной даты здесь.
		*/
		if (isShowDateTo) {
			this.dateTo?.picker?.gotoDate?.(this.dateTo?.picker?.getDate?.());
		} else {
			this.dateFrom?.picker?.gotoDate?.(this.dateFrom?.picker?.getDate?.());
		}
	},

	hide() {
		$(this.classToSetShowFlag).removeClass('show-pikaday-modal');
		this.$el.hide();
	},
});
