import React, { useCallback, useContext, useRef } from "react";
import StatisticsPopupContainer from "./StatisticsPopupContainer";
import { ReservationType } from "../../../../../helpers/interfaces/Reservation";
import ReactDialog, {
	ReactDialogApi,
} from "../../../../../components/ReactDialog/ReactDialog";
import { ReactAppGlobalContext } from "../../../../../../js/utils/react-utils";
import { PopupButton } from "./styled";

interface ReservationStatisticsPopupProps {
	reservation: ReservationType;
}

const ReservationStatisticsPopup: React.FC<ReservationStatisticsPopupProps> = (
	props
) => {
	const { reservation } = props;

	const { L10N } = useContext(ReactAppGlobalContext);

	const dialogRef = useRef<ReactDialogApi>(null);

	const closePopup = useCallback(() => {
		if (dialogRef.current) {
			dialogRef.current.hide();
		}
	}, []);

	const showPopup = useCallback(() => {
		if (dialogRef.current) {
			dialogRef.current.show();
		}
	}, []);

	return (
		<>
			<ReactDialog ref={dialogRef}>
				{reservation && (
					<StatisticsPopupContainer
						closePopup={closePopup}
						reservation={reservation}
					/>
				)}
			</ReactDialog>
			<PopupButton className="b-order__info-footer-button" onClick={showPopup}>
				{L10N.get("cabinet.statistics.buttonText")}
			</PopupButton>
		</>
	);
};

export default ReservationStatisticsPopup;
