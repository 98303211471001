// eslint-disable-next-line banned-modules
'use strict';

import BasePageView from '@/pages/base.page-view';
import CabinetMenu from '@/blocks/pages/b-cabinet/b-cabinet-menu/index';
import PassengerView from '@/blocks/pages/b-cabinet/b-cabinet-passengers/b-cabinet-passenger/index';

import axios from 'axios';

export default BasePageView.extend({

	initialize(options) {
		const { passengerInfo } = options;
		const container = this.$el;
		this.render();

		container.append(this.$el);
		this.ui.sidebar.append(new CabinetMenu().$el);
		this.ui.content.append(new PassengerView({
			passengerInfo,
		}).$el);
	},

}, {
	async load(passengerRef) {
		if (!STATE.getCountries()) {
			await STATE.loadCountries();
		}

		if (!passengerRef || passengerRef === 'new') {
			return Promise.resolve({});
		}

		return new Promise((resolve, reject) => {
			axios.post('/midoffice/ibecorp-b2b/cabinet/getPassengerInfo', {
				parameters: {
					passengerRef,
				},
			}).then((passengerInfoResponse) => {
				resolve({
					passengerInfo: passengerInfoResponse.data.result,
				});
			}).catch(reject);
		});
	},
});
