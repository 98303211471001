// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';
import templateHotels from './template-hotels.ejs';
import templateAvia from './template-avia.ejs';
import templateTransfers from './template-transfers.ejs';
import SelectWidget from '@/widgets/b-select/';

import $ from 'jquery';

export default BaseView.extend({

	className: '_search-flight-leg',

	events: {
		'click .b-search-route__remove': 'delete',
		'click .p-search__direction-swap': 'swapLocations',
		'click .b-search-route-address__swap': 'swapSiblingSubAddresses',
	},

	initialize(options) {
		this.searchModel = options.searchModel;
		this.parent = options.parent;
		this.render();
		this.model.on('change:dateTo', () => {
			const dateTo = new Time(this.model.get('dateTo'));
			this.model.trigger('setMinDate:dateFrom', dateTo.getMoment().toDate());
		});
		this.model.on('change:date', () => {
			const date = new Time(this.model.get('date'));
			this.model.trigger('setMinDate:backDate', date.getMoment().toDate());
		});
		// this.model.on('change:transferType', (model, value) => {
		// 	const $addAddresses = this.parent.$el.find('.b-search-route-address__collection__add');
		// 	const $addressCollection = this.parent.$el.find('.b-search-route-address__collection');
		// 	const $swap = this.parent.$el.find('.p-search__direction-swap');
		// 	const $timePicker = this.$el.find('.js-show-time-picker');
		// 	const $datepickerTo = this.parent.$el.find('.pikaday-modal_show-date-to').find('.p-search__input');
		// 	const $datepickerFrom = this.parent.$el.find('.pikaday-modal_show-date-from').find('.p-search__input');
		// 	if (value && value.uid === 'RENT') {
		// 		$timePicker.show();
		// 		$addAddresses.hide();
		// 		$addressCollection.hide();
		// 		$swap.hide();
		// 		$datepickerTo.attr('placeholder', L10N.get('transfers.startRentDate'));
		// 		$datepickerFrom.attr('placeholder', L10N.get('transfers.endRentDate'));
		// 	} else {
		// 		$timePicker.hide();
		// 		$addAddresses.show();
		// 		$addressCollection.show();
		// 		$swap.show();
		// 		$datepickerTo.attr('placeholder', L10N.get('transfers.fromDate'));
		// 		$datepickerFrom.attr('placeholder', L10N.get('transfers.backDate'));
		// 	}
		// });
	},

	swapLocations(e) {
		if (!e) return;
		const routeCid = this.$(e.currentTarget).data('routeCid');
		this.searchModel.swapLocations(routeCid);
	},

	swapSiblingSubAddresses(e) {
		if (e != null) {
			const el = $(e.target).closest('.b-search-route-address__collection-element');
			const prev = el.prev('.b-search-route-address__collection-element');
			this.searchModel.swapSiblingSubAddresses([el.attr('data-cid'), prev.attr('data-cid')]);
		}
	},

	delete() {
		this.model.destroy();

		const $target = $('.p-search-form__add');
		if (this.searchModel.get('routes').length < 6 && $target.hasClass('disabled')) {
			if ($target.attr('data-origin-title')) {
				$target
					.removeClass('disabled')
					.children('a')
					.text($target.attr('data-origin-title'));
			}
		}
	},

	render() {
		let tpl = template;
		if (this.searchModel.get('serviceTypes').find({uid: 'HOTEL'}) != null) {
			// TODO: need refactoring after combined search will enabled
			this.model = this.searchModel;
			tpl = templateHotels;

			this.listenTo(this.model, 'change:checkInDate', (model) => {
				if (model.get('checkOutDate') == null) {
					this.$el.find('.checkoutdate-from-datepicker .p-search__input').trigger('click');
				} else {
					const checkOutDate = new Time(model.get('checkOutDate'));
					const checkInDate = new Time(model.get('checkInDate'));

					if (checkOutDate.getMoment().isBefore(checkInDate.getMoment()) || checkOutDate.getMoment().isSame(checkInDate.getMoment())) {
						model.set('checkOutDate', null);
						this.$el.find('.checkoutdate-from-datepicker .p-search__input').trigger('click');
					}
				}
			});
		} else if (this.searchModel.get('serviceTypes').find({uid: 'AVIA'}) != null) {
			tpl = templateAvia;
		} else if (this.searchModel.get('serviceTypes').find({uid: 'TRANSFER'}) != null) {
			const corporateSettings = STATE.getSettings().corporateSettings;
			tpl = templateTransfers;
			const route = this.model;
			this.model = this.searchModel;
			const availableTransferType = (corporateSettings && corporateSettings.availableTransferType) || [{uid: 'TRANSFER', caption: 'Трансфер'}];
			const date = route.get('checkInDate') || route.get('dateTo');
			const dateBack = route.get('checkOutDate') || route.get('dateFrom');

			this.model.set('date', date);
			this.model.set('dateBack', dateBack);
			if (this.model.get('transferType') == null) this.model.set('transferType', _.find(availableTransferType, (item) => item.uid === 'TRANSFER'));
			this.setElement(tpl.call(this, {
				classes: this.options.classes,
				cid: this.model.cid,
				type: this.searchModel.get('routeType'),
				searchModel: this.searchModel,
				transferTypes: SelectWidget.storeCollection(
					SelectWidget.dictionaryToCollection(availableTransferType),
				),
				transferType: this.model.get('transferType'),
			}));
			return this;
		}

		this.setElement(tpl.call(this, {
			classes: this.options.classes,
			cid: this.model.cid,
			type: this.searchModel.get('routeType'),
			searchModel: this.searchModel,
		}));
		return this;
	},

});
