// eslint-disable-next-line banned-modules
'use strict';

import BasePageView from '@/pages/base.page-view';
import CabinetMenu from '@/blocks/pages/b-cabinet/b-cabinet-menu/index';
import ScheduleFormView from '@/blocks/pages/b-schedule-form/index';
import './style.less';

export default BasePageView.extend({

	initialize() {
		this.model = STATE.getSearchModel();
		this.render();
	},

	render() {
		const container = this.$el;
		this.setElement(this.template());
		container.append(this.$el);
		this.ui.content.append(new ScheduleFormView({
			model: this.model,
		}).$el);
		this.ui.sidebar.append(new CabinetMenu().$el);
	},

});
