module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-search-room-row">\n	<div class="b-search-room-header">\n		<div class="b-search-room-label">' +
((__t = ( (index + 1) )) == null ? '' : __t) +
' ' +
((__t = ( L10N.get('searchForm.room') )) == null ? '' : __t) +
'</div>\n		<div class="b-search-room-border"></div>\n		<div class="b-search-room-delete">\n			<span class="b-search-room__remove"></span>\n		</div>\n	</div>\n	<div class="b-search-room-employees"></div>\n</div>\n';

}
return __p
}