// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';

export default BaseModel.extend({

	defaults() {
		return {
			trainNumber: '',
			trainCarriageNumber: '',
			flightNumber: '',
			terminalNumber: '',
			address: '',
			date: '',
			time: '',
		};
	},

	initialize() {},
});
