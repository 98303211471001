// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';
import LocationModel from './locationModel';

export default BaseModel.extend({

	defaults() {
		return {
			start: new LocationModel(),
			end: new LocationModel(),
		};
	},

	initialize(options) {
		if (options.date) {
			const date = options.date.split('T')[0];
			_.each(['start', 'end'], (item) => {
				this.get(`${item}`).set('date', date);
			});
		}
	},

	toJSON() {
		const result = BaseModel.prototype.toJSON.call(this);

		_.each([result.start, result.end], (item) => {
			const keys = Object.keys(item);
			_.each(keys, (key) => {
				if (_.isEmpty(item[key])) delete item[key];

				if (key === 'time') {
					if (!_.isEmpty(item[key])) {
						const time = `${item[key].replace(/\s/g, '')}:00`;
						item[key] = `${item.date}T${time}`;
					}
					delete item.date;
				}
				if (key === 'terminalNumber') {
					if (_.isObject(item[key])) {
						item[key] = item[key].uid;
					}
				}
			});
		});

		return result;
	},
});
