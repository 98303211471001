module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-flight-info__wrapper">\r\n	<div class="collapsed sidebar__toggler" data-toggle="collapse" data-target=".b-sidebar-container">\r\n		';

			var flight = model;
			var legs = model.legs;

			var segmentFirst = legs[0].segments[0];
			var segmentLast = legs[legs.length - 1].segments;
			segmentLast = segmentLast[segmentLast.length - 1];
		;
__p += '\r\n		<div class="b-flight-info__legs-direction">\r\n			<div class="b-flight-info__direction__value">\r\n				' +
((__t = ( segmentFirst.departureAirport.uid )) == null ? '' : __t) +
' &rarr; ' +
((__t = ( segmentLast.arrivalAirport.uid )) == null ? '' : __t) +
'\r\n			</div>\r\n			<div class="b-flight-info__price__value">\r\n				' +
((__t = ( flight.price.total.amount )) == null ? '' : __t) +
' ' +
((__t = ( flight.price.total.currencyCode )) == null ? '' : __t) +
'\r\n			</div>\r\n		</div>\r\n	</div>\r\n	<div class="collapse sidebar__toggle-block b-sidebar-container show">\r\n		<section class="b-flight-info">\r\n			<div class="b-flight-info__legs"></div>\r\n			<div class="b-flight-info__limit"></div>\r\n			<div class="b-flight-info__passengers"></div>\r\n            ';
 if (!_.isEmpty(flight.vendorCommissionsData)) { ;
__p += '\r\n                <div class="b-flight-info__commission-section mobile-hide">\r\n                ';
_.each(_.keys(flight.vendorCommissionsData), function(key) {
                    const passengerTypeDataList = flight.vendorCommissionsData[key];
                _.each(passengerTypeDataList, function(item) {
                    const value = !_.isEmpty(item.amount) ? item.amount.amount + Formatter.formatCurrency(item.amount.currencyCode) : item.rate + '%';
                    const commissionRowContent = L10N.get('pricing.commission') + ' ' + key + ': ' + value + ' (' + item.ruleNumber + ') ';
                ;
__p += '\r\n                <div class="b-flight-info__commission-row">\r\n                    <span>' +
((__t = ( commissionRowContent )) == null ? '' : __t) +
'</span>\r\n                    <i class="g-icon-attention" data-toggle="tooltip" data-html="true" data-position="bottom" data-original-title="<div>' +
((__t = ( _.escape(item.contractCaption) )) == null ? '' : __t) +
'</div>"></i>\r\n                </div>\r\n                ';
 });
                });
__p += '\r\n            </div>\r\n            ';
 } ;
__p += '\r\n			<div class="b-flight-info__pricing"></div>\r\n            ';
 if (flight.messages != null && flight.messages.length) { ;
__p += '\r\n                <ul class="b-flight-info__messages-list">\r\n                    ';
 _.each(flight.messages, function(message) { ;
__p += '\r\n                        ';
 if (message.text != null) { ;
__p += '\r\n                            <li class="b-flight-info__messages-list-item">\r\n                                ' +
((__t = ( `${message.text}`.toUpperCase() )) == null ? '' : __t) +
'\r\n                            </li>\r\n                        ';
 } ;
__p += '            \r\n                    ';
 }) ;
__p += '\r\n                </ul>\r\n            ';
 } ;
__p += '\r\n		</section>\r\n	</div>\r\n</div>\r\n';

}
return __p
}