module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="l-block__back">\r\n	<section class="b-filters l-block__over">\r\n		<div class="b-filters__sorting"></div>\r\n		<div class="b-filters__mobile-common-filters-title collapse--with-anchor" data-toggle="collapse" data-target=".b-filters__common-filters">' +
((__t = ( L10N.get('filters.commonFilters') )) == null ? '' : __t) +
'</div>\r\n		<div class="b-filters__common-filters show">\r\n			<div class="b-filters__line b-filters__travel_policy"></div>\r\n			<div class="b-filters__line b-filters__toggles"></div>\r\n			<div class="b-filters__line b-filters__input"></div>\r\n			<div class="b-filters__line  b-filters__block b-filters__common"></div>\r\n			<div class="b-filters__line b-filters__block b-filters__range"></div>\r\n\r\n		</div>\r\n		<div class="b-filters__trigger"></div>\r\n\r\n		<div class="b-filters__line b-filters__block b-filters__train_leg b-filters__leg-first">\r\n			<h4 class="b-filters__line-title collapse--with-anchor" data-toggle="collapse" data-target=".b-filters__filter-lines-container__leg-first">' +
((__t = ( L10N.get("filters.there") )) == null ? '' : __t) +
'</h4>\r\n			<div class="b-filters__filter-lines-container b-filters__filter-lines-container__leg-first collapse" data-toggle=false"></div>\r\n\r\n		</div>\r\n\r\n		<div class="b-filters__line b-filters__block b-filters__train_leg b-filters__leg-second">\r\n			<h4 class="b-filters__line-title collapse--with-anchor" data-toggle="collapse" data-target=".b-filters__filter-lines-container__leg-second">' +
((__t = ( L10N.get("filters.back") )) == null ? '' : __t) +
'</h4>\r\n			<div class="b-filters__filter-lines-container b-filters__filter-lines-container__leg-second collapse" data-toggle=false"></div>\r\n\r\n		</div>\r\n\r\n		';
 if (!this.options.isRoundTrip && this.options.legs.length >= 2) {
			_.each(this.options.legs, function(leg, i) { ;
__p += '\r\n				<div class="b-filters__line b-filters__block b-filters__leg ' +
((__t = ( (i === 0) ? 'b-filters__leg-first' : 'b-filters__leg-second' )) == null ? '' : __t) +
'">\r\n					<h4 class="b-filters__line-title collapse--with-anchor " data-toggle="collapse" data-target=".b-filters__filter-lines-container">\r\n						';

							var first = _.first(leg.segments);
							var last = _.last(leg.segments);

						if (first && last) { ;
__p += '\r\n							' +
((__t = ( (first.departureCity || {}).caption || '--' )) == null ? '' : __t) +
' - ' +
((__t = ( (last.arrivalCity || {}).caption || '--' )) == null ? '' : __t) +
'\r\n						';
 } else { ;
__p += '\r\n							' +
((__t = ( L10N.get("filters.leg") )) == null ? '' : __t) +
' №' +
((__t = ( (i + 1) )) == null ? '' : __t) +
'\r\n						';
 } ;
__p += '\r\n					</h4>\r\n					<div class="b-filters__filter-lines-container ' +
((__t = ( (i === 0) ? 'b-filters__filter-lines-container__leg-first' : 'b-filters__filter-lines-container__leg-second' )) == null ? '' : __t) +
' collapse" data-toggle="false"></div>\r\n				</div>\r\n			';
 }) ;
__p += '\r\n		';
 } else { ;
__p += '\r\n			<div class="b-filters__line b-filters__block b-filters__leg b-filters__leg-first">\r\n				<h4 class="b-filters__line-title collapse--with-anchor" data-toggle="collapse" data-target=".b-filters__filter-lines-container__leg-first">' +
((__t = ( L10N.get("filters.firstLeg") )) == null ? '' : __t) +
'</h4>\r\n				<div class="b-filters__filter-lines-container b-filters__filter-lines-container__leg-first collapse" data-toggle="false"></div>\r\n			</div>\r\n			<div class="b-filters__line b-filters__block b-filters__leg b-filters__leg-second">\r\n				<h4 class="b-filters__line-title collapse--with-anchor" data-toggle="collapse" data-target=".b-filters__filter-lines-container__leg-second">' +
((__t = ( L10N.get("filters.secondLeg") )) == null ? '' : __t) +
'</h4>\r\n				<div class="b-filters__filter-lines-container b-filters__filter-lines-container__leg-second collapse" data-toggle="false"></div>\r\n			</div>\r\n		';
 } ;
__p += '\r\n\r\n		<div class="b-filters__select"></div>\r\n\r\n		<div class="b-filters__mobile-buttons">\r\n			<span class="b-filters__mobile-reset">' +
((__t = ( L10N.get("filters.reset") )) == null ? '' : __t) +
'</span>\r\n			<span class="b-filters__mobile-apply">' +
((__t = ( L10N.get("filters.apply") )) == null ? '' : __t) +
'</span>\r\n		</div>\r\n\r\n	</section>\r\n	<div class="b-filters__drop-filters">\r\n		<span class="b-filters__drop-filters-button">' +
((__t = ( L10N.get("filters.resetFilters") )) == null ? '' : __t) +
'</span>\r\n	</div>\r\n</div>\r\n';

}
return __p
}