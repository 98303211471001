module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-numerable-picker  ' +
((__t = ( classes )) == null ? '' : __t) +
'">\n	<div class="b-numerable-picker__wrapper">\n		<span class="b-numerable-picker__input"></span>\n		<div class="b-numerable-picker__select">\n			<div class="b-numerable-picker__select-img"></div>\n		</div>\n	</div>\n	<div class="b-numerable-picker__popup">\n		<div class="b-numerable-picker__item">\n			<div class="b-numerable-picker__item-content">\n				<div class="b-numerable-picker__item-controls">\n					<span class="b-numerable-picker__item-minus">&#8211;</span>\n					<span class="b-numerable-picker__item-value"></span>\n					<span class="b-numerable-picker__item-plus">+</span>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>\n';

}
return __p
}