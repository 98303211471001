// eslint-disable-next-line banned-modules
'use strict';

import './style.less';

import TrainRouteView from '@/blocks/elements/b-train/b-train-route/index';
import TrainMapsView from '@/blocks/elements/b-train/b-train-maps/index';

import BaseView from '@/classes/base.view';
import template from './template.ejs';

import $ from 'jquery';
import axios from 'axios';

export default BaseView.extend({

	template,

	events: {
		'click .b-route__info-route-link': 'showTrainRoute',
	},

	initialize() {
		this.render();
	},

	render(...args) {
		BaseView.prototype.render.apply(this, args);
		this.renderMaps();
	},

	renderMaps() {
		if (this.options.reservation == null || _.isEmpty(this.options.reservation.carriages)) {
			return this;
		}

		_.each(this.options.reservation.carriages, (c, index) => {
			const {gdsAccount} = this.options.reservation;
			const {providerCarSchemeId, twoStoreyed = false, carriageCardId, train} = c;
			const isInmob = train && train.gds && train.gds.uid === 'INMOB';
			if ((!carriageCardId && !isInmob) || (isInmob && (!providerCarSchemeId || twoStoreyed == null))) return;

			if (isInmob && providerCarSchemeId) {
				this.getInmobMap(providerCarSchemeId, twoStoreyed, gdsAccount)
					.then(map => {
						this.$el.find(`.b-train-ticket__wrapper[data-carriage-id="${index}"] .b-train-ticket__map`).html(new TrainMapsView({
							carriage: {
								_id: index,
								carriageCardId: c.carriageCardId,
								number: c.number,
								places: c.places,
								type: c.type,
							},
							providerCarSchemeId,
							inMobMap: map,
							numbers: c.places,
							previewOnly: true,
							train: {
								brand: c.trainBrand,
							},
						}).$el);
					});
			} else {
				this.$el.find(`.b-train-ticket__wrapper[data-carriage-id="${index}"] .b-train-ticket__map`).html(new TrainMapsView({
					carriage: {
						_id: index,
						carriageCardId: c.carriageCardId,
						number: c.number,
					},
					numbers: c.places,
					previewOnly: true,
					train: {
						brand: c.trainBrand,
					},
				}).$el);
			}
		});

		return this;
	},

	getInmobMap(id, twoStoreyed, gdsAccount) {
		if (twoStoreyed) {
			const promises = [];
			promises.push(axios.post('/midoffice/ibecorp-b2b/rail/car/scheme', {
				parameters: {
					providerCarSchemeId: id,
					schemeStorey: 1,
					gdsAccount,
				},
			}));
			promises.push(axios.post('/midoffice/ibecorp-b2b/rail/car/scheme', {
				parameters: {
					providerCarSchemeId: id,
					schemeStorey: 2,
					gdsAccount,
				},
			}));
			return Promise.all(promises).then(responses => {
				return `${responses[1].data}</br>${responses[0].data}`;
			});
		}
		return axios.post('/midoffice/ibecorp-b2b/rail/car/scheme', {
			parameters: {
				providerCarSchemeId: id,
				schemeStorey: 1,
				gdsAccount,
			},
		}).then(res => {
			return res.data;
		});
	},

	showTrainRoute(e) {
		if (e) {
			e.preventDefault();
		}
		if (this.trainRouteView != null) {
			this.trainRouteView.remove();
		}
		const index = $(e.currentTarget).data('index');
		const train = this.options.reservation.carriages[index].train;

		this.trainRouteView = new TrainRouteView({
			train,
		});
	},

});
