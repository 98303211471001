module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<label class="b-input b-input-phone ' +
((__t = ( this.disabled ? 'b-input-phone-disabled' : '' )) == null ? '' : __t) +
'">\r\n	<span class="b-input__label">' +
((__t = (label )) == null ? '' : __t) +
'</span>\r\n	<div class="b-input__value">\r\n		<span class="b-input-phone__country-visible"></span>\r\n		<select type="text" class="b-input-phone__country">\r\n			';
 _.forEach(countries, function(item) { ;
__p += '\r\n				<option value="' +
((__t = (item.phoneCode)) == null ? '' : __t) +
'">+' +
((__t = (item.phoneCode)) == null ? '' : __t) +
' ' +
((__t = (item.caption)) == null ? '' : __t) +
'</option>\r\n			';
});
__p += '\r\n		</select>\r\n		<input class="b-input-phone__value" type="text">\r\n		';
 if (deletableField) { ;
__p += '\r\n			<div class="b-input-phone__remove"></div>\r\n		';
 } ;
__p += '\r\n	</div>\r\n</label>\r\n';

}
return __p
}