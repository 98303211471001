// eslint-disable-next-line banned-modules
'use strict';

import BookingDuplicatesMsgHandler from './BookingDuplicatesMsgHandler';

class BookingDuplicatesHandler {
	bookingDuplicatesMsgHandler = null;

	/**
	 * Initializes the BookingDuplicatesHandler object with the provided parameters.
	 *
	 * @param {Object} params - The parameters for initializing the object.
	 * @param {Object} params.bookingSettings - The booking settings for the handler.
	 */
	constructor(params = {}) {
		this.initDuplicatesData(params.bookingSettings);
		this.bookingDuplicatesMsgHandler = new BookingDuplicatesMsgHandler();
	}

	initDuplicatesData = (bookingSettings = {}) => {
		this.setData({
			bookingFormSettingsToken: bookingSettings.bookingFormSettingsToken,
			acceptSearchDuplicatesMessageId: undefined,
			acceptProductDuplicatesMessageId: undefined,
			bookingToken: undefined,
		});
		this.bookingDuplicatesMsgHandler = new BookingDuplicatesMsgHandler();
	};

	setData = (data = {}) => {
		if (!this.data) this.data = {};
		if (!_.isEmpty(data)) {
			Object.keys(data).forEach((key) => {
				this.data[key] = data[key];
			});
		}
	};

	getBookingDataObj = () => ({
		...(this.data || {}),
	});

	handleDuplicatesMessage = (params = {}) => {
		this.bookingDuplicatesMsgHandler.showPopup(params);
	};

	showSearchDuplicatesPopup = (params = {}) => {
		const { id, text, onOk, onCancel, onClose } = params;
		const _onOk = () => {
			this.setData({ acceptSearchDuplicatesMessageId: id });
			onOk?.(this.getBookingDataObj());
		};
		this.handleDuplicatesMessage({
			text,
			onOk: _onOk,
			onCancel,
			onClose,
		});
	};

	shouldShowProductDuplicatesPopup = (params = {}) => params.bookingToken && params.productDuplicatesMessage;

	showProductDuplicatesPopup = (params = {}) => {
		const {
			bookingToken,
			productDuplicatesMessage = {},
			onOk,
			onCancel,
			onClose,
		} = params;
		const { id, text } = productDuplicatesMessage;
		const _onOk = () => {
			const newData = { acceptProductDuplicatesMessageId: id };
			if (bookingToken) newData.bookingToken = bookingToken;
			this.setData(newData);
			onOk?.(this.getBookingDataObj());
		};
		this.handleDuplicatesMessage({
			text,
			onOk: _onOk,
			onCancel,
			onClose,
		});
	};

	remove = () => {
		if (this.bookingDuplicatesMsgHandler) {
			this.bookingDuplicatesMsgHandler.removePopup();
			this.bookingDuplicatesMsgHandler = null;
		}
	};
}

export default BookingDuplicatesHandler;
