module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-airlines__item">\r\n	<span class="b-airlines__item-city">' +
((__t = (airportCaption)) == null ? '' : __t) +
'</span>\r\n</div>\r\n';

}
return __p
}