// eslint-disable-next-line banned-modules
'use strict';

import './style.mobile.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';
import travelPolicy from '../b-travel-policy.ejs';

import $ from 'jquery';

export default BaseView.extend({

	template,

	events: {
		'click .b-train-offer-preview__close-btn': 'closePreview',
		'click .carriage-indicator': 'showMap',
		'click .b-train-offer-preview__slide-left': 'showLeft',
		'click .b-train-offer-preview__slide-right': 'showRight',
	},

	ui: {
		carriagesViewContainer: '.js-carriages-view-container',
		carriagesIndicators: '.js-car-indicator-container',
		controls: '.b-train-offer-preview__controls',
		carInfoContainer: '.js-car-info',
		footer: '.js-footer',
	},

	initialize(options) {
		this.options = options;
		this.carriagesView = options.carriagesView;
		this.groupByServiceClass = options.groupByServiceClass || false;
		this.model = options.model;
		this.carriagesGroups = this.carriagesView.carriagesGroups;
		this.carriageUid = options.carriageUid;
	},

	render(attrs) {
		const params = Object.assign(
			{
				classes: '',
			},
			this.model ? this.model.attributes : {},
			this.options,
			attrs,
		);
		this.setElement(this.template(params));

		this.visibility = true;

		if (this.carriagesView) {
			this.ui.carriagesViewContainer.append(this.carriagesView.$el);

			const groups = this.carriagesGroups;
			if (!groups.groups.length) return this;

			_.each(Object.keys(groups.map), (key, i) => {
				let indicatorCaption = '';
				if (groups.map[key].length > 1) {
					const result = [];
					_.each(groups.map[key], (c) => result.push(parseInt(c.number)));
					indicatorCaption = `${L10N.get('trains.wagon')} № ${result.join(', ')}`;
				} else {
					indicatorCaption = `${L10N.get('trains.wagon')} № ${groups.map[key][0].number}`;
				}
				if (i === 0) {
					this.selectedCarriage = {
						carriage: groups.map[key][0],
						groupKey: key,
					};
				}
				const indicator = `<div class="carriage-indicator" data-id="${key}">${indicatorCaption}</div>`;
				this.ui.carriagesIndicators.append(indicator);
			});
		}
		return this;
	},

	showFirstGroup() {
		if (!this.carriagesView.carriages.length) return;
		this.$(`.carriage-indicator[data-id="${this.selectedCarriage.groupKey}"]`).click();

		this.renderTravelPolicy();
	},

	toggleControls() {
		if (
			this.carriagesGroups.map &&
			this.carriagesGroups.map[this.currentGroupId] &&
			this.carriagesGroups.map[this.currentGroupId].length > 1
		) {
			this.ui.controls.css('display', 'flex');
		} else {
			this.ui.controls.css('display', 'none');
		}
	},

	showRight() {
		if (this.carriagesView && !_.isEmpty(this.carriagesView.carriages)) {
			this.carriagesView.showRight().then(() => {
				this.options.parent.$selectedCarriage = this.$('.b-carriage-maps__container.open');
				const carriage = this.getSelectedCarriage();
				this.updateNumber(carriage);
				this.updateCarInfo(carriage);
			});
		}
	},

	showLeft() {
		if (this.carriagesView && !_.isEmpty(this.carriagesView.carriages)) {
			this.carriagesView.showLeft().then(() => {
				this.options.parent.$selectedCarriage = this.$('.b-carriage-maps__container.open');
				const carriage = this.getSelectedCarriage();
				this.updateNumber(carriage);
				this.updateCarInfo(carriage);
			});
		}
	},

	getSelectedCarriage() {
		const carriage = this.carriagesView.carriagesGroups.map[this.currentGroupId][this.getMapId()];
		this.selectedCarraige = {
			carriage,
			groupKey: this.currentGroupId,
		};
		return carriage;
	},

	getMapId(next) {
		return this.carriagesView._getMapId.call(this, next).mapId;
	},

	showMap(e) {
		if ($(e.currentTarget).hasClass('active')) return;
		this.currentGroupId = $(e.currentTarget).attr('data-id');
		this.$('.carriage-indicator').removeClass('active');
		$(e.currentTarget).addClass('active');
		this.carriagesView.showMap(e).then(() => {
			this.toggleControls();
			this.getSelectedCarriage(this.getMapId(null));
			if (this.carriagesView.carriages.length > 1) this.updateNumber(this.selectedCarriage?.carriage);
			this.updateCarInfo(this.selectedCarriage?.carriage);
			this.options.parent.$selectedCarriage = this.$('.b-carriage-maps__container.open');
		});
	},

	updateNumber(carriage) {
		if (!carriage) return;
		if (!this.ui.controls.is(':visible')) return;
		this.ui.controls.find('.js-car-number').html(`${L10N.get('trains.wagon')} №${carriage.number}`);
	},

	updateCarInfo(car) {
		const $carriage = this.$(`.b-train__carriage[data-id="${this.currentGroupId}"]`);
		car = car || this.getSelectedCarriage();
		if (!car) return;

		if (!this.ui.controls.is(':visible')) this.ui.carInfoContainer.find('.js-car-number').html(`${L10N.get('trains.wagon')} № ${car.number}`);
		this.ui.carInfoContainer.find('.js-car-type').html(car.typeTitle);
		this.ui.carInfoContainer.find('.js-car-service-class').html($carriage.find('.b-train__carriage-service-class').html());
		this.ui.carInfoContainer.find('.js-car-services').html($carriage.find('.b-train__carriage-services-group.open').html());
	},

	renderGenderSelect(genders, model) {
		const widget = new Widgets.Select({
			bindingProperty: 'gender',
			values: genders,
			placeholder: L10N.get('trains.selectCarriageType'),
		});
		widget.applyBinding(model);
		this.ui.carInfoContainer.find('.js-selector-gender').empty().append(widget.$el);
	},

	renderGenderDesc(el) {
		this.ui.carInfoContainer.find('.js-car-gender-desc').append(el);
	},

	renderCompartmentToggle(options, model) {
		const widget = new Widgets.ToggleButton(options);
		widget.applyBinding(model);
		this.ui.carInfoContainer.find('.js-selector-compartment').empty().append(widget.$el);
	},

	renderWarning() {
		this.ui.carInfoContainer.find('.js-car-messages').html(
			`<div class="b-message b-message-warning">${L10N.get('trains.seatStatus.PREFERENCES_SEATS')}</div>`,
		);
	},

	renderSeatsSelect(options, model) {
		const widget = new Widgets.Seats(options);
		widget.applyBinding(model);
		this.ui.carInfoContainer.find('.js-selector-seats').empty().append(widget.$el);
	},

	clearCarInfo() {
		this.ui.carInfoContainer.find('.js-car-gender-desc').empty();
		this.ui.carInfoContainer.find('.js-selector-gender').empty();
		this.ui.carInfoContainer.find('.js-selector-seats').empty();
		this.ui.carInfoContainer.find('.js-selector-compartment').empty();
		this.ui.carInfoContainer.find('.js-car-messages').empty();
	},

	renderCarPrice(el, mouseOverHandler) {
		this.ui.carInfoContainer.find('.js-car-prices').html(el);
		if (_.isFunction(mouseOverHandler)) {
			this.ui.carInfoContainer.find('.js-car-prices').find('.b-car-place').each(mouseOverHandler);
		}
	},

	renderTravelPolicy() {
		const carriage = _.find(this.model.get('carriageInfos'), (el) => el.uid === this.carriageUid);
		this.ui.footer.find('.js-travel-policy').empty().append(travelPolicy({carriage, isMobileTemplate: true}));
	},

	renderButtonsView(el) {
		this.ui.footer.find('.js-buttons-view').empty().append(el);
	},

	closePreview(e) {
		this.ui.carInfoContainer.empty();
		this.options.carriagesContainer.append(this.carriagesView.$el);
		this.options.parent.$selectedCarriage = null;
		this.visibility = false;
		$('.l-layout').removeClass('show-offer-preview');
		if (this.options.parent.selectedVariant.carriageView != null &&
			this.options.parent.selectedVariant.numbers != null &&
			this.options.parent.selectedVariant.numbers.length > 0) {
			this.options.parent.buttonsView.update(this.options.parent.selectedVariant, false);
		}
		if (e) {
			this.options.parent.toggleMapContainer(false);
			this.options.parent.$el.find(`.js-select-carriage[data-uid="${this.carriageUid}"]`).removeClass('open');
		}
		this.options.carriagesContainer.find('.b-carriage__selected-variant').append(this.options.parent.buttonsView.$el);
		document.documentElement.scrollTop = document.body.scrollTop = this.options.ticketYPosition;
		STATE.setIsMobileModalOpened(false);
	},

});
