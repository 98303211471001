module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="p-login__header-language">\r\n	<div class="p-login__header-language-wrapper js-select-language">\r\n		<div class="p-login__header-language-flag">\r\n			<div class="p-login__header-language-flag-img p-login__header-language-' +
((__t = ( this.configuration.selected )) == null ? '' : __t) +
'"></div>\r\n		</div>\r\n		<div class="p-login__header-language-select">\r\n			<div class="p-login__header-language-select-img"></div>\r\n		</div>\r\n	</div>\r\n	<ul class="p-login__header-language-dropdown">\r\n		';
 for (var i = 0; i < this.configuration.languages.length; i++) { ;
__p += '\r\n			';
 var lang = this.configuration.languages[i]; ;
__p += '\r\n			';
 if (this.configuration.selected != lang) { ;
__p += '\r\n				<li class="p-login__header-language-dropdown-point js-change-language" lang="' +
((__t = ( lang )) == null ? '' : __t) +
'">\r\n					<div class="p-login__header-language-dropdown-flag">\r\n						<div\r\n						class="p-login__header-language-dropdown-flag-img p-login__header-language-' +
((__t = ( lang )) == null ? '' : __t) +
'"></div>\r\n					</div>\r\n				</li>\r\n			';
 } ;
__p += '\r\n		';
 } ;
__p += '\r\n	</ul>\r\n</div>\r\n';

}
return __p
}