module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    function getDailyDetailsDataToRender(dailyDetails) {
        var output = [];
        if (!dailyDetails) return output;
        _.each(dailyDetails, function(dailyDetail) {
            var renderedDate = dailyDetail.date ? new Time(dailyDetail.date).toDateString(): "";
            var renderedPrice = dailyDetail.price ?
                (
                    Formatter.formatMoney(dailyDetail.price.amount, Formatter.getDecimal(dailyDetail.price.amount))
                    + " "
                    + Formatter.formatCurrency(dailyDetail.price.currencyCode)
                )
                :
                "";
            output.push({
                renderedDate: renderedDate,
                renderedPrice: renderedPrice,
            });
        });
        return output;
    }
;
__p += '\n<div class="b-offers-hotels-modal">\n	<div class="b-offers-hotels-modal__title">\n		';
 if (this.passengers != null) { ;
__p += '\n			' +
((__t = ( L10N.get('hotels.variantToAgreeFor') )) == null ? '' : __t) +
': <span class="b-offers-hotels-modal__title-person"></span>\n		';
 } else { ;
__p += '\n			' +
((__t = ( L10N.get('hotels.variantToAgree') )) == null ? '' : __t) +
'\n		';
 } ;
__p += '\n	</div>\n	<div class="b-offers-hotels-modal__list">\n		';
 _.each(this.offers, function (el, j) {
			var hotel = el.hotel;
			var offers = el.offers;
            var stars = parseInt(hotel.hotelStars, 10);  ;
__p += '\n			<div class="b-modal-hotel" data-hotel="' +
((__t = ( hotel.number )) == null ? '' : __t) +
'" data-copy-container="';
 j ;
__p += '">\n                <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('cabinet.orders.serviceName') )) == null ? '' : __t) +
': ' +
((__t = ( L10N.get('cabinet.orders.serviceTypes.HOTEL') )) == null ? '' : __t) +
'</span>\n                <span class="js-copy-content copy-content">' +
((__t = ( hotel.name )) == null ? '' : __t) +
' ';
 if (stars > 0) { ;
__p +=
((__t = ( stars )) == null ? '' : __t) +
'*';
 } ;
__p += '</span>\n                ';

                    let address;
                    let addressString;
                    let distanceString;
                    if (hotel.address) {
                        address = hotel.address;
                        addressString = _.compact([address.address, address.zipCode, (address.cityInfo && address.cityInfo.city || {}).caption, (address.country || {}).caption]).join(', ');

						if (hotel.address.cityInfo) {
                            const locationHotel = _.pick(address, ['latitude', 'longitude']);
                            const locationCity = _.pick(address.cityInfo, ['latitude', 'longitude']);

                            if (!_.isEmpty(locationHotel) && !_.isEmpty(locationCity)) {
                                try {
                                    const distance = window.google.maps ? Math.ceil(window.google.maps.geometry.spherical.computeDistanceBetween(
                                            new window.google.maps.LatLng(locationHotel.latitude, locationHotel.longitude),
                                            new window.google.maps.LatLng(locationCity.latitude, locationCity.longitude)
                                    )) : NaN;

                                    const distanceValue = parseFloat((distance / 1000).toFixed(2));
                                    if (!isNaN(distanceValue)) {
                                        distanceString = distanceValue + L10N.get('hotels.distanceFromCenter');
                                    }
                                } catch (e) {
                                    throw e;
                                }
                            }
                        }
                    }
                ;
__p += '\n                ';
 if (addressString) { ;
__p += '<span class="js-copy-content copy-content">' +
((__t = ( L10N.get('hotels.address') )) == null ? '' : __t) +
': ' +
((__t = ( addressString )) == null ? '' : __t) +
' ';
 if (distanceString) { ;
__p += ' ' +
((__t = ( distanceString )) == null ? '' : __t) +
' ';
 } ;
__p += '</span>';
 } ;
__p += '\n				<div class="b-modal-hotel__description-container">\n					<div class="b-modal-hotel__description">\n						<div class="b-modal-hotel__description-name">' +
((__t = ( hotel.name )) == null ? '' : __t) +
'</div>\n						';
 if (stars > 0) { ;
__p += '\n							<div class="b-modal-hotel__description-stars b-hotel-stars">\n								';
 for (var i = 0; i < stars; i++) { ;
__p += '\n									<span class="b-hotel-star"></span>\n								';
 } ;
__p += '\n							</div>\n						';
 } ;
__p += '\n						<div class="b-modal-hotel__remove-btn-container">\n							<span class="b-modal-hotel__remove"></span>\n						</div>\n					</div>\n					<div class="b-modal-hotel__address">\n                        ' +
((__t = ( addressString || '' )) == null ? '' : __t) +
' ' +
((__t = ( !_.isEmpty(hotel.phones) ? '| ' + hotel.phones.join(', ') : '' )) == null ? '' : __t) +
' ' +
((__t = ( distanceString ? '| ' + distanceString : '' )) == null ? '' : __t) +
'\n					</div>\n				</div>\n\n				<div class="b-modal-hotel__offers-list">\n					';
 _.each(offers, function (o, k) { ;
__p += '\n						<div class="b-offers-hotels-modal__list-item b-offers-hotels-modal__list-item-' +
((__t = ( k )) == null ? '' : __t) +
' drag-sort-element" data-room-index="' +
((__t = ( o.roomIndex )) == null ? '' : __t) +
'" data-token="' +
((__t = ( o.token )) == null ? '' : __t) +
'">\n                            <span class="js-copy-content copy-content">' +
((__t = ( (k > 0 ? '\n' : '') + L10N.get('hotels.checkin') )) == null ? '' : __t) +
': ' +
((__t = ( new Time(o.checkIn).toDateString() )) == null ? '' : __t) +
', ' +
((__t = ( L10N.get('Common.with') )) == null ? '' : __t) +
' ' +
((__t = ( new Time(o.checkIn).toShortTime() )) == null ? '' : __t) +
'</span>\n                            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('hotels.checkout') )) == null ? '' : __t) +
': ' +
((__t = ( new Time(o.checkOut).toDateString() )) == null ? '' : __t) +
', ' +
((__t = ( L10N.get('Common.with') )) == null ? '' : __t) +
' ' +
((__t = ( new Time(o.checkOut).toShortTime() )) == null ? '' : __t) +
'</span>\n                            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('searchForm.room') )) == null ? '' : __t) +
': <span data-copy-content-from=".b-offers-hotels-modal__list-item-' +
((__t = ( k )) == null ? '' : __t) +
' .b-offer-modal__name"></span></span>\n                            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('hotels.cost') )) == null ? '' : __t) +
': <span data-replace-newline=" " data-copy-content-from=".b-offers-hotels-modal__list-item-' +
((__t = ( k )) == null ? '' : __t) +
' .b-offers-hotels-modal__list-item-price-container"></span></span>\n                            ';
 if (o.price.passengerPrices && o.price.passengerPrices.length) {
                                var passengerPrices = _.toArray(o.price.passengerPrices).sort(function(a,b) {return a.passengerType.uid > b.passengerType.uid});
                                _.each(passengerPrices, function(value) {
                                    var dailyDetailsDataToRender = getDailyDetailsDataToRender(value.dailyDetails);
                                        if (dailyDetailsDataToRender.length) {
                            ;
__p += '\n                                        <span class="js-copy-content copy-content">' +
((__t = ( _.map(dailyDetailsDataToRender, function(dailyDetailsData) {return dailyDetailsData.renderedDate + " " + dailyDetailsData.renderedPrice;}).join("\n") )) == null ? '' : __t) +
'</span>\n                                    ';
 } ;
__p += '\n                                ';
 }) ;
__p += '\n                            ';
 } ;
__p += '\n                            ';
 if (this.showGdsAccountName) { ;
__p += '\n                                <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('filters.providers') )) == null ? '' : __t) +
': ' +
((__t = ( o.provider.caption )) == null ? '' : __t) +
'</span>\n                            ';
 } ;
__p += '\n							<div class="b-offers-hotels-modal__list-item-drag drag-sort-anchor"></div>\n							<div class="b-offers-hotels-modal__list-item-description b-offer-modal">\n								<div class="b-route__element b-offer-modal__name-container">\n									<div class="b-offer-modal__name" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="<div>' +
((__t = ( _.escape(o.roomName) )) == null ? '' : __t) +
'</div>">' +
((__t = ( o.roomName )) == null ? '' : __t) +
'</div>\n									';
 if (!_.isEmpty(o.essentialInfo)) {
										_.each(o.essentialInfo, function(essential) { ;
__p += '\n											<div class=\'b-offer-modal__essential b-offer-modal__important\' data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="<div>' +
((__t = ( _.escape(essential) )) == null ? '' : __t) +
'</div>">' +
((__t = ( essential )) == null ? '' : __t) +
'</div>\n                                            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('Common.importantInformation') )) == null ? '' : __t) +
': ' +
((__t = ( essential )) == null ? '' : __t) +
'</span>\n										';
 });
									} ;
__p += '\n								</div>\n								<div class="b-route__element">\n									';
 if (o.cancellationPolicyText || o.offerWithPenalty === true) {
										var cancellationPolicyText = '<div>' +
											((o.offerWithPenalty === true) ? '<span><strong>' + L10N.get('bookingForm.attention') + '</strong>: ' + L10N.get('hotels.refundPenalties') + '. ' + '</span>' : '') +
											(o.cancellationPolicyText ? (((o.offerWithPenalty === true) ? '<br />' : '') + o.cancellationPolicyText) : '') + '</div>';
										;
__p += '\n										<div>\n											' +
((__t = ( L10N.get('hotels.penalties') )) == null ? '' : __t) +
' <span class="b-hotel-offer__fines ' +
((__t = ( (o.offerWithPenalty === true) ? 'b-hotel-offer__fines-important' : '' )) == null ? '' : __t) +
'" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="' +
((__t = ( _.escape(cancellationPolicyText) )) == null ? '' : __t) +
'">?</span>\n                                            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('hotels.penalties') )) == null ? '' : __t) +
': ' +
((__t = ( cancellationPolicyText )) == null ? '' : __t) +
'</span>\n										</div>\n									';
 } ;
__p += '\n									';
 if (o.meal != null) { ;
__p += '\n										<div class="b-hotel-offer__meal" data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( _.escape(captionMeal) )) == null ? '' : __t) +
'">\n										';
 var captionMeal = (o.meal != null && !['-1', 'nomeal'].includes(o.meal.uid) && !['-', L10N.get('hotels.withoutMeal')].includes(o.meal.caption)) ? o.meal.caption : L10N.get('hotels.withoutMeal'); ;
__p += '\n											' +
((__t = ( captionMeal )) == null ? '' : __t) +
'\n										</div>\n                                        <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('hotels.meal') )) == null ? '' : __t) +
': ' +
((__t = ( captionMeal )) == null ? '' : __t) +
'</span>\n									';
 }
									if (_.some(o.options, function (option) {
										return _.isArray(option) && !_.isEmpty(option);
									})) {
										var optionsHTML = '<div>';
										var options = o.options;
										var checkOptions = [];

										if (!_.isEmpty(options.earlierCheckIn)) {
											checkOptions.push(L10N.get('hotels.earlyCheckIn'));
										}
										if (!_.isEmpty(options.laterCheckOut)) {
											checkOptions.push(L10N.get('hotels.lateCheckout'));
										}

										if (!_.isEmpty(checkOptions)) {
											// Capitalize for perfect look
											checkOptions[0] = checkOptions[0].charAt(0).toUpperCase() + checkOptions[0].slice(1);
											optionsHTML += checkOptions.join(', ');
										}

										if (!_.isEmpty(options.meals)) {
											optionsHTML += '<br />' + L10N.get('hotels.meal') + ': <ul class="options-list">';
											optionsHTML += _.map(options.meals, function (m) {
												return '<li>' + m.caption + '</li>';
											}).join('');
											optionsHTML += '</ul>'
										}

										optionsHTML += '</div>'; ;
__p += '\n\n										<div data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="' +
((__t = ( _.escape(optionsHTML) )) == null ? '' : __t) +
'">\n											<span class="action-link action-link--color-blue">' +
((__t = ( L10N.get('cabinet.orders.hotelAddServices') )) == null ? '' : __t) +
'</span>\n										</div>\n									';
 } ;
__p += '\n								</div>\n								';
 if (this.showGdsAccountName) { ;
__p += '\n                                    <div class="b-route__element">' +
((__t = ( o.provider.caption )) == null ? '' : __t) +
'</div>\n                                ';
 } ;
__p += '\n							</div>\n							<div class="b-offers-hotels-modal__list-item-price">\n								<div class="b-offers-hotels-modal__list-item-price-container">\n									<span class="b-offers-train-modal__list-item-price-amount">\n										' +
((__t = ( Formatter.formatMoney(o.price.total.amount, 0) )) == null ? '' : __t) +
'\n										' +
((__t = ( Formatter.formatCurrency(o.price.total.currencyCode) )) == null ? '' : __t) +
'\n									</span>\n									<span>\n										| ' +
((__t = ( L10N.get('hotels.for') )) == null ? '' : __t) +
' <span class="b-offers-hotels-modal__title-person-count"></span> ' +
((__t = ( L10N.get('hotels.person') )) == null ? '' : __t) +
'\n									</span>\n								</div>\n								<span class="b-offers-hotels-modal__list-item-remove"></span>\n							</div>\n						</div>\n						<div class="b-offers-hotels-modal__form b-offers-hotels-modal__form-control b-offers-hotels-modal__list-item-comment">\n							<span class="js-widget"  onclick=\'return new Widgets.Input({bindingProperty: "comments'+ j +''+ k +'", placeholder: L10N.get("hotels.comment") })\'></span>\n						</div>\n					';
 }); ;
__p += '\n				</div>\n			</div>\n			<div class="b-offers-hotels-modal__list-item-line">\n			</div>\n		';
 }); ;
__p += '\n	</div>\n\n	<div class="b-offers-hotels-modal__no-offers ' +
((__t = ( !_.isEmpty(this.offers) ? 'dn' : '' )) == null ? '' : __t) +
'">\n		<span>' +
((__t = ( L10N.get('hotels.noOffers') )) == null ? '' : __t) +
'</span>\n	</div>\n\n	<div class="b-offers-hotels-modal__emails">\n\n	</div>\n\n    <div class="b-offers-hotels-modal__form">\n	';
 if (this.options != null && !_.isEmpty(this.options.order)) { ;
__p += '\n        <div class="b-message b-message-warning">\n            ' +
((__t = ( L10N.get('hotels.sendToAgreementInfo') )) == null ? '' : __t) +
'\n        </div>\n        <div class="b-offers-hotels-modal__form-buttons">\n            <div class="b-offers-hotels-modal__form-button b-offers-hotels-modal__order">' +
((__t = ( L10N.get('hotels.addToOrder') )) == null ? '' : __t) +
'</div>\n	';
 } else { ;
__p += '\n        <div class="b-offers-hotels-modal__form-buttons">\n            <div class="b-offers-hotels-modal__form-button b-offers-hotels-modal__submit">' +
((__t = ( L10N.get('hotels.send') )) == null ? '' : __t) +
'</div>\n            <div class="b-offers-hotels-modal__form-button b-offers-hotels-modal__order">' +
((__t = ( L10N.get('hotels.addToOrder') )) == null ? '' : __t) +
'</div>\n	';
 } ;
__p += '\n            <div class="b-offers-hotels-modal__form-button copy-btn js-copy-to-clipboard mobile-hide">' +
((__t = ( L10N.get('Common.copyToClipboard') )) == null ? '' : __t) +
'</div>\n        </div>\n    </div>\n\n	<div class="b-offers-hotels-modal__close"></div>\n</div>\n';

}
return __p
}