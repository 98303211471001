module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (caption && caption.length) { ;
__p += '\n    <div class="b-autocomplete__item">\n        <span class="b-autocomplete__item-caption">' +
((__t = ( caption )) == null ? '' : __t) +
'</span>\n    </div>\n';
 } ;


}
return __p
}