// eslint-disable-next-line banned-modules
'use strict';

import './style.less';

import BaseView from '@/classes/base.view';
import BaseModel from '@/classes/base.model';
import template from './template.ejs';
import issuesTemplate from './template.issues.ejs';
import AttachmentsTemplate from './template.uploaded-attachments.ejs';

import GlUl from '@/utils/global-utils';
import Time from '@/utils/time';
import axios from 'axios';
import $ from 'jquery';

const enumKeyboard = {
	ENTER: 13,
};

export default BaseView.extend({

	template,

	events: {
		'click .b-cabinet-order__notes-create': 'createCorrespondence',
		'click .b-order-issue-attachment-download': 'downloadAttachment',
		'click .js-notes-attachment-btn': 'uploadAttachmentMobile',
		'click .b-uploaded-attachment > .g-icon-w-cancel': 'clearAttachment',
		'keydown .b-order__notes-container .b-textarea__value': 'changeMessageHandler',
		'keydown .b-order-issues__item-textarea-container .b-textarea__value': 'changeMessageHandler',
	},

	ui: {
		issuesContainer: '.b-order-issues',
		emptyIssuesNotesContainer: '.js-empty-issues-template',
		dragNDropContainer: '.b-order__notes-container',
		dragNDropBackground: '.b-order-notes__drop-background',
		attachments: '.js-attachments-container',
	},

	/**
	 * Initilize view
	 */
	initialize() {
		this.toggledIssuesUids = [];
		this.validAttachmentTypes = [
			'application/vnd.ms-excel',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			'application/msword',
			'text/html',
			'text/xml',
			'application/json',
			'application/pdf',
			'application/vnd.ms-excel',
			'application/xml',
			'text/plain',
			'text/css',
			'image/gif',
			'image/jpeg',
			'image/png',
			'text/calendar',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			'application/octet-stream',
		];
		this.attachments = [];
		this.booking = this.options.booking;
		this.model = new BaseModel();
		this.render();
	},

	dragAndDropListenersInit(dragNDropContainer, dragNDropBackground) {
		if (!dragNDropContainer || !dragNDropBackground) return;
		const issueUid = dragNDropContainer.data('issue');
		dragNDropContainer.off();
		dragNDropBackground.off();
		dragNDropContainer.on('dragenter', (e) => {
			e.preventDefault();
			e.stopPropagation();
			dragNDropContainer.addClass('dragging');
		});
		dragNDropContainer.on('dragover', (e) => {
			e.preventDefault();
			e.stopPropagation();
			dragNDropContainer.addClass('dragging');
		});
		dragNDropBackground.on('dragleave', (e) => {
			e.preventDefault();
			e.stopPropagation();
			dragNDropContainer.removeClass('dragging');
		});
		dragNDropBackground.on('drop', (e) => {
			e.preventDefault();
			e.stopPropagation();
			dragNDropContainer.removeClass('dragging');
			if (e.originalEvent && e.originalEvent.dataTransfer && e.originalEvent.dataTransfer.files) {
				this.uploadAttachment(e.originalEvent.dataTransfer.files, issueUid);
			}
		});
	},

	/**
	 * Render view
	 * @param  {...any} args
	 */
	render(...args) {
		if (STATE.checkSiteType('B2B') && this.options.booking.uid) this.getCorrespondence();
		BaseView.prototype.render.apply(this, args);
	},

	/**
	 * Get issues for the order
	 * @param {boolean} afterCreate
	 */
	getCorrespondence(afterCreate = false) {
		axios
			.post('/midoffice/ibecorp-b2b/booking/correspondence/info', {
				parameters: this.getRequestParams(),
			})
			.then((response) => {
				const result = this.getResult(response);
				const issues = this.issues || [];
				this.issues = _.sortBy(this.preProcessData(result.issues), (i) => {
					if (!issues) return 0;
					const oldOne = _.find(issues, (el) => el.uid === i.uid);
					const oldIndex = issues.indexOf(oldOne);
					return oldIndex || 0;
				});

				// !TODO Support send message into some issue (when they will be)
				if (this.activeIssue == null) this.activeIssue = _.last(this.issues);
				if (afterCreate && this.activeIssue != null) {
					this.toggledIssuesUids.push(this.activeIssue.uid);
				}

				this.updateCorrespondence();
			});
	},

	// EVENTS

	/**
	 * Create note
	 */
	createCorrespondence(e, issueUid) {
		if (e) {
			issueUid = issueUid || $(e.currentTarget).data('issue');
		}

		this.$el.find('.b-cabinet-order__notes-create').addClass('btn-disabled');
		const $textarea = issueUid ? this.$el.find(`.js-dragNDropContainer-${issueUid} .b-textarea__value`) :
			this.$el.find('.b-order__notes-container .b-textarea__value');
		const parameters = {
			content: (this.model.get(issueUid ? `notes.${issueUid}` : 'notes.default') || '').trim(),
			attachments: this[`attachments${issueUid || ''}`],
		};

		if (!parameters.content) {
			return;
		}

		if (issueUid && !_.isEmpty(this.issues)) this.activeIssue = _.find(this.issues, (i) => i.uid === issueUid);

		if (this.activeIssue != null) {
			parameters.issue = this.activeIssue.uid;
			this.toggledIssuesUids.push(parameters.issue);
		}

		axios
			.post('/midoffice/ibecorp-b2b/booking/correspondence/create', {
				parameters: _.extend({}, this.getRequestParams(), parameters),
			})
			.then(() => {
				this.model.set(issueUid ? `notes.${issueUid}` : 'notes.default', null);
				$textarea.val('').blur();
				this.getCorrespondence(true);
				if (issueUid) {
					this.$(`.js-attachments-container-${issueUid}`).empty();
					this[`attachments${issueUid}`] = [];
				} else {
					this.ui.attachments.empty();
					this.attachments = [];
				}
				this.$el.find('.b-cabinet-order__notes-create').removeClass('btn-disabled');
			});
	},

	/**
	 * Download attachment post
	 * @param {Event} click event
	 */
	downloadAttachment(event) {
		const {attachmentUid} = event.target.dataset;
		const parameters = {
			attachment: attachmentUid,
		};

		axios
			.post('/midoffice/ibecorp-b2b/booking/correspondence/attachment/download', {
				parameters: _.extend(this.getRequestParams(), parameters),
			}, this.model, {
				responseType: 'arraybuffer',
			})
			.then((response) => {
				GlUl.downloadFile(response, 'unknown', {type: 'image/png'});
			});
	},

	clearAttachment(e) {
		const uid = $(e.currentTarget).data('uid');
		const issueUid = $(e.currentTarget).data('issue');
		this[`attachments${issueUid || ''}`] = _.filter(this[`attachments${issueUid || ''}`], (item) => item.uid !== uid);
		this.renderAttachments(issueUid);
	},

	uploadAttachment(files, issueUid) {
		const attachments = [];
		let thisAttachments = issueUid ? this[`attachments${issueUid}`] : this.attachments;
		_.each(files, (item, index) => {
			if (!this.validAttachmentTypes.includes(item.type)) return;
			new Promise((resolve) => {
				const reader = new FileReader();
				reader.onload = (file) => {
					resolve({
						uid: item.name,
						name: item.name,
						size: item.size,
						content: file.target.result.split(',')[1],
						type: this.getAttachmentType(item.name),
						create: new Time().format('yyyy-MM-DDTHH:mm:ss.SSSZZ'),
					});
				};
				reader.readAsDataURL(item);
			}).then(result => {
				thisAttachments = [...attachments, ...thisAttachments];
				if (!thisAttachments.find((el) => el && el.uid === result.uid)) attachments.push(result);
				if (index === files.length - 1) {
					thisAttachments = [...attachments, ...thisAttachments];
					if (issueUid) {
						this[`attachments${issueUid}`] = thisAttachments;
					} else {
						this.attachments = thisAttachments;
					}
					this.renderAttachments(issueUid);
				}
			});
		});
	},

	getAttachmentType(fileName) {
		const ext = fileName.split('.')[-1];
		if (['xls', 'xlsm'].includes(ext)) {
			return 'EXCEL';
		} else if (ext === 'xlsx') {
			return 'EXCEL_2007';
		} else if (['doc', 'docx', 'docm'].includes(ext)) {
			return 'WORD';
		} else if (['htm', 'html'].includes(ext)) {
			return 'HTML';
		} else if (ext === 'pdf') {
			return 'PDF';
		} else if (ext === 'xml') {
			return 'XML';
		} else if (ext === 'txt') {
			return 'TEXT';
		} else if (ext === 'gif') {
			return 'GIF';
		} else if (ext === 'dbf') {
			return 'DBF';
		} else if (['jpg', 'jpeg'].includes(ext)) {
			return 'JPEG';
		} else if (ext === 'png') {
			return 'PNG';
		} else if (ext === 'css') {
			return 'CSS';
		} else if (ext === 'ico') {
			return 'ICO';
		} else if (ext === 'svg') {
			return 'SVG';
		}
		return 'BINARY';
	},

	uploadAttachmentMobile(e) {
		if (!e) return;
		const issueUid = $(e.currentTarget).data('issue');
		const accept = this.validAttachmentTypes.map(el => el).join(', ');
		const $fileInput = $(`<input type="file" accept="${accept}" multiple/>`);
		const fileInputCallback = () => {
			$fileInput.off('change', fileInputCallback);
			this.uploadAttachment($fileInput.prop('files'), issueUid);
		};
		$fileInput.on('change', fileInputCallback);

		$fileInput.click();
	},

	renderAttachments(issueUid) {
		const container = issueUid ? this.$(`.js-attachments-container-${issueUid}`) : this.ui.attachments;
		container.empty().append(AttachmentsTemplate({
			attachments: issueUid ? this[`attachments${issueUid}`] : this.attachments,
			issueUid,
		}));
	},

	/**
	 * Check keydown event on textarea
	 * @param {Event} keydown event
	 */
	changeMessageHandler(event) {
		const {keyCode, metaKey, ctrlKey} = event;
		const issueUid = $(event.currentTarget).parents('[data-issue]').data('issue');

		if (keyCode === enumKeyboard.ENTER && (metaKey || ctrlKey)) {
			event.preventDefault();
			this.createCorrespondence(null, issueUid);
		}

		this.model.set(issueUid ? `notes.${issueUid}` : 'notes.default', event.target.value);
	},

	// UTILS

	/**
	 * Render issues list
	 */
	updateCorrespondence() {
		const $toggledIssues = this.ui.issuesContainer.find('[data-issue-uid]:not(.collapsed)');
		const toggledIssuesUids = _.map($toggledIssues, ($el) => $el.dataset.issueUid);

		if (!_.isEmpty(this.toggledIssuesUids)) {
			toggledIssuesUids.push(...this.toggledIssuesUids);
			this.toggledIssuesUids = [];
		}

		if (!_.isEmpty(this.issues)) this.ui.emptyIssuesNotesContainer.remove();

		const $compileTemplate = issuesTemplate({
			issues: this.issues,
			toggledIssuesUids,
		});
		this.ui.issuesContainer.html($compileTemplate);
		this.bindWidgets(this.ui.issuesContainer);

		if (!_.isEmpty(this.issues)) {
			_.each(this.issues, (i) => {
				this[`attachments${i.uid}`] = [];
				const container = this.ui.issuesContainer.find(`.js-dragNDropContainer-${i.uid}`);
				const background = this.ui.issuesContainer.find(`.js-dragNDropBackground-${i.uid}`);
				this.dragAndDropListenersInit(container, background);
			});
		} else {
			this.dragAndDropListenersInit(this.ui.dragNDropContainer, this.ui.dragNDropBackground);
		}
	},

	/**
	 * Changed issues' data to need format
	 * @param {Array} issues
	 * @return {Array} changed issues
	 */
	preProcessData(issues = []) {
		const profile = STATE.getProfile();

		return _.map(issues, (issue) => {
			issue.posts = _.chain(issue.posts).map((post) => {
				post.date = new Time(post.createDate);
				post.outself = (profile && post.author)
					? profile.uid !== post.author.uid
					: true;

				post.onlyAttachment = _.some(post.attachments, (attach) => {
					return post.content && (attach.name === post.content);
				},
				);
				_.map(post.attachments, (attachment) => {
					attachment.size = this.formatSizeFile(attachment.size);
					return attachment;
				});

				return post;
			})
				.groupBy((post) => post.date.toServerDate())
				.value();

			return issue;
		});
	},

	/**
	 * Get base request parameters
	 * @return {Object} base parameters
	 */
	getRequestParams() {
		return {
			booking: this.booking,
		};
	},

	/**
	 * Get data from HTTP-response
	 * @param {Object} response
	 * @return {Object} data
	 */
	getResult(response) {
		const {data: {result = {}}} = response;
		return result;
	},

	/**
	 * Format size of file
	 * @param {Number} bytes
	 * @param {Number} decimals
	 * @return {String} Human format size of file
	 */
	formatSizeFile(bytes = 0, decimals = 2) {
		if (!bytes) {
			return '';
		}

		const k = 1024;
		const dm = (decimals < 0) ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));
		const value = parseFloat((bytes / k ** i).toFixed(dm));

		return [value, sizes[i]].join(' ');
	},

});
