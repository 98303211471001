import React from "react";
import { TravelSubject } from "../../../../../../../../../../helpers/interfaces/TravelSubject";
import { Container } from "./styled";
import "./style.less";

interface ServiceIconProps {
	travelSubject: TravelSubject;
}

const ServiceIcon: React.FC<ServiceIconProps> = (props) => {
	const { travelSubject } = props;
	return (
		<Container
			className={`r-service-icon g-icon-service--type-${travelSubject?.uid || "unknown"}`}
		/>
	);
};

export default ServiceIcon;
