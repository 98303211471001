// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';

export default BaseModel.extend({

	defaults() {
		return {
			docoInfo: new BaseModel({
				visa: true,
			}),
			docaInfo: new BaseModel({
				addressType: 'DESTINATION',
			}),
		};
	},

	toJSON() {
		const result = BaseModel.prototype.toJSON.call(this);

		if (result.docoInfo != null) {
			result.docoInfo.visa = String(result.docoInfo.visa) === 'true';
		}

		return result;
	},

});
