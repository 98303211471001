// eslint-disable-next-line banned-modules
'use strict';

import axios from 'axios';

/**
 * constructor params:
 * @param routeType string | enum
 * @param transliterationOpts should be passed to constructor and be updated when smth changes in parent
 */

class BookingAutoCorrectionAdapter {
	propertiesToTriggerCheck = [
		'passport.lastName',
		'passport.firstName',
		'passport.middleName',
		'passport.secondName',
	];

	requests = {};

	widgets = []; // <- должны быть инстансами Backbone.View

	constructor(params) {
		this.routeType = params.routeType;
		if (this.routeType && this.routeType.indexOf('RAILWAY') !== -1) {
			this.routeType = 'rail';
		}
		this.updateTransliterationOpts(params.transliterationOpts || {});
	}

	updateTransliterationOpts = (transliterationOpts) => {
		this.transliterationOpts = transliterationOpts;
	};

	isTriggerProperty = (property) => {
		return this.propertiesToTriggerCheck.includes(property);
	};

	makeTranslitRequest = ({
		value,
		transliterationPopupInstance,
	}) => {
		this.clearRunningRequest(transliterationPopupInstance.bindingProperty);
		this.requests[transliterationPopupInstance.bindingProperty] = {
			requestCancelToken: axios.CancelToken.source(),
		};
		this.requests[transliterationPopupInstance.bindingProperty].translitRequestTimer = setTimeout(() => {
			axios.post(`/midoffice/ibecorp-b2b/${this.routeType.toLowerCase()}/bookingDataAutoCorrection`, {
				parameters: this.transliterationOpts,
			}, {
				cancelToken: this.requests[transliterationPopupInstance.bindingProperty].requestCancelToken,
			}).then((resp) => {
				const passport = resp.data.result.traveller.passport;
          
				if (!passport[transliterationPopupInstance.bindingProperty.split('.')[1]]) {
					transliterationPopupInstance.close();
					return;
				}
        
				value = passport[transliterationPopupInstance.bindingProperty.split('.')[1]];
          
				transliterationPopupInstance.applyTransliterationValueToEl(value);
			}).catch((err) => {
				if (axios.isCancel(err)) return;
				throw err;
			});
		}, 1);
	};

	_clearRequest = (key) => {
		if (this.requests[key]) {
			if (this.requests[key]?.requestCancelToken?.cancel) {
				this.requests[key].requestCancelToken.cancel();
			}
			if (this.requests[key]?.translitRequestTimer) {
				clearTimeout(this.requests[key].translitRequestTimer);
			}
			delete this.requests[key];
		}
	};

	clearRunningRequest = (key) => {
		if (key) {
			this._clearRequest(key);
		} else {
			Object.keys(this.requests).forEach((k) => {
				this._clearRequest(k);
			});
		}
	};

	/* Widgets handlers */

	addWidget = (widget) => {
		this.widgets.push(widget);
	};

	removeAllWidgets = () => {
		this.widgets.forEach((w) => {
			w.remove();
		});
	};

	/* EOF Widgets handlers */
}

export default BookingAutoCorrectionAdapter;
