// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

export default BaseView.extend({

	template,

	el: '.l-layout__footer',

	initialize() {
		this.copyright = window.ibe.settings.visualSettings?.bottomBlock;
		this.copyright = this.copyright.replace('${Copyright}', L10N.get('footer.footerCopyright'));
		this.render(this.copyright);
	},

});
