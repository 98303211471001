module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-hotel-pricing">\n	<div class="b-hotel-pricing__header">' +
((__t = ( L10N.get("pricing.title") )) == null ? '' : __t) +
'</div>\n	';
 _.each(offers, function(offer, i) {
		var travellersInfo = offer.travellersInfo || [];
		var passengerTypes = [];

		_.each(travellersInfo, function (count, type) {
			var item = L10N.get("passengerTypes." + type);
			if (count > 1) {
				item += ' x ' + count;
			}

			passengerTypes.push(item);
		});
		var pPrices = offer.price && offer.price.passengerPrices || [];
		var types = ['tariff', 'fee', 'taxes', 'techFees', 'supplierFees', 'agencyFees', 'feeAndTaxes', 'discount'];
	;
__p += '\n        <div class="b-hotel-pricing__section">\n            <div class="b-hotel-pricing__room">' +
((__t = ( (i + 1) )) == null ? '' : __t) +
' ' +
((__t = ( L10N.get('hotels.room') )) == null ? '' : __t) +
'</div>\n            <div class="b-hotel-pricing__services">\n                <div class="b-hotel-pricing__service b-hotel-pservice">\n                    ';
 if (_.size(passengerTypes) > 0) { ;
__p += '\n                        <div class="b-hotel-pservice__caption">' +
((__t = ( passengerTypes.join(', ') )) == null ? '' : __t) +
'</div>\n                    ';
 } ;
__p += '\n\n                    ';
 _.each(pPrices, function(value) {
                        var passengerPrice = _.extend({}, value, value.feeDetails);
                        _.each(types, function(type) {
                            var priceValue = passengerPrice[type];
                            if (priceValue != null && parseFloat(priceValue.amount) !== 0) { ;
__p += '\n                                <div class="b-hotel-pservice__element">\n                                    ';
 if (offer.provider.uid === 'SABRE' && type === 'agencyFees') { ;
__p += '\n                                        <div class="b-hotel-pservice__caption-mr">' +
((__t = ( L10N.get('cabinet.orders.paymentInAgency'))) == null ? '' : __t) +
'</div>\n                                    ';
 } ;
__p += '\n                                    <span class="b-hotel-pservice__element-caption">' +
((__t = ( L10N.get("pricing." + type) )) == null ? '' : __t) +
'</span>\n                                    <span class="b-hotel-pservice__element-price">\n                                        ' +
((__t = ( Formatter.formatMoney(priceValue.amount, Formatter.getDecimal(priceValue.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(priceValue.currencyCode) )) == null ? '' : __t) +
'\n                                    </span>\n                                </div>\n                            ';
 }
                        }) ;
__p += '\n                    ';
 }) ;
__p += '\n                </div>\n\n                ';
 if (!_.isEmpty(offer.additionalServices)) { ;
__p += '\n                    <div class="b-hotel-pricing__service b-hotel-pservice">\n                        <div class="b-hotel-pservice__caption">' +
((__t = ( L10N.get('hotels.additionalServiceShort') )) == null ? '' : __t) +
':</div>\n                        ';
 if (offer.additionalServices.checkIn) { ;
__p += '\n                            <div class="b-hotel-pservice__element">\n                                <div class="b-hotel-pservice__element-caption">' +
((__t = ( L10N.get('hotels.addServices.earlyCheckIn') )) == null ? '' : __t) +
'</div>\n                                <div class="b-hotel-pservice__element-price">\n                                    ';
 if (offer.additionalServices && offer.additionalServices.checkInAmount) { ;
__p += '\n                                        ' +
((__t = ( Formatter.formatMoney(offer.additionalServices.checkInAmount, Formatter.getDecimal(offer.additionalServices.checkInAmount)) )) == null ? '' : __t) +
'\n                                        ' +
((__t = ( Formatter.formatCurrency(offer.price.total.currencyCode) )) == null ? '' : __t) +
'\n                                    ';
 } else { ;
__p += '-';
 } ;
__p += '\n                                </div>\n                            </div>\n                        ';
 } ;
__p += '\n                        ';
 if (offer.additionalServices.checkOut) { ;
__p += '\n                            <div class="b-hotel-pservice__element">\n                                <div class="b-hotel-pservice__element-caption">' +
((__t = ( L10N.get('hotels.addServices.lateCheckout') )) == null ? '' : __t) +
'</div>\n                                <div class="b-hotel-pservice__element-price">\n                                    ';
 if (offer.additionalServices && offer.additionalServices.checkOutAmount) { ;
__p += '\n                                        ' +
((__t = ( Formatter.formatMoney(offer.additionalServices.checkOutAmount, Formatter.getDecimal(offer.additionalServices.checkOutAmount)) )) == null ? '' : __t) +
'\n                                        ' +
((__t = ( Formatter.formatCurrency(offer.price.total.currencyCode) )) == null ? '' : __t) +
'\n                                    ';
 } else { ;
__p += '-';
 } ;
__p += '\n                                </div>\n                            </div>\n                        ';
 } ;
__p += '\n                        ';
 if (!_.isEmpty(offer.additionalServices.meals)) { ;
__p += '\n                            <div class="b-hotel-pservice__element">\n                                <div class="b-hotel-pservice__element-caption">' +
((__t = ( L10N.get('hotels.meal') )) == null ? '' : __t) +
'</div>\n                                <div class="b-hotel-pservice__element-price">\n                                    ';
 if (offer.additionalServices && offer.additionalServices.mealAmount) { ;
__p += '\n                                        ' +
((__t = ( Formatter.formatMoney(offer.additionalServices.mealAmount, Formatter.getDecimal(offer.additionalServices.mealAmount)) )) == null ? '' : __t) +
'\n                                        ' +
((__t = ( Formatter.formatCurrency(offer.price.total.currencyCode) )) == null ? '' : __t) +
'\n                                    ';
 } else { ;
__p += '-';
 } ;
__p += '\n                                </div>\n                            </div>\n                        ';
 } ;
__p += '\n                    </div>\n                ';
 } ;
__p += '\n                <div class="b-hotel-pservice__element b-hotel-pricing__total">\n                    <div class="b-hotel-pservice__element-caption">' +
((__t = ( L10N.get('pricing.total') )) == null ? '' : __t) +
'</div>\n                    <div class="b-hotel-pservice__element-price">\n                        <div class="b-hotel-pservice__element-price-text">\n                            ' +
((__t = ( Formatter.formatMoney(offer.price.total.amount, Formatter.getDecimal(offer.price.total.amount)) )) == null ? '' : __t) +
'\n                            ' +
((__t = ( Formatter.formatCurrency(offer.price.total.currencyCode) )) == null ? '' : __t) +
'\n                        </div>\n                        <div class="b-hotel-pservice__element-vat">\n                            ';
 if ((offer.price.vat || offer.price.vat === 0) && offer.price.vat >= 0) { ;
__p += '\n                                ' +
((__t = ( L10N.get('hotels.vatEnabled') )) == null ? '' : __t) +
' ' +
((__t = ( offer.price.vat )) == null ? '' : __t) +
'%\n                            ';
 } else { ;
__p += '\n                                ' +
((__t = ( L10N.get('hotels.withOutVat') )) == null ? '' : __t) +
'\n                            ';
 } ;
__p += '\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n	';
 }); ;
__p += '\n</div>\n';

}
return __p
}