import BaseView from '@/classes/base.view';
import template from './template.ejs';
import SettingView from './b-notification-setting/index';
import './style.less';

export default BaseView.extend({
  
	template,

	ui: {
		container: '.b-notifications',
	},

	initialize() {
		this.render();
	},

	render() {
		this.setElement(this.template(this.options));
		this.collection.each(itemModel => {
			const view = new SettingView({
				model: itemModel, 
			});
			this.$el.find('.b-notifications').append(view.$el);
		});
	},

});
