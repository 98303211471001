// eslint-disable-next-line banned-modules
'use strict';

import FilterBaseModel from '@/blocks/elements/b-filters/base.model';
import View from './view';

export default FilterBaseModel.extend({

	defaults() {
		return {
			label: '',
			min: Number.MAX_SAFE_INTEGER,
			max: 0,
			value: [0, 1],
		};
	},

	View,

	initialize(attr, options) {
		this.options = options || {};
	},

	parse(value) {
		return parseFloat(value);
	},

	filter(model) {
		const field = this.get('field');
		const range = this.get('value');

		const value = this.parse(model.get(field));
		return (value === 0 || (range[0] <= value && value <= range[1]));
	},

	isValid() {
		return (this.get('max') !== this.get('min')) && (this.get('min') < this.get('max'));
	},

	collect(model) {
		const field = this.get('field');

		const fields = [field];
		const val = this.get('value');

		if (!_.isEmpty(fields)) {
			_.each(fields, (f) => {
				const value = this.parse(model.get(f));

				if (!isNaN(value) && value !== 0) {
					if (this.get('min') > value) {
						this.set('min', value);
						val[0] = value;
						this.set('value', val);
					}
					if (this.get('max') < value) {
						this.set('max', value);
						val[1] = value;
						this.set('value', val);
					}
				}
			});
		}
	},

});
