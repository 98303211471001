// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';

export default Widget.extend({

	validationTemplate,

	template,

	initialize(options) {
		this.options = {
			// eslint-disable-next-line no-unneeded-ternary
			sectionDisable: options.sectionDisable === 'false' ? false : true,
			sectionName: options.sectionName,
			iconClassName: options.iconClassName,
			// eslint-disable-next-line no-unneeded-ternary
			sectionCurrent: options.sectionCurrent === 'false' ? false : true,
			path: options.path,
			classes: options.classes,
			target: options.target === 'undefined' ? undefined : options.target,
		};
		this.render();
	},

});
