// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

export default BaseView.extend({

	template,

	className: 'b-ticket b-ticket__visible',

	initialize() {
		this.render();
		if (this.options.classes != null && this.options.classes !== '') {
			this.options.classes.split(' ').forEach(cl => {
				this.$el.get(0).classList.add(cl);
			});
		}
	},

});
