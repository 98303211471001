// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import TicketView from '@/blocks/elements/b-flight-info/b-ticket-info/';
import PricingView from '@/blocks/elements/b-pricing/index';
import PassengersView from '@/blocks/elements/b-flight-info/b-flight-info-passengers/index';
import template from './template.ejs';

export default BaseView.extend({

	template,

	ui: {
		legs: '.b-flight-info__legs',
		limit: '.b-flight-info__limit',
		pricing: '.b-flight-info__pricing',
		passengers: '.b-flight-info__passengers',
	},

	initialize(options) {
		this.render(options);

		const ticketView = new TicketView({
			flight: this.model,
			classes: 'b-ticket-small',
		});
		this.ui.legs.append(ticketView.$el);
		const pricingView = new PricingView({
			price: this.model.price,
			priceBack: this.model.priceBack,
			travelSubject: 'AIR',
		});
		this.ui.pricing.append(pricingView.$el);
		if (options.getPassengers) {
			const passengerView = new PassengersView({
				passengers: this.model.passengers,
				customer: this.model.customer,
				el: this.ui.passengers,
			});
			logger.debug(passengerView);
		}
	},

});
