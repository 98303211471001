module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<label class="b-input b-passenger-autocomplete ' +
((__t = ( classes )) == null ? '' : __t) +
'">\n	<span class="b-input__label b-passenger-autocomplete__label">' +
((__t = ( label )) == null ? '' : __t) +
'</span>\n	<input class="b-input__value b-passenger-autocomplete__value" type="text" placeholder="' +
((__t = ( placeholder )) == null ? '' : __t) +
'">\n</label>\n';

}
return __p
}