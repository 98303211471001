// eslint-disable-next-line banned-modules
'use strict';

import './style.less';

import BaseView from '@/classes/base.view';
import template from './template.ejs';

import PdfView from '@/blocks/utils/b-pdf-view/index';

export default BaseView.extend({

	template,

	events: {
		'click .js-cancel': 'closeModal',
		'click .js-print': 'print',
	},

	ui: {
		pdfContainer: '.b-cabinet-order-pdf-viewer__pdf-view',
	},

	initialize() {
		this.render();
	},

	render(...args) {
		BaseView.prototype.render.apply(this, args);

		const {previewDocument, label = L10N.get('cabinet.orders.pdf.print')} = this.options.data;
		this.PdfView = new PdfView({
			pdfSource: previewDocument.content,
		});
		this.ui.pdfContainer.html(this.PdfView.$el);

		this.PdfView.pdfDocument.promise.then(() => {
			this.modal = new Widgets.Popup({
				content: this.$el,
				title: label,
				actions: [],
				type: 'simple',
				classes: 'b-cabinet-order-pdf-viewer__pdf-modal',
			});
			this.modal.show();
		});
	},

	// EVENTS
	print() {
		this.closeModal();

		if (this.PdfView != null) {
			this.PdfView.print();
		}

		if (_.isFunction(this.options.printCallback)) {
			this.options.printCallback();
		}
	},

	closeModal() {
		this.modal.hide();
	},

});
