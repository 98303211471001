module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-order-issue__posts-item b-order-issue__post ' +
((__t = ( post.outself ? 'b-order-issue__post--outself' : '' )) == null ? '' : __t) +
'">\n    <div class="b-order-issue__post-author">\n        ' +
((__t = ( [ (post.author || {}).caption, post.date.toPostTime() ].join(', ') )) == null ? '' : __t) +
'\n    </div>\n    ';
 if (post.onlyAttachment) { ;
__p += '\n        <div class="b-order-issue__post-content">\n            ' +
((__t = (	require('./template.attachments.ejs')({ attachments: post.attachments }) )) == null ? '' : __t) +
'\n        </div>\n    ';
 } else { ;
__p += '\n        <div class="b-order-issue__post-content">\n            <pre>' +
((__t = ( post.content || '<em>' + L10N.get('cabinet.orders.notes.noContent') + '</em>' )) == null ? '' : __t) +
'</pre>\n        </div>\n        ';
 if (!_.isEmpty(post.attachments)) { ;
__p += '\n            <div class="b-order-issue__post-attachments">\n                ' +
((__t = (	require('./template.attachments.ejs')({ attachments: post.attachments }) )) == null ? '' : __t) +
'\n            </div>\n        ';
 } ;
__p += '\n    ';
 } ;
__p += '\n</div>\n';

}
return __p
}