// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import PersonView from '@/blocks/pages/b-cabinet/b-cabinet-person';
import CabinetCollectionView from '@/blocks/pages/b-cabinet/b-cabinet-collection';
import template from './template.ejs';
import Model from './model';

import axios from 'axios';

export default BaseView.extend({

	template,

	ui: {
		container: '.b-cabinet-employee__content',
		organizations: '.b-cabinet-employee__organizations',
		person: '.b-cabinet-employee__person',
	},

	events: {
		'click .b-cabinet-employee__save': 'save',
		'click .b-employee-restore': 'restore',
		'click .b-employee-remove': 'removeEmployee',
	},

	initialize() {
		const { employeeInfo, loadEmployeeInfo, reRenderPage } = this.options;

		this.loadEmployeeInfo = loadEmployeeInfo;
		this.reRenderPage = reRenderPage;

		this.beforeRender(employeeInfo);
		this.render();
		this.afterRender();
	},

	beforeRender(employeeInfo) {
		this.isNew = _.isEmpty(employeeInfo) || !employeeInfo.employee.uid;
		this.isDisabled = !!employeeInfo?.employee?.disabled;
		this.editPersonAllowed = employeeInfo && employeeInfo.editPersonAllowed != null ? employeeInfo.editPersonAllowed : true;
		this.model = !_.isEmpty(employeeInfo) ? new Model(employeeInfo.employee, {parse: true}) : new Model();

		const organizationInfos = this.model.get('organizationInfos');
    
		this.off(organizationInfos);
		this.listenTo(organizationInfos, 'changeMainEmployment', (model, value) => {
			this.model.trigger('changeMainEmployment', model, value);
		});

		const organizationsCollection = organizationInfos;
		if (organizationsCollection.length === 0) {
			const defaultOrganizationsCollection = new organizationsCollection.model();
			organizationsCollection.reset();

			const corporateSettings = STATE.getSettings().corporateSettings;
			if (_.isObject(corporateSettings) &&
				_.isObject(corporateSettings.defaultCompanyClient)) {
				defaultOrganizationsCollection.set('organization', corporateSettings.defaultCompanyClient);
			}

			defaultOrganizationsCollection.set('mainEmployment', 'true');
			organizationsCollection.add(defaultOrganizationsCollection);
		}
	},

	afterRender() {
		if (!this.editPersonAllowed) {
			_.each(this.$el.find('*'), (el) => this.$(el).attr('tabindex', -1));

			this.$el.keydown((e) => {
				if ((e.keyCode || e.which) === 9) e.preventDefault();
			});
		}
	},

	render() {
		this.$el.empty();
		this.setElement(template(_.extend({
			isDisabled: this.isDisabled,
			isDisablePersonAllowed: !this.isNew && this.options.employeeInfo.isDisablePersonAllowed === true,
			editPersonAllowed: this.editPersonAllowed === true,
		}, this.model.attributes)));

		const person = new PersonView({
			model: this.model,
			personType: 'employee',
			advancedPersonForm: true,
			useProfileCostCodes: true,
			isNew: this.isNew,
		});
		this.ui.person.html(person.$el);
		this.organizationView = new CabinetCollectionView({
			collection: this.model.get('organizationInfos'),
			collectionViewOptions: {
				isNew: this.isNew,
			},
			title: L10N.get('cabinet.contacts.documentsTitle'),
			container: this.ui.organizations,
		});
	},

	getEmployeeRef() {
		return this.isNew || this.options.employeeInfo.employee.uid;
	},
  
	async reloadData() {
		// get current employee uid
		const employeeRef = this.getEmployeeRef();

		// fetch data from server
		const {employeeInfo = {}} = await this.loadEmployeeInfo(employeeRef);

		this.options.employeeInfo = employeeInfo;
		// re-render
		/*
		this.beforeRender(employeeInfo);
		this.render();
		this.afterRender();*/

		this.reRenderPage(employeeInfo);
	},

	restore(event) {
		event.preventDefault();

		const self = this;
    
		const parameters = {
			uid: this.model.get('uid'),
		};

		STATE.showLoader();
		this.disableElements(event);

		axios.post(`/midoffice/ibecorp-b2b/cabinet/activatePerson`, {
			parameters,
		}).then(() => {
			STATE.hideLoader();
			if (self?.loadEmployeeInfo) {
				self.reloadData();
			}
		}).catch((e) => {
			logger.debug(e);
			if (e && e.error === 'validationError') {
				const popup = new Widgets.Popup({
					type: 'danger',
					content: `
						<div>
							${L10N.get('cabinet.successfulSavedPopup.failed')}
						</div>
					`,
					actions: [{
						label: L10N.get('cabinet.orders.close'),
						action: () => {
							popup.hide();
						},
					}],
				});
				popup.show();
			}
		});
	},

	save(event) {
		event.preventDefault();

		const parameters = {
			employee: this.model.toJSON(),
		};

		STATE.showLoader();
		this.disableElements(event);
		axios.post(`/midoffice/ibecorp-b2b/cabinet/${(this.isNew === true ? 'createEmployee' : 'updateEmployee')}`, {
			parameters,
		}, this.model).then(() => {
			STATE.hideLoader();

			if (this.isNew) {
				STATE.navigate(STATE.ROUTES.CABINET_EMPLOYEES);
				this.model.clear();
			}
		}).catch((e) => {
			logger.debug(e);
			if (e && e.error === 'validationError') {
				const popup = new Widgets.Popup({
					type: 'danger',
					content: `
						<div>
							${L10N.get('cabinet.successfulSavedPopup.failed')}
						</div>
					`,
					actions: [{
						label: L10N.get('cabinet.orders.close'),
						action: () => {
							popup.hide();
						},
					}],
				});
				popup.show();
			}
		});
	},

	removeEmployee(e) {
		if (e) {
			e.preventDefault();
		}

		const employerName = _.compact([
			this.model.get('lastName'),
			this.model.get('firstName'),
			this.model.get('middleName'),
		]).join(' ');
		const popup = new Widgets.Popup({
			content: `
				<div class="b-dialog__popup-confirm-quastion">${L10N.get('cabinet.employees.deleteEmployee')}</div>
				<div class="b-dialog__popup-confirm-content">${employerName}</div>
			`,
			title: L10N.get('cabinet.employees.confirm'),
			type: 'danger',
			actions: [
				{
					label: L10N.get('cabinet.employees.cancel'),
					action: () => {
						popup.hide();
					},
				},
				{
					label: L10N.get('cabinet.employees.delete'),
					action: ($currentTarget) => {
						$currentTarget.attr('disabled', true);
						STATE.showLoader();
						this.disableElements(e);
						axios.delete('/midoffice/ibecorp-b2b/cabinet/disablePerson', {
							data: {
								parameters: {
									uid: this.model.get('uid'),
								},
							},
						}, this.model).then(() => {
							STATE.navigate(STATE.ROUTES.CABINET_EMPLOYEES);
							$currentTarget.attr('disabled', false);
							popup.hide();
							STATE.hideLoader();
						}).catch(() => {
							popup.hide();
							STATE.hideLoader();
						});
					},
				},
			],
			classes: 'b-dialog__popup-confirm',
		});
		popup.show();
	},

});
