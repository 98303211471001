// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';

const TrainModel = BaseModel.extend({

	defaults: {
		visibility: true,
	},

	initialize(args, options) {
		const {hasTravelPolicy = false} = options || {};
		const travelPolicyComplianceTypes = [];

		const types = {};
		const train = {
			types: [],
			prices: [],
			totalDurationInMinutes: 0,
			departureDate: [],
			arrivalDate: [],
			departureLocations: [],
			arrivalLocations: [],
			travelPolicyCompliance: [],
		};

		if (options.currencyCode != null) {
			this.set('currencyCode', options.currencyCode);
		}

		if (this.get('carriageInfos') != null && this.get('carriageInfos').length > 0) {
			this.get('carriageInfos').forEach(c => {
				train.prices.push(parseFloat(c.price));
				types[c.type.uid] = {
					uid: c.type.uid,
					caption: c.type.caption,
					price: parseFloat(c.price),
				};

				if (hasTravelPolicy) {
					if (c.travelPolicyCompliance != null && !_.isEmpty(c.travelPolicyCompliance.rulesApplied)) {
						travelPolicyComplianceTypes.push(..._.map(c.travelPolicyCompliance.rulesApplied, (rule) => rule.action));
					} else {
						travelPolicyComplianceTypes.push({caption: L10N.get('travelPolitics.noViolation'), uid: 'COMPLIANCE'});
					}
				}
			});

			Object.keys(types).forEach(k => {
				train.types.push(types[k]);
			});
		}
		this.get('segments').forEach(s => {
			train.totalDurationInMinutes += s.durationInMinutes;
		});
		const firstSegment = _.first(this.get('segments'));
		const lastSegment = _.last(this.get('segments'));

		train.departureDate.push(new Time(firstSegment.departureDate).getDayMinutes());
		train.arrivalDate.push(new Time(lastSegment.arrivalDate).getDayMinutes());

		train.departureLocations.push(Object.assign({}, firstSegment.departureLocation, {
			price: _.min(train.prices),
		}));
		train.arrivalLocations.push(Object.assign({},
			lastSegment.arrivalLocation,
			{
				price: _.min(train.prices),
			},
		));
		train.travelPolicyCompliance = _.uniq(travelPolicyComplianceTypes, (el) => el.uid);
		this.set('price', _.min(train.prices));
		this.set('_train', train);
		this.set('_category', [{
			uid: this.get('category') || 'UNKNOWN',
			caption: this.get('categoryDescription') || this.get('category') || L10N.get('trains.unknownCategory'),
			price: _.min(train.prices),
		}]);
		this.set('_brand', [{
			uid: this.get('brand') || 'UNKNOWN',
			caption: this.get('brand') || L10N.get('trains.unknownCategory'),
			price: _.min(train.prices),
		}]);
	},

	toJSON() {
		const json = BaseModel.prototype.toJSON.call(this);
		delete json._train;
		delete json.price;
		delete json._seatsRequest;
		return json;
	},

});

export default TrainModel;
