module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-hotel-additional-services">\n	<div class="b-hotel-additional-services__caption collapse--with-anchor--white mobile-collapse" data-toggle="collapse" data-target=".toggle-additional-services-' +
((__t = ( index )) == null ? '' : __t) +
'">' +
((__t = ( L10N.get('hotels.additionalServices') )) == null ? '' : __t) +
'</div>\n	';
 if (offer != null && offer.cancellationPolicyPricesMayChange === true) { ;
__p += '\n		<div class="b-notification b-notification--type-alert b-hotel-additional-services__notification">\n			<div><strong>' +
((__t = ( L10N.get('hotels.warning') )) == null ? '' : __t) +
'!</strong> ' +
((__t = ( L10N.get('hotels.warningPenalties') )) == null ? '' : __t) +
'</div>\n		</div>\n	';
 } ;
__p += '\n	';
 if (showRoomInfo && (offer.meal != null || offer.standardCheckIn || offer.standardCheckOut)) { ;
__p += '\n		<div class="b-hotel-room__info-wrapper">\n			';
 if (offer.standardCheckIn != null) { ;
__p += '\n				<div class="b-hotel-room__info-item">\n					<span class="b-hotel-room__info-service">' +
((__t = ( L10N.get('hotels.checkin') )) == null ? '' : __t) +
'</span>\n					<span class="b-hotel-room__info-value">' +
((__t = ( new Time(offer.standardCheckIn).toShortTime() )) == null ? '' : __t) +
'</span>\n				</div>\n			';
 } ;
__p += '\n\n			';
 if (offer.standardCheckOut != null) { ;
__p += '\n				<div class="b-hotel-room__info-item">\n					<span class="b-hotel-room__info-service">' +
((__t = ( L10N.get('hotels.checkout') )) == null ? '' : __t) +
'</span>\n					<span class="b-hotel-room__info-value">' +
((__t = ( new Time(offer.standardCheckOut).toShortTime() )) == null ? '' : __t) +
'</span>\n				</div>\n			';
 } ;
__p += '\n\n			';
 if (offer.meal != null) { ;
__p += '\n				<div class="b-hotel-room__info-item">\n					<span class="b-hotel-room__info-service">' +
((__t = ( L10N.get('hotels.meal') )) == null ? '' : __t) +
'</span>\n					<span class="b-hotel-room__info-value">\n						' +
((__t = ( (offer.meal != null && !['-1', 'nomeal'].includes(offer.meal.uid) && !['-', L10N.get('hotels.withoutMeal')].includes(offer.meal.caption)) ? offer.meal.caption : L10N.get('hotels.withoutMeal') )) == null ? '' : __t) +
'\n					</span>\n				</div>\n			';
 } ;
__p += '\n		</div>\n	';
 } ;
__p += '\n	<div class="b-hotel-additional-services__list-wrapper toggle-additional-services-' +
((__t = ( index )) == null ? '' : __t) +
' show">\n		<div class="b-hotel-additional-services__list">\n			<div class="l-grid-container"></div>\n            <div class="b-hotel-additional-services__mobile-reprice-warning">\n                <div>' +
((__t = ( L10N.get('hotels.mobileRepriceWarning_1') )) == null ? '' : __t) +
'</div>\n                <div class="js-reprice-warning-link">' +
((__t = ( L10N.get('hotels.mobileRepriceWarning_2') )) == null ? '' : __t) +
'</div>\n            </div>\n		</div>\n		<div class="b-hotel-additional-services__footer">\n			<div class="b-hotel-additional-services__total" style="display: none">\n				' +
((__t = ( Formatter.formatMoney(offer.price.total.amount, 0) )) == null ? '' : __t) +
'\n				' +
((__t = ( Formatter.formatCurrency(offer.price.total.currencyCode) )) == null ? '' : __t) +
'\n			</div>\n		</div>\n	</div>\n</div>\n';

}
return __p
}