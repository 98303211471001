module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


var offerSelected = offerSelected || false
var segments = this.model.get('segments');
var dateDepartureLocal = segments[0].localDepartureDate ? new Time(segments[0].localDepartureDate) : null;
var dateArrivalLocal = segments[0].localArrivalDate ? new Time(segments[0].localArrivalDate) : null;
;
__p += '\n<div class="b-train-offer-preview b-offer-preview">\n    <div class="b-train-offer-preview__header">\n        <button class="b-train-offer-preview__close-btn">' +
((__t = ( L10N.get('avia.goBack') )) == null ? '' : __t) +
'</button>\n    </div>\n    <div class="b-train-offer-preview__train-info">\n        <div class="b-train-offer-preview__train-info-row b-train-offer-preview__train-info-direction">\n            ' +
((__t = ( segments[0].trainDepartureLocation )) == null ? '' : __t) +
' - ' +
((__t = ( segments[0].trainArrivalLocation )) == null ? '' : __t) +
'\n        </div>\n        <div class="b-train-offer-preview__train-info-row">\n            <div class="b-train-offer-preview__train-info-date">' +
((__t = ( dateDepartureLocal.toMonthDay() )) == null ? '' : __t) +
'</div>\n            <div class="b-train-offer-preview__train-info-time">' +
((__t = ( dateDepartureLocal.toShortTime() )) == null ? '' : __t) +
'</div>\n            <div class="b-train-offer-preview__train-info-number">' +
((__t = ( number )) == null ? '' : __t) +
'</div>\n        </div>\n        <div class="b-train-offer-preview__train-info-row b-train-offer-preview__train-info-indicators js-car-indicator-container"></div>\n    </div>\n\n    <div class="b-train-offer-preview__wrapper">\n        <div class="b-train-offer-preview__car-info js-car-info">\n            <div class="b-train-offer-preview__controls">\n                <div class="b-carriage-maps__control b-train-offer-preview__slide-left g-icon-arrow-left"></div>\n                <div class="b-train-offer-preview__car-number js-car-number"></div>\n                <div class="b-carriage-maps__control b-train-offer-preview__slide-right g-icon-arrow-right"></div>\n            </div>\n            <div class="b-car-info__number js-car-number"></div>\n            <div class="b-car-info__type js-car-type"></div>\n            <div class="b-car-info__service-class js-car-service-class"></div>\n            <div class="b-car-info__prices js-car-prices"></div>\n            <div class="b-car-info__services js-car-services train-services-gray"></div>\n            <div class="b-car-info__messages js-car-messages"></div>\n            <div class="b-car-info__gender-desc js-car-gender-desc"></div>\n            <div class="b-car-info__seats-selector js-selector-seats"></div>\n            <div class="b-car-info__compartment-selector js-selector-compartment"></div>\n            <div class="b-car-info__gender-selector js-selector-gender"></div>\n        </div>\n        <div class="b-train-offer-preview__carriages js-carriages-view-container"></div>\n        <div class="b-train-offer-preview__footer js-footer">\n            <div class="b-train-offer-preview__travel-policy js-travel-policy"></div>\n            <div class="b-train-offer-preview__buttons js-buttons-view"></div>\n        </div>\n    </div>\n</div>\n';

}
return __p
}