// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';
import BaseCollection from '@/classes/base.collection';
import TravellerModel from '@/blocks/elements/b-hotel/b-hotel-traveller/model';

export default BaseModel.extend({

	defaults() {
		return {
			travellers: new BaseCollection([], {
				model: TravellerModel,
			}),
		};
	},

	toJSON() {
		const data = BaseModel.prototype.toJSON.call(this);

		if (!_.isEmpty(data.additionalServices)) {
			data.offer.additionalServices = data.additionalServices;

			data.additionalServices.checkOut = (data.additionalServices.checkOut || '').replace(/ /g, '');
			data.additionalServices.checkIn = (data.additionalServices.checkIn || '').replace(/ /g, '');

			if (!data.additionalServices.checkOut) {
				delete data.additionalServices.checkOut;
			}

			if (!data.additionalServices.checkIn) {
				delete data.additionalServices.checkIn;
			}

			delete data.additionalServices;
		}

		if (!_.isEmpty(data.remarksValue)) {
			_.each(data.offer.remarks, (r) => {
				const value = data.remarksValue[r.id];

				if (r.dataType.uid === 'STRING' && value) {
					r.value = r.title;
				}
				if (r.dataType.uid === 'DATETIME' && value) {
					r.value = `${(data.offer.checkIn || data.offer.checkOut).split('T')[0]}T${value.replace(/\s/g, '')}:00`;
					delete r.title;
				}
			});
		}

		return data;
	},

});
