import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { ReactUtils } from "../../../../js/utils/react-utils";
import Pikaday from "pikaday";
import Time from "../../../../js/utils/time";
import { StoreValue } from "rc-field-form/es/interface";
import { isSameDates } from "./helpers/utils";
import { FormItemControlProps } from "../FormItem/helpers/interfaces";

interface DatepickerProps extends FormItemControlProps {
	label?: string;
	classes?: string;
	placeholder?: string;
	pikadayModalCid?: string;
	dateFormat?: string;
	noMinDate?: boolean;
	minDate?: Date;
	depend?: boolean;
	dependOffset?: string;
	showDaysInNextAndPreviousMonths?: boolean;
	numberOfMonths?: number;
	bound?: boolean;
	keyboardInput?: boolean;
	value?: StoreValue;
	allowClear?: boolean;
	onSelect?: (date: Date | undefined) => void;
}

const Datepicker: React.FC<DatepickerProps> = (props) => {
	const {
		label,
		classes,
		placeholder = "",
		pikadayModalCid,
		dateFormat = "DD MMM",
		noMinDate,
		minDate,
		depend,
		dependOffset,
		showDaysInNextAndPreviousMonths = true,
		numberOfMonths = 1,
		bound = true,
		keyboardInput = true,
		value,
		allowClear = false,
		onSelect,
	} = props;

	const minDateComputed = useMemo(() => {
		if (noMinDate) {
			return null;
		} else if (minDate) {
			return minDate;
		} else if (depend && dependOffset) {
			const offset = dependOffset.split(" ");
			if (offset.length === 2) {
				return new Time()
					.getMoment()
					.add(parseInt(offset[0]), offset[1])
					.toDate();
			}
		} else {
			return new Date();
		}
	}, []);

	// update minDate on ref
	useEffect(() => {
		if (pickerRef && pickerRef.current) {
			pickerRef.current._minDate = minDate;
		}
	}, [minDate]);

	const onOpen = useCallback(() => {
		if (pickerRef.current) {
      pickerRef.current.setMinDate(pickerRef.current._minDate);
		}
	}, []);

	const inputRef = useRef(null);
	const pickerRef = useRef<any>(null);

	useEffect(() => {
		const time = new Time();
		pickerRef.current = new Pikaday({
			format: dateFormat,
			field: inputRef.current,
			minDate: minDateComputed,
			firstDay: 1,
			showDaysInNextAndPreviousMonths,
			enableSelectionDaysInNextAndPreviousMonths: true,
			numberOfMonths,
			keyboardInput: keyboardInput,
			bound,
			i18n: {
				months:
					time.getMoment().localeData().months().standalone ||
					time.getMoment().localeData().months(),
				weekdays:
					time.getMoment().localeData().weekdays().standalone ||
					time.getMoment().localeData().weekdays(),
				weekdaysShort:
					time.getMoment().localeData().weekdaysShort().standalone ||
					time.getMoment().localeData().weekdaysShort(),
			},
			onSelect,
			onOpen,
		});
	}, []);

	useEffect(() => {
		if (pickerRef.current) {
			const innerValue = pickerRef.current.getDate();
			if (!isSameDates(innerValue, value)) {
				pickerRef.current.setDate(value);
			}
		}
	}, [value]);

	const isValueFilled = !!value;

	const openIconClasses = useMemo(() => {
		if (allowClear && isValueFilled) {
			return "b-datepicker__remove";
		}
		return "b-datepicker__open";
	}, [allowClear, isValueFilled]);

	const onIconClick = useCallback(() => {
		if (allowClear && isValueFilled) {
			onSelect && onSelect(undefined);
		} else {
			pickerRef.current.show();
		}
	}, [allowClear, isValueFilled]);

	return (
		<div
			className={ReactUtils.classNames([
				"p-search__direction-date",
				"p-search__direction-date-wrapper",
				classes,
			])}
		>
			{label && <div className="p-search__direction-date-label">{label}</div>}
			<input
				ref={inputRef}
				type="text"
				placeholder={placeholder}
				data-toggle-modal={pikadayModalCid}
				className="p-search__input p-search__direction-date-input b-datepicker__field"
			/>
			<div className="p-search__direction-date-calendar">
				<div
					onClick={onIconClick}
					className={ReactUtils.classNames([
						"b-datepicker__icon",
						"p-search__direction-date-calendar-img",
						openIconClasses,
					])}
				></div>
			</div>
		</div>
	);
};

export default Datepicker;
