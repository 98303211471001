// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import CheckboxWidget from '@/widgets/b-checkbox/index';
import template from './template.ejs';

export default CheckboxWidget.extend({

	template,

	events: {
		'change .b-toggle__input': 'change',
	},

	ui: {
		input: '.b-toggle__input',
	},

	initialize() {
		this.render({
			position: this.options.position || 'left',
		});

		if (String(this.options.disabled) === 'true') {
			this.disable(true);
		}
	},

	change() {
		this.model.set(this.options.bindingProperty, this.ui.input.is(':checked'));
	},

	disable(disabled) {
		this.disabled = disabled;
		this.ui.input.attr('disabled', disabled);
	},

	applyBinding(model) {
		this.model = model;

		if (this.model.get(this.options.bindingProperty)) {
			this.ui.input.prop('checked', true);
		} else {
			this.ui.input.prop('checked', false);
		}

		this.listenTo(this.model, `change:${this.options.bindingProperty}`, (changedModel, value) => {
			this.ui.input.prop('checked', !!value);
		});

		this.listenTo(model, `validationError:${this.options.bindingProperty}`, () => {
			this.$el.addClass('b-checkbox__validation-error');
		});
	},

});
