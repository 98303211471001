// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';

export default Widget.extend({

	validationTemplate,

	template,

	events: {
		'change .b-textarea__value': 'change',
	},

	ui: {
		input: '.b-textarea__value',
	},

	initialize() {
		this.options.label = this.options.label || '';
		this.options.placeholder = this.options.placeholder || '';

		this.render(Object.assign({
			type: 'text',
		}, this.options));

		if (_.isObject(this.options.attrs)) {
			this.applyAttrs(this.options.attrs);
		}
	},

	applyAttrs(attrs) {
		_.each(attrs, (value, key) => {
			if (['true', 'false'].includes(value)) value = value === 'true';
			this.ui.input.attr(key, value);
		});
	},

	change() {
		this.model.set(this.options.bindingProperty, this.ui.input.val());
	},

	applyBinding(model) {
		this.model = model;
		this.listenTo(model, `change:${this.options.bindingProperty}`, (thisModel, value) => {
			this.ui.input.val(value || '');
		});
		this.ui.input.val(model.get(this.options.bindingProperty));
	},

});
