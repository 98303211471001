// eslint-disable-next-line banned-modules
'use strict';

import TravellersCollection from '@/blocks/pages/b-search-form/b-company-employees/b-employees/b-employees-collection/collection';
import EmployeeView from '@/blocks/pages/b-search-form/b-company-employees/b-employees/b-employees-collection/b-employee/index';

import BaseModel from '@/classes/base.model';
import View from './index';

export default BaseModel.extend({

	defaults: () => {
		return {
			travellers: new TravellersCollection([], {
				model: BaseModel.extend({
					View: EmployeeView,

					toJSON() {
						return this.get('travellers');
					},

					initialize(data) {
						this.attributes = {
							travellers: data,
						};
					},
				}),
			}),
		};
	},

	initialize(data = {}) {
		if (data == null) {
			return this;
		}

		this.set('travellers', new TravellersCollection(data.travellers || [], {
			model: BaseModel.extend({
				View: EmployeeView,

				toJSON() {
					return this.get('travellers');
				},

				initialize(d) {
					this.attributes = {
						travellers: d,
					};
				},
			}),
		}));

		return this;
	},

	View,

});
