module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-refund-exchange">\n    ';

    var reservation = reservation || {};
    var firstSegment = _.first(reservation.legs[0].segments);
    var lastSegment = _.last(reservation.legs[reservation.legs.length - 1].segments);
    var routeName = ''

    if (firstSegment.departureCity && firstSegment.departureCity.caption) {
        routeName += firstSegment.departureCity.caption;
    }
    routeName += ' - ';
    if (lastSegment.arrivalCity && lastSegment.arrivalCity.caption) {
        routeName += lastSegment.arrivalCity.caption;
    }
    routeName += ' | ' + new Time(firstSegment.departureDate).format('DD.MM.YY');
    ;
__p += '\n    <div class="b-refund-exchange__modal">\n        <div class="b-refund-exchange__header">' +
((__t = ( this.isExchange ? L10N.get('avia.ticketExchange') : L10N.get('cabinet.orders.refundConfirmation') )) == null ? '' : __t) +
'</div>\n        <div class="b-refund-exchange__content">\n            ';
 _.each(this.services, function(service) { ;
__p += '\n				<div class="b-refund-exchange__traveller-view">\n                    <div class="b-refund-exchange__traveller-service">\n                        <span class="b-refund-exchange__traveller-service__ticketNumber">\n                            <div>\n                            ' +
((__t = ( service.ticketNumber )) == null ? '' : __t) +
'\n                                ';
 if (!_.isEmpty(service._mco)) { ;
__p += '\n                                    ';
 _.each(service._mco, function(el) { ;
__p += '\n                                        <div>' +
((__t = ( el.ticketNumber )) == null ? '' : __t) +
'</div>\n                                    ';
 }) ;
__p += '\n                                ';
 } ;
__p += '\n                            </div>\n                        </span>\n                        <span class="b-refund-exchange__traveller-service__fullName">\n                           <div>\n                                ' +
((__t = ( _.map(service.travellers, function(t) { return t.fullName }).join(' | ') )) == null ? '' : __t) +
'\n                               ';
 if (!_.isEmpty(service._mco)) { ;
__p += '\n                                   ';
 _.each(service._mco, function(el) { ;
__p += '\n                                       <div>' +
((__t = ( L10N.get('trains.edmNa') )) == null ? '' : __t) +
' ' +
((__t = ( el.category && el.category.caption )) == null ? '' : __t) +
'</div>\n                                   ';
 }) ;
__p += '\n                               ';
 } ;
__p += '\n                           </div>\n                        </span>\n                    </div>\n                    <div class="b-refund-exchange__flight">\n                        <div class="b-refund-exchange__flight-header">\n                            <span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "selectedServices.'+ reservation._index +'.'+ service.uid +'.selected", label: "'+ routeName +'"})\'></span>\n                            <div class="b-refund-exchange__flight-price"></div>\n                        </div>\n                        ' +
((__t = ( require('./refund-exchange-flight-legs.ejs')({legs: reservation.legs}))) == null ? '' : __t) +
'\n                    </div>\n                </div>\n            ';
 }) ;
__p += '\n            ';
 if (!this.isExchange) { ;
__p += '\n                    <div class="b-refund-exchange__footer">\n                        ';
 var price = refund.refundPrice; ;
__p += '\n                        ' +
((__t = ( L10N.get('bookingForm.totalRefund') )) == null ? '' : __t) +
'\n                        <div class="b-refund-exchange__refund-price">\n                            <div class="b-refund-exchange__refund-price-details" data-html="true" data-toggle="tooltip" data-placement="top" data-original-title="' +
((__t = ( _.escape(require('@/blocks/elements/b-ticket/b-ticket-pricing.ejs')({price: price})) )) == null ? '' : __t) +
'"></div>\n                            <div class="b-refund-exchange__refund-price-total">' +
((__t = ( Formatter.formatMoney(price.total.amount, Formatter.getDecimal(price.total.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(price.total.currencyCode))) == null ? '' : __t) +
'</div>\n                        </div>\n                    </div>\n            ';
 } ;
__p += '\n        </div>\n        <div class="b-refund-exchange__modal-buttons">\n            <button class="b-refund-exchange__cancel js-cancel">' +
((__t = ( L10N.get('cabinet.orders.cancel') )) == null ? '' : __t) +
'</button>\n            <button class="b-refund-exchange__confirm js-confirm" data-reservation-id="' +
((__t = ( reservation._index )) == null ? '' : __t) +
'">' +
((__t = ( this.isExchange ? L10N.get('cabinet.orders.goToExchange') : L10N.get('cabinet.orders.confirmRefund') )) == null ? '' : __t) +
'</button>\n        </div>\n    </div>\n</div>\n';

}
return __p
}