module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-finance-autocomplete__item ' +
((__t = ( caption && caption.length ? '' : 'dn' )) == null ? '' : __t) +
'">\n    <span class="b-finance-autocomplete__item-caption">' +
((__t = ( caption )) == null ? '' : __t) +
'</span>\n</div>\n';

}
return __p
}