module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var transferType = transferType || {}
   var endPlace = endPlace || null;
   var startPlace = startPlace || null;
   var date = date || null;
   var backDate = backDate || null;
;
__p += '\n<div class="b-transfers-search-form b-transfers-search">\n	<div class="b-transfers-search__row">\n		<div class="b-transfers-search__direction">\n			<span class="js-widget"  onclick=\'return new Widgets.GeocoderInput({ bindingProperty: "startPlace", placeholder:  L10N.get("transfers.startPlace") })\'></span>\n            ';
 if (transferType && transferType.uid === 'TRANSFER') { ;
__p += '\n			<div class="b-transfers-search__direction-swap">\n				<div class="b-transfers-search__direction-swap-img"></div>\n			</div>\n			<span class="js-widget"  onclick=\'return new Widgets.GeocoderInput({ bindingProperty: "endPlace", placeholder:  L10N.get("transfers.endPlace") })\'></span>\n            ';
 };
__p += '\n		</div>\n		<div class="b-transfers-search__dates-container">\n			<div class="b-transfers-search__options-date-element b-transfers-search__options-date-element--type-date date-from-datepicker">\n				<span class="js-widget"  onclick=\'return new Widgets.Datepicker({ bindingProperty: "date", dateFormat: "DD MMM", placeholder: L10N.get("transfers.fromDate") })\'></span>\n			</div>\n			<div class="b-transfers-search__options-date-element b-transfers-search__options-date-element--type-dateBack backDate-from-datepicker">\n				<span class="js-widget"  onclick=\'return new Widgets.Datepicker({ bindingProperty: "backDate", dateFormat: "DD MMM", depend: "date", removable: true, placeholder: L10N.get("transfers.backDate") })\'></span>\n			</div>\n		</div>\n		<div class="b-transfers-search__button-container">\n			<div class="b-transfers-search__button">' +
((__t = ( L10N.get('transfers.search') )) == null ? '' : __t) +
'</div>\n		</div>\n	</div>\n    <div class="b-transfers-search-mobile">\n        <div class="b-transfers-search-mobile__search-form">\n            <div class="b-transfers-search-mobile__route">\n                ';
 var backDate = backDate || null ;
__p += '\n                ';
 if (startPlace != null && endPlace != null) { ;
__p += '\n                    ' +
((__t = ( startPlace.address )) == null ? '' : __t) +
' - ' +
((__t = ( endPlace.address )) == null ? '' : __t) +
'\n                ';
 } ;
__p += '\n            </div>\n            <div class="b-transfers-search-mobile__route-info">\n                ';
 if (date != null ) { ;
__p += '\n                    ' +
((__t = ( new Time(date).toMonthDay() )) == null ? '' : __t) +
'\n                ';
 } ;
__p += '\n                ';
 if (backDate != null) { ;
__p += '\n                    - ';
 new Time(backDate).toMonthDay() ;
__p += '\n                ';
 } ;
__p += ',\n                ';
 var passengersCount = 0;
                if (_.size(passengers.models)) {
                    _.each(passengers.models, function (m) {
                        if (m.attributes.passengers != null) passengersCount += _.size(m.attributes.passengers.models);
                    });
                } else {
                    passengersCount = _.reduce(Object.keys(passengersTypes.attributes), function (prev, acc) {
                        return prev + passengersTypes.attributes[acc];
                    }, 0);
                } ;
__p += '\n                ' +
((__t = ( passengersCount )) == null ? '' : __t) +
' ' +
((__t = ( L10N.getPluralForm('searchForm.passenger', passengersCount) )) == null ? '' : __t) +
',\n            </div>\n        </div>\n        <div class="b-transfers-search-mobile__filters-trigger"></div>\n    </div>\n    <div class="b-transfers-search-form-mobile__background"></div>\n</div>\n';

}
return __p
}