import BaseView from '@/classes/base.view';
import template from './template.ejs';

export default BaseView.extend({
  
	template,

	initialize() {
		this.render();
	},

	render() {
		this.setElement(this.template(this.options));
	},

});
