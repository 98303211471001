module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="w-timerange">\r\n	<div class="w-timerange__wrapper">\r\n		<input type="text" class="w-timerange__input ' +
((__t = ( classes )) == null ? '' : __t) +
'" placeholder="' +
((__t = (placeholder )) == null ? '' : __t) +
'" />\r\n		<div class="w-timerange__select">\r\n			<div class="w-timerange__select-img"></div>\r\n		</div>\r\n	</div>\r\n	<div class="w-timerange__suggest">\r\n		<ul class="w-timerange__suggest-list">\r\n			<li class="w-timerange__suggest-list-point" data-type="morning">\r\n					<div class="w-timerange__suggest-list-point-wrapper">\r\n						<span class="w-timerange__suggest-list-daytime">00:00 – 06:00</span>\r\n					</div>\r\n			</li>\r\n			<li class="w-timerange__suggest-list-point" data-type="noon">\r\n					<div class="w-timerange__suggest-list-point-wrapper">\r\n						<span class="w-timerange__suggest-list-daytime">06:00 – 12:00</span>\r\n					</div>\r\n			</li>\r\n			<li class="w-timerange__suggest-list-point" data-type="evening">\r\n					<div class="w-timerange__suggest-list-point-wrapper">\r\n						<span class="w-timerange__suggest-list-daytime">12:00 – 18:00</span>\r\n					</div>\r\n			</li>\r\n			<li class="w-timerange__suggest-list-point" data-type="night">\r\n					<div class="w-timerange__suggest-list-point-wrapper">\r\n						<span class="w-timerange__suggest-list-daytime">18:00 – 00:00</span>\r\n					</div>\r\n			</li>\r\n		</ul>\r\n	</div>\r\n</div>\r\n';

}
return __p
}