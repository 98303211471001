module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="carriage-map-description text-align-right">\r\n	';
 carPlaceData.forEach(function(place) { ;
__p += '\r\n		<div class="b-car-place" data-places="' +
((__t = ( place.availableSeats.join(',') )) == null ? '' : __t) +
'" data-groupId="' +
((__t = ( groupId )) == null ? '' : __t) +
'">\r\n			<div class="b-car-place__caption">\r\n				';
 if (place.carPlaceType.caption != null && place.carPlaceType.caption.toLocaleLowerCase() != 'без значения') { ;
__p += '\r\n					' +
((__t = ( place.carPlaceType.caption )) == null ? '' : __t) +
':\r\n				';
 } ;
__p += '\r\n			</div>\r\n			<div class="b-car-place__quantity">\r\n				<span class="b-car-place__quantity-count">\r\n					';
 if (!_.isEmpty(seatsGroup) && seatsGroup.seatsGroupSizeRestricted && seatsGroup.seatsGroupSize > 1) { ;
__p += '\r\n						';

							var ticketsCount = seatsGroup.seatsGroupSize || Math.ceil(seatsGroup.seatsAmount / seatsGroup.seatsGroupSize) * seatsGroup.seatsGroupSize;
							var message = ticketsCount + ' ' + Formatter.getNumEnding(ticketsCount, L10N.get('formatter.endings.place'));
						;
__p += '\r\n						<span data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="<div class=\'b-car-place-tooltip\'><div class=\'tooltip__header\'>' +
((__t = ( L10N.get('bookingForm.attention') )) == null ? '' : __t) +
'</div> <div class=\'tooltip__body\'>' +
((__t = ( L10N.get('trains.bookingAmount') )) == null ? '' : __t) +
' ' +
((__t = ( message )) == null ? '' : __t) +
'</div>">\r\n							' +
((__t = ( '<strong>x' + ticketsCount + '</strong>' + ' ' + Formatter.getNumEnding(ticketsCount, L10N.get('formatter.endings.place')))) == null ? '' : __t) +
'\r\n						</span>\r\n					';
 } else { ;
__p += '\r\n						' +
((__t = ( place.availableSeats.length )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.getNumEnding(place.availableSeats.length, L10N.get('formatter.endings.place')) )) == null ? '' : __t) +
'\r\n					';
 } ;
__p += '\r\n				</span>\r\n			</div>\r\n			<div class="b-car-place__price">\r\n				' +
((__t = ( L10N.get('trains.from') )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatMoney(place.price.priceRange != null ? place.price.priceRange.min : place.price.price) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(currencyCode) )) == null ? '' : __t) +
'\r\n			</div>\r\n		</div>\r\n	';
 }); ;
__p += '\r\n\r\n	';

	if (carriage.arrivalDate != null) {
		var dateArrival = new Time(carriage.arrivalDate);
		var dateArrivalLocal = carriage.localArrivalDate ? new Time(carriage.localArrivalDate) : null;
		var segment = _.last(trainModel.get('segments'));

		var dateArrivalTooltip = "<div class='text-align-center'>" + L10N.get('trains.cardLocalInfo');
		if (segment != null && segment.arrivalMoscowTimeZone === true) {
			dateArrivalTooltip += '<br />' + L10N.get('trains.moscowTime') + ' - <strong>' + dateArrival.toShortTime() + ' ' + dateArrival.toShortDate() + '</strong>';
		}
		dateArrivalTooltip += '</div>';
	;
__p += '\r\n		<div class="carriage-map-description__notifications d-ib">\r\n			<span class="b-notification b-notification--type-alert"\r\n				data-toggle="tooltip"\r\n				data-html="true"\r\n				data-placement="bottom"\r\n				data-original-title="<div><strong>' +
((__t = ( L10N.get('bookingForm.attention') )) == null ? '' : __t) +
'!</strong><br/><br/>' +
((__t = ( L10N.get('trains.trainArrivalTime_1') )) == null ? '' : __t) +
' ' +
((__t = ( carriage.number )) == null ? '' : __t) +
' ' +
((__t = ( L10N.get('trains.trainArrivalTime_2') )) == null ? '' : __t) +
' ' +
((__t = ( trainModel.get('number') )) == null ? '' : __t) +
'</div>"></span>\r\n			' +
((__t = ( L10N.get('trains.arrivalTime') )) == null ? '' : __t) +
':\r\n			<strong\r\n				data-toggle="tooltip"\r\n				data-html="true"\r\n				data-placement="bottom"\r\n				data-original-title="' +
((__t = ( dateArrivalTooltip )) == null ? '' : __t) +
'">\r\n				' +
((__t = ( (dateArrivalLocal || dateArrival).toShortTime() )) == null ? '' : __t) +
' ' +
((__t = ( (dateArrivalLocal || dateArrival).toShortDate() )) == null ? '' : __t) +
'\r\n			</strong>\r\n		</div>\r\n	';
 } ;
__p += '\r\n</div>\r\n';

}
return __p
}