import { Field } from "rc-field-form";
import { FieldProps } from "rc-field-form/es/Field";
import { Meta } from "rc-field-form/es/interface";
import React, { useMemo } from "react";

interface FormItemProps extends FieldProps {
	children?: React.ReactElement;
}

export const Input: React.FC<any> = ({ value = "", ...props }) => (
	<input {...props} value={value} />
);

/**
 * Return a wrapped Field with meta info
 */
const FormItem: React.FC<FormItemProps> = ({ children, ...props }) => (
	<Field {...props}>
		{(control: any, meta: Meta) => {
			return (
				<div className="field">
					{children ? (
						React.cloneElement(children, {
							...control,
							meta,
						})
					) : (
						<Input {...control} />
					)}
				</div>
			);
		}}
	</Field>
);

export default FormItem;
