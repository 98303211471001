module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<table class="b-nearest-dates-matrix__table b-nearest-dates-matrix__roundtrip">\r\n	<tr class="b-nearest-dates-matrix__row-direction">\r\n		<td class="b-nearest-dates-matrix__table-empty" colspan="1" rowspan="1"></td>\r\n		<th class="b-nearest-dates-matrix__table-from" colspan="8">' +
((__t = ( L10N.get('nearestMatrix.from') )) == null ? '' : __t) +
'</th>\r\n	</tr>\r\n	<tr class="b-nearest-dates-matrix__row-dates">\r\n		<th class="b-nearest-dates-matrix__row-dates-left"></th>\r\n		<th></th>\r\n		';
 _.each(matrixDaysFrom, function (matrixDateFrom) { ;
__p += '\r\n		<th class="b-nearest-dates-matrix__table-date ' +
((__t = ( matrixDateFrom == dateFrom ? 'current' : '' )) == null ? '' : __t) +
'">\r\n			' +
((__t = ( new Time(matrixDateFrom).format(L10N.get('nearestMatrix.dateFormat')) )) == null ? '' : __t) +
'\r\n		</th>\r\n		';
 }) ;
__p += '\r\n	</tr>\r\n		';
  _.each(datesMatrix, function(matrixTo, i) {  ;
__p += '\r\n			<tr class="b-nearest-dates-matrix__row-prices b-nearest-dates-matrix__row-prices' +
((__t = (i)) == null ? '' : __t) +
'  ' +
((__t = ( (matrixTo[0] && matrixTo[0].departureTo == dateTo) ? 'current' : '' )) == null ? '' : __t) +
'">\r\n				';
 if (i == 0) { ;
__p += '\r\n					<td rowspan="7" class="b-nearest-dates-matrix__table-to"><span class="b-nearest-dates-matrix__table-to">' +
((__t = ( L10N.get('nearestMatrix.to') )) == null ? '' : __t) +
'</span></td>\r\n				';
 } ;
__p += '\r\n				<td class="b-nearest-dates-matrix__table-date ' +
((__t = ( (matrixDaysTo[i] == dateTo)? 'current' : '' )) == null ? '' : __t) +
'">\r\n					' +
((__t = ( new Time(matrixDaysTo[i]).format(L10N.get('nearestMatrix.dateFormat')) )) == null ? '' : __t) +
'\r\n				</td>\r\n				';
  _.each(matrixTo, function(flight, j) {  ;
__p += '\r\n					';
 if (flight) { ;
__p += '\r\n						';

							var classes = [];
							classes.push("b-nearest-dates-matrix__table-price");
							classes.push("b-nearest-dates-matrix__table-price" + j);
						;
__p += '\r\n\r\n						<td class="' +
((__t = (classes.join(' ') )) == null ? '' : __t) +
'" data-to="' +
((__t = ( flight.departureTo )) == null ? '' : __t) +
'" data-from="' +
((__t = ( flight.departureFrom )) == null ? '' : __t) +
'" data-price="' +
((__t = ( flight.amount )) == null ? '' : __t) +
'" >\r\n							<div class="b-nearest-dates-matrix__table-price-container">\r\n								<span>\r\n									' +
((__t = ( L10N.get('nearestMatrix.up') )) == null ? '' : __t) +
'&nbsp;\r\n									' +
((__t = ( flight.amount )) == null ? '' : __t) +
'&nbsp;\r\n									' +
((__t = ( Formatter.formatCurrency(flight.currencyCode) )) == null ? '' : __t) +
'\r\n								</span>\r\n							</div>\r\n						</td>\r\n					';
 } else  { ;
__p += '\r\n						<td></td>\r\n					';
 } ;
__p += '\r\n				';
 }) ;
__p += '\r\n			</tr>\r\n		';
 }) ;
__p += '\r\n</table>\r\n';

}
return __p
}