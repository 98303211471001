// eslint-disable-next-line banned-modules
'use strict';

import ProfileCostCodesModel from './model.js';

import BaseView from '@/classes/base.view';
import template from '../template.ejs';

import InputWidget from '@/widgets/b-input/index';
import Autocomplete from '@/widgets/autocomplete-input/noquery-autocomplete/index';
import $ from 'jquery';

/*
	Пока отличие от обычных costCodes в формате данных "values"
*/

export default BaseView.extend({

	template,

	ui: {
		costCodesField: '.b-cost-codes__item-content',
	},

	initialize() {
		const { organizationCostCodes, passenger } = this.options;
		this.collection = this.options.collection;

		if (_.isEmpty(organizationCostCodes)) {
			return;
		}

		this.render();
		if (this.collection) {
			this.collection.reset();
		}

		_.each(organizationCostCodes, (item) => {
			const model = new ProfileCostCodesModel(item);
			const isOrganizationCodes = !_.isEmpty(item.organization);
			let localWidget = null;
			let enWidget = null;

			if (item.controlType === 'TEXT') {
				localWidget = new InputWidget({
					bindingProperty: 'value.localValue',
					label: (isOrganizationCodes) ? L10N.get('travelPolitics.senseLocal') : `${item.name} (${L10N.get('travelPolitics.local')})`,
				});

				enWidget = new InputWidget({
					bindingProperty: 'value.englishValue',
					label: (isOrganizationCodes) ? L10N.get('travelPolitics.senseEn') : `${item.name} (EN)`,
				});

				const passengerValue = _.isObject(passenger) ?
					_.find(passenger.costCodes, (code) => (
						((code.costCodeCategoryUid != null) && (code.costCodeCategoryUid === item.costCodeCategoryUid)) ||
						((code.costCodePropertyUid != null) && (code.costCodePropertyUid === item.costCodePropertyUid)))) : null;

				model.set('value', (passengerValue != null) ? passengerValue.value : (item.values || [])[0]);
			} else if (item.controlType === 'SELECT') {
				localWidget = new Autocomplete({
					bindingProperty: 'value.localValue',
					setPropertyOnChange: true,
					label: `${item.name} (${L10N.get('travelPolitics.local')})`,
					values: Autocomplete.storeCollection(
						Autocomplete.dictionaryToCollection(_.map(item.values, (el) => {
							return {
								caption: el.localValue,
								uid: el.localValue,
							};
						})),
					),
				});

				enWidget = new Autocomplete({
					bindingProperty: 'value.englishValue',
					setPropertyOnChange: true,
					label: `${item.name} (EN)`,
					values: Autocomplete.storeCollection(
						Autocomplete.dictionaryToCollection(_.map(item.values, (el) => {
							return {
								caption: el.englishValue,
								uid: el.englishValue,
							};
						})),
					),
				});

				const passengerValue = _.isObject(passenger) ?
					_.find(passenger.costCodes, (code) => code.costCodeCategoryUid === item.costCodeCategoryUid) : null;

				if (passengerValue != null
					&& ((_.contains(item.values, passengerValue.value) || _.find(item.values, (el) => _.isEqual(el, passengerValue.value))))) {
					model.set('value', {
						uid: passengerValue.value,
						caption: passengerValue.value,
					});
					if (passengerValue.value?.localValue) {
						model.set('value.localValue', {
							uid: passengerValue.value.localValue,
							caption: passengerValue.value.localValue,
						});
					}
					if (passengerValue.value?.englishValue) {
						model.set('value.englishValue', {
							uid: passengerValue.value.englishValue,
							caption: passengerValue.value.englishValue,
						});
					}
				}
			} else if (item.controlType === 'SERVICE') {
				throw Error('"SERVICE" controlType is not supported for profileCostCodes. Please contact support.');
			} else {
				throw Error('Unknown controlType for organizationCostCode');
			}

			if (localWidget !== null && enWidget !== null) {
				localWidget.applyBinding(model);
				enWidget.applyBinding(model);
				const container = $('<div class="flex-grid-container"></div>');
				if (isOrganizationCodes) {
					this.ui.costCodesField.append(container);
					container.append($('<div class="flex-grid-layout"></div>').append(new InputWidget({
						label: L10N.get('travelPolitics.client'),
						attrs: {
							readonly: true,
							value: item.organization.caption,
						},
					}).$el));

					container.append($('<div class="flex-grid-layout"></div>').append(new InputWidget({
						label: L10N.get('travelPolitics.category'),
						attrs: {
							readonly: true,
							value: item.name,
						},
					}).$el));
					container.append($('<div class="flex-grid-layout b-cost-codes__item-field"></div>').append(localWidget.$el));
					container.append($('<div class="flex-grid-layout b-cost-codes__item-field"></div>').append(enWidget.$el));
				} else {
					container
						.append($('<div class="flex-grid-layout b-cost-codes__item-field"></div>').append(localWidget.$el))
						.append($('<div class="flex-grid-layout b-cost-codes__item-field"></div>').append(enWidget.$el));
					this.ui.costCodesField.append(container);
				}
			}

			this.collection.add(model);
		});

		this.listenTo(this.collection, 'validationError:costCodeCategoryUid', this.showValidationErrorModal.bind(this));
	},

	showValidationErrorModal(value) {
		const message = value[0].text;
		const popup = new Widgets.Popup({
			content: message,
			type: 'danger',
			actions: [{
				label: L10N.get('Common.close'),
				action: () => {
					popup.hide();
				},
			}],
			onClose: () => {
				popup.hide();
			},
		});
		popup.show();
		return this;
	},
});
