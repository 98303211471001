// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import $ from 'jquery';
import BaseView from '@/classes/base.view';
import template from './table_footer.ejs';

export default BaseView.extend({

	template,

	events: {
		'click .b-footer-show-select': 'showSelect',
		'click .b-footer-show-more': 'showMore',
		'click .js-select-page': 'selectPage',
	},

	ui: {
		showMoreBtn: '.b-footer-show-more',
	},

	initialize(options) {
		this.model = options.model;
		this.data = options.data;
		this.showSummaryOnTop = options.showSummaryOnTop;
		this.render();

		if (options.showMoreEventHandler) {
			this.showMoreEventHandler = options.showMoreEventHandler;
			this.ui.showMoreBtn[this.data.count === 0 || this.model.get('pageSize') >= this.data.count ? 'hide' : 'show']();
		}
	},

	getPagesCount() {
		const totalPages = this.data.count;
		const pageSize = this.model.get('pageSize');
		return Math.ceil(totalPages / pageSize);
	},

	showSelect(e) {
		const $elm = $(e.currentTarget);
		$elm.toggleClass('open');
	},

	selectPage(e) {
		const $elm = $(e.currentTarget);
		const page = $elm.attr('page');
		this.model.set('page', page);
	},

	showMore(e) {
		this.ui.showMoreBtn[this.data.count === 0 ? 'hide' : 'show']();
		if (this.showMoreEventHandler && _.isFunction(this.showMoreEventHandler)) {
			this.showMoreEventHandler(e);
		}
	},
});
