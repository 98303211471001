module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	_.each(reservation.services, function (s) {
		if (s == null) {
			s = {};
			return;
		}
		if	(s.offer == null) {
			s.offer = {};
		}
	});
	var createdDate = new Time(order.created).format('DD.MM.YY');
	var travelSubject = reservation.travelSubject.uid;
	var hasRefundableService = _.some(reservation.services, function (el) {
		return el && (el.refundable === true);
	});
    var hasExchangeableService = _.some(reservation.services, function (el) {
        return el && (el.exchangeable === true);
    });

	var offer = reservation.services.length > 0 ? reservation.services[0].offer : {};

	var request = _.map(reservation.services, function (service) { return  service && service.offer && !service.offer.online; });
	var hasNotConfirmedAdditionalServices = _.map(reservation.services, function (service) { return service.hasNotConfirmedAdditionalServices === true; });

    var isEditGrantedForAllServices = _.every(reservation.services, (p) => p.aclEditGranted);
    var hasEditingService = hasRefundableService || hasExchangeableService;
	var showFooterOrderInfo =
	    (reservation.travelPolicyStatus && reservation.travelPolicyStatus.uid === "AUTHORIZATION_REQUESTED") ||
		reservation.isCancelBookingAllowed ||
        reservation.isRefundBookingAllowed ||
		hasRefundableService ||
		offer.cancellationPolicyText ||
		(offer.online !== true && offer.cancellationPolicyPricesMayChange === true) ||
		(hasNotConfirmedAdditionalServices === true);

    function isBlurred(service) {
        return service.aclViewGranted ? '' : 'content-blur';
    }

    var hasStatistics = _.some(reservation.services, function (el) {
      return ((el.statistics && (
        el.statistics.commonCostCodes && el.statistics.commonCostCodes.length > 0 ||
        el.statistics.travellerStatistics && el.statistics.travellerStatistics.length > 0
      )));
    });
;
__p += '\n\n<div>\n	<div class="b-order__container-header">\n		<div class="b-order__container-header-label b-order-label">\n			<i class="g-icon-service g-icon-service--type-' +
((__t = ( travelSubject.toLowerCase() )) == null ? '' : __t) +
'"></i>\n			';
 if (reservation.status.uid === 'ERROR') { ;
__p += '\n				<span class="b-order__info-error" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="<div class=\'b-car-place-tooltip\'><div class=\'tooltip__header\'>' +
((__t = ( L10N.get('settings.error') )) == null ? '' : __t) +
'</div><div class=\'tooltip__body\'>' +
((__t = ( L10N.get('settings.errorTechSupport') )) == null ? '' : __t) +
'</div>">' +
((__t = ( L10N.get('settings.error') )) == null ? '' : __t) +
'</span>\n			';
 } ;
__p += '\n			<div class="b-order-label__list">\n				';
 if (reservation.card && reservation.card.address != null &&
					   reservation.card.address.cityInfo) { ;
__p += '\n					<span class="b-order-label__item b-order-label__route">\n						' +
((__t = ( reservation.card.address.cityInfo.city.caption )) == null ? '' : __t) +
'\n					</span>\n                    <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('cabinet.orders.city') )) == null ? '' : __t) +
': ' +
((__t = ( reservation.card.address.cityInfo.city.caption )) == null ? '' : __t) +
'</span>\n				';
 } ;
__p += '\n\n				';
 if (reservation.card && reservation.card.name) { ;
__p += '\n					<span class="b-order-label__item b-order-label__route">\n						' +
((__t = ( reservation.card.name )) == null ? '' : __t) +
'\n                        <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('cabinet.orders.hotelName') )) == null ? '' : __t) +
': ' +
((__t = ( reservation.card.name )) == null ? '' : __t) +
'</span>\n					</span>\n				';
 } ;
__p += '\n\n				<span class="b-order-label__item b-order-label__create-date">\n					' +
((__t = ( createdDate )) == null ? '' : __t) +
'\n				</span>\n\n				';
 if (reservation.prn != null) { ;
__p += '\n\n            <span class="b-order-label__item b-order-label__pnr">\n              ';
 if (reservation.showGdsAccountName) { ;
__p += '\n                <span class="b-order-label__item-extra">' +
((__t = ( reservation.gdsAccountName )) == null ? '' : __t) +
'</span>\n              ';
 } ;
__p += '\n              <span class="b-order-label__item-simple">' +
((__t = ( L10N.get('cabinet.orders.reservationPnr') )) == null ? '' : __t) +
':</span>\n              ' +
((__t = ( reservation.prn )) == null ? '' : __t) +
'\n            </span>\n          \n				';
 } ;
__p += '\n\n				';
 if (reservation.status.uid === 'INTENTION' && _.some(request)) { ;
__p += '\n					<span class="b-order-label__item">' +
((__t = ( L10N.get('cabinet.orders.hotelIntention') )) == null ? '' : __t) +
'</span>\n				';
 } ;
__p += '\n			</div>\n            ';
 if (reservation.incomplete) { ;
__p += '\n                <span class="missing-info-warning"\n                      data-toggle="tooltip"\n                      data-placement="bottom"\n                      data-html="true"\n                      data-original-title="<div>' +
((__t = ( L10N.get('cabinet.orders.incompleteReservationWarning') )) == null ? '' : __t) +
'</div>"\n                ></span>\n            ';
 } ;
__p += '\n		</div>\n		<div class="b-order__container-header-right">\n			';
 if (!_.isEmpty(reservation.price)) { ;
__p += '\n				<div class="b-order-price">\n          \n				<span data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="' +
((__t = ( _.escape(require('@/blocks/elements/b-hotel/b-hotel-order/b-ticket-pricing.ejs')({ price: reservation.price, services: reservation.services })) )) == null ? '' : __t) +
'">\n					' +
((__t = ( Formatter.formatMoney(reservation.price.total.amount, Formatter.getDecimal(reservation.price.total.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(reservation.price.total.currencyCode) )) == null ? '' : __t) +
'\n				</span>\n					';

						var additionalTaxes = _.reduce(reservation.services, function (acc, s) {
							if (_.isEmpty(s.offer.taxInfoList)) {
								return acc;
							}

							return acc.concat(s.offer.taxInfoList);
						}, []);

					if (!_.isEmpty(additionalTaxes)) {
						var sum = _.reduce(additionalTaxes, function(acc, tax) {
							return +tax.amount.amount + acc;
						}, 0);
						var currencyCode = additionalTaxes[0].amount.currencyCode;
					;
__p += '\n					<div class="b-order-price__taxs">\n						' +
((__t = ( additionalTaxes.length === 1 ? L10N.get('hotels.taxInfoList_1') : L10N.get('hotels.taxInfoList_2') )) == null ? '' : __t) +
' -\n						' +
((__t = ( Formatter.formatMoney(sum, 0) )) == null ? '' : __t) +
'\n						' +
((__t = ( Formatter.formatCurrency(currencyCode) )) == null ? '' : __t) +
'\n					</div>\n					';
 } ;
__p += '\n				</div>\n			';
 } ;
__p += '\n            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('hotels.cost') )) == null ? '' : __t) +
': <span data-copy-content-from=".b-order-price > :first-child"></span></span>\n            <span class="js-copy-content copy-content"><span data-find-copy-in-tooltip data-copy-title-from=".b-order-price > :first-child"></span></span>\n\n			';
 if (!(reservation.status && reservation.status.uid === 'ERROR')) { ;
__p += '\n				';
 if (['INTENTION', 'BOOKING', 'AUTHORIZATION', 'PROPOSAL'].indexOf(reservation.status.uid) !== -1) { ;
__p += '\n					<span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "selectedReservation.' + index + '.selected"})\'></span>\n				';
 } ;
__p += '\n			';
 } ;
__p += '\n		</div>\n	</div>\n	<div class="b-order__container-content">\n		<table class="b-block-table">\n			<tr class="b-block-table__row b-block-table__row-header">\n				';
 if (isEditGrantedForAllServices && hasEditingService === true) { ;
__p += '\n					<th class="b-block-table__cell b-block-table__cell-type-checkbox mobile-hide">\n						<span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "selectedServices.' + index + '.allServices"})\'></span>\n					</th>\n				';
 } else { ;
__p += '\n					<th class="b-block-table__cell b-block-table__cell-empty"></th>\n				';
 } ;
__p += '\n				<th class="b-block-table__cell">' +
((__t = ( L10N.get('cabinet.orders.hotelVoucher') )) == null ? '' : __t) +
'</th>\n				<th class="b-block-table__cell">' +
((__t = ( L10N.get('cabinet.orders.hotelFullName') )) == null ? '' : __t) +
'</th>\n				<th class="b-block-table__cell">' +
((__t = ( L10N.get('cabinet.orders.hotelAddServices') )) == null ? '' : __t) +
'</th>\n				<th class="b-block-table__cell b-block-table__cell--width-auto">\n                    ' +
((__t = ( L10N.get('cabinet.orders.hotelStatus') )) == null ? '' : __t) +
'\n                </th>\n				<th class="b-block-table__cell"></th>\n			</tr>\n            ';
 if (isEditGrantedForAllServices && hasEditingService === true) { ;
__p += '\n                <tr class="b-block-table__row desktop-hide">\n                    <td class="b-block-table__cell b-block-table__cell-type-checkbox b-block-table__cell--checkobx-with-caption">\n                        <span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "selectedServices.' + index + '.allServices"})\'></span>\n                        <span>' +
((__t = ( L10N.get('cabinet.orders.selectAll') )) == null ? '' : __t) +
'</span>\n                    </td>\n                </tr>\n            ';
 } ;
__p += '\n			';
 _.each(reservation.services, function(p, i) {
				if (!p) return;
				var passengers = p.travellers || [];
				var refundable = (p.refundable === true);
                var alcEditGranted = p.aclEditGranted;
			;
__p += '\n				<tr class="b-block-table__row" data-service-uid="' +
((__t = ( p.uid )) == null ? '' : __t) +
'" data-service-status="' +
((__t = ( p.status && p.status.uid )) == null ? '' : __t) +
'">\n					';
 if (hasEditingService === true && alcEditGranted) { ;
__p += '\n						<td class="b-block-table__cell b-block-table__cell-type-checkbox b-block-table__cell--checkobx-with-caption">\n							<span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "selectedServices.' + index + '.' + p.uid + '.selected"})\'></span>\n                            <span class="desktop-hide">' +
((__t = ( L10N.get('cabinet.orders.selectService') )) == null ? '' : __t) +
'</span>\n						</td>\n					';
 } else { ;
__p += '\n						<td class="b-block-table__cell b-block-table__cell-empty"></td>\n					';
 } ;
__p += '\n\n					<td class="b-block-table__cell b-block-table__cell-ticket-number ' +
((__t = ( isBlurred(p) )) == null ? '' : __t) +
'">\n                        <span class="desktop-hide">' +
((__t = ( L10N.get('cabinet.orders.hotelVoucher') )) == null ? '' : __t) +
'</span>\n						<span class="b-order__info-ticket-number">\n							' +
((__t = ( (p.ticketNumber != null) ? p.ticketNumber : '一' )) == null ? '' : __t) +
'\n						</span>\n                        <span class="js-copy-content copy-content">' +
((__t = ( i > 0 ? '\n' : '')) == null ? '' : __t) +
'<strong>' +
((__t = ( L10N.get('cabinet.orders.hotelVoucher') )) == null ? '' : __t) +
'</strong>: ' +
((__t = ( (p.ticketNumber != null) ? p.ticketNumber : '一' )) == null ? '' : __t) +
' (' +
((__t = ( p.status && p.status.caption )) == null ? '' : __t) +
')</span>\n					</td>\n					<td class="b-block-table__cell b-block-table__cell-fullname ' +
((__t = ( isBlurred(p) )) == null ? '' : __t) +
'">\n						';
 const passengersFullName = _.map(passengers, function (passenger) {
							return '<div>' + passenger.fullName || '-' + '</div>' ;
__p += '\n						';
 }).join(''); ;
__p += '\n                        ' +
((__t = ( passengersFullName )) == null ? '' : __t) +
'\n                        <span class="js-copy-content copy-content" data-replace-newline=" "><strong>' +
((__t = ( L10N.get('cabinet.orders.hotelFullName') )) == null ? '' : __t) +
'</strong>: ' +
((__t = ( passengersFullName )) == null ? '' : __t) +
'</span>\n					</td>\n					<td class="b-block-table__cell mobile-hide  ' +
((__t = ( isBlurred(p) )) == null ? '' : __t) +
'">\n						';
 if (p.offer && p.offer.additionalServices) { ;
__p += '\n							';

								var additionalServices = [];
								if (p.offer.additionalServices.checkIn) {
									additionalServices.push(L10N.get('hotels.addServices.earlyCheckIn'));
								}
								if (p.offer.additionalServices.checkOut) {
									additionalServices.push(L10N.get('hotels.addServices.lateCheckout'));
								}
								if (!_.isEmpty(p.offer.additionalServices.meals)) {
									additionalServices.push(L10N.get('hotels.meal'));
								}
							;
__p += '\n							' +
((__t = ( additionalServices.join('; ') )) == null ? '' : __t) +
'\n                            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('cabinet.orders.hotelAddServices') )) == null ? '' : __t) +
': ' +
((__t = ( additionalServices.join('; ') )) == null ? '' : __t) +
'</span>\n						';
 } ;
__p += '\n					</td>\n					<td class="b-block-table__cell b-block-table__cell-status">\n                        <div class="b-order__info-status-container">\n						    <span class="b-order__info-status b-order__info-status--' +
((__t = ( p.status && p.status.uid )) == null ? '' : __t) +
'">\n                                ' +
((__t = ( p.status && p.status.caption )) == null ? '' : __t) +
'\n                            </span>\n                            ';
 if (useAuthSteps) { ;
__p += '\n                                <button\n                                    type="button"\n                                    class="b-order__show-detailed js-open-auth-steps-modal"\n                                    data-reservation-uid="' +
((__t = ( reservation.uid )) == null ? '' : __t) +
'"\n                                    data-service-uid="' +
((__t = ( p.uid )) == null ? '' : __t) +
'"\n                                >\n                                    ' +
((__t = ( L10N.get('cabinet.orders.showDetails') )) == null ? '' : __t) +
'\n                                </button>\n                            ';
 } ;
__p += '\n                        </div>\n					</td>\n                    <td class="b-block-table__cell b-block-table__cell-travel-policy">\n                        <div class="b-block-table__cell-content-container order-reservation__travel-policy-container b-order-reservation__travel-policy">\n                            ';
 if (p.travelPolicyCompliance && !_.isEmpty(p.travelPolicyCompliance.rulesApplied)) { ;
__p += '\n                              ' +
((__t = ( require('@/blocks/elements/b-hotel/b-hotel-order/b-hotel-info/b-travel-policy.ejs')({
                                travelPolicyCompliance: p.travelPolicyCompliance,
                              }) )) == null ? '' : __t) +
'\n                            ';
 } ;
__p += '\n                            ';
 if (p.isTripartiteDiscount) { ;
__p += '\n                                <span class="b-order-reservation__travel-policy--3D g-icon-features-3D-contract" data-toggle="tooltip" data-placement="bottom" data-title="' +
((__t = ( L10N.get('filters.tripartite'))) == null ? '' : __t) +
'"></span>\n                            ';
 } ;
__p += '\n                        </div>\n                    </td>\n				</tr>\n			';
 }); ;
__p += '\n      ';
 if (hasStatistics) { ;
__p += '\n        <tr class="b-block-table__row">\n          <td colspan="8" class="b-block-table__cell" style="text-align: center;">\n            <div class="js-react-booking-statistics-dialog" data-reservation-uid="' +
((__t = ( reservation.uid )) == null ? '' : __t) +
'"></div>\n          </td>\n        </tr>\n        ';
 } ;
__p += '\n			';
 if (showFooterOrderInfo) { ;
__p += '\n				<tr class="b-block-table__row b-order__info-footer">\n					<td class="b-block-table__cell ' +
((__t = ( (hasEditingService === true) ? 'b-block-table__cell-type-checkbox' : 'b-block-table__cell-empty' )) == null ? '' : __t) +
'"></td>\n					<td colspan="1" class="b-block-table__cell">\n						<div class="b-order__info-footer-container">\n							<div class="b-order__info-footer-controls">\n								';
 if (reservation.isCancelBookingAllowed && STATE.getLoggedInUser() != null) { ;
__p += '\n									<span class="b-order__info-footer-button b-payment__cancel mobile-hide">' +
((__t = ( L10N.get('cabinet.orders.cancel') )) == null ? '' : __t) +
'</span>\n								';
 } ;
__p += '\n\n								';
 if (hasRefundableService && reservation.isRefundBookingAllowed && STATE.getLoggedInUser() != null) { ;
__p += '\n									<span class="b-order__info-footer-button b-refund-services mobile-hide">' +
((__t = ( L10N.get('cabinet.orders.refund') )) == null ? '' : __t) +
'</span>\n								';
 } ;
__p += '\n\n								';
 if (['REFUND', 'PAYED', 'ISSUED'].indexOf(reservation.status.uid) !== -1 && false) { ;
__p += '\n									<span class="b-order__info-footer-button b-order__additional-services collapsed mobile-hide" data-toggle="collapse" data-target=".toggle-order__additional-services-container-' +
((__t = ( index )) == null ? '' : __t) +
'">' +
((__t = ( L10N.get('cabinet.orders.addAdditionalServices') )) == null ? '' : __t) +
'</span>\n								';
 } ;
__p += '\n							</div>\n							<div class="b-order-hotel__notifications ">\n								';
 _.each(reservation.services, function (service) { ;
__p += '\n								';
 if (!service || _.isEmpty(service)) return; ;
__p += '\n									';
 var offer = service.offer; ;
__p += '\n\n									';
 if (offer.paymentInHotel === true) { ;
__p += '\n										<div class="b-notification b-notification--type-alert b-order-hotel__notification ' +
((__t = ( isBlurred(service) )) == null ? '' : __t) +
'">\n											<strong>' +
((__t = ( L10N.get('bookingForm.attention') )) == null ? '' : __t) +
': </strong>\n											' +
((__t = ( L10N.get('cabinet.orders.paymentPossible') )) == null ? '' : __t) +
'\n											<span class="b-order__important">\n												' +
((__t = ( L10N.get('cabinet.orders.paymentInHotel') )) == null ? '' : __t) +
'\n											</span>\n										</div>\n									';
 } ;
__p += '\n\n									';
 if (service.hasNotConfirmedAdditionalServices === true) { ;
__p += '\n											<div class="b-notification b-notification--type-alert b-order-hotel__notification ' +
((__t = ( isBlurred(service) )) == null ? '' : __t) +
'">\n												<div>\n													<strong>' +
((__t = ( L10N.get('bookingForm.attention') )) == null ? '' : __t) +
'!</strong> ' +
((__t = ( L10N.get('cabinet.orders.notifications.addServiceBookingError') )) == null ? '' : __t) +
'<br />\n												</div>\n											</div>\n									';
 } ;
__p += '\n\n									';
 if (offer.online !== true && offer.cancellationPolicyPricesMayChange === true) { ;
__p += '\n										<div class="b-notification b-notification--type-alert b-order-hotel__notification ' +
((__t = ( isBlurred(service) )) == null ? '' : __t) +
'">\n											<div>\n												<span class="b-order__important">' +
((__t = ( L10N.get('cabinet.orders.notifications.hotelRequest') )) == null ? '' : __t) +
'</span> <strong>' +
((__t = ( L10N.get('bookingForm.attention') )) == null ? '' : __t) +
'!</strong> ' +
((__t = ( L10N.get('cabinet.orders.notifications.responseTime') )) == null ? '' : __t) +
'\n												<div>' +
((__t = ( L10N.get('cabinet.orders.notifications.penaltyInfo') )) == null ? '' : __t) +
'</div>\n											</div>\n										</div>\n									';
 } ;
__p += '\n									';
 if (offer.cancellationPolicyText || offer.freeCancellationTerm) { ;
__p += '\n										<div class="b-notification ' +
((__t = ( isBlurred(service) )) == null ? '' : __t) +
' ' +
((__t = ( !offer.freeCancellationTerm ? 'b-notification--type-alert' : '' )) == null ? '' : __t) +
' b-order-hotel__notification">\n											<div>\n												';
 if (!offer.freeCancellationTerm) { ;
__p += '\n                                                    <span ' +
((__t = ( offer.offerWithPenalty === true ? 'class="b-order__important"' : '' )) == null ? '' : __t) +
'>' +
((__t = ( L10N.get('cabinet.orders.notifications.penalties') )) == null ? '' : __t) +
'</span>\n                                                ';
 } ;
__p += '\n                                                ';
 if (offer.freeCancellationTerm) { ;
__p += '\n                                                    ' +
((__t = ( L10N.get('hotels.freeCancellation'))) == null ? '' : __t) +
' ' +
((__t = ( L10N.get('cabinet.orders.until') )) == null ? '' : __t) +
' ' +
((__t = ( new Time(offer.freeCancellationTerm).toDateString() )) == null ? '' : __t) +
'\n                                                ';
 } else { ;
__p += '\n                                                    (<strong>' +
((__t = ( L10N.get('bookingForm.attention') )) == null ? '' : __t) +
'</strong> ' +
((__t = ( L10N.get('cabinet.orders.notifications.cancelWithoutPenalty') )) == null ? '' : __t) +
' <span class="b-order__important">' +
((__t = ( L10N.get('cabinet.orders.notifications.notAllowed') )) == null ? '' : __t) +
'</span>)\n                                                ';
 } ;
__p += '\n                                            </div>\n											<div>' +
((__t = ( offer.cancellationPolicyText )) == null ? '' : __t) +
'</div>\n										</div>\n									';
 } ;
__p += '\n								';
 }) ;
__p += '\n							</div>\n						</div>\n					</td>\n					<td colspan="3" class="b-block-table__cell b-order__info-footer-right">\n						';
 if (['PAYED', 'ISSUED'].indexOf(reservation.status.uid) !== -1) { ;
__p += '\n							<span class="b-order__info-footer-item b-route-receipt-link mobile-hide">\n								<span class="action-link">' +
((__t = ( L10N.get('cabinet.orders.hotelVoucher') )) == null ? '' : __t) +
'</span>\n								<i class="g-icon-download"></i>\n							</span>\n						';
 } else if (reservation.approveAuthorizationAllowed && reservation.refuseAuthorizationAllowed) { ;
__p += '\n								<span class="mobile-hide">\n									<button type="button" class="btn btn-danger b-order__refuse_auth">\n										' +
((__t = ( L10N.get('cabinet.orders.refuse') )) == null ? '' : __t) +
'\n									</button>\n								</span>\n								<span class="mobile-hide">\n									<button type="button" class="btn btn-green b-order__approve_auth">\n										' +
((__t = ( L10N.get('cabinet.orders.confirm') )) == null ? '' : __t) +
'\n									</button>\n								</span> \n						';
 } else if (['HOTEL_RESERVATION'].indexOf(reservation.travelSubject.uid) !== -1 && reservation.hasOwnProperty('approvalNeeded') && reservation.approvalNeeded) { ;
__p += '\n							<span class="action-link mobile-hide">\n								<button type="button" class="btn btn-dark b-order__intention-approval" data-type="' +
((__t = ( reservation.travelSubject.uid )) == null ? '' : __t) +
'">\n									' +
((__t = ( L10N.get('cabinet.orders.toBooking') )) == null ? '' : __t) +
'\n								</button>\n							</span>\n						';
 } ;
__p += '\n            ';
  if (['AIR', 'RAILWAY', 'HOTEL_RESERVATION'].indexOf(reservation.travelSubject.uid) !== -1 && reservation.hasOwnProperty('bookAuthorizationAllowed') && reservation.bookAuthorizationAllowed === true) { ;
__p += '\n              <span class="action-link mobile-hide">\n                <button type="button" class="btn btn-dark b-booking-authorization">\n                  ' +
((__t = ( L10N.get('cabinet.orders.doBooking') )) == null ? '' : __t) +
'\n                </button>\n              </span>\n              ';
 } ;
__p += '\n					</td>\n                    <td class="b-block-table__cell mobile-cell">\n                        ';
 if (reservation.isCancelBookingAllowed && STATE.getLoggedInUser() != null) { ;
__p += '\n                            <span class="b-order__info-footer-button b-payment__cancel">' +
((__t = ( L10N.get('cabinet.orders.cancel') )) == null ? '' : __t) +
'</span>\n                        ';
 } ;
__p += '\n						';
 if (reservation.approveAuthorizationAllowed && reservation.refuseAuthorizationAllowed) { ;
__p += ' \n                            <span class="b-order__info-footer-button">\n								<button type="button" class="btn btn-danger b-order__refuse_auth">\n									' +
((__t = ( L10N.get('cabinet.orders.refuse') )) == null ? '' : __t) +
'\n								</button>\n							</span>\n                            <span class="b-order__info-footer-button">\n								<button type="button" class="btn btn-green b-order__approve_auth">\n									' +
((__t = ( L10N.get('cabinet.orders.confirm') )) == null ? '' : __t) +
'\n								</button>\n							</span>\n                        ';
 } ;
__p += '\n                        ';
 if (['HOTEL_RESERVATION'].indexOf(reservation.travelSubject.uid) !== -1 && reservation.hasOwnProperty('approvalNeeded') && reservation.approvalNeeded) { ;
__p += '\n                        	<button type="button" class="btn btn-dark b-order__intention-approval" data-type="' +
((__t = ( reservation.travelSubject.uid )) == null ? '' : __t) +
'">\n                        		' +
((__t = ( L10N.get('cabinet.orders.toBooking') )) == null ? '' : __t) +
'\n                        	</button>\n                        ';
 } ;
__p += '\n                        ';
  if (['AIR', 'RAILWAY', 'HOTEL_RESERVATION'].indexOf(reservation.travelSubject.uid) !== -1 && reservation.hasOwnProperty('bookAuthorizationAllowed') && reservation.bookAuthorizationAllowed === true) { ;
__p += '\n                        	<button type="button" class="btn btn-dark b-booking-authorization">\n                        		' +
((__t = ( L10N.get('cabinet.orders.toBooking') )) == null ? '' : __t) +
'\n                        	</button>\n                        ';
 } ;
__p += '\n                        <div class="b-order__info-footer-controls-mobile js-show-mobile-controls desktop-hide expand-only"></div>\n                        <div class="b-order__info-footer-controls-popup js-mobile-controls desktop-hide">\n                            <div class="b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn" data-target-class=".b-route-receipt-link">\n                                ' +
((__t = ( L10N.get('cabinet.orders.hotelVoucher') )) == null ? '' : __t) +
'\n                            </div>\n                            <div class="b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn" data-target-class=".b-refund-services">\n                                ' +
((__t = ( L10N.get('cabinet.orders.refund') )) == null ? '' : __t) +
'\n                            </div>\n                            <div class="b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn" data-target-class=".b-expand">\n                                ' +
((__t = ( L10N.get('loginForm.rulesMore') )) == null ? '' : __t) +
'\n                            </div>\n                        </div>\n                    </td>\n					<td class="b-block-table__cell b-block-table__cell-empty"></td>\n				</tr>\n			';
 } else { ;
__p += '\n                <tr class="b-block-table__row b-order__info-footer">\n                    <td class="b-block-table__cell mobile-cell">\n                        <div class="b-order__info-footer-controls-mobile js-show-mobile-controls desktop-hide expand-only"></div>\n                        <div class="b-order__info-footer-controls-popup js-mobile-controls desktop-hide">\n                            <div class="b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn" data-target-class=".b-route-receipt-link">\n                                ' +
((__t = ( L10N.get('cabinet.orders.hotelVoucher') )) == null ? '' : __t) +
'\n                            </div>\n                            <div class="b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn" data-target-class=".b-refund-services">\n                                ' +
((__t = ( L10N.get('cabinet.orders.refund') )) == null ? '' : __t) +
'\n                            </div>\n                            <div class="b-order__info-footer-controls-mobile--mobile-btn js-footer-controls-btn" data-target-class=".b-expand">\n                                ' +
((__t = ( L10N.get('loginForm.rulesMore') )) == null ? '' : __t) +
'\n                            </div>\n                        </div>\n                    </td>\n                </tr>\n            ';
 } ;
__p += '\n		</table>\n		<div class="b-order__info-expand b-expand collapsed"></div>\n	</div>\n	<div class="toggle-order__additional-services-container toggle-order__additional-services-container-' +
((__t = ( index )) == null ? '' : __t) +
' collapse">\n		<div class="b-order__additional-services-container"></div>\n	</div>\n	<div class="b-order__container-expand">\n		<div class="b-order__container-expand-inner"></div>\n		<div class="b-order__info-expand b-expand"></div>\n	</div>\n</div>\n';

}
return __p
}