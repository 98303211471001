// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';

export default BaseModel.extend({

	defaults() {
		return {
			ADULT: 1,
			CHILD: 0,
		};
	},

	minus(type) {
		const total = Object.keys(this.defaults())
			.reduce((result, item) => result + parseInt(this.get(item)), 0);
		if (total > 1) {
			const value = parseInt(this.get(type));
			if (value > 0) {
				this.set(type, value - 1);
			}
		}
		this.trigger('change:passengersTypes');
	},

	plus(type) {
		const total = Object.keys(this.defaults())
			.reduce((result, item) => result + parseInt(this.get(item)), 0);
		if (total < 8) {
			const value = parseInt(this.get(type));
			if (value < 8) {
				this.set(type, value + 1);
			}
		}
		this.trigger('change:passengersTypes');
	},

	toJSON() {
		return Object.keys(this.attributes).reduce((result, item) => {
			if (parseInt(this.get(item)) > 0 && _.keys(this.defaults()).includes(item)) {
				/*eslint-disable */
				result[item] = this.get(item);
				/*eslint-enable */
			}
			return result;
		}, {});
	},

});
