// eslint-disable-next-line banned-modules
'use strict';

import BaseCollection from '@/classes/base.collection';

export default BaseCollection.extend({

	toJSON(options) {
		const data = BaseCollection.prototype.toJSON.call(this, options);
		return _.map(data, (el) => (el && el.value) || '');
	},

});
