// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import CostCodesModel from './model';

import BaseView from '@/classes/base.view';
import template from './template.ejs';

import InputWidget from '@/widgets/b-input/index';
import Autocomplete from '@/widgets/autocomplete-input/noquery-autocomplete/index';
import $ from 'jquery';

export default BaseView.extend({

	template,

	ui: {
		costCodesField: '.b-cost-codes__item-content',
	},

	initialize() {
		const { organizationCostCodes, passenger, applyCurrentCostCodesCollectionValues = false } = this.options;
		this.collection = this.options.collection;

		if (_.isEmpty(organizationCostCodes)) {
			return;
		}

		this.render();

		let prevCollectionItems = null;

		if (this.collection) {
			if (applyCurrentCostCodesCollectionValues) {
				prevCollectionItems = this.getPrevCollectionItems(organizationCostCodes);
			}
			this.collection.reset();
		}

		_.each(organizationCostCodes, (item) => {
			const model = new CostCodesModel(item);
			const isOrganizationCodes = !_.isEmpty(item.organization);
			let widget = null;

			if (item.controlType === 'TEXT') {
				widget = new InputWidget({
					bindingProperty: 'value',
					label: (isOrganizationCodes) ? L10N.get('travelPolitics.sense') : item.name,
				});

				const passengerValue = _.isObject(passenger) ?
					_.find(passenger.costCodes, (code) => (
						((code.costCodeCategoryUid != null) && (code.costCodeCategoryUid === item.costCodeCategoryUid)) ||
						((code.costCodePropertyUid != null) && (code.costCodePropertyUid === item.costCodePropertyUid)))) : null;

				let modelValue = (passengerValue != null) ? passengerValue.value : (item.values || [])[0];
				if (applyCurrentCostCodesCollectionValues && prevCollectionItems[item?.costCodeCategoryUid]) {
					modelValue = prevCollectionItems[item?.costCodeCategoryUid].value;
				}
				model.set('value', modelValue);
			} else if (item.controlType === 'SELECT') {
				widget = new Autocomplete({
					bindingProperty: 'value',
					setPropertyOnChange: true,
					label: item.name,
					values: Autocomplete.storeCollection(
						Autocomplete.dictionaryToCollection(_.map(item.values, (el) => ({
							caption: el,
							uid: el,
						}))),
					),
				});

				const passengerValue = _.isObject(passenger) ?
					_.find(passenger.costCodes, (code) => code.costCodeCategoryUid === item.costCodeCategoryUid) : null;

				let modelValue;
				if (applyCurrentCostCodesCollectionValues
					&& prevCollectionItems[item?.costCodeCategoryUid]
					&& _.contains(item.values, prevCollectionItems[item?.costCodeCategoryUid].value)) {
					modelValue = {
						uid: prevCollectionItems[item?.costCodeCategoryUid].value,
						caption: prevCollectionItems[item?.costCodeCategoryUid].value,
					};
				} else if (passengerValue != null && _.contains(item.values, passengerValue.value)) {
					modelValue = {
						uid: passengerValue.value,
						caption: passengerValue.value,
					};
				}
				if (modelValue !== undefined) {
					model.set('value', modelValue);
				}
			} else if (item.controlType === 'SERVICE') {
				let modelValue = item.values[0];
				if (applyCurrentCostCodesCollectionValues && prevCollectionItems[item?.costCodeCategoryUid]) {
					modelValue = prevCollectionItems[item?.costCodeCategoryUid].value;
				}
				model.set('value', modelValue);
			} else {
				throw Error('Unknown controlType for organizationCostCode');
			}

			if (widget != null) {
				widget.applyBinding(model);
				if (isOrganizationCodes) {
					const container = $('<div class="flex-grid-container"></div>');

					this.ui.costCodesField.append(container);
					container.append($('<div class="flex-grid-layout"></div>').append(new InputWidget({
						label: L10N.get('travelPolitics.client'),
						attrs: {
							readonly: true,
							value: item.organization.caption,
						},
					}).$el));

					container.append($('<div class="flex-grid-layout"></div>').append(new InputWidget({
						label: L10N.get('travelPolitics.category'),
						attrs: {
							readonly: true,
							value: item.name,
						},
					}).$el));
					container.append($('<div class="flex-grid-layout b-cost-codes__item-field"></div>').append(widget.$el));
				} else {
					this.ui.costCodesField.append($('<div class="flex-grid-layout b-cost-codes__item-field"></div>').append(widget.$el));
				}
				this.widgets[item.costCodeCategoryUid] = widget;
			}
			this.collection.add(model);
		});

		this.listenTo(this.collection, 'validationError:costCodeCategoryUid', this.showValidationErrorModal.bind(this));
	},

	getPrevCollectionItems(organizationCostCodes = []) {
		if (!organizationCostCodes.length) return {};
		const prevCollectionValuesArr = this.collection.toJSON();
		return prevCollectionValuesArr.reduce((acc, item) => {
			if (organizationCostCodes.find((orgCostCode) => orgCostCode?.costCodeCategoryUid === item?.costCodeCategoryUid)) {
				acc[item.costCodeCategoryUid] = item;
			}
			return acc;
		}, {});
	},

	showValidationErrorModal(value) {
		const message = value[0].text;
		const popup = new Widgets.Popup({
			content: message,
			type: 'danger',
			actions: [{
				label: L10N.get('Common.close'),
				action: () => {
					popup.hide();
				},
			}],
			onClose: () => {
				popup.hide();
			},
		});
		popup.show();
		return this;
	},

	render(attrs) {
		this.removeWidgets();
		BaseView.prototype.render.call(this, attrs);
	},

	removeWidgets() {
		if (!this.widgets) {
			this.widgets = {};
			return;
		}
		if (!_.isEmpty(this.widgets)) {
			Object.keys(this.widgets).forEach((key) => {
				const widget = this.widgets[key];
				if (widget?.destroy) widget.destroy();
				delete this.widgets[key];
			});
		}
	},

	destroy() {
		this.removeWidgets();
		BaseView.prototype.destroy.call(this);
	},

});
