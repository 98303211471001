/* eslint-disable no-use-before-define */
/* eslint-disable banned-modules */
/* eslint-disable-next-line banned-modules */

'use strict';

import axios from 'axios';
import './utils/axios';
import '../assets/css/styles.less';
import Backbone from 'backbone';
import STATE from './classes/application-state';
import Layout from './layout/view';
import Router from './router';
import $ from 'jquery';

const AppWrapper = () => {
	if (STATE.checkSiteType('B2B') && !STATE.layout) {
		new Layout();
	}
	// eslint-disable-next-line no-use-before-define
	new AppView();
};

const AppView = Backbone.View.extend({
	initialize(fnCallback) {
		STATE.APP = this;
		this.fnCallback = fnCallback;

		this.startVersionControl();

		if (window.location.hash.indexOf('authorization/recovery') !== -1) {
			new Router();
		} else {
			if (STATE.checkSiteType('B2C')) {
				this[STATE.getLoggedInUser() != null ? 'getHolder' : 'getHolderCallback']();
			} else {
				this.getHolder();
			}
		}
	},

	getHolder() {
		axios.get('/midoffice/ibecorp-b2b/cabinet/getCabinetHolder').then(this.getHolderCallback.bind(this));
	},

	getHolderCallback(profile) {
		if (STATE.checkSiteType('B2C')) {
			if (profile != null) {
				STATE.setLoginUser(profile.data.result.holder);
			}
			STATE.loadSettings().then(() => this.initLayout(profile || null));
			return;
		}
		STATE.setProfile(profile.data.result.holder);
		STATE.loadSettings().then(() => {
			axios.post('/midoffice/ibecorp-b2b/cabinetNew/getBalancesCompany', {
				parameters: {},
			}).then(response => {
				this.initLayout(response);
			});
		});
	},

	initLayout(res) {
		if (STATE.checkSiteType('B2C') && !STATE.layout) {
			new Layout();
		}
		STATE.layout.renderHeader({
			balanceInfo: res ? res.data.result : null,
		});
		STATE.layout.renderFooter();

		if (STATE.router == null) {
			new Router();
		}

		if (_.isFunction(this.fnCallback)) {
			this.fnCallback();
		}

		if (STATE.getSiteLogo()) {
			const logo = $(`<a href="${this.getRootURL()}" class="b-header__additional-logo"></div>`);
			$(logo).css('background-image', `url("${STATE.getSiteLogo().replace(/\s/g, '')}")`);
			$('.b-header__info .b-header__info-wrapper').prepend(logo);
		}

		$(window).off();
		$(window).on('click', (e) => {
			if (!e || !$(e.target).hasClass('js-navigate-back')) return;
			e.preventDefault();
			window.history.back();
		});
	},

	getRootURL() {
		return `${window.location.origin}${window.location.path ? window.location.path : ''}${window.location.search}`;
	},

	startVersionControl() {
		const versionControl = STATE.get('versionControl');

		axios.get(`/version.json?t=${new Date().getTime()}`).then((response) => {
			if (response != null && response.data != null && response.data.version != null) {
				versionControl.version = response.data.version;
				const head = document.getElementsByTagName('head');
				const linkElement = document.createElement('link');
				linkElement.setAttribute('href', `/midoffice/ibecorp-b2b/site/getStyles?v${versionControl.version}`);
				linkElement.setAttribute('rel', 'stylesheet');
				if (head.length === 1) {
					head[0].appendChild(linkElement);
				}
			}
		});

		versionControl.timerId = setInterval(() => {
			axios.get(`/version.json?t=${new Date().getTime()}`).then((response) => {
				if (response != null && response.data != null && response.data.version != null) {
					if (versionControl.version != null && versionControl.version !== response.data.version) {
						const popup = new Widgets.Popup({
							content: L10N.get('Common.systemUpdateMessage'),
							closeOnlyOnAction: true,
							type: 'info',
							actions: [{
								label: `<i class="g-icon g-icon-reload"></i> ${L10N.get('Common.update')}`,
								action: () => {
									window.location.reload(true);
								},
							}],
							classes: 'b-reload-popup',
						});
						popup.show();
						clearInterval(versionControl.timerId);
					} else {
						versionControl.version = response.data.version;
					}
				}
			});
		}, 300000);
	},
});

// eslint-disable-next-line no-undef
export default AppWrapper;
