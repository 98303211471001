module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-cabinet-person-cost-codes-wrapper">\n  ';
 if (title !== undefined) { ;
__p += '\n    <div class="b-cabinet-person-cost-codes-wrapper__header">\n      ' +
((__t = ( title )) == null ? '' : __t) +
'\n    </div>\n  ';
 } ;
__p += '\n  <div class="js-cabinet-person-cost-codes-wrapper-content"></div>\n</div>';

}
return __p
}