module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-categories">\n	<label class="b-categories__label" for="">' +
((__t = ( L10N.get('hotels.showRooms') )) == null ? '' : __t) +
'</label>\n	<span class="b-categories__selected"></span>\n	<div class="b-categories__drop-down">\n		';
 _.each(variants, function (variant) { ;
__p += '\n				<span  class="b-categories__drop-down-item" type="' +
((__t = ( variant.value )) == null ? '' : __t) +
'">' +
((__t = ( variant.label )) == null ? '' : __t) +
'</span>\n		';
 }) ;
__p += '\n	</div>\n</div>\n';

}
return __p
}