// eslint-disable-next-line banned-modules
'use strict';

import BaseView from '@/classes/base.view';
import template from './template.ejs';

export default BaseView.extend({

	template,

	events: {
		'click .b-customer__allow-edit': 'allowEdit',
	},

	initialize() {
		this.contactAutocompleteAvailable = this.options.contactAutocompleteAvailable;
		this.contactPersonEditGranted = this.options.contactPersonEditGranted;
		this.contactPersonDisableFreeTextInput = this.options.contactPersonDisableFreeTextInput;
		const searchModel = STATE.getSearchModel();
		this.options.client = searchModel.get('client');
		this.render();
		this.listenTo(this.model, 'typeahead:contactSelect', (model, value) => {
			this.autocompleteData(value);
		});
		this.autocompleteData(this.getDataToAutocomplete((this.options.contactFillingType || {}).uid));
	},

	getDataToAutocomplete(type) {
		if (!type) return null;
		return type === STATE.FILLING_TYPE.FIRST_TRAVELLER ? this.options.travellersModel.toJSON()[0] : STATE.getLoggedInUser();
	},

	autocompleteData(customer) {
		if (!customer) return;
		if (!customer.phone && !_.isEmpty(customer.phones)) customer.phone = customer.phones[0];
		if (!customer.email && !_.isEmpty(customer.emails)) customer.email = customer.emails[0];
		if (!customer.personRef && customer.uid) {
			customer.personRef = {uid: customer.uid, caption: `${customer.lastName}`};
		}
		customer = _.extend(customer, customer.passport);
		const data = _.pick(customer, ['lastName', 'firstName', 'phone', 'email', 'personRef']);
		_.each(data, (value, key) => {
			this.model.set(key, value);
		});
		const container = this.$el;
		this.$el.empty();
		this.render();
		container.append(this.$el);
	},

	allowEdit() {
		this.model.unset('disabled');
		this.model.set('overrideCustomer', true);
		this.$el.empty();
		this.render();
	},

});
