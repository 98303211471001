import type { PropsWithChildren, ReactNode } from "react";
import React from "react";

import { forwardRef, useRef, useImperativeHandle } from "react";

export interface ReactDialogApi {
	get isOpen(): boolean;
	show(): void;
	hide(): void;
}

export type DialogProps = PropsWithChildren<{
	label?: string;
	footer?: ReactNode;
}>;

const ReactDialog = forwardRef<ReactDialogApi, DialogProps>(function Dialog(
	{ label, footer, children },
	ref
) {
	const dialogRef = useRef<HTMLDialogElement>(null);

	useImperativeHandle(
		ref,
		() => {
			return {
				get isOpen() {
					return dialogRef?.current?.open;
				},
				show() {
					dialogRef?.current?.showModal();
				},
				hide() {
					dialogRef?.current?.close();
				},
			} as ReactDialogApi;
		},
		[]
	);

	const handleCloseButtonClick = () => {
		dialogRef?.current?.close();
	};

	return (
		<dialog
			ref={dialogRef}
			style={{
				background: "none",
				border: "none",
				outline: "none",
				overflow: "hidden",
			}}
		>
			<div
				className={`b-popup b-button-container b-reload-popup r-content-popup  b-popup--type-info visible ${
					!label ? "without-title" : ""
				}`}
				style={{ zIndex: 2 }}
			>
				<div className="b-button-container__top">
					<div className="b-popup__header">
						<h3 className="b-popup__title">{label}</h3>
					</div>
					<div
						className="b-popup__content"
						style={{
							marginTop: !label ? 1 : 0,
							overflow: "hidden",
						}}
					>
						{children}
					</div>
				</div>
				<div className="b-button-container__footer">
					<span
						onClick={handleCloseButtonClick}
						className="b-button-container__button b-popup__action "
						data-id="0"
						title='<i class="g-icon g-icon-reload"></i> Закрыть'
					>
						<span>
							<i className="g-icon g-icon-reload"></i> Закрыть
						</span>
					</span>
				</div>
			</div>
		</dialog>
	);
});

export default ReactDialog;
