module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-offers-transfers-modal">\n    <div class="b-offers-transfers-modal__title">\n        ';
 if (this.passengers != null) { ;
__p += '\n            ' +
((__t = ( L10N.get('ticket.variantsToCoordination') )) == null ? '' : __t) +
' ' +
((__t = ( L10N.get('Common.for') )) == null ? '' : __t) +
': <span class="b-offers-transfers-modal__title-person"></span>\n        ';
 } else { ;
__p += '\n            ' +
((__t = ( L10N.get('ticket.variantsToCoordination') )) == null ? '' : __t) +
'\n        ';
 } ;
__p += '\n    </div>\n    <div class="b-offers-transfers-modal__list">\n        ';
 _.each(this.offers, function (offer, i) { ;
__p += '\n\n        <div class="b-offers-transfers-modal__list-item b-transfer" style="border: none" data-transfer-token="' +
((__t = ( offer.transferToken )) == null ? '' : __t) +
'" data-copy-container="' +
((__t = ( i )) == null ? '' : __t) +
'">\n            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('cabinet.orders.serviceName') )) == null ? '' : __t) +
': ' +
((__t = ( L10N.get('cabinet.orders.serviceTypes.TRANSFER') )) == null ? '' : __t) +
'</span>\n            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('transfers.carClass') )) == null ? '' : __t) +
': ' +
((__t = ( offer.transfer.carClass && offer.transfer.carClass.caption || '' )) == null ? '' : __t) +
'</span>\n            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('transfers.carModel') )) == null ? '' : __t) +
': ' +
((__t = ( offer.transfer.models )) == null ? '' : __t) +
'</span>\n            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('transfers.capacityTitle') )) == null ? '' : __t) +
': <span data-copy-content-from=".b-transfer__info-capacity-text.mobile-hide"></span></span>\n            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('hotels.cost') )) == null ? '' : __t) +
': <span data-replace-newline=" " data-copy-content-from=".b-offers-transfers-modal__list-item-price-amount"></span></span>\n            <div class="b-offers-transfers-modal__list-item-drag"></div>\n            <div class="b-offers-transfers-modal__list-item-content b-transfer__info">\n                <div class="b-offers-transfers-modal__list-item__photo b-transfer__info-photo" style="background-image: url(\'' +
((__t = ( offer.transfer.imageUrl )) == null ? '' : __t) +
'\'); "></div>\n                <div class="b-offers-transfers-modal__list-item__car-info">\n                    <div class="b-offers-transfers-modal__list-item__car-class">' +
((__t = ( offer.transfer.carClass && offer.transfer.carClass.caption || '' )) == null ? '' : __t) +
'</div>\n                    <div class="b-offers-transfers-modal__list-item__models">' +
((__t = ( offer.transfer.models )) == null ? '' : __t) +
'</div>\n                    ';
 if (offer.transfer.isExceededCapacity) { ;
__p += '\n                        <div class="b-transfer__info-capacity--exceeded-text desktop-hide" style="margin-top: 10px">' +
((__t = ( L10N.get("transfers.exceededCapacity") )) == null ? '' : __t) +
'</div>\n                    ';
 } ;
__p += '\n                </div>\n                <div class="b-offers-transfers-modal__list-item__restrictions b-transfer__info-restrictions">\n                    <div class="b-transfer__info-capacity">\n                        <span class="b-transfer__info-capacity-icon mobile-hide"></span>\n                        <span class="b-transfer__info-capacity-icon green desktop-hide"></span>\n                        <span class="b-transfer__info-capacity-text mobile-hide">\n                            ' +
((__t = ( L10N.get('Common.until'))) == null ? '' : __t) +
' ' +
((__t = ( offer.transfer.capacity )) == null ? '' : __t) +
' ' +
((__t = ( L10N.getPluralForm("transfers.capacity", offer.transfer.capacity) )) == null ? '' : __t) +
'\n                        </span>\n                        <span class="b-transfer__info-capacity-text desktop-hide">\n                            ' +
((__t = ( L10N.get('Common.until'))) == null ? '' : __t) +
' ' +
((__t = ( offer.transfer.capacity )) == null ? '' : __t) +
'\n                        </span>\n                    </div>\n                    ';
 if (offer.transfer.isExceededCapacity) { ;
__p += '\n                        <div class="b-transfer__info-capacity--exceeded">\n                            <span class="b-transfer__info-capacity--exceeded-icon"></span>\n                            <span class="b-transfer__info-capacity--exceeded-text mobile-hide">' +
((__t = ( L10N.get("transfers.exceededCapacity") )) == null ? '' : __t) +
'</span>\n                            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get("transfers.exceededCapacity") )) == null ? '' : __t) +
'</span>\n                        </div>\n                    ';
 } ;
__p += '\n                </div>\n            </div>\n            <div class="b-offers-transfers-modal__list-item-price">\n                <div class="b-offers-transfers-modal__list-item-price-container">\n                    <span class="b-offers-transfers-modal__list-item-price-amount">\n                        ' +
((__t = ( Formatter.formatMoney(offer.transfer.totalPrice.amount, Formatter.getDecimal(offer.transfer.totalPrice.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(offer.transfer.totalPrice.currencyCode) )) == null ? '' : __t) +
'\n                    </span>\n                    <span>\n                        | ' +
((__t = ( L10N.get('Common.behind') )) == null ? '' : __t) +
' <span class="b-offers-transfers-modal__title-person-count"></span> ' +
((__t = ( L10N.get('Common.pers') )) == null ? '' : __t) +
'\n                    </span>\n                </div>\n                <span class="b-offers-transfers-modal__list-item-remove"></span>\n            </div>\n            <div class="break"></div>\n        </div>\n        <div class="b-offers-transfers-modal__form b-offers-transfers-modal__form-control b-offers-transfers-modal__list-item-comment">\n            <span class="js-widget"  onclick=\'return new Widgets.Input({bindingProperty: "comment'+ i +'", placeholder: L10N.get("ticket.comment") })\'></span>\n        </div>\n        <div class="b-offers-transfers-modal__list-item-line">\n        </div>\n        ';
 }); ;
__p += '\n    </div>\n\n    <div class="b-offers-transfers-modal__no-offers ' +
((__t = ( !_.isEmpty(this.offers) ? 'dn' : '' )) == null ? '' : __t) +
'">\n        <span>' +
((__t = ( L10N.get('ticket.noOffers') )) == null ? '' : __t) +
'</span>\n    </div>\n\n    <div class="b-offers-transfers-modal__emails">\n\n    </div>\n\n    <div class="b-offers-transfers-modal__form">\n    ';
 if (this.options != null && !_.isEmpty(this.options.order)) { ;
__p += '\n        <div class="b-message b-message-warning">\n            ' +
((__t = ( L10N.get('ticket.youCanSendOfferToCoordinationOnOrderPage') )) == null ? '' : __t) +
'\n        </div>\n\n        <div class="b-offers-transfers-modal__form-buttons">\n            <div class="b-offers-transfers-modal__form-button b-offers-transfers-modal__order">' +
((__t = ( L10N.get('ticket.putAsideOrder') )) == null ? '' : __t) +
'</div>\n    ';
 } else { ;
__p += '\n        <div class="b-offers-transfers-modal__form-buttons">\n            <div class="b-offers-transfers-modal__form-button b-offers-transfers-modal__submit">' +
((__t = ( L10N.get('ticket.submit') )) == null ? '' : __t) +
'</div>\n            <div class="b-offers-transfers-modal__form-button b-offers-transfers-modal__order">' +
((__t = ( L10N.get('ticket.putAsideOrder') )) == null ? '' : __t) +
'</div>\n    ';
 } ;
__p += '\n            <button class="b-offers-transfers-modal__form-button copy-btn js-copy-to-clipboard mobile-hide">' +
((__t = ( L10N.get('Common.copyToClipboard') )) == null ? '' : __t) +
'</button>\n        </div>\n    </div>\n\n    <div class="b-offers-transfers-modal__close"></div>\n</div>\n';

}
return __p
}