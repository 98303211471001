module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var attachments = attachments || [];
	var issueUid = issueUid || null;
;
__p += '\n<div class="b-order__attachments">\n    ';
_.each(attachments, function(file) { ;
__p += '\n        <div class="b-uploaded-attachment"  data-uid="' +
((__t = ( file.uid )) == null ? '' : __t) +
'">\n            <span class="b-uploaded-attachment__name">' +
((__t = ( file.name )) == null ? '' : __t) +
'</span>\n            <i class="g-icon-w-cancel" data-uid="' +
((__t = ( file.uid )) == null ? '' : __t) +
'" ';
 if (issueUid) { ;
__p += ' data-issue="' +
((__t = ( issueUid )) == null ? '' : __t) +
'" ';
 } ;
__p += '></i>\n        </div>\n    ';
 }) ;
__p += '\n</div>\n';

}
return __p
}