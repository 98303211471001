'use strict';

import BaseView from '@/classes/base.view';
import template from './template.ejs';
import $ from 'jquery';

export default BaseView.extend({

	template,

	events: {
		'click .b-hotel__slider-control--type-prev': 'moveSlideLeft',
		'click .b-hotel__slider-control--type-next': 'moveSlideRight',
		'click .b-hotel__slider-slide': 'toggleFullscreen',
		'click .b-hotel__mobile-close-slider-btn': 'toggleFullscreen',
	},

	ui: {
		slider: '.b-hotel__slider',
	},

	flightInfoView: null,

	initialize(options) {
		this.images = options.images;
		this.withoutAnimate = options.withoutAnimate;
		this.render();
	},

	render(...args) {
		BaseView.prototype.render.apply(this, args);

		_.defer(() => {
			if (!_.isFunction(this.ui.slider.find)) return this;
			this.$parentElement = this.$el.parent();
			this.calculateSizes();
			return this;
		});
		return this;
	},

	calculateSizes() {
		const $slidesList = this.ui.slider.find('.b-hotel__slider-list');

		this.ui.slider.css({width: '', height: ''});
		$slidesList.css({width: '', marginLeft: ''});
		$slidesList.find('.b-hotel__slider-slide').css({width: ''});

		const slidesCount = $slidesList.children().length;
		const sliderWidth = $slidesList.width();
		const sliderHeight = $slidesList.height();
		const sliderUlWidth = slidesCount * sliderWidth;

		if (slidesCount === 0) {
			return;
		}

		this.ui.slider.css({
			width: sliderWidth,
			height: sliderHeight,
		});
		$slidesList.css({
			width: sliderUlWidth,
		});
		$slidesList.find('.b-hotel__slider-slide').css('width', sliderWidth);
		this.currentSlide = this.currentSlide == null ? 0 : this.currentSlide;
		this.moveToSlide(this.currentSlide, this.withoutAnimate);
	},

	moveToSlide(index, withoutAnimate = false) {
		const $slidesList = this.ui.slider.find('.b-hotel__slider-list');

		const slidesCount = $slidesList.children().length;
		const slidesWidth = $slidesList.children().width();

		if (this.sliderAnimate === true) {
			return this;
		}

		this.sliderAnimate = true;
		this.currentSlide = index;
		if (_.isNaN(index) || index >= slidesCount) {
			this.currentSlide = 0;
		} else if (index < 0) {
			this.currentSlide = slidesCount - 1;
		}

		const $slide = $($slidesList.children()[this.currentSlide]);
		if ($slide.length !== 0) {
			const imageUid = $slide.attr('data-image-uid');

			if (imageUid) {
				this.loadImage(`/midoffice/ibecorp-b2b/hotels/image?uid=${imageUid}`).then((img) => {
					this.$el.find('.b-hotel__slider-control').show();
					$slide.removeAttr('data-image-uid');
					$slide.html(img);
				});
			}
		}

		$slidesList.animate({
			marginLeft: this.currentSlide * slidesWidth * -1,
		}, withoutAnimate ? 0 : 300, () => {
			this.sliderAnimate = false;
		});

		const indicator = this.$('.b-hotel__mobile-slide-indicator');
		if (indicator.is(':visible')) indicator.html(`${this.currentSlide + 1}/${slidesCount}`);

		return this;
	},

	moveSlideLeft(e) {
		if (e != null) {
			e.preventDefault();
		}

		this.moveToSlide(--this.currentSlide);
	},

	moveSlideRight(e) {
		if (e != null) {
			e.preventDefault();
		}

		this.moveToSlide(++this.currentSlide);
	},

	loadImage(url) {
		return new Promise((resolve) => {
			const img = new Image();
			img.addEventListener('load', () => resolve(img));
			img.src = url;
		});
	},

	toggleFullscreen(e) {
		if (e) e.preventDefault();
		const isOpen = this.$el.hasClass('open');
		this.$el.toggleClass('open', !isOpen);
		const $body = $('body');
		this.outsideContainer = $body.find('.b-hotel__photo-outside-container');
		if (isOpen) {
			if (this.$parentElement) this.$parentElement.append(this.$el.detach());
			this.outsideContainer.remove();
			this.outsideContainer = null;
			this.calculateSizes();
		} else {
			if (!this.outsideContainer.length) {
				this.outsideContainer = $('<div class="b-hotel__photo-outside-container"></div>');
				this.outsideContainer.append(this.$el.detach());
				this.outsideContainer.addClass('open');
				$body.append(this.outsideContainer);
				this.calculateSizes();
			}
		}
	},

});
