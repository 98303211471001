// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import suggestionTemplate from './suggestion.ejs';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';
import 'corejs-typeahead/dist/typeahead.jquery';
import axios from 'axios';
import BaseCollection from '@/classes/base.collection';
import BaseModel from '@/classes/base.model';

const AutocompleteModel = BaseModel.extend({
	idAttribute: 'uid',
});

const AutocompleteCollection = BaseCollection.extend({
	model: AutocompleteModel,
});

const ContactAutocomplete = Widget.extend({

	template,

	validationTemplate,

	viewName: 'b-booking-form-contacts-autocomplete',

	initialize(options) {
		this.options = options;
		if (this.options.limit) this.options.limit = parseInt(this.options.limit);
		this.queryHandler = this.queryHandler.bind(this);
		this.render();
		this.$typeahead = this.$('.b-booking-form-contacts-autocomplete__value');
		this.$typeahead.typeahead(
			{
				hint: false,
				highlight: false,
				minLength: 0,
				limit: 1,
				classNames: {
					menu: 'b-booking-form-contacts-autocomplete__items',
					wrapper: 'b-booking-form-contacts-autocomplete__wrapper',
					input: 'b-booking-form-contacts-autocomplete__input',
					hint: 'b-booking-form-contacts-autocomplete__hint',
					dataset: 'b-booking-form-contacts-autocomplete__dataset',
					suggestion: 'b-booking-form-contacts-autocomplete__item',
					selectable: 'b-booking-form-contacts-autocomplete__selectable',
					empty: 'b-booking-form-contacts-autocomplete__empty',
					open: 'b-booking-form-contacts-autocomplete__open',
					cursor: 'b-booking-form-contacts-autocomplete__cursor',
					highlight: 'b-booking-form-contacts-autocomplete__highlight',
				},
			},
			{
				limit: 100,
				name: 'contactPerson',
				source: (q, cb, async) => {
					clearTimeout(this.timer);
					this.timer = setTimeout(this.queryHandler, STATE.QUERY_DELAY, q, cb, async);
				},
				display: (item) => this.getDisplay(item),
				async: true,
				templates: {
					suggestion: suggestionTemplate.bind(this),
				},
			});
		if (_.isObject(this.options.attrs)) {
			this.applyAttrs(this.options.attrs);
		}
	},

	getDisplay(item) {
		switch (this.options.displayType) {
			case 'fullName':
				return `${item.lastName} ${item.firstName}`;
			case 'firstName':
				return item.firstName;
			case 'lastName':
			default:
				return item.lastName;
		}
	},

	applyAttrs(attrs) {
		_.each(attrs, (value, key) => {
			if (['true', 'false'].includes(value)) value = value === 'true';
			this.$typeahead.attr(key, value);
		});
	},

	queryHandler(q, cb, async) {
		const suggestionValue = this.model.get('suggestionValue')?.[this.options.bindingProperty];
		if (!suggestionValue || (suggestionValue && suggestionValue !== this.$typeahead.val())) {
			axios.get('/midoffice/ibecorp-b2b/autocomplete/booking-form-contacts', {
				params: {
					pattern: q,
					clientUid: this.options.clientUid,
					limit: this.options.limit,
				},
			}).then((resp) => {
				if (!_.isEmpty(resp.data.result)) this.$typeahead[0].focus();
				async(resp.data.result.reduce((result, item) => {
					result.push(item);
					return result;
				}, []));
			}); 
		}
	},

	applyBinding(model) {
		this.$typeahead.on('typeahead:select', (ev, suggestion) => {
			// debugger;
			this.model.trigger('typeahead:contactSelect', this.model, suggestion);
			this.model.set('suggestionValue', suggestion);
		});

		this.$typeahead.on('typeahead:change', (event, value) => {
			this.model.set(this.options.bindingProperty, value);
		});

		this.listenTo(model, `validationError:${this.options.bindingProperty}`, () => {
			this.$el.addClass('validation-error');
		});

		this.listenTo(model, `change:${this.options.bindingProperty}`, (__model, item) => {
			// debugger;
			this.$typeahead.typeahead('val', item);
		});

		if (model.get(this.options.bindingProperty)) {
			// debugger;
			this.$typeahead.typeahead('val', model.get(this.options.bindingProperty));
		}

		if (this.options.disableFreeTextInput && this.options.disableFreeTextInput === 'true') {
			this.$typeahead.on('blur', (event) => {
				const suggestionValue = this.model.get('suggestionValue');
				if (!suggestionValue || (suggestionValue && suggestionValue[this.options.bindingProperty] !== event.target.value)) {
					this.model.set(this.options.bindingProperty, '');
					this.model.trigger(`validationError:${this.options.bindingProperty}`, [{text: L10N.get('bookingForm.chooseCustomer')}]);
				}
			});
		}
	},

	getCaption(item) {
		return item.iataCode;
	},

}, {
	dictionaryToCollection(data) {
		return new AutocompleteCollection(data);
	},

	arrayToCollection(data) {
		return ContactAutocomplete.dictionaryToCollection(data);
	},

	objectToModel(data) {
		return new BaseModel(data);
	},

	storeCollection(collection) {
		if (!ContactAutocomplete.stores) {
			ContactAutocomplete.stores = {};
		}
		if (!ContactAutocomplete.stores[collection.cid]) {
			ContactAutocomplete.stores[collection.cid] = collection;
		}
		return collection.cid;
	},

	getStoreCollection(cid) {
		return ContactAutocomplete.stores[cid];
	},
});

export default ContactAutocomplete;
