module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-search-progress-bar">\n    <div class="b-search-progress-bar__spinner-container">\n        <div class="b-search-progress-bar__spinner"></div>\n        <div class="b-search-progress-bar__spinner-text">' +
((__t = ( L10N.get('hotels.searchingHotels'))) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="b-search-progress-bar__bar">\n        <span></span>\n    </div>\n</div>\n';

}
return __p
}