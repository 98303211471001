import moment from "moment";
import { NewsItem, SearchFormStatusEnum } from "./interfaces";
import { SearchFormParams } from "../NewsPage";
import { PaginationInfo } from "../../../components/form/Pagination/Pagination";

export const newsItemsParser = (sourceItems: NewsItem[]) => {
	return sourceItems.map((sourceItem: NewsItem) => {
		if (sourceItem.created) {
			sourceItem.created = moment(sourceItem.created);
		}
		return { ...sourceItem };
	});
};

export const searchFormParamsConverter = (
	searchFormParams: SearchFormParams,
	{ pageSize, currentPage }: PaginationInfo
) => {
	const { _dates } = searchFormParams;
	if (_dates && Array.isArray(_dates)) {
		const [startDate, endDate] = _dates;
		if (startDate) {
		}
		searchFormParams.startDate = startDate
			? moment(startDate).format("YYYY-MM-DD")
			: undefined;
		searchFormParams.endDate = endDate
			? moment(endDate).format("YYYY-MM-DD")
			: undefined;
	} else {
		delete searchFormParams.startDate;
		delete searchFormParams.endDate;
	}
	delete searchFormParams._dates;
	if (searchFormParams._status) {
		switch (searchFormParams._status) {
			case SearchFormStatusEnum.IMPORTANT:
				searchFormParams.important = true;
				delete searchFormParams.read;
				break;
			case SearchFormStatusEnum.UNREAD:
				searchFormParams.read = false;
				delete searchFormParams.important;
				break;
			default:
				delete searchFormParams.important;
				delete searchFormParams.read;
				break;
		}
	}
	delete searchFormParams._status;
	searchFormParams.page = currentPage;
	searchFormParams.pageSize = pageSize;
	return { ...searchFormParams };
};
