// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';

export default BaseModel.extend({

	defaults() {
		return {
			location: {
				address: '',
				longitude: '',
				latitude: '',
			},
		};
	},

	initialize() {},

});
