// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import RoomModel from './model';
import TravellerView from '@/blocks/elements/b-hotel/b-hotel-traveller/index';
import AdditionalServicesView from '@/blocks/elements/b-hotel/b-hotel-additional-services/index';
import HotelOfferRemarks from '../b-hotel-offer-remarks';
import template from './template.ejs';

import axios from 'axios';

export default BaseView.extend({

	template,

	ui: {
		travellersContainer: '.b-hotel-travellers__wrapper',
		additionalServicesContainer: '.b-hotel-room__additional-services',
		remarksContainer: '.js-remarks-container',
	},

	initialize() {
		if (this.options.model == null) {
			this.model = new RoomModel();
		}
		this.forbidTravellerUpdate = this.options.forbidTravellerUpdate;
		this.travellerViewList = [];

		if (this.options.offer != null) {
			this.model.set('offer', this.options.offer);
		}

		this.collection.add(this.model);

		this.model.get('travellers').reset();
		const model = this.model.get('travellers').model;
		const {travellersInfo = {ADULT: 1}} = this.options;
		_.each(_.sortBy(_.keys(travellersInfo)), (key) => {
			const value = travellersInfo[key];
			for (let i = 0; i < value; i++) {
				const travellerModel = new model({type: {
					caption: L10N.get(`passengerTypes.${key}`),
					uid: key,
				}});
				this.model.get('travellers').add(travellerModel);
			}
		});

		this.render();
	},

	render(...args) {
		BaseView.prototype.render.apply(this, args);

		const {parent, bookingSettings = {}, index } = this.options;
		const { contactFillingType, contactPersonEditGranted, contactPersonDisableFreeTextInput } = bookingSettings;
		const searchParams = STORE.get(STATE.ROUTES.MAIN_SEARCH);

		/*
			Список корпоративных пассажиров одномерен. Список комнат включает в себя поле 'travellers' - это список
			имен и uid пассажиров. Длинна данных списков можен не совпадать.
		*/
		const travellersByRooms = searchParams?.rooms || bookingSettings.corporatePassengers;

		let hotelCountry = {};

		if (this.options && this.options.parent && this.options.parent.hotel && this.options.parent.hotel.address) {
			hotelCountry = this.options.parent.hotel.address.country || {};
		}
    
		this.model.get('travellers').each((item, i) => {
			const isContact = index === 0 && i === 0 && (contactFillingType || {}).uid === STATE.FILLING_TYPE.FIRST_TRAVELLER;
			let corpPassenger = null;
			if (travellersByRooms.length && searchParams?.rooms) {
				corpPassenger = bookingSettings.corporatePassengers.find((pass) => {
					if (travellersByRooms[index] && travellersByRooms[index]?.travellers?.length) {
						const travellerToCheck = travellersByRooms[index].travellers[i];
						if (travellerToCheck && travellerToCheck?.uid) return travellerToCheck.uid === pass.uid;
					}
					return false;
				});
			} else {
				corpPassenger = travellersByRooms[i];
			}
			
			const travellerView = new TravellerView({
				forbidTravellerUpdate: this.forbidTravellerUpdate,
				isContact,
				index: i,
				bookingSettings,
				contactPersonEditGranted,
				contactPersonDisableFreeTextInput,
				model: item,
				offers: this.options.offers,
				passenger: corpPassenger || null,
				hotelCountry,
				hotelsView: parent,
			});
			this.ui.travellersContainer.append(travellerView.$el);
			this.travellerViewList.push(travellerView);

			const validateAsync = parent.validateAsync.bind(parent);

			this.listenTo(item, 'change', validateAsync);
			this.listenTo(item.get('passport'), 'change', validateAsync);
			this.listenTo(item.get('costCodes'), 'change', () => {
				if (this.options.parent.renderInfoView) {
					this.updatePricingWithFormSettings();
				}
				validateAsync();
			});

			const setContact = (passenger, model) => {
				if (passenger.get('isContact') && !_.isEmpty(model.changed)) {
					const customer = parent.model.get('customer');
					_.each(model.changed, (value, key) => {
						customer.set(key, value);
					});
				}
			};

			this.listenTo(item, 'change:phone change:email', setContact.bind(this, item));
			this.listenTo(item.get('passport'), 'change:lastName change:firstName change:phone change:email', async (model) => {
				if (parent != null && parent.model != null) {
					await parent.validateAsync();
					setContact(item, model);
				}
			});

			this.listenTo(item, `change:isContact`, (model, value) => {
				if (!value) {
					// Сбрасываем personRef
					if (parent?.model && parent.model.get('customer') != null && parent.model.get('customer').get('personRef') != null) {
						parent.model.get('customer').set('personRef', undefined);
					}
					return;
				}

				this.model.get('travellers').each((otherModel) => {
					if (otherModel.cid !== model.cid) {
						otherModel.set('isContact', false);
					}
				});

				if (parent != null && parent.model != null) {
					const lastName = model.get('passport').get('lastName');

					parent.model.get('customer').set('lastName', lastName);
					parent.model.get('customer').set('firstName', model.get('passport').get('firstName'));
					parent.model.get('customer').set('phone', model.get('phone'));
					parent.model.get('customer').set('email', model.get('email'));
					if (lastName && model.get('uid')) {
						/*
							Логика создания personRef копирована из ibecorp\frontend\app-b2b\js\blocks\elements\b-customer\b-customer-hotel\index.js
						*/
						parent.model.get('customer').set('personRef', { uid: model.get('uid'), caption: `${lastName}`});
					}
				}
			});
		});

		if (_.some(this.options.offer.options, (o) => _.isArray(o) && !_.isEmpty(o))) {
			this.additionalServices = new AdditionalServicesView({
				offer: this.options.offer,
				model: this.model,
				baseView: parent,
				showRoomInfo: true,
				index,
			});
			this.ui.additionalServicesContainer.html(this.additionalServices.$el);
		}

		if (this.options.offer.remarks && this.options.offer.remarks.length) {
			this.remarksView = new HotelOfferRemarks({
				parent: this,
				model: this.model,
				offer: this.options.offer,
				remarks: this.options.offer.remarks,
			});
			this.ui.remarksContainer.append(this.remarksView.$el);
		}
	},

	adjustMobileTemplate(matches) {
		const $roomDescContainer = this.$('.b-hotel-room__content-description');
		const $roomDescHeader = this.$('.b-hotel-room__toggle-caption-wrapper');
		const $roomDescToggleContainer = this.$('.b-hotel-room__toggle-container');

		if (matches) {
			$roomDescHeader.prependTo($roomDescToggleContainer);
		} else {
			$roomDescHeader.prependTo($roomDescContainer);
		}

		_.each(this.travellerViewList, (v) => v && v.adjustMobileTemplate(matches));
	},

	updatePricingWithFormSettings() {
		const parameters = {
			parameters: {
				rooms: this.options.parent.model.get('rooms').toJSON(),
			},
		};
		return axios
			.post('/midoffice/ibecorp-b2b/hotels/pricingWithFormSettings', parameters, this.options.parent.model)
			.then((response) => {
				const { result } = response.data;
				if (result != null) {
					this.options.parent.renderInfoView(result);
					if (this.options && this.options.parent && this.options.parent.reasonCodeKeyContanctView) {
						this.options.parent.reasonCode = result.reasonCode;
						this.options.parent.keyContact = result.keyContact;

						this.options.parent.reasonCodeKeyContanctView.update({
							reasonCode: result.reasonCode,
							keyContact: result.keyContact,
						});
					}
				}
			});
	},

});
