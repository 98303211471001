// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

const OrderPricing = BaseView.extend({

	template,

	initialize() {
		this.travelSubjects = _.uniq(_.map(this.options.reservations, (el) => el.travelSubject.uid));
		this.mergePricing = this.mergePricingReservations(this.options.reservations);
		this.render();
	},

	mergePricingReservations(reservations = []) {
		if (!_.isArray(reservations)) {
			return reservations;
		}

		reservations = _.map(reservations, (reservation) => {
			const mergePricing =
				this.mergePricingPassengers([reservation.price.passengerPrices, reservation.priceBack && reservation.priceBack.passengerPrices]);
			const totalPrice = reservation.totalPrice;
			return _.extend(mergePricing, {totalPrice}, {
				travelSubject: reservation.travelSubject,
				taxes: this.options.isSabreProvider ? this.getTaxesFromReservation(reservation) : mergePricing.taxes || null,
			});
		});

		return _.reduce(reservations, (acc, obj) => (acc = OrderPricing.deepMergeSum(acc, obj)));
	},

	getTaxesFromReservation(reservation) {
		const additionalTaxes = _.reduce(reservation.services, (acc, s) => {
			const _taxInfoList = s?.offer?.taxInfoList || [];
			if (_.isEmpty(_taxInfoList)) {
				return acc;
			}

			return acc.concat(_taxInfoList);
		}, []);

		if (!_.isEmpty(additionalTaxes)) {
			const sum = _.reduce(additionalTaxes, (acc, tax) => {
				return +tax.amount.amount + acc;
			}, 0);
			const currencyCode = additionalTaxes[0].amount.currencyCode;
			return {amount: sum, currencyCode};
		}
		return null;
	},

	mergePricingPassengers(arg = []) {
		if (!_.isArray(arg[0])) return arg;

		const process = (price) => {
			if (!_.isObject(price)) {
				return price;
			}
			if (parseInt(price.passengerCount) > 0) {
				const types = ['tariff', 'taxes', 'feeDetails', 'fee', 'feeAndTaxes', 'discount', 'additionalServices'];

				_.each(types, (type) => {
					if (price[type] != null && !isNaN(parseFloat(price[type].amount))) {
						price[type].amount = (parseFloat(price[type].amount) * parseInt(price.passengerCount)).toFixed(2);
					} else if (_.isObject(price[type])) {
						_.each(_.keys(price[type]), (key) => {
							if (price[type][key] == null || isNaN(parseFloat(price[type][key].amount))) return;
							price[type][key].amount = (parseFloat(price[type][key].amount) * parseInt(price.passengerCount)).toFixed(2);
						});
					}
				});
				delete price.passengerCount;
			}

			return price;
		};

		arg[0] = _.map(arg[0], (price) => {
			return process(price);
		});

		const priceBackIncluded = arg[1] != null && _.isArray(arg[1]);
		if (priceBackIncluded) {
			arg[1] = _.map(arg[1], (price) => {
				return process(price);
			});
		}

		const result = _.reduce(priceBackIncluded ? arg : arg[0], (acc, obj) => {
			if (_.isArray(acc)) {
				acc = _.reduce(acc, (a, o) => (a = OrderPricing.deepMergeSum(a, o)));
				obj = _.reduce(obj, (a, o) => (a = OrderPricing.deepMergeSum(a, o)));
			}
			return (acc = OrderPricing.deepMergeSum(acc, obj));
		});

		delete result.passengerType;
		delete result.passengerCount;

		return result;
	},

}, {
	deepMergeSum(obj1, obj2) {
		const keys = _.uniq([..._.keys(obj1), ..._.keys(obj2)]);

		return _.reduce(keys, (acc, key) => {
			if (key === 'passengerCount') {
				acc[key] = obj1[key];
				return acc;
			}

			if (_.isArray(obj2[key])) {
				acc[key] = _.toArray(OrderPricing.deepMergeSum(obj1[key], obj2[key]));
			} else if (_.isObject(obj2[key])) {
				if (obj1[key] == null) {
					acc[key] = obj2[key];
				} else {
					acc[key] = OrderPricing.deepMergeSum(obj1[key], obj2[key]);
				}
			} else if (obj2.hasOwnProperty(key) && !isNaN(parseFloat(obj2[key]))) {
				let value = parseFloat(obj2[key]);
				if (parseInt(obj2.passengerCount) > 0) {
					value *= parseInt(obj2.passengerCount);
				}

				acc[key] = (((obj1 && obj1[key] != null) ? parseFloat(obj1[key]) : 0) + value).toFixed(2);
			} else {
				acc[key] = (obj1 && obj1[key]) || (obj2 && obj2[key]);
			}

			return acc;
		}, {});
	},
});

export default OrderPricing;
