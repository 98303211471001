module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	var step3Settings = STATE.getSettings().thirdStepSettings;
;
__p += '\r\n\r\n<section class="b-booking">\r\n	<section class="b-travellers">\r\n		<header class="b-travellers__header collapse--with-anchor--white mobile-collapse" data-toggle="collapse" data-target=".toggle-travellers">\r\n			' +
((__t = ( L10N.get("bookingForm.passengers") )) == null ? '' : __t) +
'\r\n			';
 if (this.order != null) { ;
__p += '\r\n				(' +
((__t = ( L10N.get('bookingForm.inOrder') )) == null ? '' : __t) +
': ' +
((__t = ( this.order.number )) == null ? '' : __t) +
')\r\n			';
 } ;
__p += '\r\n		</header>\r\n		<div class="b-booking__travellers toggle-travellers show"></div>\r\n	</section>\r\n\r\n	';
 if (this.keyContact || this.reasonCode) { ;
__p += '\r\n		<div class="b-booking__two-selector-wrapper">\r\n			<div class="l-grid-container">\r\n				<div class="b-cost-codes__item-content flex-grid-container">\r\n					<div class="b-booking__reason-code flex-grid-layout b-cost-codes__item-field"></div>\r\n					<div class="b-booking__key-contact flex-grid-layout b-cost-codes__item-field"></div>\r\n				</div>\r\n			</div>\r\n		</div>\r\n	';
 } ;
__p += '\r\n\r\n	';
 if (!_.isEmpty(this.externalSystems)) {;
__p += '\r\n		<div class="b-booking__two-selector-wrapper">\r\n			<span class="b-traveller__block-header">' +
((__t = ( L10N.get("bookingForm.externalSystemCode"))) == null ? '' : __t) +
'</span>\r\n			<div class="l-grid-container">\r\n				';
 _.each(this.externalSystems, function (sys) { 
					const bindingProperty = "externalSystem__" + sys.uid ;
__p += '\r\n				<div class="l-grid-layout-33">\r\n					<span class="pos-rel">\r\n						<span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "' + bindingProperty + '", label: "' + sys.caption + '", })\'></span>\r\n					</span>\r\n				</div>\r\n				';
 });
__p += '\r\n			</div>\r\n		</div>\r\n	';
 } ;
__p += '\r\n\r\n	<div class="b-booking__customer"></div>\r\n    <div class="b-booking__price-info">\r\n        <div class="b-booking__price-info-header collapse--with-anchor--white mobile-collapse" data-toggle="collapse" data-target=".toggle-price-info">' +
((__t = ( L10N.get("bookingForm.paymentInfo") )) == null ? '' : __t) +
'</div>\r\n        <div class="b-booking__price-info-container js-price-info-container toggle-price-info show"></div>\r\n    </div>\r\n    <div class="b-booking__rules"></div>\r\n\r\n	';
 if (step3Settings.displayDKNumber) { ;
__p += '\r\n		<div class="b-booking__dk">\r\n			<div class="l-line"></div>\r\n			<div class="l-grid-container">\r\n				<div class="l-grid-layout-33">\r\n					<span class="js-widget"  onclick=\'return new Widgets.Input({bindingProperty: "dkNumber", label: L10N.get("bookingForm.dkNumber"), placeholder: L10N.get("bookingForm.bonusCardNumberPlaceholder")})\'></span>\r\n				</div>\r\n				<div class="l-grid-layout-33"></div>\r\n				<div class="l-grid-layout-33"></div>\r\n			</div>\r\n		</div>\r\n	';
 } ;
__p += '\r\n	<div class="b-booking__visa-info"></div>\r\n	<div class="b-booking__footer b-block-container__footer">\r\n		<div class="b-block-container__footer-leftbar">\r\n			<button class="btn btn-outline-dark b-booking__back">' +
((__t = ( L10N.get('bookingForm.back') )) == null ? '' : __t) +
'</button>\r\n		</div>\r\n		<div class="b-block-container__footer-rightbar">\r\n			<button class="btn btn-dark b-booking__submit ml-auto">' +
((__t = ( L10N.get("bookingForm.createBookingButton") )) == null ? '' : __t) +
'</button>\r\n		</div>\r\n	</div>\r\n</section>\r\n\r\n';

}
return __p
}