// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

export default BaseView.extend({

	template,

	initialize(options) {
		const prices = _.compact([options.price, options.priceBack]);
		const additionalServices = _.compact([options.additionalServices, options.additionalServicesBack]);
		this.render({prices, total: options.totalPrice, additionalServices});
	},
});
