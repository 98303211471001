// eslint-disable-next-line banned-modules
'use strict';

import './style.mobile.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

import $ from 'jquery';
import axios from 'axios';
import brandedFaresMobileTemplate from '../b-branded-fares-mobile.ejs';

export default BaseView.extend({

	template,

	events: {
		'click .b-avia-offer-preview-price__button': 'select',
		'click .b-fares__select-tariff': 'selectBrandedFare',
		'click .b-fares__select-tariff--offer': 'selectBrandedFareOffer',
		'click .b-branded-fares__additionalDetails': 'showAdditionalDetails',
		'click .b-avia-offer-preview__branded-fares': 'showBrandedFares',
		'click .b-avia-offer-preview-price__button--offer': 'addOffer',
		'click .b-avia-offer-preview__fare-rules-open': 'openRuleCarrier',
		'click .b-avia-offer-preview__close-btn': 'closePreview',
		'click .b-fares__close': 'toggleBrandedFares',
		'mousedown .b-fares__fares-container': 'touchStart',
		'mouseup .b-fares__fares-container': 'touchEnd',
		'mouseleave .b-fares__fares-container': 'touchEnd',
		'mousemove .b-fares__fares-container': 'touchMove',
		'touchstart .b-fares__fares-container': 'touchStart',
		'touchend .b-fares__fares-container': 'touchEnd',
		'touchmove .b-fares__fares-container': 'touchMove',
		'click .slider-indicator': 'setPositionBySliderIndicator',
		'click .slider-overflow-arrow': 'setPositionBySliderArrow',
	},

	className: 'b-avia-offer-preview-preview b-avia-offer-preview',

	ui: {
		brandedFaresModal: '.b-flight-ticket__branded-fares--mobile',
		selectBrandedFaresButton: '.b-fares__select-tariff',
		selectBrandedFaresOfferButton: '.b-fares__select-tariff--offer',
		standardFaresModal: '.b-flight-ticket__standard-fares--mobile',
		sliderIndicators: '.b-fares__slider-indicators',
		sliderIndicatorsContainer: '.b-fares__slider-indicators-container',
		footer: '.b-fares__footer',
		offerLegsContainer: '.b-avia-offer-preview__legs',
		offerPriceButtons: '.b-avia-offer-preview__price-buttons',
	},

	initialize(options) {
		this.options = options;
		this.approvalAllowed = this.options.approvalAllowed;
		this.issueAllowed = this.options.issueAllowed;
		this.flightFares = this.options.parent.flightFares;
		this.model = this.options.model;

		this.animationId = 0;
		this.isDragging = false;
		this.startPos = 0;
		this.currPos = 0;
		this.currTranslate = 0;
		this.prevTranslate = 0;
		this.currentIndex = 0;
		this.previousIndex = 0;
		this.animation = () => {
			this.setTranslate();
			if (this.isDragging) requestAnimationFrame(this.animation);
		};
		this.touchStart = this.touchStart.bind(this);
		this.touchEnd = this.touchEnd.bind(this);
		this.touchMove = this.touchMove.bind(this);
		this.setTranslate = this.setTranslate.bind(this);
		this.setPositionByIndex = this.setPositionByIndex.bind(this);
		this.getPosX = this.getPosX.bind(this);
	},

	render(...args) {
		BaseView.prototype.render.apply(this, args);
		return this;
	},

	touchStart(e) {
		this.startPos = this.getPosX(e);
		this.animationId = requestAnimationFrame(this.animation);
		this.isDragging = true;
		this.faresContainer.addClass('grabbing');
	},

	touchEnd() {
		this.isDragging = false;
		cancelAnimationFrame(this.animationId);
		this.faresContainer.removeClass('grabbing');
		this.previousIndex = this.currentIndex;
		$(`[data-fare-card="${this.previousIndex}"]`).removeClass('selected');
		$(`[data-fare-card="${this.currentIndex}"]`).addClass('selected');
		const movedBy = this.currTranslate - this.prevTranslate;

		if (movedBy < -100 && this.currentIndex < _.size($('[data-fare-card]')) - 1) this.currentIndex += 1;
		if (movedBy > 100 && this.currentIndex > 0) this.currentIndex -= 1;

		this.setPositionByIndex();
	},

	touchMove(e) {
		if (this.isDragging) {
			this.currPos = this.getPosX(e);
			this.currTranslate = this.prevTranslate + this.currPos - this.startPos;
			this.setTranslate();
		}
	},

	setTranslate() {
		this.faresContainer.css('transform', `translateX(${this.currTranslate}px)`);
	},

	setPositionByIndex() {
		this.currTranslate = this.currentIndex * -window.innerWidth;
		this.prevTranslate = this.currTranslate;
		this.setTranslate();
		$('[data-fare-card]').removeClass('selected');
		$(`[data-fare-card="${this.currentIndex}"]`).addClass('selected');
		this.changeRelativeSlideIndicator();
		this.setButtonFareIndex();
	},

	setPositionBySliderIndicator(e) {
		if (!e) return;
		e.preventDefault();
		this.previousIndex = this.currentIndex;
		this.currentIndex = parseInt($(e.currentTarget).attr('data-indicator-id'));
		this.setPositionByIndex();
	},

	setPositionBySliderArrow(e) {
		if (!e) return;
		e.preventDefault();

		this.previousIndex = this.currentIndex;
		const isNext = e.currentTarget.dataset.nextCard != null;
		if (this.currentIndex === _.size($('[data-fare-card]')) - 1 && isNext) return;
		if (this.currentIndex === 0 && !isNext) return;
		this.currentIndex = isNext ? this.currentIndex + 1 : this.currentIndex - 1;
		this.setPositionByIndex();
	},

	getPosX(e) {
		return e.type.includes('mouse') ? e.pageX : e.touches[0].clientX;
	},

	changeRelativeSlideIndicator() {
		$(`[data-indicator-id="${this.previousIndex}"]`).removeClass('active');
		const $currentIndicator = this.$el.find(`[data-indicator-id="${this.currentIndex}"]`);
		const $container = this.$el.find('.b-fares__slider-indicators-container');
		$currentIndicator.addClass('active');

		const width = $container.width();
		const currentScroll = $container.scrollLeft();
		const isOverFlowedRight = width < $currentIndicator.position().left;
		const isOverFlowedLeft = ($currentIndicator.position().left - width) < 0;
		if ((this.previousIndex > this.currentIndex) && isOverFlowedLeft) {
			$container.scrollLeft(currentScroll - width);
		}
		if ((this.previousIndex < this.currentIndex) && isOverFlowedRight) {
			$container.scrollLeft(currentScroll + width);
		}
	},

	setButtonFareIndex() {
		const flightIndex = this.$el.find(`[data-fare-card="${this.currentIndex}"]`).attr('data-flight-index');
		this.ui.selectBrandedFaresButton.attr('data-index', flightIndex);
		this.ui.selectBrandedFaresOfferButton.attr('data-index', flightIndex);

		if (this.ui.selectBrandedFaresOfferButton.hasClass('b-ticket-offer__cancel')) {
			this.ui.selectBrandedFaresOfferButton.html(L10N.get('avia.remove'));
		} else {
			this.ui.selectBrandedFaresOfferButton.html(L10N.get('ticket.addToCoordination'));
		}
	},

	showBrandedFares(e, closeOffer = false) {
		if (e != null) {
			e.preventDefault();
		}

		this.closeOffer = closeOffer;

		const $container = this.$el.find('[data-branded-container]');

		if (this.flightFares == null) {
			STATE.showLoader();
			this.disableElements(e);

			axios.post('/midoffice/ibecorp-b2b/avia/searchFlightFares', {
				parameters: {
					flightToken: this.model.get('flightToken'),
				},
			}).then((response) => {
				STATE.hideLoader();
				const {result} = response.data;
				this.options.parent.flightFaresResult = result.flights;
				this.options.parent.loadBrandedFares.call(this, result.flights);
				this.options.parent.flightFares = this.flightFares;
				this.options.parent.flattenFlightFares = _.flatten(_.values(this.flightFares));
				this.renderFares($container);
				this.toggleBrandedFares(e);
			}).catch(() => {
				STATE.hideLoader();
				Widgets.Popup.showUnknownErrorPopup();
			});
		} else {
			this.renderFares($container);
			this.toggleBrandedFares(e);
		}
	},

	renderFares($container) {
		const options = {
			flight: this.model.get('flight'),
			approvalAllowed: this.approvalAllowed,
			issueAllowed: this.issueAllowed,
			airlineCode: this.model.get('flight').carrier.airlineCode,
		};

		$container.empty();

		const addBrandedFares = (bf) => {
			if (!_.isEmpty(bf)) {
				const $template = brandedFaresMobileTemplate({
					flightFares: bf,
					...options,
				});
				$container.append($template);
			}
		};

		_.each((this.flightFares.brandedFares), addBrandedFares);

		if (!_.every(this.flightFares.brandedFares, _.isEmpty)) {
			if (this.flightFares.brandedFares.length >= 2) addBrandedFares(_.last(this.flightFares.brandedFares), this.flightFares.brandedFares.length);
		}

		const uniqStandardFares = [_.uniq(_.flatten(this.flightFares.standartFares), (fare) => fare._flightIndex)];

		_.each(uniqStandardFares, (sf) => {
			if (_.isEmpty(sf)) return;
			const $template = brandedFaresMobileTemplate({
				flightFares: sf,
				...options,
			});

			this.$el.find('[data-standard-container]').append($template);
		});
	},

	toggleBrandedFares(e) {
		if (e != null) e.preventDefault();
		this.showBranded = false;
		this.showStandard = false;

		this.ui.offerLegsContainer.toggleClass('dn');

		if (this.ui.brandedFaresModal.hasClass('open') || this.ui.standardFaresModal.hasClass('open')) {
			this.ui.brandedFaresModal.toggleClass('open', false);
			this.ui.standardFaresModal.toggleClass('open', false);

			// close all popups
			if (this.closeOffer) {
				$('.l-layout').removeClass('show-offer-preview');
				this.closeOffer = false;
			}

			return this;
		}

		if (!_.every(this.flightFares.brandedFares, _.isEmpty)) this.showBranded = true;
		if (!_.every(this.flightFares.standartFares, _.isEmpty)) this.showStandard = true;
		if (!this.showBranded && !this.showStandard) return this;
		if (this.showBranded) {
			this.faresContainer = $('[data-branded-container]');
			this.ui.standardFaresModal.empty();
		}
		if (this.showStandard) {
			this.faresContainer = $('[data-standard-container]');
			this.ui.brandedFaresModal.empty();
		}

		if (this.ui.sliderIndicatorsContainer.is(':empty')) {
			let indicatorsOverflow = false;
			_.each($('[data-fare-card]'), ($el, i, collection) => {
				this.ui.sliderIndicatorsContainer.append(
					`<div class="slider-indicator ${i === 0 ? 'active' : ''}" data-indicator-id="${i}"></div>`,
				);
				indicatorsOverflow = collection.length >= 10;
			});
			this.ui.footer.toggleClass('overflowed', indicatorsOverflow);
		}

		this.ui.brandedFaresModal.toggleClass('open', this.showBranded);
		this.ui.standardFaresModal.toggleClass('open', this.showStandard);

		return this;
	},

	openRuleCarrier(e) {
		this.options.parent.openRuleCarrier.call(this, e);
	},

	selectBrandedFareOffer(e) {
		this.options.parent.selectBrandedFareOffer.call(this, e);
	},

	selectBrandedFare(e) {
		this.options.parent.selectBrandedFare.call(this, e);
	},

	select(...args) {
		this.options.parent.select.call(this, ...args);
	},

	showAdditionalDetails(e) {
		this.options.parent.showAdditionalDetails.call(this, e);
	},

	addOffer(e) {
		this.options.parent.addOffer.call(this, e);
	},

	closePreview() {
		const $target = this.options.parent.$el.find('.b-avia-offer-preview-price__button--offer');
		const targetSelected = $target.hasClass('b-ticket-offer__cancel');
		const previewOfferButtonSelected = _.size(this.$el.find('.b-ticket-offer__cancel'));
		if (previewOfferButtonSelected) {
			if (!targetSelected) {
				const width = $target.outerWidth();

				$target.attr('data-original-caption', _.escape($target.html()));
				$target.attr('data-flight-token', _.escape(this.model.get('flightToken')));
				$target.text(L10N.get('avia.remove'));

				$target.addClass('b-ticket-offer__cancel');
				$target.css('width', width);
			}
		} else if (!previewOfferButtonSelected && targetSelected) {
			$target.click();
		}
		$('.l-layout').removeClass('show-offer-preview');
		document.documentElement.scrollTop = document.body.scrollTop = this.options.ticketYPosition;
	},
});
