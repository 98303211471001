// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import template from './template.ejs';
import BaseView from '@/classes/base.view';
import TransfersCollection from './collection';

import ToggleModel from '@/blocks/elements/b-filters/widgets/toggle/model';
import RangeModel from '@/blocks/elements/b-filters/b-filters-transfers/range/model';
import SelectModel from '@/blocks/elements/b-filters/b-filters-transfers/select/model';
import InputModel from '@/blocks/elements/b-filters/widgets/input/model';

import GlUl from '@/utils/global-utils';
import $ from 'jquery';

export default BaseView.extend({

	template,

	ui: {
		sorting: '.b-filters-transfers__sorting',
		categories: '.b-filters-transfers__categories',
		widgets: '.b-filters-transfers__widgets',
		count: '.b-filters-transfers__count',
		dropFilters: '.b-filters__drop-filters-button',
		travelPolicy: '.b-filters__travel_policy',
	},

	events: {
		'click .b-filters__mobile-apply': 'closeFiltersMobile',
		'click .b-filters__mobile-reset': 'resetFilters',
		'click .b-filters__drop-filters-button': 'resetFilters',
	},

	preinitialize(options) {
		BaseView.prototype.preinitialize.call(this, options);
		this.windowEventListenerList.push({
			name: 'scroll',
			callback: GlUl.resetButtonOnScroll,
		});
	},

	initialize(options) {
		this.collection = new TransfersCollection(null, {
			results: options.models,
			currencyCode: options.currencyCode,
		});

		this.render();
		this.addEventListeners();
	},

	addEventListeners() {
		this.listenTo(this.collection, 'filterCollection', () => {
			this.applyFilters();
		});
	},

	render(...args) {
		BaseView.prototype.render.apply(this, args);

		const remove = [];
		const $container = this.ui.widgets;

		_.each(this.collection.models, (model) => {
			if (!model.isValid()) {
				remove.push(model);
				return;
			}

			const content = model.render();
			if (content != null) {
				$container.append(content.$el.addClass(`b-filters-transfers__item b-filters-transfers__${model.get('field')}`));
			}
		});

		this.collection.remove(remove);
		this.collection.bind();

		return this;
	},

	addSorting(view) {
		this.ui.sorting.append(view.el);
	},

	addCategories(view) {
		this.ui.categories.append(view.el);
	},

	// EVENTS
	applyFilters(e) {
		if (e != null) {
			e.preventDefault();
		}

		_.each(this.collection.visibleResults, (item) => { item.trigger('visibilityChange', item, true); });
		_.each(this.collection.filteredResult, (item) => { item.trigger('visibilityChange', item, false); });

		this.$el.find('.b-filters-transfers__apply').hide();
		this.$el.find('.b-filters-transfers__reset').show();
	},

	resetFilters(e) {
		if (e != null) {
			e.preventDefault();
		}

		_.each(this.collection.models, (model) => {
			if (model instanceof ToggleModel) {
				model.set('value', false);
			} else if (model instanceof RangeModel) {
				model.set('value', [model.get('min'), model.get('max')]).trigger('reset:range');
			} else if (model instanceof SelectModel) {
				model.get('values').forEach((item) => item.set('value', false));
			} else if (model instanceof InputModel) {
				model.set('value', '');
			}
		});

		_.each(this.collection.visibleResults, (item) => { item.trigger('visibilityChange', item, true); });
		_.each(this.collection.filteredResult, (item) => { item.trigger('visibilityChange', item, false); });

		this.$el.find('.b-filters-transfers__apply').addClass('btn-disabled').show();
		this.$el.find('.b-filters-transfers__reset, .b-filters-transfers__footer-variants').hide();

		this.closeFiltersMobile();
	},

	closeFiltersMobile() {
		$('.l-layout').removeClass('show-filters');
	},

});
