module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var caption = lastName + ' ' + firstName;
    if (lastName.trim() === '' || firstName.trim() === '') {
      caption = caption.replace(/\s/ig, '');
      if (caption === '') {
        caption = "[Не задано]";
      }
    }
;
__p += '\n';
 if (caption.trim().length) { ;
__p += '\n    <div class="b-booking-form-contacts-autocomplete__item">\n        <span class="b-booking-form-contacts-autocomplete__item-code">' +
((__t = ( caption )) == null ? '' : __t) +
'</span>\n    </div>\n';
 } ;
__p += '\n';

}
return __p
}