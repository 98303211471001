import axios from "axios";
import { initialNoticesState } from "../../../../../../react/store/notices";

export const fetchNoticesSummary = async () => {
	try {
		const { data = {} } = await axios.get(
			"/midoffice/ibecorp-b2b/notices/summary"
		);

		return data.result;
	} catch (err) {
		logger.error(err);
    return initialNoticesState;
	}
};
