// eslint-disable-next-line banned-modules
'use strict';

import Backbone from 'backbone';

const BaseModel = Backbone.Model.extend({

	initialize(attr, options) {
		this.options = options;
	},

	__internalSet(key, val, options, obj) {
		if (key.indexOf('.') >= 0) {
			const pathParts = key.split('.');
			let oldValue = obj[pathParts[0]];
			const newPath = pathParts.slice(1).join('.');

			if (oldValue === undefined || oldValue === null) {
				obj[pathParts[0]] = oldValue = {};
			}

			if (oldValue instanceof Backbone.Model) {
				oldValue.once(`change:${newPath}`, (...args) => {
					if (val === args[1]) {
						this.trigger(`change:${key}`, ...args);
					}
				});
				oldValue.set(newPath, val, options);
				return false;
			}
			return this.__internalSet(newPath, val, options, oldValue);
		}
		if (obj[key] !== val) {
			obj[key] = val;
			return true;
		}
		return false;
	},

	// Nested set, only to string keys;
	set(key, val, options) {
		if (_.isString(key)) {
			if (key.indexOf('.') >= 0) {
				if (this.__internalSet(key, val, options, this.attributes)) {
					this.trigger(`change:${key}`, this, val);
				}
			} else {
				Backbone.Model.prototype.set.call(this, key, val, options);
			}
		} else {
			Backbone.Model.prototype.set.call(this, key, val, options);
		}
		return this;
	},

	get(attr) {
		if (attr && _.isString(attr)) {
			if (attr.indexOf('.') >= 0 || attr.indexOf('[') >= 0) {
				const parts = attr.split('.');
				let obj = this.attributes;
				while (parts.length > 0) {
					if (obj instanceof BaseModel) {
						return obj.get(parts.join('.'));
					}
					const part = parts.shift();
					if (part.indexOf('[') >= 0) {
						/*eslint-disable */
						const v = /(.*)\[(.*)\]/.exec(part);
						/*eslint-enable */
						if (obj[v[1]]) {
							obj = obj[v[1]][v[2]];
						} 						
					} else {
						obj = obj[part];
					}
					if (!(obj)) {
						return obj;
					}
				}
				return obj;
			}
			return Backbone.Model.prototype.get.call(this, attr);
		}
		return undefined;
	},

	toJSON() {
		const json = {};
		Object.keys(this.attributes).forEach(key => {
			if (this.attributes.hasOwnProperty(key)) {
				if (this.attributes[key] instanceof Backbone.Model) {
					json[key] = this.attributes[key].toJSON();
				} else if (this.attributes[key] instanceof Backbone.Collection) {
					json[key] = this.attributes[key].toJSON();
				} else {
					json[key] = this.attributes[key];
				}
			}
		});
		return json;
	},

	isValidationMessage(obj) {
		return Array.isArray(obj) && obj.reduce((result, item) => {
			return result && item.hasOwnProperty('code') && item.hasOwnProperty('text');
		}, true);
	},

	isCollectionValidationMessage(obj) {
		return Object.keys(obj).reduce((result, item) => {
			return result && this.isValidationMessage(obj[item]);
		}, true);
	},

	triggerValidation(path, value) {
		this.trigger(`validationError:${path}`, value);
		logger.debug(`validationError:${path}`, value, this);
	},

	handleValidation(validatedParameters, path = '', parent, parentPath) {
		if (this.isValidationMessage(validatedParameters)) {
			this.triggerValidation(path, validatedParameters);
			return validatedParameters;
		}
		const messages = [];

		Object.keys(validatedParameters).forEach((key) => {
			let newPath = path ? `${path}.${key}` : `${key}`;
			const value = validatedParameters[key];
			const modelValue = this.get(newPath);

			if (this.isValidationMessage(value)) {
				if (modelValue instanceof Backbone.Model && !modelValue._events[`validationError:${key}`]) {
					modelValue.trigger('noWidgetListening', newPath, value);
				}
				if (modelValue instanceof Backbone.Collection && !modelValue.models.length) {
					modelValue.trigger('noWidgetListening', newPath, value);
				}
				this.triggerValidation(newPath, value);
				logger.debug('triggerValidation', newPath, value);
				if (parent && parentPath) {
					parent.triggerValidation(`${parentPath}.${newPath}`, value);
					logger.debug('triggerValidation', `${parentPath}.${newPath}`, value);
				}
				messages.push(value);
			} else if (Array.isArray(value)) {
				value.forEach((item, index) => {
					newPath += `[${index}]`;
					messages.push(this.handleValidation(item, newPath));
				});
			} else {
				if (modelValue instanceof Backbone.Model) {
					if (!modelValue._events[`validationError:${key}`]) {
						modelValue.trigger('noWidgetListening', newPath, value);
					}
					messages.push(modelValue.handleValidation(value, '', this, newPath));
				} else if (modelValue instanceof Backbone.Collection) {
					if (this.isCollectionValidationMessage(value)) {
						if (!modelValue.models.length) {
							modelValue.trigger('noWidgetListening', newPath, value);
						}
						modelValue.each((item, index) => {
							item.triggerValidation(key, value[index]);
							messages.push(value[index]);
						});
					}
					Object.keys(value).forEach((key_) => {
						messages.push(this.get(newPath).at(parseInt(key_)).handleValidation(value[key_], '', this, newPath));
					});
				} else {
					messages.push(this.handleValidation(value, newPath));
				}
			}
			logger.debug(key);
		});
		return messages;
	},

});

export default BaseModel;
