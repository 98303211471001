module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

  var langRegistrationFullName = options && options.langRegistrationFullName || {};
    var text = '';
	switch (langRegistrationFullName.uid) {
        case 'LATIN':
            text = L10N.get('bookingForm.yourAttention_text_lat');
            break;
        case 'LATIN_AND_CYRILLIC':
            text = L10N.get('bookingForm.yourAttention_text_lat_cyr')
            break;
        case 'CYRILLIC':
            text = L10N.get('bookingForm.yourAttention_text_cyr');
            break;
    }

;
__p += '\n<p>\n    <div>' +
((__t = ( L10N.get('bookingForm.yourAttention') )) == null ? '' : __t) +
'</div>\n    ' +
((__t = ( L10N.get('bookingForm.yourAttention_text_1') )) == null ? '' : __t) +
'\n    ' +
((__t = ( text )) == null ? '' : __t) +
'\n    ' +
((__t = ( L10N.get('bookingForm.yourAttention_text_2') )) == null ? '' : __t) +
'\n</p>\n';

}
return __p
}