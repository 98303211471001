// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import suggestionTemplate from './suggestion.ejs';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';
import 'corejs-typeahead/dist/typeahead.jquery';
import axios from 'axios';
import TransliterationPopup from '@/widgets/b-transliteration-popup';

export default Widget.extend({

	template,

	validationTemplate,

	viewName: 'b-passenger-autocomplete',

	initialize(options) {
		this.options = options;
		this.queryHandler = this.queryHandler.bind(this);
		this.render();
		this.$typeahead = this.$('.b-passenger-autocomplete__value');
		this.options.transliterationOn = [true, 'true'].includes(this.options.transliterationOn);
		this.transliterationPopup = null;
		this.$typeahead.typeahead(
			{
				hint: false,
				highlight: false,
				minLength: 0,
				limit: 1,
				classNames: {
					menu: 'b-passenger-autocomplete__items',
					wrapper: 'b-passenger-autocomplete__wrapper',
					input: 'b-passenger-autocomplete__input',
					hint: 'b-passenger-autocomplete__hint',
					dataset: 'b-passenger-autocomplete__dataset',
					suggestion: 'b-passenger-autocomplete__item',
					selectable: 'b-passenger-autocomplete__selectable',
					empty: 'b-passenger-autocomplete__empty',
					open: 'b-passenger-autocomplete__open',
					cursor: 'b-passenger-autocomplete__cursor',
					highlight: 'b-passenger-autocomplete__highlight',
				},
			},
			{
				limit: 100,
				name: 'passenger',
				source: (q, cb, async) => {
					clearTimeout(this.timer);
					this.timer = setTimeout(this.queryHandler, STATE.QUERY_DELAY, q, cb, async);
				},
				display: (item) => `${item.lastNameLat}`,
				async: true,
				templates: {
					suggestion: suggestionTemplate.bind(this),
				},
			});
	},

	queryHandler(q, cb, async) {
		if (!_.isEmpty(this.options.corporatePassengers)) {
			return async(this.options.corporatePassengers);
		} else {
			return axios.get('/midoffice/ibecorp-b2b/autocomplete/passengers', {
				params: {
					pattern: q,
				},
			}).then((resp) => {
				async(resp.data.result.reduce((result, item) => {
					result.push(item);
					return result;
				}, []));
			});
		}
	},

	initTransliteration(model) {
		if (this.transliterationPopup == null) {
			this.transliterationPopup = new TransliterationPopup({
				bindingProperty: this.options.bindingProperty,
				targetInput: this.$typeahead,
				autoCorrectionAdapter: this.options.autoCorrectionAdapter,
			});
			this.transliterationPopup.applyBinding(model);
			this.transliterationPopup.transliterate(); // <- запустить проверку сразу после первого рендера не дожидаясь ввода пользователя
		}
	},

	applyBinding(model) {
		this.$typeahead.on('typeahead:select', (ev, suggestion) => {
			this.model.trigger('typeahead:passengerSelect', this.model, suggestion);
			_.defer(() => {
				if (this.options.transliterationOn && this.transliterationPopup) {
					this.transliterationPopup.transliterate();
				}
			});
		});

		if (this.options.transliterationOn) this.initTransliteration(model);

		this.$typeahead.on('typeahead:change', (event, value) => {
			this.model.set(this.options.bindingProperty, value);
		});

		this.listenTo(model, `validationError:${this.options.bindingProperty}`, () => {
			this.$el.addClass('validation-error');
		});

		this.listenTo(model, `change:${this.options.bindingProperty}`, (__model, item) => {
			this.$typeahead.typeahead('val', item);
		});

		if (model.get(this.options.bindingProperty)) {
			this.$typeahead.typeahead('val', model.get(this.options.bindingProperty));
		}
	},

	getCaption(item) {
		return item.iataCode;
	},

	remove() {
		if (this.transliterationPopup?.remove) {
			this.transliterationPopup.remove();
		}
		Widget.prototype.remove.call(this);
	},

});
