// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './b-ticket-popup-offers-modal.ejs';

import EmailsCollectionView from './b-emails-collection/index';
import EmailsCollection from './b-emails-collection/collection';
import EmailModel from './b-emails-collection/b-email/model';

import GlUl from '@/utils/global-utils';
import axios from 'axios';
import $ from 'jquery';

export default BaseView.extend({

	template,

	events: {
		'click .b-offers-avia-modal__close': 'closeModal',
		'click .b-offers-avia-modal__order': 'orderOffers',
		'click .b-offers-avia-modal__submit': 'submitOrder',
		'click .b-offers-avia-modal__list-item-remove': 'removeOffer',
		'click .js-copy-to-clipboard': 'copyToClipboard',
	},

	ui: {
		noOffersContainer: '.b-offers-avia-modal__no-offers',
		formButtons: '.b-offers-avia-modal__form-button',
	},

	offers: [],
	ticketModalOffers: null,

	preinitialize(options) {
		BaseView.prototype.preinitialize.call(this, options);
		this.windowEventListenerList.push({
			name: 'resize',
			callback: GlUl.syncModalWidthPosition.bind(this),
		});
	},

	initialize(options) {
		GlUl.popupOffersViewInitialize.call(this, options);
	},

	render(...args) {
		BaseView.prototype.render.apply(this, args);

		this.showModal();
		this.enableDragSort('.drag-sort-enable');

		if (this.passengers != null) {
			this.$el.find('.b-offers-avia-modal__title-person').html(this.passengers.join(', '));
			this.$el.find('.b-offers-avia-modal__title-person-count').text(this.passengers.length);
		} else {
			this.$el.find('.b-offers-avia-modal__title-person-count').text(this.passengersCount || 1);
		}

		this.model.set('emails', new EmailsCollection([], {
			model: EmailModel,
		}));

		const emailsContainer = this.$('.b-offers-avia-modal__emails');
		emailsContainer.append(new EmailsCollectionView({
			autoSuggestEmails: this.model.get('autoSuggestEmails'),
			collection: this.model.get('emails'),
			container: emailsContainer,
			model: this.model,
		}));

		return this;
	},

	setOptions({$ticketContainer, data}) {
		this.$ticketContainer = $ticketContainer;
		this.offers = data;

		return this;
	},

	closeModal() {
		if (this.ticketModalOffers != null) {
			this.ticketModalOffers.hide();
		}
	},

	showModal() {
		this.ticketModalOffers = new Widgets.Popup({
			content: this.$el,
			actions: [],
			classes: 'b-ticket-popup-offers-modal',
			maskClasses: 'b-ticket-popup-offers-modal_mask',
			overlayFixed: true,
		});

		GlUl.syncModalWidthPosition.call(this);
		this.ticketModalOffers.show();
	},

	checkOffersCount() {
		if (_.isEmpty(this.offers)) {
			this.ui.noOffersContainer.removeClass('dn');
			this.ui.formButtons.addClass('disabled');
		} else {
			this.ui.noOffersContainer.addClass('dn');
			this.ui.formButtons.removeClass('disabled');
		}
	},

	updateOffersOrder() {
		const $offersCards = this.$el.find('.b-offers-avia-modal__list .b-offers-avia-modal__list-item');
		const orderFlightToken = _.map($offersCards, (o) => o.getAttribute('data-flight-token'));
		GlUl.updateOffersOrder.call(this, orderFlightToken);
	},

	enableDragSort(listClass) {
		const sortableLists = this.$el.find(listClass);
		_.each(sortableLists, (list) => this.enableDragList(list));
	},

	enableDragList(list) {
		_.each(list.children, (item) => this.enableDragItem(item));
	},

	enableDragItem(item) {
		$(item).attr('draggable', true);

		item.ondrag = GlUl.handleDrag.bind(this);
		item.ondragend = this.handleDrop.bind(this);
		item.ondragover = (e) => e.preventDefault();
	},

	handleDrop(e) {
		const $item = $(e.currentTarget);
		$item.removeClass('drag-sort-active');

		this.updateOffersOrder();
	},

	// EVENTS
	removeOffer(e) {
		if (e != null) {
			e.preventDefault();
		}

		const $target = $(e.currentTarget);
		const $container = $target.closest('.b-offers-avia-modal__list-item');
		const $commentRow = $container.siblings('.b-offers-avia-modal__list-item-comment').first();
		const flightToken = $container.attr('data-flight-token');

		if (this.options.parent != null && _.isFunction(this.options.parent.addOffer)) {
			this.options.parent.addOffer(e, { flightToken }, true);
		}

		$container.remove();
		$commentRow.remove();
		// Change button on the card
		if (flightToken) {
			$(`.btn[data-flight-token="${_.escape(flightToken)}"]`).first().trigger('click');
		}

		this.checkOffersCount();
	},

	submitOrder(e) {
		this.orderOffers(e, _.extend({ sendEmail: true }));
	},

	orderOffers(e, opts) {
		if (e != null) {
			e.preventDefault();
		}

		const $target = $(e.currentTarget);
		if ($target.hasClass('disabled')) {
			return this;
		}

		const flightTokens = _.map(this.offers, (o) => o.flightToken);

		STATE.showLoader();
		STATE.getPageView().getPageModel().set('nearestDatesMatrix', false);
		STATE.getPageView().getPageModel().set('currentDateMatrix', false);
		$('.b-matrix-controls__control').removeClass('active');

		this.disableElements(e);

		const flightOffers = flightTokens.map((flightToken, i) => {
			return {flightToken, comments: this.model.get(`comment${i}`)};
		});
		const emails = this.model.get('emails').toJSON();
		
		axios.post('/midoffice/ibecorp-b2b/avia/sendToApprove', {
			parameters: _.extend({
				flightOffers,
				emails,
				sendEmail: false,
			}, opts),
		}, this.model).then((result) => {
			STATE.hideLoader();
			if (!_.isEmpty(result.data.result.messages)) {
				const messages = result.data.result.messages;
				const popup = new Widgets.Popup({
					type: 'info',
					content: messages.length && _.map(messages, (m) => m.text).join('<br/>'),
					actions: [
						{
							label: L10N.get('Common.close'),
							action: () => {
								STATE.navigate([STATE.ROUTES.CABINET_ORDER, result.data.result.newBooking.uid].join('/'));
								popup.hide();
							},
						},
					],
				});
				popup.show();
			} else {
				STATE.navigate([STATE.ROUTES.CABINET_ORDER, result.data.result.newBooking.uid].join('/'));
			}
			this.ticketModalOffers.hide();
		}).catch(() => {
			STATE.hideLoader();
			Widgets.Popup.showUnknownErrorPopup();
		});
		
		return this;
	},

	copyToClipboard(e) {
		if (e) e.preventDefault();
		let result = '';
		const containers = this.$el.find('[data-copy-container]');
		_.each(containers, (el) => {
			result += `${GlUl.gatherTextToCopyToClipBoard(el)}\n\n`;
		});
		logger.debug(result);
		GlUl.copyToClipboard(result);
	},

});
