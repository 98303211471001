// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import template from './template.ejs';
import BaseView from '@/classes/base.view';
import TransferView from './b-transfers-transfer/index';
import TransferModel from './b-transfers-transfer/model';
import $ from 'jquery';

export default BaseView.extend({

	template,

	ui: {
		transferResultsContainer: '.b-transfers__results',
		exceededCapacityContainer: '.b-transfers__exceeded-capacity',
	},

	views: [],
	exceededCapacityViews: [],
	models: [],
	applyFilters: false,

	initialize(options) {
		this.options = options;
		this.parent = this.options.parent;
		this.results = this.options.results || {};
		this.render();

		_.defer(() => {
			this.resetResults();
			this.initializeOffers();
		});
	},

	initializeOffers() {
		const searchModel = STATE.getSearchModel();
		const {issueAllowed, transfers, hasTravelPolicy} = this.results;
		const transferType = searchModel.get('transferType');
		const travellersCount = this.results.travellersCount || _.reduce(searchModel.get('passengersTypes').attributes, (prev, curr) => {
			return prev + curr;
		}, 0);

		if (!_.isEmpty(transfers)) {
			_.each(transfers, (transfer) => {
				const model = new TransferModel({transfer, transferType, travellersCount, searchModel, hasTravelPolicy});
				const view = new TransferView({parent: this, issueAllowed, pageView: this.parent, model, transfer, transferType, travellersCount, searchModel});

				this.views.push(view);
				this.models.push(model);
			});

			if (_.some(this.models, m => m.get('isExceededCapacity'))) this.ui.exceededCapacityContainer.removeClass('dn');
			this.parent.renderFilters();
			this.parent.applyFilters();

			this.parent.sortingView.sort();
		} else {
			this.noResults();
		}
	},

	renderResults(visibleViews) {
		visibleViews = _.isEmpty(visibleViews) ? this.views : visibleViews;
		this.clearContainers();
		_.each(visibleViews, (v) => {
			if (v.model.get('isExceededCapacity')) {
				this.ui.exceededCapacityContainer.append(v.render().$el);
			} else {
				this.ui.transferResultsContainer.append(v.render().$el);
			}
			v.addEventListeners();
		});
	},

	noResults() {
		const noTicketsPopup = new Widgets.Popup({
			content: L10N.get('hotels.noOffers'),
			type: 'danger',
			actions: [{
				label: L10N.get('errors.noTicketsButton'),
				action: () => {
					noTicketsPopup.hide();
				},
			}],
			onClose: () => {
				noTicketsPopup.hide();
				STATE.navigate(STATE.ROUTES.INDEX);
			},
		});
		noTicketsPopup.show();
	},

	render(args) {
		BaseView.prototype.render.apply(this, args);
		this.ui.transferResultsContainer.empty();
	},

	clearContainers() {
		this.ui.transferResultsContainer.empty();
		this.ui.exceededCapacityContainer.empty();
	},

	resetResults() {
		$('.b-tickets-container-hotels').removeClass('search-is-complete');
		this.ui.transferResultsContainer.html('');

		this.views = [];
		this.models = [];

		this.parent.results = [];
		this.parent.renderFilters();
	},
});
