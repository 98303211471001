// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import axios from 'axios';
import BaseView from '@/classes/base.view';
import BaseModel from '@/classes/base.model';
import BaseCollection from '@/classes/base.collection';
import VisaInfoView from '@/blocks/elements/b-visa-info';
import template from './template.ejs';

export default BaseView.extend({

	template,

	ui: {
		visaInfo: '.b-booking__visa-info',
	},

	events: {
		'click .b-cabinet-docodoca__submit': 'send',
	},

	initialize(options) {
		this.model = new BaseModel();
		this.data = options.data.reservation;
		const seatsList = [];
		const passengersCollection = new BaseCollection();

		if (_.isEmpty(this.data.passengers)) {
			return;
		}

		this.data.passengers.forEach((passenger) => {
			passengersCollection.add(new BaseModel({
				fromEmail: true,
				passenger,
				travellerUid: passenger.uid,
				_docoInfo: passenger.docoInfos[0],
				_docaInfo: passenger.docaInfos[0],
			}));
		});

		this.model.set('booking', options.bookingUid);
		this.model.set('docoDocaInfos', passengersCollection);

		this.filled = _.every(this.data.passengers, (el) => {
			return	!_.isEmpty(this.data.docoDocaNeededInfo) && this.data.docoDocaNeededInfo.isDocoNeeded && !_.isEmpty(el.docoInfos[0]) &&
					this.data.docoDocaNeededInfo.isDocaNeeded && !_.isEmpty(el.docaInfos[0]);
		});

		const visaInfoView = new VisaInfoView(Object.assign({
			model: this.model,
			seats: seatsList,
			passengersModel: passengersCollection,
			fromEmail: true,
		}, this.data.docoDocaNeededInfo));

		this.render(options);
		this.ui.visaInfo.append(visaInfoView.$el);
	},

	adjustModelToJSON(json) {
		delete json.noVisaInfo;

		json.docoDocaInfos = _.map(json.docoDocaInfos, (info) => {
			info = _.omit(info, 'passenger', 'fromEmail', '_docaInfo', '_docoInfo');
			if (info.docaInfo != null) {
				delete info.docaInfo.filled;
			}

			if (info.docoInfo != null) {
				delete info.docoInfo.filled;

				if (info.docoInfo.visa.toString() === 'false') {
					info.docoInfo = _.pick(info.docoInfo, 'visa');
				}
			}

			return info;
		});

		return json;
	},

	send(e) {
		STATE.showLoader();
		const bookingUid = this.model.get('booking');
		const params = _.extend({
			booking: {
				uid: bookingUid,
				caption: '',
			},
			reservation: this.data.uid,
		}, this.adjustModelToJSON(this.model.toJSON()));

		this.disableElements(e);
		axios.post('/midoffice/ibecorp-b2b/booking/sendDocoDocaInfo', {
			parameters: params,
		}, this.model).then((result) => {
			STATE.hideLoader();
			if (result.data.result.success) {
				STATE.router.reloadPage();
			}
		});
	},

});
