module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-rooms-collection">\n	<div class="b-rooms-collection__content clearfix">\n		<span class="b-rooms-collection__add">\n			' +
((__t = ( L10N.get('searchForm.addRoom') )) == null ? '' : __t) +
' <span class="b-rooms-collection__add-icon"></span>\n		</span>\n	</div>\n</div>\n';

}
return __p
}