// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

export default BaseView.extend({

	template,

	events: {
		'click .b-input__remove': 'removeItem',
	},

	initialize() {
		this.render();
	},

	removeItem() {
		this.model.destroy();
		this.destroy();
	},

	/* eslint-disable */
	render() {
		this.setElement(this.template(
			_.extend({}, this.options, this.model.attributes, {
				// "this" - is widget context, underscore & ES6 not supported ejs-loader
				employeeFormatter: function employeeFormatter(result) {
					if (!Array.isArray(result)) {
						return result;
					}

					return result;
				},
			})
		));
	},

});
