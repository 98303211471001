// eslint-disable-next-line banned-modules
'use strict';

class Deferred {
	constructor() {
		const self = this;
		this.promise = new Promise((resolve, reject) => {
			self.reject = reject;
			self.resolve = resolve;
		});
	}
}

export default Deferred;
