// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';

export default BaseModel.extend({

	defaults() {
		return {
			page: 0,
			pageSize: 10,
			count: 0,
		};
	},

	toJSON() {
		const model = BaseModel.prototype.toJSON.call(this);
		delete model.count;
		return {
			parameters: model,
		};
	},

});
