module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


  var stepStatus = (stepData && stepData.status && stepData.status.uid) || null;
  var stepStatusClsName = view.getStatusClassNameSuffix(stepStatus);

  var initialOpenedStepIndex = view.model.get("initialOpenedStepIndex");
  var isInitialyOpened = initialOpenedStepIndex != null && index === initialOpenedStepIndex;
;
__p += '\n<div\n  class=\'js-step b-cabinet-order-auth-step b-cabinet-order-auth-step--' +
((__t = ( stepStatusClsName )) == null ? '' : __t) +
' ' +
((__t = ( isInitialyOpened ? "" : "b-cabinet-order-auth-step--collapsed" )) == null ? '' : __t) +
'\'\n>\n  ';

    var headerOptions = {
      label: label,
      index: index,
      isCollapsible: isCollapsible,
      view: view,
      stepData: stepData
    }
  ;
__p += '\n  ' +
((__t = ( require('./template-step-header.ejs')(headerOptions) )) == null ? '' : __t) +
'\n  ';
 if (isCollapsible) { ;
__p += '\n    <div class="b-cabinet-order-auth-step__content">\n      <ul class="b-cabinet-order-auth-step__units-list">\n        ';
 _.each(stepData.units || [], function (unit) { ;
__p += '\n          ';

            var unitOptions = {
              view: view,
              unit: unit,
              stepStatus: stepStatus,
            }
          ;
__p += '\n          <li class="b-cabinet-order-auth-step__units-list-item">\n            ' +
((__t = ( require('./b-step-unit/template.ejs')(unitOptions) )) == null ? '' : __t) +
'\n          </li>\n        ';
 }) ;
__p += '\n      </ul>\n    </div>\n  ';
 } ;
__p += '\n</div>';

}
return __p
}