module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-sorting">\r\n	<label class="b-sorting__label" for="">' +
((__t = ( L10N.get('sorting.label') )) == null ? '' : __t) +
'</label>\r\n	<span class="b-sorting__selected"></span>\r\n	<div class="b-sorting__drop-down">\r\n		';
 _.each(variants, function (variant) { ;
__p += '\r\n				<span  class="b-sorting__drop-down-item" type="' +
((__t = ( variant.value )) == null ? '' : __t) +
'">' +
((__t = ( variant.label )) == null ? '' : __t) +
'</span>\r\n		';
 }) ;
__p += '\r\n	</div>\r\n</div>\r\n';

}
return __p
}