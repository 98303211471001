module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {



var routeNames = [];

_.each([this.reservation.legs, this.selectedFlight.legs], function(legs) {
	var first = _.first(legs[0].segments);
	var last = _.last(legs[legs.length - 1].segments);
	var name = '';

    if (first.departureCity && first.departureCity.caption) {
        name += first.departureCity.caption;
    }
    name += ' - ';
    if (last.arrivalCity && last.arrivalCity.caption) {
        name += last.arrivalCity.caption;
    }
    name += ' | ' + new Time(first.departureDate).format('DD.MM.YY');

	routeNames.push(name);
});
;
__p += '\n\n<div class="b-exchange">\n    <div class="b-exchange__modal">\n        <div class="b-exchange__header">' +
((__t = ( L10N.get('avia.exchangeConfirmation') )) == null ? '' : __t) +
'</div>\n        <div class="b-exchange__content">\n            ';
 _.each(this.reservation.services, function (service) { ;
__p += '\n                <div class="b-exchange__traveller-service">\n                    <span class="b-exchange__traveller-service__ticketNumber">' +
((__t = ( service.ticketNumber )) == null ? '' : __t) +
'</span>\n                    <span class="b-exchange__traveller-service__fullName">\n                        ' +
((__t = ( _.map(service.travellers, function(t) { return t.fullName }).join(' | ') )) == null ? '' : __t) +
'\n                    </span>\n                </div>\n            ';
 }) ;
__p += '\n\n            ';
 _.each([this.reservation.legs, this.selectedFlight.legs], function(legs, itemIndex) { ;
__p += '\n                        <div class="b-exchange__route-title ' +
((__t = ( itemIndex === 0 ? 'b-exchange__original-route-title' : 'b-exchange__new-route-title')) == null ? '' : __t) +
'">\n                            ' +
((__t = ( itemIndex === 0 ? L10N.get('avia.originalRoute') : L10N.get('avia.newRoute') )) == null ? '' : __t) +
'\n                        </div>\n                        <div class="' +
((__t = ( itemIndex === 0 ? 'b-exchange__original-route' : 'b-exchange__new-route' )) == null ? '' : __t) +
' b-exchange__flight">\n                            <div class="b-exchange__flight-header">' +
((__t = ( routeNames[0] )) == null ? '' : __t) +
'</div>\n                            <div class="b-exchange__flight-legs">\n                                ' +
((__t = ( require('../../pages/b-cabinet/b-cabinet-orders/b-cabinet-order/b-cabinet-order-refund-exchange/refund-exchange-flight-legs.ejs')({legs: legs}))) == null ? '' : __t) +
'\n                            </div>\n                        </div>\n                ';
 }) ;
__p += '\n            <div class="b-exchange__footer">\n                ';
 var price = this.selectedFlight.price.passengerPrices[0]; ;
__p += '\n                <div class="b-exchange__exchange-warning">\n                    ' +
((__t = ( L10N.get('avia.exchangeRules') )) == null ? '' : __t) +
'\n                </div>\n                <div class="b-exchange__price-details">\n                    ';
 _.each(['feeAndTaxes', 'tariff'], function(key) { ;
__p += '\n                    ';
 var value = price[key]; ;
__p += '\n                        ';
 if (value) { ;
__p += '\n                            <div class="b-exchange__price-details-row">\n                                <div class="b-exchange__price-details-title">' +
((__t = ( L10N.get('avia.' + key + 'Exchange') )) == null ? '' : __t) +
'</div>\n                                <div class="b-exchange__price-details-price">' +
((__t = ( Formatter.formatMoney((parseFloat(value.amount, 10) * price.passengerCount), Formatter.getDecimal(value.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(value.currencyCode) )) == null ? '' : __t) +
'</div>\n                            </div>\n                        ';
 } ;
__p += '\n                    ';
 }) ;
__p += '\n                </div>\n                <div class="b-exchange__price-details-row b-exchange__total-price">\n                    <div class="b-exchange__price-details-title">' +
((__t = ( L10N.get('avia.exchangeTotal') )) == null ? '' : __t) +
'</div>\n                    <div class="b-exchange__price-details-price">' +
((__t = ( Formatter.formatMoney(price.total.amount, Formatter.getDecimal(price.total.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(price.total.currencyCode) )) == null ? '' : __t) +
'</div>\n                </div>\n            </div>\n        </div>\n        <div class="b-exchange__modal-buttons">\n            <button class="b-exchange__cancel js-cancel">' +
((__t = ( L10N.get('cabinet.orders.cancel') )) == null ? '' : __t) +
'</button>\n            <button class="b-exchange__confirm js-exchange">' +
((__t = ( L10N.get('avia.confirmExchange') )) == null ? '' : __t) +
'</button>\n        </div>\n    </div>\n</div>\n';

}
return __p
}