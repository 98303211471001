module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="l-block__back">\r\n	<section class="b-filters l-block__over">\r\n		<div class="b-filters__sorting"></div>\r\n		<div class="b-filters__trigger"></div>\r\n		<div class="b-filters__mobile-buttons">\r\n			<span class="b-filters__mobile-reset">' +
((__t = (L10N.get("filters.reset"))) == null ? '' : __t) +
'</span>\r\n			<span class="b-filters__mobile-apply">' +
((__t = (L10N.get("filters.apply"))) == null ? '' : __t) +
'</span>\r\n		</div>\r\n		<div class="b-filters__line b-filters__travel_policy"></div>\r\n		<div class="b-filters__line b-filters__toggles"></div>\r\n		<div class="b-filters__line b-filters__input"></div>\r\n		<div class="b-filters__line  b-filters__block b-filters__common"></div>\r\n		<div class="b-filters__line b-filters__block b-filters__range"></div>\r\n\r\n		<div class="b-filters__line b-filters__block b-filters__train_leg b-filters__leg-first">\r\n			<h4 class="b-filters__line-title">' +
((__t = (L10N.get("filters.there"))) == null ? '' : __t) +
'</h4>\r\n		</div>\r\n\r\n		<div class="b-filters__line b-filters__block b-filters__train_leg b-filters__leg-second">\r\n			<h4 class="b-filters__line-title">' +
((__t = (L10N.get("filters.back"))) == null ? '' : __t) +
'<</h4>\r\n		</div>\r\n\r\n		<div class="b-filters__line b-filters__block b-filters__leg b-filters__leg-first">\r\n			<h4 class="b-filters__line-title">' +
((__t = (L10N.get("filters.firstLeg"))) == null ? '' : __t) +
'</h4>\r\n		</div>\r\n\r\n		<div class="b-filters__line b-filters__block b-filters__leg b-filters__leg-second">\r\n			<h4 class="b-filters__line-title">' +
((__t = (L10N.get("filters.secondLeg"))) == null ? '' : __t) +
'</h4>\r\n		</div>\r\n\r\n		<div class="b-filters__select"></div>\r\n\r\n	</section>\r\n	<div class="b-filters__drop-filters">\r\n		<span class="b-filters__drop-filters-button">' +
((__t = (L10N.get("filters.resetFilters"))) == null ? '' : __t) +
'</span>\r\n	</div>\r\n</div>\r\n';

}
return __p
}