/* eslint-disable banned-modules */
'use strict';

import BaseView from '@/classes/base.view';
import Header from '@/blocks/layout/b-header/index';
import Footer from '@/blocks/layout/b-footer/index';
import Loader from '@/blocks/layout/b-loader/index';
import Login from '@/blocks/pages/b-login/view';
import Authorization from '@/blocks/pages/b-authorization/index';
import template from './template.ejs';
import './style.less';

const Layout = () => {
	const view = BaseView.extend({

		template,

		el: 'body',

		initialize() {
			if (!this.checkAuthorization()) {
				this.render();
				this.drawLogin();
				new Loader();
			} else {
				new Loader();
			}
			STATE.layout = this;
		},

		drawLogin() {
			if (STATE.loginView == null) {
				STATE.loginView = new Login();
			}
		},

		renderHeader(options) {
			this.header = new Header({
				balanceInfo: options.balanceInfo,
			});
		},

		renderFooter() {
			if (!this.footer) {
				this.footer = new Footer();
			}
		},

		checkAuthorization() {
			if (window.location.href.includes('/authorization/page/')) {
				this.$el.append(new Authorization());
				return true;
			}
			return false;
		},

	});

	new view();
};

export default Layout;
