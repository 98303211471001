// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import suggestionTemplate from './suggestion.ejs';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';
import 'corejs-typeahead/dist/typeahead.jquery';

export default Widget.extend({

	template,

	validationTemplate,

	viewName: 'b-email-autocomplete',

	el: '.b-email-autocomplete',

	initialize(options) {
		this.options = options;
		this.options.label = this.options.label || '';
		this.options.placeholder = this.options.placeholder || '';
		this.options.deletableField = (this.options.deletableField === true);

		this.renderEmailAutocomplete();

		this.$typeahead = this.$('.b-email-autocomplete__value');
		this.$typeahead.typeahead({
			hint: false,
			highlight: false,
			minLength: 0,
			limit: 1,
			classNames: {
				menu: 'b-email-autocomplete__items',
				wrapper: 'b-email-autocomplete__wrapper',
				input: 'b-email-autocomplete__input',
				hint: 'b-email-autocomplete__hint',
				dataset: 'b-email-autocomplete__dataset',
				suggestion: 'b-email-autocomplete__item',
				selectable: 'b-email-autocomplete__selectable',
				empty: 'b-email-autocomplete__empty',
				open: 'b-email-autocomplete__open',
				cursor: 'b-email-autocomplete__cursor',
				highlight: 'b-email-autocomplete__highlight',
			},
		}, {
			name: 'emails',
			source: (q, cb) => {
				const listToView = [];
				_.each(options.autoSuggestEmails, (el) => {
					if (~el.email.indexOf(q) || ~el.fio.indexOf(q)) {
						listToView.push(el);
					}
				});
				cb(listToView, []);
			},
			display: (item) => `${item.email}`,
			async: true,
			templates: {
				suggestion: suggestionTemplate,
			},
		});
	},

	applyBinding(model) {
		this.$typeahead.on('typeahead:change', (ev, suggestion) => {
			const isNull = suggestion.replace(/\s/g, '').length === 0;
			this.model.set(this.options.bindingProperty, isNull ? null : _.isObject(suggestion) ? suggestion.email : suggestion);
		});

		this.$typeahead.on('typeahead:select', (ev, suggestion) => {
			this.model.set(this.options.bindingProperty, suggestion.email);
		});

		this.listenTo(model, `validationError:${this.options.bindingProperty}`, () => {
			this.$el.addClass('validation-error');
		});

		this.listenTo(model, `change:${this.options.bindingProperty}`, (__model, item) => {
			this.$typeahead.typeahead('val', item);
		});

		if (model.get(this.options.bindingProperty)) {
			this.$typeahead.typeahead('val', model.get(this.options.bindingProperty));
		}
	},

	renderEmailAutocomplete() {
		this.render();
		this.options.container.before(this.$el);
	},

});
