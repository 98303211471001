module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-cabinet-order-pdf-viewer">\n	<div class="b-block-container">\n		<div class="b-block-container__footer">\n			<div class="b-block-container__footer-leftbar">\n				<button type="button" class="btn js-cancel">' +
((__t = ( L10N.get('cabinet.orders.back') )) == null ? '' : __t) +
'</button>\n			</div>\n			<div class="b-block-container__footer-rightbar">\n				<button type="button" class="btn js-print btn-dark">' +
((__t = ( L10N.get('cabinet.orders.print') )) == null ? '' : __t) +
'</button>\n			</div>\n		</div>\n	</div>\n	<div class="b-cabinet-order-pdf-viewer__pdf-view"></div>\n</div>';

}
return __p
}