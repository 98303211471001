// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

import GlUl from '@/utils/global-utils';
import axios from 'axios';
import $ from 'jquery';

export default BaseView.extend({

	template,

	events: {
		'click .b-transfers-search__button': 'searchTransfers',
		'click .b-transfers-search__direction-swap': 'swapLocations',
		'click .b-transfers-search-mobile__filters-trigger': 'openFiltersMobile',
		'click .b-transfers-search__options-date-element': 'showPikadayModal',
		'click .b-transfers-search-form-mobile__background': 'toggleSearchFormMobile',
		'click .b-transfers-search-mobile__search-form': 'toggleSearchFormMobile',
	},

	ui: {
		mobileSearchFormBackground: '.b-transfers-search-form-mobile__background',
	},

	initialize() {
		this.model = STATE.getSearchModel();
		this.render({transferType: this.model.get('transferType')});

		this.listenTo(this.model, 'change:date change:backDate', (model) => {
			if (model.get('backDate') != null) {
				const dateBack = new Time(model.get('backDate'));
				const dateFrom = new Time(model.get('date'));

				if (dateBack.getMoment().isBefore(dateFrom.getMoment())) {
					model.set('backDate', null);
					this.$el.find('.backDate-from-datepicker .p-search__input').trigger('click');
				}
			}
		});
	},

	// EVENTS
	searchTransfers(e) {
		if (e != null) {
			e.preventDefault();
		}

		const model = this.model.toJSON();
		const {parent} = this.options;

		const showMessagePopup = (result) => {
			if (result != null && result.data != null && result.data.messages != null && result.data.messages.length > 0) {
				const messages = result.data.messages.reduce((prev, curr) => {
					let resultMessages = prev;
					resultMessages += `${curr.text}<br/>`;
					return resultMessages;
				}, '');

				const isWarning = _.some(result.data.messages, (m) => m.severity === 'WARNING');
				const popup = new Widgets.Popup({
					content: isWarning ? [L10N.get('transfers.noOffers'), messages].join('<br/><br/>') : messages,
					closeOnlyOnAction: false,
					type: isWarning ? 'danger' : 'info',
					actions: [{
						label: isWarning ? L10N.get('errors.noTicketsButton') : L10N.get('searchForm.close'),
						action: () => {
							popup.hide();

							if (isWarning) {
								STATE.navigate(STATE.ROUTES.INDEX);
							}
						},
					}],
					classes: 'b-reload-popup',
				});
				popup.show();
			}
		};

		STATE.showLoader();
		this.disableElements(e);
		if (GlUl.geocoderLocationUpdateNeeded(this.model)) return GlUl.updateGeocoderLocations(this.searchTransfers.bind(this), this.model);

		return axios.post('/midoffice/ibecorp-b2b/transfers/search', model, this.model).then((result) => {
			showMessagePopup(result);

			STATE.setTransfersSearchResult(result.data.result);
			STORE.set(STATE.ROUTES.MAIN_SEARCH, STATE.getSearchModel().toJSON(), 14400);
			STATE.setFormState('transfers:filter', null);

			parent.transfersView.results = result.data.result;
			parent.transfersView.resetResults();

			parent.transfersView.initializeOffers();
			STATE.hideLoader();
		});
	},

	swapLocations() {
		this.model.swapLocations();
	},

	openFiltersMobile() {
		$('.l-layout').addClass('show-filters');
	},

	createPikadayModalView() {
		this.pikadayModal = GlUl.createPikadayModalView(this.model);
	},

	clearPikadayModalView() {
		this.pikadayModal.destroy();
		this.pikadayModal = null;
	},

	destroy(...args) {
		this.clearPikadayModalView();
		BaseView.prototype.destroy.apply(this, args);
	},

	showPikadayModal(e) {
		if (this.pikadayModal == null) this.createPikadayModalView();
		GlUl.showPikadayModal(e, this.pikadayModal);
	},

	toggleSearchFormMobile() {
		const lLayout = $('.l-layout');
		const shouldOpen = !lLayout.hasClass('show-search-form');
		this.ui.mobileSearchFormBackground[shouldOpen ? 'show' : 'hide']();
		lLayout.toggleClass('show-search-form', shouldOpen);
	},
});
