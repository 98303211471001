import "./style.less";
import React, { ReactNode } from "react";

interface ButtonProps {
  type?: "submit" | "button" | "reset";
	children: ReactNode;
	onClick?: () => Promise<void> | void;
	style?: React.CSSProperties | undefined;
}

const Button: React.FC<ButtonProps> = (props) => {
	const { children, style = {}, onClick, type = "button" } = props;

	return (
		<button type={type} style={style} onClick={onClick} className="r-button">
			{children}
		</button>
	);
};

export default Button;
