// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';
import View from './index';

export default BaseModel.extend({

	View,

	toJSON(...args) {
		const result = BaseModel.prototype.toJSON.apply(this, args);
		let railwayCarrier;

		if (_.isEmpty(result)) {
			return null;
		}

		const params = {
			uid: result.uid,
			number: result.number,
			carrierCode: result.carrierCode,
		};
		if (result.carrierCode && _.isObject(result.carrierCode)) {
			railwayCarrier = result.carrierCode.railwayCarrier;
			params.carrierCode = {
				uid: railwayCarrier.uid,
				caption: railwayCarrier.caption,
			};
			params.carrierCode = railwayCarrier.uid;
		}

		return params;
	},

});
