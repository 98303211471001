// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import $ from 'jquery';
import BaseView from '@/classes/base.view';
import template from './template.ejs';
import templateItems from './template_items.ejs';

export default BaseView.extend({

	template,

	routeType: null,

	routes: {
		AVIA: [
			'INDEX',
			'AVIA_TICKETS',
			'AVIA_PASSENGERS',
			'AVIA_PAYMENT',
		],
		RAILWAYS_ONEWAY: [
			'INDEX',
			'RAILWAYS_TICKETS',
			'RAILWAYS_PASSENGERS',
			'RAILWAYS_PAYMENT',
		],
		RAILWAYS_ROUNDTRIP: [
			'INDEX',
			'RAILWAYS_TICKETS_1',
			'RAILWAYS_TICKETS_2',
			'RAILWAYS_PASSENGERS',
			'RAILWAYS_PAYMENT',
		],
		HOTELS: [
			'INDEX',
			'HOTELS_OFFERS',
			'HOTELS_PASSENGERS',
			'HOTELS_PAYMENT',
		],
		TRANSFERS: [
			'INDEX',
			'TRANSFERS_OFFERS',
			'TRANSFERS_PASSENGERS',
			'TRANSFERS_PAYMENT',
		],
	},

	ui: {
		items: '.b-breadcrumbs__item',
	},

	events: {
		'click .b-breadcrumbs__item-active': 'changePage',
	},

	el: '.b-header__blocks',

	changePage(e) {
		STATE.navigate(STATE.ROUTES[this.$(e.currentTarget).attr('data-route')]);
	},

	preinitialize(options) {
		BaseView.prototype.preinitialize.call(this, options);
		this.windowEventListenerList.push({
			name: 'scroll',
			callback: this.checkFixedPosition,
		});
	},

	initialize(options) {
		options.router.on('route', () => {
			this.setActive(this.options.router.current());
		});
		this.render();
		this.setRouteType(options.routeType || 'AVIA');
		this.renderItems();
		this.setDefaultActive();
	},

	checkFixedPosition() {
		const $headerBlocks = $('.b-header__blocks');
		const $header = $('.b-header');
		if ($headerBlocks.length === 0) {
			return;
		}

		const headerBlocksHeight = $headerBlocks.innerHeight() + $headerBlocks.get(0).offsetTop;
		const scrollOffset = window.pageYOffset;

		if (scrollOffset > headerBlocksHeight) {
			$headerBlocks.addClass('fixed');
			$header.css('margin-bottom', headerBlocksHeight);
		} else {
			$header.css('margin-bottom', 'unset');
			$headerBlocks.removeClass('fixed');
		}
	},

	renderItems() {
		this.$el.find('.js-items-container').empty().append(templateItems({
			routes: this.routes[this.routeType],
			routeType : this.routeType,
		}));
	},

	setDefaultActive() {
		this.setActive(this.options.router.current() || '/');
	},

	setActive(route) {
		route = route.split('?')[0];
		if (route.match(/[!|]/g)) {
			route = route.substring(0, route.lastIndexOf('/'));
		}

		let routeName = this.routes[this.routeType].reduce((result, item) => {
			result = route === STATE.ROUTES[item] ? item : result;
			return result;
		}, '');

		if (route === 'avia/brands/tickets') {
			routeName = 'AVIA_TICKETS';
		}

		if (routeName) {
			STORE.set('BREADCRUMBS', routeName, 60 * 1);
		} else {
			routeName = STORE.get('BREADCRUMBS');
		}

		const $itemElements = this.$el.find('.b-breadcrumbs__item');

		$itemElements.removeClass('b-breadcrumbs__item-active').removeClass('last');
		$itemElements.filter(`:lt(${this.routes[this.routeType].indexOf(routeName) + 1})`)
			.addClass('b-breadcrumbs__item-active');
		$itemElements.filter('.b-breadcrumbs__item-active:last').addClass('last');
		if (route.startsWith('/')) {
			route = route.substr(1);
		}
	},

	setRouteType(routeType) {
		this.routeType = routeType;
		this.renderItems();
		this.setDefaultActive();
	},

});
