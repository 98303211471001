module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<section class="b-customer b-payment-types__customer">\n	<header class="b-customer__header">' +
((__t = ( L10N.get('payment.preferredPaymentForm') )) == null ? '' : __t) +
'</header>\n	<div class="l-grid-container b-payment-types__container">\n		<div class="l-grid-layout-33">\n			<label class="b-input">\n				<span class="js-widget"  onclick=\'return new Widgets.Select({ bindingProperty: "paymentType", values: "' + paymentTypes + '", label: L10N.get("payment.paymentForm") })\'></span>\n			</label>\n		</div>\n	</div>\n</section>\n';

}
return __p
}