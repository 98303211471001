module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


  var additionalText = null;
  if (stepData !== null && stepData.sendDate) {
    additionalText = L10N.get("cabinet.orders.authRequestSendDate") + ": " + new Time(stepData.sendDate).format('DD.MM.YYYY HH:mm');
  }
  var showTail = stepData !== null;
;
__p += '\n<div\n  class=\'b-cabinet-order-auth-step__header ' +
((__t = ( isCollapsible ? "js-toggle-step b-cabinet-order-auth-step__header--collapsible" : "" )) == null ? '' : __t) +
'\'\n  ';
 if (isCollapsible) { ;
__p += 'data-step-index="' +
((__t = ( index || null )) == null ? '' : __t) +
'" role="button"';
 } ;
__p += '\n>\n  <div class="b-cabinet-order-auth-step__header-step-line-container">\n    <div class="b-cabinet-order-auth-step__header-status-icon"></div>\n    ';
 if (showTail) { ;
__p += '\n      <div class="b-cabinet-order-auth-step__header-step-line-tail"></div>\n    ';
 } ;
__p += '\n  </div>\n  <div class="b-cabinet-order-auth-step__header-content">\n    <span class="b-cabinet-order-auth-step__header-title">' +
((__t = ( label )) == null ? '' : __t) +
'</span>\n    ';
 if (additionalText !== null || isCollapsible) { ;
__p += '\n      <span class="b-cabinet-order-auth-step__header-right-col">\n        ';
 if (additionalText !== null) { ;
__p += '\n          <span class="b-cabinet-order-auth-step__header-additional-text">' +
((__t = ( additionalText )) == null ? '' : __t) +
'</span>\n        ';
 } ;
__p += '\n        ';
 if (isCollapsible) { ;
__p += '\n          <span class="b-cabinet-order-auth-step__header-toggler"></span>\n        ';
 } ;
__p += '\n      </span>\n    ';
 } ;
__p += '\n  </div>\n</div>';

}
return __p
}