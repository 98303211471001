import axios from "axios";
import { ReactUtils } from "../../../../js/utils/react-utils";
import { SearchFormParams } from "../NewsPage";
import { NewsSearchResponse } from "./interfaces";
import { newsItemsParser, searchFormParamsConverter } from "./utils";
import { PaginationInfo } from "../../../components/form/Pagination/Pagination";

export const fetchNewsItems = async (
	searchFormParams: SearchFormParams,
	paginationInfo: PaginationInfo
) => {
	try {
		const { data = {} } = await axios.post(
			"/midoffice/ibecorp-b2b/notices/all",
			ReactUtils.modelToParameters(
				searchFormParamsConverter(searchFormParams, paginationInfo)
			)
		);
		const searchResponse: NewsSearchResponse = data.result;
		return {
			totalCount: searchResponse.totalCount || 0,
			notices: newsItemsParser(searchResponse.notices || []),
		} as NewsSearchResponse;
	} catch (err) {
		logger.error(err);
		return { totalCount: 0, notices: [] } as NewsSearchResponse;
	}
};
