// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';
import TransliterationPopup from '@/widgets/b-transliteration-popup/index';

export default Widget.extend({

	template,

	validationTemplate,

	events: {
		'change .b-middle-name__value': 'change',
		'click .b-middle-name__without': 'withoutMiddleName',
		'click .b-middle-name__without-lat': 'withoutMiddleNameLat',
	},

	ui: {
		input: '.b-middle-name__value',
		withoutMiddleName: '.b-middle-name__without',
		withoutMiddleNameLat: '.b-middle-name__without-lat',
	},

	initialize() {
		this.options.withoutMiddleName = this.options.withoutMiddleName || false;
		this.options.withoutMiddleNameLat = this.options.withoutMiddleNameLat || false;
		this.options.isPassportModel = this.options.isPassportModel || false;
		this.options.forbidEdit = [true, 'true'].includes(this.options.forbidEdit);
		this.options.disabled = [true, 'true'].includes(this.options.disabled);
		this.options.transliterationOn = [true, 'true'].includes(this.options.transliterationOn);
		this.options.runTransliterationOnFirstRender = [true, 'true'].includes(this.options.runTransliterationOnFirstRender);
		this.transliterationPopup = null;

		this.render();

		if (_.isObject(this.options.attrs)) {
			this.applyAttrs(this.options.attrs);
		}
	},

	applyAttrs(attrs) {
		_.each(attrs, (value, key) => {
			if (['true', 'false'].includes(value)) value = value === 'true';
			this.ui.input.attr(key, value);
			if (key === 'pattern') {
				this.ui.input.attr('oninput', `this.value = this.value.replace(${new RegExp(value, 'g')}, '')`);
			}
		});
	},

	change() {
		this.model.set(this.options.bindingProperty, this.ui.input.val());
	},

	withoutMiddleName() {
		const WITHOUT_MIDDLE_NAME = !this.model.get('withoutMiddleName');
		const DOCUMENTS_COLLECTION = !this.options.isPassportModel ? this.model.get('passports') : this.model.collection;

		if (this.options.isPassportModel && this.model) {
			this.model.set('withoutMiddleName', WITHOUT_MIDDLE_NAME);
		}

		this.model.set('withoutMiddleName', WITHOUT_MIDDLE_NAME);
		this.ui.withoutMiddleName.html(
			WITHOUT_MIDDLE_NAME ? L10N.get('bookingForm.withMiddleName') : L10N.get('bookingForm.withoutMiddleName'),
		);
		if (this.options.forbidEdit) {
			this.ui.input.attr('disabled', true);
			if (WITHOUT_MIDDLE_NAME) {
				this.savedPassportMiddleName = this.model.get('passport') != null && this.model.get('passport.middleName');
				this.model.set('passport.middleName', null);
			} else {
				this.model.set('passport.middleName', this.savedPassportMiddleName);
			}
			return;
		}
		this.ui.input.attr('disabled', WITHOUT_MIDDLE_NAME);

		this.hideValidation();
		if (DOCUMENTS_COLLECTION != null && DOCUMENTS_COLLECTION.length !== 0) {
			// DOCUMENTS_COLLECTION.each((model) => {
			// 	model.set('withoutMiddleName', WITHOUT_MIDDLE_NAME);
			// });
		}
	},

	withoutMiddleNameLat() {
		const WITHOUT_MIDDLE_NAME = !this.model.get('withoutMiddleNameLat');

		if (this.options.isPassportModel && this.model) {
			this.model.set('withoutMiddleNameLat', WITHOUT_MIDDLE_NAME);
		}

		this.model.set('withoutMiddleNameLat', WITHOUT_MIDDLE_NAME);
		this.ui.withoutMiddleNameLat.html(
			WITHOUT_MIDDLE_NAME ? L10N.get('bookingForm.withMiddleName') : L10N.get('bookingForm.withoutMiddleName'),
		);
		if (this.options.forbidEdit) {
			this.ui.input.attr('disabled', true);
			if (WITHOUT_MIDDLE_NAME) {
				this.savedPassportMiddleName = this.model.get('passport') != null && this.model.get('passport.middleNameLat');
				this.model.set('passport.middleNameLat', null);
			} else {
				this.model.set('passport.middleNameLat', this.savedPassportMiddleName);
			}
			return;
		}
		this.ui.input.attr('disabled', WITHOUT_MIDDLE_NAME);

		this.hideValidation();
	},

	initTransliteration(model) {
		if (this.transliterationPopup == null) {
			this.transliterationPopup = new TransliterationPopup({
				bindingProperty: this.options.bindingProperty,
				targetInput: this.ui.input,
				autoCorrectionAdapter: this.options.autoCorrectionAdapter,
			});
			this.transliterationPopup.applyBinding(model);
			if (this.options.runTransliterationOnFirstRender) this.transliterationPopup.transliterate();
		}
	},

	applyBinding(model) {
		this.model = model;
		const isWithoutMiddleName = this.options.withoutMiddleNameLat
			? model.get('withoutMiddleNameLat') === true
			: model.get('withoutMiddleName') === true;
		if (this.options.transliterationOn) this.initTransliteration(model);

		this.listenTo(model, `change:${this.options.bindingProperty}`, (thisModel, value) => {
			this.ui.input.val(value);
		});

		if (!isWithoutMiddleName) {
			this.ui.input.val(model.get(this.options.bindingProperty));
		}
		this.ui.input.attr('disabled', !!this.options.disabled || isWithoutMiddleName);
		const getLabel = () => {
			if (this.options.forbidEdit) {
				return isWithoutMiddleName ? L10N.get('bookingForm.withMiddleName') : L10N.get('bookingForm.withoutMiddleName');
			} else {
				return !!this.options.disabled || isWithoutMiddleName ? L10N.get('bookingForm.withMiddleName') : L10N.get('bookingForm.withoutMiddleName');
			}
		};

		if (this.options.withoutMiddleNameLat) {
			this.ui.withoutMiddleNameLat.html(getLabel());
		} else {
			this.ui.withoutMiddleName.html(getLabel());
		}
	},

	remove() {
		if (this.transliterationPopup?.remove) {
			this.transliterationPopup.remove();
		}
		Widget.prototype.remove.call(this);
	},

});
