// eslint-disable-next-line banned-modules
'use strict';

import './style.less';

import BaseView from '@/classes/base.view';
import BaseModel from '@/classes/base.model';
import Model from './model';
import template from './template.ejs';
import TravellerPrices from './template-traveller-prices.ejs';

import axios from 'axios';
import $ from 'jquery';
import ServiceView from './b-service/index';

export default BaseView.extend({

	template,

	el: 'body',

	events: {
		'click .js-changeLeg': 'handleVisibility',
		'click .js-changeSegment': 'handleVisibility',
		'click .js-changeTraveller': 'handleVisibility',
		'click .js-service-list-item': 'handleVisibility',
		'click .js-back': 'close',
		'click .js-save': 'ancillaryFeesAdd',
	},

	ui: {
		changeLegButtons: '.js-changeLeg',
		changeSegmentButtons: '.js-changeSegment',
		serviceList: '.js-service-list',
		serviceContentList: '.js-service-content',
		travellersPriceList: '.js-travellers-prices',

		totalPrice: '.js-total-price',
	},

	initialize(options) {
		this.options = options;
		this.travellers = options.travellers;
		this.reservation = options.reservation;
		this.ancillaryFees = options.ancillaryFees;
		this.parent = options.parent;
		this.model = new Model({
			ancillaryFees: this.ancillaryFees,
			reservationUid: this.reservation.uid,
			currentBooking: options.currentBooking,
		});

		this.travellerPrices = {};
		this.travellerServiceCount = {};
		this.travellersSelectedServices = {};

		this.selectedTraveller = this.travellers[0].uid;
		this.selectedLeg = 0;
		this.selectedSegment = 0;
		this.selectedServiceTypeUid = this.model.sortByType()[0].type.uid;

		this.selectedTravellersServices = this.initializeModels(this.ancillaryFees);
		this.model.set('selectedTravellersServices', this.selectedTravellersServices);

		this.render();
	},

	initializeModels(services = this.get('ancillaryFees')) {
		const result = {};

		_.each(services, (s) => {
			this.travellerPrices[s.travellerUid] = 0;
			this.travellerServiceCount[s.travellerUid] = 0;
			result[s.travellerUid] = [];
			_.each(s.ancillaryFees, (item, i) => {
				item._index = i;
				const model = new BaseModel(item);
				result[s.travellerUid].push(model);
				model.on(`change:selected`, (m, v) => {
					if (!v) {
						m.set('quantity', 0);
					} else {
						if (m.get('quantity') === 0) m.set('quantity', 1);
					}
				});
				model.on(`change:quantity`, () => {
					const leg = this.selectedLeg;
					const seg = this.selectedSegment;
					const $segmentButton = this.$(`.js-changeSegment[data-leg-index="${leg}"][data-segment-index="${seg}"]`);
					const selectedServices = _.filter(result[s.travellerUid], (m) => {
						return _.some(m.get('segments'), (el) => el.legIndex === leg && el.segmentIndex === seg)
							&& m.get('selected') === true;
					});

					const quantity = _.reduce(selectedServices, (prev, curr) => {
						return prev + curr.get('quantity');
					}, 0);
					if (_.isEmpty(selectedServices)) {
						$segmentButton
							.find('.js-segment-button-tooltip')
							.tooltip('dispose')
							.html('')
							.addClass('dn');
					} else {
						const tooltipContent = `<div class="segment-tooltip">
											${L10N.get('cabinet.orders.segmentServicesOrdered')}:
											<br/>
											<br/>
											${_.map(selectedServices, (m) => {
							return `<div>
															${m.get('description')} ${m.get('quantity')}${L10N.get('Common.pcs')}. ${m.get('price').amount}
														</div>`;
						}).join('<br/>')}
											</div>`;
						$segmentButton
							.find('.js-segment-button-tooltip')
							.tooltip({
								placement: 'bottom',
								html: true,
							})
							.attr('data-original-title', tooltipContent)
							.tooltip('update')
							.html(quantity)
							.removeClass('dn');
					}
					this.price.call(this);
				});
			});

			result[s.travellerUid] = this.sortModelsByType(result[s.travellerUid]);
		});

		return result;
	},

	sortModelsByType(services) {
		return _.sortBy(services, (m) => m.get('type'));
	},

	render(...args) {
		BaseView.prototype.render.apply(this, ...args);

		_.each(_.keys(this.selectedTravellersServices), (tUid) => {
			const container = this.$(`.js-service-content[data-traveller-uid="${tUid}"]`);
			_.each(this.selectedTravellersServices[tUid], (model) => {
				const view = new ServiceView({
					model,
					tUid,
					selectedTravellerUid: this.selectedTraveller,
					selectedTypeUid: this.selectedServiceTypeUid,
				});
				container.append(view.render().$el);
			});
		});
	},

	handleVisibility(e) {
		if (!e) return;
		const action = $(e.currentTarget).data('action');
		let $travellerPrices;
		let $serviceList;

		switch (action) {
			case 'CHANGE_TRAVELLER': {
				const travellerUid = $(e.currentTarget).data('travellerUid');
				$travellerPrices = this.$('.js-travellers-prices');
				$travellerPrices
					.find(`[data-traveller-uid="${this.selectedTraveller}"]`)
					.removeClass('active');

				this.selectedTraveller = travellerUid;

				$travellerPrices
					.find(`[data-traveller-uid="${travellerUid}"]`)
					.addClass('active');
				break;
			}
			case 'CHANGE_LEG': {
				const legIndex = $(e.currentTarget).data('legIndex');
				$(`.js-changeLeg[data-leg-index="${this.selectedLeg}"]`)
					.removeClass('active');
				$(`.js-changeSegment[data-leg-index="${this.selectedLeg}"]`)
					.addClass('dn');

				this.selectedLeg = parseInt(legIndex);
				const serviceListItem = $('.js-service-list')
					.find(`
					[data-service-type="${this.selectedServiceTypeUid}"],
					[data-leg-index="${this.selectedLeg}"],
					[data-segment-index="${this.selectedSegment}"]
					`);
				if (_.size(serviceListItem) === 0) {
					$('.js-service-list').find(`[data-leg-index="${this.selectedLeg}"], [data-segment-index="${this.selectedSegment}"]`)
						.first()
						.click();
				}

				$(`.js-changeLeg[data-leg-index="${legIndex}"]`)
					.addClass('active');
				$(`.js-changeSegment[data-leg-index="${legIndex}"]`)
					.removeClass('dn');
				break;
			}
			case 'CHANGE_SEGMENT': {
				const segmentIndex = $(e.currentTarget).data('segmentIndex');
				$(`.js-changeSegment[data-leg-index="${this.selectedLeg}"], [data-segment-index="${this.selectedSegment}"]`)
					.removeClass('active');

				this.selectedSegment = parseInt(segmentIndex);

				$(`.js-changeSegment[data-leg-index="${this.selectedLeg}"], [data-segment-index="${segmentIndex}"]`)
					.addClass('active');
				break;
			}
			case 'CHANGE_TYPE': {
				const serviceType = $(e.currentTarget).data('serviceType');
				$serviceList = $('.js-service-list');
				$serviceList
					.find(`[data-service-type="${this.selectedServiceTypeUid}"]`)
					.removeClass('active');

				this.selectedServiceTypeUid = serviceType;

				$serviceList
					.find(`[data-service-type="${this.selectedServiceTypeUid}"]`)
					.addClass('active');
				break;
			}
			default:
				return;
		}
		this.filterServices();
	},

	filterServices() {
		const t = this.selectedTraveller;
		const l = this.selectedLeg;
		const s = this.selectedSegment;
		const st = this.selectedServiceTypeUid;
		const $travellerViews = $('.js-traveller-views');
		this.$('.js-traveller-view').addClass('dn');

		$travellerViews.find(`.js-traveller-view[data-traveller-uid="${t}"]`).removeClass('dn');

		_.each(this.$('.js-service-content-item'), (el) => {
			const $el = this.$(el);
			const legsSegments = JSON.parse(decodeURIComponent($el.data('legsSegments')));
			const correctLegAndSegment = _.some(legsSegments, (item) => item.legIndex === l && item.segmentIndex === s);
			const correctTraveller = $el.data('travellerUid') === t;
			const correctType = $el.data('serviceType') === st;
			if (correctLegAndSegment && correctTraveller && correctType) {
				$el.removeClass('dn');
			} else {
				$el.addClass('dn');
			}
		});

		_.each(this.$('.js-service-list-item'), (el) => {
			const $el = this.$(el);
			const legIndex = $el.data('legIndex');
			const segmentIndex = $el.data('segmentIndex');
			const correctTraveller = $el.data('travellerUid') === t;
			if (legIndex === l && segmentIndex === s && correctTraveller) {
				$el.removeClass('dn');
			} else {
				$el.addClass('dn');
			}
		});
	},

	ancillaryFeesAdd() {
		STATE.showLoader();
		return axios.post('/midoffice/ibecorp-b2b/booking/ancillaryFees/add', this.model.toJSON())
			.then(() => {
				STATE.router.reloadPage();

				setTimeout(() => {
					this.parent.switchLabelTab(null, 'ANC_FEES', this.reservation._index);
					this.close();
				}, 1500);
			});
	},

	price() {
		axios.post('/midoffice/ibecorp-b2b/booking/ancillaryFees/price', this.model.toJSON())
			.then((res) => {
				if (res.data.result) {
					const ancillaryFees = res.data.result;
					const currencyCode = ancillaryFees[0].ancillaryFees[0].price.currencyCode;
					_.each(ancillaryFees, (travellerServices) => {
						_.each(this.model.get('selectedTravellersServices')[travellerServices.travellerUid], (service) => {
							service.set(travellerServices.ancillaryFees[service._index]);
						});
						this.updateTravellerPrices(travellerServices.travellerUid);
					});

					this.totalPrice = _.reduce(_.keys(this.travellerPrices), (prev, curr) => {
						const result = prev + this.travellerPrices[curr];
						this.travellerPrices[curr] = this.travellerPrices[curr].toString();
						return result;
					}, 0);

					this.ui.travellersPriceList.html(TravellerPrices({
						travellerPrices: this.travellerPrices,
						travellers: this.travellers,
						totalPrice: this.totalPrice.toString(),
						travellersServiceCount: this.travellerServiceCount,
						currencyCode,
						selectedTraveller: this.selectedTraveller,
					}));
				}
			});
	},

	updateTravellerPrices(tUid) {
		const selected = this.model.getSelectedServices(tUid);
		let selectedQuantity = 0;

		this.travellerPrices[tUid] = _.reduce(selected, (prev, curr) => {
			selectedQuantity += curr.get('quantity');
			return prev + (parseFloat(curr.get('price').amount) * curr.get('quantity'));
		}, 0);
		this.travellerServiceCount[tUid] = selectedQuantity;
		this.travellersSelectedServices[tUid] = selected;
	},

	close() {
		this.$el.remove();
		this.destroy();
	},

});
