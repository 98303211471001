module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 _.each(issues, function(issue, i) { ;
__p += '\n    ';
 var isActiveIssue = toggledIssuesUids.includes(issue.uid); ;
__p += '\n    <div class="b-order-issues__item">\n        <div\n            class="b-order-issues__item-name collapse--with-anchor ' +
((__t = ( isActiveIssue ? '': 'collapsed' )) == null ? '' : __t) +
'"\n            data-issue-uid="' +
((__t = ( issue.uid )) == null ? '' : __t) +
'"\n            data-toggle="collapse"\n            data-target=".toggle-order-issues__container-' +
((__t = ( i )) == null ? '' : __t) +
'">\n            <div class="b-order-issues__item-title">\n                ' +
((__t = ( issue.title )) == null ? '' : __t) +
'\n            </div>\n        </div>\n        <div class="toggle-order-issues__container-' +
((__t = ( i )) == null ? '' : __t) +
' collapse b-order-issue ' +
((__t = ( isActiveIssue ? 'show' : '' )) == null ? '' : __t) +
'">\n            <div class="b-order-issue__posts">\n                ';
 _.each(issue.posts, function(day) { ;
__p += '\n                    ';
 _.each(day, function(post) { ;
__p += '\n                        ' +
((__t = (	require('./template.post.ejs')({ post: post }) )) == null ? '' : __t) +
'\n                    ';
 }); ;
__p += '\n                ';
 }); ;
__p += '\n            </div>\n            <div class="b-order-issues__item-textarea-container js-dragNDropContainer-' +
((__t = ( issue.uid )) == null ? '' : __t) +
'" data-issue="' +
((__t = ( issue.uid )) == null ? '' : __t) +
'">\n                <div class="b-order-issues__item-drop-background js-dragNDropBackground-' +
((__t = ( issue.uid )) == null ? '' : __t) +
' b-order-notes__drop-background mobile-hide">\n                    ' +
((__t = ( L10N.get('cabinet.orders.dropFilesHere') )) == null ? '' : __t) +
'\n                </div>\n                <div style="width: 100%">\n                    <span class="js-widget"  onclick=\'return new Widgets.Textarea({bindingProperty: "notes.'+ issue.uid +'", placeholder: L10N.get("cabinet.orders.commentWithAttachment"), classes: "vertical-resize" })\'></span>\n                    <div class="b-order__attachments-container js-attachments-container-' +
((__t = ( issue.uid )) == null ? '' : __t) +
'"></div>\n                </div>\n\n                <div class="b-order__notes-container-footer">\n                    <button class="b-order__notes-attachment-btn js-notes-attachment-btn desktop-hide" data-issue="' +
((__t = ( issue.uid )) == null ? '' : __t) +
'" type="button">\n                        <i class="g-icon-file desktop-hide"></i>\n                    </button>\n                    <button type="button" class="btn b-cabinet-order__notes-create btn-dark" data-issue="' +
((__t = ( issue.uid )) == null ? '' : __t) +
'">\n                        <span class="mobile-hide">' +
((__t = ( L10N.get('cabinet.orders.save') )) == null ? '' : __t) +
'</span> <i class="g-icon-ok mobile-hide"></i>\n                        <i class="g-icon-send desktop-hide"></i>\n                    </button>\n                </div>\n            </div>\n        </div>\n    </div>\n';
 }); ;
__p += '\n\n';
 if (_.isEmpty(issues)) { ;
__p += '\n    <div class="b-order-issues__empty">\n        <em>' +
((__t = ( L10N.get('cabinet.orders.notes.noIssues') )) == null ? '' : __t) +
'</em>\n    </div>\n';
 } ;
__p += '\n';

}
return __p
}