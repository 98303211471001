module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-block-container">\n    <button class="desktop-hide b-navigate-back-button js-navigate-back">' +
((__t = ( L10N.get('avia.goBack') )) == null ? '' : __t) +
'</button>\n	<div class="b-block-container__header">\n		<div class="b-block-container__header-label">' +
((__t = ( L10N.get('cabinet.employees.profile') )) == null ? '' : __t) +
'</div>\n	</div>\n\n	<div class="b-block-container__content">\n		<div class="b-cabinet-holder">\n			<div class="b-cabinet-holder__content">\n				';
 if (siteType && siteType.uid !== 'B2C') { ;
__p += '\n					<div class="b-cabinet-holder__organizations"></div>\n				';
 } ;
__p += '\n				<div class="b-cabinet-holder__person"></div>\n			</div>\n		</div>\n	</div>\n\n	<span class="b-cabinet-holder__save">' +
((__t = (L10N.get("cabinet.save"))) == null ? '' : __t) +
'</span>\n</div>\n\n';

}
return __p
}