// eslint-disable-next-line banned-modules
'use strict';

import BaseView from '@/classes/base.view';

import PassengersCollection from './b-employees-collection/collection';
import RoomsCollection from './b-rooms-collection/collection';

import RetailTemplate from './template-retail.ejs';
import CompanyTemplate from './template-company.ejs';

import EmployeesCollectionView from './b-employees-collection/index';
import RoomsCollectionView from './b-rooms-collection/index';

import SearchEmployeeModel from './b-employees-collection/b-employee/model';
import SearchRoomModel from './b-rooms-collection/b-room/model';

import PassengerModelAvia from '@/widgets/w-passengers/w-passengers-avia/passenger-model';
import PassengerModelRail from '@/widgets/w-passengers/w-passengers-rail/passenger-model';
import PassengerModelHotel from '@/widgets/w-passengers/w-passengers-hotel/passenger-model';
import PassengerTransferModel from '@/widgets/w-passengers/w-passengers-transfer/passenger-model';

import axios from 'axios';

export default BaseView.extend({

	ui: {
		companyContainer: '.b-company-employees__company',
	},

	initialize() {
		this.model = this.options.model;

		this.$el.empty();
		if (this.options.isRetailClient === true) {
			this.renderRetailTemplate();
		} else {
			this.renderCompanyTemplate();
		}
	},

	render(...args) {
		BaseView.prototype.render.apply(this, args);

		_.defer(() => {
			if (STATE.getSinglePersonSearch() === true) {
				this.$el.closest('.p-search-form__wrapper').addClass('single-person');
			} else {
				this.$el.closest('.p-search-form__wrapper').removeClass('single-person');
			}
		});
	},

	renderRetailTemplate() {
		this.template = RetailTemplate;

		const passengersTypes = _.extend({}, this.model.get('passengersTypes').attributes);

		if (this.model.get('serviceTypes').find({uid: 'RAIL'}) != null) {
			const availableTypes = _.keys(PassengerModelRail.prototype.defaults());
			this.model.set('passengersTypes', new PassengerModelRail(_.pick(passengersTypes, availableTypes)));
		} else if (this.model.get('serviceTypes').find({uid: 'AVIA'}) != null) {
			const availableTypes = _.keys(PassengerModelAvia.prototype.defaults());
			this.model.set('passengersTypes', new PassengerModelAvia(_.pick(passengersTypes, availableTypes)));
		} else if (this.model.get('serviceTypes').find({uid: 'TRANSFER'}) != null) {
			const availableTypes = _.keys(PassengerTransferModel.prototype.defaults());
			this.model.set('passengersTypes', new PassengerTransferModel(_.pick(passengersTypes, availableTypes)));
		} else if (this.model.get('serviceTypes').find({uid: 'HOTEL'}) != null) {
			const availableTypes = _.keys(PassengerModelHotel.prototype.defaults());
			this.model.set('passengersTypes', new PassengerModelHotel(_.pick(passengersTypes, availableTypes)));
			this.model.set('rooms', new RoomsCollection([], {
				model: SearchRoomModel,
			}));
		}

		const passengersTypesModel = this.model.get('passengersTypes');
		if (passengersTypesModel && _.isEmpty(passengersTypesModel.toJSON())) {
			passengersTypesModel.clear().set(passengersTypesModel.defaults());
		}

		if (this.model.get('serviceTypes').find({uid: 'HOTEL'}) == null) {
			this.model.set('passengers', new PassengersCollection([], {
				model: SearchEmployeeModel,
			}));
		}

		this.render();
	},

	renderCompanyTemplate() {
		this.template = CompanyTemplate;

		this.model.trigger('change');
		if (this.model.get('serviceTypes').find({uid: 'HOTEL'}) != null) {
			let roomsCollection = this.model.get('rooms');
			if (!(roomsCollection instanceof RoomsCollection)) {
				this.model.set('rooms', new RoomsCollection(roomsCollection, {
					model: SearchRoomModel,
				}));

				roomsCollection = this.model.get('rooms');
			}

			this.render();

			this.model.get('passengersTypes').clear();
			this.passengerView = new RoomsCollectionView({
				company: this.model.get('client'),
				collection: roomsCollection,
				container: this.ui.companyContainer,
				searchModel: this.model,
			});

			return this;
		}

		const passengersCollection = this.model.get('passengers');
		const passengerViewInit = () => {
			this.render();
			this.model.get('passengersTypes').clear();
			this.passengerView = new EmployeesCollectionView({
				company: this.model.get('client'),
				collection: passengersCollection,
				container: this.ui.companyContainer,
				maxEmployees: this.model.get('serviceTypes').find({uid: 'RAIL'}) != null ? 4 : null,
			});
		};

		if (passengersCollection.size() === 0) {
			axios.get('/midoffice/ibecorp-b2b/autocomplete/organization-employees', {
				params: {
					pattern: '',
					limit: 3,
					uid: this.model.get('client').uid,
				},
			}).then(resp => {
				const singelPerson = (resp.data.result.length === 1);
				STATE.setSinglePersonSearch(singelPerson);

				if (singelPerson) { // in case of single person set it by default
					let exists = false;
					passengersCollection.forEach((p) => {
						if (p.get('passengers')) {
							if (p.get('passengers').uid === resp.data.result[0].uid) {
								exists = true;
							}
						}
					});
					if (!exists) {
						passengersCollection.add(new passengersCollection.model(resp.data.result[0]));
						this.$el.closest('.p-search-form__wrapper').addClass('single-person');
					}
				} else {
					this.$el.closest('.p-search-form__wrapper').removeClass('single-person');
				}
				passengerViewInit();
			});
		} else {
			passengerViewInit();
		}

		return this;
	},
});
