// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import PersonView from '@/blocks/pages/b-cabinet/b-cabinet-person';
import template from './template.ejs';
import Model from './model';

import axios from 'axios';

export default BaseView.extend({

	template,

	ui: {
		container: '.b-cabinet-passenger__content',
		person: '.b-cabinet-passenger__person',
	},

	events: {
		'click .b-cabinet-passenger__save': 'save',
		'click .b-passenger-remove': 'removePassenger',
	},

	preinitialize(options) {
		BaseView.prototype.preinitialize.call(this, options);
		this.windowEventListenerList.push({
			mediaQuery: '(max-width: 768px)',
			name: 'change',
			callback: this.adjustMobileTemplate.bind(this),
			isMatchMedia: true,
		});
	},

	initialize() {
		const { passengerInfo } = this.options;

		this.isNew = _.isEmpty(passengerInfo) || !passengerInfo.passenger.uid;
		this.editPersonAllowed = passengerInfo && passengerInfo.editPersonAllowed != null ? passengerInfo.editPersonAllowed : true;
		this.model = !_.isEmpty(passengerInfo) ? new Model(passengerInfo.passenger, {parse: true}) : new Model();

		this.render();

		if (!this.editPersonAllowed) {
			_.each(this.$el.find('*'), (el) => this.$(el).attr('tabindex', -1));

			this.$el.keydown((e) => {
				if ((e.keyCode || e.which) === 9) e.preventDefault();
			});
		}
	},

	render() {
		this.setElement(template(_.extend({
			isDisablePersonAllowed: !this.isNew && this.options.passengerInfo.isDisablePersonAllowed === true,
			editPersonAllowed: this.editPersonAllowed,
			showDeleteBtn: !this.isNew && this.editPersonAllowed,
		}, this.model.attributes)));

		const person = new PersonView({
			model: this.model,
			personType: 'passenger',
			isNew: this.isNew,
			advancedPersonForm: true,
		});

		this.ui.person.html(person.$el);
		this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));
	},

	delegateEvents(...args) {
		BaseView.prototype.delegateEvents.apply(this, args);
	},

	undelegateEvents(...args) {
		BaseView.prototype.undelegateEvents.apply(this, args);
	},

	adjustMobileTemplate(matches) {
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			if (_.isObject(matches)) matches = matches.matches;

			const $addPassengerBtn = this.$('.b-passenger-remove');
			const $container = this.$('.b-block-container');
			const $addButtonHeaderContainer = this.$('.b-block-container__header-right');
			const $tooltips = this.$('[data-toggle="tooltip"]');

			if (matches) {
				$addPassengerBtn.appendTo($container);
				_.each($tooltips, (el) => el && this.$(el).tooltip('disable'));
			} else {
				$addPassengerBtn.appendTo($addButtonHeaderContainer);
				_.each($tooltips, (el) => el && this.$(el).tooltip('enable'));
			}
		}, 100);
	},

	save() {
		STATE.showLoader();
		axios[this.isNew === true ? 'post' : 'put']('/midoffice/ibecorp-b2b/cabinet/passenger', {
			parameters: {
				passenger: this.model.toJSON(),
			},
		}, this.model).then(() => {
			STATE.hideLoader();

			if (this.isNew) {
				STATE.navigate(STATE.ROUTES.CABINET_PASSENGERS);
				this.model.clear();
			}
		}).catch((e) => {
			logger.debug(e);
			if (e && e.error === 'validationError') {
				const popup = new Widgets.Popup({
					type: 'danger',
					content: `
						<div>
							${L10N.get('cabinet.successfulSavedPopup.failed')}
						</div>
					`,
					actions: [{
						label: L10N.get('cabinet.orders.close'),
						action: () => {
							popup.hide();
						},
					}],
				});
				popup.show();
			}
		});
	},

	removePassenger(e) {
		if (e) {
			e.preventDefault();
		}

		const passengerName = `${this.model.get('lastName') || ''} ${this.model.get('firstName') || ''} ${this.model.get('middleName') || ''}`;
		const popup = new Widgets.Popup({
			content: `
				<div class="b-dialog__popup-confirm-quastion">${L10N.get('cabinet.passenger.removePassenger')}</div>
				<div class="b-dialog__popup-confirm-content">${passengerName}</div>
			`,
			title: L10N.get('cabinet.orders.confirm'),
			type: 'danger',
			actions: [
				{
					label: L10N.get('cabinet.orders.cancel'),
					action: () => {
						popup.hide();
					},
				},
				{
					label: L10N.get('cabinet.orders.remove'),
					action: ($currentTarget) => {
						$currentTarget.attr('disabled', true);
						STATE.showLoader();
						this.disableElements(e);
						axios.delete('/midoffice/ibecorp-b2b/cabinet/disablePerson', {
							data: {
								parameters: {
									uid: this.model.get('uid'),
								},
							},
						}, this.model).then(() => {
							STATE.navigate(STATE.ROUTES.CABINET_PASSENGERS);
							$currentTarget.attr('disabled', false);
							popup.hide();
							STATE.hideLoader();
						}).catch(() => {
							popup.hide();
							STATE.hideLoader();
						});
					},
				},
			],
			classes: 'b-dialog__popup-confirm',
		});
		popup.show();
	},
});
