module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var offerSelected = offerSelected || false; ;
__p += '\n<div class="b-avia-offer-preview">\n    <div class="b-avia-offer-preview__header">\n        <button class="b-avia-offer-preview__close-btn">' +
((__t = ( L10N.get('avia.goBack') )) == null ? '' : __t) +
'</button>\n    </div>\n\n    <div class="b-avia-offer-preview__wrapper">\n        <div class="b-avia-offer-preview__legs b-avia-offer-preview__legs">\n            ';
 _.each(flight.legs, function(leg, legIndex) {
                var segmentFirst = leg.segments[0];
                var segmentLast = leg.segments[leg.segments.length - 1];

                var dateDeparture = new Time(segmentFirst.departureDate);
                var dateArrival = new Time(segmentLast.arrivalDate);
                var companyForIcon = segmentFirst.airline && segmentFirst.airline.codeSystem !== 'IATA' ? segmentFirst.airline.airlineCode + '-' + segmentFirst.airline.codeSystem : segmentFirst.airline && segmentFirst.airline.airlineCode || 'noSegmentAirlineField';
                var opAircraft = [];
                var opFlightNumbers = [];
                var opClassOfService = [];
                var opStops = [];

                var opAirlinesObj = {};

                leg.segments.forEach(function(segment, i) {
                    if (segment.operatingAirlineCompanyName != null && segment.operatingAirlineCompanyName != flight.carrier.caption) {
                        opAirlinesObj[segment.operatingAirlineCompanyName] = true;
                    } else if (segment.operatingAirline != null && segment.operatingAirline.uid != flight.carrier.uid) {
                        opAirlinesObj[segment.operatingAirline.caption] = true;
                    } else if (segment.airline != null) {
                        opAirlinesObj[segment.airline.caption] = true;
                    }

                    opAircraft.push(segment.aircraft && segment.aircraft.caption || '-');
                    opFlightNumbers.push(segment.airline && segment.airline.airlineCode + segment.flightNumber);
                    opClassOfService.push({
                        title: segment.classOfService.caption + (segment.classOfServiceCode != null ? ' (' + segment.classOfServiceCode + ')' : ''),
                        tooltip: (segment.servicesDetails && segment.servicesDetails.tariffName) ? segment.servicesDetails.tariffName : '',
                    });

                    if (i > 0) {
                        var segmentDateDeparture = new Time(segment.departureDate);
                        var prevStopDuration = new Time(leg.segments[i - 1].arrivalDate);
                        var stopDuration = segmentDateDeparture.subtract(prevStopDuration.getMoment().toDate().getTime(), 'ms').getMoment().toDate().getTime() / 1000 / 60;

                        var stopType = 'short';

                        if (stopDuration > 2*60) {
                            stopType = 'medium';
                        }

                        if (stopDuration > 6*60) {
                            stopType = 'long';
                        }

                        opStops.push({
							caption: ((segment.departureCity || {}).caption || '--') + ' (' + ((segment.departureAirport || {}).uid || '--') + ') - ' + Formatter.formatDurationFromPattern(stopDuration),
                            type: stopType
						});
                    }
                });
                opAircraft = _.uniq(opAircraft);
                var opAirlines = _.keys(opAirlinesObj);
                if (_.size(opAirlines) === 1 && opAirlines.indexOf(flight.carrier.caption) !== -1) {
                    opAirlines = [];
                }

            ;
__p += '\n\n            <div class="b-avia-offer-preview__leg-container">\n                <div class="b-avia-offer-preview__leg-header">\n                    ';
 if (segmentFirst.departureCity) { ;
__p += '\n                        ' +
((__t = ( segmentFirst.departureCity.caption )) == null ? '' : __t) +
'\n                    ';
 } ;
__p += '\n                    -\n                    ';
 if (segmentLast.arrivalCity) { ;
__p += '\n                        ' +
((__t = ( segmentLast.arrivalCity.caption )) == null ? '' : __t) +
',\n                    ';
 } ;
__p += '\n                    ' +
((__t = ( dateDeparture.toMonthDay() )) == null ? '' : __t) +
'\n                    ';
 if (legIndex === 1) { ;
__p += ',\n                    ' +
((__t = ( dateArrival.toMonthDay() )) == null ? '' : __t) +
'\n                    ';
 } ;
__p += '\n                </div>\n                <div class="b-avia-offer-preview__leg">\n                    <div class="b-avia-offer-preview__leg-wrapper">\n                        <div class="b-avia-offer-preview__leg-info">\n                            <div class="b-avia-offer-preview__company-icon-wrapper">\n                                <div class="b-avia-offer-preview__company-icon" style="background-image: url(\'/assets/img/logos/logos-m/' +
((__t = ( companyForIcon )) == null ? '' : __t) +
'-black.svg\')"></div>\n                            </div>\n                            <div class="b-avia-offer-preview__carrier-info">\n                                <span class="b-avia-offer-preview__company-name">' +
((__t = ( _.escape(flight.carrier.caption) )) == null ? '' : __t) +
'</span>\n                                <span class="b-avia-offer-preview__carrier-number">\n                                    ';
 if (_.size(leg.segments) === 1) { ;
__p += '\n                                        ' +
((__t = ( opAircraft.join(', ') )) == null ? '' : __t) +
'\n                                    ';
 } ;
__p += '\n                                </span>\n                                <span class="b-avia-offer-preview__flight-perform">\n                                    ';
 if (_.size(opAirlines) > 0) { ;
__p += '\n                                        ' +
((__t = ( L10N.get('ticket.flightBy') )) == null ? '' : __t) +
': <span>' +
((__t = ( opAirlines.join(', ') )) == null ? '' : __t) +
'</span>\n                                    ';
 } ;
__p += '\n                                    ';
 if (flight.infoMessage) { ;
__p += '\n                                        ' +
((__t = ( _.escape(flight.infoMessage) )) == null ? '' : __t) +
'\n                                    ';
 } ;
__p += '\n                                </span>\n                            </div>\n                            <div class="b-avia-offer-preview__flight-number">\n                                ';
 var fnLength = _.size(opFlightNumbers); ;
__p += '\n\n                                ';
 if (_.size(leg.segments) === 1) { ;
__p += '\n                                    <span title="' +
((__t = ( L10N.get('ticket.flightNumber') )) == null ? '' : __t) +
'">' +
((__t = ( opFlightNumbers.join(', ') )) == null ? '' : __t) +
'</span>\n                                ';
 } else if (fnLength > 0) { ;
__p += '\n                                    ';
 _.each(opFlightNumbers, function(fn, i) { ;
__p += '\n                                        <span title="' +
((__t = ( L10N.get('ticket.flightNumber') )) == null ? '' : __t) +
'">' +
((__t = ( fn + ((fnLength - 1 !== i) ? ', ' : '') )) == null ? '' : __t) +
'</span>\n                                    ';
 }) ;
__p += '\n                                ';
 } ;
__p += '\n                            </div>\n                        </div>\n                        <div class="b-avia-offer-preview__leg-route">\n                            <div class="b-avia-offer-preview__route-dates">\n					        <span class="b-avia-offer-preview__route-date-time">\n						        ' +
((__t = ( dateDeparture.toShortTime() )) == null ? '' : __t) +
'\n					        </span>\n                                <span class="b-avia-offer-preview__route-duration">' +
((__t = ( Formatter.formatDurationFromPattern(leg.duration) )) == null ? '' : __t) +
'</span>\n                                <span class="b-avia-offer-preview__route-date-time">\n						    ' +
((__t = ( dateArrival.toShortTime() )) == null ? '' : __t) +
'\n					        </span>\n                            </div>\n                            <div class="b-avia-offer-preview__route-line"></div>\n                            <div class="b-avia-offer-preview__route-info">\n                                <div class="b-avia-offer-preview__route-location">\n                                    ';
 if (segmentFirst.departureCity) { ;
__p += '\n                                        <span>' +
((__t = ( segmentFirst.departureCity.caption )) == null ? '' : __t) +
'</span>\n                                    ';
 } ;
__p += '\n                                    ';
 if (segmentFirst.departureAirport) { ;
__p += '\n                                        <span>' +
((__t = ( segmentFirst.departureAirport.uid )) == null ? '' : __t) +
'</span>\n                                        <span>' +
((__t = ( segmentFirst.departureAirport.caption )) == null ? '' : __t) +
'</span>\n                                    ';
 } ;
__p += '\n                                </div>\n                                <div class="b-avia-offer-preview__route-stops">\n                                    ';
 if (leg.segments.length > 1) { ;
__p += '\n                                        <span>' +
((__t = ( leg.segments.length - 1 )) == null ? '' : __t) +
' ' +
((__t = ( L10N.getPluralForm('flightlegs.stopEnum', leg.segments.length - 1) )) == null ? '' : __t) +
'</span>\n                                    ';
 } else { ;
__p += '\n                                        <span>' +
((__t = ( L10N.get('flightlegs.direct'))) == null ? '' : __t) +
'</span>\n                                    ';
 } ;
__p += '\n                                </div>\n                                <div class="b-avia-offer-preview__route-location">\n                                    ';
 if (segmentLast.arrivalCity) { ;
__p += '\n                                        <span>' +
((__t = ( segmentLast.arrivalCity.caption )) == null ? '' : __t) +
'</span>\n                                    ';
 } ;
__p += '\n                                    ';
 if (segmentLast.arrivalAirport) { ;
__p += '\n                                        <span>' +
((__t = ( segmentLast.arrivalAirport.uid )) == null ? '' : __t) +
'</span>\n                                        <span>' +
((__t = ( segmentLast.arrivalAirport.caption )) == null ? '' : __t) +
'</span>\n                                    ';
 } ;
__p += '\n                                </div>\n                            </div>\n                        </div>\n                        <div class="b-avia-offer-preview__leg-services">\n                            ';

                                var services = flight.servicesStatuses;
                                var cabinLuggageState = _.every(leg.segments, function (segment) {
                                    return !_.isEmpty(segment.servicesDetails.freeCabinLuggage) || !_.isEmpty(segment.servicesDetails.paidCabinLuggage);
                                }) ? 'FREE' : 'OFF';
                            ;
__p += '\n                            <div class="b-avia-offer-preview-services__option-row">\n                                <span class="g-icon-option-cabin-luggage-' +
((__t = ( cabinLuggageState )) == null ? '' : __t) +
'-black"></span>\n                                <div>' +
((__t = ( require('../b-ticket-luggage.ejs')({ segments: leg.segments, type: 'luggage', isMobileTemplate: true }) )) == null ? '' : __t) +
'</div>\n                            </div>\n                            ';
 if (services.baggage != null) { ;
__p += '\n                                <div class="b-avia-offer-preview-services__option-row">\n                                    <span class="g-icon-option-baggage-' +
((__t = ( services.baggage.uid )) == null ? '' : __t) +
'-black"></span>\n                                    <div>' +
((__t = ( require('../b-ticket-luggage.ejs')({ segments: leg.segments, type: 'baggage', isMobileTemplate: true }) )) == null ? '' : __t) +
'</div>\n                                </div>\n                            ';
 } ;
__p += '\n                            ';
 if (services.exchange != null || services.refund != null) {
                                var states = _.compact([(services.exchange || {}).uid, (services.refund || {}).uid]);
                                var state = 'FREE';
                                if ((states[0] === 'OFF' && !states[1]) || (states[0] === 'OFF' && states[0] === states[1])) {
                                    state = 'OFF';
                                }
                            ;
__p += '\n                            <div class="b-avia-offer-preview-services__option-row">\n                                <span class="g-icon-option-refund-exchange-' +
((__t = ( state )) == null ? '' : __t) +
'-black" style="min-width: 22px;"></span>\n                                <div>' +
((__t = ( require('../b-ticket-refund-exchange.ejs')({ services: {refund: flight.refund, exchange: flight.exchange}, flightToken: flight.flightToken, isList: true }) )) == null ? '' : __t) +
'</div>\n                            </div>\n                            ';
 } ;
__p += '\n                            <div class="b-avia-offer-preview__other-tariffs">\n                                ';
 if (flight.hideOtherFares !== true) { ;
__p += '\n                                  <a class="action-link b-avia-offer-preview__branded-fares b-avia-offer-preview-price__branded-fares">' +
((__t = ( L10N.get("flightlegs.selectBrandedFares") )) == null ? '' : __t) +
'</a>\n                                ';
 } ;
__p += '\n                            </div>\n                        </div>\n                    </div>\n                </div>\n\n                ';
 if (leg.segments.length > 1) { ;
__p += '\n                    ';
 _.each(opStops, function (s) { ;
__p += '\n                        <div class="b-avia-offer-preview__leg-segments-duration-stop b-stop b-stop-' +
((__t = ( s.type)) == null ? '' : __t) +
'">\n                            <i></i>\n                            ' +
((__t = ( L10N.get('flightlegs.stopmedium'))) == null ? '' : __t) +
' ' +
((__t = ( s.caption )) == null ? '' : __t) +
'\n                        </div>\n                    ';
 }) ;
__p += '\n                ';
 } ;
__p += '\n            </div>\n            ';
 }); ;
__p += '\n            ';
 if (flight.availableSeats != null) { ;
__p += '\n                <div class="b-avia-offer-preview__available-seats">\n                    ' +
((__t = ( require('../b-ticket-available-seats.ejs')({
                        availableSeats: flight.availableSeats,
                    }) )) == null ? '' : __t) +
'\n                </div>\n            ';
 } ;
__p += '\n            ';
 if ((flight.travelPolicyCompliance != null && !_.isEmpty(flight.travelPolicyCompliance.rulesApplied)) || flight.isTripartiteContractDiscountApplied === true) { ;
__p += '\n                <div class="b-avia-offer-preview__travel-policy b-travel-policy">\n                    ';
 if (flight.travelPolicyCompliance != null && !_.isEmpty(flight.travelPolicyCompliance.rulesApplied)) {
                        _.each(flight.travelPolicyCompliance.rulesApplied, function (rule) { ;
__p += '\n                            <div class="b-travel-policy__line">\n                                <span class="b-travel-policy-rule g-icon-travel-policy-rules-' +
((__t = ( rule.action.uid )) == null ? '' : __t) +
'"></span>\n                                <div class="b-travel-policy__description">\n                                    <div class="b-travel-policy__rule-caption">' +
((__t = ( rule.action.caption )) == null ? '' : __t) +
'</div>\n                                    ';
 if (_.size(rule.ruleDetails) > 0) { ;
__p += '\n                                        ';
 _.each(rule.ruleDetails, function(ruleDetail){ ;
__p += '\n                                            <div>' +
((__t = ( _.escape(ruleDetail) )) == null ? '' : __t) +
'</div>\n                                        ';
 }); ;
__p += '\n                                    ';
 } ;
__p += '\n                                </div>\n                            </div>\n                        ';
 }); ;
__p += '\n                    ';
 } ;
__p += '\n                    ';
 if (flight.isTripartiteContractDiscountApplied === true) { ;
__p += '\n                        <div class="b-travel-policy__line b-travel-policy__rule">\n                            <span class="b-ticket-leg__feature g-icon-features-3D-contract"></span>\n                            <div class="b-travel-policy__description">\n                                <div class="b-travel-policy__rule-caption">' +
((__t = ( L10N.get('ticket.tripartiteContractDiscount') )) == null ? '' : __t) +
'</div>\n                            </div>\n                        </div>\n                    ';
 } ;
__p += '\n                </div>\n            ';
 } ;
__p += '\n        </div>\n        <div class="b-avia-offer-preview__price-details inherit-color">\n            ' +
((__t = ( require('../b-ticket-pricing.ejs')({
                price: flight.price,
                isMobileTemplate: true,
                totalPrice: flight.price.total
            }) )) == null ? '' : __t) +
'\n        </div>\n        <div class="b-avia-offer-preview__price-buttons b-avia-offer-preview-price">\n            ';
 if (this.issueAllowed) { ;
__p += '\n                <div class="b-avia-offer-preview-price__top">\n                    <div class="btn btn-dark b-avia-offer-preview-price__button">\n                        ' +
((__t = ( L10N.get('ticket.selectTicket'))) == null ? '' : __t) +
'\n                    </div>\n                </div>\n            ';
 } ;
__p += '\n            ';
 if (this.approvalAllowed) { ;
__p += '\n                <div class="b-avia-offer-preview-price__top">\n                    ';
 if (STATE.checkSiteType('B2B')) { ;
__p += '\n                        ';
 if (offerSelected) { ;
__p += '\n                            <div class="btn btn-dark b-avia-offer-preview-price__button--offer b-ticket-offer__cancel" data-flight-token="' +
((__t = ( flight.flightToken )) == null ? '' : __t) +
'">\n                                ' +
((__t = ( L10N.get('avia.remove') )) == null ? '' : __t) +
'\n                            </div>\n                        ';
 } else { ;
__p += '\n                            <div class="btn btn-dark b-avia-offer-preview-price__button--offer">\n                                ' +
((__t = ( L10N.get('ticket.addToCoordination') )) == null ? '' : __t) +
'\n                            </div>\n                        ';
 } ;
__p += '\n                    ';
 } ;
__p += '\n                </div>\n            ';
 } ;
__p += '\n        </div>\n        <div class="b-flight-ticket__branded-fares--mobile">\n            <div class="b-fares__header">\n                <div class="b-fares__title">' +
((__t = ( L10N.get('flightlegs.tariffs') )) == null ? '' : __t) +
'</div>\n                <div class="b-fares__close"></div>\n            </div>\n            <div class="b-fares__brand-name-container">\n                ';
 var companyForIcon = flight.carrier && flight.carrier.codeSystem !== 'IATA' ? flight.carrier.airlineCode + '-' + flight.carrier.codeSystem : flight.carrier && flight.carrier.airlineCode || 'noCarrierField'; ;
__p += '\n                <div style="background-image: url(\'/assets/img/logos/' +
((__t = ( companyForIcon )) == null ? '' : __t) +
'-black.svg\')"></div>\n            </div>\n\n            <div class="b-fares__fares-container" data-branded-container></div>\n\n            <div class="b-fares__footer">\n              <div class="slider-wrapper">\n                <div class="slider-overflow-arrow" data-prev-card></div>\n                <div class="b-fares__slider-indicators-container"></div>\n                <div class="slider-overflow-arrow" data-next-card></div>\n              </div>\n\n                <div class="b-fares__footer-content">\n                    ';
 if (this.approvalAllowed && STATE.checkSiteType('B2B')) { ;
__p += '\n                        <div class="btn btn-dark b-fares__select-tariff--offer">\n                            ' +
((__t = ( L10N.get('ticket.addToCoordination') )) == null ? '' : __t) +
'\n                        </div>\n                    ';
 } ;
__p += '\n                    ';
 if (this.issueAllowed) { ;
__p += '\n                        <div class="btn btn-dark b-fares__select-tariff">\n                            ' +
((__t = ( L10N.get('flightlegs.selectTariff') )) == null ? '' : __t) +
'\n                        </div>\n                    ';
 } ;
__p += '\n                </div>\n            </div>\n        </div>\n        <div class="b-flight-ticket__standard-fares--mobile">\n            <div class="b-fares__header">\n                <div class="b-fares__title">' +
((__t = ( L10N.get('flightlegs.tariffs') )) == null ? '' : __t) +
'</div>\n                <div class="b-fares__close"></div>\n            </div>\n\n            <div class="b-fares__fares-container" data-standard-container></div>\n\n            <div class="b-fares__footer">\n                <div class="slider-overflow-arrow" data-prev-card></div>\n                <div class="b-fares__slider-indicators-container"></div>\n                <div class="slider-overflow-arrow" data-next-card></div>\n\n                <div class="b-fares__footer-content">\n                    ';
 if (this.approvalAllowed && STATE.checkSiteType('B2B')) { ;
__p += '\n                        <div class="btn btn-dark b-fares__select-tariff--offer">\n                            ' +
((__t = ( L10N.get('ticket.addToCoordination') )) == null ? '' : __t) +
'\n                        </div>\n                    ';
 } ;
__p += '\n                    ';
 if (this.issueAllowed) { ;
__p += '\n                        <div class="btn btn-dark b-fares__select-tariff">\n                            ' +
((__t = ( L10N.get('flightlegs.selectTariff') )) == null ? '' : __t) +
'\n                        </div>\n                    ';
 } ;
__p += '\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
}