/* eslint-disable banned-modules */
'use strict';

import $ from 'jquery';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

export default BaseView.extend({

	template,

	events: {
		'click .js-select-carriage': 'select',
		'click .test': 'select',
	},

	carriageTypes: [
		{
			uid: 'common',
			caption: L10N.get('trains.carriageTypes.common'),
			type: 'O',
		},
		{
			uid: 'seating',
			caption: L10N.get('trains.carriageTypes.seating'),
			type: 'S',
		},
		{
			uid: 'platzkart',
			caption: L10N.get('trains.carriageTypes.platzkart'),
			type: 'P',
		},
		{
			uid: 'coupe',
			caption: L10N.get('trains.carriageTypes.coupe'),
			type: 'K',
		},
		{
			uid: 'soft',
			caption: L10N.get('trains.carriageTypes.soft'),
			type: 'L',
		},
		{
			uid: 'suite',
			caption: L10N.get('trains.carriageTypes.suite'),
			type: 'M',
		},
	],

	initialize(options) {
		this.parent = options.parent;
		this.currencyCode = options.currencyCode;
		this.specialServiceClasses = options.specialServiceClasses;
		this.model = this.parent.model;
		this.render();
	},

	select(e) {
		const $currentTarget = $(e.currentTarget);
		if ($currentTarget.hasClass('open')) {
			$currentTarget.removeClass('open');
			this.parent.toggleMapContainer(false);
		} else {
			this.$('.b-train__carriages-type').removeClass('open');

			const uid = $currentTarget.data('uid');
			const type = $currentTarget.data('type');
			const groupByServiceClass = this.model.get('groupByServiceClass');

			if (this.model.get('disabled') === true) {
				return;
			}

			this.parent.select(uid, type, groupByServiceClass).then(() => {
				if (!$currentTarget.hasClass('sold_out')) {
					$currentTarget.addClass('open');
				}
			});
		}
	},

});
