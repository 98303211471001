// eslint-disable-next-line banned-modules
'use strict';

import PersonModel from '@/blocks/pages/b-cabinet/b-cabinet-person/model';
import BaseCollection from '@/classes/base.collection';
import BaseModel from '@/classes/base.model';
import OrganizationInfoModel from '@/blocks/pages/b-cabinet/b-cabinet-person/b-cabinet-person-organization/model';

export default PersonModel.extend({

	defaults() {
		return _.extend({}, {
			organizationInfos: new BaseCollection([], {
				model: OrganizationInfoModel,
				parse: true,
			}),
			systemAccess: new BaseModel(),
		}, PersonModel.prototype.defaults());
	},

	handleValidation(...args) {
		if (!this.get('holder') && args.length > 0 && args[0].holder != null) {
			args[0] = args[0].holder;
			if (args[0].passports != null) {
				const passports = args[0].passports;
				Object.keys(passports).forEach(key => {
					const passport = passports[key];
					if (passport.middleName != null) {
						args[0].middleName = passport.middleName;
					}
				});
			}
		}
		PersonModel.prototype.handleValidation.apply(this, args);
	},

	parse(...args) {
		const data = PersonModel.prototype.parse.apply(this, args);
		data.organizationInfos = new BaseCollection(data.organizationInfos, {
			model: OrganizationInfoModel,
			parse: true,
		});
		return data;
	},

	toJSON() {
		const holder = BaseModel.prototype.toJSON.call(this);
		delete holder.acceptTwoFactorAuthorization;
		delete holder.getAuthToken;
		if (STATE.checkSiteType('B2C')) delete holder.organizationInfos;

		if (!this.get('getAuthToken') || !this.get('acceptTwoFactorAuthorization')) {
			delete holder.googleAuthToken;
			holder.securityType = 'DEFAULT';
		} else {
			holder.securityType = 'GOOGLE';
		}

		return holder;
	},

});
