import { Leg } from "../../react/helpers/interfaces/Leg";

class AviaUtils {
	static isRoundTrip(legs: Leg[]) {
		if (legs.length === 2) {
			const lastLeg = legs[legs.length - 1];
			const firstSegment = legs[0].segments[0];
			const lastSegment = lastLeg.segments[lastLeg.segments.length - 1];
			return (
				firstSegment.departureAirport.uid === lastSegment.arrivalAirport.uid &&
				firstSegment.arrivalAirport.uid === lastSegment.departureAirport.uid
			);
		}
		return false;
	}
}

export default AviaUtils;
