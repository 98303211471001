module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


var travellersCount = this.bookingInfo.travellers.length;
var totalAmount = 0;
var routes = [this.bookingInfo.routeInfo];
var trains = [this.bookingInfo.routeInfo.train];
if (this.bookingInfo.routeInfoBack != null) {
	trains.push(this.bookingInfo.routeInfoBack.train);
	routes.push(this.bookingInfo.routeInfoBack);
}
routes.forEach(function(route) {
	totalAmount += parseFloat(route.carriage.price.price, 10) * travellersCount;
});

;
__p += '\r\n<div class="b-train-info__wrapper">\r\n	';
 trains.forEach(function(train, i) { ;
__p += '\r\n	<div class="b-train-info">\r\n		<div class="b-train-info__number">\r\n			№ ' +
((__t = ( train.number )) == null ? '' : __t) +
'' +
((__t = ( train.brand != null ? ' &rarr; ' + train.brand : '' )) == null ? '' : __t) +
'\r\n\r\n            ';
 if (pricingInfo && pricingInfo.travelPolicyCompliance && !_.isEmpty(pricingInfo.travelPolicyCompliance.rulesApplied) || train.tripartiteContract === true) { ;
__p += '\r\n                <span class="b-travel-policy">\r\n                            ' +
((__t = ( require('@/blocks/pages/b-hotels/b-hotels-hotel/b-travel-policy.ejs')({
                                travelPolicyCompliance: pricingInfo.travelPolicyCompliance,
                                tripartiteContract: train.tripartiteContract
                            }) )) == null ? '' : __t) +
'\r\n                    </span>\r\n            ';
 } ;
__p += '\r\n\r\n			<div class="b-ticket-info__header-separator"></div>\r\n		</div>\r\n		<div class="b-train__info-services">\r\n			';
 train.services.forEach(function(service) { ;
__p += '\r\n				<div class="train-service__img train-service__' +
((__t = ( service.uid )) == null ? '' : __t) +
'" data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( STATE.escape(service.caption) )) == null ? '' : __t) +
'"></div>\r\n			';
 }); ;
__p += '\r\n		</div>\r\n		';

		var segment = train.segments[0];
		var dateDeparture = new Time(segment.departureDate);
        var dateArrival = new Time(segment.arrivalDate);
		;
__p += '\r\n		<div class="b-ticket-info__legs">\r\n			<div class="b-ticket-info__legs-leg">\r\n				<div class="b-ticket-info__legs-leg-info">\r\n					<div class="ticket-info__transplants"></div>\r\n					<div class="ticket-info__description">\r\n						<div class="ticket-info__leg-segment">\r\n							<span class="ticket-info__place">\r\n								<strong>' +
((__t = ( segment.departureLocation.caption )) == null ? '' : __t) +
'</strong>\r\n							</span>\r\n						</div>\r\n						<div>\r\n							<span class="ticket-info__date">\r\n								<span class="ticket-info__time">' +
((__t = ( dateDeparture.toShortTime())) == null ? '' : __t) +
'</span>\r\n								' +
((__t = ( dateDeparture.toTicketDate() )) == null ? '' : __t) +
'\r\n							</span>\r\n						</div>\r\n\r\n						<div class="ticket-info__leg-travel-duration">\r\n							<span class="travel-duration">' +
((__t = ( Formatter.formatDurationInMinutes(segment.durationInMinutes) )) == null ? '' : __t) +
'</span> ' +
((__t = ( L10N.get('trains.travelDuration') )) == null ? '' : __t) +
'\r\n							';
 if (train.isRouteAvialable) {;
__p += '\r\n							<span class="b-ticket-stops-info"><a href="" class="b-train-route__link" data-index="' +
((__t = ( i )) == null ? '' : __t) +
'">' +
((__t = ( L10N.get('trains.route') )) == null ? '' : __t) +
'</a></span>\r\n							';
 } ;
__p += '\r\n						</div>\r\n\r\n						<div class="ticket-info__leg-segment">\r\n							<span class="ticket-info__place">\r\n								<strong>' +
((__t = ( segment.arrivalLocation.caption )) == null ? '' : __t) +
'</strong>\r\n							</span>\r\n						</div>\r\n						<div>\r\n							<span class="ticket-info__date">\r\n								<span class="ticket-info__time">' +
((__t = ( dateArrival.toShortTime())) == null ? '' : __t) +
'</span>\r\n								' +
((__t = ( dateArrival.toTicketDate() )) == null ? '' : __t) +
'\r\n							</span>\r\n						</div>\r\n					</div>\r\n				</div>\r\n			</div>\r\n		</div>\r\n	</div>\r\n	';
 }); ;
__p += '\r\n	<div class="b-train-info__pricing"></div>\r\n</div>\r\n\r\n';

}
return __p
}