module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-filters__line ' +
((__t = ( classes )) == null ? '' : __t) +
'">\n	<span class="js-widget"  onclick=\'return new Widgets.Range({ bindingProperty: "value", label: "' + label + '", min: ' + min + ', max: ' + max + ', formatter: "' + formatter + '", classes: "b-filters-transfers-range"  })\'></span>\n	';
 if (showInputValue === true) { ;
__p += '\n		<div class="b-filters__range-input-container">\n			<div class="b-filters__range-input">' +
((__t = ( L10N.get('filters.up') )) == null ? '' : __t) +
' <span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "__min", classes: "b-filters__range-min" })\'></span></div>\n			<div class="b-filters__range-input">' +
((__t = ( L10N.get('filters.to') )) == null ? '' : __t) +
' <span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "__max", classes: "b-filters__range-max" })\'></span></div>\n		</div>\n	';
 } ;
__p += '\n</div>\n';

}
return __p
}