module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var cancellationHours = to.cancellationNoPenaltyHours;
    var freeCancellationText = L10N.get('transfers.cancellationWarning.firstPart') + cancellationHours.toString();
    if (cancellationHours > 5 && cancellationHours < 21) {
        freeCancellationText += L10N.get('transfers.cancellationWarning.greaterThen5');
    } else if (cancellationHours === 1 || cancellationHours.toString().match(/(^[0-9]{1,3})(1)/)) {
        freeCancellationText += L10N.get('transfers.cancellationWarning.oneHour');
    } else {
        freeCancellationText += L10N.get('transfers.cancellationWarning.lessThen5');
    }
    freeCancellationText += L10N.get('transfers.cancellationWarning.lastPart');
;
__p += '\n\n<div class="b-transfers__ride-details">\n    <div class="b-transfers__ride-details__header collapse--with-anchor--white mobile-collapse" data-toggle="collapse" data-target=".toggle-ride-details">\n        <div class="b-transfers__ride-details__header-label">' +
((__t = ( L10N.get('transfers.rideDetails') )) == null ? '' : __t) +
'</div>\n    </div>\n    <div class="b-transfers__ride-details__wrapper toggle-ride-details show">\n        <div class="b-transfers__ride-details__trips"></div>\n        <div class="b-transfers__ride-details__warning">\n            <span class="b-transfers__ride-details__warning-icon"></span>\n            <span class="b-transfers__ride-details__warning-text">' +
((__t = ( freeCancellationText )) == null ? '' : __t) +
'</span>\n        </div>\n    </div>\n</div>\n\n';

}
return __p
}