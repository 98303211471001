module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<span class="b-airlines ' +
((__t = (classes )) == null ? '' : __t) +
'">\r\n	<div class="b-airlines__wrapper">\r\n		<div class="b-airlines__label"></div>\r\n		<div class="b-airlines__select">\r\n			<div class="b-airlines__select-img"></div>\r\n		</div>\r\n	</div>\r\n	<div class="b-airlines__popup">\r\n		 <span class="b-airlines__selected"></span>\r\n		<input type="text" placeholder="' +
((__t = (L10N.get('searchForm.selectAirlines') )) == null ? '' : __t) +
'" class="b-airlines__input">\r\n	</div>\r\n</span>\r\n';

}
return __p
}