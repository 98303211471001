module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	var renderedRange = require("./range-template.ejs")({ showRange, showInputValue, label, min, max, formatter });
;
__p += '\r\n<div class="b-filters__line ' +
((__t = ( classes )) == null ? '' : __t) +
'">\r\n	';
 if (activateWidgetByToggle) { ;
__p += '\r\n		<span class="js-widget"  onclick=\'return new Widgets.ToggleButton({ bindingProperty: "active", label: "' + toggleLabel +'"})\'></span>\r\n		<div class="js-widget-container ' +
((__t = ( active ? '' : 'dn')) == null ? '' : __t) +
'">\r\n			' +
((__t = ( renderedRange )) == null ? '' : __t) +
'\r\n		</div>\r\n	';
 } else { ;
__p += '\r\n		' +
((__t = ( renderedRange )) == null ? '' : __t) +
'\r\n	';
 } ;
__p += '\r\n</div>';

}
return __p
}