module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var aclEditGranted = aclEditGranted == null ? true : aclEditGranted;
    var aclViewGranted = aclViewGranted == null ? true : aclViewGranted;
    var aclDeleteGranted = aclDeleteGranted == null ? true : aclDeleteGranted;
;
__p += '\n<div class="b-person-organization">\n	<div class="l-grid-container">\n		<div class="l-grid-layout-33">\n			<div class="b-person-organization__company ';
 if (!aclViewGranted) { ;
__p += 'content-blur" style="pointer-events: none" ';
 } else { ;
__p += '"';
 } ;
__p += '>\n				<span class="js-widget"  onclick=\'return new Widgets.CompanyAutocomplete({ bindingProperty: "organization", label: L10N.get("cabinet.employees.clientCompany"), formatter: ' + companyFormatter + ', useAcl: true, disabled: "'+ !aclEditGranted +'" })\'></span>\n			</div>\n		</div>\n		<div class="l-grid-layout-33 b-person-organization__categories-wrapper">\n			<div class="b-person-organization__categories ';
 if (!aclViewGranted) { ;
__p += 'content-blur" style="pointer-events: none" ';
 } else { ;
__p += '"';
 } ;
__p += '"></div>\n		</div>\n		<div class="l-grid-layout-33 b-person-organization__checkbox">\n			<div class="b-person-organization__main-employment">\n				<span class="js-widget"  onclick=\'return new Widgets.Checkbox({ bindingProperty: "mainEmployment", label: L10N.get("cabinet.employees.mainEmployment"), value: "true", disabled: "true" })\'></span>\n			</div>\n			';
 if (aclDeleteGranted) { ;
__p += '<span class="b-person-organization__remove"></span>';
 } ;
__p += '\n		</div>\n	</div>\n</div>\n\n';

}
return __p
}