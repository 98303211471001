// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';

const FinanceFormModel = BaseModel.extend({

	defaults() {
		return {
			page: 0,
			pageSize: 10,
			count: 0,
		};
	},

	toJSON() {
		const model = BaseModel.prototype.toJSON.call(this);
		const removeFields = ['count', 'availableFunds', 'credit', 'currency', 'currentBalance', 'endDateBalance', 'startDateBalance', 'client', 'productType'];
		_.each(removeFields, (f) => {
			if (['client', 'productType'].includes(f) && model[f] != null && model[f].uid === '') {
				delete model[f];
			} else {
				delete model[f];
			}
		});
		return {
			parameters: model,
		};
	},

});

export default FinanceFormModel;
