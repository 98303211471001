// eslint-disable-next-line banned-modules
'use strict';

import BasePageView from '@/pages/base.page-view';
import PassengersDataView from '@/blocks/pages/b-passengers-data/avia/index';
import FlightInfoView from '@/blocks/elements/b-flight-info/index';
import BaseModel from '@/classes/base.model';
import BookingModel from './model';
import BookingDuplicatesHandler from '@/blocks/utils/b-booking-duplicates-handler';
import axios from 'axios';

export default BasePageView.extend({

	events: {
		'click .b-booking__submit': 'create',
		'click .b-booking__back': 'back',
	},

	flightInfoView: null,
	passengersDataView: null,

	initialize(options) {
		const { flightToken, flightInfo, bookingSettings } = options;
		this.isInternational = flightInfo.flight && flightInfo.flight.international;
		this.isMiddleNameRequired = bookingSettings && bookingSettings.isMiddleNameRequired;
		this.keyContact = bookingSettings && bookingSettings.keyContact; // IBECORP-3417

		this.model = STATE.get(`${STATE.ROUTES.AVIA_PASSENGERS}_MODEL`);

		if (!this.model) {
			const modelJSON = STORE.get(`${STATE.ROUTES.AVIA_PASSENGERS}_MODEL`);

			if (!modelJSON) {
				this.model = new BookingModel();
			} else {
				this.model = new BookingModel(modelJSON, {
					parse: true,
				});
			}
		}
		STATE.set(`${STATE.ROUTES.AVIA_PASSENGERS}_MODEL`, this.model);

		const createBookingModel = this.model.get('createBooking');

		createBookingModel.set('flightToken', flightToken);
		this.model.set('flightInfo', new BaseModel(flightInfo));
		this.render();

		const flight = this.model.get('flightInfo').get('flight');

		this.renderFlightInfo({
			model: flight,
		});

		this.passengersDataView = new PassengersDataView({
			model: createBookingModel,
			seats: flight.seats,
			token: flightToken,
			flight,
			parent: this,
			bookingSettings,
			keyContact: this.keyContact,
		});

		this.ui.content.append(this.passengersDataView.$el);

		this.passengersDataView.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));

		if (createBookingModel.get('passengers')?.length) {
			createBookingModel.get('passengers').forEach((passengerModel) => {
				this.listenTo(passengerModel.get('costCodes'), 'change', () => {
					this.updateAviaPrice(this.model.get('createBooking').toJSON());
				});
			});
		}

		this.bookingDuplicatesHandler = new BookingDuplicatesHandler({ bookingSettings });

		if (bookingSettings?.searchDuplicatesMessage) {
			this.bookingDuplicatesHandler.showSearchDuplicatesPopup(
				{
					...bookingSettings.searchDuplicatesMessage,
					onCancel: () => this.back(),
				},
			);
		}
	},

	renderFlightInfo(options) {
		if (this.flightInfoView) this.flightInfoView.remove();
		this.flightInfoView = new FlightInfoView({
			model: options.model,
		});

		this.ui.sidebar.append(this.flightInfoView.$el);
	},

	showMealPopup() {
		const mealPopup = new Widgets.Popup({
			content: 'Выбранный тип питания недоступен. Продолжить бронирование без спец. питания?',
			closeOnlyOnAction: true,
			type: 'danger',
			actions: [{
				label: 'Нет',
				action: () => mealPopup.hide(),
			}, {
				label: 'Да',
				action: () => {
					mealPopup.hide();
					logger.debug('CREATE', this.model.get('createBooking').toJSON());
					STATE.showLoader();
					this.createBooking({
						ignoreMeal: true,
					});
				},
			}],
		});
		mealPopup.show();
	},

	create(e) {
		STATE.showLoader();
		this.disableElements(e);
		logger.debug('CREATE', this.model.get('createBooking').toJSON());
		this.createBooking();
	},

	createBooking(extend = {}) {
		STATE.setBookingInProcess(true);
		let { parameters } = this.model.get('createBooking').toJSON();
		parameters = _.extend(
			{},
			parameters,
			this.bookingDuplicatesHandler.getBookingDataObj(),
			extend,
		);

		// Remove middle name for international flights
		if (this.isInternational) {
			parameters.passengers.forEach((passenger) => {
				if (this.isMiddleNameRequired) return;
				passenger.passport.middleName = '';
			});
		}

		const modelJSON = STORE.get(`${STATE.ROUTES.AVIA_PASSENGERS}`);

		if (modelJSON && modelJSON.isIntention) {
			return axios.post('/midoffice/ibecorp-b2b/avia/createBooking/validate', { parameters }, this.model.get('createBooking'))
				.then(() => this.sendApproveIntentionRequest(modelJSON, parameters));
		}
	
		if (modelJSON && !modelJSON.isIntention) {
			_.each(_.keys(parameters), (key) => { 
				if (key.indexOf('externalSystem') !== -1) {
					if (parameters.externalSystemCodes !== undefined) {
						parameters.externalSystemCodes.push({uid: key.split('__')[1], code: parameters[key]});
					} else {
						parameters.externalSystemCodes = [{uid: key.split('__')[1], code: parameters[key]}];
					}
					delete parameters[key];
				}
			});
		}

		return this.sendCreateBookingRequest(parameters);
	},

	sendApproveIntentionRequest(modelJSON, parameters = {}) {
		return axios.post('/midoffice/ibecorp-b2b/booking/approveIntention', {
			parameters:  {
				reservationUid: modelJSON.reservationUid,
				currentBooking: modelJSON.currentBooking,
				aviaData: {
					createParameters: {
						...parameters,
						...this.bookingDuplicatesHandler.getBookingDataObj(),
					},
				},
			},
		}, null, {enableCashing: true}).then((result) => {
			STATE.hideLoader();

			if (result.data.result.mealIsNotAvailable === true) {
				this.showMealPopup();
				return result;
			}

			if (this.bookingDuplicatesHandler.shouldShowProductDuplicatesPopup(result.data.result?.aviaBookingResult)) {
				this.bookingDuplicatesHandler.showProductDuplicatesPopup({
					...result.data.result.aviaBookingResult,
					onOk: () => this.createBooking(),
					onCancel: () => this.back(),
				});
				return result;
			}

			STORE.set(STATE.ROUTES.CABINET_ORDER, {
				orderUid: result.data.result.aviaBookingResult.newBooking.uid,
			}, 48 * 60);
			if (STATE.getQueryVariable('pnr') != null) {
				window.location.href = `${STATE.getRootUrl()}#${STATE.ROUTES.CABINET_ORDER}/${result.data.result.aviaBookingResult.newBooking.uid}`;
			} else {
				STATE.navigate([STATE.ROUTES.CABINET_ORDER, result.data.result.aviaBookingResult.newBooking.uid].join('/'));
			}

			return result;
		});
	},

	sendCreateBookingRequest(parameters) {
		return axios.post('/midoffice/ibecorp-b2b/avia/createBooking', {parameters}, this.model.get('createBooking'), {enableCashing: true})
			.then((result) => {
				STATE.hideLoader();

				// no check for async actions - just show messages and do not interrupt the logic below
				if (result?.data?.result?.messages && !_.isEmpty(result.data.result.messages)) {
					const messages = result.data.result.messages;
					const popup = new Widgets.Popup({
						type: 'info',
						content: messages.length && _.map(messages, (m) => m.text).join('<br/>'),
						actions: [
							{
								label: L10N.get('Common.close'),
								action: () => popup.hide(),
							},
						],
					});
					popup.show();
				}

				if (result.data.result.mealIsNotAvailable === true) {
					this.showMealPopup();
					return result;
				}

				if (this.bookingDuplicatesHandler.shouldShowProductDuplicatesPopup(result.data.result)) {
					this.bookingDuplicatesHandler.showProductDuplicatesPopup({
						...result.data.result,
						onOk: () => this.createBooking(),
						onCancel: () => this.back(),
					});
					return result;
				}

				STORE.set(STATE.ROUTES.CABINET_ORDER, {
					orderUid: result.data.result.newBooking.uid,
				}, 48 * 60);
				if (STATE.getQueryVariable('pnr') != null) {
					window.location.href = `${STATE.getRootUrl()}#${STATE.ROUTES.CABINET_ORDER}/${result.data.result.newBooking.uid}`;
				} else {
					STATE.navigate([STATE.ROUTES.CABINET_ORDER, result.data.result.newBooking.uid].join('/'));
				}

				return result;
			});
	},

	updateAviaPrice(parameters) {
		/* Пока только для обновления данных по travelPolicyCompliance */
		return axios.post('/midoffice/ibecorp-b2b/avia/price', parameters).then((res) => {
			if (res?.data?.result?.flight) {
				this.model.get('flightInfo').set('flight', res.data.result.flight);
				this.renderFlightInfo({
					model: this.model.get('flightInfo').get('flight'),
				});
			}
		});
	},

	back(e) {
		if (e != null) {
			e.preventDefault();
		}

		STATE.navigate(STATE.ROUTES.AVIA_TICKETS);
	},

	remove() {
		this.bookingDuplicatesHandler.remove();
		BasePageView.prototype.remove.call(this);
	},

}, {
	async load() {
		if (!STATE.getCountries()) {
			await STATE.loadCountries();
		}
		return new Promise((resolve, reject) => {
			const {flightToken} = STORE.get(STATE.ROUTES.AVIA_PASSENGERS) || {};
			const promises = [];

			if (!flightToken) {
				STATE.navigate(STATE.ROUTES.INDEX);
				return;
			}

			promises.push(axios.post('/midoffice/ibecorp-b2b/avia/getFlightInfo', {
				parameters: {
					flightToken,
				},
			}));

			/*
				По идее, при первом запросе у нас будет только токен без пассажиров, и список organizationCostCodes
				должен прийти на всю организацию. Но он может не содержать в себе зависимые UDIDы.
			*/

			promises.push(axios.post('/midoffice/ibecorp-b2b/avia/getBookingFormSettings', {
				parameters: {
					token: flightToken,
				},
			}));

			Promise.all(promises).then((result) => {
				const params = {
					flightToken,
					flightInfo: result[0].data.result,
				};

				if (result[1] != null) {
					params.bookingSettings = result[1].data.result;
				}
				resolve(params);
			}).catch(reject);
		});
	},
});
