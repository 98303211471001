module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-cabinet-docodoca">\r\n	<div class="b-booking__visa-info"></div>\r\n	';
 if (!this.filled) { ;
__p += '\r\n		<div class="b-cabinet-docodoca__footer b-block-container__footer">\r\n			<div class="b-block-container__footer-rightbar">\r\n				<button class="btn btn-dark b-cabinet-docodoca__submit">' +
((__t = ( L10N.get("docoDoca.sendDocoDoca") )) == null ? '' : __t) +
'</button>\r\n			</div>\r\n		</div>\r\n	';
 } ;
__p += '\r\n</div>\r\n\r\n';

}
return __p
}