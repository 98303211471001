module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-selection__value">\r\n	';

 		var captionNew = JSON.stringify(caption);
 	;
__p += '\r\n	<span class="js-widget"  onclick=\'return new Widgets.Checkbox({ bindingProperty: "value", label: ' + STATE.escape(captionNew) + ' })\'></span>\r\n	';
 if (parent != null && parent.get('type') != null && parent.get('type') == 'SERVICES') { ;
__p += '\r\n		<span class="b-selection__service-icon train-service__img train-service__' +
((__t = ( uid )) == null ? '' : __t) +
'"></span>\r\n	';
 } else { ;
__p += '\r\n		<span class="b-selection__value-price">' +
((__t = ( L10N.get("filters.up") )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatMoney(price, 0) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(currency) )) == null ? '' : __t) +
'</span>\r\n	';
 } ;
__p += '\r\n</div>\r\n';

}
return __p
}