module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-cabinet-passenger ' +
((__t = ( !editPersonAllowed ? 'edit-disabled' : '' )) == null ? '' : __t) +
'">\r\n    ';
 if (!editPersonAllowed) { ;
__p += '\r\n        <div class="b-cabinet-passenger__edit-disabled-mask"></div>\r\n    ';
 } ;
__p += '\r\n    <button class="desktop-hide b-navigate-back-button js-navigate-back">' +
((__t = ( L10N.get('avia.goBack') )) == null ? '' : __t) +
'</button>\r\n	<div class="b-block-container">\r\n		<div class="b-block-container__header">\r\n			<div class="b-block-container__header-label">' +
((__t = ( L10N.get('cabinet.employees.profile') )) == null ? '' : __t) +
'</div>\r\n			<div class="b-block-container__header-right">\r\n                ';
 if (showDeleteBtn) { ;
__p += '\r\n                    <button class="btn btn-alarm btn-icon btn-icon-remove b-passenger-remove ml-auto ' +
((__t = ( isDisablePersonAllowed ? 'show' : '')) == null ? '' : __t) +
'">\r\n                        ' +
((__t = ( L10N.get('cabinet.passenger.deletePassenger') )) == null ? '' : __t) +
'\r\n                    </button>\r\n                ';
 } ;
__p += '\r\n			</div>\r\n		</div>\r\n\r\n		<div class="b-block-container__content">\r\n			<div class="b-cabinet-passenger">\r\n				<div class="b-cabinet-passenger__content">\r\n					<div class="b-cabinet-passenger__person"></div>\r\n				</div>\r\n			</div>\r\n		</div>\r\n\r\n		<span class="b-cabinet-passenger__save">' +
((__t = ( editPersonAllowed ? L10N.get("cabinet.save") : L10N.get('cabinet.editDisabled') )) == null ? '' : __t) +
'</span>\r\n	</div>\r\n</div>\r\n';

}
return __p
}