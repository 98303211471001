// eslint-disable-next-line banned-modules
'use strict';

import Backbone from 'backbone';
import BaseModel from './base.model';

export default Backbone.Collection.extend({

	model: BaseModel,

	constructor(...args) {
		Backbone.Collection.call(this, ...args);
		this.cid = _.uniqueId('c');
	},

	handleValidation() {

	},

	toJSON(options) {
		return Backbone.Collection.prototype.toJSON.call(this, options)
			.filter(item => item !== null && item !== undefined);
	},

});
