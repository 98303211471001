// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

export default BaseView.extend({

	template,

	events: {
		'click .b-person-bonus-card-railways__remove': 'remove',
	},

	initialize() {
		this.render();
	},

	remove() {
		this.model.destroy();
	},

	render() {
		this.setElement(this.template(this.model.attributes));
	},

});
