// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';
import $ from 'jquery';

export default BaseView.extend({

	template,

	events: {
		'click .b-current-date-matrix__table-price': 'applyFilters',
	},

	connections: {
		DIRECT: 0,
		ONE_CONNECTION: 1,
		TWO_CONNECTIONS: 2,
	},

	initialize(options) {
		this.bestPrices = options.bestPrices;
		this.filters = options.filters;
		this.render();
	},

	render() {
		this.setElement(this.template({
			bestPrices: this.bestPrices,
		}));
	},

	applyFilters(e) {
		const connection = this.connections[$(e.currentTarget).attr('data-connetion')];
		const carrier = $(e.currentTarget).attr('data-carrier');
		this.filters.each(item => {
			if (item.get('field') === 'flight.carrier') {
				item.get('values').each(airline => {
					airline.set('value', airline.get('uid') === carrier);
				});
			}
			if (item.get('field') === 'transfers[0]') {
				item.get('values').each(transfer => {
					transfer.set('value', transfer.get('uid') === connection);
				});
			}
		});
	},

	show() {
		this.$el.show();
	},

	hide() {
		this.$el.hide();
	},

});
