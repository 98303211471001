// eslint-disable-next-line banned-modules
'use strict';

import BasePageView from '@/pages/base.page-view';
import CabinetMenu from '@/blocks/pages/b-cabinet/b-cabinet-menu/index';
import FinanceView from '@/blocks/pages/b-cabinet/b-cabinet-finance/index';
import FinanceFormModel from './model';
import axios from 'axios';

export default BasePageView.extend({

	initialize(options) {
		this.formSettings = options.formSettings;
		this.render();
		this.ui.sidebar.append(new CabinetMenu().$el);
		this.ui.content.append(new FinanceView({
			model: new FinanceFormModel(),
			formSettings: this.formSettings,
			data: options.data,
		}).$el);
	},

}, {
	load() {
		return new Promise((resolve, reject) => {
			axios.get('/midoffice/ibecorp-b2b/cabinet/getBalancesCompanyFormData')
				.then(formSettingsResponse => {
					const formSettings = formSettingsResponse.data.result;
					resolve({
						formSettings,
					});
				}).catch(reject);
		});
	},
});
