module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var contactPersonEditDeny = !contactPersonEditGranted || contactPersonDisableFreeTextInput;
	var disabledClass = contactPersonEditDeny ? "disabled" : "";
    var langRegistrationFullName = langRegistrationFullName || {};
;
__p += '\n\n<div class="b-traveller">\n	<div class="b-traveller__title">\n		<div class="b-traveller-number">' +
((__t = ( model.get("type").caption )) == null ? '' : __t) +
'</div>\n		';
 if (isAdult) { ;
__p += '\n			<span class="b-traveller__title-contact">\n				<span class="js-widget"  onclick=\'return new Widgets.Checkbox({ bindingProperty: "isContact", classes: "' + disabledClass + '", label: L10N.get("bookingForm.travellerAsContact"), placeholder: L10N.get("bookingForm.activePassengerPlaceholder"), disabled: "' + contactPersonEditDeny + '" })\'></span>\n			</span>\n		';
 } ;
__p += '\n	</div>\n\n	<div class="b-traveller-info">\n		<div class="flex-grid-container">\n			<div class="flex-grid-layout js-lastName-container"></div>\n			<div class="flex-grid-layout js-firstName-container"></div>\n      <div class="flex-grid-layout js-secondName-container"></div>\n			<div class="flex-grid-layout js-middleName-container"></div>\n			<div class="flex-grid-layout">\n				<span class="js-widget"  onclick=\'return new Widgets.Gender({bindingProperty: "passport.gender" })\'></span>\n			</div>\n			<div class="flex-grid-layout p-search__options-date-with-label">\n				<span class="js-widget"  onclick=\'return new Widgets.MaskedDate({ bindingProperty: "birthDate", label: L10N.get("bookingForm.birthDate"), placeholder: L10N.get("bookingForm.birthDatePlaceholder") })\'></span>\n			</div>\n			<div class="flex-grid-layout">\n				<label class="b-input">\n					<span class="js-widget"  onclick=\'return new Widgets.Select({ bindingProperty: "passport.citizenship", values: "' + countries + '", label: L10N.get("bookingForm.citizenship")})\'></span>\n				</label>\n			</div>\n\n			';
 if (isAdult) { ;
__p += '\n				<div class="flex-grid-layout lg-visible-block">\n					<span class="js-widget"  onclick=\'return new Widgets.Phone({ bindingProperty: "phone", label: L10N.get("bookingForm.phone"), placeholder: L10N.get("bookingForm.phonePlaceholder") })\'></span>\n				</div>\n				<div class="flex-grid-layout lg-visible-block">\n					<span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "email", label: L10N.get("bookingForm.email"), classes: "b-email", placeholder: L10N.get("bookingForm.emailPlaceholder") })\'></span>\n				</div>\n			';
 } ;
__p += '\n		</div>\n\n		';
 if (isAdult) { ;
__p += '\n			<div class="lg-hidden-block">\n				<span class="b-traveller__block-header">' +
((__t = ( L10N.get("bookingForm.passengerContactsHeader"))) == null ? '' : __t) +
'</span>\n				<div class="flex-grid-container">\n					<div class="flex-grid-layout">\n						<span class="js-widget"  onclick=\'return new Widgets.Phone({ bindingProperty: "phone", label: L10N.get("bookingForm.phone"), placeholder: L10N.get("bookingForm.phonePlaceholder") })\'></span>\n					</div>\n					<div class="flex-grid-layout">\n						<span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "email", label: L10N.get("bookingForm.email"), classes: "b-email", placeholder: L10N.get("bookingForm.emailPlaceholder") })\'></span>\n					</div>\n					<div class="flex-grid-layout"></div>\n				</div>\n			</div>\n		';
 } ;
__p += '\n\n		';
 if (!_.isEmpty(organizationCostCodes)) { ;
__p += '\n			<span class="b-traveller__block-header">' +
((__t = ( L10N.get('hotels.statisticsInformation') )) == null ? '' : __t) +
'</span>\n			<div class="b-traveller-cost-codes">\n				<div class="b-traveller-cost-codes__container"></div>\n			</div>\n		';
 } ;
__p += '\n	</div>\n</div>\n';

}
return __p
}