// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './b-hotels-popup-offers-modal.ejs';

import GlUl from '@/utils/global-utils';
import axios from 'axios';
import $ from 'jquery';
import EmailsCollection from '@/blocks/elements/b-ticket/b-ticket-popup-offers/b-emails-collection/collection';
import EmailModel from '@/blocks/elements/b-ticket/b-ticket-popup-offers/b-emails-collection/b-email/model';
import EmailsCollectionView from '@/blocks/elements/b-ticket/b-ticket-popup-offers/b-emails-collection';

export default BaseView.extend({

	template,

	events: {
		'click .b-offers-hotels-modal__close': 'closeModal',
		'click .b-offers-hotels-modal__order': 'orderOffers',
		'click .b-offers-hotels-modal__submit': 'submitOrder',
		'click .b-modal-hotel__remove': 'removeHotel',
		'click .b-offers-hotels-modal__list-item-remove': 'removeOffer',
		'click .js-copy-to-clipboard': 'copyToClipboard',
	},

	ui: {
		noOffersContainer: '.b-offers-hotels-modal__no-offers',
		formButtons: '.b-offers-hotels-modal__form-button',
		emailsContainer: '.b-offers-hotels-modal__emails',
	},

	offers: [],
	ticketModalOffers: null,

	preinitialize(options) {
		BaseView.prototype.preinitialize.call(this, options);
		this.windowEventListenerList.push({
			name: 'resize',
			callback: GlUl.syncModalWidthPosition.bind(this),
		});
	},

	initialize(options) {
		this.showGdsAccountName = options.showGdsAccountName;
		GlUl.popupOffersViewInitialize.call(this, options);
	},

	render(...args) {
		BaseView.prototype.render.apply(this, args);

		this.showModal();
		this.enableDragSort('.drag-sort-enable');

		if (this.passengers != null) {
			this.$el.find('.b-offers-hotels-modal__title-person').html(this.passengers.join(', '));
			this.$el.find('.b-offers-hotels-modal__title-person-count').text(this.passengers.length);
		} else {
			this.$el.find('.b-offers-hotels-modal__title-person-count').text(this.passengersCount || 1);
		}

		this.model.set('emails', new EmailsCollection([], {
			model: EmailModel,
		}));

		const emailsContainer = this.$('.b-offers-hotels-modal__emails');
		emailsContainer.append(new EmailsCollectionView({
			autoSuggestEmails: this.model.get('autoSuggestEmails'),
			collection: this.model.get('emails'),
			container: emailsContainer,
			model: this.model,
		}));

		return this;
	},

	setOptions({$ticketContainer, data}) {
		this.$ticketContainer = $ticketContainer;
		this.offers = data;

		return this;
	},

	closeModal() {
		if (this.ticketModalOffers != null) {
			this.ticketModalOffers.hide();
		}
	},

	showModal() {
		this.ticketModalOffers = new Widgets.Popup({
			content: this.$el,
			actions: [],
			classes: 'b-ticket-popup-offers-modal',
			maskClasses: 'b-ticket-popup-offers-modal_mask b-ticket-popup-offers-modal_mask--hotels',
			overlayFixed: true,
		});

		GlUl.syncModalWidthPosition.call(this);
		this.ticketModalOffers.show();
	},

	checkOffersCount() {
		if (_.isEmpty(this.offers)) {
			this.ui.noOffersContainer.removeClass('dn');
			this.ui.emailsContainer.addClass('dn');
			this.ui.formButtons.addClass('disabled');
		} else {
			this.ui.noOffersContainer.addClass('dn');
			this.ui.emailsContainer.removeClass('dn');
			this.ui.formButtons.removeClass('disabled');
		}
	},

	updateOffersOrder() {
		_.each(this.offers, ({hotel, offers}, i) => {
			const $hotelCard = this.$el.find(`[data-hotel="${hotel.number}"]`);
			const $offersCards = $hotelCard.find('.b-modal-hotel__offers-list .b-offers-hotels-modal__list-item');
			const orderDataToken = _.map($offersCards, (o) => o.getAttribute('data-token'));

			const offersSorted = [];
			_.each(orderDataToken, (token) => {
				const offer = _.find(offers, (o) => o.token === token);

				if (offer != null) {
					offersSorted.push(offer);
				} else {
					throw Error('Error order: offer not found in list');
				}
			});

			this.offers[i].offers = offersSorted;
		});

		this.options.parent.approvalOffers = this.offers;
	},

	enableDragSort(listClass) {
		const sortableLists = this.$el.find(listClass);
		_.each(sortableLists, (list) => this.enableDragList(list));
	},

	enableDragList(list) {
		_.each(list.children, (item) => this.enableDragItem(item));
	},

	enableDragItem(item) {
		$(item).attr('draggable', true);

		item.ondrag = GlUl.handleDrag.bind(this);
		item.ondragend = this.handleDrop.bind(this);
		item.ondragover = (e) => e.preventDefault();
	},

	handleDrop(e) {
		const $item = $(e.currentTarget);
		$item.removeClass('drag-sort-active');

		this.updateOffersOrder();
	},

	// EVENTS
	removeOffer(e) {
		if (e != null) {
			e.preventDefault();
		}

		const $target = $(e.currentTarget);
		const $offerContainer = $target.closest('.b-offers-hotels-modal__list-item');
		const $commentRow = $offerContainer.siblings('.b-offers-hotels-modal__list-item-comment').first();
		const $hotelContainer = $target.closest('.b-modal-hotel');

		const offerToken = $offerContainer.attr('data-token');
		const offerIndex = parseInt($offerContainer.attr('data-room-index'));
		const hotelNumber = $hotelContainer.attr('data-hotel');

		const indexHotel = _.findIndex(this.offers, (el) => el.hotel.number === hotelNumber);

		if (indexHotel === -1) {
			return this;
		}

		const hotel = this.offers[indexHotel].hotel;

		const offers = _.filter(this.offers[indexHotel].offers, (o) => {
			return offerIndex ? o.roomIndex !== offerIndex : o.token !== offerToken;
		});

		this.offers[indexHotel].offers = offers;

		$offerContainer.remove();
		$commentRow.remove();
		if (_.isEmpty(offers)) {
			$hotelContainer.remove();
			this.offers.splice(indexHotel, 1);
			if (hotel != null && hotel.number) {
				$(`.b-ticket-offer__cancel[data-token="${_.escape(hotel.number)}"]`).first().trigger('click');
			}
		}

		// Change button on the card
		if (offerToken) {
			$(`.b-ticket-offer__cancel[data-token="${_.escape(offerToken)}"]`).first().trigger('click');
		}

		this.options.parent.approvalOffers = this.offers;
		this.checkOffersCount();

		return this;
	},

	removeHotel(e) { // XTR-11014
		if (e != null) {
			e.preventDefault();
		}

		const $target = $(e.currentTarget);
		const $hotelOffersRemoveBtn = $target.closest('.b-modal-hotel').find('.b-offers-hotels-modal__list-item-remove');
		_.each($hotelOffersRemoveBtn, (btn) => btn.click());
	},

	submitOrder(e) {
		this.orderOffers(e, _.extend({ sendEmail: true }));
	},

	getVariants() {
		const roomsCount = this.options.parent.roomsCount || 1;
		const variants = [];
		_.each(this.offers, (hotel, i) => {
			let k = 0;
			const offersChunks = _.chunk(hotel.offers, roomsCount);
			offersChunks.forEach((offerChunk) => {
				const approveOffers = [];
				offerChunk.forEach((offer) => {
					offer.comments = this.model.get(`comments${i}${k}`);
					approveOffers.push({ hotelOffer: offer, comments: offer.comments });
					k++;
				});
				variants.push({ approveOffers });
			});
		});
		return variants;
	},

	orderOffers(e, opts) {
		if (e != null) {
			e.preventDefault();
		}

		const $target = $(e.currentTarget);
		if ($target.hasClass('disabled')) {
			return this;
		}

		STATE.showLoader();
		this.disableElements(e);

		const variants = this.getVariants();

		const emails = this.model.get('emails').toJSON();

		axios.post('/midoffice/ibecorp-b2b/hotels/sendToApprove', {
			parameters: _.extend({
				variants,
				emails,
				sendEmail: false,
			}, opts),
		}, this.model).then((result) => {
			STATE.hideLoader();
			this.ticketModalOffers.hide();
			let popup;
			const messages = result.data.result.messages;
			if (result.data.result.skipGetBookingInfo) {
				if (!_.isEmpty(messages)) {
					popup = new Widgets.Popup({
						content: messages.length && messages.join('</br>'),
						type: 'info',
						actions: [
							{
								label: L10N.get('bookingForm.dupesPopup.back'),
								action: () => {
									popup.hide();
								},
							},
						],
					});
					popup.show();
				}
				return this;
			} else if (!_.isEmpty(messages)) {
				popup = new Widgets.Popup({
					type: 'info',
					content: messages.length && _.map(messages, (m) => m.text).join('<br/>'),
					actions: [
						{
							label: L10N.get('Common.close'),
							action: () => {
								STATE.navigate([STATE.ROUTES.CABINET_ORDER, result.data.result.newBooking.uid].join('/'));
								popup.hide();
							},
						},
					],
				});
				popup.show();
				return this;
			}
			STATE.navigate([STATE.ROUTES.CABINET_ORDER, result.data.result.newBooking.uid].join('/'));

			return this;
		});

		return this;
	},

	copyToClipboard(e) {
		if (e) e.preventDefault();
		let result = '';
		const containers = this.$el.find('[data-copy-container]');
		_.each(containers, (el) => {
			result += `${GlUl.gatherTextToCopyToClipBoard(el)}\n\n`;
		});
		logger.debug(result);
		GlUl.copyToClipboard(result);
	},

});
