/* eslint-disable banned-modules */

'use strict';

export const LEVEL = {
	ERROR: 5,
	WARN: 4,
	INFO: 3,
	DEBUG: 2,
	LOG: 1,
};

const dateFormat = '[[]DD/MM HH:mm:ss SSS[]]';
// const allowLevel = cookies.get('logLevel');

class Logger {
	static log(_level, ...args) {
		const time = new Time().format(dateFormat);
		const level = _level || 1;

		// for old browser
		if (!window.console) {
			return;
		}

		if (LOG_LEVEL >= level) {
			return;
		}

		const stackTrace = new Error();

		/*eslint-disable */
		switch (level) {
			case LEVEL.ERROR:
				console.error.apply(console, [time, ...args, stackTrace]);
				break;
			case LEVEL.WARN:
				console.warn.apply(console, [time, ...args, stackTrace]);
				break;
			case LEVEL.INFO:
				console.info.apply(console, [time, ...args, stackTrace]);
				break;
			case LEVEL.DEBUG:
				// console.log.apply(console, [time, ...args, stackTrace]);
				console.log.apply(console, [time, ...args]);
				break;
			case LEVEL.LOG:
				console.log.apply(console, [time, ...args, stackTrace]);
				break;
			default:
				console.log.apply(console, [time, ...args, stackTrace]);
				break;
		}
		/*eslint-enable */
	}

	static error(...args) {
		Logger.log.apply(Logger, [LEVEL.ERROR, ...args]);
	}

	static warn(...args) {
		Logger.log.apply(Logger, [LEVEL.WARN, ...args]);
	}

	static info(...args) {
		Logger.log.apply(Logger, [LEVEL.INFO, ...args]);
	}

	static debug(...args) {
		Logger.log.apply(Logger, [LEVEL.DEBUG, ...args]);
	}
}

// Fix for ProvidePlugin which properly works only with old module definition
/*eslint-disable */
// module.exports = Logger;
/*eslint-enable */

export default Logger;
