module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-selection__value">\n	';

		var count = parseInt(count, 10);
		if (icon) {
			var repeat = repeat || 1;
			caption = Array.apply(null, Array(repeat)).map(function() {
				return '<span class="' + icon + '"></span>';
			}).join('');
		}
		caption = STATE.escape(JSON.stringify(caption));
	;
__p += '\n	<span class="js-widget"  onclick=\'return new Widgets.Checkbox({ bindingProperty: "value", label: ' + caption + ', disabled: ' + disabled + ' })\'></span>\n\n	';
 if (!_.isNaN(count) && count > 0) { ;
__p += '\n		<div class="b-selection__attribute">' +
((__t = ( count )) == null ? '' : __t) +
'</div>\n	';
 } ;
__p += '\n</div>\n';

}
return __p
}