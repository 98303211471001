import React, { useContext, useMemo, useRef, useState } from "react";
import {
	ReactAppGlobalContext,
	ReactUtils,
} from "../../../../../js/utils/react-utils";
import { PaginationContext } from "../Pagination";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";

const PageSize: React.FC<unknown> = () => {
	const { L10N } = useContext(ReactAppGlobalContext);
	const { pageSize, setPageSize, setCurrentPage } =
		useContext(PaginationContext);

	const [opened, setOpened] = useState<boolean>(false);

	const sizes = useMemo(() => {
		return [10, 25, 50, 100, 200];
	}, []);

	const containerRef = useRef<HTMLDivElement>(null);

	useOnClickOutside(containerRef, () => setOpened(false));

	return (
		<div
			ref={containerRef}
			className={ReactUtils.classNames([
				"b-footer-show-select",
				opened ? "open" : undefined,
			])}
			onClick={() => {
				if (!opened) {
					setOpened(true);
				}
			}}
		>
			<div className="b-footer-show-label">
				{L10N.get('Widgets.pagination.showBy')} <span className="js-page-size-value">{pageSize}</span>
			</div>
			<div className={"b-footer-show-source"}>
				<ul>
					{sizes.map((size: number) => {
						return (
							<li
								key={size}
								onClick={() => {
									setCurrentPage && setCurrentPage(0);
									setPageSize && setPageSize(size);
									setOpened(false);
								}}
							>
								{size}
							</li>
						);
					})}
				</ul>
				<div className="b-footer-show-more desktop-hide">
					{L10N.get("cabinet.table.footer.showMore")}
				</div>
			</div>
		</div>
	);
};

export default PageSize;
