'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';

export default Widget.extend({

	template,

	events: {
		'change .b-checkbox__input': 'change',
	},

	ui: {
		input: '.b-checkbox__input',
	},

	initialize() {
		this.render(_.extend({
			label: '',
		}, this.options));

		if (String(this.options.disabled) === 'true') {
			this.disable(true);
		}
	},

	disable(disabled) {
		this.disabled = disabled;
		this.ui.input.attr('disabled', disabled);
	},

	change() {
		this.model.set(this.options.bindingProperty, this.ui.input.is(':checked'));
	},

	applyBinding(model) {
		this.model = model;
		if (this.model.get(this.options.bindingProperty)) {
			this.ui.input.prop('checked', true);
		} else {
			this.ui.input.prop('checked', false);
		}

		this.listenTo(model, `validationError:${this.options.bindingProperty}`, () => {
			this.$el.addClass('b-checkbox__validation-error');
		});

		this.listenTo(model, `change:${this.options.bindingProperty}`, (changedModel, value) => {
			this.ui.input.prop('checked', !!value);
		});
	},

});
