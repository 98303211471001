// eslint-disable-next-line banned-modules
'use strict';

import axios from 'axios';
import Buffer from 'buffer';
import $ from 'jquery';
import cookies from 'js-cookie';

axios.interceptors.request.use((config) => {
	const hasHistory = window.ErrorHandler && window.ErrorHandler.hashHistory || [];

	if (window.UUIDGenerator != null) {
		window.UUIDGenerator.refreshClientOperationToken();
	}
	config.headers['X-Reference-History'] = !_.isEmpty(hasHistory) ? JSON.stringify(hasHistory.slice(-10)) : '';
	config.headers['X-Reference'] = window.location;

	return config;
}, (error) => {
	throw error;
});

let previousMessageError = '';
const _SAVED_AXIOS_PROTO = {};
['post', 'get', 'put', 'delete', 'options', 'head', 'connect', 'patch'].forEach(type => {
	_SAVED_AXIOS_PROTO[type] = axios.Axios.prototype[type];

	axios[type] = axios.Axios.prototype[type] = function a(url, params, model, axiosParams = {enableCashing: false}) {
		params = params || {};
		const enableCashing = axiosParams.enableCashing;
		delete axiosParams.enableCashing;
		if (url.indexOf('?locale') < 0) {
			if (url.indexOf('?') < 0) {
				url = `${url}?locale=${STATE.getLanguage()}`;
			} else {
				url = `${url}&locale=${STATE.getLanguage()}`;
			}
		}
		if (window.SERVER_URL) {
			params.withCredentials = true;
			url = window.SERVER_URL + url;
		}
		const savedXhr = STATE.getSavedXhr({url, ...params});
		return new Promise((resolve, reject) => {
			let source;
			if (enableCashing) {
				const cancelToken = axios.CancelToken;
				source = cancelToken.source();
				axiosParams = _.extend({}, axiosParams, {cancelToken: source.token});
				if (savedXhr) {
					if (typeof savedXhr === 'object') {
						resolve(savedXhr);
						return;
					}
				} else {
					STATE.saveXhr({url, ...params});
				}
			}
			_SAVED_AXIOS_PROTO[type].call(axios, url, params, axiosParams)
				.then((response) => {
					if (response.data && response.data.validatedParameters) {
						$('.validation-error').removeClass('validation-error');
						STATE.hideLoader();
						if (model) model.handleValidation(response.data.validatedParameters);
						return reject({
							validatedParameters: response.data.validatedParameters,
							error: 'validationError',
						});
					}
					if (response.data && response.data.result && response.data.result.updated === true) {
						const popup = new Widgets.Popup({
							type: 'info',
							content: `
								<div class="b-popup-successful__content">
									${L10N.get('cabinet.successfulSavedPopup.content')}
								</div>
							`,
							actions: [{
								label: L10N.get('cabinet.orders.close'),
								action: () => {
									popup.hide();
								},
							}],
						});
						popup.show();
					}
					if (enableCashing) STATE.saveXhr({url, ...params}, response);
					return resolve(response);
				}).catch((...args) => {
					STATE.hideLoader();
					logger.error(...args);

					if (args[0] instanceof axios.Cancel) {
						return;
					}
					// args[0].response is undefined in case on network error
					if (args[0].response == null) {
						// IBECORP-4284 ->
						if (args[0].message.includes('Network Error')) {
							args[0]._ajaxErrorHandled = true;
						}
						// | IBECORP-4284
						if (previousMessageError !== args[0].message) {
							reject(args[0]);
						}
						previousMessageError = args[0].message;
						return;
					}

					const status = args[0].response.status;
					const config = args[0].response.config;
					let errorData = args[0].response.data;

					// handle error for responseType - ArrayBuffer
					if (config != null && config.responseType === 'arraybuffer'
						&& toString.call(errorData) === '[object ArrayBuffer]') {
						try {
							const bufferStr = new Buffer.Buffer(errorData);
							errorData = JSON.parse(bufferStr);
						} catch (e) {
							/* no-op */
						}
					}

					let errorMessage = errorData.userErrorMessage || L10N.get('settings.unknownError');
					const {showAdminExceptions, showDeveloperExceptions} = STATE.getSettings() || {};
					if (showDeveloperExceptions && errorData.developerErrorMessage) errorMessage = `${errorData.developerErrorMessage}`;
					if (showAdminExceptions && errorData.adminErrorMessage) errorMessage = `${errorData.adminErrorMessage}`;
					// if (status === 401 || (status === 403 && errorData.errorMessageType !== 'ADMIN')) { IBECORP-3512

					if ((status === 401 || status === 403)) {
						// IBECORP-3732 ---->
						if (STATE.get403_HANDLED()) return;
						STATE.set403_HANDLED(true);
						setTimeout(() => STATE.set403_HANDLED(false), 5000);
						// <--- IBECORP-3732

						// IBECORP-3866 ---->
						errorMessage = errorData.userErrorMessage != null ? errorData.userErrorMessage : L10N.get('settings.unauthorizedError');
						// <---- IBECORP-3866
						cookies.remove('IBECORP_B2B_AUTH_TOKEN_COOKIE');

						STATE.logout();
						if (STATE.getPageView() != null) {
							STATE.getPageView().$el.hide();
						} else if (STATE.getLoginUser() == null) {
							return;
						}
					}

					const isDeveloperError = (errorData.errorMessageType === 'DEVELOPER');
					const isAdminError = (errorData.errorMessageType === 'ADMIN');
					let isUserError = (errorData.errorMessageType === 'USER');
					const fillExtendParams = _.some([showDeveloperExceptions, showAdminExceptions]);
					let extendParams = !fillExtendParams ? {} : _.pick(errorData, 'exceptionStackTrace', 'exceptionMessage');
					let title = isUserError ? L10N.get('settings.userErrorPopupTitle') : L10N.get('settings.errorPopupTitle');

					let errorPopup; // <- объявляем переменную заранее, чтобы actionsBtns могли на что-то ссылаться

					let popupType = 'danger';
					const actionsBtns = [
						{
							label: L10N.get('settings.errorPopupButton'),
							action: () => {
								errorPopup.hide();
							},
						},
					];

					if (status === 400 && errorData.developerErrorMessage && errorData.developerErrorMessage.includes(L10N.get('settings.orderAuthorized'))) {
						errorMessage = errorData.developerErrorMessage;
						title = ' ';
						extendParams = {};
						isUserError = true;
					}

					if (!_.isEmpty(extendParams)) {
						actionsBtns.push({
							label: L10N.get('Common.errorDetails'),
							action: () => {
								errorPopup.showDetails();
							},
						});
					}

					if (status === 400 && errorData?.errorSeverity) {
						popupType = Widgets.Popup.getErrorPopupType(errorData.errorSeverity);
						if (errorData.errorSeverity !== 'ERROR' && actionsBtns.length === 2) {
							actionsBtns.pop();
						}
					}

					if (status === 503) {
						title = L10N.get('Common.technicalBreak');
						errorMessage = L10N.get('Common.pleaseRefreshPage');
					}

					errorPopup = new Widgets.Popup(_.extend({
						errorMessage,
						title,
						isUserError,
						isAdminError,
						isDeveloperError,
						type: popupType,
						actions: actionsBtns,
						classes: 'b-error-popup',
					}, extendParams));
					errorPopup.show();

					args[0]._ajaxErrorHandled = true;
					reject(args[0]);
				});
			if (source && savedXhr === 'PENDING') {
				source.cancel(L10N.get('Common.requestAlreadyWasSent'));
			}
		});
	};
});

export default {};
