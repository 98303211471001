// eslint-disable-next-line banned-modules
'use strict';

import '../style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';
import $ from 'jquery';

export default BaseView.extend({

	className: 'b-search-route-address__collection-element',

	events: {
		'click .b-search-route-address__remove': 'delete',
		'click .p-search__direction-swap': 'swapLocations',
	},

	initialize(options) {
		this.searchModel = options.searchModel;
		this.render();
	},

	swapLocations() {
		this.searchModel.swapLocations();
	},

	delete() {
		this.model.destroy();

		const $target = $('.b-search-route-address__collection__add');
		if (this.searchModel.get('subAddresses').length < 6 && $target.hasClass('disabled')) {
			if ($target.attr('data-origin-title')) {
				$target
					.removeClass('disabled')
					.children('a')
					.text($target.attr('data-origin-title'));
			}
		}
	},

	render() {
		const tpl = template;

		this.setElement(tpl.call(this, {
			classes: this.options.classes,
			searchModel: this.searchModel,
			cid: this.options.cid,
		}));
		return this;
	},

});
