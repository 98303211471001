// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import suggestionTemplate from './suggestion.ejs';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';
import 'corejs-typeahead/dist/typeahead.jquery';
import axios from 'axios';

export default Widget.extend({

	validationTemplate,

	template,

	viewName: 'b-company-autocomplete',

	initialize(options) {
		this.options = options;
		this.options.label = this.options.label || '';
		this.options.placeholder = this.options.placeholder || '';
		this.options.deletableField = (this.options.deletableField === true);
		this.queryHandler = this.queryHandler.bind(this);
		this.render();

		this.$typeahead = this.$('.b-company-autocomplete__value');
		this.$typeahead.typeahead({
			hint: false,
			highlight: false,
			minLength: 0,
			limit: 1,
			classNames: {
				menu: 'b-company-autocomplete__items',
				wrapper: 'b-company-autocomplete__wrapper',
				input: 'b-company-autocomplete__input',
				hint: 'b-company-autocomplete__hint',
				dataset: 'b-company-autocomplete__dataset',
				suggestion: 'b-company-autocomplete__item',
				selectable: 'b-company-autocomplete__selectable',
				empty: 'b-company-autocomplete__empty',
				open: 'b-company-autocomplete__open',
				cursor: 'b-company-autocomplete__cursor',
				highlight: 'b-company-autocomplete__highlight',
			},
		}, {
			name: 'company',
			source: (q, cb, async) => {
				clearTimeout(this.timer);
				this.timer = setTimeout(this.queryHandler, STATE.QUERY_DELAY, q, cb, async);
			},
			limit: 100,
			display: (item) => `${item.caption}`,
			async: true,
			templates: {
				suggestion: suggestionTemplate,
			},
		});
	},

	queryHandler(q, cb, async) {
		const params = {
			pattern: encodeURI(q),
			limit: null,
			withoutRetailClient: this.options.withoutRetailClient === true,
		};

		if (this.options.useAcl) {
			params.useAcl = this.options.useAcl === true;
		}
		if (this.options.findOnlyAgencies) {
			params.findOnlyAgencies = this.options.findOnlyAgencies === true;
		}

		axios.get('/midoffice/ibecorp-b2b/autocomplete/organizations', {
			params,
		})
			.then((resp) => {
				logger.debug(params);
				let response = resp.data.result;
				if (_.isFunction(this.options.formatter)) {
					response = this.options.formatter.call(this, response);
				}
				async(response.reduce((result, item) => {
					result.push(item);
					return result;
				}, []));
				this.$('[data-toggle="tooltip"]').tooltip({
					container: 'body',
					trigger: 'hover',
				});
			});
	},

	applyBinding(model) {
		this.$typeahead.on('typeahead:change', (ev, suggestion) => {
			if (suggestion.replace(/\s/g, '').length === 0) {
				this.model.set(this.options.bindingProperty, null);
			}
		});

		this.$typeahead.on('typeahead:select', (ev, suggestion) => {
			this.model.set(this.options.bindingProperty, suggestion);
		});

		this.listenTo(model, `validationError:${this.options.bindingProperty}`, () => {
			this.$el.addClass('validation-error');
		});

		this.listenTo(model, `change:${this.options.bindingProperty}`, (__model, item) => {
			this.$typeahead.typeahead('val', (item || {}).caption);
		});

		this.listenTo(model, `destroy`, () => this.$el.tooltip('dispose'));

		if (_.isObject(model.get(this.options.bindingProperty))) {
			this.$typeahead.typeahead('val', model.get(this.options.bindingProperty).caption);
		}

		this.$('.b-input__remove').on('click', () => this.removeItem(model));
	},

	removeItem(model) {
		model.set(this.options.bindingProperty, null);
	},

});
