module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form autocomplete="on" class="p-login__form-auth">\r\n		<span class="js-widget"  onclick=\'return new Widgets.Login({bindingProperty: "login", type: "text", classes: "'+widgetClasses+'"})\'></span>\r\n		<span class="js-widget"  onclick=\'return new Widgets.Password({bindingProperty: "password", type: "password", classes: "'+widgetClasses+'"})\'></span>\r\n\r\n	<div class="p-login__form-button-container">\r\n		<div class="p-login__form-button">\r\n			<a href="#" class="p-login__form-button-link js-restore-link" tabindex="-1">' +
((__t = ( L10N.get('AccountLoginForm.passwordRecovery') )) == null ? '' : __t) +
'</a>\r\n		</div>\r\n        ';
 if (STATE.checkSiteType('B2C')) { ;
__p += '\r\n		    <div class="p-login__form-button">\r\n			    <a href="#" class="p-login__form-button-link js-registration-link" tabindex="-1">' +
((__t = ( L10N.get('AccountLoginForm.registration') )) == null ? '' : __t) +
'</a>\r\n		    </div>\r\n        ';
 } ;
__p += '\r\n	</div>\r\n</form>\r\n';

}
return __p
}