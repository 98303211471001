module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-ticket-leg__extended-inner">\n	';

	for (var j = 0; j < leg.segments.length; j++ ) {
		var segment = leg.segments[j];
		var segmentDateDeparture = segment.departureDate ? new Time(segment.departureDate) : null;
		var segmentDateArrival = segment.arrivalDate ? new Time(segment.arrivalDate) : null;
		var segmentTravelTime = Time.getZeroTime().add(segment.travelDuration, 'm');
	;
__p += '\n		';
 if (j > 0) { ;
__p += '\n			';

				var prevStopDuration = new Time(leg.segments[j - 1].arrivalDate);
			 	var stopDuration = segmentDateDeparture.subtract(prevStopDuration.getMoment().toDate().getTime(), 'ms').getMoment().toDate().getTime() / 1000 / 60;
			 	var stopType = 'short';
			 	if (stopDuration > 2*60) {
					stopType = 'medium';
				}
			 	if (stopDuration > 6*60) {
					stopType = 'long';
				}
 			;
__p += '\n			<div class="b-ticket-stop b-ticket-stop-' +
((__t = (stopType)) == null ? '' : __t) +
'">\n				<span class="b-ticket-section__travel-time b-ticket-stop__time">' +
((__t = (Time.toTravelTime(stopDuration))) == null ? '' : __t) +
'</span>\n				<span class="b-ticket-stop__info">' +
((__t = (L10N.get("flightlegs.stop" + stopType))) == null ? '' : __t) +
'</span>\n			</div>\n		';
 } ;
__p += '\n		';
 if (segment.techStopInfos != null && segment.techStopInfos.length > 0) { ;
__p += '\n			<h1>' +
((__t = ( L10N.get('flightlegs.techStop') )) == null ? '' : __t) +
'</h1>\n			<div class="b-ticket-stop b-ticket-stop-long">\n				<span class="b-ticket-section__travel-time b-ticket-stop__time">zzz</span>\n				<span class="b-ticket-stop__info">yyy ' +
((__t = ( segment.techStopInfos[0].location.caption )) == null ? '' : __t) +
'</span>\n			</div>\n\n		';
 } ;
__p += '\n\n		<div class="b-ticket-section">\n			<span class="b-ticket-section__decoration"></span>\n			<span class="b-ticket-section__travel-time">' +
((__t = ( segmentTravelTime.toTravelTime() )) == null ? '' : __t) +
'</span>\n			<div class="b-ticket-section__place">\n				<span class="b-ticket-section__date">\n					<span class="b-ticket-section__time">' +
((__t = ( segmentDateDeparture && segmentDateDeparture.toShortTime() || '--' )) == null ? '' : __t) +
'</span>\n					' +
((__t = ( segmentDateDeparture && segmentDateDeparture.toMonthDay() || '--' )) == null ? '' : __t) +
'\n				</span>\n				';
if (segment.departureCity) { ;
__p += '\n					' +
((__t = ( segment.departureCity.caption )) == null ? '' : __t) +
',\n				';
 } ;
__p += '\n				';
if (segment.departureAirport) { ;
__p += '\n					' +
((__t = ( segment.departureAirport.caption )) == null ? '' : __t) +
'\n				';
 } ;
__p += '\n				<span class="b-ticket-section__place-iata">(' +
((__t = ( segment.departureAirport.uid )) == null ? '' : __t) +
')</span>\n			</div>\n			<div class="b-ticket-section__info">\n				<div class="b-ticket-section__info-line">\n					<div class="b-ticket-section__info-logo">\n						<span class="b-ticket-header__compay-logo g-icon " style="background-image: url(\'/assets/img/logos/' +
((__t = ( segment.airline && segment.airline.airlineCode )) == null ? '' : __t) +
'-black.svg\')"></span>\n					</div>\n					<div class="b-ticket-section__info-data">\n						<ul class="b-ticket-section__info-flight">\n							<li class="b-ticket-section__info-flight-item">\n								' +
((__t = ( L10N.get("flightlegs.airline") )) == null ? '' : __t) +
' : ' +
((__t = ( segment.operatingAirlineCompanyName != null ? segment.operatingAirlineCompanyName : segment.airline && segment.airline.caption )) == null ? '' : __t) +
'\n							</li>\n							<li class="b-ticket-section__info-flight-item">\n								' +
((__t = ( L10N.get("flightlegs.flightNumber") )) == null ? '' : __t) +
' : ' +
((__t = ( segment.airline && segment.airline.airlineCode + segment.flightNumber )) == null ? '' : __t) +
'\n							</li>\n                            <li class="b-ticket-section__info-flight-item">\n                                ' +
((__t = ( L10N.get("flightlegs.aircraft") )) == null ? '' : __t) +
' : ' +
((__t = ( segment.aircraft && segment.aircraft.caption || '-' )) == null ? '' : __t) +
'\n                            </li>\n						</ul>\n						';
 if (segment.operatingAirline) { ;
__p += '\n							<span class="b-ticket-section__info-flight-item-warning">\n								' +
((__t = ( L10N.get("flightlegs.operatingAirline") )) == null ? '' : __t) +
' : ' +
((__t = ( segment.operatingAirline.caption )) == null ? '' : __t) +
'\n							</span>\n						';
 } ;
__p += '\n					</div>\n				</div>\n			</div>\n\n\n			<div class="b-ticket-section__place">\n				<span class="b-ticket-section__date">\n					<span class="b-ticket-section__time">' +
((__t = ( segmentDateArrival && segmentDateArrival.toShortTime() || '--' )) == null ? '' : __t) +
'</span>\n					' +
((__t = ( segmentDateArrival && segmentDateArrival.toMonthDay() || '--' )) == null ? '' : __t) +
'\n				</span>\n				';
if (segment.arrivalCity) { ;
__p += '\n					' +
((__t = ( segment.arrivalCity.caption )) == null ? '' : __t) +
',\n				';
 } ;
__p += '\n				';
if (segment.arrivalAirport) { ;
__p += '\n					' +
((__t = ( segment.arrivalAirport.caption )) == null ? '' : __t) +
'\n				';
 } ;
__p += '\n				<span class="b-ticket-section__place-iata">(' +
((__t = ( segment.arrivalAirport.uid )) == null ? '' : __t) +
')</span>\n			</div>\n		</div>\n	';
 } ;
__p += '\n</div>\n';

}
return __p
}