module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var flight = flight || null;
	var optionClass = typeof option !== 'undefined' ? '-' + option : '';
	var companyForIcon = carrier && carrier.codeSystem !== 'IATA' ? carrier.airlineCode + '-' + carrier.codeSystem : carrier && carrier.airlineCode || 'noCarrierField';
;
__p += '\r\n<div class="b-ticket-header">\r\n	<span class="b-ticket-header__compay-logo g-icon " style="background-image: url(\'/assets/img/logos/' +
((__t = ( companyForIcon )) == null ? '' : __t) +
'' +
((__t = (optionClass)) == null ? '' : __t) +
'.svg\')"></span>\r\n	';
 if (services) { ;
__p += '\r\n		<ul class="b-ticket-header__options">\r\n			';
 _.each(_.keys(services), function (item) { ;
__p += '\r\n				<li\r\n					data-toggle="tooltip" data-placement="top" title="' +
((__t = (L10N.get("flightlegs.servicesInfo." + item + "." + services[item].uid))) == null ? '' : __t) +
'"\r\n					class="b-ticket-header__option g-icon-option-' +
((__t = (item)) == null ? '' : __t) +
'-' +
((__t = (services[item].uid)) == null ? '' : __t) +
'' +
((__t = (optionClass)) == null ? '' : __t) +
'"\r\n				></li>\r\n			';
 }); ;
__p += '\r\n		</ul>\r\n	';
 } ;
__p += '\r\n    ';
 if (flight && (flight.travelPolicyCompliance && !_.isEmpty(flight.travelPolicyCompliance.rulesApplied) || flight.isTripartiteContractDiscountApplied === true)) { ;
__p += '\r\n        <div class="b-travel-policy" style="margin-top: 10px;">\r\n                ' +
((__t = ( require('@/blocks/pages/b-hotels/b-hotels-hotel/b-travel-policy.ejs')({
                    travelPolicyCompliance: flight.travelPolicyCompliance,
                    tripartiteContract: flight.isTripartiteContractDiscountApplied
                }) )) == null ? '' : __t) +
'\r\n        </div>\r\n    ';
 } ;
__p += '\r\n</div>\r\n';

}
return __p
}