module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-transfer-info__wrapper">\n    <div class="b-transfer-info">\n        ';
 if (transferInfo.transferType) { ;
__p += '\n            <div class="b-transfer-info__name">\n                ' +
((__t = ( L10N.get("transfers.type." + transferInfo.transferType.uid) )) == null ? '' : __t) +
'\n\n                ';
 if (pricingInfo && pricingInfo.travelPolicyCompliance && !_.isEmpty(pricingInfo.travelPolicyCompliance.rulesApplied) || transferInfo.tripartiteContract === true) { ;
__p += '\n                    <span class="b-travel-policy">\n                            ' +
((__t = ( require('@/blocks/pages/b-hotels/b-hotels-hotel/b-travel-policy.ejs')({
                                travelPolicyCompliance: pricingInfo.travelPolicyCompliance,
                                tripartiteContract: transferInfo.tripartiteContract
                            }) )) == null ? '' : __t) +
'\n                    </span>\n                ';
 } ;
__p += '\n\n                <div class="b-ticket-info__header-separator"></div>\n            </div>\n        ';
 } ;
__p += '\n        <div class="b-transfer-info__car-class">' +
((__t = ( transferInfo && transferInfo.carClass && transferInfo.carClass.caption )) == null ? '' : __t) +
', \n            ' +
((__t = (  transferInfo &&  transferInfo.capacity && transferInfo.capacity.caption )) == null ? '' : __t) +
'\n        </div>\n\n        ';

            var tripCount = transferInfo.backDate != null ? 2 : 1;
            for (var i = 0; i < tripCount; i++) {
                var startAddress = i === 0 ? transferInfo.startPlace.address : transferInfo.endPlace.address;
                var endAddress = i === 0 ? transferInfo.endPlace.address : transferInfo.startPlace.address;
                var date = i === 0 ? transferInfo.date : transferInfo.backDate;
        ;
__p += '\n                <div class="b-ticket-info__legs">\n                    <div class="b-ticket-info__legs-leg">\n                        <div class="b-ticket-info__legs-leg-info">\n                            <div class="ticket-info__transplants"></div>\n                            <div class="ticket-info__description">\n                                <div class="ticket-info__leg-segment">\n			    				    <span class="ticket-info__place">\n                                        <strong>' +
((__t = ( startAddress )) == null ? '' : __t) +
'</strong>\n                                    </span>\n                                </div>\n                                <div>\n			    				    <span class="ticket-info__date">\n                                        <span class="ticket-info__time">' +
((__t = ( new Time(date).toShortDate() )) == null ? '' : __t) +
'</span>\n			    				    </span>\n                                </div>\n\n                                <div class="ticket-info__leg-segment">\n			    				    <span class="ticket-info__place">\n                                        <strong>' +
((__t = ( endAddress )) == null ? '' : __t) +
'</strong>\n                                    </span>\n                                </div>\n                                <div>\n                                    <span class="ticket-info__date">\n                                        <strong class="ticket-info__time">' +
((__t = ( new Time(date).toShortDate() )) == null ? '' : __t) +
'</strong>\n                                    </span>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n        ';
 } ;
__p += '\n    </div>\n    <div class="b-transfer-info__pricing"></div>\n</div>\n\n\n\n';

}
return __p
}