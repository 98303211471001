// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

export default BaseView.extend({

	el: '.b-attachment',

	template,

	initialize() {
		this.model = this.options.model;
		this.render();

		this.listenTo(this.model, 'change:isComplex', (model, value) => {
			const $groupContainer = this.$el.find('.js-attachment-avia-group');
			if (value === true) {
				this.model.changeType('MULTISTOP');
				$groupContainer.addClass('b-attachment-avia__group--complex');
			} else {
				this.model.changeType('ONEWAY');
				$groupContainer.removeClass('b-attachment-avia__group--complex');
			}
		});

		this.listenTo(this.model, 'change:routeType', (model, value) => {
			const $roundtripBlock = this.$el.find('.js-route-type-roundtrip');
			if (value === 'ROUNDTRIP') {
				$roundtripBlock.show();
			} else {
				$roundtripBlock.hide();
			}
		});

		this.listenTo(this.model, 'change:validationErrors', (model, v) => { 
			const errors = v?.validatedParameters;
			if (errors && errors.routes) {
				_.each(_.keys(errors.routes), (key) => {
					const validation = errors.routes[key];
					if (key === '0') {
						if (validation.airline) {
							this.model.triggerValidation('airlineTo', validation.airline);
						}
						if (validation.flightNumber) {
							this.model.triggerValidation('flightNumberTo', validation.flightNumber);
						}
					} else if (key === '1') {
						if (validation.airline) {
							this.model.triggerValidation('airlineFrom', validation.airline);
						}
						if (validation.flightNumber) {
							this.model.triggerValidation('flightNumberFrom', validation.flightNumber);
						}
					}
				});
			}
		});
	},

	isAdditionalParamsBlockExpanded() {
		return this.model.get('airlineFrom')
			|| this.model.get('airlineTo')
			|| this.model.get('time')
			|| this.model.get('timeTo');
	},

});
