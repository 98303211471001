// eslint-disable-next-line banned-modules
'use strict';

import BaseView from '@/classes/base.view';
import template from './template.ejs';
import './style.less';
import TripView from './b-transfer-trip/index';
import TripModel from './model';

export default BaseView.extend({

	template,

	ui: {
		tripsContainer: '.b-transfers__ride-details__trips',
	},

	initialize(options) {
		this.options = options;
		this.parent = options.parent;
		this.isRoundTrip = (this.options.isRoundTrip === true);

		if (!this.model) {
			this.model = this.options.model;
		}

		this.render();
		this.addEventListeners();
	},

	render(args) {
		BaseView.prototype.render.apply(this, args);

		const {to, from, passengersAmountCollection, isRoundTrip, tripInfo} = this.options || {};
		const trips = this.model.get('trips');

		_.each([to, from], (trip, index) => {
			if (!_.isEmpty(trip)) {
				const existedTrip = trips.at(index);
				const date = index === 1 ? tripInfo.backDate : tripInfo.date;
				const location = {
					start: tripInfo.startPlace,
					end: tripInfo.endPlace,
				};
				const model = !_.isEmpty(existedTrip) ? existedTrip : new TripModel({
					isRoundTrip,
					location,
					date,
				});
				const view = new TripView({
					travellersCount: tripInfo.travellersCount,
					baseView: this.parent,
					passengersAmountCollection,
					isRoundTrip,
					location,
					index,
					model,
					date,
					trip,
				});
				this.ui.tripsContainer.append(view.$el);
				trips.add(model);
			}
		});
		return this;
	},

	addEventListeners() {
		const parentModel = this.model;
		const models = this.model.get('trips').models;
		const validateHandler = this.parent.validateAsync.bind(this.parent);
		_.each(models, (model) => {
			this.listenTo(model.get('start'), `change`, validateHandler);
			this.listenTo(model.get('end'), `change`, validateHandler);
			this.listenTo(model, 'change:travellersCount', (_thisModel, travellersCount) => {
				const travellers = parentModel.get('travellers');
				travellers.trigger('travellersCountChange', Number(travellersCount.uid));
			});
		});
	},
});
