'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import BaseModel from '@/classes/base.model';
import template from './template.ejs';

import axios from 'axios';
import $ from 'jquery';

export default BaseView.extend({

	template,

	el: 'body',

	events: {
		'click .js-confirm': 'confirmRefundExchange',
		'click .js-cancel': 'close',
	},

	ui: {
		confirmButton: '.js-confirm',
	},

	initialize(options) {
		this.options = options;
		this.isExchange = options.isExchange;
		this.model = new BaseModel();
		this.parent = options.parent;

		this.services = _.filter(_.map(options.productUids, (uid) => _.find(options.reservation.services, (s) => s.uid === uid)),
			(item) => item != null);

		this.model.set('selectedServices', options.selectedServices);
		this.refund = options.refund;
		const serviceCollection = options.selectedServices[this.options.reservation._index];
		_.each(serviceCollection, (model) => {
			this.listenTo(model, 'change:selected', () => this.model.trigger('change:selectedServices'));
		});

		this.listenTo(this.model, 'change:selectedServices', () => {
			if (_.some(_.keys(serviceCollection), (key) => serviceCollection[key].get('selected') === true)) {
				this.ui.confirmButton.removeAttr('disabled');
				this.ui.confirmButton.removeClass('disabled');
			} else {
				this.ui.confirmButton.attr('disabled', 'disabled');
				this.ui.confirmButton.addClass('disabled');
			}
		});

		this.render();
	},

	render(...args) {
		BaseView.prototype.render.call(this, ...args);
		return this;
	},

	confirmRefundExchange(e) {
		const reservationIndex = $(e.currentTarget).data('reservationId');
		const servicesCollection = this.model.get('selectedServices')[reservationIndex];
		const productUids = [];
		const selectedServices = [];
		STATE.showLoader();
		_.each(servicesCollection, (model, key) => {
			if (model.get('selected') === true) {
				productUids.push(key);
				const service = _.find(this.options.reservation.services, (s) => s.uid === key);
				if (service != null) {
					selectedServices.push(service);
					if (!_.isEmpty(service._mco)) productUids.push(..._.map(service._mco, (s) => s.uid));
				}
			}
		});

		if (this.isExchange) {
			const exchangeParameters = {
				parameters: {
					productUids,
					booking: {
						uid: this.options.booking.bookingInfo.bookingUid,
					},
					route: this.parent.buildExchangeRouteParameter(this.options.reservation),
				},
				order: this.parent.options.order,
				selectedServices,
				reservation: this.options.reservation,
				isExchange: this.isExchange,
			};

			axios.post('/midoffice/ibecorp-b2b/booking/exchange/flights', {parameters: exchangeParameters.parameters})
				.then((result) => {
					STATE.hideLoader();
					STATE.setSearchResult(_.extend({}, {flights: result.data.result}, {exchangeParameters}));
					STORE.set(STATE.ROUTES.MAIN_SEARCH, STATE.getSearchModel().attributes, 14400);
					STATE.setFormState('avia:filter', null);
					STATE.navigate('avia/tickets');
				});

			this.close();
			return;
		}

		const parameters = {
			parameters: {
				booking: {
					uid: this.options.booking.bookingInfo.bookingUid,
				},
				financeDetailsToken: this.refund.financeDetailsToken,
				productUids,
			},
		};

		STATE.showLoader();
		axios.post('midoffice/ibecorp-b2b/booking/refund', parameters).then(() => {
			window.location.reload();
		});
	},

	close() {
		this.$el.remove();
		this.destroy();
	},
});
