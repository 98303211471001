module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-cabinet-order-auth-step-unit__status-chip">\n  <span class="b-cabinet-order-auth-step-unit__status-chip-icon"></span>\n  <span>' +
((__t = ( unitStatus && unitStatus.caption || "" )) == null ? '' : __t) +
'</span>\n  ';
 if (operationDate != null) { ;
__p += '\n    <span class="b-cabinet-order-auth-step-unit__status-chip-date">' +
((__t = ( new Time(operationDate).format('DD.MM.YYYY HH:mm') )) == null ? '' : __t) +
'</span>\n  ';
 } ;
__p += '\n</div>';

}
return __p
}