module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var transferType = transferType || {};
;
__p += '\n<!--<div class="p-search-form__row b-search-route__transfer-type">-->\n<!--    <span class="js-widget"  onclick=\'return new Widgets.Select({ bindingProperty: "transferType", values: "' + transferTypes + '", label: L10N.get("transfers.transferType"), classes: "b-search-route__transfer-select" })\'></span>-->\n<!--</div> // IBECORP-5706 -->\n\n<div class="p-search__direction">\n    <span class="js-widget"  onclick=\'return new Widgets.GeocoderInput({ bindingProperty: "startPlace", placeholder: L10N.get("transfers.startPlace"), label: "" })\'></span>\n    ';
 if (transferType.uid === 'TRANSFER') { ;
__p += '\n    <div class="p-search__direction-swap">\n        <div class="p-search__direction-swap-img"></div>\n    </div>\n    <div class="b-search-route-address__collection">\n        <div class="b-search-route-address__collection-element">\n            <span class="js-widget"  onclick=\'return new Widgets.GeocoderInput({ bindingProperty: "endPlace", placeholder: L10N.get("transfers.endPlace"), label: "" })\'></span>\n        </div>\n    </div>\n    ';
 } ;
__p += '\n</div>\n\n<div class="p-search-form__element__dates-container">\n    <div class="p-search__options-date">\n        <div class="p-search__options-date-element pikaday-modal_show-date-to">\n            <span class="js-widget"  onclick=\'return new Widgets.Datepicker({ bindingProperty: "date", dateFormat: "DD MMM", placeholder: L10N.get("transfers.fromDate"), pikadayModalCid: "'+ cid +'" })\'></span>\n            ';
 if (transferType.uid === 'RENT') { ;
__p += '\n            <div class="p-search__options-time p-search__options-time_position p-search__options-time-from js-show-time-picker" style="' +
((__t = ( transferType && transferType.uid === 'RENT' ? '' : 'display: none')) == null ? '' : __t) +
'">\n                <div class="p-search__options-bridge"></div>\n                <span class="js-widget"  onclick=\'return new Widgets.Time({ bindingProperty: "time", classes: "p-search__input" })\'></span>\n            </div>\n            ';
 } ;
__p += '\n        </div>\n        <div class="p-search__options-date-element pikaday-modal_show-date-from">\n            <span class="js-widget"  onclick=\'return new Widgets.Datepicker({ bindingProperty: "backDate", dateFormat: "DD MMM", removable: true, depend: "date", diff: 7, placeholder: L10N.get("transfers.backDate"), classes: "backdate-from-datepicker", pikadayModalCid: "'+ cid +'" })\'></span>\n            ';
 if (transferType.uid === 'RENT') { ;
__p += '\n            <div class="p-search__options-time p-search__options-time_position p-search__options-time-to js-show-time-picker" style="' +
((__t = ( transferType && transferType.uid === 'RENT' ? '' : 'display: none')) == null ? '' : __t) +
'">\n                <div class="p-search__options-bridge"></div>\n                <span class="js-widget"  onclick=\'return new Widgets.Time({ depend: "time", bindingProperty: "timeTo", classes: "p-search__input"})\'></span>\n            </div>\n            ';
 } ;
__p += '\n        </div>\n    </div>\n</div>\n';

}
return __p
}