module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-pricing">\r\n	<header class="b-pricing__header">' +
((__t = ( L10N.get("pricing.title") )) == null ? '' : __t) +
'</header>\r\n	';

	var prices = _.compact([price, priceBack]);
	var showRates = showRates || false;
	var totalPrice = totalPrice || null;
	var travelSubject = travelSubject ? travelSubject + '.'  : '';
	_.each(prices, function(p, i) {
		if (prices.length > 1) { ;
__p += '\r\n			<div class="b-pricing__direction">' +
((__t = ( (i === 0) ? L10N.get("trains.there") : L10N.get("trains.backward") )) == null ? '' : __t) +
'</div>\r\n		';
 } ;
__p += '\r\n\r\n        ';
 if (!_.isEmpty(p.rates) && _.isArray(p.rates) && showRates) { ;
__p += '\r\n        <div class="b-pricing__header">\r\n            ';
 _.each(p.rates, function(rate) { ;
__p += '\r\n                <span>' +
((__t = ( rate.total.amount + ' ' )) == null ? '' : __t) +
'<span class="currency">' +
((__t = ( rate.symbol )) == null ? '' : __t) +
'</span></span>\r\n            ';
 }); ;
__p += '\r\n        </div>\r\n        ';
 }

		if (!_.isEmpty(p.passengerPrices)) {
            p.passengerPrices = p.passengerPrices.sort(function(a,b) {return a.passengerType.uid > b.passengerType.uid});
			for (var i = 0; i < p.passengerPrices.length; i++) {
				var types = ['tariff', 'fee', 'taxes', 'techFees', 'supplierFees', 'agencyFees', 'feeAndTaxes', 'discount'];
				if (!_.isEmpty(p.passengerPrices[i])) {
                    var passengerPrice = _.extend(p.passengerPrices[i], p.passengerPrices[i].feeDetails);
                    var ticketPrice = 0; ;
__p += '\r\n                    <div class="b-pricing__section">\r\n                        <span class="b-pricing__section-title">' +
((__t = ( passengerPrice.passengerType.caption )) == null ? '' : __t) +
' ';
 if (passengerPrice.group) { ;
__p +=
((__t = ( ' ' + passengerPrice.group )) == null ? '' : __t) +
' ';
 } ;
__p += '</span>\r\n                        ';

                        for (var j = 0; j < types.length; j++) {
                            var priceValue = passengerPrice[types[j]];
                            if (priceValue && parseFloat(priceValue.amount) !== 0) {
                                ticketPrice += priceValue.amount;
                        ;
__p += '\r\n                        <div class="b-pricing__section-info">\r\n                            <span class="b-pricing__section-passenger">' +
((__t = ( L10N.get("pricing." + travelSubject + types[j]) )) == null ? '' : __t) +
'</span>\r\n                            <span class="b-pricing__section-price">\r\n                                ' +
((__t = ( priceValue.amount )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(priceValue.currencyCode) )) == null ? '' : __t) +
'\r\n                            </span>\r\n                        </div>\r\n                        ';

                            }
                        }
                        ;
__p += '\r\n                        <div class="b-pricing__section-info">\r\n                            <span class="b-pricing__section-price">\r\n                                ' +
((__t = (passengerPrice.passengerCount)) == null ? '' : __t) +
'\r\n                                x\r\n                                ' +
((__t = ( passengerPrice.singlePassengerTotal != null ? passengerPrice.singlePassengerTotal.amount : ticketPrice )) == null ? '' : __t) +
'\r\n                                =\r\n                                ' +
((__t = ( passengerPrice['total'].amount )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(passengerPrice['total'].currencyCode) )) == null ? '' : __t) +
'\r\n                            </span>\r\n                        </div>\r\n                    </div>\r\n            ';
 }
			}
		} ;
__p += '\r\n\r\n		';
 if (p.ancillaryFees != null && p.ancillaryFees.price.amount > 0) { ;
__p += '\r\n			<div class="b-pricing__section">\r\n				<span class="b-pricing__section-title">\r\n					' +
((__t = ( L10N.get('hotels.additionalServiceShort') )) == null ? '' : __t) +
'\r\n				</span>\r\n				<div class="b-pricing__section-info">\r\n					<span class="b-pricing__section-passenger">' +
((__t = ( L10N.get('hotels.cost') )) == null ? '' : __t) +
'</span>\r\n					<span class="b-pricing__section-price">\r\n						' +
((__t = ( Formatter.formatMoney(p.ancillaryFees.price.amount, 2) )) == null ? '' : __t) +
'\r\n						' +
((__t = ( Formatter.formatCurrency(p.ancillaryFees.price.currencyCode) )) == null ? '' : __t) +
'\r\n					</span>\r\n				</div>\r\n				';
 if (p.ancillaryFees.supplierFees.amount > 0) { ;
__p += '\r\n					<div class="b-pricing__section-info">\r\n						<span class="b-pricing__section-passenger">' +
((__t = ( L10N.get('pricing.supplierFees') )) == null ? '' : __t) +
'</span>\r\n						<span class="b-pricing__section-price">\r\n							' +
((__t = ( Formatter.formatMoney(p.ancillaryFees.supplierFees.amount, 2) )) == null ? '' : __t) +
'\r\n							' +
((__t = ( Formatter.formatCurrency(p.ancillaryFees.supplierFees.currencyCode) )) == null ? '' : __t) +
'\r\n						</span>\r\n					</div>\r\n				';
 } ;
__p += '\r\n				';
 if (p.ancillaryFees.agencyFees.amount > 0) { ;
__p += '\r\n					<div class="b-pricing__section-info">\r\n						<span class="b-pricing__section-passenger">' +
((__t = ( L10N.get('pricing.agencyFees') )) == null ? '' : __t) +
'</span>\r\n						<span class="b-pricing__section-price">\r\n							' +
((__t = ( Formatter.formatMoney(p.ancillaryFees.agencyFees.amount, 2) )) == null ? '' : __t) +
'\r\n							' +
((__t = ( Formatter.formatCurrency(p.ancillaryFees.agencyFees.currencyCode) )) == null ? '' : __t) +
'\r\n						</span>\r\n					</div>\r\n				';
 } ;
__p += '\r\n				<div class="b-pricing__section-info">\r\n					';

						var totalAncillaryFees = 0;
						_.each(p.ancillaryFees, function(item, i) {
							totalAncillaryFees += Number(item.amount.slice(0, -3));
						});
					;
__p += '\r\n					<span class="b-pricing__section-price">\r\n						= ' +
((__t = ( Formatter.formatMoney(totalAncillaryFees, 2) )) == null ? '' : __t) +
'\r\n						' +
((__t = ( Formatter.formatCurrency(p.ancillaryFees.price.currencyCode) )) == null ? '' : __t) +
'\r\n					</span>\r\n\r\n				</div>\r\n			</div>\r\n		';
 } ;
__p += '\r\n\r\n		<div class="b-pricing__section b-pricing_commission">\r\n			<span class="b-pricing__section-title">' +
((__t = ( L10N.get("pricing.commissions") )) == null ? '' : __t) +
'</span>\r\n			<div class="b-pricing__section-info">\r\n				<span class="b-pricing__section-passenger">' +
((__t = ( L10N.get("pricing.commissionsPaymentGate") )) == null ? '' : __t) +
'</span>\r\n				<span class="b-pricing__section-price"> <span class="b-pricing_commission-value"></span> ' +
((__t = ( Formatter.formatCurrency(p.total.currencyCode) )) == null ? '' : __t) +
'</span>\r\n			</div>\r\n		</div>\r\n\r\n		<div class="b-pricing__total-section">\r\n			<span class="b-pricing__section-total-title">' +
((__t = ( L10N.get("pricing.total") )) == null ? '' : __t) +
'</span>\r\n			<span class="b-pricing__section-total-price">\r\n				';
 if (p.isMinPriceRangeComponent === true) { ;
__p +=
((__t = ( L10N.get('pricing.from') )) == null ? '' : __t) +
' ';
 } ;
__p += '\r\n				' +
((__t = ( p.total.amount )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(p.total.currencyCode) )) == null ? '' : __t) +
'\r\n			</span>\r\n		</div>\r\n	';
 }); ;
__p += '\r\n\r\n    ';
 if (totalPrice != null && prices.length > 1) { ;
__p += '\r\n        <div class="b-pricing__total-section">\r\n            <span class="b-pricing__section-total-title">' +
((__t = ( L10N.get("pricing.totalPrice") )) == null ? '' : __t) +
'</span>\r\n            <span class="b-pricing__section-total-price">\r\n                ' +
((__t = ( totalPrice.amount )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(totalPrice.currencyCode) )) == null ? '' : __t) +
'\r\n			</span>\r\n        </div>\r\n    ';
 } ;
__p += '\r\n</div>\r\n';

}
return __p
}