module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-transfer-travellers">\n    <div class="b-transfer-travellers__container"></div>\n    <div class="b-transfer-travellers__footer">\n<!--        <div class="b-transfer-travellers__add">' +
((__t = ( L10N.get('transfers.addPassenger') )) == null ? '' : __t) +
'</div>-->\n    </div>\n</div>\n\n';

}
return __p
}