// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';
import View from './index';

const Model = BaseModel.extend({

	View,

	parse(resp) {
		let temp = resp;
		while (temp && temp.parameters) {
			temp = temp.parameters;
		}
		return temp;
	},

	toJSON(...args) {
		const result = BaseModel.prototype.toJSON.apply(this, args);
		const type = result.type;
		const exclude = [];

		if (_.isObject(type)) {
			switch (type.uid) {
				case 'BIRTHDAY_NOTIFICATION':
				case 'INTERNAL':
					// exclude.concat(['firstNameLat', 'lastNameLat', 'middleNameLat']);
					break;
				default:
					// exclude.concat(['firstName', 'lastName', 'middleName']);
			}
		}
		return _.omit(result, exclude);
	},

});

export default Model;
