// eslint-disable-next-line banned-modules
'use strict';

import BasePageView from '@/pages/base.page-view';
import PassengersDataView from '@/blocks/pages/b-passengers-data/train/index';
import TrainInfoView from '@/blocks/elements/b-train/b-train-info/index';
import axios from 'axios';

export default BasePageView.extend({

	trainInfoView: null,

	passengerDatView: null,

	initialize(options) {
		this.render();

		const { bookingInfo, pricingInfo, bookingSettings, bookingSettingsParams } = options;

		this.passengersDataView = new PassengersDataView({
			parent: this,
			pricingInfo,
			bookingInfo,
			bookingSettings,
			bookingSettingsParams,
		});

		this.renderTrainInfo({
			bookingInfo,
			pricingInfo,
			passengersDataView: this.passengersDataView,
		});

		this.ui.content.append(this.passengersDataView.$el);
	},

	renderTrainInfo({
		bookingInfo,
		pricingInfo,
		passengersDataView,
	} = {}) {
		this.trainInfoView = new TrainInfoView({
			bookingInfo,
			pricingInfo,
			passengersDataView,
		});

		this.trainInfoView.passengersDataView = passengersDataView;
		this.trainInfoView.addModelListeners();

		this.ui.sidebar.append(this.trainInfoView.$el);
	},

}, {
	async load() {
		if (!STATE.getCountries()) {
			await STATE.loadCountries();
		}

		return new Promise(async (resolve, reject) => {
			const pricingParameters = STORE.get(STATE.ROUTES.RAILWAYS_PASSENGERS);
			let pricingInfo = STORE.get(STATE.ROUTES.RAILWAYS_TICKETS_PRICING);
			pricingInfo = pricingInfo && pricingInfo.response || null;

			if (!pricingParameters || (pricingParameters.routeInfo == null && pricingParameters.routeInfoBack == null)) {
				STATE.navigate(STATE.ROUTES.INDEX);
				return;
			}

			const travellers = [];
			const seatsRequestPassengersTypes = pricingParameters.routeInfo.seatsRequest.passengersTypes;

			const passengers = STORE.get(
				STATE.ROUTES.MAIN_SEARCH).passengers;

			Object.keys(seatsRequestPassengersTypes).forEach(k => {
				for (let i = 0; i < seatsRequestPassengersTypes[k]; i++) {
					const pass = passengers[i];

					if (k === 'ADULT') {
						travellers.push({
							type: {
								uid: 'ADULT',
							},
							tariff: 'FULL',
							uid: pass ? pass.uid : '',
						});
					} else if (k === 'CHILD') {
						travellers.push({
							type: {
								uid: 'CHILD',
							},
							tariff: 'CHILD',
							uid: pass ? pass.uid : '',
						});
					} else if (k === 'INFANT') {
						travellers.push({
							type: {
								uid: 'INFANT',
							},
							tariff: 'BABY',
							uid: pass ? pass.uid : '',
						});
					}
				}
			});

			pricingParameters.travellers = travellers;
			const bookingSettingsParams = {
				tokens: {
					trainToken: pricingParameters.routeInfo.train.token,
					carriageToken: pricingParameters.routeInfo.carriage.token,
				},
			};

			if (pricingParameters.routeInfoBack != null) {
				Object.assign(bookingSettingsParams, {
					tokensBack: {
						trainToken: pricingParameters.routeInfoBack.train.token,
						carriageToken: pricingParameters.routeInfoBack.carriage.token,
					},
				});
			}

			try {
				const bookingSettings = (await axios.post('/midoffice/ibecorp-b2b/rail/getBookingFormSettings', {
					parameters: bookingSettingsParams,
				})).data.result;
				if (pricingInfo == null || (bookingSettings.beddingEnabled || bookingSettings.beddingBackEnabled)) {
					if (bookingSettings.beddingEnabled ||
						bookingSettings.beddingBackEnabled) {
						if (!_.isEmpty(pricingParameters.routeInfo)) {
							pricingParameters.routeInfo.seatsRequest.withBed = true;
						}
						if (!_.isEmpty(pricingParameters.routeInfoBack)) {
							pricingParameters.routeInfoBack.seatsRequest.withBed = true;
						}
					}
					pricingInfo = (await axios.post('/midoffice/ibecorp-b2b/rail/pricing', {
						parameters: pricingParameters,
					})).data.result;
				}
				pricingParameters.travellers = pricingInfo.travellers;
				const params = {
					bookingInfo: pricingParameters,
					pricingInfo,
					bookingSettingsParams,
				};
				if (bookingSettings != null) {
					params.bookingSettings = bookingSettings;
				}
				resolve(params);
			} catch (e) {
				reject(e);
			}
		});
	},
});
