// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import childAgeItem from './template_child-age-item.ejs';
import PassengerModel from './passenger-model';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';
import $ from 'jquery';

export default Widget.extend({

	validationTemplate,

	template,

	ui: {
		childAgeContent: '.b-passengers__child-age__content',
		childAge: '.b-passengers__child-age',
	},

	events: {
		click: 'setActive',
		'click .b-passengers__item-minus': 'minus',
		'click .b-passengers__item-plus': 'plus',
		'click .b-passengers__wrapper': 'open',
		'change .b-passengers__child-age__item-input': 'updatechildAges',
	},

	initialize() {
		const settings = STATE.getSettings();
		this.render({
			types: (settings && settings.firstStepSettings)
				? settings.firstStepSettings.availablePassengersTypes
				: null,
			displayChildsAgeSelector: (settings && settings.firstStepSettings)
				? settings.firstStepSettings.displayChildsAgeSelector
				: null,
		});
	},

	updatechildAges() {
		const arr = [];
		this.$('.b-passengers__child-age__item-input').each((i, item) => {
			if ($(item).val() !== '') {
				arr.push($(item).val());
			}
		});
		STATE.getSearchModel().set('childsAge', arr);
		this.updateHelpers();
		this.isActive = false;
	},

	setActive() {
		this.isActive = true;
	},

	open() {
		this.$el.toggleClass('open');
		this.$('.b-passengers__validation').toggleClass('b-passengers__validation-visible');
		$('html').on('click.passengers', () => {
			if (!this.isActive) {
				this.$el.removeClass('open');
				this.$('.b-passengers__validation').removeClass('b-passengers__validation-visible');
				$('html').off('click.passengers');
			}
			this.isActive = false;
		});
		this.updateHelpers();
	},

	updateHelpers() {
		const childsAge = STATE.getSearchModel().get('childsAge');
		if (childsAge) {
			const isExtraAge = childsAge.some((age) => {
				return age === `12` || age === `13`;
			});

			const $container = $('.b-passengers__child-age__footer');
			if (isExtraAge) {
				$container.html(L10N.get('searchForm.extraAge'));
			} else {
				$container.empty();
			}
		}

		const isSeniorOrYouth = this.model.get('SENIOR') > 0 || this.model.get('YOUTH') > 0;
		const $container = $('.b-passengers__info-youth-and-senior');
		if (isSeniorOrYouth) {
			$container.html(L10N.get('searchForm.passengersInfo'));
		} else {
			$container.empty();
		}
	},

	updateLabel() {
		const count = Object.keys(PassengerModel.prototype.defaults())
			.reduce((result, item) => result + parseInt(this.model.get(item)) || 0, 0);
		this.$('.b-passengers__input')
			.text(`${count} ${L10N.getPluralForm('searchForm.passenger', count)}`);
	},

	minus(e) {
		const parent = $(e.currentTarget).parent();
		this.model.minus(parent.attr('data-type'));
		this.updateHelpers();
	},

	plus(e) {
		const parent = $(e.currentTarget).parent();
		this.model.plus(parent.attr('data-type'));
		this.updateHelpers();
	},

	displaychildAgeSelector() {
		const settings = STATE.getSettings();
		if (settings && settings.firstStepSettings && settings.firstStepSettings.displayChildsAgeSelector) {
			const count = this.model.get('CHILD');
			const childsAge = STATE.getSearchModel().get('childsAge');
			const isExtraAge = settings.firstStepSettings.displayChildsAgeSelector;
			this.ui.childAgeContent.empty();
			if (count <= 0) {
				this.ui.childAge.hide();
			} else {
				this.ui.childAge.show();
				for (let i = 0; i < count; i++) {
					this.ui.childAgeContent.append(childAgeItem({
						isExtraAge,
						number: i,
						value: (childsAge && childsAge[i]) || '',
					}));
					if (childsAge && childsAge[i]) {
						this.$(`.b-passengers__child-age__item-input-${i} option[value=${childsAge[i]}]`).prop('selected', true);
					}
				}
			}
			this.updatechildAges();
		}
	},

	applyBinding() {
		this.model = STATE.getSearchModel().get('passengersTypes');

		_.each(PassengerModel.prototype.defaults(), (count, type) => {
			const item = this.$el.find(`[data-type=${type}]`);
			const itemValue = item.find('.b-passengers__item-value');
			this.model.on(`change:${type}`, (model, value) => {
				itemValue.text(value);
				this.updateLabel();
			});
			this.model.on(`validationError:${type}`, (errors) => {
				this.showValidation(errors);
			});

			itemValue.text(this.model.get(type));
		});
		this.model.on(`change:CHILD`, () => {
			this.displaychildAgeSelector();
		});
		this.updateLabel();
		for (let i = 0; i < 9; i++) {
			this.listenTo(STATE.getSearchModel(), `validationError:childsAge.${i}`, (errors) => {
				this.showValidation(errors);
				this.$('.b-passengers__child-age__item-input').eq(i).addClass('validation-error');
			});
		}
	},

});
