// eslint-disable-next-line banned-modules
'use strict';

import './style.less';

import BaseView from '@/classes/base.view';
import template from './template.ejs';

import SliderView from '@/blocks/elements/b-slider/';

export default BaseView.extend({

	template,

	ui: {
		sliderContainer: '.b-hotel-order_slider',
	},

	initialize() {
		this.hotel = this.options.reservation.card;
		this.services = this.options.reservation.services;
		this.sliderViews = [];

		const {address} = this.hotel;
		if (address != null && address.cityInfo != null) {
			const locationHotel = _.pick(address, ['latitude', 'longitude']);
			const locationCity = _.pick(address.cityInfo, ['latitude', 'longitude']);

			if (!_.isEmpty(locationHotel) && !_.isEmpty(locationCity)) {
				try {
					const distance = window.google.maps ? Math.ceil(window.google.maps.geometry.spherical.computeDistanceBetween(
						new window.google.maps.LatLng(locationHotel.latitude, locationHotel.longitude),
						new window.google.maps.LatLng(locationCity.latitude, locationCity.longitude),
					)) : NaN;

					const distanceValue = (distance / 1000);
					if (!isNaN(distanceValue)) {
						this.hotel.distance = distanceValue;
					}
				} catch (e) {
					throw e;
				}
			}
		}

		this.render({
			hotel: this.hotel,
			services: this.services,
			offers: _.map(_.filter(this.services, (s) => s.showRoomInfo), (service) => service.offer),
			formatDate: (date) => {
				return (date ? new Time(date).format('dd, DD.MM.YYYY HH:mm') : '-');
			},
		});
	},

	render(...args) {
		BaseView.prototype.render.apply(this, args);

		_.each(this.ui.sliderContainer, (el, i) => {
			this.sliderViews.push(new SliderView({
				images: this.hotel.images || [],
			}));
			this.$(el).html(this.sliderViews[i].$el);
		});
	},

});
