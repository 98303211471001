// eslint-disable-next-line banned-modules
'use strict';

import BasePageView from '@/pages/base.page-view';
import PassengersDataView from '@/blocks/pages/b-passengers-data/transfers/index';
import TransferRouteInfoView from '@/blocks/elements/b-transfer/b-transfer-route-info/index';
import axios from 'axios';

export default BasePageView.extend({

	initialize(options, reinitialize = false) {
		this.options = options || {};

		if (!reinitialize) {
			this.render();
		}

		function checkNCreateBookingSettingsObj() {
			if (options.bookingSettings == null) {
				options.bookingSettings = {};	
			}
		}

		if (options.prevModel) {
			checkNCreateBookingSettingsObj();
			options.bookingSettings.prevModel = options.prevModel;
		}

		if (options.prevPassengerData) {
			checkNCreateBookingSettingsObj();
			options.bookingSettings.prevPassengerData = options.prevPassengerData;
		}

		this.renderRouteInfo(options);

		const passengersDataView = new PassengersDataView({
			bookingSettings: options.bookingSettings,
			tripInfo: options.tripInfo,
			parent: this,
			isIntention: options.isIntention,
		});

		this.ui.content.html(passengersDataView.$el);
	},

	reinitialize(options) {
		this.initialize(_.extend({}, this.options, options), true);
	},

	renderRouteInfo(options) {
		const {order, pricingInfo, tripInfo} = this.options;

		if (options.price) {
			pricingInfo.price = options.price;
			pricingInfo.priceBack = options.priceBack || null;
		}

		if (options.totalPrice) {
			pricingInfo.totalPrice = options.totalPrice;
		}

		if (this.transferRouteInfoView != null) this.transferRouteInfoView.$el.remove();
		this.transferRouteInfoView = new TransferRouteInfoView({
			transferInfo: tripInfo,
			pricingInfo,
			order,
		});

		this.ui.sidebar.html(this.transferRouteInfoView.$el);
	},

}, {
	async load() {
		if (!STATE.getCountries()) {
			await STATE.loadCountries();
		}

		const params = STORE.get(STATE.ROUTES.TRANSFERS_PASSENGERS);
		if (params == null) {
			STATE.navigate(STATE.ROUTES.INDEX);
			return undefined;
		}

		// Дожидаемся выполнения этого реста и из него можем потенциально сгенерировать travellers для /transfers/pricing

		const bookingFormSettings = await axios.post('/midoffice/ibecorp-b2b/transfers/getBookingFormSettings', {
			parameters: {
				token: params.token,
			},
		});

		const pricingParameters = {
			transferToken: params.token,
			trips: params.tripInfo.backDate != null ? [{}, {}] : [{}],
		};

		if (bookingFormSettings.data.result?.corporatePassengers?.length) {
			// Имитируем создание travellers, частично заполняя данными

			const costCodes = [];
			if (bookingFormSettings.data.result?.organizationCostCodes?.length) {
				// наполняем costCodes по аналогии с /ibecorp/frontend/app-b2b/js/blocks/elements/b-cost-codes/index.js
				bookingFormSettings.data.result.organizationCostCodes.forEach((costCode) => {
					costCodes.push({
						costCodeCategoryUid: costCode?.costCodeCategoryUid,
						name: costCode?.name,
					});
				});
			}

			const travellers = [];
			bookingFormSettings.data.result.corporatePassengers.forEach((passenger) => {
				const _costCodes = passenger?.costCodes ?
					passenger.costCodes.concat(costCodes).filter((cCode, index, arr) => {
						return index === arr.findIndex((c) => (
							c.costCodeCategoryUid === cCode.costCodeCategoryUid && c.name === cCode.name
						));
					})
					: costCodes;
				travellers.push({
					uid: passenger?.uid,
					costCodes: _costCodes,
				});
			});

			pricingParameters.travellers = travellers;
		}

		const pricingResult = await axios.post('/midoffice/ibecorp-b2b/transfers/pricing', {
			parameters: pricingParameters,
		});

		bookingFormSettings.data.result.travellersCount = params.tripInfo.travellersCount;
		const options = {
			bookingSettings: _.extend({},
				bookingFormSettings.data.result,
				{currentBooking: params.currentBooking},
				{reservationUid: params.reservationUid},
			),
			pricingInfo: pricingResult.data.result,
			tripInfo: params.tripInfo,
			isIntention: params.isIntention || false,
		};

		return options;
	},
});
