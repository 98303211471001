// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

import GlUl from '@/utils/global-utils';
import axios from 'axios';
import $ from 'jquery';

export default BaseView.extend({

	template,

	events: {
		'click .b-hotels-search__button': 'searchHotels',
		'click .b-hotels-search-mobile__filters-trigger': 'openFiltersMobile',
		'click .b-hotels-search-mobile__map-trigger': 'openMapMobile',
		'click .b-hotels-search__options-date-element': 'showPikadayModal',
		'click .b-hotels-search-form-mobile__background': 'toggleSearchFormMobile',
		'click .b-hotels-search-mobile__search-form': 'toggleSearchFormMobile',
	},

	ui: {
		mobileSearchFormBackground: '.b-hotels-search-form-mobile__background',
	},

	initialize() {
		this.model = STATE.getSearchModel();
		this.render();

		this.listenTo(this.model, 'change:checkInDate', (model) => {
			if (model.get('checkOutDate') == null) {
				this.$el.find('.checkoutdate-from-datepicker .p-search__input').trigger('click');
			} else {
				const checkOutDate = new Time(model.get('checkOutDate'));
				const checkInDate = new Time(model.get('checkInDate'));

				if (checkOutDate.getMoment().isBefore(checkInDate.getMoment()) || checkOutDate.getMoment().isSame(checkInDate.getMoment())) {
					model.set('checkOutDate', null);
					this.$el.find('.checkoutdate-from-datepicker .p-search__input').trigger('click');
				}
			}
		});
	},

	// EVENTS
	searchHotels(e) {
		if (e != null) {
			e.preventDefault();
		}

		const model = this.model.toJSON();
		const {parent} = this.options;

		const showMessagePopup = (result) => {
			if (result != null && result.data != null && result.data.messages != null && result.data.messages.length > 0) {
				const messages = result.data.messages.reduce((prev, curr) => {
					let resultMessages = prev;
					resultMessages += `${curr.text}<br/>`;
					return resultMessages;
				}, '');

				const isWarning = _.some(result.data.messages, (m) => m.severity === 'WARNING');
				const popup = new Widgets.Popup({
					content: isWarning ? [L10N.get('hotels.noOffers'), messages].join('<br/><br/>') : messages,
					closeOnlyOnAction: false,
					type: isWarning ? 'danger' : 'info',
					actions: [{
						label: isWarning ? L10N.get('errors.noTicketsButton') : L10N.get('searchForm.close'),
						action: () => {
							popup.hide();

							if (isWarning) {
								STATE.navigate(STATE.ROUTES.INDEX);
							}
						},
					}],
					classes: 'b-reload-popup',
				});
				popup.show();
			}
		};

		STATE.showLoader();
		this.disableElements(e);
		axios.get('/midoffice/ibecorp-b2b/hotels/searchPreferences').then((p) => {
			const preferences = p.data.result;

			axios.post('/midoffice/ibecorp-b2b/hotels/searchOffers', model, this.model).then((result) => {
				const data = _.extend({}, result.data.result, preferences);

				showMessagePopup(result);

				STATE.setHotelsSearchResult(data);
				STORE.set(STATE.ROUTES.MAIN_SEARCH, STATE.getSearchModel().attributes, 14400);
				STATE.setFormState('hotels:filter', null);

				parent.hotelsView.results = result.data.result;
				parent.hotelsView.resetResults();

				STATE.hideLoader();

				if (STATE.checkViewport('(max-width: 768px)')) this.toggleSearchFormMobile();

				parent.hotelsView.setPreferences(_.omit(data, ['hotelCards', 'restHotelToken', 'searchFinished']));
				parent.hotelsView.initializeHotels(result.data.result, true);
			});
		});
	},

	toggleSearchFormMobile() {
		const lLayout = $('.l-layout');
		const shouldOpen = !lLayout.hasClass('show-search-form');
		this.ui.mobileSearchFormBackground[shouldOpen ? 'show' : 'hide']();
		lLayout.toggleClass('show-search-form', shouldOpen);
	},

	openMapMobile() {
		this.options.parent.hotelsView.mapOpen();
		this.options.parent.hotelsView.$el.find('.b-hotels__map').addClass('b-hotels__map--fullscreen');
	},

	openFiltersMobile() {
		$('.l-layout').addClass('show-filters');
	},

	createPikadayModalView() {
		this.pikadayModal = GlUl.createPikadayModalView(this.model);
	},

	clearPikadayModalView() {
		this.pikadayModal.destroy();
		this.pikadayModal = null;
	},

	destroy(...args) {
		this.clearPikadayModalView();
		BaseView.prototype.destroy.apply(this, args);
	},

	showPikadayModal(e) {
		if (this.pikadayModal == null) this.createPikadayModalView();
		GlUl.showPikadayModal(e, this.pikadayModal);
	},

});
