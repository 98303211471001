module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-train__carriages">\r\n';

	var currencyCode = this.currencyCode;
	var groupByServiceClass = this.model.get('groupByServiceClass');
	var carriageInfos = this.model.get('carriageInfos').reduce(function(map, c) {
		var key = groupByServiceClass && c.serviceClass ? c.serviceClass.uid : c.type.uid;
		if (!map[key]) {
			map[key] = [];
		}
		map[key].push(c);
		return map;
	}, {});

	var carriageTypes = this.carriageTypes;

	if (groupByServiceClass) {
		carriageTypes = [];
		var carriagesTypesOrder = ['R2', 'S2', 'V2', 'E2', 'S1', 'V1', 'R1'];
		this.specialServiceClasses.sort(function(s1, s2) {
			var i1 = carriagesTypesOrder.indexOf(s1.uid);
			var i2 = carriagesTypesOrder.indexOf(s2.uid);
			if (i1 > -1 && i2 > -1) {
				return i1 - i2;
			}
			return 0;
		}).forEach(function(ssc) {
			carriageTypes.push(_.extend({}, ssc, {
				type: ssc.uid,
			}));
		});
	}

	var carriageUid = null;
	carriageTypes.forEach(function(ct) {
		var carriage = null;
		var carriages = carriageInfos[ct.type] != null ? carriageInfos[ct.type] : [];
		_.each(carriages, function(car) {
			if (carriage == null) {
				carriage = car;
			} else {
				var prevPrice = parseFloat(carriage.price, 10);
				var curPrice = parseFloat(car.price, 10);
				if (curPrice < prevPrice) {
					carriage = car;
				}
			}
			if (groupByServiceClass) {
				if (carriageUid == null) {
					carriageUid = carriage.uid;
				}
			} else {
				carriageUid = carriage.uid;
			}
		});
	;
__p += '\r\n	<div data-uid="' +
((__t = ( carriageUid )) == null ? '' : __t) +
'" data-type="' +
((__t = ( ct.type )) == null ? '' : __t) +
'" class="b-train__carriages-type ' +
((__t = ( carriage != null ? 'js-select-carriage' : 'sold_out' )) == null ? '' : __t) +
' b-train__carriages-select b-train__carriage">\r\n		';
 if (carriage != null) { ;
__p += '\r\n            <!-- FOR tablet -->\r\n            <div class="b-train__carriage-type-tp-container">\r\n                <div class="b-train__carriage-type">\r\n                    <div class="b-train__carriage-caption">\r\n                        ';
 if (groupByServiceClass) { ;
__p += '\r\n                            ' +
((__t = ( ct.description )) == null ? '' : __t) +
'\r\n                        ';
 } else { ;
__p += '\r\n                            ' +
((__t = ( ct.caption )) == null ? '' : __t) +
'\r\n                        ';
 } ;
__p += '\r\n                    </div>\r\n                    <div class="b-train__carriage-quantity">\r\n                        ' +
((__t = ( carriage.seatsAmount )) == null ? '' : __t) +
' <span>' +
((__t = ( Formatter.getNumEnding(carriage.seatsAmount, L10N.get('formatter.endings.place')) )) == null ? '' : __t) +
'</span>\r\n                    </div>\r\n                </div>\r\n                <div class="b-train__carriage-tp">\r\n                    ' +
((__t = ( require('../b-travel-policy.ejs')({
                        carriage: carriage,
                    }) )) == null ? '' : __t) +
'\r\n                </div>\r\n            </div>\r\n            <!-- /FOR tablet -->\r\n			<div class="b-train__carriage-type">\r\n				<div class="b-train__carriage-caption">\r\n					';
 if (groupByServiceClass) { ;
__p += '\r\n						' +
((__t = ( ct.description )) == null ? '' : __t) +
'\r\n					';
 } else { ;
__p += '\r\n						' +
((__t = ( ct.caption )) == null ? '' : __t) +
'\r\n					';
 } ;
__p += '\r\n				</div>\r\n				<div class="b-train__carriage-quantity">\r\n					' +
((__t = ( carriage.seatsAmount )) == null ? '' : __t) +
' <span>' +
((__t = ( Formatter.getNumEnding(carriage.seatsAmount, L10N.get('formatter.endings.place')) )) == null ? '' : __t) +
'</span>\r\n				</div>\r\n			</div>\r\n			<div class="b-train__carriage-tp">\r\n				' +
((__t = ( require('../b-travel-policy.ejs')({
					carriage: carriage,
				}) )) == null ? '' : __t) +
'\r\n			</div>\r\n			<div class="b-train__carriage-seats-tp">\r\n				<!-- FOR 1280px -->\r\n				<div class="b-train__carriage-quantity">\r\n					' +
((__t = ( carriage.seatsAmount )) == null ? '' : __t) +
' <span>' +
((__t = ( Formatter.getNumEnding(carriage.seatsAmount, L10N.get('formatter.endings.place')) )) == null ? '' : __t) +
'</span>\r\n				</div>\r\n				<!-- /FOR 1280px -->\r\n				<div class="b-train__carriage-price">\r\n					' +
((__t = ( L10N.get('trains.from') )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatMoney(carriage.price, 0) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(currencyCode) )) == null ? '' : __t) +
'\r\n				</div>\r\n			</div>\r\n		';
 } ;
__p += '\r\n	</div>\r\n';
 }); ;
__p += '\r\n</div>\r\n';

}
return __p
}