import React, {
	ChangeEvent,
	useCallback,
	useMemo,
} from "react";
import { BaseSelectOption } from "./helpers/interfaces";
import { FormItemControlProps } from "../FormItem/helpers/interfaces";

interface SelectProps extends FormItemControlProps {
	label?: string;
	classes?: string;
	placeholder?: string;
	removable?: boolean;
	options: BaseSelectOption[];
	minWidth?: number | string;
}

const Select: React.FC<SelectProps> = (props) => {
	const {
		label,
		value,
		placeholder = "",
		removable = false,
		options,
		minWidth,
		onChange,
	} = props;

	const onSelectHandler = useCallback(
		(event: ChangeEvent<HTMLSelectElement>) => {
			const { target } = event;
			const { selectedOptions = [] } = target;
			if (selectedOptions.length) {
				onChange && onChange(selectedOptions[0].value);
			}
		},
		[onChange]
	);

	const selectedLabel = useMemo(() => {
		const selectedOption = options.find(
			(option: BaseSelectOption) => option.value === value
		);
		if (selectedOption) {
			return selectedOption.label;
		}
		return placeholder;
	}, [value, placeholder, options]);

  console.log(selectedLabel, value);

	return (
		<div style={{ minWidth: minWidth ? minWidth : "auto" }}>
			<label className="b-input">
				{label && <span className="b-input__label">{label}</span>}
				<label className="b-input__value b-input__select-value">
					<span className="b-input__select-value-label">{selectedLabel}</span>
					<select
						className="b-input__select "
						name="type"
						onChange={onSelectHandler}
					>
						{options.map((option: BaseSelectOption) => (
							<option
								value={option.value}
								key={option.value}
								selected={option.value === value}
							>
								{option.label}
							</option>
						))}
					</select>
					{removable && <div className="b-input__select__remove dn"></div>}
				</label>
			</label>
		</div>
	);
};

export default Select;
