module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (travelPolicyCompliance != null && !_.isEmpty(travelPolicyCompliance.rulesApplied)) {
	var rulesApplied = _.sortBy(travelPolicyCompliance.rulesApplied, function (el) { return el.action.uid === 'RECOMMENDED' });

	_.each(rulesApplied, function (rule, i) { ;
__p += '\n		<span class="b-travel-policy-rule g-icon-travel-policy-rules-' +
((__t = ( rule.action.uid )) == null ? '' : __t) +
'" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="<div class=\'b-travel-policy-tooltip\'><div class=\'tooltip__header\'>' +
((__t = ( rule.action.caption )) == null ? '' : __t) +
'</div>\n		';
 if (_.size(rule.ruleDetails) > 0) { ;
__p += '<div class=\'tooltip__body\'>';
 _.each(rule.ruleDetails, function(ruleDetail){ ;
__p += '\n			<div>' +
((__t = ( _.escape(ruleDetail) )) == null ? '' : __t) +
'</div>\n		';
 }); ;
__p += '</div>';
 } ;
__p += '</div>"></span>\n	';
 });

} ;


}
return __p
}