// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';

const BookingModel = BaseModel.extend({

	initialize(args, options) {
		const baggage = this.get('flight.servicesStatuses.baggage.uid');
		const exchange = this.get('flight.servicesStatuses.exchange.uid');
		const refund = this.get('flight.servicesStatuses.refund.uid');

		const departureDate = [];
		const arrivalDate = [];
		const transfers = [];

		const {hasTravelPolicy = false} = options || {};

		this.get('flight.legs').forEach(leg => {
			departureDate.push(new Time(leg.segments[0].departureDate).getDayMinutes());
			arrivalDate.push(new Time(_.last(leg.segments).arrivalDate).getDayMinutes());
			transfers.push(leg.segments.length - 1);
		});

		this.set('hasBaggage', baggage !== 'OFF');
		this.set('exchange', exchange !== 'OFF');
		this.set('refund', refund !== 'OFF');

		this.set('transfers', transfers.map(item => {
			return {
				uid: item,
				caption: `${item !== 0 ? item : ''} ${L10N.getPluralForm('filters.transfers', item)}`,
			};
		}));
		this.set(`departureDate`, departureDate);
		this.set(`arrivalDate`, arrivalDate);

		if (!this.get('flight.carrier.caption')) {
			this.set('flight.carrier.caption', this.get('flight.carrier.uid'));
		}

		this.set(`flightNumber`, _.flatten(this.get('flight.legs').map((leg, i) => {
			const result = _.map(leg.segments, (s) => s.airline.uid + s.flightNumber);
			this.set(`flightNumber${i}`, result);
			return result;
		})));

		this.set(`departureAirport`, this.get('flight.legs').map(leg => leg.segments[0].departureAirport));
		this.set(`arrivalAirport`, this.get('flight.legs').map(leg => leg.segments[leg.segments.length - 1].arrivalAirport));

		this.set(`travelTime`, this.get('flight.legs').map(leg => {
			if (!leg.duration) {
				return 0;
			}
			return leg.duration;
		}));

		this.set(`price`, this.get('flight.price.total.amount'));

		if (hasTravelPolicy) {
			if (!_.isEmpty(this.get('flight.travelPolicyCompliance.rulesApplied'))) {
				this.set(`travelPolicyCompliance`, this.get('flight.travelPolicyCompliance.rulesApplied').map((rule) => rule.action));
			} else {
				this.set(`travelPolicyCompliance`, {caption: L10N.get('bookingForm.noViolation'), uid: 'COMPLIANCE'});
			}
		}

		this.get('flight.legs').forEach(leg => {
			leg.segments.forEach(segment => {
				const servicesDetailsView = Object.keys(segment.servicesDetails)
					.map(serviceName => {
						const service = segment.servicesDetails[serviceName];
						if (serviceName === 'tariffName') {
							segment.serviceName = service;
						} else if (typeof (service) === 'boolean') {
							return {
								order: 2,
								value: ` ${L10N.get(`flightlegs.${serviceName}`)}`,
								className: service ? 'positive' : 'negative',
							};
						} else if (typeof (service) === 'string') {
							return {
								order: 3,
								value: service,
							};
						} else if (['freeCabinLuggage', 'freeLuggage', 'paidCabinLuggage', 'paidLuggage'].indexOf(serviceName) >= 0) {
							const serviceKeys = Object.keys(service);
							if (serviceKeys.length === 0) return null;
							const baseType = service.ADULT || service[serviceKeys[0]];
							let className = serviceKeys.length <= 0 ? 'negative' :
								(['freeCabinLuggage', 'freeLuggage'].indexOf(serviceName) >= 0 ? 'positive' : 'paid');

							let typesString = [];
							if (baseType.nil) {
								className = 'negative';
								typesString = [`${L10N.get(`flightlegs.${serviceName}`)} ${L10N.get(`flightlegs.serviceNotAvailable`)}`];
							} else {
								typesString = serviceKeys.map(passengerType => {
									const data = service[passengerType];
									return `${L10N.get(`flightlegs.${serviceName}`)}
										${data.pieces} ${data.unit || L10N.get('flightlegs.pieces')}
										${(data.weight || data.size) ?
										`(${data.weight}${L10N.get('flightlegs.weight')}
												${data.size}${L10N.get('flightlegs.size')})` : ''
										}
									`;
								});
							}

							return {
								order: 1,
								className,
								value: typesString.join(' '),
							};
						}
						return null;
					});
				segment.servicesDetailsView = servicesDetailsView.filter(item => item != null);
				segment.servicesDetailsView.sort((a, b) => {
					return a.order - b.order;
				});
			});
		});
	},

});

export default BookingModel;
