module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var clientUid = (client || {}).uid || null;
    var contactPersonEditGranted = this.contactPersonEditGranted;
	var contactPersonDisableFreeTextInput = this.contactPersonDisableFreeTextInput;
	var disabledEdit = this.model.get('disabled') === true;
	var langRegistrationFullName = langRegistrationFullName || {};
    var l10nLastNamePlaceholder = '';
    var l10nFirstNamePlaceholder = '';
	switch (langRegistrationFullName.uid) {
        case 'LATIN':
            l10nLastNamePlaceholder = L10N.get("bookingForm.lastNamePlaceholder");
            l10nFirstNamePlaceholder = L10N.get("bookingForm.firstNamePlaceholder");
            break;
        case 'LATIN_AND_CYRILLIC':
            l10nLastNamePlaceholder = L10N.get("bookingForm.lastNameLatinAndCyrillicPlaceholder");
            l10nFirstNamePlaceholder = L10N.get("bookingForm.firstNameLatinAndCyrillicPlaceholder");
            break;
        case 'CYRILLIC':
            l10nLastNamePlaceholder = L10N.get("bookingForm.lastNameCyrillicPlaceholder");
            l10nFirstNamePlaceholder = L10N.get("bookingForm.firstNameCyrillicPlaceholder");
            break;
    }

;
__p += '\n\n<section class="b-customer ' +
((__t = ( this.model.get('disabled') === true ? 'b-customer--disabled' : '' )) == null ? '' : __t) +
'">\n	<header class="b-customer__header collapse--with-anchor--white mobile-collapse" data-toggle="collapse" data-target=".toggle-customer">' +
((__t = ( L10N.get("bookingForm.customer") )) == null ? '' : __t) +
'</header>\n\n	';
 if (disabledEdit && contactPersonEditGranted) { ;
__p += '\n		<div class="b-customer__info toggle-customer show">\n			<div class="l-grid-container">\n				<div class="l-grid-layout-33">\n					<span class="pos-rel">\n                        ';
 if (this.contactAutocompleteAvailable) { ;
__p += '\n                        <span class="js-widget"  onclick=\'return new Widgets.ContactAutocomplete({ bindingProperty: "lastName", label: L10N.get("bookingForm.lastName"), placeholder: L10N.get("bookingForm.lastNamePlaceholder"), attrs: {readonly: true, disabled: true}, clientUid: "'+ clientUid +'"})\'></span>\n                        ';
 } else { ;
__p += '\n                        <span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "lastName", label: L10N.get("bookingForm.lastName"), placeholder: L10N.get("bookingForm.lastNamePlaceholder"), attrs: {readonly: true} })\'></span>\n                        ';
 } ;
__p += '\n	                	<i class="b-customer__allow-edit g-icon-edit"></i>\n	            	</span>\n				</div>\n				<div class="l-grid-layout-33">\n					<span class="pos-rel">\n						<span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "firstName", label: L10N.get("bookingForm.firstName"), placeholder: "' + l10nFirstNamePlaceholder + '", attrs: {readonly: true}  })\'></span>\n						<i class="b-customer__allow-edit g-icon-edit"></i>\n					</span>\n				</div>\n			</div>\n			<div class="l-grid-container">\n				<div class="l-grid-layout-33">\n					<span class="pos-rel">\n						<span class="js-widget"  onclick=\'return new Widgets.Phone({ bindingProperty: "phone", label: L10N.get("bookingForm.phone"), placeholder: L10N.get("bookingForm.phonePlaceholder"), attrs: {readonly: true}  })\'></span>\n						<i class="b-customer__allow-edit g-icon-edit"></i>\n					</span>\n				</div>\n				<div class="l-grid-layout-33">\n					<span class="pos-rel">\n						<span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "email", label: L10N.get("bookingForm.email"), classes: "b-email", placeholder: L10N.get("bookingForm.emailPlaceholder"), attrs: {readonly: true}  })\'></span>\n						<i class="b-customer__allow-edit g-icon-edit"></i>\n					</span>\n				</div>\n			</div>\n		</div>\n	';
 } else { ;
__p += '\n		<div class="b-customer__info toggle-customer show">\n			<div class="l-grid-container">\n				<div class="l-grid-layout-33">\n                    ';
 if (this.contactAutocompleteAvailable) { ;
__p += '\n                    <span class="js-widget"  onclick=\'return new Widgets.ContactAutocomplete({ bindingProperty: "lastName", label: L10N.get("bookingForm.lastName"), placeholder: L10N.get("bookingForm.lastNamePlaceholder"), clientUid: "'+ clientUid +'", disableFreeTextInput: "'+ contactPersonDisableFreeTextInput +'", attrs: {readonly: "' + !contactPersonEditGranted + '", disabled: "' + !contactPersonEditGranted + '"} })\'></span>\n                    ';
 } else { ;
__p += '\n                    <span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "lastName", label: L10N.get("bookingForm.lastName"), placeholder: L10N.get("bookingForm.lastNamePlaceholder"), attrs: {readonly: "' + !contactPersonEditGranted + '"} })\'></span>\n                    ';
 } ;
__p += '\n				</div>\n				<div class="l-grid-layout-33">\n					<span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "firstName", label: L10N.get("bookingForm.firstName"), placeholder: "' + l10nFirstNamePlaceholder + '", attrs: {readonly: "' + !contactPersonEditGranted + '", disabled: "'+ contactPersonDisableFreeTextInput +'" } })\'></span>\n				</div>\n\n				';
 if (this.model.get('overrideCustomer') === true) { ;
__p += '\n					<div class="l-grid-layout-33">\n						<i class="b-customer__attention g-icon-attention" data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( L10N.get('bookingForm.customerDataWillBeChanged') )) == null ? '' : __t) +
'"></i>\n					</div>\n				';
 } ;
__p += '\n			</div>\n			<div class="l-grid-container">\n				<div class="l-grid-layout-33">\n					<span class="js-widget"  onclick=\'return new Widgets.Phone({ bindingProperty: "phone", label: L10N.get("bookingForm.phone"), placeholder: L10N.get("bookingForm.phonePlaceholder"), disabled: "' + !contactPersonEditGranted + '" })\'></span>\n				</div>\n				<div class="l-grid-layout-33">\n					<span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "email", label: L10N.get("bookingForm.email"), classes: "b-email", placeholder: L10N.get("bookingForm.emailPlaceholder"), attrs: {readonly: "' + !contactPersonEditGranted + '"} })\'></span>\n				</div>\n			</div>\n		</div>\n	';
 } ;
__p += '\n</section>\n';

}
return __p
}