module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-cabinet-collection">\r\n	<div class="l-grid-container">\r\n		<div class="l-grid-layout-100 b-cabinet-collection__content">\r\n			';
 if (aclCreateGranted) { ;
__p += '<span class="b-cabinet-collection__add"></span> ';
 } ;
__p += '\r\n		</div>\r\n	</div>\r\n</div>\r\n';

}
return __p
}