module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-transfers">\n    <div class="b-transfers__results"></div>\n\n    <div class="b-transfers__exceeded-capacity dn"></div>\n\n    <div class="b-message b-transfers__search-no-results dn">\n        ' +
((__t = ( L10N.get('transfers.nothingFound') )) == null ? '' : __t) +
' <span class="action-link b-transfers__search-filters-reset">' +
((__t = ( L10N.get('transfers.dropFilters') )) == null ? '' : __t) +
'</span>.\n    </div>\n</div>\n';

}
return __p
}