module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="p-login__form-block p-login__form-password">\r\n		';
 if (withLabel) { ;
__p += '\r\n			<div class="p-login__form-title p-login__form-password-title">' +
((__t = ( L10N.get('AccountLoginForm.password') )) == null ? '' : __t) +
'</div>\r\n		';
 } ;
__p += '\r\n		<input type="' +
((__t = ( type )) == null ? '' : __t) +
'" placeholder="' +
((__t = (placeholder )) == null ? '' : __t) +
'" ' +
((__t = ( elementName != null ? 'name="' + elementName + '"' : '' )) == null ? '' : __t) +
' ' +
((__t = ( elementId != null ? 'id="' + elementId + '"' : '' )) == null ? '' : __t) +
' ' +
((__t = ( autocomplete != null ? 'autocomplete="' + autocomplete + '"' : '' )) == null ? '' : __t) +
' class="p-login__form-input p-login__form-password-input ' +
((__t = ( classes )) == null ? '' : __t) +
'">\r\n		';
 if (showPasswordButton) { ;
__p += '\r\n			<div class="b-password__show"></div>\r\n		';
 } ;
__p += '\r\n</div>\r\n';

}
return __p
}