module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-train-route">\r\n	<div class="b-train-route__header">\r\n		' +
((__t = ( L10N.get('trains.mainRoute') )) == null ? '' : __t) +
'\r\n	</div>\r\n	<div class="b-train-route__info">\r\n                <div class="b-train-route__stops">\r\n                        ';
 pointInfos.forEach(function(point, i) { ;
__p += '\r\n                                ';
 if (i == 0 || i == (pointInfos.length - 1)) { ;
__p += '\r\n                                        <section class="b-train-route__stop corner-stops"></section>\r\n                                ';
 } else { ;
__p += '\r\n                                        <div class="b-train-route__stop"></div>\r\n                                ';
 } ;
__p += '\r\n                        ';
 }); ;
__p += '\r\n                </div>\r\n		<div class="b-block-table">\r\n        	<div class="b-block-table__row b-block-table__row-header mobile-hide">\r\n        		<div class="b-block-table__cell b-block-table__cell-empty"></div>\r\n        		<div class="b-block-table__cell">' +
((__t = ( L10N.get('trains.arrivalLocation') )) == null ? '' : __t) +
'</div>\r\n        		<div class="b-block-table__cell">' +
((__t = ( L10N.get('trains.arrivalTime') )) == null ? '' : __t) +
'</div>\r\n        		<div class="b-block-table__cell">' +
((__t = ( L10N.get('trains.stopTime') )) == null ? '' : __t) +
'</div>\r\n        		<div class="b-block-table__cell b-block-table__cell-no-border">' +
((__t = ( L10N.get('trains.departureTime') )) == null ? '' : __t) +
'</div>\r\n        		<div class="b-block-table__cell b-block-table__cell-empty"></div>\r\n        	</div>\r\n			';
 pointInfos.forEach(function(point) { ;
__p += '\r\n				<div class="b-block-table__row">\r\n					<div class="b-block-table__cell b-block-table__cell-empty"></div>\r\n					<div class="b-block-table__cell">\r\n                        <span class="b-block-table__mobile-header desktop-hide">' +
((__t = ( L10N.get('trains.arrivalLocation') )) == null ? '' : __t) +
'</span>\r\n                        ' +
((__t = ( point.arrivalLocation )) == null ? '' : __t) +
'\r\n                    </div>\r\n					<div class="b-block-table__cell">\r\n                        <span class="b-block-table__mobile-header desktop-hide">' +
((__t = ( L10N.get('trains.arrivalTime') )) == null ? '' : __t) +
'</span>\r\n                        ' +
((__t = ( point.arrivalTime )) == null ? '' : __t) +
'\r\n                    </div>\r\n					<div class="b-block-table__cell">\r\n                        <span class="b-block-table__mobile-header desktop-hide">' +
((__t = ( L10N.get('trains.stopTime') )) == null ? '' : __t) +
'</span>\r\n                        ' +
((__t = ( point.durationInMinutes > 0 ? Formatter.formatDurationInMinutes(point.durationInMinutes) : '' )) == null ? '' : __t) +
'\r\n                    </div>\r\n					<div class="b-block-table__cell b-block-table__cell-no-border">\r\n                        <span class="b-block-table__mobile-header desktop-hide">' +
((__t = ( L10N.get('trains.departureTime') )) == null ? '' : __t) +
'</span>\r\n                        ' +
((__t = ( point.departureTime )) == null ? '' : __t) +
'\r\n                    </div>\r\n					<div class="b-block-table__cell b-block-table__cell-empty"></div>\r\n				</div>\r\n			';
 }); ;
__p += '\r\n        </div>\r\n	</div>\r\n</div>\r\n';

}
return __p
}