module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-booking-docs-modal">\n    <div class="b-booking-docs-modal__content ">\n        <div class="b-booking-docs-modal__header">\n            <div class="b-booking-docs-modal_header-label">' +
((__t = ( L10N.get('cabinet.orders.bookingDocuments') )) == null ? '' : __t) +
'</div>\n            <a class="b-booking-docs-modal__close-btn g-icon-b-cancel js-close-modal"></a>\n        </div>\n\n        <div class="b-booking-docs-modal__table b-order__info b-docs-table">\n            <table class="b-block-table">\n                <tr class="b-block-table__row b-block-table__row-header">\n                    <th class="b-block-table__cell b-block-table__cell-empty"></th>\n                    <th class="b-block-table__cell b-block-table__cell-type-checkbox">\n                        <span><span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "selectedDocuments.allDocuments"})\'></span></span>\n                        <span class="desktop-hide">' +
((__t = ( L10N.get('cabinet.orders.selectAll') )) == null ? '' : __t) +
'</span>\n                    </th>\n                    <th class="b-block-table__cell">' +
((__t = ( L10N.get('cabinet.finance.table.documentType') )) == null ? '' : __t) +
'</th>\n                    <th class="b-block-table__cell">' +
((__t = ( L10N.get('cabinet.finance.table.external') )) == null ? '' : __t) +
'</th>\n                    <th class="b-block-table__cell">' +
((__t = ( L10N.get('cabinet.finance.table.createdDate') )) == null ? '' : __t) +
'</th>\n                    <th class="b-block-table__cell">' +
((__t = ( L10N.get('cabinet.finance.table.name') )) == null ? '' : __t) +
'</th>\n                    <th class="b-block-table__cell">' +
((__t = ( L10N.get('cabinet.finance.table.description') )) == null ? '' : __t) +
'</th>\n                    <th class="b-block-table__cell">' +
((__t = ( L10N.get('cabinet.orders.download') )) == null ? '' : __t) +
'</th>\n                    <th class="b-block-table__cell b-block-table__cell-empty"></th>\n                </tr>\n                ';
 _.each(this.documents, function(d) {
                    if (!d) return;
                    let description = '';
                    if (d.travelSubject) {
                        description += `${d.travelSubject.caption}; `;
                    }
                    if (d.ticketNumbers && d.ticketNumbers.length) {
                        description += `${d.ticketNumbers.join(', ')}; `;
                    }
                    if (d.recordLocator) {
                        description += `${d.recordLocator}; `;
                    }
                    if (d.travellersNames && d.travellersNames.length) {
                        description += `${d.travellersNames.join(', ')} `;
                    }
                ;
__p += '\n                <tr class="b-block-table__row">\n                    <td class="b-block-table__cell b-block-table__cell-empty"></td>\n                    <td class="b-block-table__cell b-block-table__cell-type-checkbox b-block-table__cell--checkobx-with-caption">\n                        <span><span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "selectedDocuments.' + d.uid + '.selected"})\'></span></span>\n                        <span class="b-block-table__cell-header-mobile desktop-hide">' +
((__t = ( L10N.get('cabinet.orders.selectDocument') )) == null ? '' : __t) +
'</span>\n                    </td>\n                    <td class="b-block-table__cell">\n                        <span class="b-block-table__cell-header-mobile desktop-hide">' +
((__t = ( L10N.get('cabinet.finance.table.documentType') )) == null ? '' : __t) +
'</span>\n                        <span class="b-order__info-ticket-number">' +
((__t = ( (d.documentType || {}).caption || '----' )) == null ? '' : __t) +
'</span>\n                    </td>\n                    <td class="b-block-table__cell">\n                        <span class="b-block-table__cell-header-mobile desktop-hide">' +
((__t = ( L10N.get('cabinet.finance.table.external') )) == null ? '' : __t) +
'</span>\n                        <span class="b-order__info-ticket-number">' +
((__t = ( L10N.get(d.external ? 'Common.yes' : 'Common.no') )) == null ? '' : __t) +
'</span>\n                    </td>\n                    <td class="b-block-table__cell">\n                        <span class="b-block-table__cell-header-mobile desktop-hide">' +
((__t = ( L10N.get('cabinet.finance.table.createdDate') )) == null ? '' : __t) +
'</span>\n                        <span class="b-order__info-ticket-number">' +
((__t = ( d.created ? new Time(d.created).toDateString() : '----' )) == null ? '' : __t) +
'</span>\n                    </td>\n                    <td class="b-block-table__cell">\n                        <span class="b-block-table__cell-header-mobile desktop-hide">' +
((__t = ( L10N.get('cabinet.finance.table.name') )) == null ? '' : __t) +
'</span>\n                        <span class="b-order__info-ticket-number">' +
((__t = ( d.title || '----' )) == null ? '' : __t) +
'</span>\n                    </td>\n                    <td class="b-block-table__cell">\n                        <span class="b-block-table__cell-header-mobile desktop-hide">' +
((__t = ( L10N.get('cabinet.finance.table.description') )) == null ? '' : __t) +
'</span>\n                        <span class="b-order__info-ticket-number">' +
((__t = ( description && description.length ? description : '----' )) == null ? '' : __t) +
'</span>\n                    </td>\n                    <td class="b-block-table__cell">\n                        <div class="b-block-table__cell-content-container" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="<div>' +
((__t = ( d.name )) == null ? '' : __t) +
'</div>">\n                            <span class="b-order__info-ticket-number b-docs-table__download g-icon-download js-download-single" data-doc-uid="' +
((__t = ( d.uid )) == null ? '' : __t) +
'" data-class-name="' +
((__t = ( d.className )) == null ? '' : __t) +
'"></span>\n                            <span class="b-block-table__cell-header-mobile desktop-hide">\n                                ' +
((__t = ( L10N.get('cabinet.orders.download') )) == null ? '' : __t) +
'\n                            </span>\n                        </div>\n                    </td>\n                    <td class="b-block-table__cell b-block-table__cell-empty"></td>\n                </tr>\n                ';
 }); ;
__p += '\n            </table>\n        </div>\n\n        <div class="b-booking-docs-modal__footer">\n            <div class="b-booking-docs-modal__comment">\n                <span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "comment", placeholder: L10N.get("cabinet.orders.comment"), classes: "vertical-resize" })\'></span>\n            </div>\n            <div class="b-booking-docs-modal__emails js-emails-container"></div>\n            <button type="button" class="b-booking-docs-modal__send-btn btn js-send-booking-docs">' +
((__t = ( L10N.get('cabinet.orders.send') )) == null ? '' : __t) +
'</button>\n            <button type="button" class="b-booking-docs-modal__send-btn btn js-download-booking-docs">' +
((__t = ( L10N.get('cabinet.orders.download') )) == null ? '' : __t) +
'</button>\n        </div>\n    </div>\n</div>\n';

}
return __p
}