// eslint-disable-next-line banned-modules
'use strict';

import fareRulesTemplate from '@/blocks/elements/b-fare-rules/fareRules';
import axios from 'axios';
import $ from 'jquery';
import BaseModel from '@/classes/base.model';
import PikadayModalView from '@/blocks/pages/b-search-form/b-pikaday-mobile-modal';
import BaseCollection from '@/classes/base.collection';

const MIME_EXTENSION_MAP = {
	'.bz': 'application/x-bzip',
	'.bz2': 'application/x-bzip2',
	'.epub': 'application/epub+zip',
	'.gz': 'application/gzip',
	'.pdf': 'application/pdf',
	'.rar': 'application/vnd.rar',
	'.tar': 'application/x-tar',
	'.zip': 'application/zip',
	'.7z': 'application/x-7z-compressed',
	'.xml': 'application/xml',
	'.json': 'application/json',
	'.html': 'application/html',
	'.txt': 'text/plain',
};
class GlobalUtils {
	static createPikadayModalView(model) {
		if (!STATE.checkViewport('(max-width: 768px)')) return null;
		const searchModel = STATE.getSearchModel();
		const travelSubject = !_.isEmpty(model.get('serviceTypes'))
			? model.get('serviceTypes').at(0).toJSON()
			: searchModel.get('serviceTypes').at(0).toJSON();

		return new PikadayModalView({
			travelSubject,
			model,
			cid: model.cid,
			searchModel,
		});
	}

	static showPikadayModal(e, pikadayModal, classToSetShowFlag = '.l-layout') {
		if (!STATE.checkViewport('(max-width: 768px)')) return;
		if (e != null) {
			e.preventDefault();
			e.stopPropagation();

			const isShowDateTo =
				$(e.target).hasClass('pikaday-modal_show-date-to') ||
				$(e.target).parents('.pikaday-modal_show-date-to').length > 0;

			if (pikadayModal != null) {
				$(classToSetShowFlag).addClass('show-pikaday-modal');
				pikadayModal.show();

				if (isShowDateTo) {
					pikadayModal.dateTo.ui.input.focus();
				} else {
					pikadayModal.dateFrom.ui.input.focus();
				}
			}
		}
	}

	static resetButtonOnScroll(btnsContainerClass) {
		if (typeof btnsContainerClass !== 'string') btnsContainerClass = '.b-filters__drop-filters';
		const btnsContainerEl = document.querySelector(btnsContainerClass);
		const mobileButtons = document.querySelector('.b-filters__mobile-buttons');
		const sideBarEl = document.querySelector('.l-layout__content-side');
		if (!btnsContainerEl || !sideBarEl) {
			return;
		}

		let leftSideHeight = 0;

		const $btnsContainerEl = $(btnsContainerEl);

		if (!$btnsContainerEl.hasClass('fixed')) {
			// поправка на высоту самого контейнера с кнопками
			leftSideHeight -= $btnsContainerEl.get(0).clientHeight;
		}
		$(sideBarEl)
			.children()
			.each(function getHeight() {
				leftSideHeight += $(this).get(0).clientHeight;
			});

		const sideBarHeight = leftSideHeight + sideBarEl.offsetTop;
		const scrollOffset = window.pageYOffset + window.innerHeight;
		const isOffsetGreater = scrollOffset > sideBarHeight;

		$btnsContainerEl.toggleClass('fixed', !isOffsetGreater);
		if (mobileButtons != null) $(mobileButtons).toggleClass('fixed', !isOffsetGreater);
	}

	static openRuleCarrier(e, params, rule16Only = false) {
		// to call func you need to change context of 'this'
		STATE.showLoader();

		axios
			.post('/midoffice/ibecorp-b2b/avia/getFlightRules', {
				parameters: params,
			})
			.then((result) => {
				const ruleCarrierPopupContent = $(
					'<div class="b-fare-rules__rule-booking" />',
				);
				const ruleCarrierPopup = new Widgets.Popup({
					content: ruleCarrierPopupContent,
					title: L10N.get('bookingForm.carrierRulesPopupTitle'),
					type: 'info',
					actions: [
						{
							label: L10N.get('bookingForm.carrierRulesPopupAccept'),
							action: () => {
								const input = this.$(e.currentTarget)
									.parents('.b-checkbox')
									.find('input');
								if (!input.is(':checked')) {
									input.trigger('click');
								}
								ruleCarrierPopup.hide();
							},
						},
					],
					classes: 'b-fare-rules__popup-rule-carrier',
				});
				const arrRules = rule16Only
					? [_.find(result.data.result.rules, (rule) => rule.number === '16')]
					: result.data.result.rules;
				STATE.hideLoader();

				ruleCarrierPopupContent.append(
					fareRulesTemplate({ arrRules, rule16Only }),
				);
				ruleCarrierPopup.show();

				const switchButtonHandler = (event) => {
					if (event != null) {
						event.preventDefault();
					}

					ruleCarrierPopup.$el.find('.b-fare-rules__full-rules-list').show();
					ruleCarrierPopup.$el.find('.b-fare-rules__penalty-list').hide();
				};

				let showTranslated = null;
				let translated = [];

				const translateButtonHandler = (event) => {
					if (event) {
						event.preventDefault();
					}
					if (showTranslated !== null) {
						showTranslated = !showTranslated;
						ruleCarrierPopupContent.html(
							fareRulesTemplate({
								arrRules: translated,
								showTranslated,
								rule16Only,
							}),
						);
						ruleCarrierPopup.$el.find('.b-fare-rules__full-rules-list').show();
						return;
					}

					if (showTranslated === null) {
						GlobalUtils.translateCarrierRules(arrRules).then((res) => {
							showTranslated = true;
							translated = res;
							ruleCarrierPopupContent.html(
								fareRulesTemplate({
									arrRules: translated,
									showTranslated,
									rule16Only,
								}),
							);
							ruleCarrierPopup.$el
								.find('.b-fare-rules__full-rules-list')
								.show();
						});
					}
				};

				ruleCarrierPopup.$el.on(
					'click',
					'.b-fare-rules__switch-button',
					switchButtonHandler,
				);

				ruleCarrierPopup.$el.on(
					'click',
					'.b-fare-rules__translate-button',
					translateButtonHandler,
				);
			})
			.catch(() => {
				STATE.hideLoader();
				Widgets.Popup.showUnknownErrorPopup();
			});
	}

	static translateCarrierRules(rules = []) {
		const promises = [];
		_.each(rules, (el) => {
			const params = {
				parameters: {
					outputLang: 'ru',
					text: el.title ? `${el.text}||${el.title}` : el.text,
				},
			};
			promises.push(axios.post('/midoffice/ibecorp-b2b/translate', params));
		});
		return Promise.all(promises).then((responses) => {
			_.each(rules, (el, i) => {
				const res = responses[i];
				if (res) {
					const [text, title] = res.data.result.text.split('||');
					if (el) {
						el.translatedText = text;
						el.translatedTitle = title;
					}
				}
			});
			return rules;
		});
	}

	static unsetPageOnFilterChange(model) {
		// to call func you need to change context of 'this'
		const attrs = _.omit(model.attributes, ['page', 'count', 'pageSize']);
		_.each(attrs, (value, key) => {
			if (_.isEmpty(value) || !value) delete attrs[key];
		});

		if (!_.isEmpty(attrs)) {
			if (this.pageBeforeFiltersApplied == null) this.pageBeforeFiltersApplied = model.get('page');
			model.set('page', 0, { silent: true });
		} else {
			if (this.pageBeforeFiltersApplied != null) {
				model.set('page', this.pageBeforeFiltersApplied, { silent: true });
				this.pageBeforeFiltersApplied = null;
			}
		}
	}

	static autocompletePassengerFields(
		passenger,
		nameFields,
		langRegistrationFullName,
		passengerFields,
		skipLang = false,
	) {
		// to call func you need to change context of 'this'
		if (passenger == null) return null;
		const data = _.defaults(
			_.pick(passenger, passengerFields),
			_.object(passengerFields, []),
		);

		if (!skipLang) {
			_.each(nameFields, (field) => {
				switch (langRegistrationFullName.uid) {
					case 'LATIN':
						data[field] = data[`${field}Lat`] || passenger[`${field}Lat`];
						delete data[`${field}Lat`];
						break;
					case 'CYRILLIC':
						data[field] = data[field] || passenger[field];
						break;
					case 'LATIN_AND_CYRILLIC':
						data[field] =
							data[`${field}Lat`] ||
							passenger[`${field}Lat`] ||
							data[field] ||
							passenger[field];
						break;
				}
			});
		}

		const keyToModelKey = (key) => {
			switch (key) {
				case 'firstNameLat':
					return 'firstName';
				case 'lastNameLat':
					return 'lastName';
				case 'middleNameLat':
					return 'middleName';
			}
			return key;
		};

		_.each(data, (value, key) => {
			this.model.set(`passport.${keyToModelKey(key)}`, value);
		});

		if (passenger.birthDate != null) {
			this.model.set('birthDate', passenger.birthDate);
			this.model.set('passport.birthDate', passenger.birthDate);
		} else {
			this.model.set('birthDate', null);
		}

		if (passenger.uid != null) {
			this.model.set('uid', passenger.uid);
		}

		this.model.set('withoutMiddleName', passenger.withoutMiddleName);

		this.model.set('email', passenger.emails[0]);
		if (_.isObject(passenger.phones[0])) {
			this.model.set('phone', passenger.phones[0]);
		} else {
			this.model.set('phone', {
				cityCode: '',
				countryCode: STATE.getDefaultCountryCode(),
				number: '',
			});
		}

		return data;
	}

	static autocompletePassport(
		passport,
		passenger,
		passportFields,
		useLang,
		skipNameFilling = false,
	) {
		// to call func you need to change context of 'this'
		let data = {};
		const isLatin = useLang === 'LATIN';
		let nameFieldsFromPassport = [];
		if (useLang === 'LATIN') {
			nameFieldsFromPassport = ['firstNameLat', 'lastNameLat', 'middleNameLat'];
		} else if (useLang === 'CYRILLIC') {
			nameFieldsFromPassport = ['firstName', 'lastName', 'middleName'];
		}
		if (
			passport != null &&
			passenger != null &&
			!_.isEmpty(passenger.passports)
		) {
			const passportExists = passport.uid.indexOf('.') !== -1;

			data = _.pick(
				_.find(
					passenger.passports,
					(el) => el.type.uid + (passportExists ? `.${el.number}` : '') ===
						passport.uid,
				) || {},
				[...passportFields, ...nameFieldsFromPassport, 'withoutMiddleName'],
			);
		}
		data = _.defaults(
			data,
			_.omit(
				_.object([...passportFields, ...nameFieldsFromPassport], []),
				'birthDate',
			),
		);

		if (isLatin) {
			data.firstName = data.firstNameLat;
			data.lastName = data.lastNameLat;
			data.middleName = data.middleNameLat;
			delete data.firstNameLat;
			delete data.lastNameLat;
			delete data.middleNameLat;
		}
		if (!skipNameFilling) {
			_.each(data, (value, key) => {
				this.model.set(`passport.${key}`, value);
			});
			this.model.set('withoutMiddleName', data.withoutMiddleName);
		}

		if (passport != null) {
			const [type] = passport.uid.split('.');

			if (type === 'INTERNAL' || type === 'FOREIGN') {
				const RU = STATE.get('countries').find((el) => el.uid === 'RU');

				if (!data.originCountry) {
					this.model.set('passport.originCountry', RU);
				}
				if (!data.citizenship) {
					this.model.set('passport.citizenship', RU);
				}
			}
		}
	}

	/* NameFields handlers */

	static getDefaultNameFields = () => {
		const nameFieldsLat = [
			{
				dataFieldName: 'firstNameLat',
				modelFieldName: 'firstName',
			},
			{
				dataFieldName: 'lastNameLat',
				modelFieldName: 'lastName',
			},
			{
				dataFieldName: 'middleNameLat',
				modelFieldName: 'middleName',
			},
		];

		const nameFieldsCyr = [
			{
				dataFieldName: 'firstName',
				modelFieldName: 'firstName',
			},
			{
				dataFieldName: 'lastName',
				modelFieldName: 'lastName',
			},
			{
				dataFieldName: 'middleName',
				modelFieldName: 'middleName',
			},
		];

		return {
			nameFieldsLat,
			nameFieldsCyr,
		};
	};

	static assignNameFieldsByUseLang = ({
		nameFieldsCyr,
		nameFieldsLat,
		nameFields,
		useLang,
	} = {}) => {
		if (useLang === 'LATIN') {
			nameFields = nameFieldsLat;
		} else if (useLang === 'CYRILLIC') {
			nameFields = nameFieldsCyr;
		}
		return nameFields;
	};

	/* 
		Проверяем если у пассажира нет двуязычности и доступен выбор на двух языках,
		то заполняем данные из того языка который имеет.
	*/
	static fillNameFieldsIfAvailable = ({
		passport,
		model,
		useCyrillicNameFieldsForInternalPassport = false,
		langRegistrationFullName,
		nameFields,
		nameFieldsCyr,
		nameFieldsLat,
		useLang,
	} = {}) => {
		const useLangTwoLocales = model.get('useLangTwoLocales');
		if (
			!useLangTwoLocales &&
			langRegistrationFullName.uid === 'LATIN_AND_CYRILLIC'
		) {
			let hasLangData = false;
			nameFields.forEach((nameField) => {
				if (passport[nameField.dataFieldName]) {
					hasLangData = true;
				}
			});
			if (!hasLangData) {
				nameFields = this.assignNameFieldsByUseLang({
					nameFieldsCyr,
					nameFieldsLat,
					nameFields,
					useLang,
				});
			}
		}

		/* Будет касаться всех типов документов. */

		const hasFields = (nFields, pass = {}) => nFields.some((nF) => !!pass[nF.dataFieldName]);

		const hasLatinFields = (pass) => hasFields(nameFieldsLat, pass);

		const hasCyrillicFields = (pass) => hasFields(nameFieldsCyr, pass);

		const noNameFields = [];

		switch (langRegistrationFullName.uid) {
			case 'LATIN': {
				nameFields = hasLatinFields(passport) ? nameFieldsLat : noNameFields;
				break;
			}
			case 'CYRILLIC': {
				nameFields = hasCyrillicFields(passport) ? nameFieldsCyr : noNameFields;
				break;
			}
			case 'LATIN_AND_CYRILLIC': {
				/*
					Должно работать только в случае, если у нас есть свитчер между языками у документа и в нем выбран "LATIN"
				*/
				if (useLangTwoLocales && useLang === 'LATIN') {
					if (hasLatinFields(passport)) {
						nameFields = nameFieldsLat;
					} else if (hasCyrillicFields(passport)) {
						nameFields = nameFieldsCyr;
					} else {
						nameFields = noNameFields;
					}
					/*
					Все остальные случаи (нет свитчера или он есть и в нем выбран "CYRILLIC")
				*/
				} else {
					if (hasCyrillicFields(passport)) {
						nameFields = nameFieldsCyr;
					} else if (hasLatinFields(passport)) {
						nameFields = nameFieldsLat;
					} else {
						nameFields = noNameFields;
					}
				}
				break;
			}
			default:
				break;
		}

		if (
			useCyrillicNameFieldsForInternalPassport &&
			passport?.type?.uid === 'INTERNAL' &&
			langRegistrationFullName.uid === 'LATIN'
		) {
			if (hasLatinFields(passport)) {
				nameFields = nameFieldsLat;
			} else if (hasCyrillicFields(passport)) {
				nameFields = nameFieldsCyr;
			}
		}

		return nameFields;
	};

	/* EOF NameFields handlers */

	static fillPassengerData(options) {
		const {
			passengerUid, //  uid текущего пассажира
			model,
			fillOnLoad = false, // логика при первоначальном заполнении
			international = false, // международная поездка
			bookingSettings = {},
			passengerTypeUid, // тип пассажира
			passengerTypeDocument = [], // map типов документов в зависимости от типа пассажира
			userSelectedPassenger = undefined, // если пользователь выбрал путешественника из autocomplete
			userSelectedPassportType = undefined, // если задан, то заполняем из выбранного паспорта
			useCyrillicNameFieldsForInternalPassport = false, // UTS-679
			nameFieldsToSkip = [], // список полей, которые нужно пропустить, когда будем выставлять значения в модель
			nameFieldsToTriggerByTimeout = [], // список полей, которые нужно засеттить, используя setTimeout()
		} = options;
		const {
			langRegistrationFullName,
			useTravellerNameFromDocuments = false,
			corporatePassengers = [],
		} = bookingSettings;

		// langRegistrationFullName - возможность латиници и/или кириллицы в ФИО
		// langRegistrationDocument - возможность латиници и/или кириллицы в номере документа

		// пропускаем, если не достаточно данных
		if (!passengerTypeUid || !passengerTypeDocument.length || !model) {
			return;
		}

		// заполняем, если выбран пассажир
		const useLang = model.get('useLang');

		let nameFields = [];
		// поля для латиницы

		const { nameFieldsCyr, nameFieldsLat } = this.getDefaultNameFields();

		nameFields = this.assignNameFieldsByUseLang({
			nameFieldsCyr,
			nameFieldsLat,
			nameFields,
			useLang,
		});

		// функция проверки наличия 2 локалей в документе для useLang
		const checkPassportForUseLang = (passport) => {
			const twoLocales =
				!!(passport.firstNameLat && passport.firstName) ||
				!!(passport.lastNameLat && passport.lastName) ||
				!!(passport.middleNameLat && passport.middleName);
			model.set('useLangTwoLocales', twoLocales);
		};

		// поля паспорт
		const passportFields = [
			'citizenship',
			'originCountry',
			'number',
			'birthDate',
			'expiredDate',
			'gender',
			'withoutMiddleName',
		];

		// ище текущего пассажира по uid
		let currentPassenger =
			passengerUid &&
			corporatePassengers.length &&
			corporatePassengers.find((corporatePassenger) => {
				return corporatePassenger.uid === passengerUid;
			});

		// Определяем розничного клиента
		let isRetailUser = false;

		// текущий пассажир выбранный пользователем
		if (!currentPassenger && userSelectedPassenger) {
			currentPassenger = userSelectedPassenger;
			isRetailUser = !userSelectedPassenger.isCorporate;
		}

		// получаем список доступных типов документов для типа пассажира
		const availablePassportTypes = passengerTypeDocument.find(
			(passengerTypePassportsMeta) => {
				return (
					passengerTypePassportsMeta.passengerType.uid === passengerTypeUid
				);
			},
		);
		// получаем массив доступных типов
		const availablePassportTypesMap = (
			availablePassportTypes.passportTypes || []
		).map((availablePassportType) => {
			return availablePassportType.uid;
		});

		// Получаем доступные документы для типа пассажира
		const currentPassengerPassports = (
			(currentPassenger && currentPassenger.passports) ||
			[]
		).filter((currentPassengerPassport) => {
			return availablePassportTypes.passportTypes.indexOf(
				(availablePassportType) => {
					return (
						currentPassengerPassport.type &&
						availablePassportType.uid === currentPassengerPassport.type.uid
					);
				},
			);
		});

		if (currentPassenger || (!currentPassenger && userSelectedPassenger)) {
			const setCitizenshipNOriginCountry = (country = 'RU') => {
				const COUNTRY = STATE.get('countries').find((el) => el.uid === country);
				model.set('passport.citizenship', COUNTRY);
				model.set('passport.originCountry', COUNTRY);
			};

			if (fillOnLoad) {
				// определяем тип документа
				let currentPassportType;
				const currentPassengerPassportType = currentPassengerPassports.find(
					(currentPassengerPassport) => {
						return !international
							? currentPassengerPassport.type.uid === 'INTERNAL'
							: currentPassengerPassport.type.uid === 'FOREIGN';
					},
				);

				if (!currentPassengerPassportType) {
					const availablePassengerPassportType =
						availablePassportTypes.passportTypes.find(
							(availablePassportType) => {
								return !international
									? availablePassportType.uid === 'INTERNAL'
									: availablePassportType.uid === 'FOREIGN';
							},
						);
					if (availablePassengerPassportType) {
						currentPassportType = availablePassengerPassportType;
					}
				} else {
					currentPassportType = currentPassengerPassportType.type;
				}

				// получаем первый валидный документ
				let firstValidPassport;

				// заполняем данными из первого доступного документа, если не задан паспорт явно

				// подставляем в модель типа паспорта
				if (currentPassportType) {
					firstValidPassport = currentPassengerPassports.find(
						(currentPassengerPassport) => {
							return (
								currentPassengerPassport.type.uid === currentPassportType.uid
							);
						},
					);

					if (!firstValidPassport && currentPassengerPassports.length) {
						const firstPassport = currentPassengerPassports[0];
						const newUid = {
							uid: `${firstPassport.type.uid}.${firstPassport.number}`,
						};
						const firstPassportType = firstPassport.number
							? { ...firstPassport.type, ...newUid }
							: firstPassport.type;
						currentPassportType = firstPassportType;
						firstValidPassport = firstPassport;
					}

					setTimeout(() => {
						const isUidWithNumber =
							currentPassportType &&
							(currentPassportType.uid || '').split('.').length === 2;
						if (
							firstValidPassport &&
							firstValidPassport.number &&
							!isUidWithNumber
						) {
							const newUid = `${currentPassportType.uid}.${firstValidPassport.number}`;
							model.set('passport.type', {
								...currentPassportType,
								...{ uid: newUid, originalUid: newUid },
							});
						} else {
							model.set(
								'passport.type',
								isUidWithNumber && !currentPassportType.originalUid ? {
									...currentPassportType,
									...{
										originalUid: currentPassportType.uid,
									},
								}
									: currentPassportType,
							);
						}
					}, 0);

					// выставляем citizenship и originCountry для INTERNAL паспорта
					if (currentPassportType.uid === 'INTERNAL') {
						setCitizenshipNOriginCountry();
					}
				}

				// заполняем паспортные данные
				if (firstValidPassport) {
					checkPassportForUseLang(firstValidPassport);

					passportFields.forEach((fieldName) => {
						model.set(`passport.${fieldName}`, firstValidPassport[fieldName]);
					});
				}
				{
					// выставляем uid текущего пользователя
					model.set('uid', currentPassenger.uid);

					// подставляем общую информацию и пассажира
					const {
						emails,
						phones,
						birthDate,
						withoutMiddleName = false,
					} = currentPassenger;
					if (birthDate) {
						model.set('birthDate', birthDate);
						model.set('passport.birthDate', birthDate);
					} else {
						model.set('birthDate', null);
						model.set('passport.birthDate', null);
					}
					model.set('withoutMiddleName', withoutMiddleName === true);
					model.set('passport.withoutMiddleName', withoutMiddleName === true);
					if (currentPassenger.gender) {
						model.set('gender', currentPassenger.gender);
						model.set('passport.gender', currentPassenger.gender);
					} else {
						model.set('gender', null);
						model.set('passport.gender', null);
					}

					model.set('email', emails && emails.length ? emails[0] : null);
					if (phones && phones.length && _.isObject(phones[0])) {
						model.set('phone', phones[0]);
					} else {
						model.set('phone', {
							cityCode: '',
							countryCode: STATE.getDefaultCountryCode(),
							number: '',
						});
					}
				}

				const fillNameNContactsData = (passport = {}) => {
					checkPassportForUseLang(passport);
					nameFields = this.fillNameFieldsIfAvailable({
						passport,
						model,
						useCyrillicNameFieldsForInternalPassport,
						langRegistrationFullName,
						nameFields,
						nameFieldsCyr,
						nameFieldsLat,
						useLang,
					});
					nameFields.forEach((fieldName) => {
						const fieldValue = passport[fieldName.dataFieldName];
						if (!nameFieldsToSkip.includes(fieldName.dataFieldName)) {
							model.set(`passport.${fieldName.modelFieldName}`, fieldValue);
						}
					});
				};

				// заполняем ФИО и контактную информацию
				// тянем информацию из документов
				if (useTravellerNameFromDocuments && firstValidPassport) {
					fillNameNContactsData(firstValidPassport);
					// тянем информацию из основного профиля и подставляем в модель
				} else {
					fillNameNContactsData(currentPassenger);
				}
			} else {
				// заполняем данными из выбранного типа паспорта
				let passportType =
					userSelectedPassportType || model.get('passport.type');

				// заполняем первый паспорт для retail пользователя, если не был выбран ранее тип документа
				if (isRetailUser && !passportType) {
					if (currentPassengerPassports && currentPassengerPassports.length) {
						// фильтруем типы документов по списку доступных
						const currentPassengerAvailablePassports =
							currentPassengerPassports.filter((cpp) => {
								return availablePassportTypesMap.includes(cpp.type.uid);
							});
						if (currentPassengerAvailablePassports.length > 0) {
							passportType = currentPassengerAvailablePassports[0].type;
						}
					}
				}

				if (passportType) {
					const [passportTypeUid, passportTypeNumber] =
						(passportType.originalUid && passportType.originalUid.split('.')) ||
						(passportType.uid && passportType.uid.split('.')) ||
						[];

					// заполняем данные только если предзаполненный паспорт
					if (userSelectedPassportType && !passportTypeNumber) {
						const fieldsToReset = [
							'number',
							'citizenship',
							'originCountry',
							'expiredDate',
						];
						fieldsToReset.forEach((field) => {
							if (!nameFieldsToSkip.includes(field)) {
								model.set(`passport.${field}`, undefined);		
							}
						});
						if (userSelectedPassportType.uid === 'INTERNAL') {
							setCitizenshipNOriginCountry();
						}
						return;
					}

					// ищем паспорт по типу и(если есть) номеру
					const selectedPassports = currentPassengerPassports
						.filter((passport) => {
							return passport.type.uid === passportTypeUid;
						})
						.filter((passport) => {
							if (passportTypeNumber) {
								return passportTypeNumber === passport.number;
							}
							return true;
						});

					// Если у текущего выбранного пользователя нет паспорта по выбранному типу
					// тогда ищем все валидные
					if (
						!passportTypeNumber &&
						selectedPassports &&
						selectedPassports.length === 0 &&
						userSelectedPassenger &&
						!userSelectedPassportType // небыл изменен тип документа
					) {
						const allValidPassport = currentPassengerPassports.filter(
							(passport) => {
								return availablePassportTypesMap.includes(passport.type.uid);
							},
						);

						if (allValidPassport.length) {
							const foreignPassport = allValidPassport.find((validPassport) => {
								return validPassport.type.uid === 'FOREIGN';
							});
							const internalPassport = allValidPassport.find(
								(validPassport) => {
									return validPassport.type.uid === 'INTERNAL';
								},
							);
							if (international) {
								selectedPassports.push(
									foreignPassport || internalPassport || allValidPassport[0],
								);
							} else {
								selectedPassports.push(
									internalPassport || foreignPassport || allValidPassport[0],
								);
							}
							// меняем тип паспорта на выбранный в случае, если небыло совпадений
							model.set('passport.type', selectedPassports[0].type);
							if (userSelectedPassenger) {
								const firstSelectedPassport = selectedPassports[0];
								// дозаполняем данныеми или выбранного пользователя, если отсутствуеют в паспорте
								this.fillPassengerDataForSelectedPassenger(
									model,
									firstSelectedPassport,
									userSelectedPassenger,
									currentPassenger,
								);
							}
						}
					} else if (
						isRetailUser &&
						!userSelectedPassportType &&
						selectedPassports &&
						selectedPassports.length
					) {
						// выставляем тип первого паспорта для retail пользователя, если не был выбран ранее тип документа
						model.set('passport.type', selectedPassports[0].type, {
							silent: true,
						});
						if (userSelectedPassenger) {
							const firstSelectedPassport = selectedPassports[0];
							// дозаполняем данныеми или выбранного пользователя, если отсутствуеют в паспорте
							this.fillPassengerDataForSelectedPassenger(
								model,
								firstSelectedPassport,
								userSelectedPassenger,
								currentPassenger,
							);
						}
					}

					const selectedPassport = selectedPassports[0] || {}; // если нет предзаполненного паспорта, то сбрасываем поля

					/*
						Следующие 3 строчки предназначены только для решения бага из IBECORP-6936.
						Остальные "явления", происходящие из этих изменений, можно считать побочными эффектами.
					*/
					if (selectedPassport.type) {
						model.set(`passport.type`, selectedPassport.type, { silent: true });
					}

					const setModelField = (m, fieldName, fieldValue) => m.set(`passport.${fieldName}`, fieldValue);

					passportFields.forEach((fieldName) => {
						if (nameFieldsToTriggerByTimeout.includes(fieldName)) {
							setTimeout(() => {
								setModelField(model, fieldName, selectedPassport[fieldName]);
							}, 0);
						} else {
							setModelField(model, fieldName, selectedPassport[fieldName]);
						}
					});

					checkPassportForUseLang(selectedPassport);
					nameFields = this.fillNameFieldsIfAvailable({
						passport: useTravellerNameFromDocuments ? selectedPassport : currentPassenger,
						model,
						useCyrillicNameFieldsForInternalPassport,
						langRegistrationFullName,
						nameFields,
						nameFieldsCyr,
						nameFieldsLat,
						useLang,
					});
					nameFields.forEach((fieldName) => {
						const fieldValue = (
							useTravellerNameFromDocuments
								? selectedPassport
								: currentPassenger
						)[fieldName.dataFieldName];

						if (nameFieldsToTriggerByTimeout.includes(fieldName.modelFieldName)) {
							setTimeout(() => {
								setModelField(model, fieldName.modelFieldName, fieldValue);
							}, 0);
						} else {
							setModelField(model, fieldName.modelFieldName, fieldValue);
						}
					});
					this.fillPassengerDataForSelectedPassenger(
						model,
						selectedPassport,
						userSelectedPassenger,
						currentPassenger,
					);

					// выставляем citizenship и originCountry для INTERNAL паспорта
					if (
						selectedPassport.type &&
						selectedPassport.type.uid === 'INTERNAL'
					) {
						setCitizenshipNOriginCountry();
					}
				}
			}
		}
	}

	static fillPassengerDataForSelectedPassenger(
		model,
		firstSelectedPassport,
		userSelectedPassenger,
		currentPassenger = {},
	) {
		if (firstSelectedPassport && userSelectedPassenger) {
			if (
				!model.get('passport.gender') &&
				!firstSelectedPassport.gender &&
				userSelectedPassenger.gender
			) {
				model.set('passport.gender', userSelectedPassenger.gender);
				model.set('gender', userSelectedPassenger.gender);
			}
			if (
				!model.get('passport.birthDate') &&
				!firstSelectedPassport.birthDate &&
				userSelectedPassenger.birthDate
			) {
				model.set('passport.birthDate', userSelectedPassenger.birthDate);
				model.set('birthDate', userSelectedPassenger.birthDate);
			}

			// заполняем контактные данные
			const { emails = [], phones = [] } = currentPassenger;
			if (!model.get('email')) {
				model.set('email', emails && emails.length ? emails[0] : null);
			}

			if (phones && phones.length && _.isObject(phones[0])) {
				model.set('phone', phones[0]);
			} else {
				model.set('phone', {
					cityCode: '',
					countryCode: STATE.getDefaultCountryCode(),
					number: '',
				});
			}
		}
	}

	static async downloadFile(
		response,
		filename,
		blobOptions = { type: 'application/pdf' },
	) {
		STATE.hideLoader();
		if (!response.data || !response.data.byteLength) {
			const noDataPopup = new Widgets.Popup({
				content: L10N.get('errors.noDocuments'),
				type: 'danger',
				actions: [
					{
						label: L10N.get('settings.errorPopupButton'),
						action: () => {
							noDataPopup.hide();
						},
					},
				],
				onClose: () => {
					noDataPopup.hide();
				},
			});
			noDataPopup.show();
			return;
		}

		const { headers = {} } = response;
		const disposition = headers['content-disposition'];

		if (disposition) {
			const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
			const matches = filenameRegex.exec(disposition);
			if (matches != null && matches[1]) {
				filename = matches[1].replace(/['"]/g, '');
				filename = filename.replace(/utf-8/g, '');
			}
		}

		if (filename == null || filename === '') filename = 'unknown';

		if (filename.indexOf('.') !== -1) {
			const fileExt = filename.slice(
				filename.lastIndexOf('.'),
				filename.length,
			);
			blobOptions.type = MIME_EXTENSION_MAP[fileExt] || blobOptions.type;
		}

		const blob = new Blob([response.data], blobOptions);
		const link = document.createElement('a');

		link.href = window.URL.createObjectURL(blob);
		link.download = decodeURIComponent(filename || '');

		link.click();
	}

	static convertToValidPassenger(passenger) {
		if (!_.isObject(passenger)) {
			return;
		}
		passenger.phones = [_.clone(passenger.phone)];
		passenger.emails = [_.clone(passenger.email)];
		passenger.firstName = passenger.passport.firstName;
		passenger.lastName = passenger.passport.lastName;
		passenger.gender = passenger.passport.gender;

		if (passenger.passport.middleName !== undefined) {
			passenger.passport.withoutMiddleName = false;
			passenger.middleName = passenger.passport.middleName;
		} else {
			passenger.passport.withoutMiddleName = true;
		}

		passenger.passports = [_.clone(passenger.passport)];
		delete passenger.passport;
		delete passenger.phone;
		delete passenger.email;
	}

	// ticket popup
	static popupOffersViewInitialize(options) {
		// to call func you need to change context of 'this'
		this.setOptions(options);

		const searchParams = STORE.get(STATE.ROUTES.MAIN_SEARCH);
		this.passengersToSend = searchParams.passengers || [];
		this.clientToSend = searchParams.client || {};
		if (!_.isEmpty(searchParams) && !_.isEmpty(searchParams.passengers)) {
			this.passengers = _.map(searchParams.passengers, (p) => p && p.caption).filter((passenger) => passenger);
		} else if (!_.isEmpty(searchParams.passengersTypes)) {
			this.passengersCount = _.reduce(
				_.values(searchParams.passengersTypes),
				(sum, o) => sum + o,
				0,
			);
		}

		// try to calculate travellers count
		if (_.isEmpty(this.passengers) && _.isEmpty(this.passengersCount)) {
			const { rooms = [] } = searchParams;
			if (rooms.length > 0) {
				const {travellers = []} = rooms[0];
				this.passengers = undefined;
				this.passengersCount = travellers.length;
			}
		}

		this.model = new BaseModel();
		if (STATE.checkSiteType('B2B')) {
			GlobalUtils.getEmails.call(this).finally(() => this.render());
		} else {
			this.render();
		}
	}

	static getEmails() {
		// to call func you need to change context of 'this'
		const params = {
			parameters: {
				orgRef: this.clientToSend,
			},
		};
		if (!_.isEmpty(this.passengersToSend)) {
			params.parameters.travellers = this.passengersToSend;
		}

		return axios
			.post('/midoffice/ibecorp-b2b/search/getCorporateEmails', params)
			.then((response) => {
				this.model.set(
					'autoSuggestEmails',
					GlobalUtils.getEmailsList(response),
				);
			});
	}

	static getEmailsList(response) {
		let emails = [];
		_.each(response.data.result, (passenger) => {
			_.each(passenger.emails, (email) => {
				emails = [...emails, { fio: passenger.fio, email }];
			});
		});
		return emails;
	}

	static updateOffersOrder(orderToken) {
		// to call func you need to change context of 'this'
		const offers = [];
		_.each(orderToken, (token) => {
			const offer = _.find(this.offers, (o) => o.flightToken === token);

			if (offer != null) {
				offers.push(offer);
			} else {
				throw Error('Error order: offer not found in list');
			}
		});

		this.offers = offers;
		this.options.parent.approvalOffers = offers;
	}

	static syncModalWidthPosition() {
		// to call func you need to change context of 'this'
		const $ticketContainer = this.$ticketContainer;

		// Ticket card for sync width and position right
		if (this.ticketModalOffers != null && $ticketContainer instanceof $) {
			const paddingRight = $('.l-layout__wrapper').css('paddingRight');
			const width =
				parseInt($ticketContainer.outerWidth(), 10) +
				parseInt(paddingRight, 10);

			this.ticketModalOffers.$el.css({ width, paddingRight });
		}
	}

	static handleDrag(e) {
		// to call func you need to change context of 'this'
		const x = e.clientX;
		const y = e.clientY;

		const $selectedItem = $(e.currentTarget).closest('.drag-sort-element');
		const list = $selectedItem[0].parentNode;

		$selectedItem.addClass('drag-sort-active');

		let swapItem =
			document.elementFromPoint(x, y) === null
				? $selectedItem[0]
				: document.elementFromPoint(x, y);
		if (list === swapItem.parentNode) {
			swapItem =
				swapItem !== $selectedItem[0].nextSibling
					? swapItem
					: swapItem.nextSibling;
			list.insertBefore($selectedItem[0], swapItem);
		}
	}

	static setDocumentPlaceholder(type) {
		let placeholder;

		switch (type) {
			case 'INTERNAL':
			case 'BACKTOUSSRCERTIFICATE':
				placeholder = '1234567890';
				break;
			case 'PASSPORT':
				placeholder = 'N12345678';
				break;
			case 'BIRTHDAY_NOTIFICATION':
				placeholder = '';
				break;
			case 'MILITARYID':
				placeholder = 'ВБАА123456';
				break;
			case 'SEAMANSID':
				placeholder = '1234567';
				break;
			case 'IDENTITYCARD':
				placeholder = 'БГ123456';
				break;
			default:
				placeholder = '123456789';
				break;
		}

		return placeholder;
	}

	static calculateExpiredDate(personBirthDate) {
		const birthDate = new Time(personBirthDate).moment;
		const personAge = new Time().moment.diff(birthDate, 'years');

		if (personAge < 20) {
			return birthDate.add(20, 'years').add(40, 'days').format('YYYY-MM-DD');
		}

		if (personAge < 45) {
			return birthDate.add(45, 'years').add(40, 'days').format('YYYY-MM-DD');
		}

		return birthDate.add(120, 'years').add(40, 'days').format('YYYY-MM-DD');
	}

	static tablePageHistoryHandler(e) {
		// to call func you need to change context of 'this'
		if (!e.altKey) return;
		const current = _.find(this.tablePageHistory, (el) => {
			return parseInt(el) === parseInt(this.model.get('page'));
		});
		let result;
		if ([8, 37].includes(e.keyCode)) {
			STATE.setIsTablePage(true);
			setTimeout(() => STATE.setIsTablePage(false), 150);
			result =
				this.tablePageHistory[this.tablePageHistory.indexOf(current) - 1];
			if (result == null) return;
			this.model.set('page', result, { silent: true });
			this.apply();
		}
		if (e.keyCode === 39) {
			STATE.setIsTablePage(true);
			setTimeout(() => STATE.setIsTablePage(false), 150);
			result =
				this.tablePageHistory[this.tablePageHistory.indexOf(current) + 1];
			if (result == null) return;
			this.model.set('page', result, { silent: true });
			this.apply();
		}
	}

	static updateGeocoderLocations(searchFunction, searchModel) {
		return new Promise((resolve) => {
			const transferType = searchModel.get('transferType');
			const endPlaceDefined = transferType.uid === 'TRANSFER';
			const subAddressesDefined =
				transferType.uid === 'TRANSFER' &&
				!_.isEmpty(searchModel.get('subAddresses').models);
			const promises = [];

			promises.push(
				axios.post('/midoffice/ibecorp-b2b/geocoder/update', {
					parameters: { ..._.omit(searchModel.get('startPlace'), ['address']) },
				}),
			);
			if (endPlaceDefined) {
				promises.push(
					axios.post('/midoffice/ibecorp-b2b/geocoder/update', {
						parameters: { ..._.omit(searchModel.get('endPlace'), ['address']) },
					}),
				);
			}
			if (subAddressesDefined) {
				_.each(searchModel.get('subAddresses').models, (item) => {
					promises.push(
						axios.post('/midoffice/ibecorp-b2b/geocoder/update', {
							parameters: { ..._.omit(item.get('location'), ['address']) },
						}),
					);
				});
			}
			Promise.all(promises).then((res) => {
				let index = 0;
				searchModel.set('startPlace', {
					...res[index].data.result,
					address: res[index].data.result.name,
				});
				index++;
				if (endPlaceDefined) {
					searchModel.set('endPlace', {
						...res[index].data.result,
						address: res[index].data.result.name,
					});
					index++;
				}
				if (subAddressesDefined) {
					const subAddresses = [];
					_.each(searchModel.get('subAddresses').models, () => {
						subAddresses.push({
							location: {
								...res[index].data.result,
								address: res[index].data.result.name,
							},
						});
						index++;
					});
					searchModel.set('subAddresses', new BaseCollection(subAddresses));
				}
				if (_.isFunction(searchFunction)) searchFunction();
				resolve();
			});
		});
	}

	static geocoderLocationUpdateNeeded(searchModel) {
		let updateLocations = false;
		_.each(
			[searchModel.get('startPlace'), searchModel.get('endPlace')],
			(item, i) => {
				if (!item || (searchModel.get('transferType').uid === 'RENT' && i > 0)) return;
				if (
					!item.hasOwnProperty('longitude') ||
					!item.hasOwnProperty('latitude')
				) {
					updateLocations = true;
				}
			},
		);
		return updateLocations;
	}

	static gatherTextToCopyToClipBoard(container) {
		const $container = $(container);
		let result = '';
		const $elms = _.sortBy($(container).find('.js-copy-content'), (el) => {
			if (el.hasAttribute('data-copy-order')) {
				return Number($(el).data('copyOrder'));
			} else {
				return 0;
			}
		});

		const replaceNewline = ($el) => {
			if ($el[0].hasAttribute('data-replace-newline')) {
				const sep = $el.data('replaceNewline');
				$el[0].innerText = ($el[0].innerText || '').replace(/\n/g, sep);
			}
		};

		_.each($elms, (el) => {
			const $el = $(el);
			_.each($el.find('[data-copy-content-from]'), (element) => {
				const $target = $container.find($(element).data('copyContentFrom'));
				element.innerHTML = (
					($target[0] && $target[0].innerText) ||
					''
				).replace(/[^\S\r\n]+/g, ' ');
				replaceNewline($(element));
			});
			_.each($el.find('[data-copy-title-from]'), (element) => {
				const $target = $container.find($(element).data('copyTitleFrom'));
				let value = '';
				const findCopyInTooltip = element.hasAttribute(
					'data-find-copy-in-tooltip',
				);

				if ($target[0] && $target[0].hasAttribute('data-html')) {
					const $tooltip = $(
						$target.data('originalTitle') || $target.data('title'),
					);

					if (findCopyInTooltip) {
						const $tooltipCopyElements = $tooltip.find(
							'.js-copy-content-tooltip',
						);
						_.each($tooltipCopyElements, (e) => {
							const elValue = (e.innerText || '').replace(/[^\S\r\n]+/g, ' ');
							value +=
								e.dataset.noTrimStart === 'true'
									? elValue
									: elValue.trimStart();
						});
					} else {
						value =
							$tooltip.length > 1
								? _.map($tooltip, (item) => item.innerText).join(', ')
								: $tooltip[0].innerText;
					}
				} else {
					value = $target.data('originalTitle') || $target.data('title');
				}
				element.innerHTML = value;
			});
			replaceNewline($el);
			const content = (el.innerText || '')
				.replace(/[^\S\r\n]+/g, ' ')
				.replace(/\n+/g, '\n');
			result += `${content}\n`;
		});
		return `${result}\n`;
	}

	static async copyToClipboard(text) {
		const copiedToClipboardPopup = () => {
			const popup = new Widgets.Popup({
				content: L10N.get('Common.copiedToClipboard'),
				type: 'info',
				actions: [
					{
						label: L10N.get('Common.close'),
						action: () => popup.hide(),
					},
				],
			});
			popup.show();
		};
		if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
			try {
				await navigator.clipboard.writeText(`${text}`);
				copiedToClipboardPopup();
			} catch (e) {
				logger.error(e);
			}
		} else {
			const $textArea = $('<textarea></textarea>');
			const $body = $('body');
			$textArea.val(text);
			$textArea.css({
				top: '0',
				left: '0',
				position: 'fixed',
			});
			$body.append($textArea);
			$textArea.focus();
			$textArea.select();

			try {
				const success = document.execCommand('copy');
				if (success) copiedToClipboardPopup();
			} catch (e) {
				throw e;
			}
			$textArea.remove();
		}
	}
}

window.GlUl = GlobalUtils;

// Fix for ProvidePlugin which properly works only with old module definition
/*eslint-disable */
// module.exports = GlobalUtils;
/*eslint-enable */

export default GlobalUtils;
