// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

export default BaseView.extend({

	template,

	ui: {
		total: '.b-pricing__section-total-price',
		commission: '.b-pricing_commission',
		commissionValue: '.b-pricing_commission-value',
	},

	initialize() {
		this.render();
		this.pageModel = STATE.getPageModel();
		this.listenTo(this.pageModel.get('initiatePayment'), 'change:paymentType', (model, type) => {
			const paymentInfo = this.pageModel.get('checkBooking').paymentInfo;
			const comission = Object.keys(paymentInfo).reduce((result, key) => {
				const temp = Object.keys(paymentInfo[key]).reduce((resultInner, keyInner) => {
					if (keyInner === type) {
						resultInner = paymentInfo[key][keyInner];
					}
					return resultInner;
				}, null);
				if (temp) {
					result = temp;
				}
				return result;
			}, null);

			this.ui.total.text(comission.price.amount);
			// this.ui.commission.show();
			this.ui.commissionValue.text(comission.commission.amount);
		});
	},
});
