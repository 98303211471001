module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="service-type service-type-' +
((__t = ( type )) == null ? '' : __t) +
' ' +
((__t = ( disabled ? 'disabled' : '' )) == null ? '' : __t) +
'">\r\n	<div class="js-select-option service-type-img service-type-' +
((__t = ( type )) == null ? '' : __t) +
'-img" data-service-type="' +
((__t = ( type )) == null ? '' : __t) +
'"></div>\r\n</div>\r\n';

}
return __p
}