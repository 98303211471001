// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';
import SelectModel from '@/blocks/elements/b-filters/widgets/select/model';

export default SelectModel.extend({

	filter(model) {
		const modelValues = model.get(this.get('field')).map(m => m.uid);
		const filterValues = this.get('values').where({value: true}).map(s => s.get('uid'));
		if (filterValues.length === 0) {
			return true;
		}
		let filtred = false;
		filterValues.forEach(f => {
			if (modelValues.findIndex(mv => f === mv) > -1) {
				filtred = true;
			}
		});
		return filtred;
	},

	getValue(value) {
		return this.get('values').findWhere({uid: value.uid});
	},

	collect(model) {
		const field = this.get('field');
		if (field) {
			const modelValue = model.get(field);
			if (modelValue != null && _.isArray(modelValue)) {
				[...modelValue].forEach(value => {
					const current = this.getValue(value);
					if (!current) {
						this.get('values').add(new BaseModel(Object.assign({
							value: false,
							price: value.price,
							currency: this.collection.currency,
							parent: this,
						}, value)));
					} else {
						if (parseFloat(current.get('price')) > parseFloat(value.price)) {
							current.set('price', parseFloat(value.price));
							current.set('currency', this.collection.currency);
						}
					}
				});
			}
		}
	},

});
