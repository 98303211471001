'use strict';

import PersonModel from '@/blocks/pages/b-cabinet/b-cabinet-person/model';

export default PersonModel.extend({

	defaults() {
		return _.extend({}, PersonModel.prototype.defaults());
	},

});
