// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';

export default Widget.extend({

	template,

	events: {
		'change .b-toggle__input': 'change',
	},

	ui: {
		input: '.b-toggle__input',
	},

	initialize() {
		this.render();
	},

	change() {
		this.model.set(this.options.bindingProperty,
			this.ui.input.is(':checked') ? this.options.valueSecond : this.options.valueFirst);
	},

	applyBinding(model) {
		this.model = model;

		this.listenTo(model, `change:${this.options.bindingProperty}`, (m, value) => {
			if (value !== this.options.valueFirst) {
				this.ui.input.prop('checked', true);
				this.$el.removeClass('first');
			} else {
				this.ui.input.prop('checked', false);
				this.$el.addClass('first');
			}
		});

		if (this.model.get(this.options.bindingProperty) !== this.options.valueFirst) {
			this.ui.input.prop('checked', true);
			this.$el.removeClass('first');
		} else {
			this.ui.input.prop('checked', false);
			this.$el.addClass('first');
		}
	},

});
