// eslint-disable-next-line banned-modules
'use strict';

import BaseView from '@/classes/base.view';
import template from './template.ejs';
import SelectWidget from '@/widgets/b-select/index';

export default BaseView.extend({

	template,

	initialize(options) {
		this.model = options.model;
		const reasonCodes = SelectWidget.storeCollection(
			SelectWidget.dictionaryToCollection(options.reasonCode.values));

		this.render({
			reasonCodes,
		});
	},

});
