module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="l-block__back b-filters-hotels">\n	<section class="b-filters l-block__over">\n		<div class="b-filters-hotels__sorting"></div>\n		<div class="b-filters-hotels__categories"></div>\n		<div class="b-filters-hotels__widgets"></div>\n	</section>\n	<div class="b-filters__drop-apply-filters-buttons">\n		<div class="b-filters-hotels__apply" style="display: none">' +
((__t = (L10N.get("filters.applyFilters"))) == null ? '' : __t) +
'</div>\n		<div class="b-filters__drop-filters-button">' +
((__t = (L10N.get("filters.resetFilters"))) == null ? '' : __t) +
'</div>\n	</div>\n</div>\n';

}
return __p
}