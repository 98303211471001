// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';

export default BaseModel.extend({

	toJSON() {
		const result = BaseModel.prototype.toJSON.call(this);
		const json = {};

		const value = _.isObject(result.value) ? result.value.uid : result.value;
		_.each(['costCodeCategoryUid', 'costCodePropertyUid', 'organization'], (field) => {
			if (result[field] != null) {
				json[field] = result[field]; 
			}
		});

		json.name = result.name;
		if (value != null) {
			json.value = value;
		}

		return json;
	},
});
