module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


var currencyCode = this.currencyCode;
;
__p += '\r\n<div class="b-train__seats">\r\n	';
 if (this.filtered) { ;
__p += '\r\n		<div class="b-messages">\r\n			<div class="b-message b-message-error">' +
((__t = ( L10N.get('trains.seatsInDifferentCarriages') )) == null ? '' : __t) +
'.</div>\r\n		</div>\r\n	';
 } else { ;
__p += '\r\n		';

		var carriagesGroups = this.carriagesGroups;
		carriagesGroups.groups.forEach(function(carriagesGroup, idx) { ;
__p += '\r\n			';

			var g = carriagesGroups.genders[idx];
			var cg = carriagesGroups.groups[idx];
			var c = carriagesGroup[0];
			var numbers = {};
			carriagesGroup.forEach(function(cg) {
				numbers[cg.number] = true;
			});
			;
__p += '\r\n			<div class="b-train__carriage ' +
((__t = ( (idx % 2 === 0) ? 'odd' : 'even' )) == null ? '' : __t) +
'" data-id="' +
((__t = ( c._groupId )) == null ? '' : __t) +
'">\r\n                ';
 if (c.carriageCardId !== 'EMPTY_MAP') { ;
__p += '\r\n                    <div class="b-train__carriage-number">\r\n                        ' +
((__t = ( L10N.get('trains.carriage') )) == null ? '' : __t) +
' №\r\n                        ';

                            var carNumbers = Object.keys(numbers)
                                    .sort(function(a, b) {return parseInt(a) - parseInt(b)}).reduce(function(total, current) {
                                        var str = '<span data-number="' + current + '">' + current + '</span>';
                                        total.push(str);
                                        return total;
                                    }, []);
                        ;
__p += '\r\n                        ' +
((__t = ( carNumbers.join(', ') )) == null ? '' : __t) +
'\r\n                    </div>\r\n                ';
 } ;
__p += '\r\n\r\n\r\n				<div class="b-train__carriage-service-class">\r\n					<span class="b-train__carriage-service-class-caption" data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( STATE.escape(c.serviceClass && c.serviceClass.description) )) == null ? '' : __t) +
'">\r\n						' +
((__t = ( c.serviceClass && c.serviceClass.caption ? c.serviceClass.caption : '-' )) == null ? '' : __t) +
'\r\n					</span>\r\n				</div>\r\n\r\n				<div class="b-train__carriage-services train-services-gray">\r\n					';
 carriagesGroup.forEach(function(group, i) { ;
__p += '\r\n						<div class="b-train__carriage-services-group ' +
((__t = ( i === 0 ? "open" : "" )) == null ? '' : __t) +
'" data-carriage-id="' +
((__t = ( group._id )) == null ? '' : __t) +
'">';

							_.each(_.sortBy(group.services, function (s) {
								return s.uid;
							}), function(service) { ;
__p += '\r\n								<div class="train-service__img train-service__img--color-gray train-service__' +
((__t = ( service.uid )) == null ? '' : __t) +
'" data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( STATE.escape(service.caption) )) == null ? '' : __t) +
'"></div>\r\n							';
 }); ;
__p += '\r\n						</div>\r\n					';
 }); ;
__p += '\r\n				</div>\r\n\r\n				<div class="b-train__carriage-info">\r\n					<div class="b-train__carriage-genders">\r\n						';
 g.forEach(function(gg, i) { ;
__p += '\r\n							<div class="b-train__carriage-gender ' +
((__t = ( i === 0 ? "open" : "" )) == null ? '' : __t) +
'" data-carriage-id="' +
((__t = ( cg[i]._id )) == null ? '' : __t) +
'">\r\n								';

								var cgs = [];
								Object.keys(gg).forEach(function(key) {
									if (key === 'MALE') {
										cgs.unshift(L10N.get('trains.maleShort'));
									} else if (key === 'FEMALE') {
										cgs.unshift(L10N.get('trains.femaleShort'));
									}
								});
								if ('NOT_SELECTED' in gg || 'MIX' in gg) { ;
__p += '\r\n									<div class="b-train-carriage-border-text">' +
((__t = ( L10N.get('trains.mixShort') )) == null ? '' : __t) +
'</div>\r\n								';
 } else if (cgs.length > 0) { ;
__p += '\r\n									<div class="b-train-carriage-border-text">' +
((__t = ( cgs.join('/') )) == null ? '' : __t) +
'</div>\r\n								';
 } ;
__p += '\r\n							</div>\r\n						';
 }); ;
__p += '\r\n					</div>\r\n\r\n					';
 if (c.isERegister === true) { ;
__p += '\r\n						<span class="b-train-ticket__tags">\r\n							<span class="red-tag" data-toggle="tooltip" data-html="true" data-placement="bottom" data-original-title="<div class=\'tooltip-left\'>' +
((__t = ( L10N.get('trains.eRegister') )) == null ? '' : __t) +
'</div>">' +
((__t = ( L10N.get('trains.eRegisterShort') )) == null ? '' : __t) +
'</span>\r\n						</span>\r\n					';
 } ;
__p += '\r\n\r\n					';
 carriagesGroup.forEach(function(group, i) { ;
__p += '\r\n						<div class="b-train__carriages-refundable-group ' +
((__t = ( i === 0 ? "open" : "" )) == null ? '' : __t) +
'" data-carriage-id="' +
((__t = ( group._id )) == null ? '' : __t) +
'">\r\n							<div class="b-train__carriage-refundable g-icon-option-refund-exchange-OFF-black" data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( L10N.get('trains.nonRefundable') )) == null ? '' : __t) +
'" style="' +
((__t = ( !group.hasNonRefundableTariff ? 'visibility: hidden' : '' )) == null ? '' : __t) +
'"></div>\r\n						</div>\r\n					';
 }); ;
__p += '\r\n\r\n				</div>\r\n\r\n				<div class="b-train__carriage-tp">\r\n					' +
((__t = ( require('../b-travel-policy.ejs')({
						carriage: c
					}) )) == null ? '' : __t) +
'\r\n				</div>\r\n\r\n				<div class="b-train__carriage-price">\r\n					';
 if (c.price.price != null) { ;
__p += '\r\n						' +
((__t = ( L10N.get('trains.from') )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatMoney(c.price.price) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(currencyCode) )) == null ? '' : __t) +
'\r\n					';
 } else if (c.price.priceRange != null) { ;
__p += '\r\n						' +
((__t = ( L10N.get('trains.from') )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatMoney(c.price.priceRange.min) )) == null ? '' : __t) +
'\r\n						' +
((__t = ( L10N.get('trains.to') )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatMoney(c.price.priceRange.max) )) == null ? '' : __t) +
'\r\n						' +
((__t = ( Formatter.formatCurrency(currencyCode) )) == null ? '' : __t) +
'\r\n					';
 } ;
__p += '\r\n				</div>\r\n			</div>\r\n		';
 }) ;
__p += '\r\n	';
 } ;
__p += '\r\n</div>\r\n';

}
return __p
}