module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'travel-policy-status travel-policy-status--' +
((__t = ( travelPolicyStatus.uid )) == null ? '' : __t) +
'\'>\n  <span class=\'travel-policy-status__icon\'></span>\n  <span class="travel-policy-status__label">' +
((__t = ( travelPolicyStatus && travelPolicyStatus.caption )) == null ? '' : __t) +
'</span>\n</div>';

}
return __p
}