module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-passengers__child-age__item">\n	<label for="">\n		' +
((__t = (L10N.get('searchForm.childAgeItem'))) == null ? '' : __t) +
' ' +
((__t = ( number + 1 )) == null ? '' : __t) +
':\n	</label>\n\n	<select class="b-passengers__child-age__item-input ' +
((__t = ( 'b-passengers__child-age__item-input-' + number )) == null ? '' : __t) +
'" value="' +
((__t = ( value )) == null ? '' : __t) +
'">\n		<option value="2">2</option>\n		<option value="3">3</option>\n		<option value="4">4</option>\n		<option value="5">5</option>\n		<option value="6">6</option>\n		<option value="7">7</option>\n		<option value="8">8</option>\n		<option value="9">9</option>\n		<option value="10">10</option>\n		<option value="11">11</option>\n		<% if (isExtraAge) { >\n			<option value="12">12*</option>\n			<option value="13">13*</option>\n		<% } >\n	</select>\n</div>\n';

}
return __p
}