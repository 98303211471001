// eslint-disable-next-line banned-modules
'use strict';

import BasePageView from '@/pages/base.page-view';
import CabinetMenu from '@/blocks/pages/b-cabinet/b-cabinet-menu/index';
import EmployeesView from '@/blocks/pages/b-cabinet/b-cabinet-employees/index';
import EmployeesFormModel from './model';
import axios from 'axios';

export default BasePageView.extend({

	initialize(options) {
		this.render();

		this.ui.content.append(new EmployeesView({
			model: new EmployeesFormModel(),
			parent: this,
			data: options.data,
		}).$el);
		this.ui.sidebar.append(new CabinetMenu().$el);
		this.ui.sidebar.append(`<div class="l-block__back"><div class="b-cabinet__filters"></div></div>`);
	},

}, {
	load() {
		return new Promise((resolve, reject) => {
			axios.post('/midoffice/ibecorp-b2b/cabinet/getEmployees', {
				parameters: EmployeesFormModel.prototype.defaults(),
			}).then((result) => {
				resolve({
					data: result,
					initScrollTopButton: true,
				});
			}).catch(reject);
		});
	},
});
