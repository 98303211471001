import { create } from "zustand";
import { NoticesSummary } from "../../js/blocks/layout/b-header/react/helpers/interfaces";

export interface NoticesState {
	summary: NoticesSummary;
	updateSummary: (summary: NoticesSummary) => void;
	reset: () => void;
}

export const initialNoticesState = {
	summary: 0,
	count: 0,
	unreadCount: 0,
	unreadImportant: [],
	unreadImportantCount: 0,
};

export const useNoticesStore = create<NoticesState>((set) => ({
	summary: { ...initialNoticesState },
	updateSummary: (summary: NoticesSummary) => set({ summary }),
	reset: () => set({ summary: { ...initialNoticesState } }),
}));
