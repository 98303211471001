module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (carriage != null && carriage.travelPolicyCompliance != null && !_.isEmpty(carriage.travelPolicyCompliance.rulesApplied)) {
	var isMobileTemplate = isMobileTemplate || false;
	var rulesApplied = _.sortBy(carriage.travelPolicyCompliance.rulesApplied, function (el) { return el.action.uid === 'RECOMMENDED' });
	_.each(rulesApplied, function (rule, i) { ;
__p += '\n        ';
 if (isMobileTemplate) { ;
__p += '\n            <div class="b-travel-policy__line">\n                <span class="b-train__travel-policy-rule g-icon-travel-policy-rules-' +
((__t = ( rule.action.uid )) == null ? '' : __t) +
'"></span>\n                <div class="b-travel-policy__description">\n                    <div class="b-travel-policy__rule-caption">' +
((__t = ( rule.action.caption )) == null ? '' : __t) +
'</div>\n                    ';
 if (_.size(rule.ruleDetails) > 0) { ;
__p += '\n                        ';
 _.each(rule.ruleDetails, function(ruleDetail){ ;
__p += '\n                            <div>' +
((__t = ( _.escape(ruleDetail) )) == null ? '' : __t) +
'</div>\n                        ';
 }); ;
__p += '\n                    ';
 } ;
__p += '\n                </div>\n            </div>\n        ';
 } else { ;
__p += '\n            <span class="b-train__travel-policy-rule g-icon-travel-policy-rules-' +
((__t = ( rule.action.uid )) == null ? '' : __t) +
'" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="<div class=\'b-travel-policy-tooltip\'><div class=\'tooltip__header\'>' +
((__t = ( rule.action.caption )) == null ? '' : __t) +
'</div>\n		    ';
 if (_.size(rule.ruleDetails) > 0) { ;
__p += '<div class=\'tooltip__body\'>';
 _.each(rule.ruleDetails, function(ruleDetail){ ;
__p += '\n		    	<div>' +
((__t = ( _.escape(ruleDetail) )) == null ? '' : __t) +
'</div>\n		    ';
 }); ;
__p += '</div>';
 } ;
__p += '</div>"></span>\n        ';
 } ;
__p += '\n	';
 });
} ;
__p += '\n';

}
return __p
}