module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-search-bar-sorter">\n  <div class="b-search-bar-sorter__text">\n    ' +
((__t = ( L10N.get('hotels.hotelResultsAmount') )) == null ? '' : __t) +
': ' +
((__t = ( count )) == null ? '' : __t) +
'\n  </div>\n    <div class="b-search-bar-sorter__button">\n      ' +
((__t = ( L10N.get('hotels.doSort') )) == null ? '' : __t) +
'\n    </div>    \n</div>';

}
return __p
}