module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-company-employees-service__wrapper">\n	';
 if (STATE.checkSiteType('B2B')) { ;
__p += '\n		<div class="b-search-form__params">\n			<span class="js-widget"  onclick=\'return new Widgets.CompanyAutocomplete({bindingProperty: "client", deletableField: true })\'></span>\n		</div>\n	';
 } ;
__p += '\n	<div class="b-search-form__employees"></div>\n</div>\n';

}
return __p
}