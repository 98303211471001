module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-finance-select__item" data-uid="' +
((__t = ( uid )) == null ? '' : __t) +
'">\n	<span class="b-finance-select__item-city">' +
((__t = (caption)) == null ? '' : __t) +
'</span>\n</div>\n';

}
return __p
}