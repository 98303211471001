module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<table class="b-nearest-dates-matrix__table b-nearest-dates-matrix__oneway">\r\n	<tr class="b-nearest-dates-matrix__row-prices">\r\n		<td colspan="7" class="b-nearest-dates-matrix__table-nodata">\r\n			' +
((__t = ( L10N.get('nearestMatrix.noData') )) == null ? '' : __t) +
'\r\n		</td>\r\n	</tr>\r\n</table>\r\n';

}
return __p
}