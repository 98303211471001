module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-places__item">\r\n	<span class="b-places__item-city">\r\n		' +
((__t = ( obj.caption )) == null ? '' : __t) +
' ' +
((__t = ( obj.countryCaption ? ", <span>" + obj.countryCaption + "</span>" : "" )) == null ? '' : __t) +
'\r\n	</span>\r\n	';
 if (!['RAIL'].includes(this.searchTypeFromModel)) { ;
__p += '\r\n		<div class="b-places__item-code">\r\n			' +
((__t = ( obj.iataCode )) == null ? '' : __t) +
'\r\n		</div>\r\n	';
 } ;
__p += '\r\n</div>\r\n';

}
return __p
}