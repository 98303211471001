// eslint-disable-next-line banned-modules
'use strict';

import './styles.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';
import BookingModel from './createBookingModel';
import TravellerView from '@/blocks/elements/b-train/b-train-traveller/index';
import CustomerView from '@/blocks/elements/b-customer/b-customer-train/index';
import ReasonCodeView from '@/blocks/elements/b-reason-code/index';
import KeyContactView from '@/blocks/elements/b-key-contact/index';
import Rules from '@/blocks/elements/b-fare-rules/index';
import PaymentTypesView from '@/blocks/elements/b-payment-types/index';
import TrainOptions from '@/blocks/elements/b-train/b-train-options/index';
import TravellersCostCodesHandler from '@/blocks/utils/b-travellers-cost-codes-handler';
import { shouldHandleTravellers } from '@/blocks/utils/b-travellers-cost-codes-handler/utils';
import BookingDuplicatesHandler from '@/blocks/utils/b-booking-duplicates-handler';
import axios from 'axios';
import $ from 'jquery';

export default BaseView.extend({
	template,

	el: '.l-page__container',

	events: {
		'click .b-travellers__add-infant': 'addInfant',
		'click .b-booking__submit': 'doBooking',
		'click .b-booking__back': 'back',
	},

	ui: {
		customer: '.b-booking__customer',
		paymentTypes: '.b-booking__payment-types',
		trainOptions: '.b-booking__train-options',
		visaInfo: '.b-booking__visa-info',
		travellers: '.b-booking__travellers',
		rules: '.b-booking__rules',
		addInfant: '.b-travellers__header-add-infant',
		submitButton: '.b-booking__submit',
		reasonCode: '.b-booking__reason-code',
		keyContact: '.b-booking__key-contact',
	},

	preinitialize(options) {
		BaseView.prototype.preinitialize.call(this, options);
		this.windowEventListenerList.push({
			mediaQuery: '(max-width: 768px)',
			name: 'change',
			callback: this.adjustMobileTemplate.bind(this),
			isMatchMedia: true,
		});
	},

	initialize(options) {
		this.bookingInfoData = {
			availableTariffs: [],
			passengerTypeChangeEnabled: true,
		};

		this.fillBookingInfoData(options.bookingInfo);

		this.parent = options.parent;
		this.bookingFormSettings = options.bookingSettings;
		this.bookingSettingsParams = options.bookingSettingsParams;
		this.keyContact = this.options.bookingSettings.keyContact;
		this.reasonCode = this.options.bookingSettings.reasonCode; // needed fo teamplate
		this.externalSystems = this.options.bookingSettings.externalSystems;
		this.travellerViewList = [];

		this.model = new BookingModel(options.bookingInfo, {
			parse: true,
		});

		const {
			order,
			customer,
			contactFillingType,
			contactPersonEditGranted,
			contactPersonDisableFreeTextInput,
			bookingFormSettingsToken,
		} = options.bookingSettings || {};

		if (order != null) {
			this.order = order;
			STATE.layout.header.setAdditionalOrder(order);
		}

		if (customer != null) {
			const BaseModel = this.model.get('customer').constructor;
			this.model.set(
				'customer',
				new BaseModel(
					_.extend({}, customer, {
						disabled: true,
					}),
				),
			);
		}

		this.render();

		this.bookingDuplicatesHandler = new BookingDuplicatesHandler({
			bookingSettings: options.bookingSettings,
		});

		if (options.bookingSettings?.searchDuplicatesMessage) {
			this.bookingDuplicatesHandler.showSearchDuplicatesPopup({
				...options.bookingSettings.searchDuplicatesMessage,
				onCancel: () => this.back(),
			});
		}

		if (
			this.options.pricingInfo != null &&
			!_.isEmpty(this.options.pricingInfo.paymentTypes)
		) {
			const paymentTypesView = new PaymentTypesView({
				model: this.model,
				paymentTypes: this.options.pricingInfo.paymentTypes,
			});
			this.ui.paymentTypes.append(paymentTypesView.$el);
		}

		if (
			this.bookingFormSettings.beddingEnabled ||
			this.bookingFormSettings.beddingBackEnabled
		) {
			const trainOptions = new TrainOptions({
				parent: this,
				formSettings: this.bookingFormSettings,
			});
			this.ui.trainOptions.append(trainOptions.$el);
		}
		const tariffs = {};
		const tariffDescriptions = this.bookingFormSettings.tariffDescriptions;
		Object.keys(tariffDescriptions).forEach((td) => {
			const tariffDescr = this.bookingFormSettings.tariffs.find(
				(t) => t.uid === td,
			);
			if (!tariffs[tariffDescriptions[td].passengerType.uid]) {
				tariffs[tariffDescriptions[td].passengerType.uid] = [];
			}
			tariffs[tariffDescriptions[td].passengerType.uid].push({
				type: tariffDescriptions[td].passengerType,
				tariffDescr,
			});
		});

		// Актуализируем тарифы на основе списка доступных тарифов
		this.model.get('travellers').each((travellerModel) => {
			const travellerType = travellerModel.get('type') || {};
			const currentTariffDescription = tariffs[travellerType.uid].find((tariff) => tariff.tariffDescr.uid === travellerModel.get('tariff').uid);
			const firstTariffDescription = tariffs[travellerType.uid][0];
			// Высталяем принудительно первый тариф, если по умолчанию тарифа нет в списке
			if (!currentTariffDescription && firstTariffDescription && firstTariffDescription.tariffDescr) {
				travellerModel.set('tariff', firstTariffDescription.tariffDescr);
			}
		});

		this.listenTo(this.model.get('travellers'), `add remove reset`, () => {
			this.model.countTravellers(this.model.get('travellers'));
			this.toggleAddInfantButton();
		});

		this.model.get('travellers').forEach((traveller, i) => {
			const isContact =
				i === 0 &&
				(contactFillingType || {}).uid === STATE.FILLING_TYPE.FIRST_TRAVELLER;
			const travellerView = new TravellerView({
				index: i,
				isContact,
				tariffs,
				contactPersonEditGranted,
				contactPersonDisableFreeTextInput,
				bookingInfoData: this.bookingInfoData,
				parentModel: this.model.get('travellers'),
				model: traveller,
				passenger: options.bookingSettings.corporatePassengers[i],
				bookingSettings: options.bookingSettings || {},
				bookingSettingsParams: this.bookingSettingsParams,
				bookingFormSettingsToken,
				parent: this,
			});
			this.travellerViewList.push(travellerView);
			this.ui.travellers.append(travellerView.$el);

			this.addEventListeners(traveller);
		});

		// проверяем и инициализиуем обработчик common костКодов пассажиров
		const isAddProductToOrder = !!options?.bookingSettings.order?.bookingUid;
		this.checkNInitTravellersCostCodesHandler(
			options?.bookingSettings?.organizationCostCodes,
			isAddProductToOrder,
		);

		this.customerView = new CustomerView({
			contactFillingType,
			contactPersonEditGranted,
			contactPersonDisableFreeTextInput,
			parentModel: this.model.get('travellers'),
			model: this.model.get('customer'),
			contactAutocompleteAvailable:
				options.bookingSettings.contactAutocompleteAvailable,
		});

		this.ui.customer.append(this.customerView.$el);
		this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));
	},

	checkNInitTravellersCostCodesHandler(organizationCostCodes = [], isAddProductToOrder = false) {
		if (
			organizationCostCodes.length &&
			this.travellerViewList.length > 1 &&
			shouldHandleTravellers(this.travellerViewList, organizationCostCodes, isAddProductToOrder)
		) {
			this.travellersCostCodesHandler = new TravellersCostCodesHandler({
				disableAllFilledCommons: isAddProductToOrder,
			});
			// добавляем в обработчик вьюшки путешественников и их модели
			this.travellerViewList.forEach((view) => {
				this.travellersCostCodesHandler.addTravellerView(view);
			});
		}
	},

	delegateEvents(...args) {
		BaseView.prototype.delegateEvents.apply(this, args);
	},

	undelegateEvents(...args) {
		BaseView.prototype.undelegateEvents.apply(this, args);
	},

	adjustMobileTemplate(matches) {
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			if (_.isObject(matches)) matches = matches.matches;

			const $addInfantButton = this.$('.b-travellers__header-add-infant');
			const $travellersContainer = this.$('.b-booking__travellers');
			const $travellersHeader = this.$('.b-travellers__header');
			const $priceInfoContainer = this.$('.js-price-info-container');
			const $tooltips = this.$('[data-toggle="tooltip"]');
			const $mobileCollapseHeaders = this.$('.mobile-collapse');

			if (matches) {
				$addInfantButton.insertAfter($travellersContainer);
				$priceInfoContainer
					.empty()
					.append(this.parent.trainInfoView.ui.pricing);
				_.each($tooltips, (el) => el && this.$(el).tooltip('disable'));
				_.each(
					$mobileCollapseHeaders,
					(el) => el && this.$(el).attr('data-toggle', 'collapse'),
				);
			} else {
				$travellersHeader.append($addInfantButton);
				this.parent.trainInfoView.$el.append(
					this.parent.trainInfoView.ui.pricing,
				);
				_.each($tooltips, (el) => el && this.$(el).tooltip('enable'));
				_.each($mobileCollapseHeaders, (el) => {
					const $target = this.$(el);
					if ($target.hasClass('collapsed')) $target.click();
					$target.removeAttr('data-toggle');
				});
			}

			_.each(
				this.travellerViewList,
				(v) => v && v.adjustMobileTemplate(matches),
			);
		}, 100);
	},

	addEventListeners(traveller) {
		if (traveller == null) {
			return this;
		}

		this.listenTo(traveller.get('passport'), `change:lastName`, (thisModel) => {
			if (traveller.get('isContact')) {
				this.model.get('customer').set('lastName', thisModel.get('lastName'));
			}
		});
		this.listenTo(
			traveller.get('passport'),
			`change:firstName`,
			(thisModel) => {
				if (traveller.get('isContact')) {
					this.model
						.get('customer')
						.set('firstName', thisModel.get('firstName'));
				}
			},
		);
		this.listenTo(traveller, `change:phone`, (thisModel) => {
			if (traveller.get('isContact')) {
				this.model.get('customer').set('phone', thisModel.get('phone'));
			}
		});
		this.listenTo(traveller, `change:email`, (thisModel) => {
			if (traveller.get('isContact')) {
				this.model.get('customer').set('email', thisModel.get('email'));
			}
		});
		this.listenTo(traveller, `change:isContact`, (thisModel, value) => {
			if (!value) {
				// Сбрасываем personRef
				const resetPersonRefInModel = (model) => {
					if (model && model.get('personRef') != null) {
						model.set('personRef', undefined);
					}
				};
				resetPersonRefInModel(this.customerView.model);
				if (this.model.get('customer') != null) {
					resetPersonRefInModel(this.model.get('customer'));
				}
				return;
			}
			this.model.get('travellers').each((otherModel) => {
				if (otherModel.cid !== thisModel.cid) {
					otherModel.set('isContact', false);
				}
			});

			const lastName = thisModel.get('passport').get('lastName');

			this.model.get('customer').set('lastName', lastName);
			this.model
				.get('customer')
				.set('firstName', thisModel.get('passport').get('firstName'));
			this.model.get('customer').set('phone', thisModel.get('phone'));
			this.model.get('customer').set('email', thisModel.get('email'));

			if (lastName && thisModel.get('uid')) {
				const personRef = { uid: thisModel.get('uid'), caption: `${lastName}` };
				this.customerView.model.set('personRef', personRef);
				this.model.get('customer').set('personRef', personRef);
			}

			if (this.model.get('customer').get('disabled') === true) {
				this.customerView.allowEdit();
			}
		});

		return this;
	},

	render() {
		const { reasonCode } = this.bookingFormSettings;
		const { train = {} } = (this.bookingInfo || {}).routeInfo || {};

		const container = this.$el;
		this.setElement(template.call(this));
		container.append(this.$el);

		if (reasonCode && reasonCode.values) {
			this.ui.reasonCode.html(
				new ReasonCodeView({
					model: this.model,
					reasonCode,
				}).$el,
			);
		} else {
			this.ui.reasonCode.hide();
		}

		// IBECORP-3377
		if (this.keyContact && this.keyContact.values) {
			this.ui.keyContact.html(
				new KeyContactView({
					model: this.model,
					keyContact: this.keyContact,
				}).$el,
			);
		} else {
			this.ui.keyContact.hide();
		}

		const rules = new Rules({
			model: this.model,
			travelSubjectUid: 'RAIL',
			gdsUid: (train.gds || {}).uid,
		});

		this.ui.rules.html(rules.el);
	},

	fillBookingInfoData(bookingInfo) {
		this.bookingInfo = bookingInfo;

		const carriage = bookingInfo.routeInfo && bookingInfo.routeInfo.carriage;
		if (!carriage.passengerTypeChangeEnabled) {
			this.bookingInfoData.passengerTypeChangeEnabled = false;
		}
		carriage.availableTariffs.forEach((t) => {
			if (_.indexOf(this.bookingInfoData.availableTariffs, t) === -1) {
				this.bookingInfoData.availableTariffs.push(t);
			}
		});
		if (bookingInfo.routeInfoBack != null) {
			const carriageBack = bookingInfo.routeInfoBack.carriage;
			if (!carriageBack.passengerTypeChangeEnabled) {
				this.bookingInfoData.passengerTypeChangeEnabled = false;
			}
			carriageBack.availableTariffs.forEach((t) => {
				if (_.indexOf(this.bookingInfoData.availableTariffs, t) === -1) {
					this.bookingInfoData.availableTariffs.push(t);
				}
			});
		}
	},

	addTraveller(traveller, index) {
		if (!traveller) {
			traveller = {
				type: {
					uid: 'ADULT',
					caption: L10N.get('trains.tariff.ADULT'),
				},
				tariff: {
					uid: 'FULL',
					caption: L10N.get('trains.tariff.FULL'),
				},
			};
		}
		const travellerModel = this.model.addTraveller(traveller);
		this.addEventListeners(travellerModel);

		this.model.get('travellers').trigger('change');
		if (!index) {
			index = this.model.get('travellers').size() - 1;
		}
		const travellerView = new TravellerView({
			index,
			bookingInfoData: this.bookingInfoData,
			parentModel: this.model.get('travellers'),
			model: travellerModel,
			bookingSettings: this.options.bookingSettings || {},
			bookingFormSettingsToken:
				this.options.bookingSettings?.bookingFormSettingsToken,
			parent: this,
		});
		this.ui.travellers.append(travellerView.$el);
		this.travellerViewList.push(travellerView);
		this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));

		if (this.travellersCostCodesHandler) {
			this.travellersCostCodesHandler.addTravellerView(travellerView, true);
		}
	},

	toggleAddInfantButton() {
		if (this.model.isCanAddInfant(this.bookingInfo)) {
			this.ui.addInfant.show();
		} else {
			this.ui.addInfant.hide();
		}
	},

	addInfant(e) {
		if (e) {
			e.preventDefault();
		}
		if (this.model.isCanAddInfant(this.bookingInfo)) {
			this.addTraveller({
				type: {
					uid: 'INFANT',
					caption: L10N.get('trains.tariff.BABY'),
				},
				tariff: {
					uid: 'BABY',
					caption: L10N.get('trains.tariff.BABY'),
				},
			});
		}
	},

	doBooking(e) {
		if (e) {
			e.preventDefault();
		}

		STATE.showLoader();
		this.ui.submitButton.attr('disabled', true);
		this.$el.find('.b-booking__common-validation').hide();
		this.disableElements(e);

		const request = this.model.toJSON();
		delete request.parameters.additionalInfo;
		request.parameters = {
			...request.parameters,
			...this.bookingDuplicatesHandler.getBookingDataObj(),
		};

		if (
			this.bookingInfo.additionalInfo &&
			this.bookingInfo.additionalInfo.isIntention
		) {
			return axios
				.post(
					'/midoffice/ibecorp-b2b/rail/booking/validate',
					request,
					this.model,
				)
				.then(() => {
					this.sendApproveIntention({
						parameters: {
							reservationUid: this.bookingInfo.additionalInfo.reservationUid,
							currentBooking: this.bookingInfo.additionalInfo.currentBooking,
							railData: {
								createParameters: {
									...(request.parameters || {}),
									...this.bookingDuplicatesHandler.getBookingDataObj(),
								},
							},
						},
					});
				})
				.catch((ex) => {
					STATE.hideLoader();
					this.ui.submitButton.attr('disabled', false);
					if (ex != null && ex.validatedParameters != null) {
						_.each(ex.validatedParameters.travellers, (traveller, index) => {
							if (
								traveller.passport != null &&
								traveller.passport.expiredDate != null
							) {
								this.$el
									.find(
										`.b-traveller[data-index="${index}"] .b-traveller__passport-expired-date`,
									)
									.show();
							}
						});

						this.$el.find('.b-booking__common-validation .messages').empty();
						if (!_.isEmpty(ex.validatedParameters.commonMessages)) {
							this.$el.find('.b-booking__common-validation').show();
							_.each(ex.validatedParameters.commonMessages, (message) => {
								const $container = $('<div />');
								$container.addClass('b-message b-message-error');
								$container.text(message.text);
								this.$el
									.find('.b-booking__common-validation .messages')
									.append($container);
							});
						} else {
							this.$el.find('.b-booking__common-validation').hide();
						}
					}

					STATE.hideLoader();
					this.ui.submitButton.attr('disabled', false);

					throw ex;
				});
		}
		STATE.setBookingInProcess(true);
		_.each(_.keys(request.parameters), (key) => {
			const params = request.parameters;
			if (key.indexOf('externalSystem') !== -1) {
				if (params.externalSystemCodes !== undefined) {
					params.externalSystemCodes.push({
						uid: key.split('__')[1],
						code: params[key],
					});
				} else {
					params.externalSystemCodes = [
						{ uid: key.split('__')[1], code: params[key] },
					];
				}
				delete params[key];
			}
		});

		return axios
			.post('/midoffice/ibecorp-b2b/rail/booking', request, this.model, {
				enableCashing: true,
			})
			.then((result) => {
				STATE.hideLoader();
				this.ui.submitButton.attr('disabled', false);

				if (
					this.bookingDuplicatesHandler.shouldShowProductDuplicatesPopup(
						result.data.result,
					)
				) {
					this.bookingDuplicatesHandler.showProductDuplicatesPopup({
						...result.data.result,
						onOk: () => this.doBooking(),
						onCancel: () => this.back(),
					});
					return result;
				}

				STORE.set(STATE.ROUTES.CABINET_ORDER, {
					orderUid: result.data.result.UID,
				});
				STORE.set('orderBookingMessages', {
					messages: result.data.messages,
				});
				if (STATE.getQueryVariable('pnr') != null) {
					window.location.href = `${STATE.getRootUrl()}#${
						STATE.ROUTES.CABINET_ORDER
					}/${result.data.result.UID}`;
				} else {
					STATE.navigate(
						[STATE.ROUTES.CABINET_ORDER, result.data.result.UID].join('/'),
					);
				}

				return result;
			})
			.catch((ex) => {
				STATE.hideLoader();
				this.ui.submitButton.attr('disabled', false);
				if (
					ex &&
					ex.response &&
					ex.response.data.errorType ===
						'com.gridnine.xtrip.common.ibecorp.exception.NoSeatsException'
				) {
					$('body').find('.b-popup-mask.visible').remove();
					const popup = new Widgets.Popup({
						content: ex.response.data.userErrorMessage || '',
						type: 'danger',
						actions: [
							{
								label: L10N.get('searchForm.backToSearch'),
								action: () => {
									this.showUpdatedSearchResults();
									popup.hide();
								},
							},
						],
						onClose: () => {
							popup.hide();
						},
					});
					popup.show();
					return;
				}
				if (ex != null && ex.validatedParameters != null) {
					_.each(ex.validatedParameters.travellers, (traveller, index) => {
						if (
							traveller.passport != null &&
							traveller.passport.expiredDate != null
						) {
							// IBECORP-3338
							// Validate only visible expire date field
							if (
								this.$el
									.find(
										`.b-traveller[data-index="${index}"] .b-traveller__passport-expired-date`,
									)
									.is(`:visible`)
							) {
								this.$el
									.find(
										`.b-traveller[data-index="${index}"] .b-traveller__passport-expired-date`,
									)
									.show();
							}
						}
					});

					this.$el.find('.b-booking__common-validation .messages').empty();
					if (!_.isEmpty(ex.validatedParameters.commonMessages)) {
						this.$el.find('.b-booking__common-validation').show();
						_.each(ex.validatedParameters.commonMessages, (message) => {
							const $container = $('<div />');
							$container.addClass('b-message b-message-error');
							$container.text(message.text);
							this.$el
								.find('.b-booking__common-validation .messages')
								.append($container);
						});
					} else {
						this.$el.find('.b-booking__common-validation').hide();
					}
				}

				STATE.hideLoader();
				this.ui.submitButton.attr('disabled', false);

				throw ex;
			});
	},

	showUpdatedSearchResults() {
		STATE.setTrainsSearchResults({
			rail: [
				{
					doSearch: true,
					findTrainByNumber: this.options.bookingInfo.routeInfo.train.number,
				},
			],
		});
		STATE.navigate(STATE.ROUTES.RAILWAYS_TICKETS);
	},

	sendApproveIntention(parameters) {
		return axios
			.post(
				'/midoffice/ibecorp-b2b/booking/approveIntention',
				parameters,
				null,
				{ enableCashing: true },
			)
			.then((result) => {
				STATE.hideLoader();
				this.ui.submitButton.attr('disabled', false);

				if (
					this.bookingDuplicatesHandler.shouldShowProductDuplicatesPopup(
						result.data.result?.railBookingResult,
					)
				) {
					this.bookingDuplicatesHandler.showProductDuplicatesPopup({
						...result.data.result.railBookingResult,
						onOk: () => this.doBooking(),
						onCancel: () => this.back(),
					});
					return result;
				}

				const redirect = (orderUid) => {
					STORE.set(STATE.ROUTES.CABINET_ORDER, {
						orderUid,
					});

					if (STATE.getQueryVariable('pnr') != null) {
						window.location.href = `${STATE.getRootUrl()}#${
							STATE.ROUTES.CABINET_ORDER
						}/${orderUid}`;
					} else {
						STATE.navigate([STATE.ROUTES.CABINET_ORDER, orderUid].join('/'));
					}
				};

				if (result.data.messages && result.data.messages.length !== 0) {
					result.data.messages.forEach((message, i) => {
						const popup = new Widgets.Popup({
							content: message.text,
							type: 'info',
							title:
								message.code === 'priceChanged'
									? L10N.get('cabinet.orders.priceChanging')
									: L10N.get('bookingForm.attention'),
							actions: [
								{
									label: L10N.get('settings.errorPopupButton'),
									action: () => {
										popup.hide();
										if (i + 1 === result.data.messages.length) {
											redirect(result.data.result.railBookingResult.UID);
										}
									},
								},
							],
							onClose: () => {
								popup.hide();
								redirect(result.data.result.railBookingResult.UID);
							},
						});
						popup.show();
					});
				} else {
					redirect(result.data.result.railBookingResult.UID);
				}

				return result;
			})
			.catch(() => {
				this.ui.submitButton.attr('disabled', false);
			});
	},

	back(e) {
		if (e != null) {
			e.preventDefault();
		}

		const isRoundTrip =
			this.bookingInfo &&
			this.bookingInfo.routeInfo &&
			this.bookingInfo.routeInfoBack;

		STATE.navigate(
			STATE.ROUTES[isRoundTrip ? 'RAILWAYS_TICKETS_2' : 'RAILWAYS_TICKETS_1'],
		);
	},

	clearTravellersCostCodesHandler() {
		if (this.travellersCostCodesHandler) {
			this.travellersCostCodesHandler.clear();
		}
	},

	remove() {
		this.bookingDuplicatesHandler.remove();
		this.clearTravellersCostCodesHandler();
		BaseView.prototype.remove.call(this);
	},
});
