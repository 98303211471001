// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';

const Model = BaseModel.extend({

	toJSON() {
		return {
			parameters: BaseModel.prototype.toJSON.call(this),
		};
	},

});

export default Model;
