// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import 'corejs-typeahead/dist/typeahead.jquery';

export default Widget.extend({

	template,
});
