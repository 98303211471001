module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<ul class="b-flight-services__options">\n	';

		var services = flight.servicesStatuses;
		var cabinLuggageState = _.every(leg.segments, function (segment) {
			return !_.isEmpty(segment.servicesDetails.freeCabinLuggage) || !_.isEmpty(segment.servicesDetails.paidCabinLuggage);
		}) ? 'FREE' : 'OFF';
		var exchangeOrRefundExists = services.exchange != null || services.refund != null;
		var copyExchangeRefund = copyExchangeRefund != null ? copyExchangeRefund : true;
        var cabinLuggageClsName = cabinLuggageState === 'OFF' ?
            'g-icon-option-cabin-luggage-grey' : 'g-icon-option-cabin-luggage-' + cabinLuggageState + '-black';
	;
__p += '\n        <li\n            data-toggle="tooltip"\n            data-placement="bottom"\n            data-placement="bottom"\n            data-html="true"\n            data-original-title="' +
((__t = ( _.escape(require('./b-ticket-luggage.ejs')({ segments: leg.segments, type: 'luggage' })) )) == null ? '' : __t) +
'"\n            class="b-flight-services__option ' +
((__t = ( cabinLuggageClsName )) == null ? '' : __t) +
'"\n        >\n        </li>\n	';

        if (services.baggage != null) {
            var baggageClsName = services.baggage.uid === undefined ?
                'g-icon-option-baggage-grey' : 'g-icon-option-baggage-' + services.baggage.uid + '-black';
    ;
__p += '\n		<li\n            data-toggle="tooltip"\n            data-placement="bottom"\n            data-html="true"\n            data-original-title="' +
((__t = ( _.escape(require('./b-ticket-luggage.ejs')({ segments: leg.segments, type: 'baggage' })) )) == null ? '' : __t) +
'"\n		    class="b-flight-services__option ' +
((__t = ( baggageClsName )) == null ? '' : __t) +
'"\n        >\n        </li>\n	';
 } ;
__p += '\n	';
 if (exchangeOrRefundExists) {
		var states = _.compact([(services.exchange || {}).uid, (services.refund || {}).uid]);
		var state = 'FREE';

		if ((states[0] === 'OFF' && !states[1]) || (states[0] === 'OFF' && states[0] === states[1])) {
			state = 'OFF';
		}
        var refundClsName = state === 'OFF' ?
            'g-icon-option-refund-exchange-grey' : 'g-icon-option-refund-exchange-' + state + '-black';
	;
__p += '\n		<li\n            data-toggle="tooltip"\n            data-placement="bottom"\n            data-html="true"\n            data-original-title="' +
((__t = ( _.escape(require('./b-ticket-refund-exchange.ejs')({ services: {refund: flight.refund, exchange: flight.exchange}, isList: true })) )) == null ? '' : __t) +
'"\n		    class="b-flight-services__option ' +
((__t = ( refundClsName )) == null ? '' : __t) +
'"\n        >\n        </li>\n	';
 } ;
__p += '\n    <span class="js-copy-content copy-content"><span data-find-copy-in-tooltip data-copy-title-from=".b-leg-' +
((__t = (legIndex)) == null ? '' : __t) +
' .b-flight-services__options > :first-child"></span></span>\n    <span class="js-copy-content copy-content">';
 if (services.baggage != null) { ;
__p += '<span data-find-copy-in-tooltip data-copy-title-from=".b-leg-' +
((__t = (legIndex)) == null ? '' : __t) +
' .b-flight-services__options > :nth-child(2)"></span>';
 } ;
__p += '</span>\n    <span class="js-copy-content copy-content">';
 if (exchangeOrRefundExists && copyExchangeRefund) { ;
__p += '<span data-find-copy-in-tooltip data-copy-title-from=".b-leg-' +
((__t = (legIndex)) == null ? '' : __t) +
' .b-flight-services__options > :nth-child(3)"></span>';
 } ;
__p += '</span>\n</ul>\n';

}
return __p
}