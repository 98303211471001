// eslint-disable-next-line banned-modules
'use strict';

import Backbone from 'backbone';
import $ from 'jquery';

import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/collapse';

export default Backbone.View.extend({

	constructor(options) {
		this.options = options || {};
		if (this.options.template) {
			this.template = this.options.template;
		}
		Backbone.View.call(this, options);
		const pageViewInitialized = new CustomEvent('pageViewInitialized', {
			detail: {
				view: this,
			},
		});
		window.dispatchEvent(pageViewInitialized);
		if (options && options.model && options.bindField) {
			this.__bindModel(options.bindField);
		}
	},

	preinitialize(options) {
		this.windowEventListenerList = [];
		this.handleScrollTopButton = this.handleScrollTopButton.bind(this);
		if (options && options.initScrollTopButton) {
			this.windowEventListenerList.push({
				name: 'scroll',
				callback: this.handleScrollTopButton,
			});
		} else {
			$('body').find('.js-scroll-top-btn').hide();
		}
	},

	handleScrollTopButton() {
		clearTimeout(this.scrollEventTimer);
		this.scrollEventTimer = setTimeout(() => {
			const scrollOffset = window.scrollY + window.innerHeight;
			const $container = $('body');
			let scrollTopButton = $container.find('.js-scroll-top-btn');

			const scrollTopOnclick = () => {
				window.scrollTo(0, 0);
			};

			if (scrollOffset >= 1200) {
				if (_.size(scrollTopButton) === 0) {
					$container.append(`<button class="js-scroll-top-btn scroll-top-button"></button>`);
					scrollTopButton = $container.find('.js-scroll-top-btn');
					scrollTopButton.on('click', scrollTopOnclick);
				}
				scrollTopButton.show();
			} else {
				scrollTopButton.hide();
			}
		}, 100);
	},

	initialize() {
		this.render();
	},

	/**
	 * Get elements from view scope by given "element" part of class.
	 * .b-block - the main block class , .b-block__title - element,
	 *  this.getElements("title") return all elements
	 * with class ".b-block__title" in the current block
	 * @param name
	 * @returns
	 */
	getElements(name) {
		return this.$el.find(`.${this.viewName}__${name}`);
	},

	initUI() {
		if (this.ui) {
			const ui = this.constructor.prototype.ui;
			this.ui = {};
			Object.keys(ui).forEach((key) => {
				if (ui.hasOwnProperty(key)) {
					this.ui[key] = this.$(ui[key]);
				}
			});
		}
	},

	bindWidgets(container = null) {
		const widgetsElements = container && container.find('.js-widget') || this.$('.js-widget');
		widgetsElements.each((i, item) => {
			const element = widgetsElements.eq(i);
			const widget = item.onclick();
			element.after(widget.$el);
			element.remove();
			widget.applyBinding(this.model);
		});

		let tooltips;
		if (container) {
			tooltips = container.find('[data-toggle="tooltip"]');
		} else {
			tooltips = this.$('[data-toggle="tooltip"]');
		}
		tooltips.tooltip({
			container: 'body',
			trigger: 'hover',
			html: true,
		});
	},

	isDefaultElement(el) {
		return el.childNodes && el.childNodes.length === 0 &&
			el.tagName.toLowerCase() === 'div';
	},

	setElement(element) {
		this.undelegateEvents();
		this._setElement(element);
		this.$el.__jsWidget = this;
		this.delegateEvents();
		if ((!_.isString(element) || (element.trim().startsWith('<') && (element.trim() !== '<div />')))
			&& !this.isDefaultElement(element)) {
			this.initUI();
		}
		this.bindWidgets();

		// For debuggin in browser
		if (LOG_LEVEL < 4 && this.el) {
			this.el.getModel = () => {
				return this.model;
			};
			this.el.getView = () => {
				return this;
			};
		}
		return this;
	},

	__bindModel(field) {
		if (this.model) {
			if (this.bindModel) {
				this.bindModel();
			} else {
				this.model.set(field, this.getVal());
			}
		}
	},

	render(attrs) {
		const params = Object.assign(
			{
				classes: '',
			},
			this.model ? this.model.attributes : {},
			this.options,
			attrs,
		);
		const container = this.$el;
		this.setElement(this.template(params));
		container.append(this.$el);
		this.addWindowEventListeners();
	},

	hideForm(el) {
		$(el).hide();
	},

	/**
	 Extending Backbone remove functionality with removeWindowEventListenerList function
	 for easier work with global events.

	 @windowEventListenerList - is a list of objects that contains an @windowEventListenerObject

	 @windowEventListenerObject {
		@name - event name,
		@callback - assigned callback function,
		@isMatchMedia - if needed to set window.matchMedia event listener,
		@mediaQuery - media query e.g. '(max-width: 768px)',
	 }

	 To get use of this logic you need to push a described object into windowEventListenerList
	 in View.preinitialize
	 */

	addWindowEventListeners() {
		this.windowEventListenerList.forEach((item) => {
			if (!_.isFunction(item.callback)) return;
			if (item.isMatchMedia) {
				item.matchMediaQueryList = window.matchMedia(item.mediaQuery);
				item.matchMediaQueryList.addEventListener(item.name, item.callback);
			} else {
				window.addEventListener(item.name, item.callback);
			}
		});
	},

	removeWindowEventListeners() {
		this.windowEventListenerList.forEach((item) => {
			if (!_.isFunction(item.callback)) return;
			if (item.isMatchMedia) {
				if (item.matchMediaQueryList) item.matchMediaQueryList.removeEventListener(item.name, item.callback);
			} else {
				window.removeEventListener(item.name, item.callback);
			}
		});
	},

	remove() {
		this.removeWindowEventListeners();
		Backbone.View.prototype.remove.call(this);
	},

	hideValidations() {
		this.$el.find('.validation-error').removeClass('validation-error');
		this.$el.find('.validations-errors__container').remove();
	},

	disableElements(event = {}) {
		const { currentTarget } = _.isObject(event) ? event : {};

		if (!currentTarget) {
			return;
		}

		const $element = $(currentTarget);
		$element.css({ pointerEvents: 'none' });

		setTimeout(() => {
			$element.css({ pointerEvents: '' });
		}, 150);
	},

	destroy() {
		this.undelegateEvents();
		this.$el.removeData().unbind();

		this.remove();
		this.$el.empty();
	},

});
