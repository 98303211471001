module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var _this = this;
    var currencyCode = _this.model.get('currencyCode') || 'RUB';
	var ancillaryFeesByTraveller = this.model.sortByTraveller();
    var serviceTypeList = _this.model.getTypes();
    var reservation = reservation || [];
    var travellers = travellers || [];
	var segmentsByLeg = [];

;
__p += '\n\n<div class="b-ancillary-fees__modal">\n    <div class="b-ancillary-fees">\n        <div class="b-ancillary-fees__modal-content-container">\n            <div class="b-ancillary-fees__modal-header">' +
((__t = ( L10N.get('avia.addServices.caption') )) == null ? '' : __t) +
'</div>\n            <div class="b-ancillary-fees__modal-content">\n                <div class="b-ancillary-fees__legs-buttons">\n                    ';
 _.each(reservation.legs, function (l, legIndex) {
                        segmentsByLeg.push(l.segments);
                        var firstSegment = _.first(l.segments);
                        var lastSegment = _.last(l.segments);
                        var segmentName = '-';
                        if (firstSegment.departureCity && lastSegment.arrivalCity) {
                            segmentName = firstSegment.departureCity.caption + ' - ' + lastSegment.arrivalCity.caption;
                        }
                    ;
__p += '\n                    <button class="b-ancillary-fees__leg-button js-changeLeg ' +
((__t = ( legIndex === 0 ? 'active' : '' )) == null ? '' : __t) +
'"\n                            data-leg-index="' +
((__t = ( legIndex )) == null ? '' : __t) +
'"\n                            data-action="CHANGE_LEG"\n                    >\n                        ' +
((__t = ( segmentName.toUpperCase() )) == null ? '' : __t) +
'\n                    </button>\n                    ';
 }) ;
__p += '\n                </div>\n                <div class="flex-container">\n                    <div class="b-ancillary-fees__traveller-views js-traveller-views">\n                        ';
 _.each(travellers, function (t, travellerIndex) { ;
__p += '\n                            <div class="b-ancillary-fees__traveller-view js-traveller-view ' +
((__t = ( travellerIndex === 0 ? '' : 'dn' )) == null ? '' : __t) +
'" data-traveller-uid="' +
((__t = ( t.uid )) == null ? '' : __t) +
'">\n                                <div class="b-ancillary-fees__segments-buttons">\n                                    ';
 _.each(segmentsByLeg, function (item, legIndex) {
                                    _.each(item, function (segment, segmentIndex) {
                                        var segmentName = '-';
                                        if (segment.departureCity && segment.arrivalCity) {
                                            segmentName = segment.departureCity.caption + ' - ' + segment.arrivalCity.caption;
                                        }
                                    ;
__p += '\n                                    <button class="b-ancillary-fees__segment-button js-changeSegment ' +
((__t = ( legIndex === 0 ? '' : 'dn' )) == null ? '' : __t) +
' ' +
((__t = ( segmentIndex === 0 ? 'active' : '' )) == null ? '' : __t) +
'"\n                                            data-leg-index="' +
((__t = ( legIndex )) == null ? '' : __t) +
'"\n                                            data-segment-index="' +
((__t = ( segmentIndex )) == null ? '' : __t) +
'"\n                                            data-action="CHANGE_SEGMENT"\n                                    >\n                                        <span>' +
((__t = ( segmentName.toUpperCase() )) == null ? '' : __t) +
'</span>\n                                        <i class="b-ancillary-fees__segment-button-icon"></i>\n                                        <span>' +
((__t = ( segment.airline && segment.airline.airlineCode + '-' + segment.flightNumber || segment.flightNumber )) == null ? '' : __t) +
'</span>\n                                        <i class="b-ancillary-fees__segment-button-tooltip js-segment-button-tooltip dn"></i>\n                                    </button>\n                                    ';
 });
                                    }); ;
__p += '\n                                </div>\n                                <div class="flex-container">\n                                    <div class="b-ancillary-fees__service-list js-service-list">\n                                        ';
 _.each(segmentsByLeg, function(item, legIndex) {
											_.each(item, function(segment, segmentIndex) {
                                                _.each(serviceTypeList, function (type, typeIndex) {
                                                    var service = _.find(ancillaryFeesByTraveller[t.uid], function (s) {
                                                        return s.type.uid === type &&
                                                                _.find(s.segments, function(seg) { return seg.segmentIndex === segmentIndex && seg.legIndex === legIndex}) != null;
                                                    }); ;
__p += '\n                                                    ';
 if (service != null) { ;
__p += '\n                                                        <div class="b-ancillary-fees__service-item js-service-list-item ' +
((__t = ( legIndex === 0 && segmentIndex === 0 ? '' : "dn")) == null ? '' : __t) +
' ' +
((__t = ( typeIndex === 0 ? 'active' : '' )) == null ? '' : __t) +
'"\n                                                             data-service-type="' +
((__t = ( service.type.uid )) == null ? '' : __t) +
'"\n                                                             data-traveller-uid="' +
((__t = ( t.uid )) == null ? '' : __t) +
'"\n                                                             data-action="CHANGE_TYPE"\n                                                             data-leg-index="' +
((__t = ( legIndex )) == null ? '' : __t) +
'"\n                                                             data-segment-index="' +
((__t = ( segmentIndex )) == null ? '' : __t) +
'"\n                                                        >\n\n                                                            ' +
((__t = ( L10N.get("cabinet.orders.ancillaryFeeTypeList." + type) )) == null ? '' : __t) +
'\n                                                        </div>\n                                                    ';
 } ;
__p += '\n                                                ';
 });
                                            });
                                        }); ;
__p += '\n                                    </div>\n                                    <div class="b-ancillary-fees__service-content-list b-service-content js-service-content" data-traveller-uid="' +
((__t = ( t.uid )) == null ? '' : __t) +
'"></div>\n                                </div>\n                            </div>\n                        ';
 }); ;
__p += '\n                    </div>\n                    <div class="b-ancillary-fees__traveller-prices js-travellers-prices">\n                        ' +
((__t = ( require('./template-traveller-prices.ejs')({travellers: travellers, currencyCode: currencyCode}))) == null ? '' : __t) +
'\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class="b-ancillary-fees__modal-buttons">\n            <button class="b-ancillary-fees__back js-back">' +
((__t = ( L10N.get('cabinet.orders.cancel') )) == null ? '' : __t) +
'</button>\n            <button class="b-ancillary-fees__save js-save">' +
((__t = ( L10N.get('cabinet.save') )) == null ? '' : __t) +
'</button>\n        </div>\n    </div>\n</div>\n';

}
return __p
}