module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="p-login__form-restore-wrapper">\r\n	<div class="p-login__form-restore">\r\n			<span class="js-widget"  onclick=\'return new Widgets.Login({bindingProperty: "login", type: "text", classes: "'+widgetClasses+'"})\'></span>\r\n		<div class="p-login__form-restore-info">\r\n			<p class="p-login__form-restore-info-text">\r\n				' +
((__t = ( L10N.get('AccountLoginForm.restore.infoText') )) == null ? '' : __t) +
'\r\n			</p>\r\n		</div>\r\n\r\n		<div class="p-login__form-button-container">\r\n            <div class="p-login__form-button">\r\n                <a href="#" class="p-login__form-button-link js-auth-link">' +
((__t = ( L10N.get('AccountLoginForm.restore.loginToCabinet') )) == null ? '' : __t) +
'</a>\r\n            </div>\r\n        </div>\r\n\r\n	</div>\r\n	<div class="p-login__form-notify-email p-login__form-notify-email-new">\r\n		<div class="p-login__form-notify-email-header">\r\n			<p class="p-login__form-notify-email-header-text">' +
((__t = ( L10N.get('AccountLoginForm.restore.emailSent') )) == null ? '' : __t) +
'</p></div>\r\n		<div class="p-login__form-notify-email-info">\r\n			<p class="p-login__form-notify-email-info-text">\r\n				' +
((__t = ( L10N.get('AccountLoginForm.restore.infoTextEmailSent') )) == null ? '' : __t) +
'\r\n			</p>\r\n		</div>\r\n	</div>\r\n	<div class="p-login__form-notify-email p-login__form-notify-email-generated">\r\n		<div class="p-login__form-notify-email-header">\r\n			<p class="p-login__form-notify-email-header-text">' +
((__t = ( L10N.get('AccountLoginForm.restore.infoTextHeader') )) == null ? '' : __t) +
'</p></div>\r\n		<div class="p-login__form-notify-email-info">\r\n			<p class="p-login__form-notify-email-info-text">\r\n				' +
((__t = ( L10N.get('AccountLoginForm.restore.infoTextEmailSentWithNewPassword') )) == null ? '' : __t) +
'\r\n			</p>\r\n		</div>\r\n	</div>\r\n</div>\r\n';

}
return __p
}