// eslint-disable-next-line banned-modules
'use strict';

import FilterBaseModel from '@/blocks/elements/b-filters/base.model';
import View from './view';

export default FilterBaseModel.extend({

	defaults() {
		return {
			label: '',
			min: 0,
			max: 0,
			value: [0, 1],
			active: false,
		};
	},

	View,

	initialize(attr, options) {
		this.options = options || {};
		this.set('active', this.options.activateWidgetByToggle === false);
	},

	parse(value) {
		return parseFloat(value);
	},

	filter(model) {
		const isActive = this.get('active');
		if (this.options.activateWidgetByToggle != null && !isActive) return true;
		const field = this.get('field');
		const sources = _.compact(this.get('sources'));
		const range = this.get('value');
		if (range[1] === 0) range[1] = Infinity;

		const fields = !_.isEmpty(sources) ? sources : [field];

		if (fields.length === 2) {
			const minValue = this.parse(model.get(fields[1]));
			const maxValue = this.parse(model.get(fields[0]));

			return (minValue === 0 || ((range[0] <= minValue && minValue <= range[1]) || (range[0] <= maxValue && maxValue <= range[1])));
		}

		const value = this.parse(model.get(field));
		return (value === 0 || (range[0] <= value && value <= range[1]));
	},

	isValid() {
		return (this.get('max') !== this.get('min')) && (this.get('min') < this.get('max'));
	},

	collect(model) {
		const field = this.get('field');
		const sources = _.compact(this.get('sources'));

		const fields = !_.isEmpty(sources) ? sources : [field];
		const val = this.get('value');

		if (!_.isEmpty(fields)) {
			_.each(fields, (f) => {
				const value = this.parse(model.get(f));

				if (!isNaN(value)) {
					if ((this.get('min') > value)) {
						this.set('min', value);
						val[0] = value;
						if (!this.get('__minManualTyping')) this.set('value', val);
					}
					if ((this.get('max') < value)) {
						this.set('max', value);
						val[1] = value;
						if (!this.get('__maxManualTyping')) this.set('value', val);
					}
				}
			});
		}
	},

});
