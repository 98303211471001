// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import BookingModel from './model';
import CustomerView from '@/blocks/elements/b-customer/b-customer-avia/index';
import template from './template.ejs';
import ReasonCodeView from '@/blocks/elements/b-reason-code/index';
import KeyContactView from '@/blocks/elements/b-key-contact/index';
import Rules from '@/blocks/elements/b-fare-rules/index';
import TransferTravellers from '@/blocks/elements/b-transfer/b-transfer-travellers/index';
import AdditionalServices from '@/blocks/elements/b-transfer/b-transfer-additional-services/index';
import RiderDetailsView from '@/blocks/elements/b-transfer/b-transfer-ride-details/index';
import BookingDuplicatesHandler from '@/blocks/utils/b-booking-duplicates-handler';

import axios from 'axios';

export default BaseView.extend({
	template,

	el: '.l-page__container',

	events: {
		'click .b-booking__submit': 'submitBooking',
		'click .b-booking__back': 'back',
	},

	ui: {
		commentContainer: '.b-booking-transfers__notes',
		customerContainer: '.b-booking__customer',
		submitButton: '.b-booking__submit',
		reasonCode: '.b-booking__reason-code',
		rules: '.b-booking__rules',
		keyContact: '.b-booking__key-contact',
		travellersContainer: '.b-booking-transfers__passengers-wrapper',
		additionalServicesContainer:
			'.b-booking-transfers__additional-services-wrapper',
		rideDetailsContainer: '.b-booking__transfer-ride-details',
	},

	preValidateDeferred: null,

	preinitialize(options) {
		BaseView.prototype.preinitialize.call(this, options);
		this.windowEventListenerList.push({
			mediaQuery: '(max-width: 768px)',
			name: 'change',
			callback: this.adjustMobileTemplate.bind(this),
			isMatchMedia: true,
		});
	},

	initialize() {
		const { bookingSettings = {} } = this.options || {};
		const {
			order,
			prevModel,
			keyContact,
			reasonCode,
			externalSystems,
			searchDuplicatesMessage,
		} = bookingSettings;
		this.keyContact = keyContact;
		this.reasonCode = reasonCode;
		this.externalSystems = externalSystems;

		this.addPreValidateDeferred = this.addPreValidateDeferred.bind(this);
		this.removePreValidateDeferred = this.removePreValidateDeferred.bind(this);

		if (order != null) {
			this.order = order;
			STATE.layout.header.setAdditionalOrder(order);
		}

		this.model = prevModel || new BookingModel();
		this.isIntention =
			this.options.isIntention && this.options.isIntention === true;

		this.reprice = this.reprice.bind(this);

		this.render();
		this.addEventListeners();

		this.bookingDuplicatesHandler = new BookingDuplicatesHandler({
			bookingSettings,
		});

		if (searchDuplicatesMessage) {
			this.bookingDuplicatesHandler.showSearchDuplicatesPopup({
				...searchDuplicatesMessage,
				onCancel: () => this.back(),
			});
		}
	},

	reinitialize(options) {
		this.options.parent.reinitialize(options);
	},

	addEventListeners() {
		const handler = this.validateAsync.bind(this);
		this.listenTo(this.model, 'change', handler);
		this.listenTo(this.model.get('customer'), 'change', handler);
		this.listenTo(this.model.get('travellers'), 'add remove', () => {
			setTimeout(handler, 0);
		});
		this.off('reprice');
		this.on('reprice', this.reprice);
	},

	enableSubmitButton() {
		this.ui.submitButton.removeAttr('disabled').removeClass('btn-disabled');
	},

	getBookingRequestObj(isIntention = false) {
		let parameters = this.model.toJSON();
		if (isIntention) {
			parameters = {
				parameters: {
					reservationUid: this.options.bookingSettings.reservationUid,
					currentBooking: this.options.bookingSettings.currentBooking,
					transferData: {
						createParameters: {
							...this.model.toJSON().parameters,
							...this.bookingDuplicatesHandler.getBookingDataObj(),
						},
					},
				},
			};
		} else {
			parameters.parameters = {
				...parameters.parameters,
				...this.bookingDuplicatesHandler.getBookingDataObj(),
			};
		}
		return parameters;
	},

	async validateAsync() {
		if (_.isFunction(this.cancelValidateRequest)) {
			this.cancelValidateRequest();
		}
		if (this.preValidateDeferred != null) {
			return this.preValidateDeferred.promise
				.then(() => {
					this.removePreValidateDeferred();
					return this.validate();
				})
				.catch((e) => {
					if (e !== 'Cancel') {
						throw e;
					}
				});
		}
		return this.validate();
	},

	validate() {
		this.hideValidations();

		const isSearchDuplicatesMessage = this.options.bookingSettings?.searchDuplicatesMessage;
		const CancelToken = axios.CancelToken;
		const parameters = this.getBookingRequestObj(false); // <- здесь объект валидации должен быть как в формате для /transfers/booking запроса

		// skip validation is not accepted
		if (isSearchDuplicatesMessage && !parameters.parameters.acceptSearchDuplicatesMessageId) {
			return Promise.resolve({});
		}

		if (_.isFunction(this.cancelValidateRequest)) {
			this.cancelValidateRequest();
		}

		this.hideValidations();
		return axios
			.post(
				'/midoffice/ibecorp-b2b/transfers/booking/validate',
				parameters,
				this.model,
				{
					cancelToken: new CancelToken((c) => {
						this.cancelValidateRequest = c;
					}),
				},
			)
			.then((response) => {
				const { result } = response.data;
				const disabled = result && !_.isEmpty(result.validatedParameters);

				if (disabled) {
					this.ui.submitButton.attr('disabled', true).addClass('btn-disabled');
				} else {
					this.ui.submitButton
						.attr('disabled', false)
						.removeClass('btn-disabled');
				}

				return response;
			})
			.catch((e) => {
				this.ui.submitButton.attr('disabled', true).addClass('btn-disabled');
				throw e;
			})
			.finally(() => {
				this.cancelValidateRequest = null;
			});
	},

	delegateEvents(...args) {
		BaseView.prototype.delegateEvents.apply(this, args);
	},

	undelegateEvents(...args) {
		BaseView.prototype.undelegateEvents.apply(this, args);
	},

	adjustMobileTemplate(matches) {
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			if (_.isObject(matches)) matches = matches.matches;
			const $mobileCollapseHeaders = this.$('.mobile-collapse');

			if (matches) {
				_.each(
					$mobileCollapseHeaders,
					(el) => el && this.$(el).attr('data-toggle', 'collapse'),
				);
			} else {
				_.each($mobileCollapseHeaders, (el) => {
					const $target = this.$(el);
					if (
						$target.hasClass('collapsed') ||
						!this.$($target.data('target')).hasClass('show')
					) $target.click();
					$target.removeAttr('data-toggle');
				});
			}
			if (this.travellersView != null) this.travellersView.adjustMobileTemplate(matches);
			this.renderAdditionalServices();
		}, 100);
	},

	render(...args) {
		this.showCommentBlock = true;

		BaseView.prototype.render.apply(this, args);

		// Customer info
		const {
			reasonCode,
			contactFillingType,
			contactPersonEditGranted,
			contactPersonDisableFreeTextInput,
		} = this.options.bookingSettings || {};
		const { to, from } = this.options.bookingSettings || {};
		const { tripInfo } = this.options;
		const prevReasonCode =
			this.options.prevPassengerData &&
			this.options.prevPassengerData.reasonCode;
		const { customer } =
			this.options.prevPassengerData || this.options.bookingSettings || {};

		const isRoundTrip = from != null;

		if (tripInfo.token != null) {
			this.model.set('transferToken', tripInfo.token);
		}

		this.model.set('flexibleTariff', tripInfo.flexibleTariff === true);
		this.model.set(
			'flexibleTariffAgreement',
			tripInfo.flexibleTariffAgreement === true,
		);
		if (!_.isEmpty(tripInfo.passengersTypes)) {
			this.model.set('passengersTypes', tripInfo.passengersTypes);
		}

		if (customer != null) {
			const BaseModel = this.model.get('customer').constructor;
			this.model.set(
				'customer',
				new BaseModel(
					_.extend({}, customer, {
						disabled: true,
					}),
				),
			);
		}

		this.travellersView = new TransferTravellers({
			parent: this,
			baseView: this.options.parent,
			bookingSettings: this.options.bookingSettings,
			tripInfo,
			model: this.model,
		});
		this.ui.travellersContainer.html(this.travellersView.$el);

		if (!_.isEmpty(to.additionalServices)) {
			this.additionalServicesView = new AdditionalServices({
				baseView: this.options.parent,
				model: this.model.get('additionalServices'),
				parent: this,
				to,
				from,
				tripInfo,
			});

			this.ui.additionalServicesContainer.html(this.additionalServicesView.$el);
		}

		const passengersAmountCollection = [];
		for (let i = 0; i < tripInfo.capacity.uid; i++) {
			passengersAmountCollection.push({ uid: i + 1, caption: i + 1 });
		}
		this.rideDetailsView = new RiderDetailsView({
			model: this.model,
			parent: this,
			passengersAmountCollection,
			isRoundTrip,
			tripInfo,
			from,
			to,
		});
		this.ui.rideDetailsContainer.html(this.rideDetailsView.$el);

		this.customerView = new CustomerView({
			parentModel: this.model.get('travellers'),
			contactFillingType,
			contactPersonEditGranted,
			contactPersonDisableFreeTextInput,
			contactAutocompleteAvailable:
				this.options.bookingSettings.contactAutocompleteAvailable,
			model: this.model.get('customer'),
			langRegistrationFullName:
				this.options.bookingSettings.langRegistrationFullName,
		});
		this.ui.customerContainer.html(this.customerView.$el);

		if (reasonCode && reasonCode.values) {
			this.ui.reasonCode.html(
				new ReasonCodeView({
					model: this.model,
					reasonCode,
				}).$el,
			);

			if (prevReasonCode && !_.isEmpty(prevReasonCode)) {
				this.model.set('reasonCode', prevReasonCode);
			}
		} else {
			this.ui.reasonCode.hide();
		}

		if (this.keyContact && this.keyContact.values) {
			this.ui.keyContact.html(
				new KeyContactView({
					model: this.model,
					keyContact: this.keyContact,
				}).$el,
			);
		} else {
			this.ui.keyContact.hide();
		}

		const rules = new Rules({
			model: this.model,
			travelSubjectUid: 'TRANSFER',
		});

		this.ui.rules.html(rules.el);

		this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));
	},

	renderAdditionalServices() {
		if (this.additionalServicesView) {
			this.additionalServicesView.removeListeners();
			this.additionalServicesView.$el.remove();
			const op = this.additionalServicesView.options;
			this.additionalServicesView.initialize(op);
			this.ui.additionalServicesContainer
				.empty()
				.append(this.additionalServicesView.$el);
		}
	},

	// EVENTS
	async submitBooking(e) {
		if (e != null) {
			e.preventDefault();
		}

		STATE.showLoader();
		await this.validateAsync();
		this.ui.submitButton.attr('disabled', true).addClass('btn-disabled');
		STATE.setBookingInProcess(true);

		this.disableElements(e);

		const parameters = this.getBookingRequestObj(this.isIntention);

		if (!this.isIntention) {
			_.each(_.keys(parameters.parameters), (key) => {
				const params = parameters.parameters;
				if (key.indexOf('externalSystem') !== -1) {
					if (params.externalSystemCodes !== undefined) {
						params.externalSystemCodes.push({
							uid: key.split('__')[1],
							code: params[key],
						});
					} else {
						params.externalSystemCodes = [
							{ uid: key.split('__')[1], code: params[key] },
						];
					}
					delete params[key];
				}
			});
		}
		const rest = this.isIntention
			? `/midoffice/ibecorp-b2b/booking/approveIntention`
			: `/midoffice/ibecorp-b2b/transfers/booking`;

		axios
			.post(rest, parameters, this.model, { enableCashing: true })
			.then((response) => {
				const { result } = response.data;

				const resultObjToUse = this.isIntention
					? result.transferBookingResult
					: result;

				if (
					this.bookingDuplicatesHandler.shouldShowProductDuplicatesPopup(
						resultObjToUse,
					)
				) {
					this.bookingDuplicatesHandler.showProductDuplicatesPopup({
						...resultObjToUse,
						onOk: () => this.submitBooking(),
						onCancel: () => this.back(),
					});
					return result;
				}

				return this.submitBookingCallback(response);
			})
			.catch((ex) => {
				STATE.hideLoader();
				this.ui.submitButton
					.attr('disabled', false)
					.removeClass('btn-disabled');
				throw ex;
			})
			.finally(() => {
				this.ui.submitButton
					.attr('disabled', false)
					.removeClass('btn-disabled');
				STATE.hideLoader();
			});
	},

	submitBookingCallback(response) {
		const { result } = response.data;

		if (result.booking != null || result.transferBookingResult != null) {
			const newUid = this.isIntention
				? result.transferBookingResult.booking.uid
				: result.booking.uid;

			STORE.set(STATE.ROUTES.CABINET_ORDER, {
				orderUid: newUid,
				transferInfo: this.model.toJSON(),
			});

			if (result.skipGetBookingInfo) {
				const popup = Widgets.Popup({
					content: result.messages.length && result.messages.join('</br>'),
					type: 'info',
					actions: [
						{
							label: L10N.get('bookingForm.dupesPopup.back'),
							action: () => {
								window.location.href = STATE.getPreviousLocation().href;
								popup.hide();
							},
						},
					],
				});
				popup.show();
				return this;
			}

			if (STATE.getQueryVariable('pnr') != null) {
				window.location.href = `${STATE.getRootUrl()}#${
					STATE.ROUTES.CABINET_ORDER
				}/${newUid}`;
			} else {
				STATE.navigate([STATE.ROUTES.CABINET_ORDER, newUid].join('/'));
			}
		}
		return this;
	},

	reprice() {
		const params = this.model.toJSON();
		const t = this;

		// Здесь travellers уже должны быть

		STATE.showLoader();
		return axios
			.post(
				'/midoffice/ibecorp-b2b/transfers/pricing',
				params,
				this.options.parent.model,
			)
			.then((response) => {
				const { result } = response.data;
				if (result != null) {
					result.prevModel = t.model;
					result.prevPassengerData = params.parameters;
					t.options.parent.renderRouteInfo(result);
					t.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));
				}
			})
			.finally(() => STATE.hideLoader());
	},

	back(e) {
		if (e != null) {
			e.preventDefault();
		}

		STATE.navigate(STATE.ROUTES.TRANSFERS_OFFERS);
	},

	/* preValidateDeferred handlers */

	addPreValidateDeferred(deferred) {
		this.cancelPreValidateDeferred();
		this.preValidateDeferred = deferred;
	},

	removePreValidateDeferred() {
		this.preValidateDeferred = null;
	},

	cancelPreValidateDeferred() {
		if (this.preValidateDeferred) this.preValidateDeferred.reject('Cancel');
		this.removePreValidateDeferred();
	},

	/* EOF preValidateDeferred handlers */

	remove() {
		this.cancelPreValidateDeferred();
		this.bookingDuplicatesHandler.remove();
		BaseView.prototype.remove.call(this);
	},
});
