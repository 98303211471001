// eslint-disable-next-line banned-modules
'use strict';

import BaseView from '@/classes/base.view';
import template from './template.ejs';
import Select from '@/widgets/b-select/index';

export default BaseView.extend({

	template,

	initialize(options) {
		this.options = options;
		this.model = options.model;
		const {trip} = this.options;

		this.renderSettings = {
			passengersAmountCollection: Select.storeCollection(Select.dictionaryToCollection(this.options.passengersAmountCollection)),
		};
		if (!this.options.isRoundTrip || this.options.index === 1) {
			const count = this.options.travellersCount;
			this.model.set('travellersCount', {uid: count, caption: count});
		}

		_.each([trip.startPlaceInfo, trip.endPlaceInfo], (place) => {
			if (place && !_.isEmpty(place.terminals)) {
				if (!Array.isArray(place.terminals)) return;
				place.terminals = _.map(place.terminals, (item) => {
					item = {uid: item, caption: item};
					return item;
				});
				place.terminals = Select.storeCollection(Select.dictionaryToCollection(place.terminals));
			}
		});

		this.render({...this.options, ...this.renderSettings});
	},
});
