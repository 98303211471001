// eslint-disable-next-line banned-modules
'use strict';

import Widget from '@/widgets/base.widget';
import template from './template.ejs';

export default Widget.extend({

	template,

	events: {
		'click .js-select-option': 'toggleSelect',
	},

	change() {
		const model = this.collection.get(this.ui.input.find('option:selected').attr('value')).attributes;
		this.select(model);
		this.model.set(this.options.bindingProperty, model);
	},

	applyBinding(model) {
		logger.debug(this.options.bindingProperty, model);
		this.model = model;
		this.listenTo(model, `change:${this.options.bindingProperty}`, (thisModel, value) => {
			this.select(value);
		});
		if (this.model.has(this.options.bindingProperty)) {
			this.select(this.model.get(this.options.bindingProperty));
		}
	},

	toggleSelect() {
		if (this.options.disabled) {
			return;
		}

		const collection = this.model.get(this.options.bindingProperty);
		const selectedValue = collection.findWhere(this.options.value);
		const selected = selectedValue != null;

		if (selected) {
			if (collection.length !== 1) { // Not reset already selcted option
				collection.remove(selectedValue);
				this.$el.removeClass('active');
			} else {
				return;
			}
		} else {
			this.model.trigger('convertRoutePoints', collection, this.options.value);
			this.model.trigger('change:resetFormSettings');
			// TODO: remove after combined search will enabled
			collection.reset();
			// EOF TODO
			collection.add(this.options.value);
			this.$el.addClass('active');
		}

		this.model.trigger('searchFormSettings');
		this.model.trigger('change:serviceTypesRedraw');
	},

	select(collection) {
		if (collection != null && this.options.value != null) {
			if (collection.findWhere(this.options.value) != null) {
				this.$el.addClass('active');
			}
		}
	},

});
