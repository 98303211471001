// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import childAgeItem from './template_child-age-item.ejs';
import PassengerModel from './passenger-model';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';
import Formatter from '../../../utils/formatter';
import $ from 'jquery';

export default Widget.extend({

	validationTemplate,

	template,

	ui: {
		childAgeContent: '.b-passengers-hotels__child-age__content',
		childAge: '.b-passengers-hotels__child-age',
	},

	events: {
		click: 'setActive',
		'click .b-passengers__item-minus': 'minus',
		'click .b-passengers__item-plus': 'plus',
		'click .b-passengers__wrapper': 'open',
		'change .b-passengers-hotels__child-age__item-input': 'updateChildsAge',
	},

	initialize() {
		this.render({
			types: [
				{
					uid: 'ADULT',
					caption: Formatter.toCamelCase(L10N.get('passengerTypes.ADULT')),
				},
				{
					uid: 'CHILD',
					caption: Formatter.toCamelCase(L10N.get('passengerTypes.CHILD')),
				},
			],
		});
	},

	updateChildsAge() {
		const arr = [];
		this.$('.b-passengers-hotels__child-age__item-input').each((i, item) => {
			if ($(item).val() !== '') {
				arr.push($(item).val());
			}
		});
		STATE.getSearchModel().set('childsAge', arr);

		const childsAge = STATE.getSearchModel().get('childsAge');
		const isExtraAge = childsAge.some((age) => {
			return age.match(/(1)([2-7])/);
		});
		const $container = $('.b-passengers__info-extra-age');
		if (isExtraAge) {
			$container.html(L10N.get('searchForm.extraAge'));
		} else {
			$container.empty();
		}

		this.isActive = false;
	},

	setActive() {
		this.isActive = true;
	},

	open() {
		this.$el.toggleClass('open');
		this.$('.b-passengers__validation').toggleClass('b-passengers__validation-visible');
		$('html').on('click.passengers', () => {
			if (!this.isActive) {
				this.$el.removeClass('open');
				this.$('.b-passengers__validation').removeClass('b-passengers__validation-visible');
				$('html').off('click.passengers');
			}
			this.isActive = false;
		});
	},

	updateLabel() {
		const guests = this.model.toJSON();
		const guestsLabels = _.values(_.mapObject(guests, (val, key) => `${val} ${L10N.getPluralForm(`searchForm.guests.${key}`, val)}`));
		const total = _.reduce(guests, (a, b) => a + b, 0);

		this.$('.b-passengers__input').text(guestsLabels.join(', '));

		const $selectImage = this.$el.find('.b-passengers__select-img');
		const clearTypes = () => {
			this.model.set(PassengerModel.prototype.defaults());
		};

		if (total > 1) {
			$selectImage.addClass('b-passengers__select-img--type-cancel').click(clearTypes.bind(this));
		} else {
			$selectImage.removeClass('b-passengers__select-img--type-cancel');
		}
	},

	minus(e) {
		const parent = $(e.currentTarget).closest('.b-passengers__item');
		this.model.minus(parent.attr('data-type'));
	},

	plus(e) {
		const parent = $(e.currentTarget).closest('.b-passengers__item');
		this.model.plus(parent.attr('data-type'));
	},

	displaychildAgeSelector() {
		const count = this.model.get('CHILD');
		const childsAge = STATE.getSearchModel().get('childsAge');
		this.ui.childAgeContent.empty();
		if (count <= 0) {
			this.ui.childAge.addClass('dni');
		} else {
			this.ui.childAge.removeClass('dni');
			for (let i = 0; i < count; i++) {
				this.ui.childAgeContent.append(childAgeItem({
					isExtraAge: true,
					number: i,
					value: (childsAge && childsAge[i]) || '',
				}));
				if (childsAge && childsAge[i]) {
					this.$(`.b-passengers-hotels__child-age__item-input-${i} option[value=${childsAge[i]}]`)
						.prop('selected', true);
				}
			}
		}
		this.updateChildsAge();
	},

	applyBinding() {
		this.model = STATE.getSearchModel().get('passengersTypes');

		_.each(PassengerModel.prototype.defaults(), (count, type) => {
			const item = this.$el.find(`[data-type=${type}]`);
			const itemValue = item.find('.b-passengers__item-value');
			this.model.on(`change:${type}`, (model, value) => {
				itemValue.text(value);
				this.updateLabel();
			});
			this.model.on(`validationError:${type}`, (errors) => {
				this.showValidation(errors);
			});

			itemValue.text(this.model.get(type));
		});

		this.model.on(`change:CHILD`, () => {
			this.displaychildAgeSelector();
		});

		this.updateLabel();
		for (let i = 0; i < 9; i++) {
			this.listenTo(STATE.getSearchModel(), `validationError:childsAge.${i}`, (errors) => {
				this.showValidation(errors);
				this.$('.b-passengers__child-age__item-input').eq(i).addClass('validation-error');
			});
		}
	},

});
