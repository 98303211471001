'use strict';

import BasePageView from '@/pages/base.page-view';
import SortingView from '@/blocks/elements/b-sorting/index';
import TransfersView from '@/blocks/pages/b-transfers/index';
import FiltersView from '@/blocks/elements/b-filters/b-filters-transfers/index';
import TransfersSearch from '@/blocks/pages/b-transfers/b-transfers-search-form/index';
import TransfersPopupOffers from '@/blocks/pages/b-transfers/b-transfers-popup-offers/b-transfers-popup-offers';
import TransfersModalOffersView from '@/blocks/pages/b-transfers/b-transfers-popup-offers/index';
import BaseModel from '@/classes/base.model';
import SideClickOutsideAdapter from '@/blocks/utils/b-side-click-outside-adapter';
import GlUl from '@/utils/global-utils';
import $ from 'jquery';
import axios from 'axios';

export default BasePageView.extend({

	ui: {
		content: '.b-tickets-container',
		controls: '.l-layout__content-controls',
		sidebar: '.l-layout__content-side',
		top: '.l-layout__top',
	},

	preinitialize(options) {
		BasePageView.prototype.preinitialize.call(this, options);
		this.sideOutsideClickAdapter = new SideClickOutsideAdapter({
			applyHandlerOnStart: window.matchMedia('(min-width: 768px)').matches,
		});
		this.windowEventListenerList.push(
			{
				name: 'change',
				callback: this.sideOutsideClickAdapter.handleSideOutsideClickHandler,
				mediaQuery: '(min-width: 768px)',
				isMatchMedia: true,
			},
			{
				name: 'keyup',
				callback: this.contentSideEscHandler.bind(this),
			},
		);
	},

	initialize(options) {
		this.options = options;
		this.results = this.options.results || {};

		this.approvalOffers = [];

		this.render();
		this.addEventListeners();
	},

	render(...args) {
		BasePageView.prototype.render.apply(this, args);

		this.transfersView = new TransfersView({ parent: this, results: this.results });
		if (!STATE.getIsFromHash()) {
			this.transfersSearchView = new TransfersSearch({parent: this});
		}

		if (this.transfersSearchView) this.ui.top.append(this.transfersSearchView.$el);
		this.ui.content.addClass('b-tickets-container-transfers');
		this.ui.controls.append(`<div class="l-content-table-title-transfers">${L10N.get('trains.fullPrice')}</div>`);
		this.ui.content.append(this.transfersView.$el);

		this.renderFilters();
		this.applyFilters();
	},

	contentSideEscHandler(e) {
		if (e.code === 'Escape') {
			$('.l-layout').removeClass('show-filters');
		}
	},

	renderFilters() {
		if (this.transfersView == null) {
			return this;
		}
		const {currencyCode} = ((this.results || [])[0] || {}).totalPrice || {};
		this.filtersView = new FiltersView({ models: this.transfersView.models, currencyCode });

		this.sortingView = new SortingView({
			container: this.ui.content.find('.b-transfer__results'),
			views: this.transfersView.views,
			type: 'TRANSFER',
			renderCallback: (views) => {
				this.filtersView.applyFilters();
				const visibleViews = _.filter(views, (view) => view.model.get('visibility') === true);
				this.transfersView.renderResults(visibleViews);
			},
		});

		this.filtersView.addSorting(this.sortingView);

		this.ui.sidebar.empty().append(this.filtersView.$el);

		return this;
	},

	applyFilters() {
		this.filtersView.applyFilters();
	},

	addEventListeners() {
		this.listenTo(this.getPageModel(), 'change:isFilteringResults', (model, value) => {
			if (value) {
				this.ui.content.addClass('g-process');
			} else {
				this.ui.content.removeClass('g-process');
			}
		});
	},

	addOffer(e, { transferToken, transfer }, _exclude = false) {
		const $target = $(e.currentTarget);
		let exclude = _exclude;

		if ($target.length !== 0) {
			if (!$target.hasClass('b-ticket-offer__cancel')) {
				$target.attr('data-original-caption', _.escape($target.html()));
				$target.attr('data-transfer-token', _.escape(transferToken));
				$target.text(L10N.get('avia.remove'));

				$target.addClass('b-ticket-offer__cancel');
			} else {
				const originalCaption = $target.attr('data-original-caption');
				exclude = true;

				$target.html(originalCaption);
				$target.removeAttr('data-original-caption').removeClass('b-ticket-offer__cancel');
				$target.removeAttr('data-transfer-token');
			}
		}

		if (exclude !== true) {
			this.approvalOffers.push({ transfer, transferToken });
		} else {
			const indexTransfer = _.findIndex(this.approvalOffers, (o) => o != null && o.transferToken === transferToken);

			if (indexTransfer !== -1) {
				this.approvalOffers.splice(indexTransfer, 1);
			}
		}

		this.ui.content.find('.b-ticket-popup-offers').detach();
		if (this.approvalOffers.length) {
			this.ui.content.append(TransfersPopupOffers({
				data: this.approvalOffers,
			}));
			this.ui.content.find('.b-ticket-popup-offers').on('click', this.sendOffers.bind(this));
		}
	},

	sendOffers(e) {
		if (e != null) {
			e.preventDefault();
		}

		// First ticket card for sync width and postion right
		const $ticketContainer = this.ui.content.find('.b-transfer.b-transfer__visible').first();
		const { order } = this.options || {};
		if (this.ticketModalOffers == null) {
			this.ticketModalOffers = new TransfersModalOffersView({ data: this.approvalOffers, parent: this, $ticketContainer, order });
		} else {
			this.ticketModalOffers.setOptions({ data: this.approvalOffers, $ticketContainer }).render();
		}
	},

	remove() {
		if (this.sideOutsideClickAdapter) {
			this.sideOutsideClickAdapter.removeListener();
		}
		BasePageView.prototype.remove.call(this);
	},

}, {

	loadFromHash(hash) {
		// CL|ECONOMY!D|true!R|2000000|2004000|2023-05-20!R|2004000|2000000|2023-06-03!PT|ADULT|1!RT|ROUNDTRIP!C|false +
		// !C|07e9360d-542d-4518-a494-87c5589f0d3a!P|!S|RAIL
		STATE.showLoader();
		return new Promise(async (resolve) => {
			try {
				const attrs = {
					passengersTypes: {},
					searchType: 'RAIL',
					startPlace: {
						longitude: null,
						latitude: null,
					},
					endPlace: {
						longitude: null,
						latitude: null,
					},
					subAddresses: [],
				};

				STATE.checkHash(hash, 'TRANSFER');
				STATE.getParsedHash(hash, attrs);

				const appSearchModel = STATE.getSearchModel();

				if (!_.isEmpty(attrs.passengersTypes)) {
					Object.keys(attrs.passengersTypes).forEach(key => {
						appSearchModel.get('passengersTypes').set(key, attrs.passengersTypes[key]);
					});
				}

				appSearchModel.set('date', attrs.date);
				appSearchModel.set('dateBack', attrs.dateBack);
				appSearchModel.set('startPlace', attrs.startPlace);
				appSearchModel.set('endPlace', attrs.endPlace);
				appSearchModel.set('transferType', attrs.transferType);
				_.each(attrs.subAddresses, (el) => {
					appSearchModel.get('subAddresses').add(new BaseModel({location:el}));
				});
				appSearchModel.get('serviceTypes').reset([{uid: attrs.searchType, caption: attrs.searchType}]);
				const load = () => {
					const url = '/midoffice/ibecorp-b2b/transfers/search';
					const params = appSearchModel.toJSON();
					STORE.set(STATE.ROUTES.MAIN_SEARCH, params.parameters, 14400);
					axios.post(url, params, appSearchModel)
						.then(result => {
							STATE.setTransfersSearchResult(result.data.result);
							STATE.removeHeader();
							resolve({fromHash: true});
						}).catch(e => {
							STORE.remove(STATE.ROUTES.TICKETS);
							logger.error(e);
							resolve();
						});
				};
				if (GlUl.geocoderLocationUpdateNeeded(appSearchModel)) {
					GlUl.updateGeocoderLocations(this.searchTransfer, appSearchModel).then(() => {
						load();
					});
				} else {
					load();
				}
			} catch (e) {
				logger.error(e);
				resolve();
			}
		});
	},

	async load(params) {
		return new Promise((resolve) => {
			const results = STATE.getTransferSearchResults();

			if (_.isEmpty(results)) {
				STATE.navigate(STATE.ROUTES.INDEX);
				return;
			}

			if (params.fromHash) {
				STATE.setIsFromHash(true);
				STATE.disableFirstStep();
			}

			resolve({ results, initScrollTopButton: true });
		});
	},
});
