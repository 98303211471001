// eslint-disable-next-line banned-modules
'use strict';

class TravellersCostCodesHandler {
	travellersViews = null;

	touchedCostCodes = null;

	constructor(options = {}) {
		this.disableAllFilledCommons = !!options.disableAllFilledCommons;
		this.filledCommonCostCodes = {};
		this.touchedCostCodes = {};
		this.handleTravellerPersonChanged = this.handleTravellerPersonChanged.bind(this);
		this.handleTravellerPersonRemoved = this.handleTravellerPersonRemoved.bind(this);
		this.handleCostCodesCollectionChange = this.handleCostCodesCollectionChange.bind(this);
		this.handleCostCodesViewRendered = this.handleCostCodesViewRendered.bind(this);
		this.resetTravellersViews();
	}

	addTravellerView = (view, applyTouchedCostCodes = false) => {
		if (!view || !view?.model) return;
		const id = view?.cid;
		if (!id) return;
		this.travellersViews[id] = view;
		/*
			Проверяем для той ситуации, когда мы добавили нового пользователя.
			Соответственно, мы должны заполнить и задизейблить его поля с common костКодами.
		*/
		if (applyTouchedCostCodes) this.applyTouchedCostCodesToAddedView(view);
		this.addTravellerPersonChangedListener(view);
		this.addTravellerPersonRemovedListener(view);
		this.addCostCodesViewRenderedListener(view);
		this.addCostCodesCollectionListener(view.model.get('costCodes'));

		/* disable все заполненные common коды (например когда добавляепм продукт
      к существующему заказу
    ) */
		if (this.disableAllFilledCommons) {
			this.disableAllFilledCommonsHandler(view);
		}
	};

	disableAllFilledCommonsHandler = (view) => {
		const costCodesView = this.getCostCodesView(view);
		const costCodes = view?.model ? view.model.get('costCodes') : null;
		if (costCodes !== null && costCodesView?.widgets) {
			// Заполняем список common costcodes с заполенными значениями
			costCodes.reduce((filled, costCodeModel) => {
				if (costCodeModel?.get && !!costCodeModel.get('common') && costCodeModel.get('value')) {
					filled[costCodeModel.get('costCodeCategoryUid')] = costCodeModel.get('value');
				}
				return filled;
			}, this.filledCommonCostCodes);
			
			costCodes.forEach((costCodeModel) => {
				const isCommon = !!costCodeModel.get('common');
				const field = costCodeModel.get('costCodeCategoryUid');
				if (costCodeModel?.get && isCommon) {
					const value = costCodeModel.get('value');
					// Заполняем значание если оно было уже проставлено у другого пассажира
					if (!value && this.filledCommonCostCodes[field]) {
						costCodeModel.set('value', this.filledCommonCostCodes[field]);
					}
					if (costCodeModel.get('value')) {
						this.handleWidgetsDisableState(costCodesView.widgets, field, true);
					}
				}
			});
		}
	};

	removeTravellerView = (view, handleTouchedCostCodes = false) => {
		if (!view || !view?.model) return;
		const id = view?.cid;
		if (!id) return;
		this.removeTravellerPersonChangedListener(view);
		this.removeTravellerPersonRemovedListener(view);
		this.removeCostCodesViewRenderedListener(view);
		this.removeCostCodesCollectionListener(view.model.get('costCodes'));
		/*
			Проверяем для той ситуации, когда пользователь, у которого выставили костКоды, удаляется.
			Соответственно, мы должны сбросить и раздизейблить все common поля.
		*/
		if (handleTouchedCostCodes) this.handleTouchedCostCodesByRemovedView(view);
		delete this.travellersViews[id];
	};

	addCostCodesCollectionListener = (collection) => {
		if (!collection) return;
		collection.on('change', this.handleCostCodesCollectionChange);
	};

	removeCostCodesCollectionListener = (collection) => {
		if (!collection) return;
		collection.off('change', this.handleCostCodesCollectionChange);
	};

	addCostCodesViewRenderedListener = (view) => view.on('costCodesViewRendered', this.handleCostCodesViewRendered);

	removeCostCodesViewRenderedListener = (view) => view.off('costCodesViewRendered', this.handleCostCodesViewRendered);

	addTravellerPersonChangedListener = (view) => view.on('travellerPersonChanged', this.handleTravellerPersonChanged);

	removeTravellerPersonChangedListener = (view) => view.off('travellerPersonChanged', this.handleTravellerPersonChanged);

	addTravellerPersonRemovedListener = (view) => view.on('travellerPersonRemoved', this.handleTravellerPersonRemoved);

	removeTravellerPersonRemovedListener = (view) => view.off('travellerPersonRemoved', this.handleTravellerPersonRemoved);

	handleTravellerPersonRemoved(view) {
		this.removeTravellerView(view, true);
	}

	handleCostCodesViewRendered(view) {
		const costCodesView = this.getCostCodesView(view);
		if (costCodesView && costCodesView?.widgets && !_.isEmpty(this.touchedCostCodes)) {
			Object.keys(this.touchedCostCodes).forEach((key) => {
				const touchedCostCode = this.touchedCostCodes[key];
				if (touchedCostCode?.viewCid === view?.cid || touchedCostCode?.modelCid === view.model?.cid) {
					// значит, это вьюшка-инициатор значения в common костКоде
				} else {
					this.handleWidgetsDisableState(costCodesView.widgets, key, true);
				}
			});
		}
	}

	handleTravellerPersonChanged(view) {
		/*
			Отрабатывает, когда:
				1) через автокомплит фамилии поменяли пассажира;
				2) переключили свитчер языка в пассажире;
				3) поменяли документ пассажира.
			Вкратце, вьюшка пассажира должна триггерить этот метод каждый раз, когда
			вызывается ее render(), кроме самого первого рендера. Т.к. в разных местах после рендера
			могут производится еще какие-то действия с моделью (например, GlUl.fillPassengerData({ ... })),
			то вызываем метод точечно, а не внутри самого render(), т.к. на момент его вызова в модели
			могут быть еще не все данные.
      В таких случаях нужно проверить все то, что было связано с изменившейся моделью и,
      если нужно, раздизейблить поля.
    */
		if (view?.cid && this.travellersViews[view.cid]) {
			this.removeTravellerView(view);
			this.resetRelativeCostCodeFieldsOnPersonChanged(view);
			this.addTravellerView(view);
		}
	}

	applyTouchedCostCodesToAddedView = (view) => {
		if (!view || !view?.model || _.isEmpty(this.touchedCostCodes)) return;
		const costCodes = view?.model ? view.model.get('costCodes') : null;
		if (costCodes !== null) {
			costCodes.forEach((costCodeModel) => {
				if (costCodeModel?.get && costCodeModel.get('common')) {
					const field = costCodeModel.get('costCodeCategoryUid');
					const touchedCostCodesField = this.touchedCostCodes[costCodeModel.get('costCodeCategoryUid')];
					if (touchedCostCodesField && touchedCostCodesField.value) {
						costCodeModel.set('value', touchedCostCodesField.value);
						const costCodesView = this.getCostCodesView(view);
						if (costCodesView?.widgets) {
							this.handleWidgetsDisableState(costCodesView.widgets, field, true);
						}
					}
				}
			});
		}
	};

	getCostCodesToResetByView = (view) => {
		const costCodesToReset = [];
		Object.keys(this.touchedCostCodes).forEach((key) => {
			const touchedCostCode = this.touchedCostCodes[key];
			if (touchedCostCode?.viewCid === view?.cid || touchedCostCode?.modelCid === view.model?.cid) {
				costCodesToReset.push(key);
			}
		});
		return costCodesToReset;
	};

	handleTouchedCostCodesByRemovedView = (view) => {
		if (!view || !view?.model || _.isEmpty(this.touchedCostCodes) || _.isEmpty(this.travellersViews)) return;
		const costCodesToReset = this.getCostCodesToResetByView(view);
		if (costCodesToReset.length) {
			Object.keys(this.travellersViews).forEach((key) => {
				const travelerView = this.travellersViews[key];
				if (travelerView?.model?.get && travelerView.model.get('costCodes')) {
					travelerView.model.get('costCodes').forEach((costCodeModel) => {
						costCodeModel.set('value', undefined);
					});
				}

				const costCodesView = this.getCostCodesView(travelerView);

				if (costCodesView?.widgets) {
					costCodesToReset.forEach((costCodeToReset) => {
						this.handleWidgetsDisableState(costCodesView.widgets, costCodeToReset, false);
					});
				}
			});
			costCodesToReset.forEach((costCodeToReset) => {
				delete this.touchedCostCodes[costCodeToReset];
			});
		}
	};

	resetRelativeCostCodeFieldsOnPersonChanged = (view) => {
		/*
			Ищем в ранее выставленных костКодах вьюшку и применяем к ней ранее выставленные значения
		*/
		if (!_.isEmpty(this.touchedCostCodes)) {
			const costCodes = view?.model ? view.model.get('costCodes') : null;
			if (costCodes !== null) {
				costCodes.forEach((costCodeModel) => {
					if (costCodeModel?.get && costCodeModel.get('common')) {
						const field = costCodeModel.get('costCodeCategoryUid');
						const touchedCostCodesField = this.touchedCostCodes[costCodeModel.get('costCodeCategoryUid')];
						if (touchedCostCodesField) {
							const areModelCidsEqual = touchedCostCodesField?.modelCid === costCodeModel.cid;
							const areViewsCidsEqual = touchedCostCodesField?.viewCid === view?.cid;
							/*
								Кейс, когда поменялись данные пассажира (заменили одного на другого), костКоды которого
								использовались как touched.
							*/
							if ((areModelCidsEqual || areViewsCidsEqual) && !costCodeModel.get('value') && touchedCostCodesField.value) {
								if (!areModelCidsEqual) {
								/*
										Если модели в коллекции поменялись, нам нужно переустановить cid touched модели
										на новый cid.
									*/
									this.touchedCostCodes[field].modelCid = costCodeModel.cid;
								}
								costCodeModel.set('value', touchedCostCodesField.value);
							} else if (!areViewsCidsEqual && !costCodeModel.get('value') && touchedCostCodesField.value) {
							/*
									Кейс, когда поменялись данные пассажира (заменили одного на другого), костКоды которого
									блокировались ибыли зависимыми от touched.
								*/
								costCodeModel.set('value', touchedCostCodesField.value);

								const costCodesView = this.getCostCodesView(view);

								if (costCodesView?.widgets) {
									this.handleWidgetsDisableState(costCodesView.widgets, field, true);
								}
							}
						}
					}
				});
			}
		}
	};

	isViewCostCodeModel(view, model) {
		if (!view || !view?.model || !model) return false;
		if (!view.model.get('costCodes')) return false;
		return view.model.get('costCodes').find((m) => m === model);
	}

	handleCostCodesCollectionChange(model) {
		/*
      Приходит модель костКода.
      1) нужно определить, к какой вьюшке она относится, чтобы не задизейблить данные там.
      2) проставить данные в остальные схожие модели костКодов в других вьюшках и задизейблить их.
    */

		const isCommonCostCode = model.get('common');
		if (!isCommonCostCode) return;

		const changedValue = model.changedAttributes()?.value;
		const hasValue = !!changedValue;
		const field = model.get('costCodeCategoryUid');
		const touchedField = this.touchedCostCodes[field];

		const changedValueToAssign = changedValue !== null && typeof changedValue === 'object' ? { ...changedValue } : changedValue;

		if (!touchedField && hasValue) {
			this.touchedCostCodes[field] = {
				modelCid: model.cid,
				value: changedValueToAssign,
			};
		} else {
			if (touchedField?.modelCid === model.cid) {
				// изменена та же модель
				this.touchedCostCodes[field].value = changedValueToAssign;
				if (!hasValue) {
					// значения больше нет, считаем, что данное поле можно редактировать у любого другого путешественника
					delete this.touchedCostCodes[field];
				}
			} else {
				// изменена не та же самая модель, останавливаемся.
				return;
			}
		}

		const travellersViewsKeys = Object.keys(this.travellersViews);
		let changedView;
		for (let i = 0; i < travellersViewsKeys.length; i++) {
			const view = this.travellersViews[travellersViewsKeys[i]];
			if (this.isViewCostCodeModel(view, model)) {
				changedView = view;
				break;
			}
		}

		if (changedView) {
			if (this.touchedCostCodes[field] && !this.touchedCostCodes[field]?.viewCid) {
				this.touchedCostCodes[field].viewCid = changedView.cid;
			}

			Object.keys(this.travellersViews).forEach((key) => {
				const travelerView = this.travellersViews[key];
				// только для тех вьюшек, в которых не происходили изменения
				if (travelerView !== changedView) {
					// меняем значение в моделях
					if (travelerView?.model?.get && travelerView.model.get('costCodes')) {
						travelerView.model.get('costCodes').forEach((costCodeModel) => {
							if (costCodeModel.get('costCodeCategoryUid') === field) {
								/*
                  триггерит handleCostCodesCollectionChange() уже для остальных моделей, но silent делать нельзя,
                  т.к. вьюшка должна услышать изменения и отреагировать на них (reprice, например).
                */
								costCodeModel.set('value', changedValue);
							}
						});
					}

					const costCodesView = this.getCostCodesView(travelerView);

					// меняем состояние полей
					if (costCodesView && costCodesView?.widgets && !_.isEmpty(costCodesView.widgets)) {
						this.handleWidgetsDisableState(costCodesView.widgets, field, hasValue);
					}
				}
			});
		}
	}

	getCostCodesView = (view) => {
		if (!view) return null;
		return view?.costCodesView || view?.costCodesAdapter?.costCodesView;
	};

	handleWidgetsDisableState = (widgets = {}, field, state = false) => {
		Object.keys(widgets).forEach((wKey) => {
			if (wKey === field) {
				const widget = widgets[wKey];
				if (widget?.disable) widget.disable(state);
			}
		});
	};

	clearListeners = () => {
		Object.keys(this.travellersViews).forEach((key) => {
			const view = this.travellersViews[key];
			this.removeTravellerPersonChangedListener(view);
			this.removeTravellerPersonRemovedListener(view);
			this.removeCostCodesViewRenderedListener(view);
			if (view?.model) this.removeCostCodesCollectionListener(view.model.get('costCodes'));
		});
	};

	resetTravellersViews = () => {
		this.travellersViews = {};
	};

	clear = () => {
		this.clearListeners();
		this.resetTravellersViews();
		this.touchedCostCodes = null;
	};
}

export default TravellersCostCodesHandler;
