module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-matrix-controls">\r\n	';

		var serachParametrs = STATE.getSearchParametrs();
		var settings = STATE.getSettings()
 		var searchMatrixFlightsEnabled = settings && settings.firstStepSettings && settings.firstStepSettings.searchMatrixFlightsEnabled && serachParametrs.routeType !== 'MULTISTOP';
 	;
__p += '\r\n	<div class="b-matrix-controls__control b-matrix-controls__current-date ' +
((__t = ( searchMatrixFlightsEnabled ? '' : 'b-matrix-controls__control-full' )) == null ? '' : __t) +
'">\r\n		' +
((__t = ( L10N.get("matrixControls.currentDate") )) == null ? '' : __t) +
'\r\n	</div>\r\n	';

	if (searchMatrixFlightsEnabled) {
		;
__p += '\r\n		<div class="b-matrix-controls__control b-matrix-controls__nearest-dates">\r\n			' +
((__t = ( L10N.get("matrixControls.nearestDates") )) == null ? '' : __t) +
'\r\n		</div>\r\n		';

	}
	;
__p += '\r\n</div>\r\n';

}
return __p
}