// eslint-disable-next-line banned-modules
'use strict';

import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import 'corejs-typeahead/dist/typeahead.jquery';
import $ from 'jquery';

export default Widget.extend({

	template,

	events: {
		'click .b-footer-show-select': 'showSelect',
		'click .js-page-size li': 'handleSelect',
	},

	initialize() {
		this.render();
	},

	applyBinding(model) {
		logger.debug(this.options.bindingProperty, model);
		this.model = model;

		const changeProperty = (thisModel, value) => {
			model.off(`change:${this.options.bindingProperty}`, changeProperty);
			const {depend} = this.options;
			const {changed} = thisModel;

			if (depend && this.model.has(depend) && _.size(changed) === 1 && changed.pageSize != null) {
				const previousPageSize = parseInt(thisModel._previousAttributes.pageSize, 10);
				const pageSize = parseInt(value, 10);

				let page = this.model.get(depend);
				page = Math.floor((previousPageSize * page) / pageSize);

				this.model.set(depend, page, {silent: true});
			}

			model.trigger(`afterchange:${this.options.bindingProperty}`, thisModel, value);
			this.select(value);
		};

		this.listenTo(model, `change:${this.options.bindingProperty}`, changeProperty);
		if (this.model.has(this.options.bindingProperty)) {
			this.select(this.model.get(this.options.bindingProperty));
		} else {
			try {
				const value = this.collection.get(this.ui.input.find('option:selected').attr('value')).attributes;
				this.model.set(this.options.bindingProperty, value);
			} catch (e) {
				logger.debug(e);
			}
		}
	},

	showSelect(e) {
		const $elm = $(e.currentTarget);
		$elm.toggleClass('open');
	},

	select(model) {
		if (model != null) {
			this.$('.js-page-size-value').html(model);
		}
	},

	handleSelect(e) {
		const value = $(e.currentTarget).attr('value');
		this.model.set(this.options.bindingProperty, value);
	},

	blurHandler(e) {
		const $languageContainer = $(e.target).parents('.p-login__header-language:first');
		if ($languageContainer.length === 0) {
			this.$el.removeClass('opened');
			$(document).off('click.p-login__header-language');
		}
	},

});
