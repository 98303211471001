module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-payment__item-in-hotel">\n    <div class="b-payment__item">\n        <div class="b-payment__item-row b-payment__item-row--with-border">\n            <span class="b-payment__item-cell b-payment__item-cell--type-title">\n                ' +
((__t = ( L10N.get('pricing.total') )) == null ? '' : __t) +
'\n            </span>\n            <div class="b-payment__item-cell b-payment__item-cell--width-full">\n                <div class="b-payment__item-border"></div>\n            </div>\n            <span class="b-payment__item-cell b-payment__item-cell--type-amount">\n                ' +
((__t = ( Formatter.formatMoney(price.amount, 0) )) == null ? '' : __t) +
'\n                ' +
((__t = ( Formatter.formatCurrency(price.currencyCode) )) == null ? '' : __t) +
'\n            </span>\n        </div>\n    </div>\n</div>\n';

}
return __p
}