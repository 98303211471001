module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var value = value || ''; ;
__p += '\n<div class="b-transliteration-popup" style="display: none">\n    <div class="arrow"></div>\n    <div class="b-transliteration-popup__content">\n        <div class="b-transliteration-popup__text">\n            ' +
((__t = ( L10N.get('Widgets.popup.changeTo') )) == null ? '' : __t) +
'\n            <span class="b-transliteration-popup__value js-transliteration-value" data-transliterated-value="' +
((__t = ( value )) == null ? '' : __t) +
'">' +
((__t = ( value )) == null ? '' : __t) +
'</span>\n        </div>\n        <span class="b-transliteration-popup__close js-close-popup"></span>\n    </div>\n</div>\n';

}
return __p
}