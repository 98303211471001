module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-train__carriage-map">\n    <div class="b-map-description__messages">\n        <div class="b-message b-message-warning">\n            ' +
((__t = ( L10N.get('trains.noSeatSelectionNotification') )) == null ? '' : __t) +
'\n        </div>\n    </div>\n\n</div>\n';

}
return __p
}