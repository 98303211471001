module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var airlinesList = [];

    _.each(flight.legs, function(l) {
         _. each(l.segments, function(s) {
			 if (s.airline && s.airline.airlineCode && s.airline.codeSystem) {
                 airlinesList.push(s.airline);
             }
        });
    });

	var hasStops = function() {
		return !_.every(flight.legs, function(leg) { 
			if (_.size(leg.segments) === 1) {
				if (leg.segments[0].techStopInfos?.length) {
					return false
				}
				return true
			} else {
				return false
			}; 
		})
	}

    airlinesList = _.uniq(airlinesList, false, function(a) { return a.airlineCode; });
	if (_.isEmpty(airlinesList)) airlinesList.push(flight.carrier);

    var showTravelPolicyBlock = (flight.travelPolicyCompliance != null && !_.isEmpty(flight.travelPolicyCompliance.rulesApplied)) || flight.isTripartiteContractDiscountApplied;
    var showAvailableSeats = flight.availableSeats != null;
    var showExtraInfoBlock = showAvailableSeats || showTravelPolicyBlock;
;
__p += '\r\n<div class="b-flight-ticket__wrapper ' +
((__t = ( flight.selected === true ? 'selected' : '' )) == null ? '' : __t) +
'" data-selected="' +
((__t = ( flight.selected )) == null ? '' : __t) +
'">\r\n	<div class="b-flight-ticket__short-container">\r\n		<div class="b-flight-ticket">\r\n			';
 if (flight.showGdsAccountName) { ;
__p += '\r\n                <div class="b-flight-ticket__gds-info">' +
((__t = ( flight.gds.uid )) == null ? '' : __t) +
' - ' +
((__t = ( flight.gdsAccountName )) == null ? '' : __t) +
'</div>\r\n            ';
 } ;
__p += '\r\n			<div class="b-flight-ticket__mobile-company-logos">\r\n				';
 _.each(airlinesList, function(airline) { ;
__p += '\r\n                    ';
 var companyForIcon = airline.codeSystem !== 'IATA' ? airline.airlineCode + '-' + airline.codeSystem : airline.airlineCode; ;
__p += '\r\n\r\n                    <div class="b-leg__company-logo-container">\r\n					<div class="b-leg__company-logo g-icon" style="background-image: url(\'/assets/img/logos/logos-m/' +
((__t = ( companyForIcon )) == null ? '' : __t) +
'-black.svg\')" data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( _.escape(airline.caption) )) == null ? '' : __t) +
'"></div>\r\n				</div>\r\n				';
 }); ;
__p += '\r\n			</div>\r\n			<div class="b-flight-ticket__content-container">\r\n				<div class="b-flight-ticket__legs">\r\n					' +
((__t = ( require('./b-ticket-legs/b-ticket-legs.ejs')({
						flight: flight,
						flightToken: flightToken
					}) )) == null ? '' : __t) +
'\r\n				</div>\r\n                ';
 if (showExtraInfoBlock) { ;
__p += '\r\n                    <div class="b-flight-ticket__extra-info-container">\r\n                        ';
 if (showAvailableSeats) { ;
__p += '\r\n                            ' +
((__t = ( require('./b-ticket-available-seats.ejs')({
                                availableSeats: flight.availableSeats,
                            }) )) == null ? '' : __t) +
'\r\n                        ';
 } ;
__p += '\r\n                        ';
 if (showTravelPolicyBlock) { ;
__p += '\r\n                            <div class="b-flight-ticket__travel-policy b-travel-policy">\r\n                                ' +
((__t = ( require('./b-travel-policy.ejs')({
                                    flight: flight,
                                }) )) == null ? '' : __t) +
'\r\n                            </div>\r\n                        ';
 } ;
__p += '\r\n                    </div>\r\n                ';
 } ;
__p += '\r\n				<div class="b-flight-ticket__price ' +
((__t = ( flight.isMultiTicket ? 'b-flight-ticket__price--multiticket' : '' )) == null ? '' : __t) +
' b-flight-price">\r\n                    ';
 if (flight.isMultiTicket) { ;
__p += '\r\n                        <div\r\n                            data-toggle="tooltip"\r\n                            data-placement="top"\r\n                            data-html="true"\r\n                            data-original-title="<div>' +
((__t = ( L10N.get('multiticket_hint') )) == null ? '' : __t) +
'</div>"\r\n                            class="mobile-hide"\r\n                            style="position: absolute;\r\n                            top: -24px;\r\n                            background: #4A5767;\r\n                            color: white;\r\n                            width: 50%;\r\n                            left: 40px;\r\n                            margin: 0 auto;\r\n                            border-radius: 3px;"\r\n                        >\r\n                            Multi-Ticket\r\n                        </div>\r\n                    ';
 } ;
__p += '\r\n                    ';
 var priceString = Formatter.formatMoney(flight.price.total.amount, 0) + ' ' + Formatter.formatCurrency(flight.price.total.currencyCode) ;
__p += '\r\n                    ';
 if (this.isExchange) { ;
__p += '\r\n                        <div class="b-flight-price__top">\r\n                            ' +
((__t = ( L10N.get('avia.exchangeFee') )) == null ? '' : __t) +
'\r\n                            <div class="btn btn-dark b-flight-price__button js-exchange mobile-hide" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="' +
((__t = ( _.escape(require('./b-ticket-pricing.ejs')({ price: flight.price })) )) == null ? '' : __t) +
'">\r\n                                ' +
((__t = ( priceString )) == null ? '' : __t) +
'\r\n                            </div>\r\n                            <div class="btn btn-dark b-flight-price__button js-exchange desktop-hide">' +
((__t = ( priceString )) == null ? '' : __t) +
'</div>\r\n                        </div>\r\n                    ';
 } ;
__p += '\r\n					';
 if (this.issueAllowed) { ;
__p += '\r\n						<div class="b-flight-price__top">\r\n							<div class="btn btn-dark b-flight-price__button mobile-hide" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="' +
((__t = ( _.escape(require('./b-ticket-pricing.ejs')({ price: flight.price })) )) == null ? '' : __t) +
'">\r\n								' +
((__t = ( priceString )) == null ? '' : __t) +
'\r\n							</div>\r\n                            <div class="btn btn-dark b-flight-price__button desktop-hide">' +
((__t = ( priceString )) == null ? '' : __t) +
'</div>\r\n						</div>\r\n					';
 } ;
__p += '\r\n					';
 if (this.approvalAllowed) { ;
__p += '\r\n						<div class="b-flight-price__top">\r\n							';
 if (!this.issueAllowed) { ;
__p += '\r\n								<div class="b-flight-price__offer-total mobile-hide" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="' +
((__t = ( _.escape(require('./b-ticket-pricing.ejs')({ price: flight.price })) )) == null ? '' : __t) +
'">\r\n									' +
((__t = ( Formatter.formatMoney(flight.price.total.amount, 0) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(flight.price.total.currencyCode) )) == null ? '' : __t) +
'\r\n								</div>\r\n                                <div class="b-flight-price__offer-total desktop-hide">' +
((__t = ( priceString )) == null ? '' : __t) +
'</div>\r\n							';
 } ;
__p += '\r\n							';
 if (STATE.checkSiteType('B2B')) { ;
__p += '\r\n								<div class="btn btn-dark b-flight-price__button--offer">' +
((__t = ( L10N.get('ticket.addToCoordination') )) == null ? '' : __t) +
'</div>\r\n							';
 } ;
__p += '\r\n						</div>\r\n					';
 } ;
__p += '\r\n\r\n					<div class="b-flight-price__bottom">\r\n            ';
 if (flight.hideOtherFares !== true) { ;
__p += '\r\n              <a class="action-link b-flight-price__branded-fares">' +
((__t = ( L10N.get("flightlegs.selectBrandedFares") )) == null ? '' : __t) +
'</a>\r\n            ';
 } ;
__p += '\r\n					</div>\r\n                    ';
 if (!_.isEmpty(flight.vendorCommissionsData)) { ;
__p += '\r\n                        <div class="b-flight-price__commission-section mobile-hide">\r\n                            ';
_.each(_.keys(flight.vendorCommissionsData), function(key) {
                                const passengerTypeDataList = flight.vendorCommissionsData[key];
                                _.each(passengerTypeDataList, function(item) {
									const value = !_.isEmpty(item.amount) ? item.amount.amount + Formatter.formatCurrency(item.amount.currencyCode) : item.rate + '%';
									const commissionRowContent = L10N.get('pricing.commission') + ' ' + key + ': ' + value + ' (' + item.ruleNumber + ') ';
                            ;
__p += '\r\n                                    <div class="b-flight-price__commission-row">\r\n                                        <span>' +
((__t = ( commissionRowContent )) == null ? '' : __t) +
'</span>\r\n                                        <i class="g-icon-attention" data-toggle="tooltip" data-html="true" data-position="bottom" data-original-title="<div>' +
((__t = ( _.escape(item.contractCaption) )) == null ? '' : __t) +
'</div>"></i>\r\n                                    </div>\r\n                                ';
 });
                            });
__p += '\r\n                        </div>\r\n                    ';
 } ;
__p += '\r\n				</div>\r\n				';
 if (hasStops) { ;
__p += '\r\n					<div class="b-flight-ticket__expand b-expand collapsed"></div>\r\n				';
 } ;
__p += '\r\n			</div>\r\n		</div>\r\n	</div>\r\n	';
 if (hasStops) { ;
__p += '\r\n		<div class="b-flight-ticket__expand-container">\r\n			<div class="b-flight-ticket__expand-container-inner">\r\n				' +
((__t = ( require('./b-ticket-route.ejs')({
					flight: flight,
					flightToken: flightToken,
					withServices: false,
					isOnline: true,
				}) )) == null ? '' : __t) +
'\r\n			</div>\r\n			<div class="b-flight-ticket__expand b-expand"></div>\r\n		</div>\r\n	';
 } ;
__p += '\r\n	<div class="b-flight-ticket__branded-fares"></div>\r\n	<div class="b-flight-ticket__standart-fares"></div>\r\n</div>\r\n';

}
return __p
}