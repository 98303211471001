'use strict';

import './style.less';
import template from './template.ejs';
import BaseView from '@/classes/base.view';

export default BaseView.extend({

	template,

	ui: {
		resultsContainer: '.b-transfers__results',
	},

	events: {
		'click .b-transfer__select-button': 'selectOffer',
		'click .b-transfer__select-button--offer': 'addOffer',

	},

	initialize(options) {
		this.options = options;
		this.transfer = this.options.transfer;
		this.model = this.options.model;
		this.parent = this.options.parent;
		this.pageView = this.options.pageView;
		this.searchModel = this.options.searchModel;
		this.model.set('transferType', this.searchModel.get('transferType'));
	},

	render(args) {
		BaseView.prototype.render.apply(this, args);
		if (this.model.get('isExceededCapacity') || this.transfer.capacity < this.model.get('travellersCount')) {
			this.$el.addClass('b-transfer__unavailable');
		}

		return this;
	},

	selectOffer(e) {
		if (e) {
			e.preventDefault();
		}
		const {transfer, capacity, travellersCount} = this.model.toJSON();
		const {token, carClass, flexibleTariff, flexibleTariffAgreement} = transfer || {};
		const searchParams = this.searchModel.toJSON().parameters;
		const passengersTypes = this.searchModel.get('passengersTypes').toJSON();

		const tripInfo = {
			startPlace: searchParams.startPlace,
			date: searchParams.date,
			backDate: searchParams.backDate,
			endPlace: searchParams.endPlace || {},
			subAddresses: searchParams.subAddresses || [],
			transferType: searchParams.transferType,
			passengersTypes,
			capacity,
			carClass,
			travellersCount,
			token,
			flexibleTariff,
			flexibleTariffAgreement,
		};

		STORE.remove(STATE.ROUTES.TRANSFERS_PASSENGERS);
		STORE.set(STATE.ROUTES.TRANSFERS_PASSENGERS, {
			token,
			tripInfo,
		});
		STATE.navigate(STATE.ROUTES.TRANSFERS_PASSENGERS);
	},

	addEventListeners() {
		this.listenTo(this.model, 'visibilityChange', (model, value) => {
			model.set('visibility', value);
			if (value === true) {
				this.$el.addClass('b-transfer__visible');
			} else {
				this.$el.removeClass('b-transfer__visible');
			}
		});
	},

	addOffer(e) {
		if (!e) return;
		e.preventDefault();
		this.pageView.addOffer.call(this.pageView, e, {
			transfer: this.options.transfer,
			transferToken: this.model.get('transfer.token'),
		});
	},
});
