module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="l-grid-layout-50">\n	<div class="b-company-employees__retail">\n		';
 if (this.model.get('serviceTypes').find({uid: 'AVIA'})) { ;
__p += '\n			<span class="js-widget"  onclick=\'return new Widgets.PassengersAvia({ bindingProperty: "passengersTypes", classes: "b-passengers-complex b-passengers-avia" })\'></span>\n		';
 } else if (this.model.get('serviceTypes').find({uid: 'RAIL'})) { ;
__p += '\n			<span class="js-widget"  onclick=\'return new Widgets.PassengersRail({ bindingProperty: "passengersTypes", classes: "b-passengers-complex b-passengers-rail" })\'></span>\n		';
 } else if (this.model.get('serviceTypes').find({uid: 'HOTEL'})) { ;
__p += '\n			<span class="js-widget"  onclick=\'return new Widgets.PassengersHotel({ bindingProperty: "passengersTypes", classes: "b-passengers-complex b-passengers-hotels" })\'></span>\n			<span class="js-widget"  onclick=\'return new Widgets.NumerablePicker({ bindingProperty: "roomsCount", classes: "b-numerable-picker-hotels", plurarForm: "searchForm.rooms", default: 1, min: 1, max: 8 })\'></span>\n		';
 } else if (this.model.get('serviceTypes').find({uid: 'TRANSFER'})) { ;
__p += '\n			<span class="js-widget"  onclick=\'return new Widgets.PassengersTransfer({ bindingProperty: "passengersTypes", classes: "b-passengers-complex b-passengers-transfer" })\'></span>\n		';
 } ;
__p += '\n	</div>\n</div>\n';

}
return __p
}