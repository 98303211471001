// eslint-disable-next-line banned-modules
'use strict';

import BaseView from '@/classes/base.view';
import BaseModel from '@/classes/base.model';
import BaseCollection from '@/classes/base.collection';
import SelectWidget from '@/widgets/b-select';
import template from './template.ejs';
import './style.less';

import axios from 'axios';

export default BaseView.extend({

	template,

	events: {
		'click .b-person-organization__remove': 'remove',
	},

	ui: {
		categoriesContainer: '.b-person-organization__categories',
		mainEmploymentContainer: '.b-person-organization__main-employment',
	},

	employerSettings: null,

	initialize(options) {
		this.options = options;
		this.isNew = options.isNew;
		this.render();

		this.listenTo(this.model, 'change:organization', this.getEmployeeFormData.bind(this));
		this.listenTo(this.model.get('costCodesInfo'), 'change', () => {
			const organization = this.model.get('organization');
			this.getEmployeeFormData(this.model, organization);
		});

		this.listenTo(this.model, 'change:mainEmployment', (model, value, init) => {
			if ([true, 'true'].includes(value) && this.model.collection instanceof BaseCollection) {
				if (_.isObject(init) && !init.silent) {
					this.model.collection.each((item) => {
						if (item !== model) {
							item.set('mainEmployment', false);
						}
					});
				}
				this.model.collection.trigger('changeMainEmployment', model, _.extend(this.employerSettings, {
					role: model.get('role'),
				}));
			}
		});

		if (this.model.get('organization') != null) {
			const noSettings = this.model.get('employerSettings') == null && !this.hasEmployerSettings(this.model);
			const isRoles = this.model.get('roles') && this.model.get('roles').models.length > 0;

			const updateModels = (result, employerSettings, hasEmployerSettings = null) => {
				if (!result) return;
				const {employeeCategoryAccess, hideSystemAccess} = result.data.result;
				this.employerSettings = employerSettings;
				this.employerSettings.employeeCategoryAccess = employeeCategoryAccess;
				this.employerSettings.hideSystemAccess = hideSystemAccess;

				if (hasEmployerSettings != null && hasEmployerSettings === false) return;
				this.model.set('employerSettings', this.employerSettings);
				this.model.trigger('change:mainEmployment', this.model, this.model.get('mainEmployment'), {silent: true});
				this.model.collection.trigger('getEmployeeFormData', this.model, this.model.collection);
				this.model.collection.trigger('setSystemAccessData', this.model, this.model.collection);
				this.model.collection.trigger('setEmployeeCategoryAccess', this.model, this.model.collection);
			};

			const updateData = (parameters) => {
				return axios.post('/midoffice/ibecorp-b2b/cabinet/getEmployeeFormData', parameters);
			};

			if (noSettings) {
				if (!isRoles) {
					// For new employee
					this.getEmployeeFormData(this.model, this.model.get('organization'), () => {
						this.model.trigger('change:mainEmployment', this.model, this.model.get('mainEmployment'), {silent: true});
					});
				} else {
					// For employee with role and with "access to system" block
					const employerSettings = {organizationInfo: this.model.toJSON()};
					const parameters = {
						parameters: {
							organization: employerSettings.organizationInfo.organization || null,
						},
					};

					updateData(parameters).then((result) => {
						updateModels(result, employerSettings);
						this.selectEmployer(this.employerSettings);
						if (this.model.get('aclEditGranted') == null ||
							(this.model.get('aclEditGranted') != null && this.model.get('aclEditGranted') === true)) {
							this.ui.mainEmploymentContainer.find('.b-checkbox__input').removeAttr('disabled');
						}
					});
				}
			} else {
				// For employee without "access to system" block
				const hasEmployerSettings = this.hasEmployerSettings(this.model);
				const employerSettings = hasEmployerSettings ? {organizationInfo: this.model.toJSON()} : this.model.get('employerSettings');

				const parameters = {
					parameters: {
						organization: (hasEmployerSettings ? employerSettings.organizationInfo.organization : employerSettings.organization) || null,
					},
				};

				updateData(parameters).then((result) => {
					updateModels(result, employerSettings, hasEmployerSettings);
					this.selectEmployer(this.employerSettings);
					if (this.model.get('aclEditGranted') == null ||
						(this.model.get('aclEditGranted') != null && this.model.get('aclEditGranted') === true)) {
						this.ui.mainEmploymentContainer.find('.b-checkbox__input').removeAttr('disabled');
					}
				});
			}
		} else {
			this.getEmployeeFormData();
		}
	},

	getEmployeeFormData(model, value, callback) {
		const jmodel = model && model.toJSON() || {};
		const parameters = {
			parameters: {
				organization: value || null,
			},
		};

		if (!_.isEmpty(jmodel.costCodesInfo)) {
			parameters.parameters.personCostCodes = jmodel.costCodesInfo;
		}

		axios.post('/midoffice/ibecorp-b2b/cabinet/getEmployeeFormData', parameters).then((result) => {
			this.employerSettings = result.data.result;
			this.model.set('employerSettings', this.employerSettings);

			if (this.employerSettings.organizationInfo &&
				this.employerSettings.organizationInfo.employeeCategoryInfos != null &&
				this.employerSettings.organizationInfo.roles != null) {
				this.selectEmployer(this.employerSettings);
				if (this.model.get('aclEditGranted') != null && this.model.get('aclEditGranted') === true) {
					this.ui.mainEmploymentContainer.find('.b-checkbox__input').removeAttr('disabled');
				}
				this.model.collection.trigger('getEmployeeFormData', this.model, this.model.collection);
				this.model.collection.trigger('setEmployeeCategoryAccess', this.model, this.model.collection);
				this.model.trigger('change:mainEmployment', this.model, this.model.get('mainEmployment'), {silent: true});
			}

			this.model.collection.trigger('setSystemAccessData', this.model, this.model.collection);

			if (_.isFunction(callback)) {
				callback();
			}
		});

		return this;
	},

	selectEmployer(employer) {
		const {employeeCategoryInfos, roles} = employer.organizationInfo;
		const {employeeCategoryAccess} = employer;

		this.model.set('employeeCategoryInfos', new BaseCollection(_.map(employeeCategoryInfos, (category) => new BaseModel(category)), {
			model: BaseModel,
		}));
		this.model.set('roles', new BaseCollection(_.map(roles, (role) => new BaseModel(role)), {
			model: BaseModel,
		}));

		const widget = new SelectWidget({
			bindingProperty: 'category',
			label: L10N.get('cabinet.person.employeeCategory'),
			disabled: !this.isNew && !employeeCategoryAccess,
			values: SelectWidget.storeCollection(
				SelectWidget.dictionaryToCollection(_.map(employeeCategoryInfos, (item) => ({
					caption: item.name,
					uid: item.uid,
				}))),
			),
		});

		const selectedCategory = _.find(employeeCategoryInfos, (el) => el.uid === (this.model.get('employeeCategoryInfo') || {}).uid);
		const selectedRole = _.find(roles, (el) => el.uid === (this.model.get('role') || {}).uid);

		if (selectedCategory != null) {
			this.model.set('category', {
				uid: selectedCategory.uid,
				caption: selectedCategory.name,
			});
		}
		this.model.set('role', selectedRole);

		if (_.isEmpty(employeeCategoryInfos)) {
			this.$('.b-person-organization__categories-wrapper').hide();
		} else {
			this.$('.b-person-organization__categories-wrapper').show();
		}

		widget.applyBinding(this.model);
		this.ui.categoriesContainer.html(widget.$el);
	},

	hasEmployerSettings(model) {
		const data = model.toJSON();
		return data.roles != null && data.costCodesInfo != null && !_.isEmpty(data.employeeCategoryInfos);
	},

	remove() {
		this.model.destroy();
	},

	/*eslint-disable */
	render() {
		this.setElement(this.template(_.extend({},
			this.model.attributes,
			this.options,
			{
				cid: this.model.cid,
				// "this" - is widget context, underscore & ES6 not supported ejs-loader
				companyFormatter: function (result) {
					if (!Array.isArray(result)) {
						return result;
					}

					const companyList = this.model && this.model.collection && this.model.collection.toJSON()
						.map((model) => model.organization != null ? model.organization.uid : null);

					return result.filter((el) => el != null && companyList && !companyList.includes(el.uid));
				},
			}),
		));

		if (this.model.get('organization') == null) {
			this.selectEmployer({
				organizationInfo: {
					employeeCategoryInfos: [],
					roles: [],
				},
			});
		}
		if (this.model.collection.length === 1) {
			this.$el.find('.b-person-organization__remove').hide();
		}

	},

});
