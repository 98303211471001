// eslint-disable-next-line banned-modules
'use strict';

/**
 * params:
 * @param langRegistrationFullNameUid string | undefined 
 * @param forbidTravellerUpdate boolean
 */

export function getAutoCorrectionWidgetsLabels({
	langRegistrationFullNameUid,
	forbidTravellerUpdate,
}) {
	let l10nLastNamePlaceholder = '';
	let l10nFirstNamePlaceholder = '';
	let l10nMiddleNamePlaceholder = '';
	let l10nSecondNamePlaceholder = '';

	switch (langRegistrationFullNameUid) {
		case 'LATIN':
			l10nLastNamePlaceholder = L10N.get('bookingForm.lastNamePlaceholder');
			l10nFirstNamePlaceholder = L10N.get('bookingForm.firstNamePlaceholder');
			l10nMiddleNamePlaceholder = L10N.get(
				'bookingForm.middleNamePlaceholder',
			);
			l10nSecondNamePlaceholder = L10N.get('bookingForm.secondNamePlaceholder');
			break;
		case 'LATIN_AND_CYRILLIC':
			l10nLastNamePlaceholder = L10N.get(
				'bookingForm.lastNameLatinAndCyrillicPlaceholder',
			);
			l10nFirstNamePlaceholder = L10N.get(
				'bookingForm.firstNameLatinAndCyrillicPlaceholder',
			);
			l10nMiddleNamePlaceholder = L10N.get(
				'bookingForm.middleNameLatinAndCyrillicPlaceholder',
			);
			l10nSecondNamePlaceholder = L10N.get('bookingForm.secondNameLatinAndCyrillicPlaceholder');
			break;
		case 'CYRILLIC':
			l10nLastNamePlaceholder = L10N.get(
				'bookingForm.lastNameCyrillicPlaceholder',
			);
			l10nFirstNamePlaceholder = L10N.get(
				'bookingForm.firstNameCyrillicPlaceholder',
			);
			l10nMiddleNamePlaceholder = L10N.get(
				'bookingForm.middleNameCyrillicPlaceholder',
			);
			l10nSecondNamePlaceholder = L10N.get('bookingForm.secondNameCyrillicPlaceholder');
			break;
	}

	if (forbidTravellerUpdate) l10nMiddleNamePlaceholder = '';

	return {
		l10nLastNamePlaceholder,
		l10nFirstNamePlaceholder,
		l10nMiddleNamePlaceholder,
		l10nSecondNamePlaceholder,
	};
}
