module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var caption = caption || '' ;
__p += '\n<div class="b-company-autocomplete__item ' +
((__t = ( caption && caption.length ? '' : 'dn' )) == null ? '' : __t) +
'"\n';
 if (caption && caption.length >= 32) { ;
__p += '\n     data-toggle="tooltip" data-placement="bottom" data-original-title="' +
((__t = ( caption )) == null ? '' : __t) +
'"\n        ';
 } ;
__p += '\n>\n    <span class="b-company-autocomplete__item-caption">' +
((__t = ( caption )) == null ? '' : __t) +
'</span>\n</div>\n';

}
return __p
}