module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (!_.isArray(price)) {
    price = [price];
} ;
__p += '\n\n<div class="b-ticket-pricing no-max-width">\n    ';
 _.each(price, function (p, i) { ;
__p += '\n        <div class="b-ticket-pricing__item">\n            ';
 if (_.size(price) > 1) { ;
__p += '\n                <div class="b-ticket-pricing__direction">\n                    <span>' +
((__t = ( (i === 0) ? L10N.get("trains.there") : L10N.get("trains.backward") )) == null ? '' : __t) +
'</span>\n                </div>\n            ';
 } ;
__p += '\n\n            ';
 if (!_.isEmpty(p.rates) && _.isArray(p.rates)) { ;
__p += '\n                <div class="b-ticket-pricing__header">\n                    ';
 _.each(p.rates, function(rate) { ;
__p += '\n                        <span>' +
((__t = ( rate.total.amount + ' ' )) == null ? '' : __t) +
'  <span class="currency">  ' +
((__t = ( rate.symbol )) == null ? '' : __t) +
'</span></span>\n                    ';
 }); ;
__p += '\n                </div>\n            ';
 } ;
__p += '\n\n            <div class="b-ticket-pricing__content">\n                ';

                    var types = ['tariff', 'fee', 'taxes', 'techFees', 'supplierFees', 'agencyFees', 'feeAndTaxes', 'penalty', 'discount'];

                    p.passengerPrices = _.toArray(p.passengerPrices).sort(function(a,b) {return a.passengerType.uid > b.passengerType.uid});

                _.each(p.passengerPrices, function(value) {
                    var passengerPrice = _.extend({}, value, value.feeDetails);
                    var ticketPrice = 0; ;
__p += '\n\n                <div class="b-ticket-pricing__section">\n						<span class="b-ticket-pricing__section-title">\n                            ';
 if (passengerPrice && passengerPrice.passengerType) { ;
__p += '\n                                ' +
((__t = ( passengerPrice.passengerType.caption )) == null ? '' : __t) +
'\n                                ';
 if (passengerPrice.passengerCount > 1) { ;
__p += 'x' +
((__t = ( passengerPrice.passengerCount )) == null ? '' : __t);
 } ;
__p += '\n                            ';
 } ;
__p += '\n                                <span class="js-copy-content-tooltip copy-content">' +
((__t = ( passengerPrice.passengerType && passengerPrice.passengerType.caption )) == null ? '' : __t) +
' ';
 if (passengerPrice.passengerCount > 1) { ;
__p += 'x' +
((__t = ( passengerPrice.passengerCount )) == null ? '' : __t);
 } ;
__p += '/</span>\n                        </span>\n                    ';
 _.each(types, function (type) {
                        var priceValue = passengerPrice[type];
                    if (priceValue != null && parseFloat(priceValue.amount) !== 0) {
                        ticketPrice += priceValue.amount; ;
__p += '\n                    <div class="b-ticket-pricing__section-info">\n                        <span class="b-ticket-pricing__section-passenger">' +
((__t = ( L10N.get("pricing." + type) )) == null ? '' : __t) +
'</span>\n                        <span class="b-ticket-pricing__section-price">\n                            ' +
((__t = ( Formatter.formatMoney(priceValue.amount, Formatter.getDecimal(priceValue.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(priceValue.currencyCode) )) == null ? '' : __t) +
'\n                        </span>\n                        <span class="js-copy-content-tooltip copy-content">' +
((__t = ( L10N.get("pricing." + type) )) == null ? '' : __t) +
': ' +
((__t = ( Formatter.formatMoney(priceValue.amount, 2) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(priceValue.currencyCode) )) == null ? '' : __t) +
'/</span>\n                    </div>\n                    ';
 } ;
__p += '\n                    ';
 }); ;
__p += '\n                    ';
 if (!_.isEmpty(passengerPrice.ancillaryFees)) { ;
__p += '\n						<span class="b-ticket-pricing__section-title">\n							' +
((__t = ( L10N.get('hotels.additionalServiceShort') )) == null ? '' : __t) +
'\n						</span>\n                        <span class="js-copy-content-tooltip copy-content">' +
((__t = ( L10N.get('hotels.additionalServiceShort') )) == null ? '' : __t) +
':/</span>\n                        ';
 _.each(passengerPrice.ancillaryFees, function (item) { ;
__p += '\n                            <div class="b-ticket-pricing__section-info">\n                                <span class="b-ticket-pricing__section-passenger">' +
((__t = ( item.caption )) == null ? '' : __t) +
'</span>\n                                <span class="b-ticket-pricing__section-price">\n                                    ' +
((__t = ( item.number + 'x' )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatMoney(item.price.amount, Formatter.getDecimal(item.price.amount)) )) == null ? '' : __t) +
'\n                                    ' +
((__t = ( Formatter.formatCurrency(item.price.currencyCode) )) == null ? '' : __t) +
'\n                                </span>\n                            </div>\n                            <span class="js-copy-content-tooltip copy-content">' +
((__t = ( item.caption )) == null ? '' : __t) +
': ' +
((__t = ( item.number + 'x' )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatMoney(item.price.amount, Formatter.getDecimal(item.price.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(item.price.currencyCode) )) == null ? '' : __t) +
'/</span>\n                        ';
 }) ;
__p += '\n                    ';
 } ;
__p += '\n                </div>\n                ';
 }); ;
__p += '\n\n            </div>\n\n            ';
 if (_.size(price) > 1 && p.total != null) { ;
__p += '\n                <div class="b-pricing__total-section clearfix">\n                    <span class="b-pricing__section-total-title">' +
((__t = ( L10N.get("pricing.total") )) == null ? '' : __t) +
':</span>\n                    <span class="b-pricing__section-total-price">\n                        ';
 if (p.isMinPriceRangeComponent === true) { ;
__p +=
((__t = ( L10N.get('pricing.from') )) == null ? '' : __t) +
' ';
 } ;
__p += '\n                        ' +
((__t = ( Formatter.formatMoney(p.total.amount, Formatter.getDecimal(p.total.amount)))) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(p.total.currencyCode) )) == null ? '' : __t) +
'\n					</span>\n                </div>\n                    <span class="js-copy-content-tooltip copy-content">' +
((__t = ( L10N.get("pricing.total") )) == null ? '' : __t) +
': ';
 if (p.isMinPriceRangeComponent === true) { ;
__p +=
((__t = ( L10N.get('pricing.from') )) == null ? '' : __t) +
' ';
 } ;
__p += ' ' +
((__t = ( Formatter.formatMoney(p.total.amount, Formatter.getDecimal(p.total.amount)))) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(p.total.currencyCode) )) == null ? '' : __t) +
'/</span>\n            ';
 } ;
__p += '\n        </div>\n    ';
 }); ;
__p += '\n</div>\n';

}
return __p
}