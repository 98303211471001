module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var transfer = reservation || {};
   var passengersCount = (transfer.services[0] || {}).passengersCount || 1;
   var passengersCountText = L10N.getPluralForm('searchForm.passenger', passengersCount);
;
__p += '\n\n<div class="b-transfer-order-info">\n    <div class="b-transfer-order-info__wrapper">\n        <div class="b-transfer-order-info__car-info">\n            <div class="b-transfer-order-info__car-info-photo-container">\n                <div class="b-transfer__info-photo" style="background-image: url(\'' +
((__t = ( transfer.car && transfer.car.imageUrl )) == null ? '' : __t) +
'\')"></div>\n            </div>\n            <span class="js-copy-content copy-content" data-replace-newline=" ">' +
((__t = ( L10N.get('transfers.tripDate') )) == null ? '' : __t) +
': <span data-copy-content-from=".b-transfer-order-info__trip-info-time"></span></span>\n            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('transfers.carClass') )) == null ? '' : __t) +
': <span data-copy-content-from=".b-transfer-order-info__car-info-class"></span></span>\n            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('transfers.capacityTitle') )) == null ? '' : __t) +
': <span data-copy-content-from=".b-transfer-order-info__car-info__passengers-text"></span></span>\n            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('transfers.startPlace') )) == null ? '' : __t) +
':' +
((__t = ( transfer.startAddress )) == null ? '' : __t) +
'</span>\n            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('transfers.endPlace') )) == null ? '' : __t) +
':' +
((__t = ( transfer.endAddress )) == null ? '' : __t) +
'</span>\n            <div class="b-transfer-order-info__car-info-element">\n                <div class="b-transfer-order-info__row b-transfer-order-info__car-info-class">' +
((__t = ( transfer.car && transfer.car.carClass && transfer.car.carClass.caption || '' )) == null ? '' : __t) +
'</div>\n                <div class="b-transfer-order-info__row">\n                    <div class="b-transfer-order-info__car-info__passengers-icon"></div>\n                    <span class="b-transfer-order-info__car-info__passengers-text">' +
((__t = ( passengersCount )) == null ? '' : __t) +
' ' +
((__t = ( passengersCountText )) == null ? '' : __t) +
'</span>\n                </div>\n            </div>\n        </div>\n        <div class="b-transfer-order-info__trip-info">\n            <div class="b-transfer-order-info__trip-info-element">\n                <div class="b-transfer-order-info__row b-transfer-order-info__trip-info-time">\n                    ' +
((__t = ( new Time(transfer.pickUpTime).toShortDate() )) == null ? '' : __t) +
', ' +
((__t = ( new Time(transfer.pickUpTime).toShortTime() )) == null ? '' : __t) +
'\n                </div>\n                <div class="b-transfer-order-info__row b-transfer-order-info__trip-info-route">\n                    <span>' +
((__t = ( L10N.get('transfers.startPlace') )) == null ? '' : __t) +
':</span>' +
((__t = ( transfer.startAddress )) == null ? '' : __t) +
'\n                </div>\n                <div class="b-transfer-order-info__row b-transfer-order-info__trip-info-route">\n                    <span>' +
((__t = ( L10N.get('transfers.endPlace') )) == null ? '' : __t) +
':</span>' +
((__t = ( transfer.endAddress )) == null ? '' : __t) +
'\n                </div>\n                ';
 if (!_.isEmpty(transfer.subAddresses)) { ;
__p += '\n                    <div class="b-transfer-order-info__row b-transfer-order-info__trip-sub-addresses">\n                        <div>' +
((__t = ( L10N.get('transfers.subAddresses') )) == null ? '' : __t) +
':</div>\n                        ';
 const subAddresses = _.map(transfer.subAddresses, function (route) {
                            return '<div class="overflow-field">' + route + '</div>';
                        }).join('') ;
__p += '\n                        ' +
((__t = ( subAddresses )) == null ? '' : __t) +
'\n                        <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('transfers.subAddresses') )) == null ? '' : __t) +
': &#10;&#13;' +
((__t = ( subAddresses )) == null ? '' : __t) +
'</span>\n\n                    </div>\n                ';
 } ;
__p += '\n            </div>\n            <div class="b-transfer-order-info__trip-info-element">\n\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
}