module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-passengers  ' +
((__t = ( classes )) == null ? '' : __t) +
'">\r\n	<div class="b-passengers__wrapper">\r\n		<span class="b-passengers__input"></span>\r\n		<div class="b-passengers__select">\r\n			<div class="b-passengers__select-img"></div>\r\n		</div>\r\n	</div>\r\n	<div class="b-passengers__popup">\r\n		<ul class="b-passengers__list">\r\n			';
 _.each(types, function (type) { ;
__p += '\r\n				<li class="b-passengers__item" data-type="' +
((__t = ( type.uid )) == null ? '' : __t) +
'">\r\n					' +
((__t = ( type.caption )) == null ? '' : __t) +
'\r\n					<span class="b-passengers__item-minus">&#8211;</span>\r\n					<span class="b-passengers__item-value"></span>\r\n					<span class="b-passengers__item-plus">+</span>\r\n				</li>\r\n			';
 }); ;
__p += '\r\n		</ul>\r\n	</div>\r\n</div>\r\n';

}
return __p
}