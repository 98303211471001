// eslint-disable-next-line banned-modules
'use strict';

import BaseCollection from '@/classes/base.collection';
import ToggleModel from '@/blocks/elements/b-filters/widgets/toggle/model';
import RangeModel from '@/blocks/elements/b-filters/widgets/range/model';
import SelectModel from '@/blocks/elements/b-filters/widgets/select/model';
import InputModel from '@/blocks/elements/b-filters/widgets/input/model';

const timeFormatter = (value) => {
	const minutes = Math.round(value % 60);
	return `${Math.floor(value / 60)}:${minutes > 9 ? minutes : `0${minutes}`}`;
};

export default BaseCollection.extend({

	snapshotName: 'avia:filter',

	initialize(models, options) {
		this.applyFilters = this.filterTickets;
		this.tickets = options.tickets;
		if (this.tickets.length === 0) return;
		this.legsCount = this.tickets[0] ? this.tickets[0].get('flight.legs').length : 1;
		let i = 0;

		if (STATE.checkSiteType('B2B')) {
			this.add(new ToggleModel({
				label: L10N.get('filters.tripartite'),
				field: 'flight.isTripartiteContractDiscountApplied',
				classes: '',
			}));
		}

		const siteSettings = STATE.getSettings();
		
		if (siteSettings?.secondStepSettings?.filterExchangeableEnabled) {
			this.add(new ToggleModel({
				label: L10N.get('filters.exchange'),
				field: 'exchange',
				classes: '',
			}));
		}
		if (siteSettings?.secondStepSettings?.filterRefundableEnabled) {
			this.add(new ToggleModel({
				label: L10N.get('filters.refund'),
				field: 'refund',
				classes: '',
			}));
		}

		this.add(new RangeModel({
			label: L10N.get('filters.price'),
			field: 'flight.price.total.amount',
			common: true,
			formatter: (value) => {
				return Math.round(value);
			},
		}));
		if (this.legsCount < 2) {
			this.add(new InputModel({
				placeholder: L10N.get('filters.flightNumber'),
				field: 'flightNumber',
				common: true,
			}));
		}
		for (i = 0; i < this.legsCount; i++) {
			if (this.legsCount >= 2) {
				this.add(new InputModel({
					placeholder: L10N.get('filters.flightNumber'),
					field: `flightNumber${i}`,
					leg: i,
					legsCount: this.legsCount,
				}));
			}
			this.add(new RangeModel({
				label: L10N.get('filters.travelTime'),
				field: `travelTime[${i}]`,
				formatter: timeFormatter,
				leg: i,
				legsCount: this.legsCount,
			}));
			this.add(new RangeModel({
				label: L10N.get('filters.departureDate'),
				field: `departureDate[${i}]`,
				formatter: timeFormatter,
				leg: i,
				legsCount: this.legsCount,
			}));
			this.add(new RangeModel({
				label: L10N.get('filters.arrivalDate'),
				field: `arrivalDate[${i}]`,
				formatter: timeFormatter,
				leg: i,
				legsCount: this.legsCount,
			}));
			this.add(new SelectModel({
				label: L10N.get('filters.departureAirport'),
				field: `departureAirport[${i}]`,
				leg: i,
				legsCount: this.legsCount,
			}));
			this.add(new SelectModel({
				label: L10N.get('filters.arrivalAirport'),
				field: `arrivalAirport[${i}]`,
				leg: i,
				legsCount: this.legsCount,
			}));
			this.add(new SelectModel({
				label: L10N.get('filters.transfersTitle'),
				field: `transfers[${i}]`,
				leg: i,
				legsCount: this.legsCount,
			}));
		}
		this.add(new SelectModel({
			label: L10N.get('filters.airlines'),
			field: 'flight.carrier',
		}));
		this.add(new SelectModel({
			label: L10N.get('filters.airlinesAlliance'),
			field: 'flight.airlineAlliance',
		}));

		const travelPolicyComplianceFilter = new SelectModel({
			label: L10N.get('filters.travelPolicy'),
			field: `travelPolicyCompliance`,
		});
		travelPolicyComplianceFilter.formatter = (values) => {
			values.models = _.sortBy(values.models, (el) => {
				let value = 0;
				switch (el.get('uid')) {
					case 'RECOMMENDED':
						value = -1;
						break;
					case 'VIOLATION':
						value = 1;
						break;
					case 'STRICT_VIOLATION':
						value = 2;
						break;
					case 'AUTHORIZATION_REQUIRED':
						value = 3;
						break;
				}
				return value;
			});
			return values;
		};

		this.add(travelPolicyComplianceFilter);
		this.collect();
		this.bind();
	},

	bind() {
		this.models.forEach((model) => {
			model.on('change:value', () => this.triggerChangeEvent());
		});
	},

	collectSnapshot() {
		const snapshot = _.map(this.models, (m) => {
			if (m instanceof SelectModel) {
				return {
					field: m.get('field'),
					values: _.map(m.get('values').models, (v) => {
						return {
							uid: v.get('uid'),
							value: v.get('value'),
						};
					}),
				};
			}

			return {
				field: m.get('field'),
				value: m.get('value'),
			};
		});
		STATE.setFormState(this.snapshotName, snapshot);
	},

	resetSnapshotFieldsBySettings(snapshot) {
		if (!snapshot || snapshot === null || !Array.isArray(snapshot)) {
			return;
		}
		const siteSettings = STATE.getSettings();
		if (!siteSettings?.secondStepSettings?.filterRefundableEnabled) {
			const exchangeModel = snapshot.find((model) => model.field === 'exchange');
			if (exchangeModel && exchangeModel.value) {
				exchangeModel.value = false;
			}
		}
		if (!siteSettings?.secondStepSettings?.filterRefundableEnabled) {
			const exchangeModel = snapshot.find((model) => model.field === 'refund');
			if (exchangeModel && exchangeModel.value) {
				exchangeModel.value = false;
			}
		}
	},

	applySnapshot() {
		const snapshot = STATE.getFormState(this.snapshotName);

		this.resetSnapshotFieldsBySettings(snapshot);

		_.each(snapshot, ({ field, value, values }) => {
			const model = _.find(this.models, (m) => m.get('field') === field);
			if (!model) {
				return;
			}

			if (model instanceof SelectModel && _.isArray(values)) {
				const valueModels = model.get('values').models;
				_.each(values, (v) => {
					const valueModel = _.find(valueModels, (vm) => vm.get('uid') === v.uid);
					if (!valueModel) {
						return;
					}

					valueModel.set('value', v.value);
				});
			} else if (model instanceof RangeModel && _.isArray(value)) {
				model.set('value', value);
				_.defer(() => model.trigger(`change:value`, model, value));
			} else {
				model.set('value', value);
			}
		});
	},

	filterTickets() {
		this.collectSnapshot();
		this.tickets.forEach(ticket => {
			ticket.set('visibility', !!this.models.reduce((result, model) => {
				return result && model.filter(ticket);
			}, true));
		});
	},

	triggerChangeEvent() {
		clearTimeout(this.timer);
		STATE.getPageView().getPageModel().set('isFilteringResults', true);
		this.timer = setTimeout(() => {
			this.filterTickets();
			if (STATE.getPageView()) STATE.getPageView().getPageModel().set('isFilteringResults', false);
		}, 2000);
	},

	collect() {
		if (this.tickets && this.models) {
			this.tickets.forEach(item => {
				this.models.forEach(model => model.collect(item));
			});
		}
		this.applySnapshot();
	},
});
