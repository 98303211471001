module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<table class="b-order-pricing">\n	';
 if (this.mergePricing != null) {
		var passengerPrice = _.extend({}, this.mergePricing, this.mergePricing.feeDetails);
		var types = ['tariff', 'fee', 'taxes', 'techFees', 'supplierFees', 'agencyFees', 'feeAndTaxes', 'discount'];
		// Если несколько разных услуг - суммировать тариф, таксы и писать "Стоимость по билету"
		var travelSubject = this.travelSubjects[0];
		if (_.size(this.travelSubjects) > 1) {
			travelSubject = 'RAILWAY';
			if (passengerPrice.taxes != null) {
				passengerPrice.tariff.amount = parseFloat(passengerPrice.tariff.amount) + parseFloat(passengerPrice.taxes.amount);
				delete passengerPrice.taxes;
			}
		} ;
__p += '\n\n		<tr class="b-order-pricing__section">\n			<td class="b-order-pricing__section-pricing">\n				';
 _.each(_.pick(passengerPrice, types), function (price, key) {

					if (key !== 'discount') {
						var priceValue = (!_.isArray(price)) ? [price] : price;
						_.each(priceValue, function (el) { ;
__p += '\n						';
 if (el && parseFloat(el.amount) !== 0) { ;
__p += '\n						<div class="b-order-pricing__section-info">\n							<span class="b-order-pricing__section-passenger">\n								' +
((__t = ( L10N.get("pricing." + travelSubject + "." + key) )) == null ? '' : __t) +
'\n							</span>\n							<span class="b-order-pricing__section-price">\n								' +
((__t = ( Formatter.formatMoney(el.amount, Formatter.getDecimal(el.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(el.currencyCode) )) == null ? '' : __t) +
'\n							</span>\n						</div>\n						';
 } ;
__p += '\n						';
 }); ;
__p += '\n					';
 } ;
__p += '\n\n					';
 if (key === 'tariff') { ;
__p += '\n						';
 if (!_.isEmpty(passengerPrice.additionalServices) && _.compact(_.values(passengerPrice.additionalServices)).length) { ;
__p += '\n							';
 if (passengerPrice.additionalServices.checkInAmount) { ;
__p += '\n								<div class="b-order-pricing__section-info">\n									<span class="b-order-pricing__section-passenger">' +
((__t = ( L10N.get('cabinet.orders.additionalServices.checkInAmount') )) == null ? '' : __t) +
'</span>\n									<span class="b-order-pricing__section-price">\n										' +
((__t = ( Formatter.formatMoney(passengerPrice.additionalServices.checkInAmount, Formatter.getDecimal(passengerPrice.additionalServices.checkInAmount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(passengerPrice.total.currencyCode) )) == null ? '' : __t) +
'\n									</span>\n								</div>\n							';
 } ;
__p += '\n							';
 if (passengerPrice.additionalServices.checkOutAmount) { ;
__p += '\n								<div class="b-order-pricing__section-info">\n									<span class="b-order-pricing__section-passenger">' +
((__t = ( L10N.get('cabinet.orders.additionalServices.checkOutAmount') )) == null ? '' : __t) +
'</span>\n									<span class="b-order-pricing__section-price">\n										' +
((__t = ( Formatter.formatMoney(passengerPrice.additionalServices.checkOutAmount, Formatter.getDecimal(passengerPrice.additionalServices.checkOutAmount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(passengerPrice.total.currencyCode) )) == null ? '' : __t) +
'\n									</span>\n								</div>\n							';
 } ;
__p += '\n							';
 if (passengerPrice.additionalServices.mealAmount) { ;
__p += '\n								<div class="b-order-pricing__section-info">\n									<span class="b-order-pricing__section-passenger">' +
((__t = ( L10N.get('hotels.meal') )) == null ? '' : __t) +
'</span>\n									<span class="b-order-pricing__section-price">\n										' +
((__t = ( Formatter.formatMoney(passengerPrice.additionalServices.mealAmount, Formatter.getDecimal(passengerPrice.additionalServices.mealAmount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(passengerPrice.total.currencyCode) )) == null ? '' : __t) +
'\n									</span>\n								</div>\n							';
 } ;
__p += '\n						';
 } ;
__p += '\n					';
 } ;
__p += '\n\n					';
 if (key === 'discount' && parseFloat(price.amount) !== 0) { ;
__p += '\n						<div class="b-order-pricing__section-info">\n							<span class="b-order-pricing__section-passenger">\n								' +
((__t = ( L10N.get("pricing." + travelSubject + "." + key) )) == null ? '' : __t) +
'\n							</span>\n							<span class="b-order-pricing__section-price">\n								' +
((__t = ( Formatter.formatMoney(price.amount, Formatter.getDecimal(price.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(price.currencyCode) )) == null ? '' : __t) +
'\n							</span>\n						</div>\n					';
 } ;
__p += '\n\n				';
 }); ;
__p += '\n			</td>\n		</tr>\n	';
 } ;
__p += '\n\n	';
 if (this.options.commission != null && parseFloat(commission.amount) !== 0) { ;
__p += '\n		<tr class="b-order-pricing__section">\n			<td class="b-order-pricing__section-pricing">\n				<div class="b-order-pricing__section-info">\n					<span class="b-order-pricing__section-passenger">\n						' +
((__t = ( L10N.get('cabinet.orders.paymentCommission') )) == null ? '' : __t) +
'\n					</span>\n					<span class="b-order-pricing__section-price">\n						' +
((__t = ( Formatter.formatMoney(commission.amount, Formatter.getDecimal(commission.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(commission.currencyCode) )) == null ? '' : __t) +
'\n					</span>\n				</div>\n			</td>\n		</tr>\n	';
 } ;
__p += '\n</table>\n';

}
return __p
}