module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<span  class="b-finance-select__item_selected">\n	<span class="b-finance-select__item_selected_text">' +
((__t = (obj.caption )) == null ? '' : __t) +
'</span>\n	<span class="b-finance-select__item-code" uid="' +
((__t = (obj.uid )) == null ? '' : __t) +
'"> </span>\n</span>\n';

}
return __p
}