// eslint-disable-next-line banned-modules
'use strict';

import Backbone from 'backbone';
import BaseCollection from '@/classes/base.collection';

export default BaseCollection.extend({

	toJSON(options) {
		return Backbone.Collection.prototype.toJSON.call(this, options);
	},

});
