import React from "react";
import { useMemo } from "react";

interface FormItemValidationProps {
	errors: string[];
}

const FormItemValidation: React.FC<FormItemValidationProps> = (props) => {
	const { errors } = props;
	const validationErrorsItems = useMemo(() => {
		if (errors.length) {
			return (
				<span className="validations-errors__container">
					{errors.map((error: any, index: number) => {
						return (
							<span className="validations-errors__item" key={index}>
								{error}
							</span>
						);
					})}
				</span>
			);
		}
		return null;
	}, [errors]);

	return <>{validationErrorsItems}</>;
};

export default FormItemValidation;
