module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var legs = legs || [];
;
__p += '\n\n<div class="b-refund-exchange__flight-legs">\n    ';
 _.each(legs, function(leg, legIndex) { ;
__p += '\n        <div class="b-refund-exchange__flight-leg">\n            ';
 _.each(leg.segments, function(seg, segIndex) { ;
__p += '\n                ';

                    var segmentName = '';
                    if (seg.departureCity && seg.departureCity.caption) {
                        segmentName += seg.departureCity.caption;
                    }

                    segmentName += ' - ';

                    if (seg.arrivalCity && seg.arrivalCity.caption) {
                        segmentName += seg.arrivalCity.caption;
                    }
                    var companyLogo;
                    if (seg.airline != null) {
                        companyLogo = seg.airline.codeSystem !== 'IATA' ? seg.airline.airlineCode + '-' + seg.airline.codeSystem : seg.airline.airlineCode
                    }
                ;
__p += '\n                <div class="b-refund-exchange__flight-segment b-segment">\n                    <div class="b-segment__name"> ' +
((__t = ( segmentName )) == null ? '' : __t) +
'</div>\n                    <div class="b-segment__aircraft">' +
((__t = ( seg.airline && seg.airline.airlineCode + '-' + seg.flightNumber || seg.flightNumber )) == null ? '' : __t) +
'</div>\n                    <div class="b-segment__company-logo-container">\n                        <i class="b-segment__company-logo" style="background-image: url(\'/assets/img/logos/' +
((__t = ( companyLogo )) == null ? '' : __t) +
'-black.svg\');"></i>\n                    </div>\n                    <div class="b-segment__departure-date">' +
((__t = ( seg.departureDate && new Time(seg.departureDate).format('DD.MM.YY dd') || '-' )) == null ? '' : __t) +
'</div>\n                    <div class="b-segment__travel-time-container">\n                        ';
 if (seg.departureDate && seg.arrivalDate) { ;
__p += '\n                            ';

                                var departureDate = new Time(seg.departureDate);
                                var arrivalDate = new Time(seg.arrivalDate);
                                var nextDay = departureDate.format('D') !== arrivalDate.format('D');
                            ;
__p += '\n                            <span class="b-segment__travel-time" ';
 if (nextDay === true) { ;
__p += ' data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( L10N.get('ticket.arrive') )) == null ? '' : __t) +
': ' +
((__t = ( arrivalDate.format('dddd, DD.MM') )) == null ? '' : __t) +
'"';
 } ;
__p += ' >\n                                                        ' +
((__t = ( departureDate.toTravelTime() + '-' + arrivalDate.toTravelTime() )) == null ? '' : __t) +
'\n                                ';
 if (nextDay === true) { ;
__p += '\n                                    <sup class="b-leg__date-next-day">+1</sup>\n                                ';
 } ;
__p += '\n                            </span>\n                        ';
 } ;
__p += '\n                    </div>\n                </div>\n            ';
 }) ;
__p += '\n        </div>\n    ';
 }) ;
__p += '\n</div>\n';

}
return __p
}