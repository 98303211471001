'use strict';

import BaseModel from '@/classes/base.model';

export default BaseModel.extend({

	initialize(options) {
		const capacity = this.get('transfer.capacity');
		const travellersCount = this.get('travellersCount');
		const hasTravelPolicy = options.hasTravelPolicy;

		this.set('capacity', {uid: capacity, caption: L10N.getPluralForm(`transfers.capacity`, capacity)});

		this.set('price', this.get('transfer.totalPrice').amount);

		if (hasTravelPolicy) {
			if (!_.isEmpty(this.get('transfer.travelPolicyCompliance.rulesApplied'))) {
				this.set(`travelPolicyCompliance`, this.get('transfer.travelPolicyCompliance.rulesApplied').map((rule) => rule.action));
			} else {
				this.set(`travelPolicyCompliance`, {caption: L10N.get('bookingForm.noViolation'), uid: 'COMPLIANCE'});
			}
		}
		if (!this.get('transfer.isExceededCapacity')) {
			this.set('isExceededCapacity', parseInt(travellersCount) > parseInt(capacity));
		}
	},
});
