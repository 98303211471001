// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import BlockTableView from '@/blocks/layout/b-block-table/index';
import template from './template.ejs';

export default BaseView.extend({

	template,

	initialize() {
		this.render();
		this.$el.append(new BlockTableView().$el);
	},

});
