module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-hotels-locations__item">\n	<span class="b-hotels-locations__item-city">' +
((__t = ( this.getCaptionForHotel(obj, true) )) == null ? '' : __t) +
'</span>\n</div>\n';

}
return __p
}