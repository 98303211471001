// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import $ from 'jquery';

export default (() => {
	let googleMapsApi;
	let googleMapsApiPromise;

	let GOOGLE_MAPS_API_TOKEN = '__googleMapsKey__';
	const GOOGLE_MAPS_API_URL = `//maps.googleapis.com/maps/api/js?`;
	const GOOGLE_MAPS_API_PARAMS = {
		libraries: ['geometry'],
		callback: 'googleMapsAutoCompleteAPILoad',
		language: 'ru',
		v: '3',
	};

	class Gmaps {
		loadAutoCompleteAPI(params) {
			const { generalSettings } = STATE && STATE.getSettings ? STATE.getSettings() : {};
			if (!_.isEmpty(generalSettings) && generalSettings.googleMapsApiKey) {
				GOOGLE_MAPS_API_TOKEN = generalSettings.googleMapsApiKey;
				GOOGLE_MAPS_API_PARAMS.key = GOOGLE_MAPS_API_TOKEN;
			}

			$('head').append($('<script />', _.extend({
				src: _.reduce(GOOGLE_MAPS_API_PARAMS, (result, value, key) => `${result}&${key}=${value}`, GOOGLE_MAPS_API_URL),
				async: '',
			}, params)));
		}

		googleMapsApiLoader(params) {
			if (googleMapsApi != null) {
				return Promise.resolve(googleMapsApi);
			}

			if (googleMapsApiPromise == null) {
				googleMapsApiPromise = new Promise((resolve, reject) => {
					this.loadAutoCompleteAPI(params);

					window.googleMapsAutoCompleteAPILoad = () => {
						googleMapsApi = window.google;
						resolve(googleMapsApi);
					};

					setTimeout(() => {
						if (window.google == null) {
							reject();
						}
					}, 5000);
				});
			}

			return googleMapsApiPromise;
		}

		static googleMapsApiIsLoaded() {
			return window.google != null;
		}

		static getApiToken() {
			return GOOGLE_MAPS_API_TOKEN;
		}

		static getApiPromise() {
			return googleMapsApiPromise != null ? googleMapsApiPromise : new Gmaps().googleMapsApiLoader();
		}

		static setMapControls(map, { isSimplyMap } = {}) {
			// Zoom controls
			const mapSettingsControlDiv = document.createElement('div');
			mapSettingsControlDiv.index = 1;

			mapSettingsControlDiv.style.padding = '9px';
			mapSettingsControlDiv.style.top = '0';
			mapSettingsControlDiv.style.bottom = 'auto';

			const controlWrapper = document.createElement('div');
			mapSettingsControlDiv.appendChild(controlWrapper);

			const zoomInButton = document.createElement('div');
			zoomInButton.className = 'hotels-map-zoom-in';
			controlWrapper.appendChild(zoomInButton);

			const zoomOutButton = document.createElement('div');
			zoomOutButton.className = 'hotels-map-zoom-out';
			controlWrapper.appendChild(zoomOutButton);

			try {
				googleMapsApi.maps.event.addDomListener(zoomInButton, 'click', () => {
					map.setZoom(map.getZoom() + 1);
				});

				googleMapsApi.maps.event.addDomListener(zoomOutButton, 'click', () => {
					map.setZoom(map.getZoom() - 1);
				});
			} catch (e) {
				throw e;
			}

			map.controls[googleMapsApi.maps.ControlPosition.LEFT_BOTTOM].push(mapSettingsControlDiv);

			let idleCallback = () => {
				$(zoomOutButton).tooltip({
					title: L10N.get('Common.maps.zoomOut'),
					placement: 'right',
					container: 'body',
				});
				$(zoomInButton).tooltip({
					title: L10N.get('Common.maps.zoomIn'),
					placement: 'right',
					container: 'body',
				});
			};

			if (!isSimplyMap) {
				// Close control
				const mapCloseControlDiv = document.createElement('div');
				mapCloseControlDiv.index = 1;

				const closeButton = document.createElement('div');
				closeButton.className = 'hotels-map-close';
				mapCloseControlDiv.appendChild(closeButton);

				map.controls[googleMapsApi.maps.ControlPosition.RIGHT_TOP].push(mapCloseControlDiv);

				// Expand control
				const mapControlWrapDiv = document.createElement('div');
				const mapExpandControlDiv = document.createElement('div');
				mapExpandControlDiv.index = 1;

				const expandButton = document.createElement('div');
				expandButton.className = 'hotels-map-expand';
				mapExpandControlDiv.appendChild(expandButton);

				mapControlWrapDiv.appendChild(mapExpandControlDiv);

				// Fixed control
				const fixTopButton = document.createElement('div');
				fixTopButton.className = 'hotels-map-fix';
				mapControlWrapDiv.appendChild(fixTopButton);

				map.controls[googleMapsApi.maps.ControlPosition.RIGHT_TOP].push(mapControlWrapDiv);

				const baseCallback = idleCallback;
				idleCallback = () => {
					baseCallback();

					$(fixTopButton).tooltip({
						title: L10N.get('Common.maps.fix'),
						placement: 'left',
						container: 'body',
					});
					$(expandButton).tooltip({
						title: L10N.get('Common.maps.fullScreen'),
						placement: 'left',
						container: 'body',
					});
					$(closeButton).tooltip({
						title: L10N.get('Common.maps.close'),
						placement: 'left',
						container: 'body',
					});
				};
			}

			googleMapsApi.maps.event.addListenerOnce(map, 'idle', idleCallback);
		}
	}

	return Gmaps;
}
)();
