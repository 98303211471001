// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';
import Backbone from 'backbone';

export default BaseModel.extend({

	collect() {},

	filter() {
		return true;
	},

	parse(value) {
		return value;
	},

	render() {
		const value = this.get('values') || this.get('value');

		if (value == null ||
			(_.isArray(value) && _.isEmpty(value)) ||
			(value instanceof Backbone.Collection && !value.length)) {
			return null;
		}

		const view = new this.View({
			model: this,
		});
		this.set('view', view);
		return view;
	},

});
