// eslint-disable-next-line banned-modules
'use strict';

import BasePageView from '@/pages/base.page-view';
import PassengersDataView from '@/blocks/pages/b-passengers-data/hotels/index';
import HotelInfoView from '@/blocks/elements/b-hotel/b-hotel-info/index';

import Gmaps from '@/blocks/utils/b-google-maps/index';
import axios from 'axios';

export default BasePageView.extend({

	initialize(options) {
		this.options = options || {};

		this.render();

		this.renderInfoView(this.options);
		this.passengersDataView = new PassengersDataView({
			bookingSettings: options,
			parent: this,
			isIntention: true,
			hotelInfoView: this.hotelInfoView,
		});

		this.ui.content.html(this.passengersDataView.$el);
	},

	renderInfoView(options) {
		this.hotelInfoView = new HotelInfoView({
			hotel: options.hotelCard,
			showGdsAccountName: this.options.showGdsAccountName,
		});
		this.ui.sidebar.html(this.hotelInfoView.$el);
		if (this.passengersDataView) {
			this.updatePassengerDataView(options);
		}
	},

	updatePassengerDataView(options) {
		this.passengersDataView.options.hotelInfoView.destroy();
		this.passengersDataView.options.hotelInfoView = this.hotelInfoView;
		this.passengersDataView.options.bookingSettings = this.getMergedOptions(options);
		this.passengersDataView.updateData();
	},

	getMergedOptions(newOptions = {}) {
		/*
			IBECORP-6945
			Т.к. после репрайса некоторых полей не хватает, пока будем мерджить старые поля с новыми данными.
			Подробный коммент по этому поводу есть в задаче.
		*/
		return {
			/* Старые поля */
			currentBooking: this.options?.currentBooking,
			reservation: this.options?.reservation,
			showGdsAccountName: this.options?.showGdsAccountName,
			/* Новые поля */
			...newOptions,
		};
	},

}, {
	async load() {
		if (!STATE.getCountries()) {
			await STATE.loadCountries();
		}

		if (window.google == null) {
			await Gmaps.getApiPromise();
		}

		return new Promise((resolve) => {
			const data = STORE.get(`${STATE.ROUTES.HOTELS_PREVIEW}_INTENTION_DATA`);
			if (_.isEmpty(data)) {
				STATE.navigate(STATE.ROUTES.INDEX);
				return;
			}
			const passengersParameters = { offers: data.hotelCard.hotelOffers };
			axios.post('/midoffice/ibecorp-b2b/hotels/getIntentionFormSettings', {
				parameters: passengersParameters,
			}).then((response) => {
				const { result } = response.data;
				const offersWithOnlineParam = [];
				_.each(data.hotelCard.hotelOffers, (offer) => {
					const newOffer = {
						online: offer.online,
					};
					offersWithOnlineParam.push(newOffer);
				});
				STATE.set('offersWithOnlineParam', offersWithOnlineParam);
				resolve({ ...result, ..._.omit(data, `hotelCard`) });
			});
		});
	},
});
