module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-header">\r\n    <a href="' +
((__t = ( this.getRootURL() )) == null ? '' : __t) +
'" class="b-header__logo">\r\n        <div class="b-header__logo-img"></div>\r\n    </a>\r\n    <div class="b-header__info">\r\n        <div class="b-header__info-wrapper">\r\n            <div class="b-header__additional-order"></div>\r\n            <div class="b-header__info-account">\r\n                <p class="b-header__info-account-text">' +
((__t = ( Formatter.displayFullName(this.profile) )) == null ? '' : __t) +
'</p>\r\n                <div class="react-news-block"></div>\r\n            </div>\r\n            <div>\r\n                ';
 if (this.balanceInfo != null && this.balanceInfo.availableFunds != null && this.balanceInfo.availableFundsCurrency != null) { ;
__p += '\r\n                    ' +
((__t = ( require('./template-price.ejs')({ balanceInfo: this.balanceInfo }) )) == null ? '' : __t) +
'\r\n                ';
 } ;
__p += '\r\n                <div class="b-header__additional-client"></div>\r\n            </div>\r\n        </div>\r\n        <div class="b-header__info-button b-header__info-search-button"></div>\r\n        <div class="b-header__info-button b-header__info-menu-button"></div>\r\n        <div class="b-header__info-language"><span class="js-widget"  onclick=\'return new Widgets.Language()\'></span></div>\r\n    </div>\r\n</div>\r\n<div class="b-header__blocks"></div>\r\n\r\n';

}
return __p
}