module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-current-date-matrix">\r\n	<table class="b-current-date-matrix__table">\r\n			';

			_.each(_.keys(bestPrices).sort(), function (key) {
				;
__p += '\r\n				<tr>\r\n					<td class="b-current-date-matrix__table-info">' +
((__t = ( L10N.get("currentMatrix." + key) )) == null ? '' : __t) +
'</td>\r\n					';

					var flights = bestPrices[key].bestFlights
					var flightsSize = flights.length;
					var tail = 0;
					if (flightsSize > 7) {
						flightsSize = 7;
					} else {
						tail = 7 - flightsSize;
					}
					for (var i = 0; i < flightsSize; i++) {
						;
__p += '\r\n						<td class="b-current-date-matrix__table-price" data-connetion="' +
((__t = ( key )) == null ? '' : __t) +
'" data-carrier="' +
((__t = ( flights[i].carrier.uid )) == null ? '' : __t) +
'">\r\n							<div class="b-current-date-matrix__table-price-container">\r\n								<span>' +
((__t = ( L10N.get('currentMatrix.up') )) == null ? '' : __t) +
'&nbsp;' +
((__t = ( flights[i].price.amount )) == null ? '' : __t) +
'&nbsp;' +
((__t = ( Formatter.formatCurrency(flights[i].price.currencyCode) )) == null ? '' : __t) +
'</span>\r\n								<span class="b-current-date-matrix__table-price-airline g-icon " style="background-image: url(\'/assets/img/logos/' +
((__t = (flights[i].carrier.airlineCode )) == null ? '' : __t) +
'-black.svg\')"></span>\r\n							</div>\r\n						</td>\r\n					';

					}
					for (var i = 0; i < tail; i++) {
						;
__p += '\r\n						<td class="b-current-date-matrix__table-price no-pointer"></td>\r\n						';

					}
					;
__p += '\r\n				</tr>\r\n				';

			});
 			;
__p += '\r\n\r\n	</table>\r\n</div>\r\n';

}
return __p
}