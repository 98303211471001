// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';
import $ from 'jquery';

export default BaseView.extend({

	template,

	ui: {
		selected: '.b-sorting__selected',
		dropdown: '.b-sorting__drop-down',
	},

	events: {
		'click .b-sorting__selected': 'toggle',
		'click .b-sorting__drop-down-item': 'select',
	},

	isOpen: false,

	initialize(options) {
		const { hasTravelPolicy = false, fakeRecommendedSort = false, defaultSortOption = null } = options;

		this.container = options.container;
		this.defaultSortOption = defaultSortOption;
		this.views = options.views;

		this.sortOptions = [{
			value: 'SORT_BY_PRICE_ASCENDING',
			label: L10N.get(`sorting.types.price_asc`),
			field: 'price',
			sort: (views) => views.sort((a, b) => {
				const price1 = a.model.get('price');
				const price2 = b.model.get('price');

				if (price1 !== 0 && (price2 === 0 || isNaN(price2))) {
					return Number.MIN_SAFE_INTEGER;
				}
				if ((price1 === 0 || isNaN(price1)) && price2 !== 0) {
					return Number.MAX_SAFE_INTEGER;
				}

				return price1 - price2;
			}),
		}, {
			value: 'SORT_BY_PRICE_DESCENDING',
			label: L10N.get(`sorting.types.price_desc`),
			field: 'price',
			sort: (views) => views.sort((a, b) => {
				const price1 = a.model.get('price');
				const price2 = b.model.get('price');

				if (price1 !== 0 && (price2 === 0 || isNaN(price2))) {
					return Number.MIN_SAFE_INTEGER;
				}
				if ((price1 === 0 || isNaN(price1)) && price2 !== 0) {
					return Number.MAX_SAFE_INTEGER;
				}

				return price2 - price1;
			}),
		}];

		if (this.options.type === 'RAILWAYS') {
			this.sortOptions.unshift({
				value: 'SORT_BY_SCHEDULE',
				label: L10N.get(`sorting.types.schedule_time`),
				field: 'departureDate',
				sort: (views) => _.sortBy(views, (el) => {
					const train = el.model.get('_train');
					return train.departureDate[0] || Number.MIN_SAFE_INTEGER;
				}),
			});
		} else if (this.options.type === 'AVIA') {
			this.sortOptions.unshift({
				value: 'SORT_BY_SCHEDULE',
				label: L10N.get(`sorting.types.schedule`),
				field: 'departureDate',
				sort: (views) => {
					return (
						_.chain(views)
							.sortBy((el) => parseFloat(el.model.get('price')))
							.sortBy((el) => {
								const departureDate = el.model.get('departureDate');
								return departureDate[0] || Number.MIN_SAFE_INTEGER;
							})
							.sortBy((el) => {
								const { legs } = el.model.get('flight');
								const leg = _.first(legs);

								return leg != null ? leg.segments.length : Number.MIN_SAFE_INTEGER;
							})
							.value()
					);
				},
			});
		}

		if (hasTravelPolicy) {
			this.sortOptions.push({
				value: 'SORT_BY_TRAVEL_POLICY',
				label: L10N.get(`sorting.types.travel_policy`),
				field: 'travelPolicyCompliance',
				sort: (views) => {
					const isTrainSorting = _.every(views, (el) => (el.model.get('_train') != null));

					const getScore = (collection, price) => {
						if (_.some(collection, (item) => item.uid === 'RECOMMENDED')) {
							if (_.some(collection, (item) => item.uid === 'VIOLATION')) {
								return -90 + price;
							} else if (_.some(collection, (item) => item.uid === 'STRICT_VIOLATION')) {
								return -80 + price;
							}
							return -100 + price;
						} else if (_.some(collection, (item) => item.uid === 'VIOLATION')) {
							return -20 + price;
						} else if (_.some(collection, (item) => item.uid === 'STRICT_VIOLATION')) {
							return -10 + price;
						}
						return -30 + price;
					};
					
					return _.chain(views)
						.sortBy((el) => {
							const collection = isTrainSorting ? el.model.get('_train').travelPolicyCompliance :
								el.model.get('travelPolicyCompliances') || el.model.get('travelPolicyCompliance');

							const parsedPrice = parseFloat(el.model.get('price'));
							const price = isNaN(parsedPrice) || parsedPrice <= 0 ? 0 : Math.log(parsedPrice);

							const score = getScore(collection, price);

							return score;
						})
						.value();
				},
			});
		}

		if (fakeRecommendedSort) {
			this.sortOptions.push({
				value: 'SORT_BY_RECOMMEND',
				label: L10N.get(`sorting.types.recommended`),
				field: 'recommended',
				sort: (views) => {
					return _.chain(views).sortBy((el) => parseFloat(el.model.get('price')))
						.sortBy((el) => {
							const isRecommended = el.model.get('recommended');

							if (isRecommended) {
								return -100;
							}
							return 10;
						})
						.value();
				},
			});
		}

		this.render({
			variants: this.sortOptions,
		});

		const savedSortingValue = this.getSortingOption();
		const savedSotring = _.find(this.sortOptions, (o) => o && o.value === savedSortingValue) || this.sortOptions[0];
		if (this.defaultSortOption !== null) {
			const defaultSort = _.find(this.sortOptions, (o) => o.value === this.defaultSortOption.uid) || this.sortOptions[0];
			this.setActive(defaultSort, true);
		} else if (fakeRecommendedSort && !hasTravelPolicy) {
			const fakeSort = _.find(this.sortOptions, (o) => o && o.value === 'SORT_BY_RECOMMEND');
			this.setActive(fakeSort, this.options.type === 'HOTEL');
		} else if (savedSotring) {
			this.setActive(savedSotring, true);
		} else if (hasTravelPolicy) {
			const tp = _.find(this.sortOptions, (o) => o.value === 'SORT_BY_TRAVEL_POLICY');
			this.setActive(tp, this.options.type === 'HOTEL');
		} else {
			this.setActive(this.sortOptions[0], true);
		}
	},

	sort() {
		let views = [];
		let hasWrapper = false;

		this.views = this.active.sort(this.views);
		if (!_.isEmpty(this.views)) {
			const $view = this.views[0].$el;
			hasWrapper = $view.parent().hasClass('b-ticket') || $view.parent().hasClass('b-hotel');
		}

		if (!_.isFunction(this.options.renderCallback)) {
			_.each(this.views, (view) => {
				if (hasWrapper) {
					views.push(view.$el.parent().detach());
				} else {
					views.push(view.$el.detach());
				}
			});

			_.each(views, (v) => {
				if (!$(v).is(':empty') || $(v).hasClass('b-ticket__visible')) {
					v.appendTo(this.container);
				}
			});
		} else {
			this.options.renderCallback(this.views);
		}

		views = [];
	},

	sortGiven(views = []) {
		return this.active.sort(views);
	},

	setActive(item, silent) {
		this.active = item;
		this.ui.selected.text(item.label);
		this.close();

		this.saveSortingOption(item);
		if (!silent) {
			this.sort();
		}
	},

	saveSortingOption(item) {
		STORE.set('SORTING_VIEW_OPTION', item.value, 36000);
	},

	getSortingOption() {
		return STORE.get('SORTING_VIEW_OPTION');
	},

	select(e) {
		const val = this.$el.find(e.currentTarget).attr('type');

		if (val !== this.active.value) {
			this.setActive(this.sortOptions.reduce((result, item) => {
				if (val === item.value) {
					result = item;
				}
				return result;
			}, null));
			this.close();
		}
	},

	toggle() {
		if (this.isOpen) {
			this.close();
		} else {
			this.open();
		}
	},

	open() {
		this.isOpen = true;
		this.isOuter = false;
		this.$el.addClass('b-sorting__open');
		this.ui.dropdown.stop().clearQueue().fadeIn();
		$('html').on('click.sorting', () => {
			if (this.isOuter) {
				$('html').off('click.sorting');
				this.close();
			}
			this.isOuter = true;
		});
	},

	close() {
		this.$el.removeClass('b-sorting__open');
		if (this.isOpen) {
			this.ui.dropdown.stop().clearQueue().fadeOut();
			this.isOpen = false;
		} else {
			this.ui.dropdown.stop().clearQueue().hide();
		}
	},

});
