module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<label class="b-toggle ' +
((__t = (classes )) == null ? '' : __t) +
'">\r\n	<span class="b-toggle__label ' +
((__t = ( labelFirst === '' ? 'dn' : '')) == null ? '' : __t) +
'">' +
((__t = (labelFirst)) == null ? '' : __t) +
'</span>\r\n	<input type="checkbox" class="b-toggle__input">\r\n	<span class="b-toggle__label"><i class="b-toggle__view"></i>' +
((__t = (labelSecond)) == null ? '' : __t) +
'</span>\r\n</label>\r\n';

}
return __p
}