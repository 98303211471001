// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

import GlUl from '@/utils/global-utils';
import TravelerModel from './model';
import SelectWidget from '@/widgets/b-select/index';
import InputWidget from '@/widgets/b-input/index';
import MiddleNameWidget from '@/widgets/b-middle-name/index';
import PassengerAutocompleteWidget from '@/widgets/autocomplete-input/b-passenger/index';
import BookingAutoCorrectionAdapter from '../../../utils/b-booking-auto-correction-adapter';
import { getAutoCorrectionWidgetsLabels } from '../../../utils/b-booking-auto-correction-input-labels';
import TravellerCostCodesAdapter from '../../b-cost-codes/b-traveller-cost-codes-adapter';
import Deferred from '@/utils/Deferred';
import axios from 'axios';

export default BaseView.extend({
	ui: {
		expiredDate: '.b-traveller__passport-expired-date',
		statisticsContainer: '.b-traveller-cost-codes__container',
		passportNumber: '.b-traveller__passport-number',
	},

	initialize(options) {
		this.index = options.index;
		this.forbidTravellerUpdate = this.options.forbidTravellerUpdate;
		this.corporatePassengers = this.options.bookingSettings.corporatePassengers;
		this.contactPersonEditGranted = this.options.contactPersonEditGranted;
		this.contactPersonDisableFreeTextInput =
			options.contactPersonDisableFreeTextInput;
		this.hotelsView = options.hotelsView;
		this.addPreValidateDeferred = this.hotelsView.addPreValidateDeferred;
		this.removePreValidateDeferred = this.hotelsView.removePreValidateDeferred;
		this.organizationCostCodes = options?.bookingSettings?.organizationCostCodes; // при инициализации используем общие (глобальные) кост коды
		this.getFormSettings = this.getIntentionFormSettings.bind(this);
		this.bookingFormSettingsToken = options?.bookingSettings?.bookingFormSettingsToken || undefined;

		if (!this.model) {
			this.model = new TravelerModel();
		}

		if (options.isContact != null) this.model.set('isContact', options.isContact);

		this.isMiddleNameRequired = true;
		this.isAdult =
			this.model.get('type') != null && this.model.get('type').uid === 'ADULT';

		this.bookingAutoCorrectionAdapter = new BookingAutoCorrectionAdapter(
			{
				routeType: STATE.router.breadcrumbs.routeType,
				transliterationOpts: this.getTransliterationOpts(),
			},
		);

		this.listenTo(this.model.get('passport'), 'change:lastName change:firstName change:middleName', () => {
			if (this.bookingAutoCorrectionAdapter) {
				this.bookingAutoCorrectionAdapter.updateTransliterationOpts(this.getTransliterationOpts());
			}
		});

		this.autoCompleteData(this.options.passenger);

		// LISTENERS
		this.listenTo(this.model, `change:birthDate`, (model, value) => {
			this.model.set('passport.birthDate', value);
		});

		this.listenTo(
			this.model.get('passport'),
			`change:citizenship`,
			async () => {
				// const dfd = new Deferred();
				// this.addPreValidateDeferred(dfd);

				// try {
				// 	this.resetNonCommonCostCodes();
				const formSettings = await this.getFormSettings();
				const { langRegistrationFullName = {} } =
								this.options.bookingSettings || {};
				if (formSettings.langRegistrationFullName &&
								(formSettings.langRegistrationFullName.uid !==
								langRegistrationFullName.uid)
				) {
					this.langRegistrationFullName = formSettings.langRegistrationFullName;
					this.autoCompleteData(this.options.passenger);
					this.autoCompletePassport(this.model.get('passport').get('type'));
				}
				this.trigger('travellerPersonChanged', this);
				// dfd.resolve();
				// this.removePreValidateDeferred();
				// } catch (e) {
				// 	dfd.reject(e);
				// }
			},
		);

		this.listenTo(this.model, `typeahead:passengerSelect`, async (model, value) => {
			const dfd = new Deferred();
			this.addPreValidateDeferred(dfd);

			try {
				this.resetNonCommonCostCodes();
				await this.getFormSettings(); // <- обновляем список костКодов
			
				const container = this.$el;
				this.options.passenger = _.omit(value, 'uid');
			
				this.autoCompleteData(this.options.passenger);
			
				this.$el.empty();
				this.render();
				container.append(this.$el);
			
				this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));
				this.autoCompletePassport(this.model.get('passport').get('type'));
			
				this.trigger('travellerPersonChanged', this);

				dfd.resolve();
				this.removePreValidateDeferred();
			} catch (e) {
				dfd.reject(e);
			}
		});

		this.render();
		this.autoCompletePassport(this.model.get('passport').get('type'));
	},

	getTransliterationOpts() {
		const opts = {
			traveller: {
				...this.model.toJSON(),
				langRegistrationFullName: this.options.langRegistrationFullName,
			},
		};
		if (
			opts.traveller.passport &&
			opts.traveller.passport.type &&
			opts.traveller.passport.type.uid.indexOf('.') !== -1
		) {
			opts.traveller.passport.type.uid = opts.traveller.passport.type.uid.split('.')[0];
		}
		return opts;
	},

	getIntentionFormSettings() {
		const traveller = this.model.toJSON();
		if (
			traveller.passport &&
			traveller.passport.type &&
			traveller.passport.type.uid.indexOf('.') !== -1
		) {
			traveller.passport.type.uid = traveller.passport.type.uid.split('.')[0];
		}
		const params = {
			parameters: {
				traveller,
				offers: this.options.offers,
				bookingFormSettingsToken: this.bookingFormSettingsToken,
			},
		};

		return axios
			.post(
				'/midoffice/ibecorp-b2b/hotels/getIntentionFormSettings',
				params,
				this.model,
			)
			.then((res) => {
				this.organizationCostCodes = res.data.result?.organizationCostCodes || [];
				return res.data.result;
			});
	},

	adjustMobileTemplate(matches) {
		const $travellerContact = this.$('.b-traveller__title-contact');
		const $travellerTitle = this.$('.b-traveller__title');
		const $travellerInfo = this.$('.b-traveller-info')
			.find('.flex-grid-container')
			.first();
		const $mobileCollapseHeaders = this.$('.mobile-collapse');

		if (matches) {
			_.each(
				$mobileCollapseHeaders,
				(el) => el && this.$(el).attr('data-toggle', 'collapse'),
			);
			$travellerInfo.prepend($travellerContact);
		} else {
			$travellerTitle.append($travellerContact);
			_.each($mobileCollapseHeaders, (el) => {
				const $target = this.$(el);
				if ($target.hasClass('collapsed')) $target.click();
				$target.removeAttr('data-toggle');
			});
		}
	},

	fillNameFieldsIfAvailable(passenger, langUid) {
		const isRU = this.options.hotelCountry ? this.options.hotelCountry.uid === 'RU' : false;
		const nameFieldsCyr = ['firstName', 'secondName', 'lastName', 'middleName'];
		const nameFieldsLat = [
			'firstNameLat',
			'secondNameLat',
			'lastNameLat',
			'middleNameLat',
		];

		if (!langUid) {
			return nameFieldsCyr;
		}

		let hasCyr = false;
		nameFieldsCyr.forEach((nameField) => {
			if (passenger[nameField]) {
				hasCyr = true;
			}
		});

		if (langUid === 'LATIN_AND_CYRILLIC' || langUid === 'CYRILLIC') {
			if (isRU) {
				return hasCyr ? nameFieldsCyr : nameFieldsLat;
			}
			return nameFieldsLat;
		}
		return nameFieldsLat;
	},

	autoCompleteData(passenger) {
		if (!_.isObject(passenger)) {
			return;
		}

		if (!passenger.phones && !passenger.emails) {
			GlUl.convertToValidPassenger(passenger);
		}

		const langRegistrationDocument =
			this.options.bookingSettings.langRegistrationDocument || {};

		const langRegistrationFullName =
			this.options.bookingSettings.langRegistrationFullName || {};
		const nameFields = ['firstName', 'secondName', 'lastName', 'middleName'];
		const passengerFields = this.fillNameFieldsIfAvailable(
			passenger,
			langRegistrationDocument.uid,
		).concat(['gender', 'citizenship']);

		GlUl.autocompletePassengerFields.call(
			this,
			passenger,
			nameFields,
			langRegistrationFullName,
			passengerFields,
			true,
		);

		if (
			!_.isEmpty(passenger.passports) &&
			_.some(passenger.passports, (p) => p.citizenship)
		) {
			const passport = _.find(passenger.passports, (p) => p.citizenship);
			this.model.set('passport.citizenship', passport.citizenship);
			this.model.set('passport.type', passport.type);
		}
	},

	autoCompletePassport(passport) {
		const { passenger } = this.options;
		const passportFields = [
			'originCountry',
			'number',
			'birthDate',
			'expiredDate',
			'citizenship',
			'gender',
		];

		GlUl.autocompletePassport.call(
			this,
			passport,
			passenger,
			passportFields,
			undefined,
			true,
		);
		return this;
	},

	render() {
		const { langRegistrationFullName } = this.options.bookingSettings;
		const countriesCollection = SelectWidget.dictionaryToCollection(
			STATE.getCountries(),
		);

		this.setElement(
			template({
				index: this.index,
				model: this.model,
				cid: this.model.cid,
				countries: SelectWidget.storeCollection(countriesCollection),
				forbidTravellerUpdate: this.forbidTravellerUpdate,
				isAdult: this.isAdult,
				contactPersonEditGranted: this.contactPersonEditGranted,
				contactPersonDisableFreeTextInput:
					this.contactPersonDisableFreeTextInput,
				isMiddleNameRequired: this.isMiddleNameRequired,
				organizationCostCodes: this.organizationCostCodes,
				langRegistrationFullName,
			}),
		);

		this.renderAutoCorrectionWidgets();

		/* Statistic container */
		this.renderCostCodesView();
	},

	/* Cost Codes */

	resetNonCommonCostCodes() {
		if (this.costCodesAdapter) this.costCodesAdapter.resetNonCommonCostCodes();
	},

	getCommonCostCodesRenderParams() {
		return {
			renderContainer: this.ui.statisticsContainer,
			passenger: this.options.passenger,
			organizationCostCodes: this.organizationCostCodes,
		};
	},

	renderCostCodesView() {
		if (!_.isEmpty(this.organizationCostCodes)) {
			if (!this.costCodesAdapter) {
				this.costCodesAdapter = new TravellerCostCodesAdapter({
					parentView: this,
				});
			} else {
				this.costCodesAdapter.removeCostCodesView();
			}
			this.costCodesAdapter.renderCostCodesView({
				...this.getCommonCostCodesRenderParams(),
			});
		}
	},

	removeCostCodesAdapter() {
		if (this.costCodesAdapter) {
			this.costCodesAdapter.removeCostCodesView();
			this.costCodesAdapter = null;
		}
	},

	/* EOF Cost Codes */

	renderAutoCorrectionWidgets() {
		this.clearBookingAutoCorrectionAdapter();
		
		const {
			l10nLastNamePlaceholder,
			l10nFirstNamePlaceholder,
			l10nSecondNamePlaceholder,
			l10nMiddleNamePlaceholder,
		} = getAutoCorrectionWidgetsLabels({
			langRegistrationFullNameUid: this.options?.langRegistrationFullName?.uid,
			forbidTravellerUpdate: this.forbidTravellerUpdate,
		});
		if (STATE.getLoggedInUser() && !this.forbidTravellerUpdate) {
			const widget = new PassengerAutocompleteWidget({
				bindingProperty: 'passport.lastName',
				transliterationOn: 'true',
				label: L10N.get('bookingForm.lastName'),
				langRegistrationFullName: this.options?.langRegistrationFullName?.uid,
				placeholder: l10nLastNamePlaceholder,
				autoCorrectionAdapter: this.bookingAutoCorrectionAdapter,
				corporatePassengers: this.corporatePassengers,
			});
			widget.applyBinding(this.model);
			this.$el.find('.js-lastName-container').append(widget.$el);
			this.bookingAutoCorrectionAdapter.addWidget(widget);
		} else {
			const widget = new InputWidget({
				bindingProperty: 'passport.lastName',
				transliterationOn: 'true',
				runTransliterationOnFirstRender: 'true',
				label: L10N.get('bookingForm.lastName'),
				placeholder: l10nLastNamePlaceholder,
				disabled: this.forbidTravellerUpdate,
				autoCorrectionAdapter: this.bookingAutoCorrectionAdapter,
			});
			widget.applyBinding(this.model);
			this.$el.find('.js-lastName-container').append(widget.$el);
			this.bookingAutoCorrectionAdapter.addWidget(widget);
		}

		{
			const widget = new InputWidget({
				bindingProperty: 'passport.firstName',
				transliterationOn: 'true',
				runTransliterationOnFirstRender: 'true',
				label: L10N.get('bookingForm.firstName'),
				placeholder: l10nFirstNamePlaceholder,
				disabled: this.forbidTravellerUpdate,
				autoCorrectionAdapter: this.bookingAutoCorrectionAdapter,
			});
			widget.applyBinding(this.model);
			this.$el.find('.js-firstName-container').append(widget.$el);
			this.bookingAutoCorrectionAdapter.addWidget(widget);
		}
		{
			const widget = new InputWidget({
				bindingProperty: 'passport.secondName',
				transliterationOn: 'true',
				runTransliterationOnFirstRender: 'true',
				label: L10N.get('bookingForm.secondName'),
				placeholder: l10nSecondNamePlaceholder,
				disabled: this.forbidTravellerUpdate,
				autoCorrectionAdapter: this.bookingAutoCorrectionAdapter,
			});
			widget.applyBinding(this.model);
			this.$el.find('.js-secondName-container').append(widget.$el);
			this.bookingAutoCorrectionAdapter.addWidget(widget);
		}

		if (this.isMiddleNameRequired) {
			const widget = new MiddleNameWidget({
				bindingProperty: 'passport.middleName',
				label: L10N.get('bookingForm.middleName'),
				transliterationOn: 'true',
				runTransliterationOnFirstRender: 'true',
				placeholder: l10nMiddleNamePlaceholder,
				disabled: this.forbidTravellerUpdate,
				forbidEdit: this.forbidTravellerUpdate,
				withoutMiddleName: true,
				autoCorrectionAdapter: this.bookingAutoCorrectionAdapter,
			});
			widget.applyBinding(this.model);
			this.$el.find('.js-middleName-container').append(widget.$el);
			this.bookingAutoCorrectionAdapter.addWidget(widget);
		}
	},

	clearBookingAutoCorrectionAdapter() {
		if (this.bookingAutoCorrectionAdapter) {
			this.bookingAutoCorrectionAdapter.clearRunningRequest();
			this.bookingAutoCorrectionAdapter.removeAllWidgets();
		}
	},

	remove() {
		this.clearBookingAutoCorrectionAdapter();
		this.removeCostCodesAdapter();
		BaseView.prototype.remove.call(this);
	},
});
