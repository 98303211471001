// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import maskTemplate from './mask.ejs';
import errorMessageTemplate from './error-message.ejs';
import $ from 'jquery';

let zIndex = 1;

const Popup = Widget.extend({

	template,

	errorMessageTemplate,

	events: {
		'click .b-popup__close': 'hide',
		'click .b-popup__print': 'print',
		'click .b-popup__action': 'action',
		'click .b-error-popup__more-in': 'selectErrorContent',
	},

	ui: {
		close: '.b-popup__close',
		content: '.b-popup__content',
	},

	initialize() {
		this.options = Object.assign({
			showPrintAction: false,
			closeOnlyOnAction: false,
			overlayFixed: false,
			title: '',
			type: '',
		}, this.options);

		this.initMask();
		this.render();
		this.el.view = this;
		this.container.append(this.$el);

		if (this.options.content) {
			this.ui.content.html(this.options.content);
		} else if (this.options.errorMessage) {
			this.ui.content.append(this.errorMessageTemplate({
				errorMessage: this.options.errorMessage,
				exceptionStackTrace: this.convertHtmlToText(this.options.exceptionStackTrace),
				exceptionMessage: this.convertHtmlToText(this.options.exceptionMessage),
				isUserError: (this.options.isUserError === true),
				isAdminError: (this.options.isAdminError === true),
			}));

			if (this.options.isUserError === true) {
				this.$el.addClass('is-user-error');
			} else {
				this.$el.removeClass('is-user-error');
			}

			if (this.options.isAdminError === true) {
				this.$el.addClass('is-admin-error');
			} else {
				this.$el.removeClass('is-admin-error');
			}
			if (this.options.isDeveloperError === true) {
				this.$el.addClass('is-developer-error');
			} else {
				this.$el.removeClass('is-developer-error');
			}
		} else {
			logger.error('No content for popup.', this.options);
		}
	},

	convertHtmlToText(text) {
		if (text == null) {
			return text;
		}

		const bodyContentMatch = /<body[^>]*?>([\s\S]*?)<\/body>/im.exec(text);
		if (bodyContentMatch != null) {
			text = bodyContentMatch[1];
		}

		return text
			.replace(/<style\b[^>]*?>[\s\S]*?<\/style>/ig, '')
			.replace(/<script\b[^>]*?>[\s\S]*?<\/script>/ig, '')
			.replace(/<noscript\b[^>]*?>[\s\S]*?<\/noscript>/ig, '')
			.replace(/<iframe\b[^>]*?>[\s\S]*?<\/iframe>/ig, '')
			.replace(/<object\b[^>]*?>[\s\S]*?<\/object>/ig, '')
			.replace(/<embed\b[^>]*?>[\s\S]*?<\/embed>/ig, '')
			.replace(/<!-{2}[\s\S]*?-{2}>/ig, '')
			.replace(/(<\/?(br|h[1-6]|p|hr|li|dd)\b[^>]*?\/?>)/ig, '\n')
			.replace(/<[^>]+?\/?>/ig, ' ')
			.replace(/\n{2,}/ig, '\n\n');
	},

	initMask() {
		if (!this.mask) {
			this.mask = $(maskTemplate({classes: this.options.maskClasses}));
			$('body').append(this.mask);
			if (!this.options.closeOnlyOnAction) {
				this.mask.find('.b-popup-mask-overlay').on('click', () => {
					this.hide();
				});
			}
		}
		this.container = this.mask.find('.b-popup-mask__cell');
	},

	// EVENTS
	selectErrorContent(e) {
		const element = e.currentTarget;
		if (document.createRange) {
			const range = document.createRange();
			const selection = window.getSelection();

			range.selectNode(element);
			selection.removeAllRanges();
			selection.addRange(range);
		} else if (document.body.createTextRange) {
			const range = document.body.createTextRange();

			range.moveToElementText(element);
			range.select();
		}

		document.execCommand('copy');
	},

	action(e) {
		const actionIndex = $(e.currentTarget).attr('data-id');
		this.options.actions[actionIndex].action($(e.currentTarget));
	},

	showDetails() {
		if (this.$el.hasClass('b-details__is-open')) {
			const copyLink = this.$el.find('.js-copy-text');

			this.$el.find('.b-error-popup__more-in').trigger('click');
			copyLink.tooltip({
				container: 'body',
				trigger: 'manual',
			}).tooltip('show');

			setTimeout(() => {
				copyLink.tooltip('hide');
			}, 2000);
		} else {
			this.$el.addClass('b-details__is-open');
			this.$el.find('.b-error-popup__more-wrapper')
				.stop()
				.slideDown();

			this.$el.find('.b-button-container__footer .b-popup__action:last-child')
				.addClass('js-copy-text')
				.attr('title', L10N.get('Widgets.popup.copyErrorMessage'))
				.find('span')
				.html(L10N.get('Widgets.popup.copy'));
		}
	},

	show() {
		STATE.hideLoader();
		if (!this.isVisible()) {
			zIndex++;
		}
		this.mask.addClass('visible');
		this.$el.addClass('visible');
		this.mask.find('.b-popup-mask-overlay').addClass('visible');
		this.$el.css({
			'z-index': zIndex,
		});

		if (this.options.overlayFixed === true) {
			$('body').addClass('b-popup-overlay-fixed');
		}
	},

	hide() {
		if (this.isVisible()) {
			zIndex--;
			this.$el.removeClass('visible');
			this.mask.removeClass('visible');
			if (this.options.onClose) {
				this.options.onClose();
			}
			setTimeout(() => {
				this.$el.remove();
				this.mask.remove();
			}, 5000);
		}
		if (this.options.onHideCallback != null) {
			this.options.onHideCallback();
		}
		$('body').removeClass('b-popup-print b-popup-overlay-fixed');
	},

	remove() {
		this.hide();
		Widget.prototype.remove.call(this);
	},

	isVisible() {
		return this.$el.hasClass('visible');
	},

	applyBinding(model) {
		this.model = model;
	},

	applyBindingModel(model) {
		this.model = model;
		this.bindWidgets();
	},

	print(e) {
		if (e) {
			e.preventDefault();
		}
		$('body').addClass('.b-popup-print');
		window.print();
	},

}, {
	hideAllPopups() {
		$('.b-popup').each((i, item) => {
			if (item.view) {
				item.view.remove();
			}
		});
		$('body').removeClass('b-popup-print b-popup-overlay-fixed');
	},

	showUnknownErrorPopup() {
		const errorPopup = new Popup({
			content: L10N.get('settings.unknownError'),
			title: L10N.get('settings.errorPopupTitle'),
			type: 'danger',
			actions: [{
				label: L10N.get('settings.errorPopupButton'),
				action: () => {
					errorPopup.hide();
				},
			}],
		});
		return errorPopup;
	},

	getErrorPopupType(type) {
		let output = '';
		switch (type) {
			case 'INFO':
				output = 'info';
				break;
			case 'WARNING':
				output = 'warning';
				break;
			case 'ERROR':
			default:
				output = 'danger';
				break;
		}
		return output;
	},

});

export default Popup;
