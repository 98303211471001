import React, { useCallback, useContext, useMemo, useState } from "react";
import {
	ReactAppGlobalContext,
	ReactUtils,
} from "../../../../../js/utils/react-utils";
import { PaginationContext } from "../Pagination";


enum PageSeparator {
	REDUCE = "REDUCE",
	REGULAR = "REGULAR",
}

interface Page {
	uid: string;
	caption?: number;
	pageNumber?: number;
	separator?: PageSeparator;
}

const Pages: React.FC<unknown> = () => {
  const { setCurrentPage = () => {}, currentPage = 0, pageSize = 10, totalCount = 0 } = useContext(PaginationContext);
	const { STATE } = useContext(ReactAppGlobalContext);
	const isMobile = STATE.checkViewport("(max-width: 768px)");

	const [spread, setSpread] = useState<number>(2);

	const addPage = useCallback(
		(
			pages: Page[],
			pageNumber: number | undefined = undefined,
			separator: PageSeparator | undefined
		) => {
			const isSpread = !!separator;
			pages.push({
				uid: `_${isSpread}_${pageNumber}_${pages.length}`,
				caption: pageNumber,
				pageNumber: pageNumber ? pageNumber - 1 : undefined,
				separator,
			});
		},
		[]
	);

	const pagesList = useMemo(() => {
		if (pageSize < 1 || totalCount <= pageSize) {
			return [];
		}

    const innerCurrentPage = currentPage + 1;

		const countPages = Math.ceil(totalCount / pageSize);

		if (isMobile && countPages >= 900 && spread !== 1) {
			setSpread(1);
		} else if (spread !== 2) {
			setSpread(2);
		}

		if (innerCurrentPage > countPages || innerCurrentPage < 1) {
			setCurrentPage(0);
		}

		let shiftStart = Math.max(innerCurrentPage - spread, 2);
		let shiftEnd = Math.min(innerCurrentPage + spread, countPages - 1);

		if (shiftEnd < spread * 2) {
			shiftEnd = Math.min(spread * 2, countPages - 1);
		}
		if (shiftEnd === countPages - 1 && shiftStart > 3) {
			shiftStart = Math.max(
				3,
				Math.min(countPages - spread * 2 + 1, shiftStart)
			);
		}

		const pages: Page[] = [];
		addPage(pages, 1, undefined);

		if (shiftStart === 3) {
			addPage(pages, 2, undefined);
		} else if (shiftStart > 3) {
			addPage(
				pages,
				undefined,
				isMobile && shiftEnd >= 900
					? PageSeparator.REDUCE
					: PageSeparator.REGULAR
			);
		}

		for (let i = shiftStart; i <= shiftEnd; i++) {
			addPage(pages, i, undefined);
		}

		const lastPage = countPages - 1;

		if (shiftEnd === lastPage - 1) {
			addPage(pages, lastPage, undefined);
		} else if (shiftEnd < lastPage) {
			addPage(
				pages,
				undefined,
				isMobile && shiftEnd >= 900
					? PageSeparator.REDUCE
					: PageSeparator.REGULAR
			);
		}

		addPage(pages, countPages, undefined);
		return pages;
	}, [pageSize, totalCount, currentPage, spread]);

	const drawSpreadElement = useCallback((separator?: PageSeparator) => {
		if (!separator) {
			return null;
		}
		if (separator === PageSeparator.REDUCE) {
			return (
				<div
					className="b-footer-page"
					style={{ minWidth: "15px", maxWidth: "15px" }}
				>
					.
				</div>
			);
		}
		return <div className="b-footer-page">...</div>;
	}, []);

	const handlePageChange = useCallback((page: Page) => {
		setCurrentPage(page.pageNumber || 0);
	}, []);

	return (
		<>
			{pagesList.map((page: Page) => {
				return (
					<React.Fragment key={page.uid}>
						{page.separator ? (
							drawSpreadElement(page.separator)
						) : (
							<div
								className={ReactUtils.classNames([
									"b-footer-page",
									currentPage === page.pageNumber ? "current" : undefined,
								])}
								onClick={() => handlePageChange(page)}
							>
								{page.caption}
							</div>
						)}
					</React.Fragment>
				);
			})}
		</>
	);
};

export default Pages;
