// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import axios from 'axios';
import $ from 'jquery';
import BaseView from '@/classes/base.view';
import TrainRouteView from '@/blocks/elements/b-train/b-train-route/index';
import PricingView from '@/blocks/elements/b-pricing/index';
import template from './template.ejs';

export default BaseView.extend({

	template,

	ui: {
		pricing: '.b-train-info__pricing',
	},

	events: {
		'click .b-train-route__link': 'showTrainRoute',
	},

	initialize(options) {
		this.bookingInfo = options.bookingInfo;
		this.pricingInfo = options.pricingInfo;
		this.travelPolicyCompliance = this.pricingInfo.travelPolicyCompliance;
		this.passengersDataView = options.passengersDataView;
		this.render();
	},

	addModelListeners() {
		const _updatePricing = this.updatePricing.bind(this);
		this.passengersDataView.model.get('travellers').each(t => {
			this.listenTo(t, 'change:tariff', _updatePricing);
			this.listenTo(t, 'change:nonRefundableTariff', _updatePricing);
			this.listenTo(t.get('costCodes'), 'change', _updatePricing);
		});
		this.listenTo(this.passengersDataView.model.get('travellers'), 'add reset remove', _updatePricing);
		this.listenTo(this.passengersDataView.model, 'updateTrainOptions', _updatePricing);
	},

	updatePricing() {
		this.bookingInfo.travellers = this.passengersDataView.model.get('travellers').toJSON();
		this.checkFullCompartment();
		axios.post('/midoffice/ibecorp-b2b/rail/pricing', {
			parameters: this.bookingInfo,
		}).then((result) => {
			this.pricingInfo = result.data.result;
			this.render();
		});
	},

	checkFullCompartment() {
		const {routeInfo} = this.bookingInfo;
		if (routeInfo.seatsRequest) {
			routeInfo.seatsRequest.fullCompartment = (routeInfo && routeInfo.train &&
				routeInfo.train._compartmentRequest && ['K', 'L'].includes(routeInfo.carriage.type.uid));
		}
	},

	render(attrs) {
		const params = Object.assign(
			{
				classes: '',
			},
			this.model ? this.model.attributes : {},
			this.options,
			attrs,
		);
		const container = this.$el;
		this.setElement(this.template(params));
		container.html(this.$el);

		const pricingView = new PricingView({
			price: this.pricingInfo.price,
			priceBack: this.pricingInfo.priceBack,
			totalPrice: this.pricingInfo.totalPrice,
		});
		this.ui.pricing.html(pricingView.$el);
	},

	showTrainRoute(e) {
		if (e) {
			e.preventDefault();
		}
		if (this.trainRouteView != null) {
			this.trainRouteView.remove();
		}
		const index = $(e.currentTarget).data('index');
		let train = null;
		if (index === 0) {
			train = this.bookingInfo.routeInfo.train;
		} else if (index === 1) {
			train = this.bookingInfo.routeInfoBack.train;
		} else {
			throw new Error(`Wrong train index`);
		}
		this.trainRouteView = new TrainRouteView({
			train,
		});
	},

});
