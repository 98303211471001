// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import 'corejs-typeahead/dist/typeahead.jquery';
import $ from 'jquery';

export default Widget.extend({

	template,

	events: {
		'click .js-select-language': 'selectLanguage',
		'click .js-change-language': 'changeLanguage',
	},

	configuration: null,

	initialize() {
		this.configuration = {
			selected: STATE.getLanguage(),
			languages: STATE.generalSettings.additionalLocales,
		};
		this.render();
	},

	changeLanguage(e) {
		const lang = e.currentTarget.lang;
		window.location.href = `${window.location.origin}${window.location.path ? window.location.path : ''}${STATE.getLocationSearch(lang)}#`;
	},

	selectLanguage() {
		this.$el.toggleClass('opened');
		if (this.$el.hasClass('opened')) {
			$(document).on('click.p-login__header-language', this.blurHandler.bind(this));
		} else {
			$(document).off('click.p-login__header-language');
		}
	},

	blurHandler(e) {
		const $languageContainer = $(e.target).parents('.p-login__header-language:first');
		if ($languageContainer.length === 0) {
			this.$el.removeClass('opened');
			$(document).off('click.p-login__header-language');
		}
	},

});
