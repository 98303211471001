module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


     var currencyCode = currencyCode || 'RUB';
     var travellers = travellers || [];
	 var travellerPrices = travellerPrices || {};
	 var totalPrice = totalPrice || '0';
	 var travellersServiceCount = travellersServiceCount || {};
	 var selectedTraveller = selectedTraveller || null;
;
__p += '\n\n<table class="b-travellers-prices">\n    <thead>\n    <tr class="b-travellers-prices__row-head">\n        <th class="b-travellers-prices__cell b-travellers-prices__cell-head">' +
((__t = ( L10N.get('cabinet.orders.hotelFullName') )) == null ? '' : __t) +
'</th>\n        <th class="b-travellers-prices__cell b-travellers-prices__cell-head">' +
((__t = ( L10N.get('cabinet.orders.serviceAmount') )) == null ? '' : __t) +
'</th>\n        <th class="b-travellers-prices__cell b-travellers-prices__cell-head">' +
((__t = ( L10N.get('cabinet.orders.sum') )) == null ? '' : __t) +
'</th>\n    </tr>\n    </thead>\n    <tbody>\n    ';
 _.each(travellers, function(traveller, travellerIndex) {
        var sum = travellerPrices[traveller.uid];
		var count = travellersServiceCount[traveller.uid];
		var active = selectedTraveller != null ? selectedTraveller === traveller.uid : travellerIndex === 0;
    ;
__p += '\n    <tr class="b-travellers-prices__row js-changeTraveller ' +
((__t = ( active ? 'active' : '' )) == null ? '' : __t) +
'" data-traveller-uid="' +
((__t = ( traveller.uid )) == null ? '' : __t) +
'" data-action="CHANGE_TRAVELLER">\n        <td class="b-travellers-prices__cell b-travellers-prices__fullName">' +
((__t = ( traveller.fullName )) == null ? '' : __t) +
'</td>\n        <td class="b-travellers-prices__cell b-travellers-prices__service-count js-traveller-service-count">' +
((__t = ( count || 0 )) == null ? '' : __t) +
'</td>\n        <td class="b-travellers-prices__cell b-travellers-prices__sum js-traveller-sum">\n            ' +
((__t = ( Formatter.formatMoney(sum || '0', Formatter.getDecimal(sum || '0')) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(currencyCode) )) == null ? '' : __t) +
'\n        </td>\n    </tr>\n    ';
 }) ;
__p += '\n    <tr class="b-travellers-prices__row">\n        <td class="b-travellers-prices__cell empty"></td>\n        <td class="b-travellers-prices__cell b-travellers-prices__total-caption">' +
((__t = ( L10N.get('pricing.total') )) == null ? '' : __t) +
':</td>\n        <td class="b-travellers-prices__cell b-travellers-prices__total-price">' +
((__t = ( Formatter.formatMoney(totalPrice, Formatter.getDecimal(totalPrice)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(currencyCode) )) == null ? '' : __t) +
'</td>\n    </tr>\n    </tbody>\n</table>\n';

}
return __p
}