module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<span class="b-finance-select ' +
((__t = (classes )) == null ? '' : __t) +
'">\n	<div class="b-finance-select__wrapper">\n		<div class="b-finance-select__label"></div>\n		<div class="b-finance-select__select">\n			<div class="b-finance-select__select-img"></div>\n		</div>\n	</div>\n	<div class="b-finance-select__popup">\n		 <span class="b-finance-select__selected"></span>\n		<input type="text" placeholder="' +
((__t = (L10N.get("cabinet.finance.documentType") )) == null ? '' : __t) +
'" class="b-finance-select__input">\n	</div>\n</span>\n';

}
return __p
}