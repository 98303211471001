// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import TravellerView from './b-transfer-traveller/index';
import template from './template.ejs';

export default BaseView.extend({

	template,

	ui: {
		travellersContainer: '.b-transfer-travellers__container',
		addTravellersButton: '.b-transfer-travellers__add',
	},

	events: {
		'click .b-transfer-travellers__add': 'addTraveller',
	},

	initialize() {
		if (this.model && this.model.get('travellers').length) {
			this.prevTravellers = this.model.get('travellers');
		}
		this.count = 0;
		this.travellerViewList = [];
		this.model.get('travellers').reset();
		const model = this.model.get('travellers').model;

		this.model.get('travellers').add(new model({
			type: {
				caption: L10N.get(`passengerTypes.ADULT`),
				uid: 'ADULT',
			},
		}));

		this.render();
	},

	adjustMobileTemplate(matches) {
		const transferRouteInfoView = this.options.baseView.transferRouteInfoView;
		const $pricing = transferRouteInfoView.ui.pricing;
		const $priceInfoContainer = this.options.parent.$el.find('.b-booking__price-info-container');
		const $tooltips = this.$('[data-toggle="tooltip"]');

		if (matches) {
			$priceInfoContainer.empty().append($pricing);
			_.each($tooltips, (el) => el && this.$(el).tooltip('disable'));
		} else {
			transferRouteInfoView.$el.append($pricing);
			_.each($tooltips, (el) => el && this.$(el).tooltip('enable'));
		}

		_.each(this.travellerViewList, (v) => v && v.adjustMobileTemplate(matches));

		return this;
	},

	render(...args) {
		BaseView.prototype.render.apply(this, args);

		const { parent, bookingSettings = {}, tripInfo = {} } = this.options;
		const {
			prevPassengerData,
			contactFillingType,
			contactPersonEditGranted,
			contactPersonDisableFreeTextInput,
			langRegistrationFullName,
		} = bookingSettings;

		const {travellersData = []} = args && args[0] || {};
		this.model.get('travellers').each((item, i) => {
			const traveller = _.extend(((prevPassengerData && prevPassengerData.travellers[i]) || bookingSettings.corporatePassengers[i]), travellersData[i]);
			const isContact = i === 0 && (contactFillingType || {}).uid === STATE.FILLING_TYPE.FIRST_TRAVELLER;
			const travellerView = new TravellerView({
				index: i,
				isContact,
				contactPersonEditGranted,
				contactPersonDisableFreeTextInput,
				bookingSettings,
				model: item,
				parentModel: this.model,
				passenger: traveller,
				parent: this,
				transferView: this.options.parent,
				langRegistrationFullName,
				token: tripInfo?.token,
			});
			this.travellerViewList.push(travellerView);
			this.ui.travellersContainer.append(travellerView.$el);

			const setContact = (passenger, model) => {
				if (passenger.get('isContact') && !_.isEmpty(model.changed)) {
					const customer = parent.model.get('customer');
					_.each(model.changed, (value, key) => {
						customer.set(key, value);
					});
				}
			};

			this.listenTo(item, 'change:phone change:email', (thisModel) => {
				setContact(item, thisModel);
				_.each(thisModel.changed, (value, key) => item.get('passport').set(key, value));
			});
			this.listenTo(item.get('passport'), 'change:lastName change:firstName change:phone change:email', (model) => {
				setContact(item, model);
			});

			const reprice = () => this.options.parent.trigger('reprice');

			const costCodesCallback = async () => {
				await parent.validateAsync.call(parent);
				reprice();
			};

			this.listenTo(item.get('costCodes'), 'change', costCodesCallback);
			this.listenTo(item, 'change:uid', reprice);

			this.listenTo(item, `change:isContact`, (model, value) => {
				if (!value) {
					// Сбрасываем personRef
					if (parent?.model && parent.model.get('customer') != null && parent.model.get('customer').get('personRef') != null) {
						parent.model.get('customer').set('personRef', undefined);
					}
					return;
				}

				this.model.get('travellers').each((otherModel) => {
					if (otherModel.cid !== model.cid) {
						otherModel.set('isContact', false);
					}
				});

				if (parent != null && parent.model != null) {
					const lastName = model.get('passport').get('lastName');

					parent.model.get('customer').set('lastName', lastName);
					parent.model.get('customer').set('firstName', model.get('passport').get('firstName'));
					parent.model.get('customer').set('phone', model.get('phone'));
					parent.model.get('customer').set('email', model.get('email'));
					if (lastName && model.get('uid')) {
						parent.model.get('customer').set('personRef', { uid: model.get('uid'), caption: `${lastName}`});
					}
				}
			});
		});
		this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));

		return this;
	},

	addTraveller(e) {
		if (e) e.preventDefault();
		const travellers = this.model.get('travellers');
		const model = travellers.model;
		const {capacity} = this.options.tripInfo;
		this.travellerViewList = [];
		travellers.add(new model({
			type: {
				caption: L10N.get(`passengerTypes.ADULT`),
				uid: 'ADULT',
			},
		}));
		const passengerAmount = travellers.length;

		this.options.tripInfo.travellersCount = passengerAmount;
		this.$el.remove();

		this.options.parent.ui.travellersContainer.append(this.render({travellersData: travellers.toJSON()}).$el);
		if (passengerAmount === capacity.uid) {
			this.ui.addTravellersButton.hide();
		}
	},

	removeTraveller(travellerIndex) {
		const travellers = this.model.get('travellers');
		travellers.remove(travellers.at(travellerIndex));
		this.travellerViewList = [];
		this.$el.remove();
		this.options.parent.ui.travellersContainer.append(this.render({travellersData: travellers.toJSON()}).$el);
	},

});
