module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-employees-collection">\n	<div class="l-grid-layout-33 b-employees-collection__content">\n		';
 if (!this.options.maxEmployees || this.collection.size() < this.options.maxEmployees) { ;
__p += '\n			<span class="b-employees-collection__add b-employees-collection__add--color-gray"></span>\n		';
 } ;
__p += '\n	</div>\n</div>\n\n';

}
return __p
}