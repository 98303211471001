// eslint-disable-next-line banned-modules
'use strict';

import BaseView from '@/classes/base.view';
import Backbone from 'backbone';
import template from './template.ejs';
import $ from 'jquery';

export default BaseView.extend({

	template,

	el: '.l-layout__main',

	ui: {
		content: '.l-layout__content-main',
		sidebar: '.l-layout__content-side',
		top: '.l-layout__top',
	},

	events: {
		'click .js-show-content-side': 'showContentSide',
	},

	layoutClasses: [
		'show-filters',
		'show-menu',
	],

	preinitialize(options) {
		BaseView.prototype.preinitialize.call(this, options);
	},

	constructor(options) {
		this.model = new Backbone.Model();
		this.listenTo(this.model, 'calculateContentSidePosition', () => {
			this.calculateContentSidePosition();
		});
		STATE.setPageView(this);
		BaseView.__super__.constructor.call(this, options);
	},

	getPageModel() {
		return this.model;
	},

	remove() {
		if (!this.$el.hasClass('l-wrapper')) {
			BaseView.prototype.remove.call(this);
			this.removeProcess = true;
			STATE.setPageViewRelatedViews(_.filter(STATE.getPageViewRelatedViews(), (item) => {
				if (item.pageView === this && item.view) item.view.remove();
				return false;
			}));
		}
	},

	showContentSide() {
		const $lLayout = $('.l-layout');
		const $btn = this.$('.js-show-content-side');
		const layoutClass = $btn.data('layoutClass');
		const isOpen = $lLayout.hasClass(layoutClass);
		const $side = $lLayout.find('.l-layout__content-side');
		if (isOpen) {
			$side.css('top', 'unset');
			$lLayout.removeClass(layoutClass);
		} else {
			this.calculateContentSidePosition($btn, $side);
			_.each(this.layoutClasses, (c) => {
				if (c === layoutClass) return;
				$lLayout.removeClass(c);
			});
			$lLayout.addClass(layoutClass);
		}
	},

	calculateContentSidePosition($btn = this.$('.js-show-content-side'), $side = $('.l-layout .l-layout__content-side')) {
		const btnPosition = $btn.position();
		$side.css('top', btnPosition.top + parseInt($btn.css('width') || 0));
	},

}, {

	load() {
		return Promise.resolve({});
	},

});
