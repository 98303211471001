// eslint-disable-next-line banned-modules
'use strict';

// T12N - Transliteration
class T12N {
	static getTransliterationMap() {
		// noinspection NonAsciiCharacters
		return {
			а: 'a',
			б: 'b',
			в: 'v',
			г: 'g',
			д: 'd',
			е: 'e',
			ё: 'e',
			ж: 'zh',
			з: 'z',
			и: 'i',
			й: 'i',
			к: 'k',
			л: 'l',
			м: 'm',
			н: 'n',
			о: 'o',
			п: 'p',
			р: 'r',
			с: 's',
			т: 't',
			у: 'u',
			ф: 'f',
			х: 'kh',
			ц: 'tc',
			ч: 'ch',
			ш: 'sh',
			щ: 'shch',
			ъ: '',
			ы: 'y',
			ь: '',
			э: 'e',
			ю: 'iu',
			я: 'ia',
			А: 'A',
			Б: 'B',
			В: 'V',
			Г: 'G',
			Д: 'D',
			Е: 'E',
			Ё: 'E',
			Ж: 'Zh',
			З: 'Z',
			И: 'I',
			Й: 'I',
			К: 'K',
			Л: 'L',
			М: 'M',
			Н: 'N',
			О: 'O',
			П: 'P',
			Р: 'R',
			С: 'S',
			Т: 'T',
			У: 'U',
			Ф: 'F',
			Х: 'KH',
			Ц: 'TC',
			Ч: 'CH',
			Ш: 'SH',
			Щ: 'SHCH',
			Ъ: '',
			Ы: 'Y',
			Ь: '',
			Э: 'E',
			Ю: 'IU',
			Я: 'IA',
		};
	}

	static testTransliterationWord(word) {
		if (!word || _.isEmpty(word)) return false;
		const map = T12N.getTransliterationMap();
		let result = false;
		_.each(word.split(''), (el) => {
			if (map.hasOwnProperty(el)) {
				result = true;
			}
		});
		return result;
	}

	static transliterate(word) {
		const map = T12N.getTransliterationMap();
		return _.map(word.split(''), (char) => map[char] || '').join('');
	}

	static transliterateName(name = '') {
		const [parts, delimiters] = this._getNameParts(name);
		return parts.map((namePart, namePartIndex) => {
			return `${this._capitalizeName(this.transliterate(namePart))}${delimiters[namePartIndex] || ''}`;
		}).join('');
	}

	static _getNameParts(name = '') {
		const delimiterChars = ['-', ' ', '.'];
		const parts = [];
		const delimiters = [];
		let wordStartIndex = 0;
		let characterIndex = 0;
		while (characterIndex < name.length) {    
			const char = name.charAt(characterIndex);    
			if (delimiterChars.includes(char)) {
				parts.push(name.substring(wordStartIndex, characterIndex));
				delimiters.push(char);
				wordStartIndex = characterIndex + 1;
			}
			if (name.length === characterIndex + 1) {
				parts.push(name.substring(wordStartIndex, name.length));
			}
			characterIndex++;
		}
		return [parts, delimiters];
	}

	static _capitalizeName(name = '') {
		return name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
	}
}

window.T12N = T12N;

// Fix for ProvidePlugin which properly works only with old module definition
/*eslint-disable */
// module.exports = T12N;
/*eslint-enable */

export default T12N;
