module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-train-search-form b-train-search">\n	<div class="b-train-search__row">\n		<div class="b-train-search__direction">\n			<span class="js-widget"  onclick=\'return new Widgets.Places({ bindingProperty: "departure", placeholder: L10N.get("searchForm.from") })\'></span>\n		</div>\n		<div class="b-train-search-swap">\n			<div class="b-train-search-swap__img"></div>\n		</div>\n		<div class="b-train-search__direction">\n			<span class="js-widget"  onclick=\'return new Widgets.Places({ bindingProperty: "arrival", placeholder: L10N.get("searchForm.to") })\'></span>\n		</div>\n		<div class="b-train-search__dates-container">\n			<div class="b-train-search__options-date-element b-train-search__options-date-element--type-dateto">\n				<span class="js-widget"  onclick=\'return new Widgets.Datepicker({ bindingProperty: "dateTo", dateFormat: "DD MMM", placeholder: L10N.get("searchForm.placeholderThereDate") })\'></span>\n			</div>\n			<div class="b-train-search__options-date-element b-train-search__options-date-element--type-datefrom">\n				<span class="js-widget"  onclick=\'return new Widgets.Datepicker({ bindingProperty: "dateFrom", dateFormat: "DD MMM", depend: "dateTo", dependTime: "timeTo", diff: 7, removable: true, placeholder: L10N.get("searchForm.placeholderBackDate") })\'></span>\n			</div>\n		</div>\n		<div class="b-train-search__button-container">\n			<div class="b-train-search__button">' +
((__t = ( L10N.get('trains.search') )) == null ? '' : __t) +
'</div>\n		</div>\n	</div>\n\n    <div class="b-train-search-mobile">\n        <div class="b-train-search-mobile__search-form">\n            <div class="b-train-search-mobile__route">\n                ' +
((__t = ( departure.caption )) == null ? '' : __t) +
' - ' +
((__t = ( arrival.caption )) == null ? '' : __t) +
'\n            </div>\n            <div class="b-train-search-mobile__route-info">\n                ' +
((__t = ( new Time(dateTo).toMonthDay() )) == null ? '' : __t) +
',\n                ';
 if (_.size(passengers)) { ;
__p += '\n                    ' +
((__t = ( _.size(passengers) )) == null ? '' : __t) +
' ' +
((__t = ( L10N.getPluralForm('searchForm.passenger', _.size(passengers)))) == null ? '' : __t) +
',\n                ';
 } else { ;
__p += '\n                    ';
 const passengersCount = _.reduce(Object.keys(passengersTypes.attributes), function (prev, acc) {
                        return prev + passengersTypes.attributes[acc];
                    }, 0); ;
__p += '\n                    ' +
((__t = ( passengersCount )) == null ? '' : __t) +
' ' +
((__t = ( L10N.getPluralForm('searchForm.passenger', passengersCount) )) == null ? '' : __t) +
',\n                ';
 } ;
__p += '\n                ' +
((__t = ( L10N.get('searchForm.' + classOfService.toLowerCase()).toLowerCase() )) == null ? '' : __t) +
'\n            </div>\n        </div>\n        <div class="b-train-search-mobile__filters-trigger"></div>\n    </div>\n    <div class="b-train-search-form-mobile__background"></div>\n</div>\n';

}
return __p
}