// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';
import View from './index';

const Model = BaseModel.extend({

	View,

	toJSON() {
		return this.get('emails');
	},

	initialize(data) {
		this.attributes = {
			emails: data,
		};
	},

});

export default Model;
