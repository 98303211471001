// eslint-disable-next-line banned-modules
'use strict';

import BasePageView from '@/pages/base.page-view';

import HotelsView from '@/blocks/pages/b-hotels/index';
import HotelsSearchFormView from '@/blocks/pages/b-hotels/b-hotels-search-form/index';
import FiltersView from '@/blocks/elements/b-filters/b-filters-hotels/index';
import SortingView from '@/blocks/elements/b-sorting/index';
import HotelsCategoriesView from '@/blocks/elements/b-hotel/b-hotel-categories/index';
import Gmaps from '@/blocks/utils/b-google-maps/index';
import SideClickOutsideAdapter from '@/blocks/utils/b-side-click-outside-adapter';
import axios from 'axios';
import $ from 'jquery';

export default BasePageView.extend({

	ui: {
		content: '.b-tickets-container',
		sidebar: '.l-layout__content-side',
		controls: '.l-layout__content-controls',
		top: '.l-layout__top',
	},

	preinitialize(options) {
		BasePageView.prototype.preinitialize.call(this, options);
		this.sideOutsideClickAdapter = new SideClickOutsideAdapter({
			applyHandlerOnStart: window.matchMedia('(min-width: 768px)').matches,
		});
		this.windowEventListenerList.push(
			{
				name: 'change',
				callback: this.sideOutsideClickAdapter.handleSideOutsideClickHandler,
				mediaQuery: '(min-width: 768px)',
				isMatchMedia: true,
			},
			{
				name: 'keyup',
				callback: this.contentSideEscHandler.bind(this),
			},
		);
	},

	initialize(options) {
		this.configuration = _.omit(options.results, ['hotelCards', 'restHotelToken', 'searchFinished']);

		this.results = options.results || {};
		this.showGdsAccountName = this.results.showGdsAccountName;
		this.hasTravelPolicy = (this.results.hasTravelPolicy === true);
		this.approvalAllowed = (this.results.approvalAllowed === true);
		this.issueAllowed = (this.results.issueAllowed === true);

		this.render();
		this.addEventListeners();
	},

	contentSideEscHandler(e) {
		if (e.code === 'Escape') {
			$('.l-layout').removeClass('show-filters');
		}
	},

	render(...args) {
		BasePageView.prototype.render.apply(this, args);
		if (!STATE.getIsFromHash()) {
			this.hotelsSearchFormView = new HotelsSearchFormView({ parent: this });
		}
		this.hotelsView = new HotelsView({ parent: this, results: this.results });

		this.ui.content.append(this.hotelsView.$el);
		this.ui.content.addClass('b-tickets-container-hotels');

		if (this.hotelsSearchFormView) this.ui.top.append(this.hotelsSearchFormView.$el);
		this.ui.controls.append(`<div class="l-content-table-title-hotels">${L10N.get('hotels.fullPrice')}</div>`);

		this.renderFilters();
		this.applyFilters();
	},

	renderFilters() {
		if (this.hotelsView == null) {
			return this;
		}

		if (this.filtersView) this.filtersView.remove();
		this.filtersView = new FiltersView({ models: this.hotelsView.models, hotelsView: this.hotelsView, parent: this });
		if (this.categoriesView) this.categoriesView.remove();
		this.categoriesView = new HotelsCategoriesView({ parent: this });

		if (this.sortingView) this.sortingView.remove();
		this.sortingView = new SortingView({
			container: this.ui.content.find('.b-hotels__results'),
			views: this.hotelsView.views,
			fakeRecommendedSort: true,
			defaultSortOption: this.configuration.searchResultSortType,
			hasTravelPolicy: this.hasTravelPolicy,
			type: 'HOTEL',
			renderCallback: (views) => {
				this.hotelsView.queueRender = _.filter(views, (view) => view.model.get('visibility') === true);
				this.filtersView.collection.trigger('beforeFilterCollection');
				this.hotelsView.renderFirstResults();
			},
		});

		this.filtersView.addSorting(this.sortingView);
		this.filtersView.addCategories(this.categoriesView);

		this.ui.sidebar.empty().append(this.filtersView.$el);

		return this;
	},

	applyFilters() {
		this.filtersView.applyFilters();
		if (this.hotelsView) {
			this.hotelsView.trigger('applyEventListeners');
		}
	},

	addEventListeners() {
		this.listenTo(this.getPageModel(), 'change:isFilteringResults', (model, v) => {
			this.ui.content[v ? 'addClass' : 'removeClass']('g-process2');
		});
	},

	remove() {
		if (this.sideOutsideClickAdapter) {
			this.sideOutsideClickAdapter.removeListener();
		}
		BasePageView.prototype.remove.call(this);
	},

}, {

	loadFromHash(hash) {
		STATE.showLoader();
		return new Promise(async (resolve) => {
			try {
				const attrs = {
					rooms: [],
					searchType: 'HOTEL',
				};

				STATE.checkHash(hash, 'HOTEL');
				STATE.getParsedHash(hash, attrs);

				const appSearchModel = STATE.getSearchModel();

				if (attrs.cityCode) {
					appSearchModel.set('cityCode', attrs.cityCode);
				}

				if (!_.isEmpty(attrs.rooms)) {
					appSearchModel.set('roomsCount', attrs.rooms.length);
					Object.keys(attrs.rooms[0].travellersInfo).forEach(key => {
						appSearchModel.get('passengersTypes').set(key, attrs.rooms[0].travellersInfo[key]);
					});
					if (attrs.rooms[0].childrenAge) {
						appSearchModel.set('childsAge', attrs.rooms[0].childrenAge);
					}
				}

				appSearchModel.set('checkInDate', attrs.checkInDate);
				appSearchModel.set('checkOutDate', attrs.checkOutDate);
				appSearchModel.get('serviceTypes').reset([{uid: attrs.searchType, caption: attrs.searchType}]);
				const params = appSearchModel.toJSON();
				STORE.set(STATE.ROUTES.MAIN_SEARCH, params.parameters, 14400);
				axios.get('/midoffice/ibecorp-b2b/hotels/searchPreferences')
					.then((p) => {
						const preferences = p.data.result;
						axios.post('/midoffice/ibecorp-b2b/hotels/searchOffers', params, appSearchModel)
							.then(result => {
								STATE.setHotelSearchResults(_.extend({}, result.data.result, preferences));
								STATE.removeHeader();
								resolve({fromHash: true});
							}).catch(e => {
								STORE.remove(STATE.ROUTES.TICKETS);
								logger.error(e);
								resolve();
							});
					});
			} catch (e) {
				logger.error(e);
				resolve();
			}
		});
	},

	async load(params) {
		if (window.google == null) {
			await Gmaps.getApiPromise();
		}

		return new Promise((resolve) => {
			const results = STATE.getHotelSearchResults();

			if (params && params.fromHash) {
				STATE.setIsFromHash(true);
				STATE.disableFirstStep();
			}

			if (_.isEmpty(results)) {
				STATE.navigate(STATE.ROUTES.INDEX);
				return;
			}

			if (results.order != null) {
				STATE.layout.header.setAdditionalOrder(results.order);
			}

			resolve({ results, initScrollTopButton: true });
		});
	},
});
