module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var gdsName = gdsName || '';
   var number = number || '';
   var title = title || '';
   var translatedTitle = translatedTitle || '';
   var translatedText = translatedText || '';
   var showTranslated = showTranslated || false;
;
__p += '\r\n<h2>' +
((__t = ( number )) == null ? '' : __t) +
'. ' +
((__t = ( showTranslated ? translatedTitle : title )) == null ? '' : __t) +
'</h2>\r\n\r\n';
 if (showTranslated) { ;
__p += '\r\n	<p>' +
((__t = ( translatedText && translatedText.split('\n').join('<br />') )) == null ? '' : __t) +
'</p>\r\n';
 	} else { ;
__p += '\r\n    <p>' +
((__t = ( text && text.split('\n').join('<br />') )) == null ? '' : __t) +
'</p>\r\n';
 } ;
__p += '\r\n<hr>\r\n';

}
return __p
}