module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var placeholderText = this.options.emailOnly ? L10N.get('AccountLoginForm.enterEmail') : L10N.get('AccountLoginForm.enterLoginOrEmail'); ;
__p += '\r\n<div class="p-login__form-block p-login__form-name">\r\n	';
 if (withLabel) { ;
__p += '\r\n		<div class="p-login__form-title p-login__form-name-title">' +
((__t = ( L10N.get('AccountLoginForm.accountName') )) == null ? '' : __t) +
'</div>\r\n	';
 } ;
__p += '\r\n	<input type="' +
((__t = ( type )) == null ? '' : __t) +
'" placeholder="' +
((__t = ( placeholderText )) == null ? '' : __t) +
'" class="p-login__form-input p-login__form-name-input ' +
((__t = ( classes )) == null ? '' : __t) +
'">\r\n</div>\r\n';

}
return __p
}