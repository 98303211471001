// eslint-disable-next-line banned-modules
'use strict';

import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import PassengerModel from './passenger-model';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';
import $ from 'jquery';

export default Widget.extend({

	validationTemplate,

	template,

	ui: {
		childAgeContent: '.b-passengers__child-age__content',
		childAge: '.b-passengers__child-age',
	},

	events: {
		click: 'setActive',
		'click .b-passengers__item-minus': 'minus',
		'click .b-passengers__item-plus': 'plus',
		'click .b-passengers__wrapper': 'open',
		'change .b-passengers__child-age__item-input': 'updatechildAges',
	},

	initialize() {
		this.render({
			types: [
				{
					uid: 'ADULT',
					caption: L10N.get('Widgets.railways.ADULT'),
				},
				{
					uid: 'CHILD',
					caption: L10N.get('Widgets.railways.CHILD'),
				},
				{
					uid: 'INFANT',
					caption: L10N.get('Widgets.railways.INFANT'),
				},
			],
		});
	},

	updatechildAges() {
		const arr = [];
		this.$('.b-passengers__child-age__item-input').each((i, item) => {
			if ($(item).val() !== '') {
				arr.push($(item).val());
			}
		});
		STATE.getSearchModel().set('childsAge', arr);
		this.isActive = false;
	},

	setActive() {
		this.isActive = true;
	},

	open() {
		this.$el.toggleClass('open');
		this.$('.b-passengers__validation').toggleClass('b-passengers__validation-visible');
		$('html').on('click.passengers', () => {
			if (!this.isActive) {
				this.$el.removeClass('open');
				this.$('.b-passengers__validation').removeClass('b-passengers__validation-visible');
				$('html').off('click.passengers');
			}
			this.isActive = false;
		});
	},

	updateLabel() {
		const count = Object.keys(PassengerModel.prototype.defaults())
			.reduce((result, item) => result + parseInt(this.model.get(item)) || 0, 0);
		this.$('.b-passengers__input')
			.text(`${count} ${L10N.getPluralForm('searchForm.passenger', count)}`);
	},

	minus(e) {
		const parent = $(e.currentTarget).parent();
		this.model.minus(parent.attr('data-type'));

		this.$el.find('.b-passengers__item-plus').removeClass('tooltiped').tooltip('dispose');
	},

	plus(e) {
		const parent = $(e.currentTarget).parent();
		const type = parent.attr('data-type');
		const result = this.model.plus(type);

		const $button = $(parent).find('.b-passengers__item-plus');
		if (type === 'INFANT' && parseInt(this.model.get('ADULT')) === 1) {
			const title = `<div>${L10N.get('Widgets.railways.additionalInfantMessage')}</div>`;

			if (result !== true && !$button.hasClass('tooltiped')) {
				$button.addClass('tooltiped').tooltip({
					container: 'body',
					html: true,
					title,
				}).on('mouseleave', () => $button.tooltip('hide'));
				$button.tooltip('show');
			}
		} else {
			this.$el.find('.b-passengers__item-plus').removeClass('tooltiped').tooltip('dispose');
		}
	},

	applyBinding() {
		this.model = STATE.getSearchModel().get('passengersTypes');

		const total = Object.keys(this.model.defaults())
			.reduce((result, item) => result + parseInt(this.model.get(item)), 0);

		if (total > 4 || parseInt(this.model.get('INFANT')) > parseInt(this.model.get('ADULT'))) {
			this.model.set('ADULT', 1);
			this.model.set('INFANT', 0);
			this.model.set('CHILD', 0);
		}

		_.each(PassengerModel.prototype.defaults(), (count, type) => {
			const item = this.$el.find(`[data-type=${type}]`);
			const itemValue = item.find('.b-passengers__item-value');
			this.model.on(`change:${type}`, (model, value) => {
				itemValue.text(value);
				this.updateLabel();
			});
			this.model.on(`validationError:${type}`, (errors) => {
				this.showValidation(errors);
			});

			itemValue.text(this.model.get(type));
		});
		this.updateLabel();
		for (let i = 0; i < 9; i++) {
			this.listenTo(STATE.getSearchModel(), `validationError:childsAge.${i}`, (errors) => {
				this.showValidation(errors);
				this.$('.b-passengers__child-age__item-input').eq(i).addClass('validation-error');
			});
		}
	},

});
