// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

import $ from 'jquery';

export default BaseView.extend({

	template,

	events: {
		'click .b-addition-orders__item-add': 'setAdditionalOrder',
		'click .b-addition-orders__item-add-caption': 'setAdditionalOrder',
		'click .b-addition-orders__item-number': 'navigateOrder',
	},

	initialize() {
		this.options = _.extend({
			orders: [],
		}, this.options);

		this.model = this.options.model;
		if (_.size(this.options.orders) > 0) {
			_.each(this.options.orders, this.groupOrderReservationInfosByTravelSubject);
			this.render();
		}
	},

	setAdditionalOrder(e) {
		if (e != null) {
			e.preventDefault();
		}

		const $target = $(e.currentTarget);
		const bookingUid = $target.attr('data-booking-uid');

		const order = _.find(this.options.orders, (el) => _.isObject(el) && el.booking.uid === bookingUid);

		if (order != null) {
			this.model.set('booking', {
				number: order.booking.caption,
				bookingUid,
			});
			this.model.trigger('scrollTop');
		}
	},

	groupOrderReservationInfosByTravelSubject(order) {
		if (!(order.reservationInfos && order.reservationInfos.length > 9)) return;

		const groupByTravelSubject = {};
		const combinedStatuses = {};

		order.reservationInfos.forEach((item) => {
			item.skipIcon = true;
			if (item.travelSubject && item.travelSubject.uid) {
				if (!groupByTravelSubject[item.travelSubject.uid]) groupByTravelSubject[item.travelSubject.uid] = [];
				groupByTravelSubject[item.travelSubject.uid].push(item);
			}
		});

		_.keys(groupByTravelSubject)
			.forEach((key) => {
				if (!combinedStatuses[key]) combinedStatuses[key] = [];

				combinedStatuses[key] = _.reduce(groupByTravelSubject[key], (result, next, i) => {
					if (_.isEmpty(next.status)) return result;
					if (!result.statuses) result.statuses = {};
					if (!result.statuses[next.status.uid]) result.statuses[next.status.uid] = [];

					result.statuses[next.status.uid].push(next.status);
					if (i === groupByTravelSubject[key].length - 1) {
						_.keys(result.statuses)
							.forEach((k) => {
								if (!result.statuses[k] || !result.statuses[k].length) return;
								result.statuses[k] = `${result.statuses[k][0].caption} x${result.statuses[k].length}`;
							});
					}
					return result;
				}, {});

				let index = 0;
				let result = order.reservationInfos.find((item, i) => {
					if (item.travelSubject && item.travelSubject.uid === key) {
						index = i;
						return true;
					}
					return false;
				});
				result = {
					...result,
					statuses: combinedStatuses[key].statuses,
					skipIcon: false,
				};
				order.reservationInfos[index] = result;
			});
	},

	navigateOrder(e) {
		if (e != null) {
			e.preventDefault();
		}

		const $target = $(e.currentTarget);
		const bookingUid = $target.attr('data-booking-uid');

		if (bookingUid) {
			STATE.navigate([STATE.ROUTES.CABINET_ORDER, bookingUid].join('/'));
		}
	},

});
