module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-transfer-pricing">\n    <header class="b-transfer-pricing__header">' +
((__t = ( L10N.get("pricing.title") )) == null ? '' : __t) +
'</header>\n    ';

        var totalPrice = totalPrice || {};
        var additionalServices = additionalServices || [];
		var currencyCode = totalPrice.currencyCode || 'RUB';

		function renderAncillaryFees(fees) {
			var template = '';
			_.each(fees, function(item) {
				const price = item.price
				template += '<div class="b-transfer-pservice__element">';
				template +=    '<span class="b-transfer-pservice__element-caption">' + item.caption + '</span>';
				template +=    '<span class="b-transfer-pservice__element-p">';
				template +=       item.number + 'X' + Formatter.formatMoney(price.amount, Formatter.getDecimal(price.amount)) + Formatter.formatCurrency(price.currencyCode);
				template +=    '</span>';
				template += '</div>'
            });
			return template;
        }

    _.each(prices, function (p, i) {
    if (p == null) return;
    if (prices.length > 1) { ;
__p += '\n        <div class="b-transfer-pricing__direction">' +
((__t = ( (i === 0) ? L10N.get("trains.there") : L10N.get("trains.backward") )) == null ? '' : __t) +
'</div>\n    ';
 } ;
__p += '\n    <div class="b-transfer-pricing__services">\n        <div class="b-transfer-pricing__service b-transfer-pservice">\n            ';

                var types = ['tariff', 'fee', 'taxes', 'techFees', 'supplierFees', 'agencyFees', 'feeAndTaxes', 'penalty', 'discount', 'ancillaryFees'];

                p.passengerPrices = _.toArray(p.passengerPrices).sort(function(a,b) {return a.passengerType.uid > b.passengerType.uid});


                _.each(p.passengerPrices, function(value) {
                    var passengerPrice = _.extend({}, value, value.feeDetails);

                    _.each(types, function (type) {
                        var priceValue = passengerPrice[type];
                        if (priceValue != null && priceValue.amount != null && parseFloat(priceValue.amount) !== 0) { ;
__p += '\n                            <div class="b-transfer-pservice__element">\n                                <span class="b-transfer-pservice__element-caption">' +
((__t = ( L10N.get("pricing." + type) )) == null ? '' : __t) +
'</span>\n                                <span class="b-transfer-pservice__element-p">\n                                    ' +
((__t = ( Formatter.formatMoney(priceValue.amount, Formatter.getDecimal(priceValue.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(priceValue.currencyCode) )) == null ? '' : __t) +
'\n                                </span>\n                            </div>\n                        ';
 } ;
__p += '\n\n						';
 if (type === 'ancillaryFees') { ;
__p += '\n                            ' +
((__t = ( renderAncillaryFees(passengerPrice[type]) )) == null ? '' : __t) +
'\n                        ';
 } ;
__p += '\n                    ';
 }); ;
__p += '\n                ';
 }); ;
__p += '\n        </div>\n    </div>\n    ';
 }); ;
__p += '\n\n    <div class="b-transfer-pservice__element b-transfer-pricing__total">\n        <div class="b-transfer-pservice__element-caption">' +
((__t = ( L10N.get('pricing.total') )) == null ? '' : __t) +
'</div>\n        <div class="b-transfer-pservice__element-p">\n            ' +
((__t = ( Formatter.formatMoney(totalPrice.amount, Formatter.getDecimal(totalPrice.amount)) )) == null ? '' : __t) +
'\n            ' +
((__t = ( Formatter.formatCurrency(totalPrice.currencyCode) )) == null ? '' : __t) +
'\n        </div>\n    </div>\n</div>\n\n\n\n';

}
return __p
}