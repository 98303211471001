// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import suggestionTemplate from './suggestion.ejs';
import 'corejs-typeahead/dist/typeahead.jquery';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';
import axios from 'axios';

export default Widget.extend({

	validationTemplate,

	template,

	viewName: 'p-places',

	el: 'div',

	events: {
		'click input': 'selectInput',
		'click .p-search__direction-suggest': 'selectInput',
	},

	initialize(options) {
		this.queryHandler = this.queryHandler.bind(this);
		this.options = options;
		this.searchTypeFromModel = 'COMBINED';
		this.render();
		this.$typeahead = this.getElements('input');
		this.$typeahead.typeahead(
			{
				hint: false,
				highlight: false,
				minLength: 0,
				limit: 1,
				classNames: {
					menu: 'b-places__items',
					wrapper: 'b-places__wrapper',
					input: 'b-places__input',
					hint: 'b-places__hint',
					dataset: 'b-places__dataset',
					suggestion: 'b-places__item',
					selectable: 'b-places__selectable',
					empty: 'b-places__empty',
					open: 'b-places__open',
					cursor: 'b-places__cursor',
					highlight: 'b-places__highlight',
				},
			},
			{
				name: 'locations',
				limit: 21,
				source: (q, cb, async) => {
					clearTimeout(this.timer);
					this.timer = setTimeout(this.queryHandler, STATE.QUERY_DELAY, q, cb, async);
				},
				display: (item) => item.caption,
				async: true,
				templates: {
					suggestion: suggestionTemplate.bind(this),
				},
			}).on('typeahead:select', (ev, suggestion) => {
			this.getElements('code').text(suggestion.iataCode);
		});
	},

	queryHandler(q, cb, async) {
		const serviceTypes = STATE.getSearchModel().get('serviceTypes');
		if (serviceTypes.size() === 1) {
			this.searchTypeFromModel = serviceTypes.at(0).get('uid');
		}

		axios.post('/midoffice/ibecorp-b2b/autocomplete/getLocations', {
			parameters: {
				pattern: q,
				searchType: this.searchTypeFromModel,
				limit: 20,
			},
		}).then((resp) => {
			const result = [];
			const {locations} = resp.data.result;

			_.each(locations, (item) => {
				result.push({
					uid: item.code,
					caption: item.caption,
					cityCaption: item.caption,
					iataCode: item.code,
				});
			});
			async(result);
		});
	},

	selectInput() {
		this.$typeahead[0].select();
	},

	applyBinding(model) {
		this.$typeahead.on('typeahead:select', (ev, suggestion) => {
			model.set(this.options.bindingProperty, {
				uid: suggestion.uid,
				caption: `${suggestion.cityCaption}`,
				iataCode: suggestion.iataCode,
			});
		});

		this.$typeahead.on('typeahead:change', () => {
			const item = model.get(this.options.bindingProperty);
			if (item != null) {
				this.$typeahead.typeahead('val', item.caption);
				this.getElements('code').text(item.iataCode || '');
			}
		});

		// Listen change service type for reset dropdown list
		this.listenTo(STATE.getSearchModel().get('serviceTypes'), `add`, () => {
			if (this.$typeahead.data('ttTypeahead') != null) {
				this.$typeahead.data('ttTypeahead').menu.query = null;
				this.$typeahead.data('ttTypeahead').menu.update('');
			}
		});

		this.listenTo(model, `validationError:${this.options.bindingProperty}`, () => {
			this.$el.addClass('validation-error');
		});

		this.listenTo(model, `change:${this.options.bindingProperty}`, (__model, item) => {
			if (_.isObject(item)) {
				this.$typeahead.typeahead('val', item.caption);
				this.getElements('code').text(item.iataCode || '');
			}
		});

		const value = model.get(this.options.bindingProperty);
		if (!_.isEmpty(value)) {
			this.$typeahead.typeahead('val', value.caption);
			this.$typeahead.trigger('typeahead:select', {
				uid: value.uid,
				cityCaption: `${value.caption}`,
				iataCode: value.iataCode,
			});
		}
	},

	getCaption(item) {
		return item.uid;
	},
});
