module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-cabinet-wrapper b-cabinet-menu">\r\n    <div class="b-cabinet">\r\n        <div class="b-cabinet__title">\r\n            <p class="b-cabinet__title-text">' +
((__t = ( L10N.get('loginForm.loginTitle') )) == null ? '' : __t) +
'</p>\r\n        </div>\r\n        <div class="b-cabinet__profile"></div>\r\n        <div class="b-cabinet__additional-client">\r\n            <div class="js-container"></div>\r\n        </div>\r\n        <div class="b-cabinet__additional-order"></div>\r\n        <div class="b-cabinet__info-currency"></div>\r\n        ';
 if (STATE.checkSiteType('B2C') && STATE.getLoggedInUser() == null) { ;
__p += '\r\n            <div class="b-cabinet__login-container js-login-container"></div>\r\n        ';
 } else { ;
__p += '\r\n            <div class="b-cabinet__sections">\r\n                ';
 this.sections.forEach(function(el) { ;
__p += '\r\n                ';
el.iconClassName = el.iconClassName || ''; ;
__p += '\r\n                ';
el.classes = el.classes || ''; ;
__p += '\r\n                <span class="js-widget"  onclick=\'return new Widgets.Section({sectionName: "' + el.title + '", sectionDisable: "'+el.disable+'", sectionCurrent: "'+el.current+'", path: "'+el.path+'", target: "'+el.target+'", iconClassName: "'+el.iconClassName+'", classes: "'+ el.classes +'"})\'></span>\r\n                ';
 }) ;
__p += '\r\n            </div>\r\n        ';
 } ;
__p += '\r\n    </div>\r\n    <div class="b-cabinet__action-button js-logout dn">\r\n        <div class="b-cabinet__action-icon g-icon-exit"></div>\r\n        <p class="b-cabinet__action-text">\r\n            ' +
((__t = ( L10N.get('loginForm.logoutButton') )) == null ? '' : __t) +
'\r\n        </p>\r\n    </div>\r\n    ';
 if (STATE.checkSiteType('B2C')) { ;
__p += '\r\n        <div class="b-cabinet__action-button-container js-login-button-container is-b2c">\r\n            <button class="b-cabinet__action-button js-register dn" disabled="true">\r\n                <p class="b-cabinet__action-text">\r\n                    ' +
((__t = ( L10N.get('AccountLoginForm.buttons.register') )) == null ? '' : __t) +
'\r\n                </p>\r\n            </button>\r\n            <button class="b-cabinet__action-button js-restore dn" disabled="true">\r\n                <p class="b-cabinet__action-text">\r\n                    ' +
((__t = ( L10N.get('AccountLoginForm.buttons.sendRecovery') )) == null ? '' : __t) +
'\r\n                </p>\r\n            </button>\r\n            <button class="b-cabinet__action-button js-sign-in dn" disabled="true">\r\n                <p class="b-cabinet__action-text">\r\n                    ' +
((__t = ( L10N.get('AccountLoginForm.buttons.signIn') )) == null ? '' : __t) +
'\r\n                </p>\r\n            </button>\r\n        </div>\r\n    ';
 } ;
__p += '\r\n</div>\r\n';

}
return __p
}