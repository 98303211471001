import { Moment } from "moment";

export interface NewsItem {
	uid: string;
	title?: string;
	created?: Moment;
	read?: boolean;
	important?: boolean;
}

export interface NewsSearchResponse {
	totalCount: number;
	notices: NewsItem[];
}

export enum SearchFormStatusEnum {
	"ALL" = "ALL",
	"IMPORTANT" = "IMPORTANT",
	"UNREAD" = "UNREAD",
}
