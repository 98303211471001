module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-cabinet-collection-small">\r\n	<div class="l-grid-container">\r\n		<div class="l-grid-layout-33 b-cabinet-collection__content">\r\n			';
 if (!obj.collection || obj.collection.size() == 0) { ;
__p += '\r\n				<span class="b-input__label">' +
((__t = (obj.title)) == null ? '' : __t) +
'</span>\r\n			';
 } ;
__p += '\r\n			<span class="b-cabinet-collection__add"></span>\r\n		</div>\r\n	</div>\r\n</div>\r\n';

}
return __p
}