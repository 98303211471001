'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

import axios from 'axios';

export default BaseView.extend({

	el: 'body',

	template,

	events: {
		'click .js-exchange': 'exchange',
		'click .js-cancel': 'close',
	},

	initialize(options) {
		this.options = options;
		this.reservation = options.exchangeParameters.reservation;
		this.selectedFlight = options.selectedFlight;
		this.flightToken = options.flightToken;
		this.exchangeParameters = options.exchangeParameters;
		this.parent = options.parent;

		this.render();
	},

	render(args) {
		BaseView.prototype.render.apply(this, args);

		return this;
	},

	exchange(e) {
		if (e != null) {
			e.preventDefault();
		}
		const {parameters} = this.exchangeParameters;
		const params = {
			parameters: {
				booking: parameters.booking,
				route: parameters.route,
				productUids: parameters.productUids,
				flightToken: this.flightToken,
			},
		};
		STATE.showLoader();
		this.disableElements(e);
		axios.post('/midoffice/ibecorp-b2b/booking/exchange', params).then((res) => {
			if (res.data.result.flights) {
				this.parent.parent.result = {
					flights: _.map(res.data.result.flights, (item) => {
						item.flightItem.flight.selected = item.selected;
						return {
							flight: item.flightItem.flight,
							flightToken: item.flightItem.flightToken,
							hasExtendedFare: item.flightItem.hasExtendedFare,
						};
					}),
					bestPrices: [],
				};
				this.parent.parent.renderTickets();
				this.close();
			} else {
				STATE.navigate([STATE.ROUTES.CABINET_ORDER, parameters.booking.uid].join('/'));
				this.close();
			}
		}).catch(() => this.close());
	},

	close(e) {
		if (e) e.preventDefault();
		this.$el.remove();
		this.destroy();
	},

});
