// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';
import BaseCollection from '@/classes/base.collection';
import TravellerModel from '@/blocks/elements/b-transfer/b-transfer-travellers/b-transfer-traveller/model';
import TripModel from '@/blocks/elements/b-transfer/b-transfer-ride-details/model';
import AdditionalServicesModel from '@/blocks/elements/b-transfer/b-transfer-additional-services/model';

export default BaseModel.extend({

	defaults() {
		return {
			customer: new BaseModel({
				phone: {
					cityCode: '',
					countryCode: STATE.getDefaultCountryCode(),
					number: '',
				},
			}),
			travellers: new BaseCollection([], {
				model: TravellerModel,
			}),
			trips: new BaseCollection([], {
				model: TripModel,
			}),
			additionalServices: new AdditionalServicesModel(),
		};
	},

	toJSON() {
		const data = BaseModel.prototype.toJSON.call(this);

		if (!_.isEmpty(data.customer)) {
			if (data.customer.overrideCustomer === true) data.overrideCustomer = true;

			delete data.customer.disabled;
			delete data.customer.overrideCustomer;
		}
		data.travellersCount = data.travellers.length;

		_.each(data.trips, (trip, i) => {
			if (i === 0) {
				trip.start.address = trip.location.start;
				trip.end.address = trip.location.end;
			} else {
				trip.start.address = trip.location.end;
				trip.end.address = trip.location.start;
			}
			delete trip.isRoundTrip;
			delete trip.location;
			delete trip.date;
			if (trip.travellersCount) {
				data.travellersCount = trip.travellersCount.uid;
				delete trip.travellersCount;
			}
			if (!data.additionalServices || data.additionalServices.types[i] == null) {
				trip.additionalServiceTypes = [];
				return;
			}
			if (!_.isEmpty(data.additionalServices.tableCaptions)) {
				if (data.additionalServices.tableCaptions[i] == null && data.travellers[0] != null) {
					const caption = `${data.travellers[0].lastName || ''} ${data.travellers[0].firstName || ''}`;
					data.additionalServices.tableCaptions[i] = caption;
				}
				trip.tableCaption = data.additionalServices.tableCaptions[i];
			}
			trip.additionalServiceTypes = data.additionalServices.types[i];
		});

		delete data.additionalServices;

		return {
			parameters: data,
		};
	},

});
