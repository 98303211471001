module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<label class="b-radiobutton ' +
((__t = (classes )) == null ? '' : __t) +
'">\r\n	<input type="radio" class="b-radiobutton__input" name="' +
((__t = (bindingProperty)) == null ? '' : __t) +
'" value="' +
((__t = (value )) == null ? '' : __t) +
'">\r\n	<i class="b-radiobutton__view"></i>\r\n	<span class="b-radiobutton__label">' +
((__t = (label)) == null ? '' : __t) +
'</span>\r\n</label>\r\n';

}
return __p
}