module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<span class="validations-errors__container">\r\n	';
 _.each(errors, function (value) { ;
__p += '\r\n		<span class="validations-errors__item">' +
((__t = (value.text)) == null ? '' : __t) +
'</span>\r\n	';
 }) ;
__p += '\r\n</span>\r\n';

}
return __p
}