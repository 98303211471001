import "./style.less";
import React, { useEffect } from "react";
import Datepicker from "../Datepicker/Datepicker";
import { FormItemControlProps } from "../FormItem/helpers/interfaces";
import moment from "moment";

interface DatepickerRangeProps extends FormItemControlProps {
	label?: string;
	classes?: string;
	placeholder?: string;
	pikadayModalCid?: string;
	dateFormat?: string;
	noMinDate?: boolean;
	minDate?: Date;
	depend?: boolean;
	dependOffset?: string;
	showDaysInNextAndPreviousMonths?: boolean;
	numberOfMonths?: number;
	bound?: boolean;
	keyboardInput?: boolean;
	allowClear?: boolean;
}

const DatepickerRange: React.FC<DatepickerRangeProps> = (props) => {
	const {
		value = [undefined, undefined],
		onChange,
		label,
		...restProps
	} = props;

	const [startDate, endDate] = value;

	// reset end date is after is after then before
	useEffect(() => {
		if (startDate && endDate) {
			if (moment(startDate).isAfter(moment(endDate))) {
				value[1] = undefined;
				onChange && onChange(value);
			}
		}
	}, [startDate, endDate]);

	return (
		<div className="r-datepicker-range">
			<div className="b-block-container__element b-block-container__element-dates">
				<div className="p-search__options-date p-search__options-date-with-label date-30">
					<div className="p-search__options-date-element">
						{label && (
							<div className="p-search__options-date-label">{label}</div>
						)}
						<Datepicker
							value={value[0]}
							onSelect={(date?: Date) => {
								if (onChange) {
									value[0] = date;
									onChange(value);
								}
							}}
							{...restProps}
						/>
					</div>
					<div className="p-search__options-date-element">
						<div
							className="p-search__options-date-label"
							style={{ visibility: "hidden" }}
						>
							&nbsp;
						</div>
						<Datepicker
							value={value[1]}
							onSelect={(date?: Date) => {
								if (onChange) {
									value[1] = date;
									onChange(value);
								}
							}}
							minDate={startDate}
							{...restProps}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DatepickerRange;

{
	/* <div class="b-block-container__element b-block-container__element-dates">
					<div class="p-search__options-date p-search__options-date-with-label date-30">
						<div class="p-search__options-date-element">
							<div class="p-search__options-date-label"><%= L10N.get('cabinet.products.operationDate') %></div>
							<%@ Datepicker({ bindingProperty: "startDate", dateFormat: "DD MMM YYYY", removable: true, noMinDate: true, placeholder: L10N.get("searchForm.departureDate") })%>
						</div>
						<div class="p-search__options-date-element">
							<div class="p-search__options-date-label" style="visibility: hidden"><%= L10N.get('cabinet.products.operationDate') %></div>
							<div class="p-search__bridge"></div>
							<%@ Datepicker({ bindingProperty: "endDate", dateFormat: "DD MMM YYYY", noMinDate: true, depend: "startDate", diff: 7, removable: true, placeholder: L10N.get("searchForm.departureDate") })%>
						</div>
					</div>
				</div> */
}
