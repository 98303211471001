module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<span class="b-railway-carriers-input b-input ' +
((__t = (classes )) == null ? '' : __t) +
'">\n	<span class="b-input__label">' +
((__t = (label )) == null ? '' : __t) +
'</span>\n	<input type="text" placeholder="' +
((__t = (L10N.get('searchForm.selectRailwayCarrier') )) == null ? '' : __t) +
'" class="b-railway-carriers-input__input b-input__value" />\n</span>\n';

}
return __p
}