// eslint-disable-next-line banned-modules
'use strict';

import $ from 'jquery';
import PopupWidget from '@/widgets/b-popup/index';

class BookingDuplicatesMsgHandler {
	popup = null;

	/**
	 * Handles the search duplicates message.
	 *
	 * @param {Object} params - The parameters for the function.
	 * @param {string} [params.title] - The title of the popup.
	 * @param {string} [params.text] - The content of the popup.
	 * @param {string} [params.cancelBtnText] - The label of the cancel button.
	 * @param {string} [params.okBtnText] - The label of the ok button.
	 * @param {function} [params.onOk] - The callback function when the ok button is clicked.
	 * @param {function} [params.onCancel] - The callback function when the cancel button is clicked.
	 * @param {function} [params.onClose] - The callback function when the popup is closed.
	 */
	showPopup = (params = {}) => {
		const {
			closeOnlyOnAction = true,
			title = L10N.get('bookingForm.dupesPopup.orderTitle'),
			text,
			cancelBtnText = L10N.get('bookingForm.dupesPopup.back'),
			okBtnText = L10N.get('Common.agree'),
			onOk,
			onCancel,
			onClose,
		} = params;

		if (this.popup) this.removePopup();

		this.popup = new PopupWidget({
			title,
			content: text || '',
			type: 'info',
			closeOnlyOnAction,
			actions: [
				{
					label: cancelBtnText,
					action: () => {
						onCancel?.();
						if (this.popup) this.popup.hide();
					},
				},
				{
					label: okBtnText,
					action: () => {
						onOk?.();
						if (this.popup) this.popup.hide();
					},
				},
			],
			onClose: () => {
				onClose?.();
				if (this.popup) this.popup.hide();
			},
		});
		this.popup.show();
		_.each($(`.b-popup__content a`), (a) => $(a).on('click', () => {
			if (this.popup) this.popup.hide();
		}),
		);
	};

	removePopup() {
		if (this.popup) {
			this.popup.remove();
			this.popup = null;
		}
	}
}

export default BookingDuplicatesMsgHandler;
