module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-filters__line ' +
((__t = ( classes )) == null ? '' : __t) +
'">\r\n	<div class="b-filters__block b-selection">\r\n		<span class="b-selection__label">' +
((__t = ( label )) == null ? '' : __t) +
'</span>\r\n		<div class="b-selection__values">\r\n\r\n		</div>\r\n		<a href="#" class="b-selection__open">' +
((__t = ( showAll )) == null ? '' : __t) +
'</a>\r\n	</div>\r\n</div>\r\n';

}
return __p
}