module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var contactPersonEditDeny = !contactPersonEditGranted || contactPersonDisableFreeTextInput;
    var disabledClass = contactPersonEditDeny ? "disabled" : "";
    var langRegistrationFullName = langRegistrationFullName || {};
;
__p += '\n<div class="b-traveller">\n    <div class="b-traveller__title collapse--with-anchor mobile-collapse" data-toggle="collapse" data-target=".toggle-traveller-' +
((__t = ( index )) == null ? '' : __t) +
'">\n        <div class="b-traveller-number">' +
((__t = ( index + 1 )) == null ? '' : __t) +
'-' +
((__t = ( L10N.get('bookingForm.n') )) == null ? '' : __t) +
' ' +
((__t = ( L10N.get('passengers.passengerTitle') )) == null ? '' : __t) +
'</div>\n        ';
 if (isAdult) { ;
__p += '\n        <span class="b-traveller__title-contact">\n            <span class="js-widget"  onclick=\'return new Widgets.Checkbox({ bindingProperty: "isContact", classes: "' + disabledClass + '", label: L10N.get("bookingForm.travellerAsContact"), placeholder: L10N.get("bookingForm.activeTravellerPlaceholder"), disabled: "' + contactPersonEditDeny + '" })\'></span>\n        </span>\n        ';
 } ;
__p += '\n        <span class="b-traveller__controls mobile-hide" style="display: none">\n            <span class="b-traveller__remove"></span>\n        </span>\n    </div>\n\n    <div class="b-traveller-info toggle-traveller-' +
((__t = ( index )) == null ? '' : __t) +
' show">\n        <span class="b-traveller__controls desktop-hide" style="display: none">\n            <span class="b-traveller__remove"></span>\n        </span>\n        <div class="l-grid-container">\n            <div class="l-grid-container">\n                <div class="l-grid-layout-33 js-lastName-container"></div>\n                <div class="l-grid-layout-33 js-firstName-container"></div>\n                <div class="l-grid-layout-33 js-secondName-container"></div>\n            </div>\n\n        ';
 if (isAdult) { ;
__p += '\n            <div class="l-grid-container lg-hidden-block">\n                    <span class="b-traveller__block-header collapse--with-anchor mobile-collapse" data-toggle="collapse" data-target=".toggle-passenger-contacts-' +
((__t = ( index )) == null ? '' : __t) +
'">' +
((__t = ( L10N.get("bookingForm.passengerContactsHeader"))) == null ? '' : __t) +
'</span>\n                    <div class="l-grid-container toggle-passenger-contacts-' +
((__t = ( index )) == null ? '' : __t) +
' show">\n                        <div class="l-grid-layout-33">\n                            <span class="js-widget"  onclick=\'return new Widgets.Phone({ bindingProperty: "phone", label: L10N.get("bookingForm.phone"), placeholder: L10N.get("bookingForm.phonePlaceholder") })\'></span>\n                        </div>\n                        <div class="l-grid-layout-33">\n                            <span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "email", label: L10N.get("bookingForm.email"), classes: "b-email", placeholder: L10N.get("bookingForm.emailPlaceholder") })\'></span>\n                        </div>\n                    </div>\n            </div>\n        ';
 } ;
__p += '\n\n        ';
 if (!_.isEmpty(organizationCostCodes)) { ;
__p += '\n            <div class="l-grid-container">\n                <span class="b-traveller__block-header collapse--with-anchor mobile-collapse" data-toggle="collapse" data-target=".toggle-cost-codes-' +
((__t = ( index )) == null ? '' : __t) +
'">' +
((__t = ( L10N.get('transfers.statisticsInformation') )) == null ? '' : __t) +
'</span>\n                <div class="b-traveller-cost-codes toggle-cost-codes-' +
((__t = ( index )) == null ? '' : __t) +
' show">\n                    <div class="b-traveller-cost-codes__container"></div>\n                </div>\n            </div>\n        ';
 } ;
__p += '\n    </div>\n</div>\n';

}
return __p
}