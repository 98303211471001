module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	var showOldAmount = showOldAmount || false;
	var roomsCount = roomsCount || null;

	var offer = offer || {};

    var multipleOffers = roomsCount != null && roomsCount > 1;

	function getAmount(priceElement) {
		return showOldAmount && priceElement.oldAmount ? priceElement.oldAmount : priceElement.amount
    }

	var pPrices = price && price.passengerPrices || [];
	var types = ['tariff', 'fee', 'taxes', 'techFees', 'supplierFees', 'agencyFees', 'feeAndTaxes', 'discount'];
;
__p += '\n<div class="b-ticket-pricing b-hotel-offer-pricing">\n	<div class="b-ticket-pricing__item">\n		';
 if (!_.isEmpty(price.rates)) { ;
__p += '\n            <div class="b-ticket-pricing__header">\n                ';
 _.each(price.rates, function(rate) { ;
__p += '\n                    <span>' +
((__t = ( getAmount(rate.total) + ' ' )) == null ? '' : __t) +
' <span class="currency"> ' +
((__t = ( rate.symbol )) == null ? '' : __t) +
'</span></span>\n                ';
 }); ;
__p += '\n            </div>\n		';
 } ;
__p += '\n        <div class="b-ticket-pricing__content table-view">\n\n        </div>\n        ';
 _.each(pPrices, function(value) {
            var passengerPrice = _.extend({}, value, value.feeDetails);

			_.each(types, function(type) {
				var priceValue = passengerPrice[type];
                if (priceValue != null && parseFloat(priceValue.amount) !== 0) {
        ;
__p += '\n                    <div class="b-ticket-pricing__section">\n                        <div class="b-ticket-pricing__section-info">\n                            <span class="b-ticket-pricing__section-passenger">' +
((__t = ( L10N.get("pricing." + type) )) == null ? '' : __t) +
'</span>\n                            <span class="b-ticket-pricing__section-price">\n                                ' +
((__t = ( Formatter.formatMoney(priceValue.amount, Formatter.getDecimal(priceValue.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(priceValue.currencyCode) )) == null ? '' : __t) +
'\n                            </span>\n                        </div>\n                    </div>\n                ';
 } ;
__p += '\n            ';
 }) ;
__p += '\n            ';
 if (value.dailyDetails && value.dailyDetails.length) { ;
__p += '\n                <div class="b-ticket-pricing__bordered-wrapper">\n                    ';
 _.each(value.dailyDetails, function(dailyDetail) { ;
__p += '\n                        <div class="b-ticket-pricing__section">\n                            <div class="b-ticket-pricing__section-info">\n                                <span class="b-ticket-pricing__section-passenger">' +
((__t = ( dailyDetail.date ? new Time(dailyDetail.date).toDateString(): "" )) == null ? '' : __t) +
'</span>\n                                <span class="b-ticket-pricing__section-price">\n                                    ';
 if (dailyDetail.price) { ;
__p += '\n                                        ' +
((__t = ( Formatter.formatMoney(dailyDetail.price.amount, Formatter.getDecimal(dailyDetail.price.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(dailyDetail.price.currencyCode) )) == null ? '' : __t) +
'\n                                    ';
 } ;
__p += '\n                                </span>\n                            </div>\n                        </div>\n                    ';
 }) ;
__p += '\n                </div>\n            ';
 } ;
__p += '\n        ';
 }) ;
__p += '\n        ';
 if (multipleOffers && price.total != null) { ;
__p += '\n        <div class="b-ticket-pricing__section">\n            <div class="b-ticket-pricing__section-info">\n                <span class="b-ticket-pricing__section-price">\n                    ' +
((__t = ( roomsCount )) == null ? '' : __t) +
' x\n                    ' +
((__t = ( Formatter.formatMoney(getAmount(price.total), Formatter.getDecimal(getAmount(price.total))) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(price.total.currencyCode) )) == null ? '' : __t) +
'\n                    ';
 const value = parseInt(getAmount(price.total), 10) * roomsCount; ;
__p += '\n                    = ' +
((__t = ( Formatter.formatMoney(value, Formatter.getDecimal(value.toString(10))) )) == null ? '' : __t) +
'\n                    ' +
((__t = ( Formatter.formatCurrency(price.total.currencyCode) )) == null ? '' : __t) +
'\n                </span>\n            </div>\n        </div>\n        ';
 } ;
__p += '\n		';
 if (price.total != null) { ;
__p += '\n			<div class="b-pricing__total-section clearfix">\n				<span class="b-pricing__section-total-title">' +
((__t = ( L10N.get("pricing.total") )) == null ? '' : __t) +
':</span>\n				<span class="b-pricing__section-total-price">\n                    ';
 if (multipleOffers) { ;
__p += '\n                    ';
 const value = parseInt(getAmount(price.total), 10) * roomsCount; ;
__p += '\n                    ' +
((__t = ( Formatter.formatMoney(value, Formatter.getDecimal(value.toString(10))) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(price.total.currencyCode) )) == null ? '' : __t) +
'\n                    ';
 } else { ;
__p += '\n                    ' +
((__t = ( Formatter.formatMoney(getAmount(price.total), Formatter.getDecimal(getAmount(price.total))) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(price.total.currencyCode) )) == null ? '' : __t) +
'\n                    ';
 } ;
__p += '\n				</span>\n			</div>\n		';
 } ;
__p += '\n	</div>\n</div>\n';

}
return __p
}