// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';
import PassportModel from '@/blocks/elements/b-passport/model';
import CostCodesModel from '@/blocks/elements/b-cost-codes/model';
import BaseCollection from '@/classes/base.collection';

export default BaseModel.extend({

	defaults() {
		return {
			passport: new PassportModel(),
			costCodes: new BaseCollection([], {
				model: CostCodesModel,
			}),
		};
	},

	initialize() {
		if (!this.has('passport')) {
			this.set('passport', new BaseModel({
				citizenship: STATE.getSettings().generalSettings.defaultCitizenship,
				type: '',
				originCountry: STATE.getSettings().generalSettings.defaultCitizenship,
			}));
		}
		if (this.has('passport')) {
			this.set('phone', {
				cityCode: '',
				countryCode: STATE.getDefaultCountryCode(),
				number: '',
			});
		}
	},

	parse(resp) {
		let temp = resp;
		while (temp && temp.parameters) {
			temp = temp.parameters;
		}
		if (_.isEmpty(temp.costCodes)) {
			delete temp.costCodes;
		}
		return Object.assign(
			{},
			temp,
			{
				passport: new PassportModel(temp.passport, {
					parse: true,
				}),
			},
		);
	},

});
