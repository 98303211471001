module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-hotel-order-info">\n	<div class="b-hotel-order-info__wrapper b-hotel-order b-hotel-order_column-wrapper">\n		<div class="b-hotel-order_column">\n			<div class="b-hotel-order__column-container">\n				<div class="b-hotel-order__line">\n					<div class="b-hotel-order__name">' +
((__t = ( hotel.name )) == null ? '' : __t) +
'</div>\n					';
 var stars = parseInt(hotel.hotelStars, 10); ;
__p += '\n					';
 if (stars > 0) { ;
__p += '\n						<div class="b-hotel-order__stars b-hotel-stars">\n							';
 for (var i = 0; i < stars; i++) { ;
__p += '\n								<span class="b-hotel-star"></span>\n							';
 } ;
__p += '\n						</div>\n					';
 } ;
__p += '\n                    <span class="js-copy-content copy-content">' +
((__t = ( hotel.name )) == null ? '' : __t) +
' ' +
((__t = ( stars > 0 ? stars + '*' : '' )) == null ? '' : __t) +
'</span>\n				</div>\n                <div class="b-hotel-order_slider desktop-hide"></div>\n			</div>\n\n            ';

                let address;
                let addressString;
                let distance;
                if (hotel.address) {
                    address = hotel.address;
                    addressString = _.compact([address.address, address.zipCode, (address.cityInfo && address.cityInfo.city || {}).caption, (address.country || {}).caption]).join(', ');
                }
                if (hotel.distance != null && !isNaN(hotel.distance)) {
					distance = parseFloat(hotel.distance).toFixed(1).replace('.', ',') + L10N.get('hotels.distanceFromCenter');
                }
            ;
__p += '\n            ';
 if (addressString) { ;
__p += '<span class="js-copy-content copy-content">' +
((__t = ( L10N.get('hotels.address') )) == null ? '' : __t) +
': ' +
((__t = ( addressString )) == null ? '' : __t) +
' ';
 if (distance) { ;
__p += ' ' +
((__t = ( distance )) == null ? '' : __t) +
' ';
 } ;
__p += '</span>';
 } ;
__p += '\n\n			';
 _.each(offers, function (offer, index) { ;
__p += '\n				<div class="b-hotel-order__column-container b-hotel-order__column-container-' +
((__t = ( index )) == null ? '' : __t) +
' b-hotel-order_column-wrapper mobile-wrap">\n                    <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('hotels.checkin') )) == null ? '' : __t) +
': ' +
((__t = ( new Time(offer.standardCheckIn).toDateString() )) == null ? '' : __t) +
', ' +
((__t = ( L10N.get('Common.with') )) == null ? '' : __t) +
' ' +
((__t = ( new Time(offer.standardCheckIn).toShortTime() )) == null ? '' : __t) +
'</span>\n                    <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('hotels.checkout') )) == null ? '' : __t) +
': ' +
((__t = ( new Time(offer.standardCheckOut).toDateString() )) == null ? '' : __t) +
', ' +
((__t = ( L10N.get('Common.with') )) == null ? '' : __t) +
' ' +
((__t = ( new Time(offer.standardCheckOut).toShortTime() )) == null ? '' : __t) +
'</span>\n                    <span class="js-copy-content copy-content">&#10;&#13;' +
((__t = ( L10N.get('hotels.room') )) == null ? '' : __t) +
': <span data-copy-content-from=".b-hotel-order__column-container-' +
((__t = ( index )) == null ? '' : __t) +
' .b-hotel-order__room-info .b-hotel-order__text"></span></span>\n                    <span class="js-copy-content copy-content" data-replace-newline=" "><span data-copy-content-from=".b-hotel-order__column-container-' +
((__t = ( index )) == null ? '' : __t) +
' .b-hotel-order__standart-info > .b-hotel-order__line:first"></span></span>\n                    <span class="js-copy-content copy-content" data-replace-newline=" "><span data-copy-content-from=".b-hotel-order__column-container-' +
((__t = ( index )) == null ? '' : __t) +
' .b-hotel-order__standart-info > .b-hotel-order__line:last"></span></span>\n					<div class="b-hotel-order_column">\n						<div class="b-hotel-order__line b-hotel-order__room-info">\n							<span class="b-hotel-order__caption">' +
((__t = ( L10N.get('hotels.room') )) == null ? '' : __t) +
': </span>\n							<span class="b-hotel-order__text">' +
((__t = ( index + 1 )) == null ? '' : __t) +
' ' +
((__t = ( L10N.get('hotels.room') )) == null ? '' : __t) +
'. ' +
((__t = ( offer.roomName )) == null ? '' : __t) +
'</span>\n						</div>\n						<div class="b-hotel-order__line b-hotel-order__standart-info">\n							';
 if (offer.standardCheckIn) { ;
__p += '\n								<span class="b-hotel-order__line" style="margin-right: 15px;">' +
((__t = ( L10N.get('hotels.checkin') )) == null ? '' : __t) +
':\n                                    ' +
((__t = ( formatDate(offer.standardCheckIn) )) == null ? '' : __t) +
'\n                                </span>\n							';
 } ;
__p += '\n\n							';
 if (offer.standardCheckOut) { ;
__p += '\n								<span class="b-hotel-order__line">' +
((__t = ( L10N.get('hotels.checkout') )) == null ? '' : __t) +
':\n									' +
((__t = ( formatDate(offer.standardCheckOut) )) == null ? '' : __t) +
'\n								</span>\n							';
 } ;
__p += '\n						</div>\n						<div class="b-hotel-order__line b-hotel-order__description">\n							';
 if (offer.cancellationPolicyText) { ;
__p += '\n								<span class="b-hotel-order__link" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="<div>' +
((__t = ( offer.cancellationPolicyText )) == null ? '' : __t) +
'</div>">' +
((__t = ( L10N.get('hotels.penalties') )) == null ? '' : __t) +
'</span>\n                                <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('hotels.penalties') )) == null ? '' : __t) +
': ' +
((__t = ( offer.cancellationPolicyText )) == null ? '' : __t) +
'</span>\n							';
 } ;
__p += '\n							';
 if (offer.online !== true) { ;
__p += '\n								<span class="b-hotel-order__important">' +
((__t = ( L10N.get('hotels.byRequestCapital') )) == null ? '' : __t) +
'</span>\n                                <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('hotels.byRequestCapital') )) == null ? '' : __t) +
'</span>\n							';
 } ;
__p += '\n                            ';
 if (!_.isEmpty(offer.essentialInfoList)) {
                                let result = [];
                                _.each(offer.essentialInfoList, function(el) {
                                    if (!el) return;
                                    result.push(...el.infoValue);
                                });
                                result = _.sortBy(result, function(el) { return el.length})
                            ;
__p += '\n                            <span class="b-hotel-order__important" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="\n                                <div class=\'b-hotel-offer__essential-list\'>\n                                    ';
 _.each(result, function(essential) { ;
__p += '\n                                        <div class=\'b-hotel-offer__essential\'>' +
((__t = ( _.escape(essential) )) == null ? '' : __t) +
'</div>\n                                    ';
 }); ;
__p += '\n                                </div>\n                            ">' +
((__t = ( L10N.get('Common.importantInformation') )) == null ? '' : __t) +
'</span>\n                            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('Common.importantInformation') )) == null ? '' : __t) +
': ' +
((__t = ( result.join('\n') )) == null ? '' : __t) +
'</span>\n                            ';
} ;
__p += '\n						</div>\n						';
 if (offer.meal != null) { ;
__p += '\n							<div class="b-hotel-order__line">' +
((__t = ( offer.meal.caption )) == null ? '' : __t) +
'</div>\n						';
 } ;
__p += '\n\n						';
 if (!_.isEmpty(offer.services)) { ;
__p += '\n							<div class="b-hotel-order__line">\n								<div class="b-hotel-services b-hotel-order__services b-hotel-services--color-gray">\n									<div class="b-hotel-service b-hotel-service--type-PETS" data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( L10N.get('hotels.description') )) == null ? '' : __t) +
'"></div>\n									<div class="b-hotel-service b-hotel-service--type-MEAL" data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( L10N.get('hotels.description') )) == null ? '' : __t) +
'"></div>\n									<div class="b-hotel-service b-hotel-service--type-WIFI" data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( L10N.get('hotels.description') )) == null ? '' : __t) +
'"></div>\n								</div>\n							</div>\n						';
 } ;
__p += '\n					</div>\n\n					';
 if (!_.isEmpty(offer.additionalServices)) { ;
__p += '\n						<div class="b-hotel-order_column b-hotel-order__additional-services">\n							<div class="b-hotel-order__caption">' +
((__t = ( L10N.get('hotels.addServices.caption') )) == null ? '' : __t) +
'</div>\n							';
 if (offer.additionalServices.checkInInfo) { ;
__p += '\n								<div class="b-hotel-order__line">' +
((__t = ( offer.additionalServices.checkInInfo )) == null ? '' : __t) +
'</div>\n							';
 } else if (offer.additionalServices.checkIn) { ;
__p += '\n								<div class="b-hotel-order__line">' +
((__t = ( L10N.get('hotels.addServices.earlyCheckIn') )) == null ? '' : __t) +
': ' +
((__t = ( offer.additionalServices.checkIn.split(':').slice(0, 2).join(':') )) == null ? '' : __t) +
'</div>\n							';
 } ;
__p += '\n							';
 if (offer.additionalServices.checkOutInfo) { ;
__p += '\n								<div class="b-hotel-order__line">' +
((__t = ( offer.additionalServices.checkOutInfo )) == null ? '' : __t) +
'</div>\n							';
 } else if (offer.additionalServices.checkOut) { ;
__p += '\n								<div class="b-hotel-order__line">' +
((__t = ( L10N.get('hotels.addServices.lateCheckout') )) == null ? '' : __t) +
': ' +
((__t = ( offer.additionalServices.checkOut.split(':').slice(0, 2).join(':') )) == null ? '' : __t) +
'</div>\n							';
 } ;
__p += '\n							';
 if (!_.isEmpty(offer.additionalServices.meals)) { ;
__p += '\n								<div class="b-hotel-order__line">' +
((__t = ( L10N.get('hotels.meal') )) == null ? '' : __t) +
': ' +
((__t = ( _.map(offer.additionalServices.meals, function(el) { return el && el.caption}).join(', ') )) == null ? '' : __t) +
'</div>\n							';
 } ;
__p += '\n						</div>\n					';
 } ;
__p += '\n				</div>\n			';
 }); ;
__p += '\n\n			<div class="b-hotel-order__column-container">\n				';
 if (addressString) { ;
__p += '\n					<div class="b-hotel-order__line">\n						' +
((__t = ( addressString )) == null ? '' : __t) +
'\n					</div>\n				';
 } ;
__p += '\n\n				';
 if (distance) { ;
__p += '\n					<div class="b-hotel-order__line">' +
((__t = ( distance )) == null ? '' : __t) +
'</div>\n				';
 } ;
__p += '\n\n				';
 if (!_.isEmpty(hotel.phones)) { ;
__p += '\n					<div class="b-hotel-order__line">\n						';
 var phones = _.map(hotel.phones, function (phone) {
							return phone.value;
						}); ;
__p += '\n						' +
((__t = ( phones.join(', ') )) == null ? '' : __t) +
'\n					</div>\n				';
 } ;
__p += '\n			</div>\n		</div>\n		<div class="b-hotel-order_column b-hotel-order_slider mobile-hide"></div>\n\n		<!-- if (offer.travelPolicyCompliance != null && !_.isEmpty(offer.travelPolicyCompliance.rulesApplied)) { %>\n			<div class="b-hotel-info__travel-policy b-travel-policy">\n				 require(\'./b-travel-policy.ejs\')({\n					travelPolicyCompliance: offer.travelPolicyCompliance,\n				}) %>\n			</div>\n		 } %> -->\n	</div>\n</div>\n';

}
return __p
}