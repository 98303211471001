module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-ticket-refund-exchange">\n	';

		var result = [];
        var emptyResultList = [];
        var isFooter = false;
		var showFooterConditionsApply = false;
        var rule16Only = false;
        var isFooterDisabled = isFooterDisabled || false;
        var airlineCode = airlineCode || '';
        var isList = isList || false;
        var settings = STATE.getSettings();
        var flightToken = flightToken || null;

		// IBECORP-3348
		if (services.exchange && _.isEmpty(services.exchange)) {
			services.exchange = {ADULT: {unknown: true}}; // Forever ADULT!
			isFooter = true;
			rule16Only = true;
		}

		if (services.refund && _.isEmpty(services.refund)) {
			services.refund = {ADULT: {unknown: true}} // Forever ADULT!
			isFooter = true;
			rule16Only = true;
		}
	;
__p += '\n\n	';


	var __services = {};
	_.each(_.keys(services), function (singleService) {

		__services[singleService] = [];
		_.each(_.keys(services[singleService]), function (key) {

			var newKey = JSON.stringify(services[singleService][key]);
			if (!__services[singleService][newKey]) {
				__services[singleService][newKey] = [];
			}
			__services[singleService][newKey].push(key);
		});
	});

	_.each(_.keys(__services), function (singleService) {
		var typeRules = [];
		result.push(typeRules);

		_.each(_.keys(__services[singleService]), function (types) {
			var __item = __services[singleService][types];
			var status = 'negative';
			var item = {};
			typeRules.push(item);

			item.types = _.reduce(__item, function (r, t, i) {
				if (r != '' && i != __item.length - 1) {
					r += ', ';
				}
				if (__item.length > 1 && i == __item.length - 1) {
					r += ' ' + L10N.get('Common.and') + ' ';
				}
				r += settings && settings.passengerTypes && settings.passengerTypes.reduce(function(result, item) { if (item.uid === t) { result = item.caption; } return result; }, '') || ''
				return r;
			}, '');

			var info = services[singleService][__item[0]];

            let hasSumCanChangeMsg = false;

			if (singleService === 'exchange') {
				item.title = L10N.getUnescaped('standardServices.exchange');
				item.copy = '';

				item.text = '<div class="b-ticket-refund-exchange__element">'
				item.text += L10N.getUnescaped('standardServices.before');
                item.copy += L10N.get('standardServices.before');

				if (info.exchangeableBeforeDeparture) {
					item.text += '<span class="b-ticket-refund-exchange__cost">';
					if (info.exchangeBeforeDeparture == null) {
						status = 'default';
						item.text += L10N.getUnescaped('standardServices.possible');
						item.copy += L10N.get('standardServices.possible');
						if (info.exchangeConditionsApplyBeforeDeparture) {
							item.text += L10N.getUnescaped('standardServices.withRestrictions');
							item.copy += L10N.get('standardServices.withRestrictions');
							showFooterConditionsApply = true;
						}
					} else if (info.exchangeBeforeDeparture.amount == 0) {
						status = 'positive';
						item.text += L10N.getUnescaped('standardServices.free');
                        item.copy += L10N.get('standardServices.free');
					} else {
						status = 'paid';
                        hasSumCanChangeMsg = true;

                        item.text += `<span data-toggle="tooltip" data-html="true" data-position="bottom" data-original-title="<div>${L10N.get('avia.sumCanChangeMsg')}</div>">`;

						const value = info.exchangeBeforeDeparture.amount + ' ' + Formatter.formatCurrency(info.exchangeBeforeDeparture.currencyCode);
						item.text += value;
						item.copy += value;
						if (info.exchangeConditionsApplyBeforeDeparture) {
							item.text += ', ' + L10N.getUnescaped('standardServices.possible') + L10N.getUnescaped('standardServices.withRestrictions');
							item.copy += ', ' + L10N.get('standardServices.possible') + L10N.get('standardServices.withRestrictions');
							showFooterConditionsApply = true;
						}

                        item.text += '  <span class="icon__help" data-toggle="tooltip" data-placement="bottom"></span></span>';
					}
					item.text += '</span>';
				} else if (info.exchangeableBeforeDeparture === false) {
					item.text += '<span class="b-ticket-refund-exchange__impossible">' + L10N.getUnescaped('standardServices.impossible') + '</span>';
					item.copy += L10N.get('standardServices.impossible');
				} else {
					item.unknown = true;
					item.text += '<span class="b-ticket-refund-exchange__cost">' + L10N.get('avia.seeCarrierRules') + '</span>';
					
				}

				item.text += '<span class="b-ticket-refund-exchange__splitter">, </span>';
				item.text += '</div>';
				item.copy += ', ';

				item.text += '<div class="b-ticket-refund-exchange__element">';
				item.text += L10N.getUnescaped('standardServices.after');
				item.copy += L10N.get('standardServices.after');

				if (info.exchangeableAfterDeparture) {
					item.text += '<span class="b-ticket-refund-exchange__cost">';
					if (info.exchangeAfterDeparture == null) {
						status = 'default';
						item.text += L10N.getUnescaped('standardServices.possible');
						item.copy += L10N.get('standardServices.possible');
						if (info.exchangeConditionsApplyAfterDeparture) {
							item.text += L10N.getUnescaped('standardServices.withRestrictions');
							item.copy += L10N.get('standardServices.withRestrictions');
							showFooterConditionsApply = true;
						}
					} else if (info.exchangeAfterDeparture.amount == 0) {
						status = status === 'paid' ? status : 'positive';
						item.text += L10N.getUnescaped('standardServices.free');
						item.copy += L10N.get('standardServices.free');
					} else {
						status = 'paid';
                        hasSumCanChangeMsg = true;

						item.text += `<span data-toggle="tooltip" data-html="true" data-position="bottom" data-original-title="<div>${L10N.get('avia.sumCanChangeMsg')}</div>">`;

						const value = info.exchangeAfterDeparture.amount + ' ' + Formatter.formatCurrency(info.exchangeAfterDeparture.currencyCode);
						item.text += value;
						item.copy += value;
						if (info.exchangeConditionsApplyAfterDeparture) {
							item.text += ', ' + L10N.getUnescaped('standardServices.possible') + L10N.getUnescaped('standardServices.withRestrictions');
							item.copy += ', ' + L10N.get('standardServices.possible') + L10N.get('standardServices.withRestrictions');
							showFooterConditionsApply = true;
						}

                        item.text += '<span class="icon__help" data-toggle="tooltip" data-placement="bottom"></span></span>';
					}
					item.text += '</span>';
				} else if (info.exchangeableAfterDeparture === false) {
					item.text += '<span class="b-ticket-refund-exchange__impossible">' + L10N.getUnescaped('standardServices.impossible') + '</span>';
					item.copy += L10N.get('standardServices.impossible');
				}	else {
					item.unknown = true;
					item.text += '<span class="b-ticket-refund-exchange__cost">' + L10N.get('avia.seeCarrierRules') + '</span>';
				}
				item.text += '</span>'
				item.text += '</div>';
			} else if (singleService === 'refund') {
				item.title = L10N.getUnescaped('standardServices.refund');
				item.copy = '';

				item.text = '<div class="b-ticket-refund-exchange__element">'
				item.text += L10N.getUnescaped('standardServices.before');
				item.copy += L10N.get('standardServices.before');

				if (info.refundableBeforeDeparture) {
					item.text += '<span class="b-ticket-refund-exchange__cost">';
					if (info.refundBeforeDeparture == null) {
						status = 'default';
						item.text += L10N.getUnescaped('standardServices.possible');
						item.copy += L10N.get('standardServices.possible');
						if (info.refundConditionsApplyBeforeDeparture) {
							item.text += L10N.getUnescaped('standardServices.withRestrictions');
							item.copy += L10N.get('standardServices.withRestrictions');
							showFooterConditionsApply = true;
						}
					} else if (info.refundBeforeDeparture.amount == 0) {
						status = status === 'paid' ? status : 'positive';
						item.text += L10N.getUnescaped('standardServices.free');
						item.copy += L10N.get('standardServices.free');
					} else {
						status = 'paid';
                        hasSumCanChangeMsg = true;

                        item.text += `<span data-toggle="tooltip" data-html="true" data-position="bottom" data-original-title="<div>${L10N.get('avia.sumCanChangeMsg')}</div>">`;

						const value = info.refundBeforeDeparture.amount + ' ' + Formatter.formatCurrency(info.refundBeforeDeparture.currencyCode);
						item.text += value;
						item.copy += value;
						if (info.refundConditionsApplyBeforeDeparture) {
							item.text += ', ' + L10N.getUnescaped('standardServices.possible') + L10N.getUnescaped('standardServices.withRestrictions');
							item.copy += ', ' + L10N.get('standardServices.possible') + L10N.get('standardServices.withRestrictions');
							showFooterConditionsApply = true;
						}

                        item.text += ' <span class="icon__help" data-toggle="tooltip" data-placement="bottom"></span></span>';
					}
					item.text += '</span>';
				} else if (info.refundableAfterDeparture === false) {
					item.text += '<span class="b-ticket-refund-exchange__impossible">' + L10N.getUnescaped('standardServices.impossible') + '</span>';
					item.copy += L10N.get('standardServices.impossible');
				} else {
					item.unknown = true;
					item.text += '<span class="b-ticket-refund-exchange__cost">' + L10N.get('avia.seeCarrierRules') + '</span>';
				}

				item.text += '<span class="b-ticket-refund-exchange__splitter">, </span>';
				item.text += '</div>';
				item.copy += ', ';

				item.text += '<div class="b-ticket-refund-exchange__element">';
				item.text += L10N.getUnescaped('standardServices.after');
				item.copy += L10N.get('standardServices.after');

				if (info.refundableAfterDeparture) {
					item.text += '<span class="b-ticket-refund-exchange__cost">';
					if (info.refundAfterDeparture == null) {
						status = 'default';
						item.text += L10N.getUnescaped('standardServices.possible');
						item.copy += L10N.get('standardServices.possible');
						if (info.refundConditionsApplyAfterDeparture) {
							item.text += L10N.getUnescaped('standardServices.withRestrictions');
							item.copy += L10N.get('standardServices.withRestrictions');
							showFooterConditionsApply = true;
						}
					} else if (info.refundAfterDeparture.amount == 0) {
						status = status === 'paid' ? status : 'positive';
						item.text += L10N.getUnescaped('standardServices.free');
						item.copy += L10N.get('standardServices.free');
					} else {
						status = 'paid';
                        hasSumCanChangeMsg = true;

                        item.text += `<span data-toggle="tooltip" data-html="true" data-position="bottom" data-original-title="<div>${L10N.get('avia.sumCanChangeMsg')}</div>">`;

						const value = info.refundAfterDeparture.amount + ' ' + Formatter.formatCurrency(info.refundAfterDeparture.currencyCode);
						item.text += value;
						item.copy += value;
						if (info.refundConditionsApplyAfterDeparture) {
							item.text += ', ' + L10N.getUnescaped('standardServices.possible') + L10N.getUnescaped('standardServices.withRestrictions');
							item.copy += ', ' + L10N.get('standardServices.possible') + L10N.get('standardServices.withRestrictions');
							showFooterConditionsApply = true;
						}

                        item.text += ' <span class="icon__help" data-toggle="tooltip" data-placement="bottom"></span>'
					}
					item.text += '</span>'
				} else if (info.refundableAfterDeparture === false) {
					item.text += '<span class="b-ticket-refund-exchange__impossible">' + L10N.getUnescaped('standardServices.impossible') + '</span>';
					item.copy += L10N.get('standardServices.impossible');
				} else {
					item.unknown = true;
					item.text += '<span class="b-ticket-refund-exchange__cost">' + L10N.get('avia.seeCarrierRules') + '</span>';
				}
				item.text += '</div>';
			}

            if (hasSumCanChangeMsg) {
                item.copy += "\n" + L10N.get('avia.sumCanChangeMsg');
            }

			item.status = status;
			if (item.unknown) emptyResultList.push(item);
		});
	}); ;
__p += '\n\n	';
 _.each(result, function(type, index) {
        let titleForCopy = type[0].title + ': ';
        let textForCopy = type[0].text || '';
        if (type[0].unknown) { ;
__p += '\n            <div class="js-copy-content-tooltip copy-content">' +
((__t = ( titleForCopy )) == null ? '' : __t) +
' ' +
((__t = ( textForCopy )) == null ? '' : __t) +
' ' +
((__t = ( (index === result.length - 1 ? '' : '\n') )) == null ? '' : __t) +
'</div>\n			';
 return;
		}
		let resultForCopy = ''
        ;
__p += '\n        <div class="b-ticket-refund-exchange__item b-ticket-refund-exchange__status-' +
((__t = ( type[0].status )) == null ? '' : __t) +
'">\n            <div class="b-ticket-refund-exchange__item-title">' +
((__t = ( type[0].title )) == null ? '' : __t) +
'</div>\n            <div class="b-ticket-refund-exchange__item-rules">\n                ';
 _.each(type, function(item, i) { ;
__p += '\n                    ';
 if (!item.unknown) { ;
__p += '\n                        <span class="b-ticket-refund-exchange__item-rule">\n                            ';
 if (_.size(type) > 0) {
                                resultForCopy += item.types + ': '
                            ;
__p += '\n                                <span class="b-ticket-refund-exchange__item-rule-type">' +
((__t = ( item.types )) == null ? '' : __t) +
':</span>\n                            ';
 } ;
__p += '\n                            ' +
((__t = ( item.text )) == null ? '' : __t) +
'\n                            ';
 resultForCopy += item.copy + (type[i + 1] != null ? '/' : '') ;
__p += '\n                        </span>\n                    ';
 } ;
__p += '\n                ';
 }); ;
__p += '\n            </div>\n            <div class="js-copy-content-tooltip copy-content">' +
((__t = ( titleForCopy )) == null ? '' : __t) +
' ' +
((__t = ( resultForCopy + (index === result.length - 1 ? '' : '\n') )) == null ? '' : __t) +
'</div>\n        </div>\n	';
 }); ;
__p += '\n    ';
 var invalidResult = emptyResultList.length > 0 ;
__p += '\n    ';
 if (isList && !isFooterDisabled && rule16Only && invalidResult && flightToken) { ;
__p += '\n        <div class="b-ticket-refund-exchange__item b-ticket-refund-exchange__status-negative action-link js-searchFlightFares" data-token="' +
((__t = ( flightToken )) == null ? '' : __t) +
'">\n            ' +
((__t = ( L10N.get('avia.rules') )) == null ? '' : __t) +
'\n        </div>\n    ';
 } else if ((isFooter && !isFooterDisabled && airlineCode !== "KC") || (isList && invalidResult) || (isFooter && rule16Only && invalidResult)) { ;
__p += '\n		';
 _.each(result, function(type, index) { ;
__p += '\n			<div class="b-ticket-refund-exchange__item b-ticket-refund-exchange__status-' +
((__t = ( type[0].status )) == null ? '' : __t) +
'">\n				<div class="b-ticket-refund-exchange__item-title">' +
((__t = ( type[0].title )) == null ? '' : __t) +
'</div>\n				<div class="b-ticket-refund-exchange__item-rules">\n					';
 _.each(type, function(item, i) {;
__p += '\n							<span class="b-ticket-refund-exchange__item-rule">\n								';
 if (_.size(type) > 0) { ;
__p += '\n									<span class="b-ticket-refund-exchange__item-rule-type">' +
((__t = ( item.types )) == null ? '' : __t) +
':</span>\n								';
 } ;
__p += '\n								' +
((__t = ( item.text )) == null ? '' : __t) +
'\n							</span>\n						\n					';
 }); ;
__p += '\n				</div>\n			</div>\n		';
 }); ;
__p += '\n	';
 } else if (showFooterConditionsApply) {;
__p += '\n		<div class="b-ticket-refund-exchange__item b-ticket-refund-exchange__item-italics">\n			' +
((__t = ( L10N.get('standardServices.seeFareRules') )) == null ? '' : __t) +
'\n		</div>\n	';
};
__p += '\n</div>\n';

}
return __p
}