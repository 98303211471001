module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	var lastName = lastName;
	var lastNameLat = lastNameLat;
	var middleName = middleName;

	var firstName = firstName;
	var firstNameLat = firstNameLat;
	var middleNameLat = middleNameLat;

	var name = '';
	var lastname = '';
	var middlename = '';

	switch (this.options.langRegistrationFullName) {
        case 'LATIN':
            name = firstNameLat || '';
            lastname = lastNameLat || '';
            middlename = middleNameLat || '';
			break;
        case 'CYRILLIC':
            name = firstName || '';
            lastname = lastName || '';
            middlename = middleName || '';
			break;
        case 'LATIN_AND_CYRILLIC':
        default:
            name = firstNameLat || firstName || '';
            lastname = lastNameLat || lastName || '';
            middlename = middleNameLat || middleName || '';
			break;
    }
	var visible = (lastname.length || name.length || middlename.length) > 0;
;
__p += '\n<div class="b-passenger-autocomplete__item ' +
((__t = ( visible ? '' : 'dn' )) == null ? '' : __t) +
'">\n	<span class="b-passenger-autocomplete__item-code">' +
((__t = ( lastname )) == null ? '' : __t) +
' ' +
((__t = ( name )) == null ? '' : __t) +
' ' +
((__t = ( middlename )) == null ? '' : __t) +
'</span>\n</div>\n';

}
return __p
}