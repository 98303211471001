// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';

export default BaseModel.extend({

	toJSON(...args) {
		const result = BaseModel.prototype.toJSON.apply(this, args);

		return result;
	},
});
