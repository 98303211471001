module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="p-login__form-company">\r\n	<span class="js-widget"  onclick=\'return new Widgets.Login({bindingProperty: "login", type: "text"})\'></span>\r\n	';
 if (this.options.personTypes != null && this.options.personTypes.length > 0) { ;
__p += '\r\n	<div class="p-login__form-block">\r\n		<span class="js-widget"  onclick=\'return new Widgets.Select({ bindingProperty: "selectedPreferences.personType", values: "' + personTypes + '", label: L10N.get("AccountLoginForm.company.personType__label"), placeholder: L10N.get("AccountLoginForm.company.personType__placeholder") })\'></span>\r\n	</div>\r\n	';
 } ;
__p += '\r\n	';
 if (this.options.employers != null && this.options.employers.length > 0) { ;
__p += '\r\n	<div class="p-login__form-block">\r\n		<span class="js-widget"  onclick=\'return new Widgets.Select({ bindingProperty: "selectedPreferences.employer", values: "' + employer + '", label: L10N.get("AccountLoginForm.company.companies__label"), placeholder: L10N.get("AccountLoginForm.company.companies__placeholder") })\'></span>\r\n	</div>\r\n	';
 } ;
__p += '\r\n    ';
 if (this.options.salesPoints != null && !_.isEmpty(this.options.salesPoints)) { ;
__p += '\r\n        <div class="p-login__form-block js-salesPointsContainer"></div>\r\n    ';
 } ;
__p += '\r\n</div>\r\n';

}
return __p
}