// eslint-disable-next-line banned-modules
'use strict';

import SelectWidget from '@/widgets/b-select';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';

export default SelectWidget.extend({

	validationTemplate,

	el: '<div class="b-input"></div>',

	SelectWidget: null,

	initialize(options) {
		const genderVariants = [{ uid: 'MALE', caption: L10N.get('Widgets.male') }, { uid: 'FEMALE', caption: L10N.get('Widgets.female') }];
		const genderCollection = SelectWidget.dictionaryToCollection(genderVariants);

		SelectWidget.storeCollection(genderCollection);
		this.options = Object.assign({
			label: L10N.get('bookingForm.gender'),
		}, options);

		this.SelectWidget = new SelectWidget(_.extend({ values: genderCollection.cid }, this.options));
		this.$el.html(this.SelectWidget.$el);
	},

	applyBinding(...args) {
		this.SelectWidget.applyBinding.apply(this.SelectWidget, args);
	},

});
