module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-booking b-booking-transfers">\n    <div class="b-booking__transfer-ride-details"></div>\n    <div class="b-booking-transfers__additional-services">\n        <div class="b-booking-transfers__additional-services-header b-booking-transfers__block-header collapse--with-anchor--white mobile-collapse" data-toggle="collapse" data-target=".toggle-additional-services">\n            ' +
((__t = ( L10N.get("transfers.additionalServices.caption") )) == null ? '' : __t) +
'\n        </div>\n        <div class="b-booking-transfers__additional-services-wrapper toggle-additional-services show"></div>\n    </div>\n    <div class="b-booking-transfers__passengers">\n        <div class="b-booking-transfers__passengers-header b-booking-transfers__block-header collapse--with-anchor--white mobile-collapse" data-toggle="collapse" data-target=".toggle-passengers">\n            ' +
((__t = ( L10N.get("transfers.passengers.multiple") )) == null ? '' : __t) +
'\n        </div>\n        <div class="b-booking-transfers__passengers-wrapper toggle-passengers show"></div>\n    </div>\n\n    ';
 if (this.showCommentBlock) { ;
__p += '\n        <div class="b-booking-transfers__notes b-booking-notes">\n            <div class="b-booking-notes__caption b-booking-transfers__block-header collapse--with-anchor--white mobile-collapse" data-toggle="collapse" data-target=".toggle-notes">' +
((__t = ( L10N.get("transfers.comment") )) == null ? '' : __t) +
'</div>\n            <label class="b-textarea toggle-notes collapse">\n                <span class="js-widget"  onclick=\'return new Widgets.Textarea({ bindingProperty: "notes", placeholder: L10N.get("transfers.commentDescription") })\'></span>\n            </label>\n        </div>\n    ';
 } ;
__p += '\n\n    ';
 if (this.keyContact || this.reasonCode) { ;
__p += '\n		<div class="b-booking__two-selector-wrapper">\n			<div class="l-grid-container">\n				<div class="b-cost-codes__item-content flex-grid-container">\n					<div class="b-booking__reason-code flex-grid-layout b-cost-codes__item-field"></div>\n					<div class="b-booking__key-contact flex-grid-layout b-cost-codes__item-field"></div>\n				</div>\n			</div>\n		</div>\n	';
 } ;
__p += '\n\n    ';
 if (!_.isEmpty(this.externalSystems)) {;
__p += '\n		<div class="b-booking__two-selector-wrapper">\n			<span class="b-traveller__block-header">' +
((__t = ( L10N.get("bookingForm.externalSystemCode"))) == null ? '' : __t) +
'</span>\n			<div class="l-grid-container">\n				';
 _.each(this.externalSystems, function (sys) { 
					const bindingProperty = "externalSystem__" + sys.uid ;
__p += '\n				<div class="l-grid-layout-33">\n					<span class="pos-rel">\n						<span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "' + bindingProperty + '", label: "' + sys.caption + '", })\'></span>\n					</span>\n				</div>\n				';
 });
__p += '\n			</div>\n		</div>\n	';
 } ;
__p += '\n\n    <div class="b-booking__customer"></div>\n    <div class="b-booking__price-info">\n        <div class="b-booking__price-info-header collapse--with-anchor--white mobile-collapse" data-toggle="collapse" data-target=".toggle-price-info">' +
((__t = ( L10N.get("bookingForm.paymentInfo") )) == null ? '' : __t) +
'</div>\n        <div class="b-booking__price-info-container toggle-price-info show"></div>\n    </div>\n    <div class="b-booking__rules"></div>\n\n    <div class="b-booking__footer b-block-container__footer">\n        <div class="b-block-container__footer-leftbar">\n            <button class="btn btn-outline-dark b-booking__back">' +
((__t = ( L10N.get("bookingForm.back") )) == null ? '' : __t) +
'</button>\n        </div>\n        <div class="b-block-container__footer-rightbar">\n            <button class="btn btn-dark b-booking__submit ml-auto">' +
((__t = ( L10N.get("transfers.continueBooking") )) == null ? '' : __t) +
'</button>\n        </div>\n    </div>\n</div>\n\n';

}
return __p
}