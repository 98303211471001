// eslint-disable-next-line banned-modules
'use strict';

import BaseView from '@/classes/base.view';
import Widgets from '@/widgets/widgets';

import template from './template.ejs';

export default BaseView.extend({

	template,

	initialize() {
		this.render();
	},

	render() {
		if (this.model.attributes.formatter) {
			Widgets.Range[this.cid] = this.model.attributes.formatter;
			this.model.set('formatter', this.cid);
		} else {
			this.model.attributes.formatter = null;
		}
		this.setElement(this.template(
			Object.assign(
				{
					classes: '',
				},
				this.model.attributes,
			),
		));
	},

});
