'use strict';

import BaseModel from '@/classes/base.model';

const PassengerModel = BaseModel.extend({

	defaults() {
		return {
			ADULT: 1,
			CHILD: 0,
			INFANT: 0,
			INFANT_WITH_SITE: 0,
			YOUTH: 0,
			SENIOR: 0,
		};
	},

	clearTypes(type) {
		switch (type) {
			case 'SENIOR':
				this.set('ADULT', 0);
				this.set('CHILD', 0);
				this.set('INFANT', 0);
				this.set('INFANT_WITH_SITE', 0);
				this.set('YOUTH', 0);
				break;
			case 'YOUTH':
				this.set('ADULT', 0);
				this.set('CHILD', 0);
				this.set('INFANT', 0);
				this.set('INFANT_WITH_SITE', 0);
				this.set('SENIOR', 0);
				break;
			default:
				this.set('SENIOR', 0);
				this.set('YOUTH', 0);
				break;
		}
	},

	minus(type) {
		const total = Object.keys(this.defaults())
			.reduce((result, item) => result + parseInt(this.get(item)), 0);
		if (total > 1) {
			this.clearTypes(type);
		}
		if (total > 1) {
			const value = parseInt(this.get(type));
			if (value > 0) {
				this.set(type, value - 1);
			}
		}
		this.trigger('change:passengersTypes');
	},

	plus(type) {
		this.clearTypes(type);
		const total = Object.keys(this.defaults())
			.reduce((result, item) => result + parseInt(this.get(item)), 0);
		if (total < 8) {
			const value = parseInt(this.get(type));
			if (value < 8) {
				this.set(type, value + 1);
			}
		}
		this.trigger('change:passengersTypes');
	},

	toJSON() {
		return Object.keys(this.attributes).reduce((result, item) => {
			if (parseInt(this.get(item)) > 0 && _.keys(this.defaults()).includes(item)) {
				/*eslint-disable */
				result[item] = this.get(item);
				/*eslint-enable */
			}
			return result;
		}, {});
	},

});

export default PassengerModel;
