module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="l-block__back b-filters-transfers">\n	<section class="b-filters l-block__over">\n		<div class="b-filters-transfers__sorting"></div>\n		<div class="b-filters-transfers__widgets"></div>\n\n        <div class="b-filters__mobile-buttons">\n            <span class="b-filters__mobile-reset">' +
((__t = ( L10N.get("filters.reset") )) == null ? '' : __t) +
'</span>\n            <span class="b-filters__mobile-apply">' +
((__t = ( L10N.get("filters.apply") )) == null ? '' : __t) +
'</span>\n        </div>\n	</section>\n	<div class="b-filters__drop-filters">\n		<span class="b-filters__drop-filters-button">' +
((__t = (L10N.get("filters.resetFilters"))) == null ? '' : __t) +
'</span>\n	</div>\n</div>\n';

}
return __p
}