module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-filters__line ' +
((__t = ( classes )) == null ? '' : __t) +
'">\n	<span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "value", placeholder: "' + placeholder + '" })\'></span>\n</div>\n';

}
return __p
}