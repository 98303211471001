// eslint-disable-next-line banned-modules
'use strict';

import './style.less';

import BaseView from '@/classes/base.view';
import template from './template.ejs';
import AdditionalOrdersView from '@/blocks/elements/b-additional-orders/index';
import TableFooterView from '@/blocks/layout/b-block-table/table_footer';
import FinanceAutocomplete from '@/widgets/autocomplete-input/b-finance/index';
import axios from 'axios';

import GlUl from '@/utils/global-utils';
import $ from 'jquery';

export default BaseView.extend({

	template,

	events: {
		'click .p-search__submit': 'apply',
		'click .b-block-table__row': 'navigateOrder',
		'click .b-block-table__cell[data-sort]': 'applySorting',
		'click .b-block-container__filters-trigger': 'toggleFilters',
		'click .js-scroll-btn': 'scrollTo',
	},

	preinitialize(options) {
		BaseView.prototype.preinitialize.call(this, options);
		this.windowEventListenerList.push({
			mediaQuery: '(max-width: 768px)',
			name: 'change',
			callback: this.adjustMobileTemplate.bind(this),
			isMatchMedia: true,
		});
	},

	initialize(options) {
		this.options = options;
		this.tablePageHistory = [0];
		const state = STATE.getFormState('orders') || {};
		const stateData = _.omit(state.parameters, 'page', 'pageSize');
		let {reservationStatuses} = STATE.getSettings();

		if (reservationStatuses != null && _.size(reservationStatuses) !== 0) {
			reservationStatuses = FinanceAutocomplete.storeCollection(
				FinanceAutocomplete.dictionaryToCollection(reservationStatuses),
			);
		}

		this.render({reservationStatuses});
		this.addEventListeners();

		this.windowEventListenerList.push({
			name: 'keydown',
			callback: GlUl.tablePageHistoryHandler.bind(this),
		});

		this.addWindowEventListeners();

		if (!_.isEmpty(stateData) && _.compact(_.values(stateData)).length) {
			this.model.set(stateData);
		}

		this.apply();
		this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));
	},

	onKeyUpApply(e) {
		clearTimeout(this.keyUpTimer);
		this.keyUpTimer = setTimeout(() => {
			if (e) {
				$(e.currentTarget).trigger('blur');
				this.apply();
				$(e.currentTarget).trigger('focus');
			}
		}, 600);
	},

	delegateEvents(...args) {
		BaseView.prototype.delegateEvents.apply(this, args);
	},

	addEventListeners() {
		this.onKeyUpApply = this.onKeyUpApply.bind(this);
		this.saveState = this.saveState.bind(this);
		this.apply = this.apply.bind(this, null);

		this.$('.b-input__value').keyup(this.onKeyUpApply);
		this.model.on('change:page', (model, value) => {
			this.tablePageHistory.push(value);
			this.apply();
		});
		this.model.on('typeahead:contactSelect', (model, value) => {
			model.set('contactPersonRef', value === '' ? undefined : value.personRef);
		});
		this.model.on('change:contactPerson', (model, value) => {
			if (value === '' || !value) model.set('contactPersonRef', undefined);
		});
		this.model.on('afterchange:pageSize', this.apply);
		this.model.on('change', (model) => {
			const changed = _.omit(model.changed, ['page', 'count', 'pageSize']);
			if (_.isEmpty(changed)) return this.saveState();

			GlUl.unsetPageOnFilterChange.call(this, model);

			return this.saveState();
		});
	},

	undelegateEvents(...args) {
		if (this.$el) {
			this.$('.b-input__value').off('keyup', this.onKeyUpApply);
		}
		BaseView.prototype.undelegateEvents.apply(this, args);
	},

	adjustMobileTemplate(matches) {
		clearTimeout(this.timer);
		if (_.isObject(matches)) matches = matches.matches;
		this.isMobileTemplate = matches;
		this.timer = setTimeout(() => {
			const $cabinetFilters = $('.b-cabinet__filters');
			const $topControls = this.$('.b-block-container__top-controls');
			const $topControlsElements = $topControls.find(
				'.b-block-container__element:not(.b-block-container__element--search)',
			);

			if (matches) {
				_.each($topControlsElements, (el) => el && $cabinetFilters.append($(el).detach()));
				$cabinetFilters.find('.p-search__submit').on('click.filtersApply', this.apply);
			} else {
				$cabinetFilters.find('.p-search__submit').off('click.filtersApply', this.apply);
				_.each($cabinetFilters.find('.b-block-container__element'), (el) => {
					$topControls.append($(el).detach());
				});
			}
		}, 100);
	},

	saveState() {
		STATE.setFormState('orders', this.model.toJSON());
	},

	applyCallback(result) {
		STATE.hideLoader();

		this.model.set('count', result.totalCount);
		this.$('.b-block-container__footer').html(new TableFooterView({
			model: this.model,
			showMoreEventHandler: this.showMoreEventHandler.bind(this),
			data: {
				count: result.totalCount,
			},
		}).$el);
		this.$('.b-block-container__content').html(new AdditionalOrdersView({
			parent: this,
			orders: result.orders,
			linkHash: STATE.ROUTES.CABINET_ORDER,
			model: this.model,
		}).$el);

		this.setSorting(this.model.get('sortInfo'));
		if (this.isMobileTemplate) {
			const pageSize = this.model.get('pageSize');
			this.$('.js-scroll-btn')[result.totalCount && pageSize > 10 ? 'show' : 'hide']();
		}
	},

	setSorting(sortInfo = {}) {
		if (_.isEmpty(sortInfo)) {
			return this;
		}

		const DEFAULT_SORTING = sortInfo.sortField || 'ORDER_NUMBER';
		const DEFAULT_ORDER = sortInfo.sortOrder || 'ASC';

		const $target = this.$el.find(`.b-block-table__cell[data-sort="${DEFAULT_SORTING}"]`);
		$target.attr('data-order', DEFAULT_ORDER);

		return this;
	},

	// EVENTS
	navigateOrder(e) {
		if (e != null) {
			e.preventDefault();
		}

		const $target = $(e.currentTarget);
		const orderUid = $target.attr('data-order-uid');

		if (orderUid) {
			STATE.navigate([STATE.ROUTES.CABINET_ORDER, orderUid].join('/'));
		}
	},

	showMoreEventHandler(e) {
		const count = this.model.get('pageSize');
		if (count >= this.model.get('count')) {
			if (e) $(e.currentTarget).hide();
			return;
		}
		this.model.set('pageSize', count + 10);
		this.apply(e);
	},

	scrollTo(e) {
		const scrollTop = $(e.currentTarget).hasClass('top');
		$(e.currentTarget).toggleClass('top', !scrollTop);

		if (scrollTop) {
			this.previousScrollPosition = $(window).scrollTop();
			$(window).scrollTop(0);
		} else {
			$(window).scrollTop(this.previousScrollPosition);
		}
	},

	apply(e) {
		if (e) {
			e.preventDefault();
		}
		if ($('.l-layout').hasClass('show-filters')) this.toggleFilters();

		STATE.showLoader();
		this.disableElements(e);
		return axios.post('/midoffice/ibecorp-b2b/cabinetNew/getOrders', this.model.toJSON()).then((response) => {
			const {result} = response.data;
			this.applyCallback(result);
		});
	},

	toggleFilters() {
		const $lLayout = $('.l-layout');
		$lLayout.toggleClass('show-filters', !$lLayout.hasClass('show-filters'));
	},

	applySorting(e) {
		if (e) {
			e.preventDefault();
		}

		const $target = $(e.currentTarget);
		const sortField = $target.attr('data-sort');
		const sortOrder = $target.attr('data-order') === 'ASC' ? 'DESC' : 'ASC';

		if (sortField) {
			this.model.set('sortInfo', {
				sortField,
				sortOrder,
			});
			this.apply();
		}
	},

});
