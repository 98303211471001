// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

import EmailAutoSuggest from '@/widgets/autocomplete-input/b-email/index';

export default BaseView.extend({

	template,

	events: {
		'click .b-input__remove': 'removeItem',
	},

	initialize() {
		this.render();
	},

	removeItem() {
		this.options.collection.remove(this.model);
	},

	/* eslint-disable */
	render() {
		this.setElement(this.template(this.options));
		const container = this.$('.b-search-email');
		const widget = new EmailAutoSuggest({
			bindingProperty: this.options.bindingProperty,
			deletableField: true,
			autoSuggestEmails: this.options.autoSuggestEmails,
			model: this.options.model,
			container
		});
		widget.applyBinding(this.options.model);
		container.append(widget.$el);
		this.options.container.before(this.$el);
	},

});
