module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var pikadayModalCid = pikadayModalCid || ''; ;
__p += '\r\n<div class="p-search__direction-date p-search__direction-date-wrapper ' +
((__t = (classes )) == null ? '' : __t) +
'">\r\n	';
 if (label) { ;
__p += '\r\n		<div class="p-search__direction-date-label">' +
((__t = ( label )) == null ? '' : __t) +
'</div>\r\n	';
 } ;
__p += '\r\n	<input type="text" placeholder="' +
((__t = (placeholder )) == null ? '' : __t) +
'" data-toggle-modal="' +
((__t = ( pikadayModalCid )) == null ? '' : __t) +
'" class="p-search__input p-search__direction-date-input b-datepicker__field">\r\n	<div class="p-search__direction-date-calendar">\r\n		<div class="b-datepicker__icon p-search__direction-date-calendar-img b-datepicker__open"></div>\r\n	</div>\r\n</div>\r\n';

}
return __p
}