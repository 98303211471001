// eslint-disable-next-line banned-modules
'use strict';

import FilterBaseModel from '@/blocks/elements/b-filters/base.model';
import View from './view';

export default FilterBaseModel.extend({

	View,

	defaults() {
		return {
			value: '',
		};
	},

	findValue(model, findExactMatch = false) {
		let value = this.get('value');

		const field = this.get('field');
		const modelValue = this.parse(model.get(field));

		if (field.includes('flightNumber')) {
			value = value.replace(/[^\w\d]/gi, '').toLocaleLowerCase();
		} else {
			value = value.toLowerCase();
		}

		const find = _.isArray(modelValue) ?
			_.find(
				modelValue,
				(el) => _.some([value, ...(findExactMatch ? [] : value.split(' '))], (s) => el.toLocaleLowerCase().includes(s)),
			)
			: _.some([value, ...(findExactMatch ? [] : value.split(' '))], (s) => modelValue && modelValue.includes(s));

		return find;
	},

	filter(model) {
		if (!this.get('value')) return true; // модель отфильтрована и не должна отображаться
		return this.findValue(model);
	},

	collect(model) {
		const field = this.get('field');
		if (field.includes('flightNumber')) {
			// const SEARCH_MODEL = STORE.get(STATE.ROUTES.MAIN_SEARCH); // UTS-338
			// this.set('value', (SEARCH_MODEL && SEARCH_MODEL.flightNumber) || '');
			this.set('value', '');
		} else {
			this.set(field, model.get(field));
		}
	},

});
