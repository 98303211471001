// eslint-disable-next-line banned-modules
'use strict';

import BaseView from '@/classes/base.view';
import template from './template.ejs';
import SelectWidget from '@/widgets/b-select/index';

import './style.less';

export default BaseView.extend({

	template,

	initialize(options) {
		this.model = options.model;

		options.paymentTypes = _.map(options.paymentTypes, (type, key) => {
			if (!type || type === 'null' || type.uid == null) {
				if (key === 'INVOICE') {
					return {
						uid: key,
						caption: L10N.get('payment.paymentInvoice'),
					};
				} else if (key === 'CASH') {
					return {
						uid: key,
						caption: L10N.get('payment.paymentCash'),
					};
				}

				return {
					uid: key,
					caption: key,
				};
			}

			type.caption = `${L10N.get('payment.creditCard')} - ${type.uid}`;
			return type;
		});

		const paymentTypes = SelectWidget.storeCollection(
			SelectWidget.dictionaryToCollection(options.paymentTypes));

		if (_.size(options.paymentTypes) === 1) {
			this.model.set('paymentType', options.paymentTypes[0]);
		}

		this.render({ paymentTypes });
	},

});
