// eslint-disable-next-line banned-modules
'use strict';

import BaseView from '@/classes/base.view';
import template from './template.ejs';
import axios from 'axios';

export default BaseView.extend({

	template,

	initialize() {
		this.type = window.location.href.includes('approve') ? 'approve' :
			window.location.href.includes('approve') ? 'refuse' : 'process';
		this.setRoute();
		this.getMessages();
	},

	setRoute() {
		const index = window.location.href.indexOf('/authorization');
		this.route = window.location.href.slice(index);
	},

	getMessages() {
		if (this.type === 'approve') {
			this.getApproveMessages();
		} else if (this.type === 'refuse') {
			this.getRefuseMessages();
		} else {
			this.getProcessMessages();
		}
	},

	getApproveMessages() {
		this.token = this.route.slice(28);
		axios.get('/midoffice/ibecorp-b2b/booking/authorizeBooking', {
			params: {
				token: this.token,
			},
		}).then(this.showMessagePopup.bind(this));
	},

	getRefuseMessages() {
		this.token = this.route.slice(27);
		axios.get('/midoffice/ibecorp-b2b/booking/refuseBooking', {
			params: {
				token: this.token,
			},
		}).then(this.showMessagePopup.bind(this));
	},

	getProcessMessages() {
		this.token = this.route.slice(48);
		axios.get('/midoffice/ibecorp-b2b/booking/processBookingAuthorization', {
			params: {
				token: this.token,
			},
		}).then(this.showMessagePopup.bind(this));
	},

	showMessagePopup(response) {
		if (!response || !response.data) return;
		const debugMessages = response.data.debugMessages;
		const messages = response.data.result && response.data.result.messages || [];
		const content = [];
		if (!_.isEmpty(debugMessages)) content.push(...debugMessages);
		if (!_.isEmpty(messages)) content.push(...messages);
		if (_.isEmpty(content)) return;
		const messagePopup = new Widgets.Popup({
			content: _.map(content, (el) => { return _.isObject(el) ? el.text : el; }).join('<br/>'),
			title: '',
			type: 'info',
			actions: [],
		});
		messagePopup.show();
		this.$el.append(messagePopup);
	},

});
