module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form autocomplete="off">\r\n	<input autocomplete="false" name="hidden" type="text" style="display:none;">\r\n	<div class="b-cabinet-employee ' +
((__t = ( !editPersonAllowed ? 'edit-disabled' : '' )) == null ? '' : __t) +
'">\r\n        ';
 if (!editPersonAllowed || isDisabled) { ;
__p += '\r\n            <div class="b-cabinet-employee__edit-disabled-mask"></div>\r\n        ';
 } ;
__p += '\r\n		<div class="b-block-container">\r\n            <button class="desktop-hide b-navigate-back-button js-navigate-back">' +
((__t = ( L10N.get('avia.goBack') )) == null ? '' : __t) +
'</button>\r\n			<div class="b-block-container__header">\r\n				<div class="b-block-container__header-label">' +
((__t = ( L10N.get('cabinet.employees.profile') )) == null ? '' : __t) +
'</div>\r\n				<div class="b-block-container__header-right">\r\n          ';
 if (isDisabled) { ;
__p += '\r\n            <button class="btn btn-green b-employee-restore ml-auto">\r\n              ' +
((__t = ( L10N.get('cabinet.employees.activateDisabledEmployee') )) == null ? '' : __t) +
'\r\n            </button>\r\n          ';
 } ;
__p += '\r\n					';
 if (isDisablePersonAllowed && editPersonAllowed && !isDisabled) { ;
__p += '\r\n						<button class="btn btn-alarm btn-icon btn-icon-remove b-employee-remove ml-auto">\r\n							' +
((__t = ( L10N.get('cabinet.employees.deleteEmployee') )) == null ? '' : __t) +
'\r\n						</button>\r\n					';
 } ;
__p += '\r\n				</div>\r\n			</div>\r\n\r\n\r\n			<div class="b-block-container__content">\r\n				<div class="b-cabinet-employee">\r\n					<div class="b-cabinet-employee__content">\r\n						<div class="b-cabinet-employee__organizations"></div>\r\n						<div class="b-cabinet-employee__person"></div>\r\n					</div>\r\n				</div>\r\n			</div>\r\n\r\n			<span class="b-cabinet-employee__save" ' +
((__t = ( isDisabled || !editPersonAllowed && 'disabled')) == null ? '' : __t) +
'>' +
((__t = ( !isDisabled && editPersonAllowed ? L10N.get("cabinet.save") : L10N.get('cabinet.editDisabled') )) == null ? '' : __t) +
'</span>\r\n		</div>\r\n	</div>\r\n</form>\r\n';

}
return __p
}