// eslint-disable-next-line banned-modules
'use strict';

import axios from 'axios';
import BasePageView from '@/pages/base.page-view';
import CabinetMenu from '@/blocks/pages/b-cabinet/b-cabinet-menu/index';
import ProductsView from '@/blocks/pages/b-cabinet/b-cabinet-products/index';
import ProductsFormModel from './model';

export default BasePageView.extend({

	initialize(options) {
		this.render();

		const model = new ProductsFormModel();

		this.ui.content.append(new ProductsView({
			settings: options.settings,
			collection: this.collection,
			model,
			parent: this,
			data: options.data,
		}).$el);
		this.ui.sidebar.append(new CabinetMenu().$el);
		this.ui.sidebar.append(`<div class="l-block__back"><div class="b-cabinet__filters"></div></div>`);
	},

}, {
	load() {
		return new Promise(resolve => {
			Promise.all([
				axios.get('/midoffice/ibecorp-b2b/cabinet/getSalesFormData'),
				axios.post('/midoffice/ibecorp-b2b/cabinetNew/getProducts', {
					parameters: ProductsFormModel.prototype.defaults(),
				}),
			]).then((result) => {
				const settings = result[0].data.result;
				resolve({
					settings,
					data: result[1],
					initScrollTopButton: true,
				});
			});
		});
	},
});
