// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';
import Inputmask from 'jquery.inputmask/dist/inputmask/inputmask';
import 'jquery.inputmask/dist/inputmask/inputmask.extensions';
import 'jquery.inputmask/dist/inputmask/inputmask.date.extensions';
import Time from '@/utils/time';

export default Widget.extend({

	validationTemplate,

	template,

	events: {
		'change .b-input__value': 'change',
	},

	ui: {
		input: '.b-input__value',
	},

	timeValueConverter: Time.createPattern(Time.PATTERNS.serverDate, 'DD-MM-YYYY'),

	initialize() {
		this.render();

		Inputmask.extendAliases({
			date: {
				alias: 'dd/mm/yyyy',
				mask: L10N.get('settings.dateFormat.inputMask'),
				placeholder: L10N.get('settings.dateFormat.label'),
				separator: L10N.get('settings.dateFormat.separator'),
				leapday: '29-02-',
				yearrange: {
					minyear: this.options.min || 1900,
					maxyear: this.options.max || 2099,
				},
				clearMaskOnLostFocus: false,
				clearIncomplete: false,
			},
		});

		this.inputMask = new Inputmask({
			alias: 'date',
			onincomplete: () => {
				this.$el.addClass('incomplete');
				this.$el.removeClass('complete');
			},
			oncomplete: () => {
				this.$el.removeClass('incomplete');
				this.$el.addClass('complete');
			},
		});
		this.inputMask.mask(this.ui.input[0]);
		// this.ui.input.inputmask('date', {});
	},

	change() {
		this.model.set(this.options.bindingProperty, Time.parseToServerDate(this.ui.input.val(), L10N.get('settings.dateFormat.value')));
	},

	setDate(value) {
		if (value) {
			this.ui.input.val(this.timeValueConverter(value));
			if (this.inputMask.isValid(this.timeValueConverter(value))) {
				this.inputMask.opts.oncomplete();
			} else {
				this.inputMask.opts.onincomplete();
			}
		} else {
			this.ui.input.val('');
			this.inputMask.opts.onincomplete();
		}
	},

	applyBinding(model) {
		this.model = model;

		this.listenTo(model, `change:${this.options.bindingProperty}`, (thisModel, value) => {
			this.setDate(value);
		});

		this.listenTo(model, `validationError:${this.options.bindingProperty}`, () => {
			this.$el.addClass('validation-error');
		});

		if (this.model.has(this.options.bindingProperty)) {
			this.setDate(this.model.get(this.options.bindingProperty));
		}
	},

});
