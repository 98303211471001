// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import BaseModel from '@/classes/base.model';
import SelectWidget from '@/widgets/b-select/';
import PricingView from '@/blocks/elements/b-pricing/index';
import template from './select.ejs';

export default BaseView.extend({

	template,

	events: {
		'click .b-selected-variant__cancel button': 'cancel',
		'click .b-selected-variant__booking button': 'process',
		'click .b-selected-variant__offer button': 'addOffer',
	},

	processButtonEnabled: null,
	genderSelectControl: null,
	genderSelected: null,
	message: null,

	initialize(options) {
		this.options = options;
		this.model = new BaseModel();

		this.seatsAmount = options.seatsAmount;
		this.genderSelectControl = false;
		this.processButtonEnabled = true;
		this.selectedVariant = options.selectedVariant;
		this.intentionData = this.options.parent.parent.intentionData;

		this.genders = [{
			uid: 'NOT_SELECTED',
			caption: L10N.get('trains.notSelected'),
		},
		{
			uid: 'MALE',
			caption: L10N.get('trains.male'),
		},
		{
			uid: 'FEMALE',
			caption: L10N.get('trains.female'),
		},
		{
			uid: 'MIX',
			caption: L10N.get('trains.mix'),
		},
		];

		this.listenTo(this.model, 'change:gender', (model, value) => {
			this.options.parent.selectedGender = value;
			this.options.parent.selectedVariant.carriageView.updateAvailablePlaces();
			this.options.parent.selectPlace(this.selectedVariant);
		});
		this.render({
			genders: SelectWidget.storeCollection(SelectWidget.dictionaryToCollection(this.genders)),
		});
	},

	update(selectedVariant, isMobileTemplate, noSeatSelection = false) {
		this.genderSelectControl = false;
		this.processButtonEnabled = true;
		this.selectedVariant = selectedVariant;
		const numbers = this.selectedVariant.numbers;
		if (this.selectedVariant.fullCompartment) {
			this.seatsAmount = numbers.length;
		} else {
			this.seatsAmount = this.options.seatsAmount;
		}

		// calculate price
		const passengersTypes = this.options.parent.passengersTypes;

		const notSelected = numbers.findIndex(n => {
			if (n.gender != null && n.gender.uid === 'NOT_SELECTED') {
				return true;
			}
			return false;
		});

		if (notSelected > -1) {
			this.genderSelectControl = true;
		}
		numbers.forEach(n => {
			if (n.gender != null && n.gender.uid !== 'NOT_SELECTED') {
				this.genderSelectControl = false;
			}
		});

		this.message = '';
		if (!_.isEmpty(selectedVariant) && selectedVariant.availableStatus && selectedVariant.availableStatus !== 'DEFAULT') {
			const passengerSum = _.reduce(_.values(passengersTypes), (sum, n) => (sum += n), 0);

			switch (selectedVariant.availableStatus) {
				case 'MOTHER_AND_BABY_SELECTED':
					this.processButtonEnabled = passengersTypes && (
						(passengersTypes.ADULT === 1 && passengerSum === 1) ||
							(passengersTypes.ADULT === 1 && passengersTypes.INFANT === 1 && passengerSum === 2));
					break;
				case 'WITH_CHILD_SELECTED':
					this.processButtonEnabled = passengersTypes &&
							(passengersTypes.ADULT <= 2 &&
							(passengersTypes.CHILD >= 1 || passengersTypes.INFANT >= 1));
					break;
			}
		}

		if (this.seatsAmount > numbers.length) {
			this.message = `${L10N.get('trains.seatsMinLimit')} ${this.seatsAmount}`;
			this.processButtonEnabled = false;
		}

		if (this.genderSelectControl &&
			(!this.options.parent.selectedGender || this.options.parent.selectedGender.uid === 'NOT_SELECTED')) {
			this.processButtonEnabled = false;
		}

		this.totalPrice = parseFloat(0, 10);

		if (noSeatSelection) {
			this.processButtonEnabled = true;
		}

		const genders = SelectWidget.storeCollection(SelectWidget.dictionaryToCollection(this.genders));
		if (STATE.checkViewport('(max-width: 768px)') && this.genderSelectControl && this.options.parent.mobileOfferPreview != null) {
			this.options.parent.mobileOfferPreview.renderGenderSelect(genders, this.model);
		}
		let pricingTooltipTitle = null;
		let isMinPriceRangeComponent = false;
		if (!_.isEmpty(this.selectedVariant.railPricing)) {
			this.totalPrice = this.selectedVariant.railPricing.totalPrice;
			isMinPriceRangeComponent = this.selectedVariant.railPricing.price.isMinPriceRangeComponent;
			const pricingView = new PricingView({
				price: this.selectedVariant.railPricing.price,
				priceBack: this.selectedVariant.railPricing.priceBack,
				totalPrice: this.selectedVariant.railPricing.totalPrice,
				showRates: true,
			});
			pricingTooltipTitle = _.escape(`<div class="additional-section-passenger-margin" ">${pricingView.$el.html()}</div>`);
		}

		// EOF calculate price
		this.render({
			genders,
			currencyCode: this.options.parent.currencyCode,
			totalPrice: this.totalPrice,
			selectedVariant: this.selectedVariant,
			isMinPriceRangeComponent,
			isMobileTemplate,
			noSeatSelection,
			pricingTooltipTitle,
		});
	},

	addOffer(e) {
		if (e != null) {
			e.preventDefault();
		}
		this.options.parent.addOffer(e, this.selectedVariant, this.totalPrice);
	},

	cancel(e) {
		if (e) {
			e.preventDefault();
		}
		this.options.parent.unSelectPlace(this.selectedVariant);
	},

	process(e) {
		if (e) {
			e.preventDefault();
		}
		this.options.parent.process(this.selectedVariant);
	},

	render(attrs) {
		const params = Object.assign(
			{
				classes: '',
			},
			this.model ? this.model.attributes : {},
			this.options,
			attrs,
		);
		this.setElement(this.template(params));
		return this;
	},

});
