// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';
import PricingView from '../b-transfer-pricing/index';

export default BaseView.extend({

	template,

	ui: {
		pricing: '.b-transfer-info__pricing',
	},

	initialize(options) {
		this.options = options;
		this.pricingInfo = this.options.pricingInfo;
		if (!this.pricingInfo.priceBack) {
			this.pricingInfo.priceBack = null;
		}
		if (!this.pricingInfo.additionalServices) {
			this.pricingInfo.additionalServices = null;
		}
		if (!this.pricingInfo.additionalServicesBack) {
			this.pricingInfo.additionalServicesBack = null;
		}

		this.render(this.options);
	},

	render(...args) {
		BaseView.prototype.render.apply(this, args);

		const pricingView = new PricingView({...this.pricingInfo});
		this.ui.pricing.html(pricingView.$el);
	},
});
