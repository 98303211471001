module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-attachment-wrapper">\n	<div class="b-attachment-transfers">\n		<div class="b-attachment-transfers__wrapper">\n			<div class="b-search-form__params">\n				<div class="b-search-route-address__collection__add">\n					<span class="b-search-route-address__collection__add-text">' +
((__t = ( L10N.get('searchForm.addAddress'))) == null ? '' : __t) +
'</span>\n					<span class="g-icon-add-orange b-search-route-address__collection__add-icon"></span>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>\n';

}
return __p
}