module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-ticket-available-seats">\n  <i class="b-ticket-available-seats__ticket-icon"></i>\n  <div class="b-ticket-available-seats__text">\n    ' +
((__t = ( L10N.get('ticket.seatsRemain') )) == null ? '' : __t) +
': ' +
((__t = ( availableSeats || 0 )) == null ? '' : __t) +
'\n  </div>\n</div>';

}
return __p
}