module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="p-places p-search__direction-' +
((__t = ( this.options.bindingProperty )) == null ? '' : __t) +
' ' +
((__t = (classes )) == null ? '' : __t) +
'">\r\n	<input type="text" placeholder="' +
((__t = ( this.options.placeholder )) == null ? '' : __t) +
'" class="p-places__input p-search__input p-search__direction-' +
((__t = ( this.options.bindingProperty )) == null ? '' : __t) +
'-input\r\n	p-search__direction-' +
((__t = ( this.options.bindingProperty )) == null ? '' : __t) +
'__input">\r\n	<span class="p-search__direction-suggest"></span>\r\n</div>\r\n';

}
return __p
}