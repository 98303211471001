// eslint-disable-next-line banned-modules
'use strict';

import './style.less';

import BaseView from '@/classes/base.view';
import BaseModel from '@/classes/base.model';
import template from './template.ejs';

export default BaseView.extend({

	template,

	el: 'body',

	events: {
		'click .js-close-modal': 'close',
		'click .js-toggle-step': 'onToggleStep',
	},

	initialize(options) {
		this.options = options;
		const service = options.service || {};
		this.model = new BaseModel({
			service,
			initialOpenedStepIndex: this.getOpenedStep(service?.authorizationSteps),
		});
		this.render();
	},

	getOpenedStep(authorizationSteps = []) {
		const index = _.findLastIndex(
			authorizationSteps,
			(aS => {
				const aSStatusUid = aS.status && aS.status.uid;
				if (!aSStatusUid) return false;
				return aSStatusUid === 'WAIT';
			}),
		);
		return index === -1 ? null : index;
	},

	getStatusClassNameSuffix(status = null) {
		switch (status) {
			case 'WAIT':
				return 'wait';
			case 'APPROVED':
			case 'COMPLETED':
				return 'success';
			case 'REFUSED':
			case 'CANCELED':
			case 'DELETED':
			case 'EXPIRED':
			case 'ABORTED':
				return 'error';
			case null:
			default:
				return 'blocked';
		}
	},

	getServiceAuthStatusPlaceholderClassNameSuffix(status = null) {
		switch (status) {
			case 'APPROVED':
			case 'COMPLETED':
				return 'success';
			case 'REFUSED':
			case 'CANCELED':
			case 'DELETED':
			case 'EXPIRED':
			case 'ABORTED':
				return 'error';
			case 'WAIT': // <- т.к. мы не можем "ожидать" финального псевдошага
			case null:
			default:
				return 'blocked';
		}
	},

	onToggleStep(e) {
		e?.preventDefault?.();
		const stepContainer = e.currentTarget.closest('.js-step');
		if (stepContainer !== null) {
			if (stepContainer.classList.contains('b-cabinet-order-auth-step--collapsed')) {
				stepContainer.classList.remove('b-cabinet-order-auth-step--collapsed');
			} else {
				stepContainer.classList.add('b-cabinet-order-auth-step--collapsed');
			}
		}
	},

	close() {
		this.$el.remove();
		this.destroy();
	},

});
