// eslint-disable-next-line banned-modules
'use strict';

import './style.less';

import BaseView from '@/classes/base.view';
import template from './template.ejs';
import CabinetProductsWidgetsHelper from './b-cabinet-products-widgets-helper/index';
import TableView from '@/blocks/layout/b-block-table/table';
import TableFooterView from '@/blocks/layout/b-block-table/table_footer';
import axios from 'axios';
import $ from 'jquery';
import GlUl from '@/utils/global-utils';
import Formatter from '@/utils/formatter';

export default BaseView.extend({
	template,

	events: {
		'click .p-search__submit': 'apply',
		'click .b-block-container__filters-trigger': 'toggleFilters',
		'click .js-scroll-btn': 'scrollTo',
	},

	productUidList: [
		'TRANSFER',
		'HOTEL_RESERVATION',
		'AIR_TICKET',
		'RAILWAY_PRODUCT',
		'INSURANCE_ACCIDENT',
		'TAXI',
		'OTHER',
		'MISC_PRODUCT',
		'AEROEXPRESS',
		'INSURANCE_TOURISTIC',
		'CAR_RENTAL',
		'VISA',
		'VISA_PRODUCT',
		'BUS',
	],

	statusesOptions: [],
	productTypesOptions: [],
	widgetsHelper: null,

	preinitialize(options) {
		BaseView.prototype.preinitialize.call(this, options);
		this.windowEventListenerList.push({
			mediaQuery: '(max-width: 768px)',
			name: 'change',
			callback: this.adjustMobileTemplate.bind(this),
			isMatchMedia: true,
		});
	},

	initialize(options) {
		const { data } = this.options;
		this.tablePageHistory = [0];

		this.statusesOptions = [
			{
				uid: '',
				caption: L10N.get('cabinet.products.statuses'),
			},
		].concat(_.sortBy(options.settings.statuses, ['caption']));

		this.productTypesOptions = [
			{
				uid: '',
				caption: L10N.get('cabinet.products.types'),
			},
		].concat(_.sortBy(options.settings.productTypes, ['caption']));

		this.widgetsHelper = new CabinetProductsWidgetsHelper({
			$parentViewEl: this.$el,
			parentModel: this.model,
			widgetsParams: [
				{
					key: 'statuses',
					items: this.statusesOptions,
					label: L10N.get('cabinet.products.bookingStatus'),
					placeholder: L10N.get('cabinet.products.statuses'),
					classes: 'b-products-page__input-multiselect',
					selector: '.js-statuses-multiselect',
				},
				{
					key: 'productTypes',
					items: this.productTypesOptions,
					label: L10N.get('cabinet.products.productType'),
					placeholder: L10N.get('cabinet.products.allTypes'),
					classes: 'b-products-page__input-multiselect',
					selector: '.js-product-types-multiselect',
				},
			],
		});

		this.apply = this.apply.bind(this);
		this.model.on('change:page', (model, value) => {
			this.tablePageHistory.push(value);
			this.apply();
		});

		this.windowEventListenerList.push({
			name: 'keydown',
			callback: GlUl.tablePageHistoryHandler.bind(this),
		});

		this.addWindowEventListeners();

		this.model.on('typeahead:contactSelect', (model, value) => {
			model.set('contactPersonRef', value === '' ? undefined : value.personRef);
		});
		this.model.on('change:contactPerson', (model, value) => {
			if (value === '' || !value) model.set('contactPersonRef', undefined);
		});
		this.model.on('afterchange:pageSize', this.apply);
		this.model.on('change', (model) => {
			const changed = _.omit(model.changed, ['page', 'count', 'pageSize']);
			if (_.isEmpty(changed)) return;

			GlUl.unsetPageOnFilterChange.call(this, model);
		});
		this.render();
		this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));

		if (!_.isEmpty(data)) {
			this.applyCallback(data);
		}
	},

	renderWidgets() {
		this.widgetsHelper?.renderWidgets?.();
	},

	render() {
		const container = this.$el;
		this.setElement(
			this.template(
				Object.assign({}, this.model.attributes, this.renderSettings),
			),
		);
		container.html(this.$el);
		this.renderWidgets();
	},

	delegateEvents(...args) {
		BaseView.prototype.delegateEvents.apply(this, args);
	},

	apply(e) {
		if (e && _.isFunction(e.preventDefault)) {
			e.preventDefault();
		}

		if ($('.l-layout').hasClass('show-filters')) this.toggleFilters();

		STATE.showLoader();
		if (e) this.disableElements(e);
		axios
			.post(
				'/midoffice/ibecorp-b2b/cabinetNew/getProducts',
				this.model.toJSON(),
			)
			.then(this.applyCallback.bind(this));
	},

	applyCallback(result) {
		STATE.hideLoader();

		const self = this;
		const { products, summary } = result.data.result;
		const { hideIncome } = this.options.settings || {};
		this.model.set('count', summary.count);
		this.$('.b-block-container__footer').html(
			new TableFooterView({
				model: this.model,
				data: summary,
				showMoreEventHandler: this.showMoreEventHandler.bind(this),
			}).el,
		);
		let fieldList = [
			{
				name: 'productType',
				noHeader: true,
				showIcon: false,
				classes: 'text-align-center mobile-hide',
				formatter: (productType, field, fields, rowData) => {
					let className = 'b-block-table__data-icon--';
					if (!productType || !self.productUidList.includes(productType.uid)) {
						className = `${className}UNKNOWN`;
					}
					className = `${className}${productType?.uid}`;
					if (['продажа', 'payed'].includes(rowData.status.toLowerCase())) {
						className = `${className}--PAYED`;
					}
					return `<span class="b-block-table__data-icon ${className}"
							data-toggle="tooltip" data-placement="bottom" data-title="${productType?.caption}"></span>`;
				},
			},
			{
				name: 'bookingNumber',
				caption: L10N.get('cabinet.products.bookingNumber'),
			},
			{
				name: 'pnr',
				caption: 'PNR',
			},
			{
				name: 'ticketNumber',
				caption: L10N.get('cabinet.products.ticketNumber'),
			},
			{
				name: 'productType',
				caption: L10N.get('cabinet.products.type'),
				formatter: (type, field, fields) => {
					if (!field || !fields) return type != null ? type.caption : '';
					let className = type && type.uid;
					if (
						!className ||
						![
							'TRANSFER',
							'HOTEL_RESERVATION',
							'AIR_TICKET',
							'RAILWAY_PRODUCT',
						].includes(type.uid)
					) {
						className = 'UNKNOWN';
					}
					field.iconClass = `b-block-table__cell-icon--${className}`;
					const routeField = _.find(fields, (f) => f.name === 'route');
					if (routeField) routeField.iconClass = field.iconClass;

					return type != null ? type.caption : '';
				},
			},
			{
				name: 'travellers',
				caption: L10N.get('cabinet.products.passengerFullName'),
				iconClass: 'b-block-table__cell-icon--travellers',
				formatter: (travellers) => {
					return travellers != null && travellers.length > 0
						? travellers[0]
						: '';
				},
			},
			{
				name: 'subagency',
				caption: L10N.get('cabinet.products.subAgency'),
				formatter: (subagency) => {
					return subagency != null && subagency.caption;
				},
			},
			{
				name: 'contactPerson',
				caption: L10N.get('bookingForm.customer'),
				formatter: (contactPerson) => {
					return contactPerson != null && contactPerson.caption;
				},
			},
			{
				name: 'client',
				caption: L10N.get('cabinet.orders.client'),
				formatter: (client) => {
					return client != null && client.caption;
				},
			},
			{
				name: 'route',
				caption: L10N.get('cabinet.products.route'),
				formatter: (route) => {
					return (
						(route != null &&
							`<span class="b-table-cell-overflow">${route}</span>`) ||
						''
					);
				},
			},
			{
				name: 'departureDate',
				caption: L10N.get('cabinet.products.departure'),
				iconClass: 'b-block-table__cell-icon--departureDate',
				formatter: (date) => {
					if (!date) {
						return date;
					}
					const momentDate = new Time(date);
					return momentDate.toDateString();
				},
			},
			{
				name: 'operationDate',
				caption: L10N.get('cabinet.products.operationDate'),
				formatter: (date) => {
					if (!date) {
						return date;
					}
					const momentDate = new Time(date);
					return momentDate.toDateString();
				},
			},
			{
				name: 'status',
				caption: L10N.get('cabinet.products.status'),
			},
			{
				name: 'amount',
				caption: L10N.get('cabinet.products.amount'),
				formatter: (value) => {
					return (
						value != null &&
						`${value} ${Formatter.formatCurrency(summary.currency)}`
					);
				},
			},
			{
				name: 'holdAmount',
				caption: L10N.get('filters.holdFunds'),
				formatter: (value) => {
					return (
						value != null &&
						`${value} ${Formatter.formatCurrency(summary.currency)}`
					);
				},
			},
			{
				name: 'income',
				caption: L10N.get('cabinet.products.income'),
				formatter: (value) => {
					return (
						value != null &&
						`${value} ${Formatter.formatCurrency(summary.currency)}`
					);
				},
			},
			{
				name: 'isOnlineBooking',
				caption: L10N.get('cabinet.products.isOnlineBooking'),
				formatter: (isOnlineBooking) => {
					const isOnline = (isOnlineBooking || '').toString() === 'true';
					if (this.isMobileTemplate) return isOnline ? L10N.get('cabinet.products.isOnlineBooking') : '';
					return isOnline
						? L10N.get('cabinet.products.yes')
						: L10N.get('cabinet.products.no');
				},
			},
			{
				name: 'isTripartiteDiscount',
				noHeader: true,
				showIcon: false,
				classes: 'text-align-center',
				formatter: (value) => {
					if (!value) return '';
					return `<span class="b-block-table__data-icon--3D g-icon-features-3D-contract"
							data-toggle="tooltip" data-placement="bottom" data-title="${L10N.get(
						'filters.tripartite',
					)}"></span>`;
				},
			},
		];
		if (hideIncome) fieldList = _.filter(fieldList, (el) => el.name !== 'income');
		this.$('.b-block-container__content').html(
			new TableView({
				clickField: {
					value: 'bookingUid',
					path: 'cabinet/order/',
					linkFieldName: 'bookingNumber',
				},
				showEmptyCellsOnSides: false,
				fields: fieldList,
				data: products,
			}).el,
		);
		if (this.isMobileTemplate) {
			const pageSize = this.model.get('pageSize');
			this.$('.js-scroll-btn')[
				summary.count && pageSize > 10 ? 'show' : 'hide'
			]();
		}
	},

	adjustMobileTemplate(matches) {
		clearTimeout(this.timer);
		if (_.isObject(matches)) matches = matches.matches;
		this.isMobileTemplate = matches;
		this.timer = setTimeout(() => {
			const $cabinetFilters = $('.b-cabinet__filters');
			const $topControls = this.$('.b-block-container__top-controls');
			const $topControlsElements = $topControls.find(
				'.b-block-container__element:not(.b-block-container__element--search)',
			);

			if (matches) {
				_.each(
					$topControlsElements,
					(el) => el && $cabinetFilters.append($(el).detach()),
				);
				$cabinetFilters
					.find('.p-search__submit')
					.on('click.filtersApply', this.apply.bind(this));
			} else {
				$cabinetFilters
					.find('.p-search__submit')
					.off('click.filtersApply', this.apply.bind(this));
				_.each($cabinetFilters.find('.b-block-container__element'), (el) => {
					$topControls.append($(el).detach());
				});
			}
		}, 100);
	},

	showMoreEventHandler(e) {
		const count = this.model.get('pageSize');
		if (count >= this.model.get('count')) {
			if (e) $(e.currentTarget).hide();
			return;
		}
		this.model.set('pageSize', count + 10);
		this.apply(e);
	},

	scrollTo(e) {
		const scrollTop = $(e.currentTarget).hasClass('top');
		$(e.currentTarget).toggleClass('top', !scrollTop);

		if (scrollTop) {
			this.previousScrollPosition = $(window).scrollTop();
			$(window).scrollTop(0);
		} else {
			$(window).scrollTop(this.previousScrollPosition);
		}
	},

	toggleFilters() {
		const $lLayout = $('.l-layout');
		$lLayout.toggleClass('show-filters', !$lLayout.hasClass('show-filters'));
	},
});
