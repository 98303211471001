module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (_.isEmpty(images)) { ;
__p += '\n    <div class="b-hotel__photo no_image">\n        <div class="b-hotel__photo-not-available b-slider__image-not-available"></div>\n    </div>\n';
 } else { ;
__p += '\n    <div class="b-hotel__photo">\n        <div class="b-hotel__mobile-slider-controls">\n            <div class="b-hotel__mobile-slide-indicator">\n                ';
 if (_.size(images) > 1) { ;
__p += '\n                    1/' +
((__t = ( _.size(images) + 1 )) == null ? '' : __t) +
'\n                ';
 } ;
__p += '\n            </div>\n            <div class="b-hotel__mobile-close-slider-btn"></div>\n        </div>\n        <div class="b-hotel__slider">\n            ';
 if (_.size(images) > 1) { ;
__p += '\n                <div class="b-hotel__slider-control b-hotel__slider-control--type-prev" style="display: none"></div>\n                <div class="b-hotel__slider-control b-hotel__slider-control--type-next" style="display: none"></div>\n            ';
 } ;
__p += '\n            <div class="b-hotel__slider-list">\n                ';
 _.each(images, function (image) { ;
__p += '\n                    <div class="b-hotel__slider-slide" data-image-uid="' +
((__t = ( image.uid )) == null ? '' : __t) +
'">\n                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>\n                    </div>\n                ';
 }) ;
__p += '\n            </div>\n        </div>\n    </div>\n';
 } ;
__p += '\n';

}
return __p
}