// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

import EmployeesCollectionView from '@/blocks/pages/b-search-form/b-company-employees/b-employees/b-employees-collection/index';

import axios from 'axios';

export default BaseView.extend({

	template,

	ui: {
		employeesContainer: '.b-search-room-employees',
	},

	events: {
		'click .b-search-room__remove': 'removeRoom',
	},

	initialize() {
		this.render();
		this.renderEmployees();
	},

	removeRoom() {
		this.model.destroy();
		this.destroy();
	},

	renderEmployees() {
		const travellersCollection = this.model.get('travellers');

		if (this.options.searchModel.get('clientHasChanged')) {
			this.model.get('travellers').reset([]);
			this.options.searchModel.set('clientHasChanged', false);
		}

		this.travellersView = new EmployeesCollectionView({
			company: this.options.company,
			collection: travellersCollection,
			container: this.ui.employeesContainer,
			bindingProperty: 'travellers',
		});

		if (travellersCollection.size() === 1 && this.model.collection.size() === 1) {
			axios.get('/midoffice/ibecorp-b2b/autocomplete/organization-employees', {
				params: {
					pattern: '',
					limit: 2,
					uid: this.options.company.uid,
				},
			}).then((resp) => {
				if (resp.data.result.length === 1) { // in case of single person set it by default
					if (travellersCollection.at(0).get('travellers') == null) {
						travellersCollection.at(0).set('travellers', resp.data.result[0]);
						travellersCollection.trigger('add');
					}
				}
			});
		}
		return this;
	},

});
