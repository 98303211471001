// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

export default BaseView.extend({

	el: '.b-attachment',

	template,

	initialize() {
		this.model = this.options.model;
		this.render();
	},

});
