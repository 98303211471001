module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-selected-variant">\r\n	';

	var numbers = this.selectedVariant.numbers;
	var carriage = this.selectedVariant?.carriage;
	var isMobileTemplate = isMobileTemplate || false;
	var noSeatSelection = noSeatSelection || false;
	var pricingTooltipTitle = pricingTooltipTitle || null;
	var isMinPriceRangeComponent = isMinPriceRangeComponent || false;

	if (numbers != null && carriage != null) {
		var numbersMap = numbers.map(function (n) { return parseInt(n.number, 10); });
		var minNumber = _.min(numbersMap);
		var maxNumber = _.max(numbersMap);
		var numbersString = '';
		if (minNumber != maxNumber) {
			numbersString = L10N.get('trains.seatsRange') + ': <strong>';
			numbersString += minNumber + '&mdash;' + maxNumber;
			numbersString += '</strong>';
		} else {
			numbersString = L10N.get('trains.selectedSeats') + ': <strong>';
			numbersString += minNumber;
			numbersString += '</strong>';
		} ;
__p += '\r\n\r\n		';
 if (this.genderSelectControl && !isMobileTemplate) { ;
__p += '\r\n			<div class="b-selected-variant__controls">\r\n				<div class="b-selected__gender">\r\n					<span class="js-widget"  onclick=\'return new Widgets.Select({ bindingProperty: "gender", values: "' + genders + '", placeholder: L10N.get("trains.selectCarriageType") })\'></span>\r\n				</div>\r\n			</div>\r\n		';
 } ;
__p += '\r\n\r\n		<div class="b-selected__places">\r\n			';
 if (noSeatSelection) { ;
__p += '\r\n                ' +
((__t = ( L10N.get('trains.noSeatSelectionNotification') )) == null ? '' : __t) +
'\r\n            ';
 } else { ;
__p += '\r\n                ' +
((__t = ( numbersString )) == null ? '' : __t) +
' ' +
((__t = ( L10N.get('trains.inCarriage') )) == null ? '' : __t) +
' <span class="carriage-number">№' +
((__t = ( carriage.number )) == null ? '' : __t) +
'</span>\r\n            ';
 } ;
__p += '\r\n		</div>\r\n\r\n		<div class="b-selected__price" ';
 if (pricingTooltipTitle) { ;
__p += ' data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="' +
((__t = ( pricingTooltipTitle )) == null ? '' : __t) +
'" ';
 } ;
__p += '>\r\n			' +
((__t = ( L10N.get('trains.totalSum') )) == null ? '' : __t) +
'\r\n            ';
 if (isMinPriceRangeComponent === true) { ;
__p +=
((__t = ( (' ' + L10N.get('pricing.from')).toLowerCase() )) == null ? '' : __t) +
' ';
 } ;
__p += '\r\n			<span class="b-selected__price-value">\r\n				' +
((__t = ( Formatter.formatMoney(totalPrice.amount, Formatter.getDecimal(totalPrice.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(currencyCode) )) == null ? '' : __t) +
'\r\n			</span>\r\n		</div>\r\n    ';
 if (isMobileTemplate) { ;
__p += '<div class="b-selected-variant__buttons-container">';
 } ;
__p += '\r\n		';
 if (issueAllowed) { ;
__p += '\r\n			<div class="b-selected-variant__booking">\r\n				';
 if (!this.processButtonEnabled) { ;
__p += '\r\n					<span ' +
((__t = ( (this.message) ? 'data-toggle="tooltip" data-placement="bottom" title="' + this.message + '"' : '' )) == null ? '' : __t) +
' >\r\n						<button class="btn btn-dark btn-disabled" disabled>' +
((__t = ( L10N.get('trains.continue') )) == null ? '' : __t) +
'</button>\r\n					</span>\r\n				';
 } else { ;
__p += '\r\n					<button class="btn btn-dark">' +
((__t = ( L10N.get('trains.continue') )) == null ? '' : __t) +
'</button>\r\n				';
 } ;
__p += '\r\n			</div>\r\n		';
 } ;
__p += '\r\n		';
 if (approvalAllowed) { ;
__p += '\r\n			<div class="b-selected-variant__offer">\r\n				';
 if (!this.processButtonEnabled) { ;
__p += '\r\n					<span ' +
((__t = ( (this.message) ? 'data-toggle="tooltip" data-placement="bottom" title="' + this.message + '"' : '' )) == null ? '' : __t) +
' >\r\n						<button class="btn btn-dark btn-disabled" disabled>' +
((__t = ( L10N.get('trains.addToAgree') )) == null ? '' : __t) +
'</button>\r\n					</span>\r\n				';
 } else { ;
__p += '\r\n					<button class="btn btn-dark">' +
((__t = ( L10N.get('trains.addToAgree') )) == null ? '' : __t) +
'</button>\r\n				';
 } ;
__p += '\r\n			</div>\r\n		';
 } ;
__p += '\r\n		 ';
 if (this.intentionData) { ;
__p += '\r\n			';
 if (!this.processButtonEnabled) { ;
__p += '\r\n				<span ' +
((__t = ( (this.message) ? 'data-toggle="tooltip" data-placement="bottom" title="' + this.message + '"' : '' )) == null ? '' : __t) +
' >\r\n					<button class="btn btn-dark btn-disabled" disabled>' +
((__t = ( L10N.get('cabinet.orders.doBooking') )) == null ? '' : __t) +
'</button>\r\n				</span>\r\n			';
 } else { ;
__p += '\r\n				<button class="btn btn-dark btn btn-dark b-order__intention-approval passenger-data__preview-intention-approval">' +
((__t = ( L10N.get('cabinet.orders.doBooking') )) == null ? '' : __t) +
'</button>\r\n			';
 } ;
__p += '\r\n		';
 } ;
__p += '\r\n        ';
 if (isMobileTemplate) { ;
__p += '</div>';
 } ;
__p += '\r\n	';
 } ;
__p += '\r\n</div>\r\n';

}
return __p
}