// eslint-disable-next-line banned-modules
'use strict';

import axios from 'axios';
import BasePageView from '@/pages/base.page-view';
import OrdersView from '@/blocks/pages/b-cabinet/b-cabinet-orders/index';
import CabinetMenu from '@/blocks/pages/b-cabinet/b-cabinet-menu/index';

import OrdersFormModel from './model';

export default BasePageView.extend({

	initialize(options) {
		this.render();
		this.ui.content.append(new OrdersView({
			settings: options.settings,
			parent: this,
			model: new OrdersFormModel(),
		}).$el);
		this.ui.sidebar.append(new CabinetMenu().$el);
		this.ui.sidebar.append(`<div class="l-block__back"><div class="b-cabinet__filters"></div></div>`);
	},
}, {
	load() {
		return new Promise((resolve) => {
			Promise.all([
				axios.get('/midoffice/ibecorp-b2b/cabinet/getOrderFormData'),
			]).then((response) => {
				const settings = response[0].data.result;

				resolve({
					settings,
					initScrollTopButton: true,
				});
			});
		});
	},
});
