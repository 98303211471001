// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

export default BaseView.extend({

	template,

	events: {
		'click .b-search-bar-sorter__button': 'sort',
	},

	initialize(options) {
		this.options = options;
		this.count = this.options.count;
		this.parent = this.options.parent;
		this.parent.ui.searchProgressBarContainer.addClass('finished');

		this.render();
	},

	sort() {
		const {doSort, onSortClicked } = this.options;
		if (doSort) {
			doSort();
		}
		if (onSortClicked) {
			onSortClicked();
		}
	},

	destroyView() {
		this.remove();
		return null;
	},

});
