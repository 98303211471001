module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<section class="b-visa-info">\r\n	';
 if (!fromEmail) { ;
__p += '\r\n		<header class="b-visa-info__header">' +
((__t = ( L10N.get("docoDoca.visaInfo") )) == null ? '' : __t) +
'</header>\r\n	';
 } ;
__p += '\r\n	<div class="b-visa-info__info">\r\n		';
 if (!fromEmail) { ;
__p += '\r\n			<div class="b-visa-info__info-header">\r\n				<div class="l-grid-container">\r\n					<span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "noVisaInfo", label: L10N.get("docoDoca.hasVisaInfo_false") })\'></span>\r\n				</div>\r\n			</div>\r\n		';
 } ;
__p += '\r\n		<div class="b-visa-info__info-header-warning">\r\n			' +
((__t = (L10N.getUnescaped("docoDoca.warning"))) == null ? '' : __t) +
'\r\n		</div>\r\n		<div class="b-visa-info__passengers-container">\r\n			<div class="b-visa-info__passengers"></div>\r\n			<div class="l-grid-container-overlay"></div>\r\n		</div>\r\n	</div>\r\n</section>\r\n';

}
return __p
}