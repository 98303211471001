/* eslint-disable banned-modules */
'use strict';

import $ from 'jquery';
import axios from 'axios';
import BaseView from '@/classes/base.view';
import template from './template.ejs';
import Auth from '@/blocks/pages/b-login/b-auth/index';
import Restore from '@/blocks/pages/b-login/b-restore/index';
import Registration from '@/blocks/pages/b-login/b-registration/index';
import CompaniesView from '@/blocks/pages/b-login/b-company/index';
import InputWidget from '@/widgets/b-input/index';
import TemplateLoginButton from './templateLoginButton.ejs';
import Model from './model';
import './b-login.less';

export default BaseView.extend({

	template,

	el: 'body',

	elm: {
		formContainer: '.p-login__form',
		loginButton: '.js-login',
		restoreButton: '.js-restore',
		registerButton: '.js-register',
		restoreForm: '.p-login__form-restore',
		restoreEmailed: '.p-login__form-notify-email-new',
		restoreSuccess: '.p-login__form-notify-email-generated',
		loginWrapper: '.js-login-wrapper',
		loginButtonContainer: '.js-login-button-container',
	},

	events: {
		'click .js-registration-link': 'showRegistrationForm',
		'click .js-restore-link': 'showRestoreForm',
		'click .js-auth-link': 'showAuthForm',
		'click .js-login': 'login',
		'click .js-register': 'register',
		'click .js-restore': 'restore',
		'click .b-input__select:not(.ignore-validation)': 'selectValidation',
	},

	preinitialize(options) {
		BaseView.prototype.preinitialize.call(this, options);
		this.windowEventListenerList.push({
			mediaQuery: '(max-width: 768px)',
			name: 'change',
			callback: this.adjustMobileTemplate.bind(this),
			isMatchMedia: true,
		});
	},

	initialize() {
		const {generalSettings} = STATE.getSettings() || {};
		if (STATE.checkSiteType('B2C') && !(generalSettings && generalSettings.personalAccountEnabled)) {
			return;
		}
		this.handleLoginDisabledOnPersonTypeChange = this.handleLoginDisabledOnPersonTypeChange.bind(this);
		this.handleLoginDisabledOnEmployerChange = this.handleLoginDisabledOnEmployerChange.bind(this);
		this.model = new Model();
		this.siteTypeSettings = STATE.getSiteTypeSettings();
		this.restoreDisabled = true;
		this.loginDisabled = true;
		this.registerDisabled = true;
		this.widgetClasses = STATE.checkSiteType('B2C') ? 'is-b2c-login-input' : '';
		this.render();
		this.createForms(true);
		this.validation(this.model);
	},

	validation(model) {
		model.on('change', () => {
			const loginNotEmpty = (model.get('login') != null && model.get('login').length !== 0);
			const passwordNotEmpty = (model.get('password') != null && model.get('password').length !== 0);

			this.restoreDisabled = !loginNotEmpty;
			this.loginDisabled = !(loginNotEmpty && passwordNotEmpty);
			this.registerDisabled = !(loginNotEmpty && passwordNotEmpty);

			this.$(this.elm.registerButton).prop('disabled', this.registerDisabled);
			this.$(this.elm.restoreButton).prop('disabled', this.restoreDisabled);
			this.$(this.elm.loginButton).prop('disabled', this.loginDisabled);
		});
	},

	delegateEvents(...args) {
		BaseView.prototype.delegateEvents.apply(this, args);
	},

	undelegateEvents(...args) {
		BaseView.prototype.undelegateEvents.apply(this, args);
	},

	adjustMobileTemplate(matches, firstCall) {
		if (STATE.checkSiteType('B2C') || (this.$el.css('display') === 'none' && !firstCall)) return this;

		if (_.isObject(matches)) matches = matches.matches;

		const $currentViewEl = _.find([this.restoreView, this.authView, this.registrationView, this.companyView], (v) => {
			return v && v.$el.css('display') !== 'none';
		}).$el;
		const $loginButtonContainer = this.$(this.elm.loginButtonContainer).length ? this.$(this.elm.loginButtonContainer).detach() :
			this.$prevViewEl.find(this.elm.loginButtonContainer).detach();

		this.$prevViewEl = $currentViewEl;

		if (matches) {
			if ($currentViewEl.find('.p-login__form-restore').length) return $currentViewEl.find('.p-login__form-restore').append($loginButtonContainer);
			$currentViewEl.append($loginButtonContainer);
		} else {
			this.$prevViewEl = this.$(this.elm.loginWrapper).append($loginButtonContainer);
		}

		$loginButtonContainer.toggleClass('static-flow', matches);

		return this;
	},

	selectValidation() {
		if (this.model.get('selectedPreferences')) {
			const notSelected = document.querySelectorAll('.b-input__select-value-label:not(.ignore-validation) .placeholder');
			this.loginDisabled = notSelected.length !== 0;
			this.$(this.elm.loginButton).prop('disabled', this.loginDisabled);
		}
	},

	createForms(firstCall = false) {
		this.restoreView = new Restore({
			model: this.model,
			widgetClasses: this.widgetClasses,
		});
		this.authView = new Auth({
			model: this.model,
			widgetClasses: this.widgetClasses,
		});
		this.registrationView = new Registration({
			model: this.model,
			widgetClasses: this.widgetClasses,
		});
		this.$(this.elm.formContainer).empty();
		this.$(this.elm.loginButtonContainer).remove();
		this.$(this.elm.loginWrapper).append(TemplateLoginButton());
		this.$(this.elm.formContainer).append(this.registrationView.$el);
		this.$(this.elm.formContainer).append(this.restoreView.$el);
		this.$(this.elm.formContainer).append(this.authView.$el);
		this.$(this.elm.loginButton).show();
		this.$(this.elm.registerButton).hide();
		this.restoreView.$el.hide();
		this.registrationView.$el.hide();

		this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'), firstCall);

		if (!STATE.checkSiteType('B2C')) {
			this.addEventListeners();
			this.handleLoginBtnOnStart();
		}
	},

	addEventListeners() {
		this.$('.p-login__form-auth .p-login__form-input').keyup(e => {
			$(e.currentTarget).change();

			if (e.which === 13 && !this.loginDisabled) {
				this.login();
			}
		});
	},

	handleLoginBtnOnStart() {
		setTimeout(() => {
			if (this.loginDisabled) {
				const $inputs = this.$('.p-login__form-auth .p-login__form-input');
				$inputs.each((i, el) => {
					/*
						В хроме не будет value, но сработает matches(). В FF наоборот.
					*/
					let autoFilled = false;
					try {
						autoFilled = (el.matches && (el.matches('*:-webkit-autofill') || el.matches(':autofill')));
					} catch (error) {
						logger.error(error);
					}
					if (el.value || autoFilled) {
						this.loginDisabled = false;
						this.$(this.elm.loginButton).prop('disabled', this.loginDisabled);
					}
				});
			}
		}, 700); // хром срабатывает на 300, FF работает без таймаута, 700 нужно для яндекс браузера
	},

	showLoginView() {
		this.$el.css('display', 'flex');
		if (!STATE.checkSiteType('B2C')) this.handleLoginBtnOnStart();
	},

	hideLoginView() {
		this.$el.css('display', 'none');
	},

	showButtons() {
		if (!STATE.checkSiteType('B2C')) this.$(this.elm.formContainer).removeClass('hide-buttons');
	},

	showRestoreForm() {
		this.authView.$el.hide();
		this.restoreView.$el.show();
		this.registrationView.$el.hide();
		this.$(this.elm.loginButton).hide();
		this.$(this.elm.restoreButton).show();
		this.$(this.elm.registerButton).hide();
		this.showButtons();
		this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));
	},

	showAuthForm() {
		this.authView.$el.show();
		this.restoreView.$el.hide();
		this.registrationView.$el.hide();
		this.$(this.elm.loginButton).show();
		this.$(this.elm.restoreButton).hide();
		this.$(this.elm.registerButton).hide();
		this.showButtons();
		this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));
	},

	showRegistrationForm() {
		this.authView.$el.hide();
		this.restoreView.$el.hide();
		this.registrationView.$el.show();
		this.$(this.elm.loginButton).hide();
		this.$(this.elm.restoreButton).hide();
		this.$(this.elm.registerButton).show();
		this.showButtons();
		this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));
	},

	showRestoreSuccess() {
		this.authView.$el.hide();
		this.restoreView.$el.show();
		this.registrationView.$el.hide();
		this.$(this.elm.restoreForm).hide();
		this.$(this.elm.loginButton).hide();
		this.$(this.elm.restoreButton).hide();
		this.$(this.elm.registerButton).hide();
		this.$(this.elm.restoreSuccess).show();
		this.showButtons();
	},

	register(e) {
		if (e) {
			e.preventDefault();
		}
		const params = this.model.toJSON();
		const url = '/midoffice/ibecorp-b2b/cabinet/registerB2CCabinetHolder';
		params.parameters.captcha = '';
		params.parameters.passwordConformation = this.model.get('password');
		STATE.showLoader();
		this.registerDisabled = true;
		this.$(this.elm.registerButton).prop('disabled', this.registerDisabled);
		axios.post(url, params, this.model).then(result => {
			STATE.hideLoader();
			if (result.data.result.person) {
				STATE.login(result.data.result.person);
				this.login(e);
			} else {
				logger.error('Not authorized', result);
			}
		});
		return false;
	},

	restore(e, $container = this.$el) {
		if (e) {
			e.preventDefault();
		}
		this.disableElements(e);
		return axios.post('/midoffice/ibecorp-b2b/authorization/recovery', this.model.toJSON(), this.model).then(() => {
			$container.find(this.elm.formContainer).addClass('hide-buttons');
			$container.find(this.elm.restoreButton).hide();
			$container.find(this.elm.restoreForm).hide();
			$container.find(this.elm.restoreEmailed).show();
		});
	},

	login(e) {
		if (e) {
			e.preventDefault();
		}

		return new Promise(((resolve) => {
			let params = this.model.toJSON();
			const url = '/midoffice/ibecorp-b2b/authorization/login';
			if (this.model.get('selectedPreferences') != null) {
				params = _.extend({}, {
					parameters: {
						selectedPreferences: {
							employer: params.parameters.selectedPreferences.employer,
							personType: params.parameters.selectedPreferences.personType,
							defaultSalesPoint: params.parameters.selectedPreferences.defaultSalesPoint,
						},
						login: params.parameters.login,
						password: params.parameters.password,
					},
				});
				if (params.parameters.selectedPreferences.defaultSalesPoint && params.parameters.selectedPreferences.defaultSalesPoint.uid === 'NOT_SELECTED') {
					delete params.parameters.selectedPreferences.defaultSalesPoint;
				}
			}
			if (this.model.get('code') != null) params.parameters.code = this.model.get('code');

			STATE.showLoader();
			STATE.setLoginUser(null);
			this.disableElements(e);

			this.loginDisabled = true;
			this.$(this.elm.loginButton).prop('disabled', this.loginDisabled);

			axios.post(url, params, this.model).then((response) => {
				this.loginHandler.call(this, response);
				resolve(true);
			}).catch((error) => {
				if (error &&
					error.validatedParameters != null &&
					error.validatedParameters.code != null &&
					(this.twoFactorPopup == null || !$('.b-login-two-factor__popup').length)) {
					const widget = new InputWidget({
						bindingProperty: 'code',
						placeholder: L10N.get('loginForm.authCode'),
					});
					widget.applyBinding(this.model);

					const $container = $('<div class="l-grid-container"></div>');
					$container.append($('<div class="b-login-two-factor__input"></div>').append(widget.$el));
					$container.append(
						$(`<div class="b-login-two-factor__tip">${L10N.get('loginForm.confirmationCode')}</div>`));

					widget.$el.find('input').keyup((evt) => {
						if (evt.which === 13 && !this.loginDisabled) {
							this.login();
						}
					});

					this.twoFactorPopup = new Widgets.Popup({
						title: L10N.get('loginForm.checkPermissions'),
						content: $container,
						closeOnlyOnAction: true,
						actions: [{
							label: L10N.get('loginForm.back'),
							action: () => {
								this.model.unset('code');
								this.twoFactorPopup.hide();
								this.twoFactorPopup = null;
							},
						}, {
							label: L10N.get('loginForm.confirm'),
							action: () => {
								this.login();
							},
						}],
						classes: 'b-login-two-factor__popup',
					});
					this.twoFactorPopup.show();
					this.hideValidations();
				} else if (this.twoFactorPopup != null) {
					this.twoFactorPopup.show();
					this.hideValidations();
				}
				resolve(false);
			});
		}));
	},

	loginHandler(response) {
		const data = response.data.result;
		if (data.selectablePreferences != null && this.twoFactorPopup != null) this.twoFactorPopup.hide();
		STATE.setLoginUser(data);
		const completeCallback = () => {
			STATE.APP.initialize(() => {
				if (this.twoFactorPopup != null) {
					this.twoFactorPopup.hide();
					this.twoFactorPopup = null;
				}
				this.model.unset('code');

				this.createForms();
				STATE.hideLoader();
				STATE.hideLogin();
				if (STATE.checkSiteType('B2C') && STATE.cabinetView != null) STATE.cabinetView.rerender();
				this.model.clear();
			});
		};

		const loadCompaniesView = () => {
			STATE.hideLoader();

			this.companyView = new CompaniesView({
				employers: data.selectablePreferences.employers,
				personTypes: data.selectablePreferences.personTypes,
				salesPoints: data.selectablePreferences.agencySalesPoints,
				model: this.model,
				parent: this,
			});
			this.authView.$el.hide();
			this.$(this.elm.formContainer).html(this.companyView.$el);

			this.model.on('change:selectedPreferences.personType', this.handleLoginDisabledOnPersonTypeChange);
			this.model.on('change:selectedPreferences.employer', this.handleLoginDisabledOnEmployerChange);

			if (STATE.checkSiteType('B2B')) this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));
		};

		const {employers, personTypes, agencySalesPoints} = data.selectablePreferences || {};

		if (employers != null && employers.length >= 1) {
			loadCompaniesView();
		} else if (personTypes != null && personTypes.length >= 1) {
			loadCompaniesView();
		} else if (agencySalesPoints != null && !_.isEmpty(agencySalesPoints)) {
			loadCompaniesView();
		} else if (!_.isEmpty(personTypes)) {
			const agentType = _.find(personTypes, (el) => el && el.uid === 'AGENT');

			if (agentType != null) {
				this.model.set('selectedPreferences', {
					personType: agentType,
				});

				this.login();
			} else {
				completeCallback();
			}
		} else {
			completeCallback();
		}
	},

	handleLoginDisabledOnEmployerChange(model) {
		this.handleLoginDisabledOnSelectChange('employer', model);
	},

	handleLoginDisabledOnPersonTypeChange(model) {
		this.handleLoginDisabledOnSelectChange('personType', model);
	},

	handleLoginDisabledOnSelectChange(fieldName, model) {
		this.loginDisabled = !model?.get('selectedPreferences')[fieldName];
		this.$(this.elm.loginButton).prop('disabled', this.loginDisabled);
	},

	remove() {
		this.model.off('change:selectedPreferences.personType', this.handleLoginDisabledOnPersonTypeChange);
		this.model.off('change:selectedPreferences.employer', this.handleLoginDisabledOnEmployerChange);
		BaseView.prototype.remove.call(this);
	},

});
