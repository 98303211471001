import React from "react";
import { FormItemControlProps } from "../FormItem/helpers/interfaces";
import { ReactUtils } from "../../../../js/utils/react-utils";
import FormItemValidation from "../FormItem/FormItemValidation/FormItemValidation";

export interface InputProps extends FormItemControlProps {
	label?: string;
	type?: string;
	classes?: string;
	placeholder?: string;
	deletableField?: boolean;
  disabled?: boolean;
}

const Input: React.FC<InputProps> = (props) => {
	const {
		label,
		type = "text",
		classes = "b-input__search",
		placeholder,
		deletableField = false,
    disabled = false,
		meta = { errors: [] },
		...restProps
	} = props;

	const hasErrors = meta.errors.length > 0;

	return (
		<label
			className={ReactUtils.classNames([
				"b-input",
				classes,
				hasErrors ? "validation-error" : "",
			])}
		>
			{label && <span className="b-input__label">{label}</span>}

			<input
				className={`b-input__value ${
					!label ? " b-input__value--without-label" : ""
				}`}
				type={type}
				placeholder={placeholder}
				{...restProps}
        disabled={disabled}
			/>
			{deletableField && <div className="b-input__remove"></div>}
			<FormItemValidation errors={meta.errors} />
		</label>
	);
};

export default Input;
