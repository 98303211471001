// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';
import BaseCollection from '@/classes/base.collection';
import TravellerModel from '@/blocks/elements/b-traveller/model';

const BookingModel = BaseModel.extend({

	defaults() {
		return {
			customer: new BaseModel({
				phone: {
					cityCode: '',
					countryCode: STATE.getDefaultCountryCode(),
					number: '',
				},
			}),
			passengers: new BaseCollection({
				model: TravellerModel,
			}),
		};
	},

	toJSON() {
		const data = BaseModel.prototype.toJSON.call(this);

		if (!_.isEmpty(data.passengers)) {
			data.passengers = data.passengers.map(passenger => {
				if (data.noVisaInfo != null && data.noVisaInfo === true) {
					delete passenger.docoInfo;
					delete passenger.docaInfo;
				}
				if (passenger.isMiddleNameRequired) {
					delete passenger.isMiddleNameRequired;
				}
				if (passenger.bonusCard && !passenger.bonusCard.number) {
					delete passenger.bonusCard;
				}

				const isAdult = passenger.type != null && ['ADULT', 'YOUTH', 'SENIOR'].includes(passenger.type);
				if (!isAdult) {
					delete passenger.phone;
					delete passenger.email;
				}

				if (_.isObject(passenger.phone) && (!passenger.phone.number)) {
					delete passenger.phone;
				}

				return passenger;
			});

			_.each(data.passengers, (passenger) => {
				passenger.passport.withoutMiddleName = (passenger.withoutMiddleName === true);
				if (passenger.passport != null && _.isObject(passenger.passport.type)) {
					passenger.passport.type.uid = passenger.passport.type.uid.split('.')[0];
				}
				delete passenger.withoutMiddleName;
			});
		}

		if (!_.isEmpty(data.customer)) {
			if (data.customer.overrideCustomer === true) {
				data.overrideCustomer = true;
			}

			delete data.customer.disabled;
			delete data.customer.overrideCustomer;
		}

		delete data.noVisaInfo;

		return {
			parameters: data,
		};
	},

	parse(resp) {
		let temp = resp;
		while (temp && temp.parameters) {
			temp = temp.parameters;
		}
		while (temp.customer && temp.customer.parameters) {
			temp.customer = temp.customer.parameters;
		}
		const result = Object.assign(
			{},
			temp,
			{
				customer: new BaseModel(temp.customer),
				passengers: new BaseCollection(temp.passengers, {
					model: TravellerModel,
					parse: true,
				}),
			},
		);
		return result;
	},

});

export default BookingModel;
