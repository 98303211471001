// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';

export default BaseModel.extend({

	defaults() {
		return {
			UPPER: 0,
			LOWER: 0,
		};
	},

	setOptions(options = {}) {
		this.maxSeats = options.maxSeats;
	},

	minus(type) {
		const total = Object.keys(this.defaults())
			.reduce((result, item) => result + parseInt(this.get(item)), 0);
		if (total > 0) {
			const value = parseInt(this.get(type));
			if (value > 0) {
				this.set(type, value - 1);
			}
		}
		this.trigger('change:seatsTypes');
	},

	plus(type) {
		const value = parseInt(this.get(type));
		const total = Object.keys(this.defaults())
			.reduce((result, item) => result + parseInt(this.get(item)), 0);

		if (!this.maxSeats || total < this.maxSeats) {
			this.set(type, value + 1);
		} else {
			const t = _.chain(this.defaults())
				.keys()
				.find((k) => this.get(k) > 0 && k !== type)
				.value();

			if (t) {
				this.minus(t);
				this.set(type, value + 1);
			}
		}
		this.trigger('change:seatsTypes');
	},

	toJSON() {
		return Object.keys(this.attributes).reduce((result, item) => {
			if (parseInt(this.get(item)) > 0 && _.keys(this.defaults()).includes(item)) {
				/*eslint-disable */
				result[item] = this.get(item);
				/*eslint-enable */
			}
			return result;
		}, {});
	},

});
