module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="w-time b-input">\n	';
 if (label) { ;
__p += '\n		<span class="b-input__label">' +
((__t = ( label )) == null ? '' : __t) +
'</span>\n	';
 } ;
__p += '\n	<div class="b-input__wrapper">\n		<input type="text" class="b-input__value ' +
((__t = ( classes )) == null ? '' : __t) +
'" placeholder="' +
((__t = ( placeholder )) == null ? '' : __t) +
'" />\n		<div class="w-time__remove dn"></div>\n	</div>\n</div>\n';

}
return __p
}