// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import $ from 'jquery';

export default Widget.extend({

	template,

	el: '.b-transliteration-popup',

	ui: {
		transliterationValue: '.js-transliteration-value',
	},

	events: {
		'click .js-transliteration-value': 'applyTransliteration',
		'click .js-close-popup': 'close',
	},

	preinitialize(options) {
		Widget.prototype.preinitialize.call(this, options);
		this.windowEventListenerList.push({
			name: 'scroll',
			callback: this.positionPopup.bind(this),
		});
		/*
			Добавляет уникальности элементу, чтобы при байндинге виджета к чему-либо, у нас была возможность
			различать элементы между собой и удалять их по отдельности.
		*/
		this._viewId = options.viewId
			|| this.cid
			|| `${Math.round(Math.random() * 100)}-${Math.round(Math.random() * 100)}`;
		this.el += ` ${this._viewId}`;
	},

	initialize(options) {
		this.options = options;
		this.bindingProperty = options.bindingProperty;
		this.targetInput = $(options.targetInput);
	},

	render(...args) {
		Widget.prototype.render.apply(this, args);
		this.rendered = true;
		STATE.getPageView().$el.append(this.$el);
		this.positionPopup();

		return this;
	},

	positionPopup() {
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			if (this.targetInput == null || this.$el.height() == null) return;
			const offset = this.targetInput.offset();
			const additionalSpace = 10;
			const top = (offset.top - (this.$el.height() + additionalSpace));
			const arrowCss = {
				left: this.$el.width() / 2,
				bottom: -4,
				top: 'unset',
			};
			this.$el.css({top, left: offset.left});
			this.$el.find('.arrow').css(arrowCss);
		}, 100);
	},

	applyTransliteration(e) {
		if (!e) return;
		if (!this.ui.transliterationValue) {
			this.close();
			return;
		}
		e.preventDefault();
		const value = this.ui.transliterationValue.data('transliteratedValue');
		this.$el.remove();
		this.model.set(this.bindingProperty, value);
	},

	applyTransliterationValueToEl(value) {
		if (!this.rendered) {
			this.render({value});
			this.$el.show();
		} else {
			this.ui.transliterationValue.html(value).attr('data-transliterated-value', value);
		}
	},

	close() {
		this.remove();
		this.unbind();
		this.rendered = false;
	},

	transliterate(e, value = null) {
		if (e) {
			e.preventDefault();
			value = value || $(e.currentTarget).val();
		}
		if (this.options.autoCorrectionAdapter
			&& this.options.autoCorrectionAdapter?.isTriggerProperty
			&& this.options.autoCorrectionAdapter.isTriggerProperty(this.bindingProperty)) {
			this.options.autoCorrectionAdapter.makeTranslitRequest({
				value,
				transliterationPopupInstance: this,
			});
		} else {
			if (_.isEmpty(value) || !T12N.testTransliterationWord(value)) {
				this.close();
				return;
			}
		
			value = T12N.transliterate(value);
		
			this.applyTransliterationValueToEl(value);
		}
	},

	applyBinding(model) {
		this.model = model;

		this.listenTo(model, `change:${this.bindingProperty}`, (_thisModel, value) => {
			if (!T12N.testTransliterationWord(value)) this.close();
		});

		if (this.targetInput != null) {
			this.targetInput.off('keyup change', this.transliterate.bind(this));
			this.targetInput.on('keyup change', this.transliterate.bind(this));
		}
	},

});
