module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var issueAllowed = issueAllowed || false;
;
__p += '\n\n<div class="b-transfers-transfer b-transfer b-transfer__visible" data-transfer-token="' +
((__t = ( this.model.get("transfer.token") )) == null ? '' : __t) +
'">\n    <div class="b-transfer__wrapper">\n        <div class="b-transfer__section b-transfer__info">\n            <div class="b-transfer__info-photo" style="background-image: url(\'' +
((__t = ( this.model.get("transfer.imageUrl") )) == null ? '' : __t) +
'\')"></div>\n            <div class="b-transfer__info-section b-transfer__info-details">\n                <div class="b-transfer__info-row b-transfer__car-class">\n                    <span class="b-transfer__car-class-text">' +
((__t = ( this.model.get("transfer.carClass").caption )) == null ? '' : __t) +
'</span>\n                </div>\n                <div class="b-transfer__info-row b-transfer__model-name">\n                    <span class="b-transfer__model-name-text">' +
((__t = ( this.model.get("transfer.models") )) == null ? '' : __t) +
'</span>\n                </div>\n                ';
 if (this.model.get("transfer.transferType") && this.model.get("transfer.transferType") === "RENT") { ;
__p += '\n                    <div class="b-transfer__minimal-rent-price"></div>\n                ';
 } ;
__p += '\n\n                ';
 if (this.model.get('isExceededCapacity')) { ;
__p += '\n                    <span class="b-transfer__info-capacity--exceeded-text desktop-hide" style="margin-top: 10px">' +
((__t = ( L10N.get("transfers.exceededCapacity") )) == null ? '' : __t) +
'</span>\n                ';
 } ;
__p += '\n            </div>\n            <div class="b-transfer__info-section b-transfer__info-restrictions">\n                <div class="b-transfer__info-row b-transfer__info-capacity">\n                    <span class="b-transfer__info-capacity-icon mobile-hide"></span>\n                    <span class="b-transfer__info-capacity-icon green desktop-hide"></span>\n                    <span class="b-transfer__info-capacity-text mobile-hide">\n                        ' +
((__t = ( L10N.get('Common.until'))) == null ? '' : __t) +
' ' +
((__t = ( this.model.get("capacity").uid )) == null ? '' : __t) +
' ' +
((__t = ( L10N.getPluralForm("transfers.capacity", this.model.get("capacity").uid) )) == null ? '' : __t) +
'\n                    </span>\n                    <span class="b-transfer__info-capacity-text desktop-hide">\n                        ' +
((__t = ( L10N.get('Common.until'))) == null ? '' : __t) +
' ' +
((__t = ( this.model.get("transfer.capacity").uid )) == null ? '' : __t) +
'\n                    </span>\n                </div>\n                ';
 if (this.model.get("isExceededCapacity")) { ;
__p += '\n                    <div class="b-transfer__info-row b-transfer__capacity--exceeded">\n                        <span class="b-transfer__info-capacity--exceeded-icon"></span>\n                        <span class="b-transfer__info-capacity--exceeded-text mobile-hide">' +
((__t = ( L10N.get("transfers.exceededCapacity") )) == null ? '' : __t) +
'</span>\n                    </div>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n        <div class="b-transfer__section b-transfer__price-block">\n            ' +
((__t = ( require("./template-price-block.ejs")({model: this.model, issueAllowed: issueAllowed}) )) == null ? '' : __t) +
'\n        </div>\n        ';
 if (this.model.get("transfer.travelPolicyCompliance") != null && !_.isEmpty(this.model.get("transfer.travelPolicyCompliance.rulesApplied"))) { ;
__p += '\n            <div class="b-transfer__travel-policy b-travel-policy">\n                ';
 var travelPolicyCompliance = this.model.get("transfer.travelPolicyCompliance");
                    _.each(travelPolicyCompliance.rulesApplied, function (rule, i) { ;
__p += '\n                        <span class="b-travel-policy-rule g-icon-travel-policy-rules-' +
((__t = ( rule.action.uid )) == null ? '' : __t) +
'" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="<div class=\'b-travel-policy-tooltip\'><div class=\'tooltip__header\'>' +
((__t = ( rule.action.caption )) == null ? '' : __t) +
'</div>\n                        ';
 if (_.size(rule.ruleDetails) > 0) { ;
__p += '<div class=\'tooltip__body\'>';
 _.each(rule.ruleDetails, function(ruleDetail){ ;
__p += '\n                            <div>' +
((__t = ( _.escape(ruleDetail) )) == null ? '' : __t) +
'</div>\n                        ';
 }); ;
__p += '</div>';
 } ;
__p += '</div>"></span>\n                    ';
 });
                 ;
__p += '\n            </div>\n        ';
 } ;
__p += '\n    </div>\n</div>\n';

}
return __p
}