module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-hotels-hotel b-hotel b-hotel__visible" data-hotel-number="' +
((__t = ( _.escape(this.model.get('number')) )) == null ? '' : __t) +
'">\n    <button class="b-hotel__mobile-close-btn">' +
((__t = ( L10N.get('avia.goBack') )) == null ? '' : __t) +
'</button>\n	<div class="b-hotel__wrapper">\n		<div class="b-hotel__photo-container js-slider"></div>\n\n		<div class="b-hotel__description-wrapper">\n			<div class="b-hotel__description">\n				';
  var stars = parseInt(this.model.get('hotelStars'), 10);  ;
__p += '\n				';
 if (stars > 0) { ;
__p += '\n					<div class="b-hotel__description-stars">\n						';
 for (var i = 0; i < stars; i++) { ;
__p += '\n							<span class="b-hotel-star"></span>\n						';
 } ;
__p += '\n					</div>\n				';
 } ;
__p += '\n<!--				';
 var rating = this.model.get('sabreRating'); ;
__p += ' IBECORP-5953 -->\n<!--				';
 if (rating) {;
__p += '-->\n<!--					<div class="b-hotel__description-rating">' +
((__t = ( L10N.get('hotels.hotelRating') )) == null ? '' : __t) +
': <span class="b-hotel-rating">' +
((__t = ( rating )) == null ? '' : __t) +
'</span></div>-->\n<!--				';
 } ;
__p += '-->\n				<div class="b-hotel__description-name" data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( this.model.get('name') )) == null ? '' : __t) +
'">' +
((__t = ( this.model.get('name') )) == null ? '' : __t) +
'</div>\n			</div>\n\n			<div class="b-hotel__description-border"></div>\n\n			<div class="b-hotel__description b-hotel__description-address-container">\n				';
 if (this.model.get('distance') && this.model.get('hotelType')) { ;
__p += '\n					';
 var hotelType = this.model.get('hotelType');
__p += '\n					<div class="b-hotel__description-distance"><span class="b-hotel__description-distance-hotelType">' +
((__t = ( hotelType.caption + ' ' )) == null ? '' : __t) +
'</span>' +
((__t = ( this.model.get('distance').toFixed(1).replace('.', ',') )) == null ? '' : __t) +
' ' +
((__t = ( L10N.get('hotels.distanceFromCenter') )) == null ? '' : __t) +
'</div>\n				';
 } else if (this.model.get('distance')) { ;
__p += '\n						<div class="b-hotel__description-distance">' +
((__t = ( this.model.get('distance').toFixed(1).replace('.', ',') )) == null ? '' : __t) +
' ' +
((__t = ( L10N.get('hotels.distanceFromCenter') )) == null ? '' : __t) +
'</div>\n					';
 } else if (this.model.get('hotelType')) { ;
__p += '\n						';
 var hotelType = this.model.get('hotelType');
__p += '\n						<div class="b-hotel__description-distance"><span class="b-hotel__description-distance-hotelType">' +
((__t = ( hotelType.caption )) == null ? '' : __t) +
'</span></div>\n					';
 } ;
__p += '\n\n				';
 if (this.model.get('address') != null) { ;
__p += '\n                    ';

                        var address = this.model.get('address');
                        var addressString = '';
                    ;
__p += '\n					<div class="b-hotel__description-address mobile-hide">\n						';
 addressString = _.compact([address.address, address.zipCode, (address.cityInfo && address.cityInfo.city || {}).caption, (address.country || {}).caption]).join(', '); ;
__p += '\n						' +
((__t = ( addressString )) == null ? '' : __t) +
'\n					</div>\n                    <div class="b-hotel__description-address js-show-map desktop-hide">\n                            ';
 addressString =  _.compact([address.address, address.zipCode]).join(); ;
__p += '\n                            ' +
((__t = ( addressString )) == null ? '' : __t) +
'\n                        </div>\n				';
 } ;
__p += '\n\n				';
 if (!_.isEmpty(this.model.get('phones'))) { ;
__p += '\n					<div class="b-hotel__description-contacts">\n						';
 var phones = _.map(this.model.get('phones'), function (phone) {
							return phone.value;
						}); ;
__p += '\n						' +
((__t = ( phones.join(', ') )) == null ? '' : __t) +
'\n					</div>\n				';
 } ;
__p += '\n\n                    ';
 if (this.isMapApiAvailable) { ;
__p += '\n                        <div class="b-hotel__description-show-map">\n                            <span class="b-hotel__description-show-map-button js-show-map">' +
((__t = ( L10N.get('hotels.showOnMapShort') )) == null ? '' : __t) +
'</span>\n                        </div>\n                    ';
 } ;
__p += '\n			</div>\n\n			';
 if (this.model.get('description')) { ;
__p += '\n				<div class="b-hotel__description-border-second"></div>\n				<div class="b-hotel__description lg-visible-block b-hotel__description-hotel-text">\n					<div>\n						' +
((__t = ( this.model.get('description')  )) == null ? '' : __t) +
'\n					</div>\n				</div>\n			';
 } ;
__p += '\n		</div>\n\n		<div class="b-hotel__providers single-provider b-hotel-offers-price-block">\n			' +
((__t = ( require('./b-offer-price-block.ejs')({ offers: [], model: this.model, showGdsAccountName: this.showGdsAccountName, parentView: this }) )) == null ? '' : __t) +
'\n		</div>\n\n		<div class="b-hotel__travel-policy b-travel-policy">\n			';

			var travelPolicyCompliance = this.model.get('travelPolicyCompliance');
			var tripartiteContract = this.model.get('tripartiteContract');
			;
__p += '\n			';
 if ((travelPolicyCompliance != null && !_.isEmpty(travelPolicyCompliance.rulesApplied)) || tripartiteContract === true) { ;
__p += '\n					' +
((__t = ( require('./b-travel-policy.ejs')({
						travelPolicyCompliance: travelPolicyCompliance,
						tripartiteContract: tripartiteContract
					}) )) == null ? '' : __t) +
'\n			';
 } ;
__p += '\n		</div>\n\n		<div class="b-expand collapsed b-hotel__expand b-hotel__expand--with-description"></div>\n\n        ';
 if (this.model.get('description')) { ;
__p += '\n            <div class="b-hotel__mobile-description-container">\n                <div class="b-hotel__mobile-description-text">' +
((__t = ( this.model.get('description')  )) == null ? '' : __t) +
'</div>\n                <div class="b-hotel__mobile-description-show-btn">' +
((__t = ( L10N.get('hotels.showFullText') )) == null ? '' : __t) +
'</div>\n            </div>\n        ';
 } ;
__p += '\n\n		<div class="b-hotel__select-offer">' +
((__t = ( L10N.get('hotels.selectRoom') )) == null ? '' : __t) +
'</div>\n	</div>\n	<div class="b-hotel__expand-container b-hotel-extended b-hotel-extended-description" style="display: none">\n		<div class="b-hotel__extended-wrapper">\n			<div class="b-hotel-extended__description">\n				<div class="b-hotel-extended__description-title">' +
((__t = ( L10N.get('hotels.description') )) == null ? '' : __t) +
'</div>\n				<div class="b-toggle-extended-description">\n					<div class="b-hotel-extended__description-top">\n						<div class="b-hotel-extended__description-text">\n							' +
((__t = ( this.model.get('description') ? this.model.get('description') : '<span class="b-hotel-extended__description-no-text">' + L10N.get('hotels.noDescription') + '</span>' )) == null ? '' : __t) +
'\n						</div>\n						';
 if (this.model.has('policyDescriptions') && this.model.get('policyDescriptions').length) { ;
__p += '\n						<div class="b-hotel-extended__policy">\n							<div class="b-hotel-extended__policy-title">' +
((__t = ( L10N.get('hotels.hotelPolicy') )) == null ? '' : __t) +
'</div>\n							<div class="b-hotel-extended__policy-list b-policy-list">\n								';
 _.each(this.model.get('policyDescriptions'), function (item) { ;
__p += '\n								<div class="b-hotel-extended__policy-list-item b-policy-list-item">\n									<div class="b-policy-list-item__column b-policy-list-item__title">\n										' +
((__t = ( item.title )) == null ? '' : __t) +
'\n									</div>\n									<div class="b-policy-list-item__column b-policy-list-item__description">\n										' +
((__t = ( item.description )) == null ? '' : __t) +
'\n									</div>\n								</div>\n								';
 }); ;
__p += '\n							</div>\n						</div>\n						';
 } ;
__p += '\n					</div>\n					<div class="b-hotel-extended__description-bottom">\n						<div class="b-hotel-extended__description-bottom-column">\n							<div class="b-hotel-extended__description-bottom-item">\n								';
 if (this.model.get('address') != null) { ;
__p += '\n									<div class="b-hotel-extended__description-address">\n										';

											var address = this.model.get('address');
											var addressString = _.compact([address.address, address.zipCode, (address.cityInfo && address.cityInfo.city || {}).caption, (address.country || {}).caption]).join(', ');
										;
__p += '\n										' +
((__t = ( addressString )) == null ? '' : __t) +
'\n									</div>\n								';
 } ;
__p += '\n\n								';
 if (!_.isEmpty(this.model.get('phones'))) { ;
__p += '\n									<div class="b-hotel-extended__description-phone">\n										';
 var phones = _.map(this.model.get('phones'), function (phone) {
											return phone.value;
										}); ;
__p += '\n										' +
((__t = ( phones.join(', ') )) == null ? '' : __t) +
'\n									</div>\n								';
 } ;
__p += '\n							</div>\n							<div class="b-hotel-extended__description-bottom-item">\n								';
 if (this.model.get('distance')) { ;
__p += '\n									<div>' +
((__t = ( this.model.get('distance').toFixed(1).replace('.', ',') )) == null ? '' : __t) +
' ' +
((__t = ( L10N.get('hotels.distanceFromCenter') )) == null ? '' : __t) +
'</div>\n								';
 } ;
__p += '\n							</div>\n						</div>\n                        ';
 if (this.isMapApiAvailable) { ;
__p += '\n                            <div class="b-hotel-extended__description-bottom-column b-hotel-extended__description-bottom-map"></div>\n                        ';
 } ;
__p += '\n					</div>\n				</div>\n			</div>\n			<div class="b-expand collapsed b-hotel__expand b-hotel__expand--with-description"></div>\n		</div>\n	</div>\n\n	<div class="b-hotel__expand-container b-hotel-extended b-hotel-extended-offers" style="display: none">\n		<div class="b-hotel__extended-wrapper b-hotel-offers__wrapper"></div>\n		<div class="b-hotel__filtered-link" style="display: none">\n			<span>' +
((__t = ( L10N.get('hotels.showFiltered') )) == null ? '' : __t) +
'</span>\n		</div>\n		<div class="b-hotel-offers__summary-container"></div>\n	</div>\n</div>\n';

}
return __p
}