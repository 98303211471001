module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<label class="b-input b-autocomplete ' +
((__t = ( classes )) == null ? '' : __t) +
'">\n    ';
 if (label) { ;
__p += '\n        <span class="b-input__label b-autocomplete__label">' +
((__t = ( label )) == null ? '' : __t) +
'</span>\n        <input class="b-input__value b-autocomplete__value" type="text" placeholder="' +
((__t = ( placeholder )) == null ? '' : __t) +
'">\n    ';
 } else { ;
__p += '\n        <input class="b-input__value b-autocomplete__value b-input__value--without-label" type="text" placeholder="' +
((__t = ( placeholder )) == null ? '' : __t) +
'">\n    ';
 } ;
__p += '\n    ';
 if (deletableField) { ;
__p += '\n        <div class="b-input__remove"></div>\n    ';
 } ;
__p += '\n</label>';

}
return __p
}