// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view.js';
import ItemView from './itemView';
import template from './template.ejs';

export default BaseView.extend({

	template,

	ui: {
		items: '.b-selection__values',
		open: '.b-selection__open',
	},

	initialize() {
		this.render();
		this.listenTo(this.model.get('values'), 'add', item => {
			this.ui.items.append(new ItemView({
				model: item,
			}).$el);
		});
	},

	render() {
		this.setElement(this.template(
			Object.assign(
				{
					showAll: '',
					classes: '',
				},
				this.model.attributes,
			),
		));

		let values = _.isFunction(this.model.formatter) ? this.model.formatter(this.model.get('values')) : null;
		values = values || this.model.get('values');

		values.each(item => {
			this.ui.items.append(new ItemView({
				model: item,
			}).$el);
		});
		if (values.length > 5) {
			this.ui.open.show();
		}
	},

});
