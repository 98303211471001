module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'b-cabinet-order-auth-step__header\'>\n  <div class="b-cabinet-order-auth-step__header-step-line-container">\n    <div class="b-cabinet-order-auth-step__header-status-icon"></div>\n  </div>\n  <div class="b-cabinet-order-auth-step__header-content">\n    <span class="b-cabinet-order-auth-step__header-title">' +
((__t = ( label )) == null ? '' : __t) +
'</span>\n  </div>\n</div>';

}
return __p
}