module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-passengers-hotels__child-age__item">\n	<select class="b-passengers-hotels__child-age__item-input ' +
((__t = ( 'b-passengers-hotels__child-age__item-input-' + number )) == null ? '' : __t) +
'" value="' +
((__t = ( value)) == null ? '' : __t) +
'">\n		<option value="0">0</option>\n		<option value="1">1</option>\n		<option value="2">2</option>\n		<option value="3">3</option>\n		<option value="4">4</option>\n		<option value="5">5</option>\n		<option value="6">6</option>\n		<option value="7">7</option>\n		<option value="8">8</option>\n		<option value="9">9</option>\n		<option value="10">10</option>\n		<option value="11">11</option>\n		';
 if (isExtraAge) { ;
__p += '\n		<option value="12">12*</option>\n		<option value="13">13*</option>\n		<option value="14">14*</option>\n		<option value="15">15*</option>\n		<option value="16">16*</option>\n		<option value="17">17*</option>\n		';
 } ;
__p += '\n	</select>\n</div>\n';

}
return __p
}