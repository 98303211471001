module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<span class="b-multiple-select ' +
((__t = (classes )) == null ? '' : __t) +
'">\n    ';
 if (label) { ;
__p += '\n        <span class="b-input__label b-multiple-select__default-label">' +
((__t = ( label )) == null ? '' : __t) +
'</span>\n    ';
 } ;
__p += '\n	<div class="b-multiple-select__wrapper">\n		<div class="b-multiple-select__label"></div>\n		<div class="b-multiple-select__select">\n			<div class="b-multiple-select__select-img"></div>\n		</div>\n	</div>\n	<div class="b-multiple-select__popup">\n		 <span class="b-multiple-select__selected"></span>\n		<input type="text" placeholder="' +
((__t = (L10N.get("Widgets.selectItem") )) == null ? '' : __t) +
'" class="b-multiple-select__input">\n	</div>\n</span>\n';

}
return __p
}