module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var show = balanceInfo.availableClientFunds != null || balanceInfo.hold != null; ;
__p += '\n\n<div class="b-header__info-currency ' +
((__t = ( show ? '' : 'dn' )) == null ? '' : __t) +
'">\n    ';
 if (balanceInfo.availableClientFunds && balanceInfo.availableClientFundsCurrency) { ;
__p += '\n        <p class="b-header__info-currency-text">\n            ' +
((__t = (L10N.get("header.availableFunds"))) == null ? '' : __t) +
': ' +
((__t = ( balanceInfo.availableClientFunds )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(balanceInfo.availableClientFundsCurrency) )) == null ? '' : __t) +
'\n        </p>\n    ';
 } ;
__p += '\n    ';
 if (balanceInfo.hold && Number(balanceInfo.hold) !== 0) { ;
__p += '\n        <p class="b-header__info-currency-text">\n            ' +
((__t = (L10N.get("header.hold"))) == null ? '' : __t) +
': ' +
((__t = ( balanceInfo.hold )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(balanceInfo.holdCurrency) )) == null ? '' : __t) +
'\n        </p>\n    ';
 } ;
__p += '\n</div>\n';

}
return __p
}