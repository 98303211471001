// eslint-disable-next-line banned-modules
'use strict';

import RangeModel from '@/blocks/elements/b-filters/widgets/range/model';

export default RangeModel.extend({

	collect(model) {
		const field = this.get('field');
		if (field) {
			const modelValue = model.get(field);
			if (modelValue != null && _.isArray(modelValue)) {
				modelValue.forEach(value => {
					this.setMinMaxValue(this.parse(value));
				});
			} else {
				const value = this.parse(modelValue);
				this.setMinMaxValue(value);
			}
		}
	},

	setMinMaxValue(value) {
		const val = this.get('value');
		if (this.get('min') > value) {
			this.set('min', value);
			val[0] = value;
			this.set('value', val);
		}
		if (this.get('max') < value) {
			this.set('max', value);
			val[1] = value;
			this.set('value', val);
		}
	},

});
