// eslint-disable-next-line banned-modules
'use strict';

import BaseView from '@/classes/base.view.js';
import template from './template.ejs';

export default BaseView.extend({

	template,

	initialize() {
		this.render();
	},

	render() {
		this.setElement(this.template(
			Object.assign(
				{
					classes: '',
				},
				this.model.attributes,
			),
		));
	},

});
