import React, { useContext, useEffect, useMemo } from "react";
import Form, { useForm } from "rc-field-form";
import Input from "../../../components/form/input/Input";
import FormItem from "../../../components/form/FormItem/FormItem";
import Button from "../../../components/form/Button/Button";
import { SearchFormParams } from "../NewsPage";
import DatepickerRange from "../../../components/form/DatepickerRange/DatepickerRange";
import Select from "../../../components/form/Select/Select";
import { SearchFormStatusEnum } from "../helpers/interfaces";
import { BaseSelectOption } from "../../../components/form/Select/helpers/interfaces";
import { ReactAppGlobalContext } from "../../../../js/utils/react-utils";
import { PaginationInfo } from "../../../components/form/Pagination/Pagination";

interface NewsSearchFormParams {
	updateSearchParams: (searchParams: SearchFormParams) => void;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const NewsSearchForm: React.FC<NewsSearchFormParams> = (props) => {
	const { updateSearchParams, setCurrentPage } = props;

	const { L10N } = useContext(ReactAppGlobalContext);

	const [form] = useForm();

	const selectOptions = useMemo(() => {
		return Object.keys(SearchFormStatusEnum).map((status: string) => {
			return {
				label: L10N.get(`news.searchForm.status_${status}`),
				value: status,
			} as BaseSelectOption;
		});
	}, []);

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			_status: SearchFormStatusEnum.ALL
		});
	}, []);

	return (
		<Form
			onFinish={() => {
        setCurrentPage(0);
				updateSearchParams({...form.getFieldsValue(true)});
			}}
			form={form}
		>
			<div className="b-block-container__top-controls">
				<div className="b-block-container__element">
					<FormItem name="_dates">
						<DatepickerRange
							label={L10N.get("news.searchForm.date_label")}
							placeholder={L10N.get("news.searchForm.date_placeholder")}
							allowClear
						/>
					</FormItem>
				</div>
				<div className="b-block-container__element">
					<FormItem name="_status">
						<Select
							options={selectOptions}
							label={L10N.get("news.searchForm.status_label")}
							placeholder={L10N.get("news.searchForm.status_placeholder")}
							minWidth={"250px"}
						/>
					</FormItem>
				</div>
				<div className="b-block-container__element">
					<FormItem name="pattern">
						<Input
							label={L10N.get("news.searchForm.pattern_label")}
							placeholder={L10N.get("news.searchForm.pattern_placeholder")}
						/>
					</FormItem>
				</div>
				<div className="b-block-container__element">
					<Button type="submit">{L10N.get("news.searchForm.apply")}</Button>
				</div>
			</div>
		</Form>
	);
};

export default NewsSearchForm;
