// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';
import View from './index';

const Model = BaseModel.extend({

	View,

	toJSON(...args) {
		const result = BaseModel.prototype.toJSON.apply(this, args);

		if (_.isEmpty(result)) {
			return null;
		}

		const params = {
			number: result.number,
		};

		if (_.isObject(result.airline)) {
			params.airline = {
				uid: result.airline.uid,
				caption: result.airline.airportCaption,
			};
		}

		return params;
	},

});

export default Model;
