module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<label class="b-textarea ' +
((__t = ( classes )) == null ? '' : __t) +
'">\n	';
 if (label) { ;
__p += '\n		<span class="b-textarea__label">' +
((__t = ( label )) == null ? '' : __t) +
'</span>\n	';
 } ;
__p += '\n	<textarea class="b-textarea__value" placeholder="' +
((__t = ( placeholder )) == null ? '' : __t) +
'">\n	</textarea>\n</label>\n';

}
return __p
}