// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import BaseModel from '@/classes/base.model';
import template from './template.ejs';
import VisaInfoTraveller from './b-visa-info-traveller';
import VisaInfoTravellerModel from './b-visa-info-traveller/model';

export default BaseView.extend({

	template,

	ui: {
		visaInfoTraveller: '.b-visa-info__passengers',
	},

	initialize() {
		this.visaInfoModels = [];
		this.model = this.options.model;

		this.options.passengersModel.forEach((passenger, i) => {
			const visaInfoModel = new VisaInfoTravellerModel();
			this.visaInfoModels[i] = visaInfoModel;

			if (this.options.isDocoNeeded) {
				if (passenger.get('_docoInfo') != null) {
					passenger.set('docoInfo', new BaseModel(passenger.get('_docoInfo')));
					passenger.set('docoInfo.filled', true);
					passenger.get('docoInfo').off().unbind();
				} else {
					passenger.set('docoInfo', visaInfoModel.get('docoInfo'));
				}
			} else {
				passenger.unset('docoInfo');
			}

			if (this.options.isDocaNeeded) {
				if (passenger.get('_docaInfo') != null) {
					const defaultAddressType = visaInfoModel.get('docaInfo.addressType');

					passenger.set('docaInfo', new BaseModel(passenger.get('_docaInfo')));
					passenger.set('docaInfo.addressType', (passenger.get('docaInfo.addressType') || {
						uid: defaultAddressType,
					}).uid);
					passenger.set('docaInfo.filled', true);
					passenger.get('docaInfo').off().unbind();
				} else {
					passenger.set('docaInfo', visaInfoModel.get('docaInfo'));
				}
			} else {
				passenger.unset('docaInfo');
			}
		});

		if (this.options.fromEmail !== true) {
			this.model.set('noVisaInfo', true);
		}
		this.model.on('change:noVisaInfo', this.toggleNoVisaInfo.bind(this));

		this.render();
		this.toggleNoVisaInfo();
	},

	render() {
		const container = this.$el;
		this.setElement(template(this.options));
		container.append(this.$el);

		const seatsMap = this.options.seats.reduce((acc, cur) => {
			acc[cur.type.uid] = cur.type.caption;
			return acc;
		}, {});

		this.options.passengersModel.forEach((passenger, i) => {
			const visaInfoTravellerView = new VisaInfoTraveller({
				idx: i,
				seats: seatsMap,
				model: passenger,
				passengerModel: passenger,
				country: this.options.country,
				isDocoNeeded: this.options.isDocoNeeded,
				isDocaNeeded: this.options.isDocaNeeded,
			});
			this.ui.visaInfoTraveller.append(visaInfoTravellerView.$el);
		});
	},

	toggleNoVisaInfo() {
		const toggleValue = !this.model.get('noVisaInfo');
		const passengersContainer = this.$el.find('.b-visa-info__passengers-container');
		passengersContainer.removeClass('active').removeClass('inactive');
		passengersContainer.addClass(String(toggleValue) === 'false' ? 'inactive' : 'active');
	},

});
