module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-popup-mask ' +
((__t = ( classes || '' )) == null ? '' : __t) +
'">\r\n	<div class="b-popup-mask__table">\r\n		<div class="b-popup-mask__cell"></div>\r\n	</div>\r\n	<div class="b-popup-mask-overlay"></div>\r\n</div>\r\n';

}
return __p
}