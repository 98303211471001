// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import NearestDatesMatrix from '@/blocks/elements/b-matrix/b-matrix-nearest-dates';
import template from './template.ejs';
import $ from 'jquery';

export default BaseView.extend({

	template,

	ui: {
		openCurrentDateMatrix: '.b-matrix-controls__current-date',
		openNearestDatesMatrix: '.b-matrix-controls__nearest-dates',
	},

	events: {
		'click .b-matrix-controls__current-date': 'openCurrentDateMatrix',
		'click .b-matrix-controls__nearest-dates': 'openNearestDatesMatrix',
	},

	initialize() {
		this.render();
	},

	render() {
		const serachParametrs = STATE.getSearchParametrs();

		this.setElement(this.template({
			isRoundTrip: serachParametrs.routeType === 'ROUNDTRIP',
		}));
	},

	openCurrentDateMatrix() {
		const model = STATE.getPageView().getPageModel();
		if (model.get('currentDateMatrix')) {
			model.set('currentDateMatrix', false);
			this.ui.openCurrentDateMatrix.removeClass('active');
		} else {
			window.scrollTo(0, 0);
			$('.b-matrix-controls__control').removeClass('active');
			this.ui.openCurrentDateMatrix.addClass('active');
			model.set('nearestDatesMatrix', false);
			model.set('currentDateMatrix', true);
		}
	},

	openNearestDatesMatrix() {
		const parent = STATE.getPageView();
		const model = parent.getPageModel();
		const serachParametrs = STATE.getSearchParametrs();

		if (model.get('nearestDatesMatrix')) {
			model.set('nearestDatesMatrix', false);
			this.ui.openNearestDatesMatrix.removeClass('active');
		} else {
			window.scrollTo(0, 0);
			if (serachParametrs.routeType !== 'MULTISTOP') {
				$('.b-matrix-controls__control').removeClass('active');
				this.ui.openNearestDatesMatrix.addClass('active');

				let promise = Promise.resolve();
				if (parent.nearestDatesMatrix == null) {
					parent.nearestDatesMatrix = new NearestDatesMatrix({
						matrixControls: this,
					});

					promise = parent.nearestDatesMatrix.ready;
					parent.$el.find('.b-matrix-nearest').append(parent.nearestDatesMatrix.el);
				}

				promise.then(() => {
					model.set('currentDateMatrix', false);
					model.set('nearestDatesMatrix', true);
				});
			}
		}
	},

});
