module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<span  class="b-airlines__item_selected">\r\n	<span class="b-airlines__item_selected_text">' +
((__t = (obj.airportCaption )) == null ? '' : __t) +
'</span>\r\n	<span class="b-airlines__item-code" uid="' +
((__t = (obj.uid )) == null ? '' : __t) +
'"> </span>\r\n</span>\r\n';

}
return __p
}