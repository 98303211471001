// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';
import BaseCollection from '@/classes/base.collection';

import FilterBaseModel from '@/blocks/elements/b-filters/base.model';
import View from './view';

export default FilterBaseModel.extend({

	View,

	initialize(attr, options) {
		this.options = options || {};
		this.set('values', new BaseCollection());
	},

	filter(model) {
		if (!this.get('values').reduce((result, item) => result && !item.get('value'), true)) {
			return this.get('values').reduce((result, item) => {
				if (_.some([].concat(model.get(this.get('field'))), {
					uid: item.get('uid'),
					caption: item.get('caption'),
				})) {
					return item.get('value') || result;
				}
				return result;
			}, false);
		}
		return true;
	},

	getValue(value) {
		return this.get('values').reduce((result, item) => {
			return Object.keys(value).reduce((itemResult, key) => {
				return itemResult && item.get(key) === value[key];
			}, true) ? item : result;
		}, null);
	},

	collect(model) {
		const field = this.get('field');
		if (field) {
			const value = this.parse(model.get(field));
			if (value) {
				_.each([].concat(value), (v) => {
					const current = this.getValue(v);
					if (!current) {
						const item = new BaseModel(Object.assign({
							value: false,
							price: model.get('transfer.totalPrice.amount'),
							currency: model.get('transfer.totalPrice.currencyCode'),
							parent: this,
						}, v));

						if (this.options.snowCount === true) {
							item.set('count', 1);
						}

						this.get('values').add(item);
					} else {
						if (this.options.snowCount === true) {
							let count = current.has('count') ? parseInt(current.get('count'), 10) : 1;
							current.set('count', ++count);
						}
					}
				});
			}
		}
	},

});
