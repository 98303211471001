import React, { useState } from "react";
import { ServiceTravellerStatistics } from "../../../../../../../../../../helpers/interfaces/ServiceTravellerStatistics";
import {
	Container,
	CostCodes,
	Header,
	HeaderCollapse,
	HeaderFullName,
} from "./styled";
import { PersonRestCostCode } from "../../../../../../../../../../helpers/interfaces/PersonRestCostCode";
import Input from "../../../../../../../../../../components/form/input/Input";
import "./style.less";

interface StatisticsPopupServiceTravellerProps {
	traveller: ServiceTravellerStatistics;
  commonCostCodes: PersonRestCostCode[];
}
const StatisticsPopupServiceTraveller: React.FC<
	StatisticsPopupServiceTravellerProps
> = (props) => {
	const { traveller, commonCostCodes } = props;
	const { costCodes = [] } = traveller;

	const [collapsed, setCollapsed] = useState<boolean>(true);

	return (
		<Container>
			<Header onClick={() => setCollapsed(!collapsed)}>
				<HeaderFullName>{traveller.travellerName}</HeaderFullName>
				<HeaderCollapse
					$collapsed={collapsed}
				/>
			</Header>
			{!collapsed && (
				<CostCodes>
					{commonCostCodes.map((costCode: PersonRestCostCode) => {
						return (
							<Input
                classes="b-input-dark"
								key={costCode.costCodeCategoryUid}
								label={costCode.name}
								value={costCode.value}
								disabled
							/>
						);
					})}
					{costCodes.map((costCode: PersonRestCostCode) => {
						return (
							<Input
                classes="b-input-light"
								key={costCode.costCodeCategoryUid}
								label={costCode.name}
								value={costCode.value}
								disabled
							/>
						);
					})}
				</CostCodes>
			)}
		</Container>
	);
};

export default StatisticsPopupServiceTraveller;
