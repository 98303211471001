// eslint-disable-next-line banned-modules
'use strict';

import './style.less';

import BaseView from '@/classes/base.view';
import template from './template.ejs';
import TableView from '@/blocks/layout/b-block-table/table';
import TableFooterView from '@/blocks/layout/b-block-table/table_footer';
import GlUl from '@/utils/global-utils';
import axios from 'axios';

import $ from 'jquery';

export default BaseView.extend({

	template,

	events: {
		'click .b-passenger-add': 'addPassenger',
		'click .js-scroll-btn': 'scrollTo',
	},

	preinitialize(options) {
		BaseView.prototype.preinitialize.call(this, options);
		this.windowEventListenerList.push({
			mediaQuery: '(max-width: 768px)',
			name: 'change',
			callback: this.adjustMobileTemplate.bind(this),
			isMatchMedia: true,
		});
	},

	initialize() {
		const { data } = this.options;
		const {createPersonAllowed} = STATE[STATE.checkSiteType('B2C') ? 'getLoginUser' : 'getProfile']() || {};
		this.options.createPersonAllowed = createPersonAllowed;
		this.tablePageHistory = [0];

		this.apply = this.apply.bind(this);
		this.model.on('change:page', (model, value) => {
			if (this.tablePageHistory) this.tablePageHistory.push(value);
			this.apply();
		});
		this.model.on('afterchange:pageSize', this.apply);
		this.model.on('change', (model) => {
			const changed = _.omit(model.changed, ['page', 'count', 'pageSize']);
			if (_.isEmpty(changed)) return;

			GlUl.unsetPageOnFilterChange.call(this, model);
		});
		this.render();
		this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));
		this.addEventListeners();

		this.windowEventListenerList.push({
			name: 'keydown',
			callback: GlUl.tablePageHistoryHandler.bind(this),
		});

		this.addWindowEventListeners();

		if (!_.isEmpty(data)) {
			this.applyCallback(data);
		}
	},

	addEventListeners() {
		this.$('.b-input__value').keyup((e) => {
			this.model.set('pattern', $(e.currentTarget).val());
			clearTimeout(this.timer);
			this.timer = setTimeout(this.apply, STATE.QUERY_DELAY);
		});
	},

	scrollTo(e) {
		const scrollTop = $(e.currentTarget).hasClass('top');
		$(e.currentTarget).toggleClass('top', !scrollTop);

		if (scrollTop) {
			this.previousScrollPosition = $(window).scrollTop();
			$(window).scrollTop(0);
		} else {
			$(window).scrollTop(this.previousScrollPosition);
		}
	},

	addPassenger(e) {
		if (e) {
			e.preventDefault();
		}

		STATE.navigate(`/${STATE.ROUTES.CABINET_PASSENGER}/new`);
	},

	apply(e) {
		if (e && _.isFunction(e.preventDefault)) {
			e.preventDefault();
		}

		STATE.showLoader();
		this.disableElements(e);
		axios.post('/midoffice/ibecorp-b2b/cabinet/getPassengers', this.model.toJSON()).then(this.applyCallback.bind(this));
	},

	delegateEvents(...args) {
		BaseView.prototype.delegateEvents.apply(this, args);
	},

	undelegateEvents(...args) {
		BaseView.prototype.undelegateEvents.apply(this, args);
	},

	adjustMobileTemplate(matches) {
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			if (_.isObject(matches)) matches = matches.matches;
			this.isMobileTemplate = matches;

			const $passengerAddBtn = this.$('.js-passenger-add');
			const $tooltips = this.$('[data-toggle="tooltip"]');

			if (matches) {
				this.$('.js-passenger-add-mobile-container').append($passengerAddBtn);
				_.each($tooltips, (el) => el && this.$(el).tooltip('disable'));
			} else {
				this.$('.js-passenger-add-container').append($passengerAddBtn);
				_.each($tooltips, (el) => el && this.$(el).tooltip('enable'));
			}
		}, 100);
	},

	showMoreEventHandler(e) {
		const count = this.model.get('pageSize');
		if (count >= this.model.get('count')) {
			if (e) $(e.currentTarget).hide();
			return;
		}
		this.model.set('pageSize', count + 10);
		this.apply(e);
	},

	applyCallback(response) {
		STATE.hideLoader();

		const result = response.data.result;
		const {passengers} = result;
		this.model.set('count', result.count);
		this.$('.b-block-container__footer').html(new TableFooterView({
			model: this.model,
			data: {
				count: result.count,
			},
			showMoreEventHandler: this.showMoreEventHandler.bind(this),
		}).el);
		this.$('.b-block-container__content').html(new TableView({
			clickField: {
				value: 'personRef.uid',
				path: 'cabinet/passenger/',
				linkFieldName: 'fullName',
			},
			fields: [
				{
					name: 'fullName',
					caption: L10N.get('cabinet.employees.fullName'),
					formatter: (fullName) => {
						if (!fullName) {
							return '----------';
						}

						const names = fullName.split(' ');

						return `<span class="first-name">${names[0]}</span> ${names.slice(1).join(' ')}`;
					},
				},
				{
					name: 'birthDate',
					caption: L10N.get('cabinet.employees.birthDate'),
					formatter: (date) => {
						if (!date || date === '') {
							return date;
						}
						const momentDate = new Time(date);
						return momentDate.toDateString();
					},
				},
				{
					name: 'documents',
					caption: L10N.get('cabinet.employees.documents'),
					tooltip: true,
					formatter: (documents) => {
						if (!_.isEmpty(documents)) {
							const docs = [];

							_.each(documents, (document) => {
								let docString = '';
								if (document.number) {
									if (document.type != null) {
										docString += `<span class="document-type">${document.type.caption}</span> `;
									}

									docString += `№${document.number}`;
									docs.push(`<div>${docString}</div>`);
								}
							});

							return docs.join('');
						}

						return '—';
					},
				},
				{
					name: 'emails',
					caption: L10N.get('cabinet.employees.emails'),
					formatter: (emails) => {
						if (!_.isEmpty(emails)) {
							return emails[0];
						}

						return '—';
					},
				},
				{
					name: 'phones',
					caption: L10N.get('cabinet.employees.phones'),
					formatter: (phones) => {
						if (!_.isEmpty(phones) && phones[0].countryCode && phones[0].cityCode && phones[0].number) {
							return `+${phones[0].countryCode} (${phones[0].cityCode}) ${phones[0].number}`;
						}

						return '—';
					},
				},
			],
			data: passengers,
		}).el);

		if (this.isMobileTemplate) {
			const pageSize = this.model.get('pageSize');
			this.$('.js-scroll-btn')[result.count && pageSize > 10 ? 'show' : 'hide']();
		}
	},

});
