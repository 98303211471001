module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<label class="b-input">\r\n	';
 if (label) { ;
__p += '\r\n		<span class="b-input__label">' +
((__t = ( label )) == null ? '' : __t) +
'</span>\r\n	';
 } ;
__p += '\r\n	<label class="b-input__value b-input__select-value">\r\n		<span class="b-input__select-value-label ' +
((__t = ( noValidationClass )) == null ? '' : __t) +
'">\r\n			';
 if (values && values.size() > 0) { ;
__p += '\r\n				' +
((__t = ( values.at(0).get('caption') )) == null ? '' : __t) +
'\r\n			';
 } ;
__p += '\r\n		</span>\r\n		<select class="b-input__select ' +
((__t = ( noValidationClass )) == null ? '' : __t) +
'" type="text" name="type" placeholder="' +
((__t = ( placeholder )) == null ? '' : __t) +
'">\r\n			<option selected="selected" style="display: none">' +
((__t = ( placeholder )) == null ? '' : __t) +
'</option>\r\n			';
 values.forEach(function(item) { ;
__p += '\r\n				';
 if (!_.isEmpty(item.attributes)) { ;
__p += '<option value="' +
((__t = ( item.get('uid') )) == null ? '' : __t) +
'">' +
((__t = ( item.get('caption') )) == null ? '' : __t) +
'</option>';
 } ;
__p += '\r\n			';
 }) ;
__p += '\r\n		</select>\r\n		';
 if (removable) { ;
__p += '\r\n			<div class="b-input__select__remove dn"></div>\r\n		';
 } ;
__p += '\r\n	</label>\r\n</label>\r\n';

}
return __p
}