import styled from "styled-components";
import closeIcon from "~assets/img/close.svg";

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	text-align: left;
	align-items: flex-start;
`;

export const ReservationInfo = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

export const Title = styled.div`
	font-size: 18px;
	font-weight: 500;
	color: #4a5767;
`;

export const ReservationRoute = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;
export const ReservationParts = styled.div`
	font-size: 14px;
	font-weight: 400;
	display: flex;
	gap: 4px;
`;
export const ReservationPartDark = styled.div`
	color: #4a5767;
`;
export const ReservationPartLight = styled.div`
	color: #a4abb3;
`;

export const ReservationPartSeparator = styled.div`
	width: 10px;
	text-align: center;
`;

export const Separator = styled.div`
	width: 1px;
	min-height: 1px;
	background-color: #d4d4d4;
`;

export const ReservationDetails = styled.div`
	border-radius: 6px;
	background-color: #f3f3f3;
	display: flex;
	gap: 12px;
	width: fit-content;
	padding: 8px 16px 8px 16px;
`;

export const CloseButtonWrapper = styled.div`
	width: 32px;
	height: 32px;
	cursor: pointer;
	background: url(${() => `${closeIcon}`}) no-repeat center;
`;
