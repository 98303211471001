// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

export default BaseView.extend({

	template,

	events: {
		'click .b-rooms-collection__add': 'addNewItem',
	},

	initialize() {
		if (this.collection && this.collection.length === 0) {
			this.collection.reset();
			this.addNewItem();
		}

		this.render();
		this.listenTo(this.collection, 'add remove reset', () => {
			this.render();
		});
	},

	addNewItem() {
		this.collection.add(new this.collection.model(null));
	},

	render() {
		if (this.options.render) {
			this.options.render.call(this);
		} else {
			this.setElement(template(this.options));
			const content = this.$('.b-rooms-collection__content');
			this.collection.each((item, i) => {
				const tempView = new item.View({
					searchModel: this.options.searchModel,
					company: this.options.company,
					model: item,
					index: i,
					L10N,
				});
				if (tempView.applyBinding) {
					tempView.applyBinding(item);
				}
				content.before(tempView.$el);
			});
			this.options.container.empty().append(this.$el);
		}

		if (this.collection.length === 1) {
			this.$el.find('.b-search-room-row:first .b-search-room-delete').hide();
		}
	},

});
