module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 const { caption = ""} = organization; ;
__p += '\n<div>\n  <div class="b-notifications-org-name">' +
((__t = ( caption )) == null ? '' : __t) +
'</div>\n  <div class="b-notifications">\n  </div>\n</div>\n';

}
return __p
}