// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import suggestionTemplate from './suggestion.ejs';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';
import 'corejs-typeahead/dist/typeahead.jquery';
import BaseCollection from '@/classes/base.collection';
import BaseModel from '@/classes/base.model';

const AutocompleteModel = BaseModel.extend({
	idAttribute: 'uid',
});

const AutocompleteCollection = BaseCollection.extend({
	model: AutocompleteModel,
});

const FinanceAutocomplete = Widget.extend({

	validationTemplate,

	template,

	viewName: 'b-finance-autocomplete',

	events: {
		'click .b-input__remove': 'removeItem',
	},

	initialize(options) {
		this.options = options;
		this.options.label = this.options.label || '';
		this.options.placeholder = this.options.placeholder || '';
		this.options.deletableField = (this.options.deletableField === true);
		this.options.values = FinanceAutocomplete.getStoreCollection(options.values).toJSON();
		this.options.removable = options.removable;
		this.render();

		this.$typeahead = this.$('.b-finance-autocomplete__value');
		this.$typeahead.typeahead({
			hint: false,
			highlight: false,
			minLength: 0,
			limit: 1,
			classNames: {
				menu: 'b-finance-autocomplete__items',
				wrapper: 'b-finance-autocomplete__wrapper',
				input: 'b-finance-autocomplete__input',
				hint: 'b-finance-autocomplete__hint',
				dataset: 'b-finance-autocomplete__dataset',
				suggestion: 'b-finance-autocomplete__item',
				selectable: 'b-finance-autocomplete__selectable',
				empty: 'b-finance-autocomplete__empty',
				open: 'b-finance-autocomplete__open',
				cursor: 'b-finance-autocomplete__cursor',
				highlight: 'b-finance-autocomplete__highlight',
			},
		}, {
			limit: 100,
			name: 'finance',
			source: (q, cb) => {
				let values = this.options.values;
				if (_.isFunction(this.options.formatter)) {
					values = this.options.formatter.call(this, values);
				}
				cb(values.reduce((result, item) => {
					if (item.caption.toUpperCase().includes(q.toUpperCase())) result.push(item);
					return result;
				}, []));
			},
			display: (item) => `${item.caption}`,
			templates: {
				suggestion: suggestionTemplate,
			},
		});
	},

	applyBinding(model) {
		this.$typeahead.on('typeahead:change', (ev, suggestion) => {
			if (suggestion.replace(/\s/g, '').length === 0) {
				this.model.set(this.options.bindingProperty, null);
			}
		});

		this.$typeahead.on('typeahead:select', (ev, suggestion) => {
			this.model.set(this.options.bindingProperty, suggestion);
		});

		this.listenTo(model, `validationError:${this.options.bindingProperty}`, (value) => {
			if (value != null) {
				this.$el.addClass('validation-error');
			}
		});

		this.listenTo(model, `change:${this.options.bindingProperty}`, (__model, item) => {
			this.$typeahead.typeahead('val', (item || {}).caption);
		});

		if (_.isObject(model.get(this.options.bindingProperty))) {
			this.$typeahead.typeahead('val', model.get(this.options.bindingProperty).caption);
		}

		this.$('.b-input__remove').on('click', (e) => {
			e.stopPropagation();
			if (model === e) return;
			this.removeItem(model);
		});
	},

	removeItem(model) {
		model.set(this.options.bindingProperty, null);
	},

}, {
	dictionaryToCollection(data) {
		return new AutocompleteCollection(data);
	},

	arrayToCollection(data) {
		return FinanceAutocomplete.dictionaryToCollection(data);
	},

	storeCollection(collection) {
		if (!FinanceAutocomplete.stores) {
			FinanceAutocomplete.stores = {};
		}
		if (!FinanceAutocomplete.stores[collection.cid]) {
			FinanceAutocomplete.stores[collection.cid] = collection;
		}
		return collection.cid;
	},

	getStoreCollection(cid) {
		return FinanceAutocomplete.stores[cid];
	},
});

export default FinanceAutocomplete;
