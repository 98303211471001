import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';

export default BaseView.extend({

	template,

	initialize(options) {
		this.options = options;
		this.model = this.options.model;
		this.render();
	},
});
