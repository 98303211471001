module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	var MAX_NODES_IN_COLUMN = 3;
	var linkHash = linkHash || null;
	var serviceUidList = ['INSURANCE_ACCIDENT', 'TAXI', 'OTHER', 'AEROEXPRESS', 'INSURANCE_TOURISTIC', 'CAR_RENTAL', 'VISA', 'BUS', 'RAILWAY', 'AIR', 'HOTEL_RESERVATION', 'TRANSFER'];
;
__p += '\n<div class="b-addition-orders">\n	<table class="b-block-table">\n		<thead>\n			<tr class="b-block-table__row b-block-table__row-header">\n				<th class="b-block-table__cell b-block-table__cell-empty"></th>\n				<th class="b-block-table__cell b-block-table__cell--width-auto"></th>\n				<th class="b-block-table__cell" data-sort="ORDER_NUMBER">\n					<span class="b-block-table__label">' +
((__t = ( L10N.get('cabinet.orders.orderNumber') )) == null ? '' : __t) +
'</span>\n				</th>\n				<th class="b-block-table__cell">\n					<span class="b-block-table__label">' +
((__t = ( L10N.get('cabinet.orders.direction') )) == null ? '' : __t) +
'</span>\n				</th>\n				<th class="b-block-table__cell" data-sort="TRAVEL_DATE">\n					<span class="b-block-table__label">' +
((__t = ( L10N.get('cabinet.orders.travelDate') )) == null ? '' : __t) +
'</span>\n				</th>\n				<th class="b-block-table__cell">\n					<span class="b-block-table__label">' +
((__t = ( L10N.get('cabinet.orders.passengerName') )) == null ? '' : __t) +
'</span>\n				</th>\n				<th class="b-block-table__cell">\n					<span class="b-block-table__label">' +
((__t = ( L10N.get('cabinet.orders.client') )) == null ? '' : __t) +
'</span>\n				</th>\n                <th class="b-block-table__cell">\n                    <span class="b-block-table__label">' +
((__t = ( L10N.get('bookingForm.customer') )) == null ? '' : __t) +
'</span>\n                </th>\n				<th class="b-block-table__cell">\n					<span class="b-block-table__label">' +
((__t = ( L10N.get('cabinet.orders.agents') )) == null ? '' : __t) +
'</span>\n				</th>\n				<th class="b-block-table__cell">\n					<span class="b-block-table__label">' +
((__t = ( L10N.get('cabinet.orders.subAgencies') )) == null ? '' : __t) +
'</span>\n				</th>\n				<th class="b-block-table__cell" data-sort="ISSUE_DATE">\n					<span class="b-block-table__label">' +
((__t = ( L10N.get('cabinet.orders.issueDates') )) == null ? '' : __t) +
'</span>\n				</th>\n				<th class="b-block-table__cell">\n					<span class="b-block-table__label">' +
((__t = ( L10N.get('cabinet.orders.cost') )) == null ? '' : __t) +
'</span>\n				</th>\n				<th class="b-block-table__cell b-block-table__cell-controls"></th>\n				<th class="b-block-table__cell b-block-table__cell-empty"></th>\n			</tr>\n		</thead>\n		<tbody>\n			';
 _.each(orders, function (order) {
				var createdDate = new Time(order.date).format('DD.MM.YY');
				var hasError = _.some(order.reservationInfos, function(el) { return el && el.status && el.status.uid === 'ERROR'})
            ;
__p += '\n\n				<tr class="b-block-table__row b-addition-orders__item" ';
 if (hasError) { ;
__p += ' data-error-item ';
 } ;
__p += ' data-order-uid="' +
((__t = ( order.booking.uid ? order.booking.uid : '' )) == null ? '' : __t) +
'">\n					<td class="b-block-table__cell b-block-table__cell-empty"></td>\n                        <td class="b-block-table__cell b-block-table__cell--width-auto mobile-hide">\n                            <div class="grid-container col-3">\n                                ';
 if (!_.isEmpty(order.reservationInfos)) { ;
__p += '\n                                    ';
 _.map(order.reservationInfos, function(item) { ;
__p += '\n                                        ';

                                            if (item.skipIcon) return '';
                                            var status = item.status || {};
											var statuses = item.statuses;
                                            var trSubject = item.travelSubject || {};
                                            var icon = serviceUidList.includes(trSubject.uid) ? trSubject.uid : 'UNKNOWN';
											var trSubjectDesc = item.travelSubjectDesc ? item.travelSubjectDesc : trSubject.caption;
                                            var tooltipContent = '<div>' + trSubjectDesc + '</div>';
                                            if (!_.isEmpty(statuses)) {
                                                _.each(_.keys(item.statuses), function(key) {
													tooltipContent += '<div>' + statuses[key] + '</div>';
                                                });
                                            } else if (!_.isEmpty(status) && _.isEmpty(statuses)) {
                                                tooltipContent += '<div>' + status.caption + '</div>';
                                                icon = (status.uid === 'ISSUED' || status.uid === 'PAYED') ? icon + '--' + status.uid : icon;
                                            }
                                        ;
__p += '\n                                        <span\n                                                data-toggle="tooltip"\n                                                data-html="true"\n                                                data-placement="bottom"\n                                                data-original-title="<div class=\'service-type__tooltip\'>' +
((__t = ( tooltipContent )) == null ? '' : __t) +
'</div>"\n                                                class="service-type service-type--type-' +
((__t = ( icon )) == null ? '' : __t) +
'">\n								        </span>\n                                    ';
 }).join(' '); ;
__p += '\n                                ';
 } else { ;
__p += '\n                                    ';
 _.each(order.travelSubjects, function(item) { ;
__p += '\n                                        ';

                                            var icon = serviceUidList.includes(item.uid) ? item.uid : 'UNKNOWN';
                                            var tooltipContent = '<div>' + item.caption + '</div>';
                                        ;
__p += '\n                                        <span\n                                                data-toggle="tooltip"\n                                                data-html="true"\n                                                data-placement="bottom"\n                                                data-original-title="<div class=\'service-type__tooltip\'>' +
((__t = ( tooltipContent )) == null ? '' : __t) +
'</div>"\n                                                class="service-type service-type--type-' +
((__t = ( icon )) == null ? '' : __t) +
'">\n								</span>\n                                    ';
 }); ;
__p += '\n                                ';
 } ;
__p += '\n                            </div>\n                        </td>\n					<td class="b-block-table__cell b-block-table__cell-item-number">\n						<div class="b-block-table__cell-label">\n							<a class="b-addition-orders__item-number"\n                               href="' +
((__t = ( STATE.getRootUrl() )) == null ? '' : __t) +
'#' +
((__t = ( linkHash || '' )) == null ? '' : __t) +
'/' +
((__t = ( order.booking && order.booking.uid || '' )) == null ? '' : __t) +
'"\n                               data-booking-uid="' +
((__t = ( order.booking.uid ? order.booking.uid : '' )) == null ? '' : __t) +
'"\n                            >\n								' +
((__t = ( order.booking.caption )) == null ? '' : __t) +
'\n							</a>\n						</div>\n					</td>\n					<td class="b-block-table__cell">\n						<div class="b-block-table__cell-label">\n								';
 if (!_.isEmpty(order.reservationInfos)) { ;
__p += '\n									';
 _.each(order.reservationInfos.slice(0, MAX_NODES_IN_COLUMN), function (item) {
										var routes = item.routes || [];
										var route = routes[0];
										var status = item.status || {};
                                        var trSubject = item.travelSubject || {};
                                        routes = routes.join(', ');
										if (['CANCELED', 'ERROR', 'REJECT'].includes(status.uid)) {
											route = '<div class="canceled-reservation-text-color">' + route + '</div>';
										}
										var icon = serviceUidList.includes(trSubject.uid) ? trSubject.uid : 'UNKNOWN';
										var colored = (status.uid === 'ISSUED' || status.uid === 'PAYED');
									;
__p += '\n                                    <div class="b-block-table__cell-label-wrapper">\n                                        <div class="b-block-table__cell-icon desktop-hide ' +
((__t = ( colored ? 'b-block-table__cell-icon-service-colored' : 'b-block-table__cell-icon-service' )) == null ? '' : __t) +
' service-type--type-' +
((__t = ( icon )) == null ? '' : __t) +
'-white"></div>\n                                        <div class="overflow-field"\n                                             data-toggle="tooltip"\n                                             data-placement="bottom"\n                                             data-html="true"\n                                             data-original-title="<div>' +
((__t = ( routes )) == null ? '' : __t) +
'</div>">\n                                            ' +
((__t = ( route )) == null ? '' : __t) +
'\n                                        </div>\n                                    </div>\n								';
 }); ;
__p += '\n                                ';
 if (order.reservationInfos.length > MAX_NODES_IN_COLUMN) {
                                    var reservationRouteList =_.map(order.reservationInfos.slice(MAX_NODES_IN_COLUMN), function(item) {
                                        return item.routes[0];
                                    });
                                ;
__p += '\n                                    <span class="action-link more"\n                                          data-toggle="tooltip"\n                                          data-placement="bottom"\n                                          data-html="true"\n                                          data-original-title="<div>' +
((__t = ( reservationRouteList.join(', ') )) == null ? '' : __t) +
'</div>">\n									    ' +
((__t = ( L10N.get('Common.more') )) == null ? '' : __t) +
'\n                                        ' +
((__t = ( reservationRouteList.length )) == null ? '' : __t) +
'\n								</span>\n                                ';
 } ;
__p += '\n								';
 } else if (order.arrivalLocation && order.departureLocation) { ;
__p += '\n                                    <div class="b-block-table__cell-label-wrapper">\n                                        ';
 if (order.travelSubjects.length === 1) { ;
__p += '\n                                            <div class="b-block-table__cell-icon b-block-table__cell-icon-service desktop-hide service-type--type-' +
((__t = ( order.travelSubjects[0].uid )) == null ? '' : __t) +
'-white"></div>\n                                        ';
 } ;
__p += '\n                                            <div class="overflow-field"\n                                                 data-toggle="tooltip"\n                                                 data-placement="bottom"\n                                                 data-html="true"\n                                                 data-original-title="<div>' +
((__t = ( order.departureLocation )) == null ? '' : __t) +
' - ' +
((__t = ( order.arrivalLocation )) == null ? '' : __t) +
'</div>">\n                                                ' +
((__t = ( order.departureLocation )) == null ? '' : __t) +
' - ' +
((__t = ( order.arrivalLocation )) == null ? '' : __t) +
'\n                                            </div>\n                                    </div>\n								';
 } else { ;
__p += '\n                                    <div class="b-block-table__cell-label-wrapper">\n                                        ';
 if (order.travelSubjects.length === 1) { ;
__p += '\n                                            <div class="b-block-table__cell-icon b-block-table__cell-icon-service desktop-hide service-type--type-' +
((__t = ( order.travelSubjects[0].uid )) == null ? '' : __t) +
'-white"></div>\n                                        ';
 } ;
__p += '\n                                            <div class="overflow-field"\n                                                 data-toggle="tooltip"\n                                                 data-placement="bottom"\n                                                 data-html="true"\n                                                 data-original-title="<div>' +
((__t = ( order.arrivalLocation )) == null ? '' : __t) +
'</div>">\n                                                ' +
((__t = ( order.arrivalLocation )) == null ? '' : __t) +
'\n                                            </div>\n                                    </div>\n								';
 } ;
__p += '\n						</div>\n					</td>\n					<td class="b-block-table__cell b-block-table__cell-created-date">\n						<div class="b-block-table__cell-label">\n							<div class="b-block-table__cell-label-wrapper">\n                                <div class="b-block-table__cell-icon g-icon-schedule-blue"></div>\n                                ' +
((__t = ( createdDate )) == null ? '' : __t) +
'\n                            </div>\n						</div>\n					</td>\n					<td class="b-block-table__cell b-block-table__cell-travellers">\n						<div class="b-block-table__cell-label no-wrap">\n							' +
((__t = ( _.map(order.travellers.slice(0, MAX_NODES_IN_COLUMN), function(el) {
								var parts = el.split(' ');
								var result = parts[0];

								if (parts[1]) {
									result += ' ' + parts[1][0].toUpperCase() + '.';
								}

								if (parts[2]) {
									result += ' ' + parts[2][0].toUpperCase() + '.';
								}

								return '<div class="b-block-table__cell-label-wrapper"><div class="b-block-table__cell-icon g-icon-user-blue desktop-hide"></div><div class="overflow-field">' + result + '</div></div>';
							}).join('') )) == null ? '' : __t) +
'\n							';
 if (order.travellers.length > MAX_NODES_IN_COLUMN) { ;
__p += '\n								<span\n									class="action-link more"\n									data-toggle="tooltip"\n									data-placement="bottom"\n									data-html="true"\n									data-original-title="<div>' +
((__t = ( order.travellers.slice(MAX_NODES_IN_COLUMN).join(', ') )) == null ? '' : __t) +
'</div>">\n									' +
((__t = ( L10N.get('Common.more') )) == null ? '' : __t) +
'\n									' +
((__t = ( order.travellers.length - MAX_NODES_IN_COLUMN )) == null ? '' : __t) +
'\n								</span>\n							';
 } ;
__p += '\n						</div>\n					</td>\n					<td class="b-block-table__cell b-block-table__cell-client">\n						<div class="b-block-table__cell-label">\n							<div\n								class="overflow-field"\n								data-toggle="tooltip"\n								data-placement="bottom"\n								data-html="true"\n								data-original-title="<div>' +
((__t = ( order.client )) == null ? '' : __t) +
'</div>">\n								' +
((__t = ( order.client )) == null ? '' : __t) +
'\n							</div>\n						</div>\n					</td>\n                    <td class="b-block-table__cell b-block-table__cell-client">\n                        <div class="b-block-table__cell-label">\n                            ' +
((__t = ( (order.contactPerson || {}).caption || '' )) == null ? '' : __t) +
'\n                        </div>\n                    </td>\n					<td class="b-block-table__cell b-block-table__cell-agents">\n						<div class="b-block-table__cell-label">\n							' +
((__t = ( _.map(order.agents.slice(0, MAX_NODES_IN_COLUMN), function(a) {
								return '<div class="overflow-field">' + a + '</div>';
							}).join('') )) == null ? '' : __t) +
'\n							';
 if (order.agents.length > MAX_NODES_IN_COLUMN) { ;
__p += '\n								<div\n									class="action-link more"\n									data-toggle="tooltip"\n									data-placement="bottom"\n									data-html="true"\n									data-original-title="<div>' +
((__t = ( order.agents.slice(MAX_NODES_IN_COLUMN).join(', ') )) == null ? '' : __t) +
'</div>">\n									' +
((__t = ( L10N.get('Common.more') )) == null ? '' : __t) +
'\n									' +
((__t = ( order.agents.length - MAX_NODES_IN_COLUMN )) == null ? '' : __t) +
'\n								</div>\n							';
 } ;
__p += '\n						</div>\n					</td>\n					<td class="b-block-table__cell b-block-table__cell-subagent">\n						<div class="b-block-table__cell-label">\n							';
 _.each(order.subagencies, function (s) { ;
__p += '\n								<div data-subagent="' +
((__t = (s.uid)) == null ? '' : __t) +
'">' +
((__t = (s.caption)) == null ? '' : __t) +
'</div>\n							';
 }); ;
__p += '\n						</div>\n					</td>\n					<td class="b-block-table__cell ' +
((__t = ( order.issueDates.length > 0 ? 'b-block-table__cell-issue-date' : '' )) == null ? '' : __t) +
'">\n						<div class="b-block-table__cell-label no-wrap">\n							' +
((__t = ( order.issueDates.map(function(date) {
								return '<div class="b-block-table__cell-label-wrapper"><span class="desktop-hide">'+ L10N.get('cabinet.orders.issuePeriod')+': </span>'+new Time(date).format('DD.MM.YY')+'</div>';
							}).join('') )) == null ? '' : __t) +
'\n						</div>\n					</td>\n					<td class="b-block-table__cell b-block-table__cell-price">\n						<div class="b-block-table__cell-label no-wrap">\n							<div>\n								' +
((__t = ( Formatter.formatMoney(order.total.amount, Formatter.getDecimal(order.total.amount)) )) == null ? '' : __t) +
'\n								' +
((__t = ( Formatter.formatCurrency(order.total.currencyCode) )) == null ? '' : __t) +
'\n							</div>\n						</div>\n					</td>\n					<td class="b-block-table__cell b-block-table__cell-controls">\n                        <span class="b-addition-orders__item-add-caption" data-booking-uid="' +
((__t = ( order.booking.uid ? order.booking.uid : '' )) == null ? '' : __t) +
'">' +
((__t = ( L10N.get('searchForm.addToOrder') )) == null ? '' : __t) +
'</span>\n						<span class="b-addition-orders__item-add" data-booking-uid="' +
((__t = ( order.booking.uid ? order.booking.uid : '' )) == null ? '' : __t) +
'"></span>\n					</td>\n					<td class="b-block-table__cell b-block-table__cell-empty"></td>\n				</tr>\n			';
 }); ;
__p += '\n		</tbody>\n	</table>\n</div>\n';

}
return __p
}