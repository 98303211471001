module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="b-footer-show-select">\r\n	<div class="b-footer-show-label">' +
((__t = ( L10N.get('Widgets.pagination.showBy') )) == null ? '' : __t) +
' <span class="js-page-size-value">50</span></div>\r\n	<div class="b-footer-show-source">\r\n		<ul class="js-page-size">\r\n			<li value="10">10</li>\r\n			<li value="25">25</li>\r\n			<li value="50">50</li>\r\n			<li value="100">100</li>\r\n			<li value="200">200</li>\r\n		</ul>\r\n	</div>\r\n</div>\r\n';

}
return __p
}