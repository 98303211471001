'use strict';
/* eslint-disable banned-modules */

import moment from 'moment';

const PATTERNS = {
	serverDate: 'YYYY-MM-DD',
	timeShort: 'HH:mm',
	ticketDate: 'DD MMM dd',
	dateTime: 'YYYY-MM-DD HH:mm',
	monthDay: 'DD MMM',
	date: 'DD.MM.YYYY',
	travelTime: 'H:mm',
	shortDate: 'DD.MM dd.',
	day: 'DD',
};

const momentLocales = {
};

class Time {
	static get PATTERNS() { return PATTERNS; }

	constructor(__moment, format) {
		if (!window.timeCache) {
			window.timeCache = {
				data: {},
				counter: 0,
			};
		}
		if (!__moment) {
			if (this.inCache('MOMENT')) {
				this.moment = this.fromCache('MOMENT');
			} else {
				this.moment = moment();
				this.toCache('MOMENT', this.moment);
			}
		} else if (!format) {
			if (__moment instanceof moment) {
				this.moment = __moment;
			} else if (this.inCache(__moment)) {
				this.moment = this.fromCache(__moment);
			} else {
				this.moment = moment(__moment);
				this.toCache(__moment, this.moment);
			}
		} else if (this.inCache(__moment + format)) {
			this.moment = this.fromCache(__moment + format);
		} else {
			this.set(__moment, format);
			this.toCache(__moment + format, this.moment);
		}
	}

	add(...args) {
		this.moment.add(...args);
		return this;
	}

	set(timeString, format) {
		this.moment = moment(timeString, format);
		return this;
	}

	format(mask) {
		return this.moment.format(mask);
	}

	toServerDate() {
		return this.format(PATTERNS.serverDate);
	}

	toShortDate() {
		return this.format(PATTERNS.shortDate);
	}

	toShortTime() {
		return this.format(PATTERNS.timeShort);
	}

	toTicketDate() {
		return this.format(PATTERNS.ticketDate);
	}

	toDateTime() {
		return this.format(PATTERNS.dateTime);
	}

	toMonthDay() {
		return this.format(PATTERNS.monthDay);
	}

	toDay() {
		return this.format(PATTERNS.day);
	}

	toDateString() {
		return this.format(PATTERNS.date);
	}

	toTravelTime() {
		return this.format(PATTERNS.travelTime);
	}

	toPostTime() {
		return this.moment.format('DD MMMM YYYY, HH:mm');
	}

	getMoment() {
		return this.moment;
	}

	toDate() {
		return this.moment.toDate();
	}

	getDayMinutes() {
		return this.moment.hour() * 60 + this.moment.minute();
	}

	subtract(...args) {
		return new Time(this.moment.clone().subtract(...args));
	}

	/**
	 * Time cache methods
	 */

	inCache(key) {
		return window.timeCache.data[key] != null;
	}

	toCache(key, value) {
		if (window.timeCache.counter > 200) {
			window.timeCache = {
				data: {},
				counter: 0,
			};
		}
		window.timeCache.data[key] = value.clone();
		window.timeCache.counter++;
	}

	fromCache(key) {
		return window.timeCache.data[key].clone();
	}

	/**
	 * Create native moment() Object
	 * @param args
	 * @returns {moment}
	 */
	static moment(...args) {
		return moment(...args);
	}

	static timeValue(value, type) {
		const time = Time.getZeroTime();
		time.getMoment().add(value, type);
		return time;
	}

	static parseToServerDate(date, mask) {
		return new Time(date, mask).toServerDate();
	}

	static getZeroTime() {
		if (window.timeCache.data.ZEROTIME != null) {
			return new Time(window.timeCache.data.ZEROTIME.clone());
		}
		window.timeCache.data.ZEROTIME = moment('0000:01:01:00:00:00:00', 'YYYY:MM:DD:HH:mm:ss:SS');
		window.timeCache.counter++;
		return new Time(window.timeCache.data.ZEROTIME.clone());
	}

	static createPattern(from, to) {
		return (date) => {
			const time = to ? new Time(date, from) : new Time(date);
			return time.format(to || from);
		};
	}

	static toTravelTime(time) {
		const _t = Time.timeValue(time, 'm');
		return `${Math.floor(time / 60)}:${_t.format('mm')}`;
	}

	static loadLanguage(lang) {
		moment.locale(momentLocales[lang] || lang);
	}
}

// Fix for ProvidePlugin which properly works only with old module definition
/*eslint-disable */
// module.exports = Time;
/*eslint-enable */

/* eslint-enable banned-modules */

export default Time;
