// eslint-disable-next-line banned-modules
'use strict';

import BasePageView from '@/pages/base.page-view';
import TrainView from '@/blocks/elements/b-train/index';
import TrainModel from '@/blocks/elements/b-train/model';
import SeatsView from '@/blocks/elements/b-train/b-train-seats/index';
import CabinetMenu from '@/blocks/pages/b-cabinet/b-cabinet-menu/index';

import Backbone from 'backbone';
import axios from 'axios';

const TicketPageView = BasePageView.extend({

	tickets: new Backbone.Collection(),

	pricingParameters: null,
	seatsAmount: null,

	ui: {
		content: '.b-tickets-container',
		sidebar: '.l-layout__content-side',
		top: '.l-layout__top',
	},

	events: {
		'click .passenger-data__preview-intention-approval': 'bookRailwayTickets',
	},

	initialize(options) {
		this.options = options;
		this.intentionData = this.options.intentionData;
		this.approvalAllowed = options.approvalAllowed;
		this.issueAllowed = options.issueAllowed;
		this.currencyCode = this.intentionData.currencyCode;
		this.groupByServiceClass = this.intentionData.railData.train.groupByServiceClass;
		this.currentServiceClass = null;
		this.shelfs = this.intentionData.railData.clientPreferences.seatsRequest.amount;
		this.specialServiceClasses = this.intentionData.railData.specialServiceClasses;

		this.travellersData = this.options.travellers;

		this.seatsAmount = this.intentionData.railData.clientPreferences.seatsRequest.seatsAmount;
		this.passengersTypes = this.countTravellers(this.travellersData).travellersCount;
		this.fullCompartment = this.intentionData.railData.clientPreferences.seatsRequest.fullCompartment;

		if (this.fullCompartment) {
			this.seatsAmount = this.countTravellers(this.travellersData).travellersAmount;
		}

		this.render();

		this.renderTrain();
	},

	async renderTrain() {
		this.trainModels = [];
		this.trainViews = [];

		const railData = this.intentionData.railData;

		const trainModel = new TrainModel(railData.train, {
			hasTravelPolicy: false, // TODO: if needed
		});

		this.trainModels.push(trainModel);

		const trainView = new TrainView({
			parent: this,
			model: trainModel,
			currencyCode: this.currencyCode,
			approvalAllowed: this.approvalAllowed,
			issueAllowed: this.issueAllowed,
			filterCarriages: this.filterCarriages,
			specialServiceClasses: this.specialServiceClasses,
			shouldGetInmobMapInstantly: railData.train && railData.train.gds && railData.train.gds.uid === 'INMOB',
		});

		this.prepareCarriages(trainView, railData).then(async selectedKey => {
			this.currentServiceClass = selectedKey.type && selectedKey.type;

			this.trainViews.push(trainView);
			this.ui.content.append(trainView.$el);

			{
				const currentCarriage = trainView.carriagesView.$el.find(`[data-uid="${selectedKey.uid}"][data-type="${selectedKey.type}"]`);
				if (currentCarriage.hasClass('open')) {
					currentCarriage.removeClass('open');
					trainView.toggleMapContainer(false);
				} else {
					currentCarriage.find('.b-train__carriages-type').removeClass('open');
				}
			}
			await trainView.select(selectedKey.uid, selectedKey.type, this.groupByServiceClass);

			const adaptedChosenNumber = railData.clientPreferences.carriageNumber.toString().length === 1
				? `0${railData.clientPreferences.carriageNumber}`
				: railData.clientPreferences.carriageNumber;
			let seatMapView;

			if (this.groupByServiceClass) {
				seatMapView = trainView.seatsMap[`${selectedKey.uid}_${selectedKey.type}`].view;
			} else {
				seatMapView = trainView.seatsMap[`${selectedKey.uid}`].view;
			}

			const dataNumber = seatMapView.$el.find(`[data-number="${adaptedChosenNumber}"]`);
			const groupIdContainer = dataNumber.parents('.b-train__carriage[data-id]');
			const groupId = groupIdContainer.attr('data-id');
			const id = seatMapView.carriagesGroups.map[groupId].findIndex((car) => car.number === String(adaptedChosenNumber));

			const mapId = {
				mapId: id,
				groupId,
				hasLeft: seatMapView.carriagesGroups.map[groupId][id - 1] != null,
				hasRight: seatMapView.carriagesGroups.map[groupId][id + 1] != null,
			};

			seatMapView.showToggleMap(mapId).then(() => {
				if (this.fullCompartment) {
					const firstPlace = railData.clientPreferences.seatsRequest.range.first;
					const coupeNumber = seatMapView.$el.find(`rect[data-number="${firstPlace}"]`).attr(`data-coupe`);
					seatMapView.maps[mapId.groupId][mapId.mapId]._selectPlace(firstPlace, coupeNumber);
					trainView.showToggleCompartment(true);

					// Clear toggler container first time
					const $container = this.$('.b-carriage__selector-compartment');
					$container.empty();
				} else {
					const firstPlace = railData.clientPreferences.seatsRequest.range.first;
					const secondPlace = railData.clientPreferences.seatsRequest.range.second;
					const firstCoupeNumber = parseInt(seatMapView.$el.find(`rect[data-number="${firstPlace}"]`).attr(`data-coupe`), 10);
					const secondCoupeNumber = parseInt(seatMapView.$el.find(`rect[data-number="${secondPlace}"]`).attr(`data-coupe`), 10);

					// Chose first place
					seatMapView.maps[mapId.groupId][mapId.mapId]._selectPlace(firstPlace, firstCoupeNumber);

					if (firstPlace !== secondPlace && this.currentServiceClass !== `R1`) {	// R1 - купе переговорная, бронируется целиком
						// Chose second place
						seatMapView.maps[mapId.groupId][mapId.mapId]._selectPlace(secondPlace, secondCoupeNumber);

						if (this.shelfs) {
							// Chose shelf (upper or lower seat)
							const singleTrainView = this.trainViews[0];
							singleTrainView.model.get(`_seatsRequest`).set({
								LOWER: this.shelfs.lower || 0,
								UPPER: this.shelfs.upper || 0,
							});
						}
					}
				}
			});
		});
	},

	prepareCarriages(trainView, railData) {
		return new Promise((resolve) => {
			const seatsMap = {};
			let filtered = false;
			let carriages = railData.carriages;
			const carriageInfos = railData.train.carriageInfos;

			let uid;
			let type;

			const carInfo = carriageInfos.find((c) => {
				return c.uid === carriages[0].carriageVariantUid;
			});

			if (this.groupByServiceClass) {
				uid = trainView.$el.find('.b-train__carriages-type:first').attr('data-uid');
				type = carInfo.serviceClass.uid;
			} else {
				uid = carInfo.uid;
				type = carInfo.type.uid;
			}

			const selectKey = {
				uid,
				type,
			};

			let seatsMapKey = uid;
			if (this.groupByServiceClass) {
				seatsMapKey = `${uid}_${type}`;
			}

			if (this.seatsAmount != null && this.seatsAmount > 0) {
				carriages = carriages.filter(c => {
					let include = true;
					if (c.seatsAvailable.length < this.seatsAmount) {
						include = false;
					}
					return include;
				});
				if (carriages.length === 0) {
					filtered = true;
				}
			}

			carriages.forEach((c, i) => {
				c._id = i;
			});
			let carriage;
			let resolveOnEnd = true;
			if (this.groupByServiceClass) {
				const carriagesByServiceClass = carriages.reduce((map, c) => {
					const key = c.serviceClass && c.serviceClass.uid;
					if (!map[key]) {
						map[key] = [];
					}
					map[key].push(c);
					return map;
				}, {});
				Object.keys(carriagesByServiceClass).forEach(key => {
					seatsMapKey = `${uid}_${key}`;
					carriage = _.find(carriagesByServiceClass[key], (c) => c.number === (this.travellersData.carriages[0] || {}).number);
					if (carriage && (railData.train.gds || {}).uid === 'INMOB') {
						resolveOnEnd = false;
						const selectedCarriage = carriage;
						this.getInmobMap(selectedCarriage.providerCarSchemeId, selectedCarriage.twoStoreyed, railData.train.gdsAccount).then(inMobMap => {
							seatsMap[seatsMapKey] = {
								injected: false,
								view: new SeatsView({
									parent: trainView,
									carriages: carriagesByServiceClass[key],
									currencyCode: this.currencyCode,
									gds: railData.train.gds,
									gdsAccount: railData.train.gdsAccount,
									trainPreviewInmobMap: inMobMap ? {carNumber: selectedCarriage.number, map: inMobMap} : null,
									filtered,
								}),
							};
							resolve(selectKey);
						});
					} else {
						seatsMap[seatsMapKey] = {
							injected: false,
							view: new SeatsView({
								parent: trainView,
								carriages: carriagesByServiceClass[key],
								currencyCode: this.currencyCode,
								gds: railData.train.gds,
								gdsAccount: railData.train.gdsAccount,
								filtered,
							}),
						};
					}
				});
				seatsMapKey = `${uid}_${type}`;
			} else {
				carriage = _.find(carriages, (c) => c.number === (this.travellersData.carriages[0] || {}).number);
				if (carriage && (railData.train.gds || {}).uid === 'INMOB') {
					resolveOnEnd = false;
					const selectedCarriage = carriage;
					this.getInmobMap(selectedCarriage.providerCarSchemeId, selectedCarriage.twoStoreyed, railData.train.gdsAccount).then(inMobMap => {
						seatsMap[seatsMapKey] = {
							injected: false,
							view: new SeatsView({
								parent: trainView,
								carriages,
								currencyCode: this.currencyCode,
								gds: railData.train.gds,
								gdsAccount: railData.train.gdsAccount,
								trainPreviewInmobMap: {carNumber: selectedCarriage.number, map: inMobMap},
								filtered,
							}),
						};
						resolve(selectKey);
					});
				} else {
					seatsMap[seatsMapKey] = {
						injected: false,
						view: new SeatsView({
							parent: trainView,
							carriages,
							currencyCode: this.currencyCode,
							gds: railData.train.gds,
							gdsAccount: railData.train.gdsAccount,
							filtered,
						}),
					};
				}
			}

			trainView.seatsMap = seatsMap;

			if (resolveOnEnd) resolve(selectKey);
		});
	},

	render(...args) {
		BasePageView.prototype.render.apply(this, args);
		this.ui.content.addClass('b-tickets-container-rail');
		this.ui.sidebar.html(new CabinetMenu().$el);
	},

	process(routeInfo) {
		if (this.routeId === 1) {
			this.pricingParameters.routeInfo = routeInfo;
			STORE.set(STATE.ROUTES.RAILWAYS_PASSENGERS, this.pricingParameters);
			if (this.isRoundTrip) {
				window.scrollTo(0, this.ui.content.offset().top - 10);
				STATE.navigate('trains/tickets/route/2');
			} else {
				STATE.setIsMobileModalOpened(false);
				STATE.navigate('trains/passengers');
			}
		} else if (this.routeId === 2) {
			this.pricingParameters.routeInfoBack = routeInfo;
			STORE.set(STATE.ROUTES.RAILWAYS_PASSENGERS, this.pricingParameters);
			STATE.setIsMobileModalOpened(false);
			STATE.navigate('trains/passengers');
		} else {
			throw new Error(`Unknown step ${this.routeId}`);
		}
	},

	async bookRailwayTickets(e) {
		if (e != null) {
			e.preventDefault();
		}

		const data = this.trainViews[0].selectedVariant;

		const routeInfo = {
			train: this.trainViews[0].model.toJSON(),
			carriage: data.carriage,
			seatsRequest: this.trainViews[0].buildSeatsRequest(data.numbers),
		};

		const additionalInfo = {
			numbers: this.trainViews[0].numbers,
			isIntention: true,
			reservationUid: this.intentionData.reservationUid,
			currentBooking: this.intentionData.booking,
		};

		this.pricingParameters = { routeInfo, additionalInfo };
		STORE.set(STATE.ROUTES.RAILWAYS_PASSENGERS, this.pricingParameters);
		STATE.setIsMobileModalOpened(false);
		STATE.navigate('trains/passengers');
	},

	countTravellers(travellers) {
		const travellersCount = {
			ADULT: 0,
			CHILD: 0,
			INFANT: 0,
			UNKNOWN: 0,
		};

		let travellersAmount = 0;

		if (travellers != null) {
			travellers.services.forEach(service => {
				service.travellers.forEach((travellerFinal) => {
					if (travellerFinal.travellerType != null) {
						switch (travellerFinal.travellerType.uid) {
							case 'ADULT': {
								travellersCount.ADULT++;
								travellersAmount++;
								break;
							}
							case 'CHILD': {
								travellersCount.CHILD++;
								travellersAmount++;
								break;
							}
							case 'INFANT': {
								travellersCount.INFANT++;
								travellersAmount++;
								break;
							}
							default:
								travellersCount.UNKNOWN++;
								travellersAmount++;
								break;
						}
					}
				});
			});
		}
		return {travellersCount, travellersAmount};
	},

	getInmobMap(id, twoStoreyed, gdsAccount) {
		if (!id) return Promise.resolve();
		if (twoStoreyed) {
			const promises = [];
			promises.push(axios.post('/midoffice/ibecorp-b2b/rail/car/scheme', {
				parameters: {
					providerCarSchemeId: id,
					schemeStorey: 1,
					gdsAccount: gdsAccount || this.options.gdsAccount,
				},
			}));
			promises.push(axios.post('/midoffice/ibecorp-b2b/rail/car/scheme', {
				parameters: {
					providerCarSchemeId: id,
					schemeStorey: 2,
					gdsAccount: gdsAccount || this.options.gdsAccount,
				},
			}));
			return Promise.all(promises).then(responses => {
				return `${responses[1].data}</br>${responses[0].data}`;
			});
		}
		return axios.post('/midoffice/ibecorp-b2b/rail/car/scheme', {
			parameters: {
				providerCarSchemeId: id,
				schemeStorey: 1,
				gdsAccount: gdsAccount || this.options.gdsAccount,
			},
		}).then(res => {
			return res.data;
		});
	},

}, {
	load() {
		return new Promise((resolve) => {
			const intentionData = STORE.get(`${STATE.ROUTES.RAILWAYS_PREVIEW}_INTENTION_DATA`);
			const intentionDataReservation = STORE.get(`${STATE.ROUTES.RAILWAYS_PREVIEW}_INTENTION_DATA_RESERVATION`);
			if (_.some([intentionData, intentionDataReservation], _.isEmpty)) {
				STATE.navigate(STATE.ROUTES.INDEX);
				return;
			}
			resolve({
				intentionData,
				travellers: intentionDataReservation,
			});
		});
	},
});

export default TicketPageView;
