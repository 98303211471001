import { create } from "zustand";

interface PopupItem {
	id: string | number;
	opened: boolean;
	payload?: unknown;
}

export interface PopupsState {
	popups: Array<PopupItem>;
	reset: () => void;
	showPopup: (popupId: string | number, payload: unknown) => void;
	hidePopup: (popupId: string | number) => void;
}

export const usePopupsStore = create<PopupsState>((set) => ({
	popups: [],
	reset: () => set({ popups: [] }),
	showPopup: (popupId: string | number, payload: unknown) => {
		set((state) => {
			const { popups } = state;
			const popupIndex = popups.findIndex(
				(popup: PopupItem) => popup.id === popupId
			);
			if (popupIndex !== -1) {
				popups[popupIndex].opened = true;
				popups[popupIndex].payload = payload;
			} else {
				popups.push({ id: popupId, opened: true, payload });
			}
			return { popups };
		});
	},
	hidePopup: (popupId: string | number) => {
		set((state) => {
			const { popups } = state;
      const filtredPopups = popups.filter((popup: PopupItem) => popup.id !== popupId);
      console.log('filtredPopups', filtredPopups);
			return {
				popups: filtredPopups,
			};
		});
	},
}));
