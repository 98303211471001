module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<a href="' +
((__t = ( this.options.path )) == null ? '' : __t) +
'" ' +
((__t = ( this.options.target ? 'target="' + this.options.target + '"' : '' )) == null ? '' : __t) +
' class="w-sections__section ' +
((__t = ( this.options.classes )) == null ? '' : __t) +
' ' +
((__t = ( this.options.sectionDisable ? 'w-sections__section_disable' : '' )) == null ? '' : __t) +
' ' +
((__t = ( this.options.sectionCurrent ? 'w-sections__section_current' : '' )) == null ? '' : __t) +
'">\r\n	<div class="w-sections__section-icon ' +
((__t = ( this.options.iconClassName )) == null ? '' : __t) +
'"></div>\r\n    <div class="w-sections__section-name">' +
((__t = ( this.options.sectionName )) == null ? '' : __t) +
'</div>\r\n</a>\r\n';

}
return __p
}