// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import SelectWidget from '@/widgets/b-select/';
import FinanceSelect from '@/widgets/b-finance-select/';
import FinanceAutocomplete from '@/widgets/autocomplete-input/b-finance/';
import TableView from '@/blocks/layout/b-block-table/table';
import TableFooterView from '@/blocks/layout/b-block-table/table_footer';
import template from './template.ejs';
import DocumentSelectTemplate from './template-document-select.ejs';

import GlUl from '@/utils/global-utils';
import Formatter from '@/utils/formatter';
import axios from 'axios';
import $ from 'jquery';

export default BaseView.extend({

	template,

	events: {
		'click .p-search__submit': 'getFinanceContracts',
		'click .b-block-container__tab-btn': 'switchTab',
		'click .download-doc-link': 'downloadDocument',
	},

	ui: {
		tabGroup: '.b-block-container__tab-group',
	},

	initialize() {
		this.formSettings = this.options.formSettings;
		this.renderSettings = null;
		this.tablePageHistory = [0];
		const cabinetHolder = STATE.getProfile();
		if (!_.isEmpty(cabinetHolder)) {
			const main = _.find(cabinetHolder.organizationInfos || [], (el) => el.mainEmployment);
			if (main) this.cabinetHolderOrganization = main.organization;
		}

		const relations = this.formSettings.companyRelationsWith;

		this.renderSettings = {
			clients: FinanceAutocomplete.storeCollection(
				FinanceAutocomplete.dictionaryToCollection(this.formSettings.clients),
			),
			agencies: FinanceAutocomplete.storeCollection(
				FinanceAutocomplete.dictionaryToCollection(this.formSettings.agencies),
			),
			relations: SelectWidget.storeCollection(
				SelectWidget.dictionaryToCollection(relations),
			),
			contractor: SelectWidget.storeCollection(
				SelectWidget.dictionaryToCollection(this.formSettings.contractors),
			),
		};

		if (relations.length !== 0) {
			this.model.set('companyRelationWith', relations[0]);
		}
		this.model.set('financeTab', 'transaction');
		this.model.set('onlyNotPayed', false);

		this.model.set('organization', this.formSettings[this.isClient() ? 'clients' : 'agencies'][0]);
		this.model.set('document', []);

		let contractor = this.formSettings.contractors[0];
		if (this.cabinetHolderOrganization) {
			contractor = _.find(this.formSettings.contractors, (el) => el.uid === this.cabinetHolderOrganization.uid);
		}

		this.model.set('contractor', contractor || this.formSettings.contractors[0]);
		this.apply = this.apply.bind(this);

		this.addEventListeners();

		this.windowEventListenerList.push({
			name: 'keydown',
			callback: GlUl.tablePageHistoryHandler.bind(this),
		});

		this.addWindowEventListeners();

		this.apply();
	},

	isClient() {
		return (!this.model.get('companyRelationWith') || this.model.get('companyRelationWith').uid === 'CLIENT');
	},

	render(...args) {
		const container = this.$el;
		this.renderSettings.documentTypes = FinanceSelect.storeCollection(
			FinanceSelect.dictionaryToCollection(this.model.get('availableFinDocumentTypes')),
		);
		this.setElement(this.template(
			Object.assign(
				{},
				this.model.attributes,
				this.renderSettings,
				...args,
				Formatter,
			),
		));
		container.replaceWith(this.$el);

		this.$('.b-input__value').keyup(this.onEnterKeyApply.bind(this));

		this.rendered = true;

		if (STATE.checkViewport('(max-width: 768px)')) {
			_.defer(() => {
				this.ui.tabGroup.scrollLeft(this.model.get('financeTab') === 'document' ? this.ui.tabGroup.innerWidth() : 0);
			});
		}
	},

	showMoreEventHandler(e) {
		const count = this.model.get('pageSize');
		if (count >= this.model.get('count')) {
			if (e) $(e.currentTarget).hide();
			return;
		}
		this.model.set('pageSize', count + 10);
		this.apply(e);
	},

	onEnterKeyApply(e) {
		if (e && e.which === 13) {
			$(e.currentTarget).trigger('blur');
			this.apply();
			$(e.currentTarget).trigger('focus');
		}
	},

	undelegateEvents(...args) {
		if (this.$el) {
			this.$('.b-input__value').off('keyup', this.onEnterKeyApply);
		}
		BaseView.prototype.undelegateEvents.apply(this, args);
	},

	addEventListeners() {
		this.model.on('change:companyRelationWith', () => {
			this.model.set('organization', this.formSettings[this.isClient() ? 'clients' : 'agencies'][0]);
			this.model.set('page', 0, {silent: true});
			this.apply();
			this.render();
		});

		this.model.on('change:page', (model, value) => {
			if (this.tablePageHistory) this.tablePageHistory.push(value);
			this.apply();
		});
		this.model.on('afterchange:pageSize change:document change:onlyNotPayed', () => {
			this.apply();
		});
		this.model.on('change:contractor change:organization change:startDate change:endDate', () => {
			this.rendered = false;
			this.apply();
		});
	},

	apply(e) {
		if (e) {
			e.preventDefault();
		}
		const financeTab = this.model.get('financeTab');
		const documentTypes = this.model.get('document');
		const mandatoryKeys =
			[
				'companyRelationWith',
				'contractor',
				'documentsParameters',
				'endDate',
				'getAvailableFinDocumentTypes',
				'organization',
				'startDate',
			];
		const parameters = _.pick(this.model.toJSON().parameters, mandatoryKeys);

		if (!parameters.contractor || !parameters.organization || !parameters.companyRelationWith) return null;

		switch (financeTab) {
			case 'transaction':
				parameters.transactionsParameters = {
					pageSize: this.model.get('pageSize'),
					page: this.model.get('page'),
					pattern: this.model.get('pattern') || null,
				};
				break;
			case 'document':
				parameters.documentsParameters = {
					pageSize: this.model.get('pageSize'),
					page: this.model.get('page'),
					financeDocumentTypes: documentTypes,
					pattern: this.model.get('pattern') || null,
					onlyNotPayed: this.model.get('onlyNotPayed') || false,
				};
				break;
			case 'externalDocuments':
				parameters.externalDocumentsParameters = {
					pageSize: this.model.get('pageSize'),
					page: this.model.get('page'),
					financeDocumentTypes: documentTypes,
					pattern: this.model.get('pattern') || null,
				};
				break;
		}
		parameters.getAvailableFinDocumentTypes = true;
		STATE.showLoader();
		this.disableElements(e);
		return axios.post('midoffice/ibecorp-b2b/cabinet/getFinance', {parameters}).then(this.applyCallback.bind(this));
	},

	applyCallback(result) {
		const financeTab = this.model.get('financeTab');
		const {currency, transactionsData, documentsData, externalDocumentsData, createPrepaymentInvoice} = result.data.result;
		const data = transactionsData || documentsData || externalDocumentsData || {};
		data.currency = currency || 'RUB';
		this.model.set('count', data.count);
		const isMobileTemplate = STATE.checkViewport('(max-width: 768px)');
		if (!this.rendered) this.render({createPrepaymentInvoice, ...result.data.result});
		STATE.hideLoader();

		this.$('.b-block-container__table-footer')
			.html(new TableFooterView({
				model: this.model,
				data,
				showSummaryOnTop: true,
				showMoreEventHandler: this.showMoreEventHandler.bind(this),
				isMobileTemplate,
			}).$el);

		switch (financeTab) {
			case 'transaction':
				if (data.transactions && data.transactions.length !== 0) {
					data.transactions = data.transactions.map(el => {
						const res = {...el};
						if (currency != null) {
							const formattedCur = Formatter.formatCurrency(currency);
							if (res.outcome != null && res.outcome !== '') {
								res.outcome = `${res.outcome} ${formattedCur}`;
							} else {
								res.outcome = `${0} ${formattedCur}`;
							}
							if (res.income != null && res.income !== '') {
								res.income = `${res.income} ${formattedCur}`;
							} else {
								res.income = `${0} ${formattedCur}`;
							}
						}
						return res;
					});
				}

				this.$('.b-block-container__table')
					.html(new TableView({
						fields: [
							{
								name: 'operationDate',
								caption: L10N.get('cabinet.finance.table.date'),
								formatter: (date) => {
									if (!date) {
										return date;
									}
									const momentDate = new Time(date);
									return momentDate.toDateString();
								},
							},
							{
								name: 'notes',
								caption: L10N.get('cabinet.finance.table.notes'),
							},
							{
								name: 'income',
								caption: L10N.get('cabinet.finance.table.income'),
							},
							{
								name: 'outcome',
								caption: L10N.get('cabinet.finance.table.outcome'),
							},
						],
						data: data.transactions,
						footerData: _.omit(data, 'transactions'),
						showSummaryOnTop: true,
						isMobileTemplate,
					}).$el);
				break;
			case 'document':
				if (data.documents && data.documents.length !== 0) {
					data.documents = data.documents.map(el => {
						const res = {...el};
						if (currency != null) {
							const formattedCur = Formatter.formatCurrency(currency);
							if (res.totalAmount != null && res.totalAmount !== '') {
								res.totalAmount = `${res.totalAmount} ${formattedCur}`;
							} else {
								res.totalAmount = `${0} ${formattedCur}`;
							}
							if (res.totalPayedAmount != null && res.totalPayedAmount !== '') {
								res.totalPayedAmount = `${res.totalPayedAmount} ${formattedCur}`;
							} else {
								res.totalPayedAmount = `${0} ${formattedCur}`;
							}
						}
						if (res.documentNumber && res.uid) {
							res.documentNumber = res.documentNumber.concat(`/${res.uid}`);
						}
						if (res.shipmentNumbers && res.uid) {
							res.shipmentNumbers = _.map(res.shipmentNumbers, (str) => str.concat(`/${res.uid}`));
						}
						return res;
					});
				}
				this.$('.b-block-container__table')
					.html(new TableView({
						fields: [
							{
								name: 'date',
								caption: L10N.get('cabinet.finance.table.exposingDate'),
								formatter: (date) => {
									if (!date) {
										return '---';
									}
									const momentDate = new Time(date);
									return momentDate.toDateString();
								},
							},
							{
								name: 'documentType',
								caption: L10N.get('cabinet.finance.table.documentType'),
								formatter: (documentType) => {
									if (documentType && documentType.caption) {
										documentType = documentType.caption;
										return documentType;
									}
									return '---';
								},

							},
							{
								name: 'documentNumber',
								caption: L10N.get('cabinet.finance.table.bookingNumber'),
								formatter: (documentNumber) => {
									if (documentNumber && documentNumber.indexOf(`/`) !== -1) {
										documentNumber = documentNumber.split(`/`);
										documentNumber = `<a class="action-link--color-blue download-doc-link"
									data-document-uid="${documentNumber[1]}" >${documentNumber[0]}</a>`;
										return documentNumber;
									}
									return documentNumber || '---';
								},
							},
							{
								name: 'shipmentNumbers',
								caption: L10N.get('cabinet.finance.table.shipmentNumber'),
								formatter: (shipmentNumbers) => {
									if (_.size(shipmentNumbers) > 0) {
										if (shipmentNumbers[0].indexOf(`/`) !== -1) {
											shipmentNumbers = _.map(shipmentNumbers, (el) => {
												el = el.split(`/`);
												return `<a class="action-link--color-blue download-doc-link" data-document-uid="${el[1]}">${el[0]}</a>`;
											});
										}
										shipmentNumbers = shipmentNumbers.join(`<br/>`);
									}

									return shipmentNumbers || '--';
								},
							},
							{
								name: 'totalAmount',
								caption: L10N.get('cabinet.finance.table.totalAmount'),
							},
							{
								name: 'totalPayedAmount',
								caption: L10N.get('cabinet.finance.table.payedTotalAmount'),
							},
							{
								name: 'payed',
								caption: L10N.get('cabinet.finance.table.payed'),
								formatter: (payed) => {
									if (payed) {
										payed = L10N.get('cabinet.finance.table.payedTrue');
										return `<span class="desktop-hide" style="color: #099C9D">${payed}</span><span class="mobile-hide">${payed}</span>`;
									}
									payed = L10N.get('cabinet.finance.table.payedFalse');
									return `<span class="desktop-hide" style="color: #D70000">${payed}</span><span class="mobile-hide">${payed}</span>`;
								},
							},
						],
						data: data.documents,
						footerData: _.omit(data, 'documents'),
						showSummaryOnTop: true,
						isMobileTemplate,
					}).$el);

				if (data.amountSum) delete data.amountSum;

				this.$('.b-block-container__table-footer')
					.html(new TableFooterView({
						model: this.model,
						data,
						showSummaryOnTop: true,
						showMoreEventHandler: this.showMoreEventHandler.bind(this),
						isMobileTemplate,
					}).$el);
				break;
			case 'externalDocuments':
				if (data.documents && data.documents.length !== 0) {
					data.documents = data.documents.map(el => {
						const res = {...el};
						if (currency != null) {
							const formattedCur = Formatter.formatCurrency(currency);
							if (res.totalAmount != null && res.totalAmount !== '') {
								res.totalAmount = `${res.totalAmount} ${formattedCur}`;
							} else {
								res.totalAmount = `${0} ${formattedCur}`;
							}
							if (res.totalPayedAmount != null && res.totalPayedAmount !== '') {
								res.totalPayedAmount = `${res.totalPayedAmount} ${formattedCur}`;
							} else {
								res.totalPayedAmount = `${0} ${formattedCur}`;
							}
						}
						if (res.documentNumber && res.uid) {
							res.documentNumber = res.documentNumber.concat(`/${res.uid}`);
						}
						if (res.shipmentNumbers && res.uid) {
							res.shipmentNumbers = _.map(res.shipmentNumbers, (str) => str.concat(`/${res.uid}`));
						}
						return res;
					});
				}
				this.$('.b-block-container__table')
					.html(new TableView({
						fields: [
							{
								name: 'date',
								caption: L10N.get('cabinet.finance.table.exposingDate'),
								formatter: (date) => {
									if (!date) {
										return '---';
									}
									const momentDate = new Time(date);
									return momentDate.toDateString();
								},
							},
							{
								name: 'documentType',
								caption: L10N.get('cabinet.finance.table.documentType'),
								formatter: (documentType) => {
									if (documentType && documentType.caption) {
										documentType = documentType.caption;
										return documentType;
									}
									return '---';
								},

							},
							{
								name: 'documentNumber',
								caption: L10N.get('cabinet.finance.table.bookingNumber'),
								formatter: (documentNumber) => {
									if (documentNumber && documentNumber.indexOf(`/`) !== -1) {
										documentNumber = documentNumber.split(`/`);
										documentNumber = `<a class="action-link--color-blue download-doc-link"
									data-document-uid="${documentNumber[1]}" >${documentNumber[0]}</a>`;
										return documentNumber;
									}
									return documentNumber || '---';
								},
							},
							{
								name: 'totalAmount',
								caption: L10N.get('cabinet.finance.table.totalAmount'),
							},
							{
								name: 'bookingFileNumber',
								caption: L10N.get('cabinet.orders.orderNumber'),
							},
						],
						data: data.documents,
						footerData: _.omit(data, 'documents'),
						showSummaryOnTop: true,
						isMobileTemplate,
					}).$el);

				if (data.amountSum) delete data.amountSum;

				this.$('.b-block-container__table-footer')
					.html(new TableFooterView({
						model: this.model,
						data,
						showSummaryOnTop: true,
						showMoreEventHandler: this.showMoreEventHandler.bind(this),
						isMobileTemplate,
					}).$el);
				break;
		}

		return this;
	},

	switchTab(e) {
		if (!e) return;
		const $target = $(e.target);
		if ($target && $target.data('tab')) {
			this.model.set('financeTab', $target.data('tab'));
			this.rendered = false;
			this.apply(e);
		}
	},

	getFinanceContracts() {
		const parameters = {
			contractor: this.model.get('contractor'),
			companyRelationWith: this.model.get('companyRelationWith'),
			organization: this.model.get('organization'),
		};

		axios.post('midoffice/ibecorp-b2b/cabinet/getFinanceContracts', {parameters}).then(this.getFinanceContractsCallback.bind(this));
	},

	getFinanceContractsCallback(result) {
		const financeContracts = result.data.result;
		const parameters = {
			contractor: this.model.get('contractor'),
			companyRelationWith: this.model.get('companyRelationWith'),
			organization: this.model.get('organization'),
		};

		if (financeContracts) {
			if (_.size(financeContracts) === 1) this.model.set('contract', financeContracts[0]);
			const popup = new Widgets.Popup({
				title: L10N.get('cabinet.finance.createPrepaymentBill'),
				content: DocumentSelectTemplate({
					financeContracts: SelectWidget.storeCollection(SelectWidget.dictionaryToCollection(financeContracts)),
				}),
				actions: [
					{
						label: L10N.get('cabinet.finance.popup.back'),
						action: () => {
							popup.stopListening('change:contract');
							popup.hide();
						},
					},
					{
						label: L10N.get('cabinet.finance.popup.continue'),
						action: () => {
							parameters.contract = this.model.get('contract') || financeContracts[0];
							if (!this.model.get('amount')) {
								this.model.trigger('validationError:amount', [{text: L10N.get('cabinet.finance.popup.fillAmount')}]);
								return this;
							}
							this.createPrepayment(parameters);
							popup.stopListening('change:contract');
							popup.hide();
							return this;
						},
					},
				],
				type: 'info',
				classes: 'b-documents-select without-bg-icon',
			});

			popup.applyBindingModel(this.model);
			popup.show();
			const $contractField = $('.contract');
			popup.listenTo(this.model, 'change:contract', () => $contractField.focus());
			if (this.model.get('contract')) {
				$contractField.focus();
			}
		}
	},

	createPrepayment(parameters) {
		STATE.showLoader();
		parameters.amount = this.model.get('amount').replace(/,/g, '.').replace(/\s/g, '');
		parameters.billingItemName = this.model.get('billingItemName');
		axios.post('midoffice/ibecorp-b2b/cabinet/createPrepayment', {parameters}, {}, {responseType: 'arraybuffer'})
			.then(response => {
				GlUl.downloadFile(response, `prepayment`, {type: 'application/pdf'});
			});
	},

	downloadDocument(e) {
		const documentUid = $(e.target).attr(`data-document-uid`);
		let url = 'midoffice/ibecorp-b2b/cabinet/getDocument';
		if (this.model.get('financeTab') === 'externalDocuments') {
			url = `midoffice/ibecorp-b2b/cabinet/getExternalFinanceDocument`;
		}

		axios.get(url, {
			params: {
				uid: documentUid,
			},
			responseType: 'arraybuffer',
		}).then(res => {
			GlUl.downloadFile(res, `prepayment`, {type: 'application/pdf'});
		});
	},
});
