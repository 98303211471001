// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import BaseCollection from '@/classes/base.collection';
import BaseModel from '@/classes/base.model';

import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';

const SelectModel = BaseModel.extend({
	idAttribute: 'uid',
});

const SelectCollection = BaseCollection.extend({
	model: SelectModel,
});

const Select = Widget.extend({

	validationTemplate,

	template,

	events: {
		'change .b-input__select': 'change',
		'click .b-input__select__remove': 'removeValue',
	},

	ui: {
		input: '.b-input__select',
		label: '.b-input__select-value-label',
		removeButton: '.b-input__select__remove',
	},

	initialize(options) {
		this.collection = Select.getStoreCollection(options.values);
		this.render({
			values: this.collection,
			noValidationClass: options.noValidationClass || '',
		});

		if (this.options.disabled) {
			this.disable(true);
		}
	},

	change() {
		const model = this.collection.get(this.ui.input.find('option:selected').attr('value')).attributes;
		this.select(model);
		this.model.set(this.options.bindingProperty, model);
	},

	disable(disabled) {
		this.disabled = disabled;
		this.ui.input.attr('disabled', disabled);
		this.ui.label.addClass('disabled');
	},

	removeValue() {
		this.model.set(this.options.bindingProperty, null);
	},

	applyBinding(model) {
		logger.debug(this.options.bindingProperty, model);
		this.model = model;
		this.listenTo(model, `change:${this.options.bindingProperty}`, (thisModel, value) => {
			this.select(value);
		});
		if (this.model.has(this.options.bindingProperty)) {
			this.select(this.model.get(this.options.bindingProperty));
		} else {
			try {
				const valueOption = this.ui.input.find('option:selected').attr('value');

				if (valueOption != null) {
					const value = this.collection.get(this.ui.input.find('option:selected').attr('value')).attributes;
					this.model.set(this.options.bindingProperty, value);
				} else {
					this.select();
				}
			} catch (e) {
				//
			}
		}
	},

	select(model) {
		if (model != null && model.uid != null) {
			if (this.ui.input.val() !== model.uid) {
				this.ui.input.val(model.uid);
			}
			this.ui.label.html(model.caption);
		} else {
			this.ui.input.val('');
			this.ui.label.html(`<span class="placeholder">${this.options.placeholder || L10N.get('Widgets.select.notSelected')}</span>`);
		}

		if (this.ui.input.val()) {
			this.ui.removeButton.removeClass('dn');
		} else {
			this.ui.removeButton.addClass('dn');
		}
	},

	render(parameters) {
		const templateObject = Object.assign(
			{
				label: '',
				classes: '',
				placeholder: '',
				removable: false,
			},
			this.options,
			parameters,
		);
		this.setElement(this.template(templateObject));
	},

}, {

	dictionaryToCollection(data) {
		return new SelectCollection(data);
	},

	arrayToCollection(data) {
		return Select.dictionaryToCollection(data);
	},

	storeCollection(collection) {
		if (!Select.stores) {
			Select.stores = {};
		}
		if (!Select.stores[collection.cid]) {
			Select.stores[collection.cid] = collection;
		}
		return collection.cid;
	},

	getStoreCollection(cid) {
		return Select.stores[cid];
	},

});

export default Select;
