module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-seats  ' +
((__t = ( classes )) == null ? '' : __t) +
'">\n	<div class="b-seats__wrapper">\n		<span class="b-seats__input"></span>\n		<div class="b-seats__select">\n			<div class="b-seats__select-img"></div>\n		</div>\n	</div>\n	<div class="b-seats__popup">\n		<ul class="b-seats__list">\n			';
 _.each(types, function (type) { ;
__p += '\n				<li class="b-seats__item" data-type="' +
((__t = ( type.uid )) == null ? '' : __t) +
'">\n					' +
((__t = ( type.caption )) == null ? '' : __t) +
'\n					<span class="b-seats__item-minus">&#8211;</span>\n					<span class="b-seats__item-value"></span>\n					<span class="b-seats__item-plus">+</span>\n				</li>\n			';
 }); ;
__p += '\n		</ul>\n	</div>\n</div>\n';

}
return __p
}