module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var totalPrice = model.get("transfer.totalPrice");
	var price = model.get('transfer.price');
	var priceBack = model.get('transfer.priceBack') || null;
    var issueAllowed = issueAllowed || false;

;
__p += '\n<div class="b-transfer__select-container ' +
((__t = ( !issueAllowed ? 'price-only' : '' )) == null ? '' : __t) +
'">\n    ';
 if (issueAllowed) { ;
__p += '\n        <div class="b-transfer__select-button" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="' +
((__t = ( _.escape(require('../../../elements/b-transfer/b-transfer-pricing/template.ejs')({prices: [price, priceBack], totalPrice: totalPrice})))) == null ? '' : __t) +
'">\n            <div class="b-transfer__select-button-price">\n                ' +
((__t = ( Formatter.formatMoney(totalPrice.amount, Formatter.getDecimal(totalPrice.amount)) )) == null ? '' : __t) +
'\n                ' +
((__t = ( Formatter.formatCurrency(totalPrice.currencyCode) )) == null ? '' : __t) +
'\n            </div>\n        </div>\n    ';
 } else { ;
__p += '\n        <div class="b-transfer__select-button-price" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="' +
((__t = ( _.escape(require('../../../elements/b-transfer/b-transfer-pricing/template.ejs')({prices: [price, priceBack], totalPrice: totalPrice})))) == null ? '' : __t) +
'">\n            ' +
((__t = ( Formatter.formatMoney(totalPrice.amount, Formatter.getDecimal(totalPrice.amount)) )) == null ? '' : __t) +
'\n            ' +
((__t = ( Formatter.formatCurrency(totalPrice.currencyCode) )) == null ? '' : __t) +
'\n        </div>\n    ';
 } ;
__p += '\n    <div class="b-transfer__select-button--offer" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="' +
((__t = ( _.escape(require('../../../elements/b-transfer/b-transfer-pricing/template.ejs')({prices: [price, priceBack], totalPrice: totalPrice})))) == null ? '' : __t) +
'">\n        ' +
((__t = ( L10N.get('transfers.addToAgree') )) == null ? '' : __t) +
'\n    </div>\n</div>\n';

}
return __p
}