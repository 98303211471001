module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-ticket">\r\n	<header class="b-ticket__header">\r\n		<div class="b-ticket-header">\r\n			<span class="b-ticket-header__compay-logo g-icon " style="background-image: url(\'/assets/img/logos/' +
((__t = (carrier.airlineCode )) == null ? '' : __t) +
'.svg\')"></span>\r\n		</div>\r\n		<div class="b-ticket__header-separator"></div>\r\n		<div class="b-operating-airline">\r\n			';

			var opAirlines = [];
			var opAirlinesObj = {};
			for (var i = 0; i < flight.legs.length; i++ ) {
				var leg = flight.legs[i];
				leg.segments.forEach(function(segment) {
					if (segment.operatingAirlineCompanyName != null) {
						opAirlinesObj[segment.operatingAirlineCompanyName] = true;
					} else if (segment.operatingAirline != null) {
						opAirlinesObj[segment.operatingAirline.caption] = true;
					} else if (segment.airline != null) {
						opAirlinesObj[segment.airline.caption] = true;
					}
				});
			}
			for (var key in opAirlinesObj) {
				opAirlines.push(key);
			}
			;
__p += '\r\n			' +
((__t = ( L10N.get("flightlegs.operatingAirline") )) == null ? '' : __t) +
': ' +
((__t = ( opAirlines.join(', ') )) == null ? '' : __t) +
'\r\n		</div>\r\n	</header>\r\n	<div class="b-ticket__legs">\r\n		';

			for (var i = 0; i < flight.legs.length; i++ ) {
				var leg = flight.legs[i];
				var segmentFirst = leg.segments[0];
				var segmentLast = leg.segments[leg.segments.length-1];
		;
__p += '\r\n			<div class="b-ticket-leg">\r\n				<div class="b-ticket-leg__short">\r\n					<div class="b-ticket-leg-segment">\r\n						<div>\r\n							<span class="b-ticket-leg__place">\r\n								<strong>\r\n								';
if (segmentFirst.departureCity) { ;
__p += '\r\n									' +
((__t = ( segmentFirst.departureCity.caption )) == null ? '' : __t) +
',\r\n								';
};
__p += '\r\n								';
if (segmentFirst.departureAirport) { ;
__p += '\r\n									' +
((__t = ( segmentFirst.departureAirport.caption )) == null ? '' : __t) +
'\r\n									<span class="b-ticket__place-iata">(' +
((__t = (segmentFirst.departureAirport.uid )) == null ? '' : __t) +
')</span>\r\n								';
};
__p += '\r\n								</strong>\r\n							</span>\r\n						</div>\r\n						<div>\r\n							';
 var dateDeparture = new Time(segmentFirst.departureDate);  ;
__p += '\r\n							';
 var dateArrival = new Time(segmentLast.arrivalDate);  ;
__p += '\r\n							<span class="b-ticket-leg__date b-ticket-leg__date-departure">\r\n								<span class="b-ticket-leg__time">' +
((__t = (dateDeparture.toShortTime())) == null ? '' : __t) +
'</span>\r\n								' +
((__t = ( dateDeparture.toTicketDate() )) == null ? '' : __t) +
'\r\n							</span>\r\n						</div>\r\n					</div>\r\n					<div class="b-ticket-leg-route-info">\r\n						<div class="b-ticket-leg-route-info-description">\r\n              ';
 if (leg.duration) { ;
__p += '\r\n                ';

                  var legDurationString = L10N.get("flightlegs.legDurationPattern");
                  var legDurationDay = Math.floor(leg.duration / 1440);
                  var legDurationHours = Math.floor((leg.duration % 1440) / 60);
                  var legDurationMin = leg.duration % 60;
                  while (true) {
                    var block = /\[(.*?)\]/.exec(legDurationString);
                    if (!block) break;
                    if (block[1].indexOf("DD") >= 0) {
                      if (legDurationDay == 0) {
                        legDurationString = legDurationString.replace(block[0], "");
                        continue;
                      }
                      legDurationString = legDurationString
                          .replace(block[0], block[1].replace("DD", legDurationDay))
                      continue;
                    }
                    if (block[1].indexOf("HH") >= 0) {
                      if (legDurationDay == 0 && legDurationHours == 0) {
                        legDurationString = legDurationString.replace(block[0], "");
                        continue;
                      }
                      legDurationString = legDurationString
                          .replace(block[0], block[1].replace("HH", legDurationHours))
                      continue;
                    }
                    if (block[1].indexOf("mm") >= 0) {
                      legDurationString = legDurationString
                          .replace(block[0], block[1].replace("mm", legDurationMin))
                      continue;
                    }
                  }
                ;
__p += '\r\n                <span class="travel-duration">' +
((__t = ( legDurationString )) == null ? '' : __t) +
'</span> ' +
((__t = ( L10N.get('flightlegs.travelTime') )) == null ? '' : __t) +
'\r\n              ';
 } ;
__p += '\r\n							';
 if (leg.segments.length > 1) { ;
__p += '\r\n								' +
((__t = ( L10N.get('flightlegs.with') )) == null ? '' : __t) +
' <span class="b-ticket-stops-info">' +
((__t = (leg.segments.length-1 )) == null ? '' : __t) +
' ' +
((__t = (L10N.getPluralForm('flightlegs.stopSecond', leg.segments.length-1))) == null ? '' : __t) +
'</span>\r\n							';
 } ;
__p += '\r\n						</div>\r\n						<div class="b-ticket-leg__info-line">\r\n							';
 if (leg.segments.length > 1) { ;
__p += '\r\n								<span class="b-ticket-leg__transplants">&nbsp;</span>\r\n							';
 } ;
__p += '\r\n						</div>\r\n					</div>\r\n					<div class="b-ticket-leg-segment">\r\n						<div>\r\n							<span class="b-ticket-leg__place">\r\n								';
if (segmentLast.arrivalCity) { ;
__p += '\r\n									' +
((__t = ( segmentLast.arrivalCity.caption )) == null ? '' : __t) +
',\r\n								';
 } ;
__p += '\r\n								';
if (segmentLast.arrivalAirport) { ;
__p += '\r\n									' +
((__t = ( segmentLast.arrivalAirport.caption )) == null ? '' : __t) +
'\r\n									<span class="b-ticket__place-iata">(' +
((__t = (segmentLast.arrivalAirport.uid )) == null ? '' : __t) +
')</span>\r\n								';
 } ;
__p += '\r\n							</span>\r\n						</div>\r\n						<div>\r\n							';
 var dateDeparture = new Time(segmentFirst.departureDate);  ;
__p += '\r\n							';
 var dateArrival = new Time(segmentLast.arrivalDate);  ;
__p += '\r\n							<span class="b-ticket-leg__date b-ticket-leg__date-departure">\r\n								<span class="b-ticket-leg__time">' +
((__t = (dateDeparture.toShortTime())) == null ? '' : __t) +
'</span>\r\n								' +
((__t = ( dateDeparture.toTicketDate() )) == null ? '' : __t) +
'\r\n							</span>\r\n						</div>\r\n					</div>\r\n					<span class="b-ticket-leg__open"></span>\r\n				</div>\r\n				<div class="b-ticket-leg__extended">\r\n					<div class="b-ticket-leg__extended-visible">\r\n						' +
((__t = (require('./b-tickets-leg.ejs')({
							leg: leg
						}) )) == null ? '' : __t) +
'\r\n					</div>\r\n				</div>\r\n			</div>\r\n		';
 } ;
__p += '\r\n	</div>\r\n</div>\r\n';

}
return __p
}