'use strict';

import BaseView from '@/classes/base.view';
import SelectWidget from '@/widgets/b-select';
import template from './template.ejs';

import $ from 'jquery';

export default BaseView.extend({

	template,

	initialize() {
		this.docoFilled = this.model.get('docoInfo.filled') === true;
		this.docaFilled = this.model.get('docaInfo.filled') === true;

		this.render();

		if (this.options.isDocoNeeded) {
			this.model.on('change:docoInfo.visa', this.toggleVisa.bind(this));
			this.toggleVisa(this.model.get('docoInfo'));

			this.listenTo(this.model.get('passport'), `change:citizenship`, (model, value) => {
				if (this.model.get('docoInfo.placeOfBirthCountry') == null) {
					const country = _.find(STATE.getCountries(), (el) => el.uid === value.uid);
					this.model.set('docoInfo.placeOfBirthCountry', country);
				}
			});
		}

		if (this.docoFilled && this.docaFilled) {
			this.$el.addClass('filled').find('input, select').attr('disabled', 'disabled');
		} else {
			if (this.docoFilled) {
				this.$el
					.find('.b-visa-info__container--type-doco')
					.addClass('filled')
					.find('input, select')
					.attr('disabled', 'disabled');
			}

			if (this.docaFilled) {
				this.$el
					.find('.b-visa-info__container--type-doca')
					.addClass('filled')
					.find('input, select')
					.attr('disabled', 'disabled');
			}
		}
	},

	render() {
		const countriesArray = STATE.getCountries();
		const countriesCollection = SelectWidget.dictionaryToCollection(countriesArray);
		this.setElement(this.template(
			Object.assign(
				{},
				this.model.attributes,
				{
					countries: SelectWidget.storeCollection(countriesCollection),
				},
			),
		));

		if (this.options.isDocaNeeded) {
			this.model.set('docaInfo.country', _.find(countriesArray, (el) => el.uid === this.options.country.uid));
		}
	},

	toggleVisa(model) {
		this.$el.find('.js-has-visa-info-container').each(function toggle() {
			this.classList.remove('active');
			this.classList.remove('inactive');
			if (this.getAttribute('data-toggle-value') === `${model && model.get('visa')}`) {
				this.classList.add('active');
			} else {
				this.classList.add('inactive');
			}

			$(this)
				.find('.validation-error').removeClass('validation-error')
				.find('.validations-errors__container')
				.remove();
		});
	},

});
