module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    function renderValue(content, f, r) {
        if (this.clickField && this.clickField.linkFieldName === f.name) {
	        const href = STATE.getRootUrl() + '#' + (this.clickField.path || '') + (_.reduce(this.clickField.value.split("."), function(o, key) { return o && o[key] ? o[key] : null; }, r));
            return '<a class="b-block-table__cell--clickable" href="' + href + '">' + (content || f.default) + '</a>'
        } else {
            return (content || f.default);
        }
    }
;
__p += '\r\n<div class="b-block-table">\r\n    <div class="b-block-table__row b-block-container__table-summary desktop-hide">\r\n        ';

        if (this.showSummaryOnTop && !_.isEmpty(this.footerData)) {
			if (this.footerData.count) {
                ;
__p += '<div class="b-block-container__element b-summary-element">' +
((__t = ( L10N.get('cabinet.table.footer.entriesCounter') )) == null ? '' : __t) +
': <strong>' +
((__t = ( this.footerData.count )) == null ? '' : __t) +
'</strong></div>';

            }
            if (this.footerData.amountSum != null) {
                ;
__p += '<div class="b-block-container__element b-summary-element">' +
((__t = ( L10N.get('cabinet.table.footer.amountSum') )) == null ? '' : __t) +
': <strong>' +
((__t = ( this.footerData.amountSum )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(this.footerData.currency) )) == null ? '' : __t) +
'</strong></div>';

            }
            if (this.footerData.incomeSum != null) {
                ;
__p += '<div class="b-block-container__element b-summary-element">' +
((__t = ( L10N.get('cabinet.table.footer.incomeSum') )) == null ? '' : __t) +
': <strong>' +
((__t = ( this.footerData.incomeSum )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(this.footerData.currency) )) == null ? '' : __t) +
'</strong></div>';

            }
            if (this.footerData.debitSum != null) {
                ;
__p += '<div class="b-block-container__element b-summary-element">' +
((__t = ( L10N.get('cabinet.table.footer.debitSum') )) == null ? '' : __t) +
': <strong>' +
((__t = ( this.footerData.debitSum )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(this.footerData.currency) )) == null ? '' : __t) +
'</strong></div>';

            }
            if (this.footerData.creditSum != null) {
                ;
__p += '<div class="b-block-container__element b-summary-element">' +
((__t = ( L10N.get('cabinet.table.footer.creditSum') )) == null ? '' : __t) +
': <strong>' +
((__t = ( this.footerData.creditSum )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(this.footerData.currency) )) == null ? '' : __t) +
'</strong></div>';

            }
        }
        ;
__p += '\r\n    </div>\r\n	<div class="b-block-table__row b-block-table__row-header">\r\n		';
 if (this.showEmptyCellsOnSides) { ;
__p += ' <div class="b-block-table__cell b-block-table__cell-empty"></div> ';
 } ;
__p += '\r\n		';
 _.each(this.fields, function(f) { ;
__p += '\r\n            <div class="b-block-table__cell">' +
((__t = ( f.noHeader ? '' : f.caption )) == null ? '' : __t) +
'</div>\r\n		';
 }); ;
__p += '\r\n		';
 if (this.showEmptyCellsOnSides) { ;
__p += ' <div class="b-block-table__cell b-block-table__cell-empty"></div> ';
 } ;
__p += '\r\n	</div>\r\n	';
 _.each(this.data, function(r) { ;
__p += '\r\n		<div class="b-block-table__row ' +
((__t = ( this.clickField != null ? "clickable" : "" )) == null ? '' : __t) +
'" data-value="' +
((__t = ( this.clickField != null ? _.reduce(this.clickField.value.split("."), function(o, key) { return o && o[key] ? o[key] : null; }, r) : "" )) == null ? '' : __t) +
'">\r\n            ';
 if (this.showEmptyCellsOnSides) { ;
__p += ' <div class="b-block-table__cell b-block-table__cell-empty" style="text-align: center"></div> ';
 } ;
__p += '\r\n			';
 _.each(this.fields, function(f, i) {
				var showIcon = f.showIcon == null ? true : f.showIcon;
				var contentValue = _.reduce(f.name.split("."), function(o, key) { return o && o[key] ? o[key] : null; }, r);
				var content = _.isFunction(f.formatter) ? f.formatter.call(this, contentValue, f, this.fields, r) : contentValue;
                var isMobileCellVisible = ![null, '', '—'].includes(_.escape(content));
				var iconClass = f.iconClass != null ? f.iconClass : 'g-icon-' + f.name;
				var noBorderClass = (i === (this.fields.length - 1) ? 'b-block-table__cell-no-border' : '');
				var cellClass = 'b-block-table__cell-' + f.name.replace('.', '-')
            ;
__p += '\r\n				<div class="b-block-table__cell mobile-hide ' +
((__t = ( cellClass )) == null ? '' : __t) +
' ' +
((__t = ( f.classes || '' )) == null ? '' : __t) +
' ' +
((__t = ( noBorderClass )) == null ? '' : __t) +
'"\r\n					';
 if (f.tooltip && _.size(content) > 1) { ;
__p += '\r\n						data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="' +
((__t = ( _.escape(content) )) == null ? '' : __t) +
'"\r\n					';
 } else if (content && _.size(content) > 1) { ;
__p += '\r\n						title="' +
((__t = ( _.escape(content.replace(/<[^>]*>?/gm, '')) )) == null ? '' : __t) +
'"\r\n					';
 } ;
__p += '>\r\n                    <span class="b-block-table__cell-value">' +
((__t = ( renderValue.call(this, content, f, r) )) == null ? '' : __t) +
'</span>\r\n				</div>\r\n                <div class="b-block-table__cell desktop-hide ' +
((__t = ( cellClass )) == null ? '' : __t) +
' ' +
((__t = ( noBorderClass )) == null ? '' : __t) +
' ' +
((__t = ( f.classes || '' )) == null ? '' : __t) +
'  ' +
((__t = ( isMobileCellVisible ? '' : 'dn' )) == null ? '' : __t) +
'">\r\n                    ';
 if (showIcon) { ;
__p += ' <span class="b-block-table__cell-icon ' +
((__t = ( iconClass )) == null ? '' : __t) +
'"></span> ';
 } ;
__p += '\r\n                    <span class="b-block-table__cell-caption">' +
((__t = ( f.caption )) == null ? '' : __t) +
':</span>\r\n                    <span class="b-block-table__cell-value">' +
((__t = ( renderValue.call(this, content, f, r) )) == null ? '' : __t) +
'</span>\r\n                </div>\r\n			';
 }.bind(this)); ;
__p += '\r\n                ';
 if (this.showEmptyCellsOnSides) { ;
__p += ' <div class="b-block-table__cell b-block-table__cell-empty"></div> ';
 } ;
__p += '\r\n		</div>\r\n	';
 }.bind(this)); ;
__p += '\r\n</div>\r\n\r\n';

}
return __p
}