module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-avia-search-form b-avia-search">\n	<div class="b-avia-search-form-mobile__close"></div>\n	';
 if (parent.searchParams != null && !parent.searchParams.isRoundTrip && parent.searchParams.legs.length >= 2) { ;
__p += '\n		<div class="b-avia-search-form__complex">\n			';
 _.each(parent.searchParams.legs, function(leg) { ;
__p += '\n				<div class="b-avia-search__leg">\n					';
 if (!_.isEmpty(leg.segments)) { ;
__p += '\n						' +
((__t = ( (_.first(leg.segments).departureCity || {}).caption || '--' )) == null ? '' : __t) +
' - ' +
((__t = ( (_.last(leg.segments).arrivalCity || {}).caption || '--' )) == null ? '' : __t) +
'\n					';
 } else if (leg.arrival != null && leg.departure != null) { ;
__p += '\n						' +
((__t = ( leg.arrival.caption )) == null ? '' : __t) +
' - ' +
((__t = ( leg.departure.caption )) == null ? '' : __t) +
'\n					';
 } ;
__p += '\n				</div>\n			';
 }) ;
__p += '\n			<div class="b-avia-search__leg">\n			' +
((__t = ( _.map(parent.searchParams.legs, function(leg, i) {
				if (!_.isEmpty(leg.segments)) {
					const date = (i === 0) ? _.first(leg.segments).departureDate : _.last(leg.segments).arrivalDate;
					return new Time(date).toDateString();
				} else {
					return new Time(leg.dateTo).toDateString();
				}
			}).join(' - ') )) == null ? '' : __t) +
'\n			</div>\n		</div>\n	';
 } else { ;
__p += '\n		<div class="b-avia-search__row">\n			<div class="b-avia-search__direction">\n				<span class="js-widget"  onclick=\'return new Widgets.Places({ bindingProperty: "departure", placeholder: L10N.get("searchForm.from") })\'></span>\n			</div>\n			<div class="b-avia-search-swap">\n				<div class="b-avia-search-swap__img"></div>\n			</div>\n			<div class="b-avia-search__direction">\n				<span class="js-widget"  onclick=\'return new Widgets.Places({ bindingProperty: "arrival", placeholder: L10N.get("searchForm.to") })\'></span>\n			</div>\n			<div class="b-avia-search__dates-container">\n				<div class="b-avia-search__options-date-element b-avia-search__options-date-element--type-dateto pikaday-modal_show-date-to">\n					<span class="js-widget"  onclick=\'return new Widgets.Datepicker({ bindingProperty: "dateTo", dateFormat: "DD MMM", removable: true, placeholder: L10N.get("searchForm.placeholderThereDate") })\'></span>\n				</div>\n				<div class="b-avia-search__options-date-element b-avia-search__options-date-element--type-datefrom pikaday-modal_show-date-from">\n					<span class="js-widget"  onclick=\'return new Widgets.Datepicker({ bindingProperty: "dateFrom", dateFormat: "DD MMM", depend: "dateTo", dependTime: "timeTo", diff: 7, removable: true, placeholder: L10N.get("searchForm.placeholderBackDate") })\'></span>\n				</div>\n			</div>\n			<div class="b-avia-search__button-container">\n				<div class="b-avia-search__button">' +
((__t = ( L10N.get('searchForm.search') )) == null ? '' : __t) +
'</div>\n			</div>\n		</div>\n	';
 } ;
__p += '\n\n	<div class="b-avia-search-mobile">\n		<div class="b-avia-search-mobile__search-form">\n			<div class="b-avia-search-mobile__route">\n				' +
((__t = ( departure.caption )) == null ? '' : __t) +
' (' +
((__t = ( departure.uid )) == null ? '' : __t) +
') - ' +
((__t = ( arrival.caption )) == null ? '' : __t) +
' (' +
((__t = ( arrival.uid )) == null ? '' : __t) +
')\n			</div>\n			<div class="b-avia-search-mobile__route-info">\n				' +
((__t = ( new Time(dateTo).toMonthDay() )) == null ? '' : __t) +
',\n				';
 if (_.size(passengers)) { ;
__p += '\n					' +
((__t = ( _.size(passengers) )) == null ? '' : __t) +
' ' +
((__t = ( L10N.getPluralForm('searchForm.passenger', _.size(passengers)))) == null ? '' : __t) +
',\n				';
 } else { ;
__p += '\n					';
 const passengersCount = _.reduce(Object.keys(passengersTypes.attributes), function (prev, acc) {
						return prev + passengersTypes.attributes[acc];
					}, 0); ;
__p += '\n					' +
((__t = ( passengersCount )) == null ? '' : __t) +
' ' +
((__t = ( L10N.getPluralForm('searchForm.passenger', passengersCount) )) == null ? '' : __t) +
',\n				';
 } ;
__p += '\n				' +
((__t = ( L10N.get('searchForm.' + classOfService.toLowerCase()).toLowerCase() )) == null ? '' : __t) +
'\n			</div>\n		</div>\n		<div class="b-avia-search-mobile__filters-trigger"></div>\n	</div>\n    <div class="b-avia-search-form-mobile__background"></div>\n</div>\n\n';

}
return __p
}