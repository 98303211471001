// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import './b-cabinet-person-cost-codes-wrapper/style.less';

import BaseView from '@/classes/base.view';
import BaseModel from '@/classes/base.model';
import BaseCollection from '@/classes/base.collection';
import CabinetCollectionView from '@/blocks/pages/b-cabinet/b-cabinet-collection';
import CostCodesView from '@/blocks/elements/b-cost-codes/index.js';
import ProfileCostCodesView from '@/blocks/elements/b-cost-codes/b-profile-cost-codes/index.js';
import costCodesWrapperTemplate from './b-cabinet-person-cost-codes-wrapper/template.ejs';
import NotificationSettingsView from './b-cabinet-person-notification-settings/index.js';

import template from './template.ejs';
import Model from './model';
import SelectWidget from '@/widgets/b-select';

import axios from 'axios';
import $ from 'jquery';

export default BaseView.extend({

	template,

	ui: {
		passports: '.b-cabinet-person__passports',
		phones: '.b-cabinet-person__phones',
		emails: '.b-cabinet-person__emails',
		bonusCards: '.b-cabinet-person__bonus-card',
		railwayBonusCards: '.b-cabinet-person__bonus-card-railways',
		notificationSettings: '.b-cabinet-person__notification-settings',
		rolesContainer: '.b-person-organization__roles',
		costCodesContainer: '.b-cabinet-person__cost-codes',
		twoFactorAuthContainer: '.b-two-factor-auth',
		twoFactorAuthToken: '.b-two-factor-auth-token',
		twoFactorAuthImage: '.b-two-factor-auth-image',
		systemAccessContainer: '.b-cabinet-person__system-access',
	},

	events: {
		'click .b-cabinet-person__buy': 'save',
		'click .b-cabinet-get-auth-key': 'getToken',
	},

	renderNotifications: false,

	initialize() {
		if (!this.model) {
			this.model = new Model();
		}

		if (this.options.personType === 'profile') {
			this.renderNotifications = true;
		}

		this.render(_.extend({}, {
			airlines: '',
			renderNotifications: this.renderNotifications,
			personType: this.options.personType,
			advancedPersonForm: (this.options.advancedPersonForm === true),
			isNew: false,
		}, _.omit(this.options, 'model')));

		const birthDateChange = () => {
			if (this.model.get('passports') != null) {
				this.model.get('passports').each((el) => {
					el.trigger(`passport:birthDateChange`, el, this.model.get('birthDate'));
				});
			}
		};

		birthDateChange();
		this.listenTo(this.model, `change:birthDate`, birthDateChange);

		// translitirate UTS-1023
		this.listenTo(this.model, `change:firstName change:lastName change:middleName change:secondName`, (m, v) => {
			const changedKeys = Object.keys(m.changed);
			changedKeys.forEach((changedFieldName) => {
				const changeLatFieldName = `${changedFieldName}Lat`;
				if (v && T12N.testTransliterationWord(v)) {
					const tralitiratedValue = T12N.transliterateName(v);
					m.set(changeLatFieldName, tralitiratedValue);
				}
			});
		});
		// UTS-597 
		// this.listenTo(this.model, `change:firstNameLat change:lastNameLat change:middleNameLat autoCompletePassporst`, () => {
		// const dataFields = ['firstName', 'lastName', 'middleName', 'firstNameLat', 'lastNameLat', 'middleNameLat'];
		// if (this.model.get('passports') != null) {
		// 	this.model.get('passports').each((item) => {
		// 		item.set(_.extend({}, _.pick(this.model.toJSON(), dataFields), _.pick(item.toJSON(), dataFields)));
		// 	});
		// }
		// });
		this.listenTo(this.model, 'change:withoutMiddleName', (m, v) => {
			this.$el.find('.b-middle-name.b-middle-name__person input').attr('disabled', (v === true));
			this.$el.find('.b-middle-name.b-middle-name__person .b-middle-name__without').html(
				v ? L10N.get('bookingForm.withMiddleName') : L10N.get('bookingForm.withoutMiddleName'),
			);
			if (v) {
				m.set('middleName', '');
				m.set('middleNameLat', '');
			}
		});
		this.listenTo(this.model, 'change:withoutMiddleNameLat', (m, v) => {
			this.$el.find('.b-middle-name-lat.b-middle-name__person input').attr('disabled', (v === true));
			this.$el.find('.b-middle-name-lat.b-middle-name__person .b-middle-name__without-lat').html(
				v ? L10N.get('bookingForm.withMiddleName') : L10N.get('bookingForm.withoutMiddleName'),
			);
			if (v) {
				m.set('middleName', '');
				m.set('middleNameLat', '');
			}
		});
		this.listenTo(this.model.get('passports'), 'add reset remove', birthDateChange);
		this.listenTo(this.model.get('passports'), 'add', (model, collection) => {
			if (collection.length > 1) {
				let autoCompleteData = {};
				const dataFields = ['firstName', 'lastName', 'middleName', 'firstNameLat', 'lastNameLat', 'middleNameLat',
					'secondName', 'secondNameLat',
					'citizenship', 'originCountry'];

				collection.each((item) => {
					autoCompleteData = _.extend(autoCompleteData, _.pick(item.toJSON(), dataFields));
				});

				autoCompleteData = _.extend(autoCompleteData, _.pick(this.model.toJSON(), dataFields));
				model.set(autoCompleteData);
				model.set('withoutMiddleName', this.model.get('withoutMiddleName'));
			}
		});

		if (['employee', 'profile'].includes(this.options.personType)) {
			const systemAccessHandler = (model) => {
				if (this.options.personType === 'profile') {
					this.ui.systemAccessContainer.show();
					return;
				}
				if (!model) return;
				const {hideSystemAccess} = !model.has('employerSettings') || model.get('employerSettings');

				if (hideSystemAccess === true) {
					this.ui.systemAccessContainer.hide();
				} else {
					this.ui.systemAccessContainer.show();
				}
			};

			const renderNotificationSettings = (model, collection) => {
				this.ui.notificationSettings.empty();
				if (collection instanceof BaseCollection) {
					collection.each((item) => {
						const view = new NotificationSettingsView({
							organization: item.get('organization'),
							collection: item.get('disabledNotificationTypes'),
						});
						this.ui.notificationSettings.append(view.$el);
					});
				}
			};

			const renderCostCodes = (model, collection) => {
				this.ui.costCodesContainer.empty();

				if (collection instanceof BaseCollection) {
					collection.each((item) => {
						if (item.get('employerSettings') != null) {
							const {costCodesInfo} = item.get('employerSettings').organizationInfo || {};

							if (_.isEmpty(costCodesInfo)) {
								item.get('costCodesInfo').reset();
								return false;
							}

							const useProfileCostCodes = this.options.useProfileCostCodes || false;

							const $costCodesEl = new (useProfileCostCodes
								? ProfileCostCodesView
								: CostCodesView)({
								collection: item.get('costCodesInfo'),
								organizationCostCodes: costCodesInfo,
								passenger: {
									costCodes: item.get('costCodesInfo').toJSON().slice(),
								},
							}).$el;

							let $elToAppend = $costCodesEl;
							if (useProfileCostCodes) {
								const title = item.get('organization')?.caption;
								const $wrapper = $(costCodesWrapperTemplate({ title }));
								$wrapper.find('.js-cabinet-person-cost-codes-wrapper-content').append($costCodesEl);
								$elToAppend = $wrapper;
							}
							
							this.ui.costCodesContainer.append($elToAppend);
						}

						return true;
					});
				}

				if (this.$(this.ui.costCodesContainer).is(':empty')) {
					this.$('.b-cabinet-person__cost-codes-wrapper').hide();
				} else {
					this.$('.b-cabinet-person__cost-codes-wrapper').show();
				}
			};

			this.listenTo(this.model, 'change:acceptTwoFactorAuthorization', (m, v) => {
				if (v.toString() === 'true') {
					this.ui.twoFactorAuthContainer.show();
				} else {
					this.ui.twoFactorAuthContainer.hide();
					this.$el.find('.b-two-factor-auth-google').hide();
				}
			});

			const changeLogin = (m, v) => {
				if (v) {
					this.$el.find('.js-accept-two-factor-authorization .b-checkbox__input').removeAttr('disabled');
				} else {
					this.model.set('acceptTwoFactorAuthorization', false);
					this.model.unset('getAuthToken');

					this.ui.twoFactorAuthContainer.hide();
					this.$el.find('.b-two-factor-auth-google').hide();
					this.$el.find('.js-accept-two-factor-authorization .b-checkbox__input').attr('disabled', 'disabled');
				}
			};

			this.listenTo(this.model, 'change:systemAccess.login', changeLogin.bind(this));

			if (this.model.get('systemAccess') && this.model.get('systemAccess.login')) {
				changeLogin(this.model, this.model.get('systemAccess.login'));
			}

			this.listenTo(this.model.get('organizationInfos'), 'add remove reset getEmployeeFormData', renderCostCodes.bind(this));

			if (this.renderNotifications) {
				this.listenTo(this.model.get('organizationInfos'), 'add remove reset getEmployeeFormData', renderNotificationSettings.bind(this));
			}

			if (!this.options.isNew) {
				let employersWithoutCategoryAccess = [];
				this.listenTo(this.model.get('organizationInfos'), 'setEmployeeCategoryAccess', (model) => {
					const employerSettings = model.get('employerSettings');
					if (employerSettings && (!employerSettings.employeeCategoryAccess && employerSettings.organizationInfo.employeeCategoryInfos.length > 0)) {
						employersWithoutCategoryAccess.push(model.toJSON());
					}
					clearTimeout(this.timer);
					this.timer = setTimeout(() => {
						if (_.isEmpty(employersWithoutCategoryAccess)) {
							return;
						}
						let organizationNames = _.filter(employersWithoutCategoryAccess.map(item => item.organization && item.organization.caption || null),
							(el) => el != null);
						const length = organizationNames.length;
						organizationNames = `<strong>${length > 1 ? organizationNames.join(', ') : organizationNames[0]}</strong>`;
						const popupContent = `${L10N.getPluralForm('cabinet.employees.organization', length).replace('{0}', '')}`;
						const popup = new Widgets.Popup({
							title: L10N.get('bookingForm.attention'),
							content:
								`${popupContent} ${L10N.get('cabinet.employees.categorySelectionDisabledWarning')}`,
							type: 'info',
							actions: [
								{
									label: L10N.get('Common.close'),
									action: () => {
										popup.hide();
									},
								},
							],
						});
						popup.show();
						employersWithoutCategoryAccess = [];
					}, 100);
				});
			}
			this.listenTo(this.model.get('organizationInfos'), 'setSystemAccessData', systemAccessHandler.bind(this));
			systemAccessHandler(null);

			this.model.set('acceptTwoFactorAuthorization', this.model.get('securityType') && this.model.get('securityType').uid === 'GOOGLE');
		}

		if (this.options.personType === 'employee') {
			const selectEmployer = (model, employer) => {
				const {role, roles} = employer.organizationInfo || {};

				const widget = new SelectWidget({
					bindingProperty: 'role',
					label: L10N.get('cabinet.employees.role'),
					values: SelectWidget.storeCollection(
						SelectWidget.dictionaryToCollection(!_.isEmpty(roles) ? roles : role ? [role] : []),
					),
				});

				if (model != null) {
					model.set('roles', new BaseCollection(_.map(roles, (r) => new BaseModel(r)), {
						model: BaseModel,
					}));
					widget.applyBinding(model);

					if (role != null) {
						model.set('role', role);
					}
				}

				this.ui.rolesContainer.html(widget.$el);
			};

			this.listenTo(this.model, 'changeMainEmployment', selectEmployer);

			const jmodel = this.model.toJSON();
			selectEmployer(this.model, {
				organizationInfo: {
					employeeCategoryInfo: !_.isEmpty(jmodel.organizationInfos) &&
					!_.isEmpty(jmodel.organizationInfos[0].employeeCategoryInfo) ? jmodel.organizationInfos[0].employeeCategoryInfo : {},
					role: !_.isEmpty(jmodel.organizationInfos) &&
					!_.isEmpty(jmodel.organizationInfos[0].role) ? jmodel.organizationInfos[0].role : {},
				},
			});
		}

		this.model.trigger(`autoCompletePassporst`);
	},

	getToken(e) {
		STATE.showLoader();
		this.disableElements(e);
		axios.post('/midoffice/ibecorp-b2b/cabinet/generateGoogleAuthCode', {
			parameters: {
				employeeRef: this.model.get('uid'),
				userLogin: this.model.get('systemAccess.login'),
			},
		}, this.model).then((r) => {
			STATE.hideLoader();

			const {result} = r.data;
			this.ui.twoFactorAuthToken.html(result.authToken);
			this.ui.twoFactorAuthImage.attr('src', result.qrImageUrl);

			this.$el.find('.b-two-factor-auth-google').show();
			this.model.set('googleAuthToken', result.authToken);
			this.model.set('getAuthToken', true);
		});
	},

	save(e) {
		STATE.showLoader();
		this.disableElements(e);
		axios.post('/midoffice/ibecorp-b2b/cabinet/updateCabinetHolder', {
			parameters: {
				holder: this.model.toJSON(),
			},
		}, this.model).then(() => {
			STATE.hideLoader();
		});
	},

	render(attrs) {
		this.setElement(template(_.extend({}, this.model, attrs)));

		this.passportsView = new CabinetCollectionView({
			collection: this.model.get('passports'),
			title: L10N.get('cabinet.contacts.documentsTitle'),
			container: this.ui.passports,
		});
		this.phonesView = new CabinetCollectionView({
			collection: this.model.get('phones'),
			title: L10N.get('bookingForm.phone'),
			container: this.ui.phones,
			type: 'small',
		});
		this.emailsView = new CabinetCollectionView({
			collection: this.model.get('emails'),
			title: L10N.get('bookingForm.emails'),
			container: this.ui.emails,
			type: 'small',
		});
		this.bonusCardView = new CabinetCollectionView({
			collection: this.model.get('bonusCards'),
			title: L10N.get('bookingForm.discountHeader'),
			container: this.ui.bonusCards,
		});
		this.railwaysBonusCardView = new CabinetCollectionView({
			collection: this.model.get('railwayBonusCards'),
			title: L10N.get('trains.railwaysBonus'),
			container: this.ui.railwayBonusCards,
		});

		const systemAccess = this.model.get('systemAccess');
		if (systemAccess != null && systemAccess.login) {
			this.$('.b-cabinet-person__password .b-input__value').attr('disabled', 'disabled');
		} else {
			this.$('.b-cabinet-person__password .b-input__value').removeAttr('disabled', 'disabled');
		}
	},

});
