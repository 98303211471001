// eslint-disable-next-line banned-modules
'use strict';

import FilterBaseModel from '@/blocks/elements/b-filters/base.model';
import View from './view';

const Model = FilterBaseModel.extend({

	defaults() {
		return {
			label: '',
			value: false,
		};
	},

	View,

	filter(model) {
		return !this.get('value') || model.get(this.get('field'));
	},

});

export default Model;
