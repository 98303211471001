// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import templateOneWay from './template-oneway.ejs';
import templateRoundTrip from './template-roundtrip.ejs';
import templateEmpty from './template-empty.ejs';

import axios from 'axios';
import $ from 'jquery';

export default BaseView.extend({

	templateOneWay,

	templateRoundTrip,

	templateEmpty,

	events: {
		'click .b-nearest-dates-matrix__table-price': 'applySearch',
	},

	initialize(options) {
		this.render();
		this.$el.hide();

		this.ready = new Promise((resolve, reject) => {
			const searchParameters = STATE.getSearchParametrs();

			const isRoundTrip = searchParameters.routeType === 'ROUNDTRIP';
			this.dateTo = searchParameters.routes[0].dateTo;
			this.dateFrom = searchParameters.routes[0][isRoundTrip ? 'dateFrom' : 'dateTo'];

			const daysTo = [];
			const daysFrom = [];
			for (let day = -3; day <= 3; day++) {
				// Changes in simpleMatrixFlights response data structure, daysFrom now daysTo and vice versa
				daysTo.push(new Time(this.dateFrom).add(day, 'days').format('YYYY-MM-DD'));
				daysFrom.push(new Time(this.dateTo).add(day, 'days').format('YYYY-MM-DD'));
			}

			STATE.showLoader();
			const matrixFlightsHandler = (result) => {
				STATE.hideLoader();
				this.nothingFound = false;

				if (!result || !result.data || !result.data.result) {
					if (options.isOpen) {
						this.noTickets();
					} else {
						this.nothingFound = true;
					}
				}

				if (options.isOpen) {
					options.matrixControls.openNearestDatesMatrix();
				}

				if (!this.nothingFound) {
					this.datesMatrix = result.data.result;
					const matrix = Array(7).fill().map(() => Array(7).fill(null));
					daysFrom.forEach((dayFrom, i) => {
						daysTo.forEach((dayTo, j) => {
							if ((this.datesMatrix && this.datesMatrix.from && this.datesMatrix.from[dayFrom])) {
								if (this.datesMatrix.from[dayFrom].to && this.datesMatrix.from[dayFrom].to[dayTo]) {
									matrix[i][j] = _.extend({}, this.datesMatrix.from[dayFrom].to[dayTo], {departureTo: dayFrom, departureFrom: dayTo});
								}
							}
						});
					});
					this.$el.append((isRoundTrip ? this.templateRoundTrip : this.templateOneWay)({
						datesMatrix: matrix,
						matrixDaysTo: daysFrom,
						matrixDaysFrom: daysTo,
						dateTo: this.dateTo,
						dateFrom: this.dateFrom,
					}));

					STATE.setMatrixResult({
						result: result.data.result,
						searchParameters: _.extend({}, _.omit(searchParameters, 'toJSON')),
					});
				} else {
					this.$el.append(this.templateEmpty);
				}
				resolve({});
			};

			const matrixFlightsResult = STATE.getMatrixResult();
			const isNewSearch = _.isEmpty(matrixFlightsResult) ||
								!_.isEqual(
									_.pick(searchParameters, 'routes', 'preferredAirlines'),
									_.pick(matrixFlightsResult.searchParameters, 'routes', 'preferredAirlines'));

			if (!_.isEmpty(matrixFlightsResult) && !isNewSearch) {
				matrixFlightsHandler({
					data: matrixFlightsResult,
				});
			} else {
				const params = searchParameters.toJSON();
				axios
					.post('/midoffice/ibecorp-b2b/avia/simpleMatrixFlights', params, STATE.getSearchModel())
					.then(matrixFlightsHandler.bind(this))
					.catch((ex) => {
						if (options.isOpen) {
							this.noTickets();
						} else {
							this.nothingFound = true;
						}

						reject(ex);
					});
			}
		});
	},

	render() {
		this.setElement('<div class="b-nearest-dates-matrix" />');
	},

	applySearch(e) {
		logger.debug('applySearch');

		const searchParameters = STATE.getSearchParametrs();
		const searchModel = STATE.getSearchModel();

		const selectedFlight = $(e.currentTarget);

		const dateTo = selectedFlight.attr('data-to');
		const price = parseInt(selectedFlight.attr('data-price'), 10);

		searchModel.get('routes')[0].set('dateTo', dateTo);
		if (searchParameters.routeType === 'ROUNDTRIP') {
			const dateFrom = selectedFlight.attr('data-from');
			searchModel.get('routes')[0].set('dateFrom', dateFrom);
		}
		STATE.getPageView().getPageModel().set('newSearch', true);

		STATE.getPageView().promiseSearch.then(() => {
			const parent = STATE.getPageView();
			const filtersModels = parent.filtersCollection.models;

			if (price != null && !_.isNaN(price)) {
				const filter = _.find(filtersModels, (el) => el.get('field').includes('price'));

				if ((filter != null) && (price > filter.get('min') && price < filter.get('max'))) {
					filter.set('value', [price, price]);
				}
			}
		});
	},

	show() {
		STATE.showLoader();
		this.ready.then(() => {
			this.$el.show();
			STATE.hideLoader();
		});
	},

	hide() {
		this.$el.hide();
	},

	noTickets() {
		const parent = this.options.parent;
		const searchModel = STATE.getIsFromHash() ? STATE.getSearchModel() : parent.aviaSearchFormView && parent.aviaSearchFormView.searchModel;
		const classOfService = searchModel.get('classOfService');
		const classCaption = L10N.get(`flightlegs.classOfServiceUid.${classOfService}`);
		let noTicketsPopup;
		if (!parent.checkedAnotherClassOfService) {
			noTicketsPopup = new Widgets.Popup({
				content: `${L10N.get('errors.noTicketsFirstPart')}${classCaption}${L10N.get('errors.noTicketsSecondPart')}`,
				type: 'danger',
				actions: [
					{
						label: L10N.get('searchForm.execSearch'),
						action: () => {
							searchModel.set('classOfService', classOfService === 'BUSINESS' ? 'ECONOMY' : 'BUSINESS');
							parent.aviaSearchFormView.search();
							parent.checkedAnotherClassOfService = true;
							noTicketsPopup.hide();
						},
					},
					{
						label: L10N.get('Common.close'),
						action: () => {
							noTicketsPopup.hide();
							// STATE.navigate(STATE.ROUTES.INDEX);
						},
					},
				],
				onClose: () => {
					noTicketsPopup.hide();
				},
			});
		} else {
			noTicketsPopup = new Widgets.Popup({
				content: L10N.get('errors.noTicketsAvia'),
				type: 'danger',
				actions: [
					{
						label: L10N.get('errors.noTicketsButton'),
						action: () => {
							noTicketsPopup.hide();
						},
					},
				],
				onClose: () => {
					noTicketsPopup.hide();
					STATE.navigate(STATE.ROUTES.INDEX);
				},
			});
		}

		noTicketsPopup.show();
	},
});
