module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div>\n	';

		var payments = _.pick(checkBooking.paymentInfo, 'cashOfficePaymentTypes', 'invoicePaymentTypes', 'cashPaymentTypes', 'creditCardsPaymentTypes', 'sabrePaymentType');

		if (!_.isEmpty(payments)) { ;
__p += '\n			';
 if (hasPaymentInHotel) { ;
__p += '\n				<h2 class="b-cabinet-order-payment__title">\n					' +
((__t = ( L10N.get("cabinet.orders.paymentInAgency") )) == null ? '' : __t) +
'\n				</h2>\n			';
 } ;
__p += '\n			';
 if (payments.sabrePaymentType && !hasPaymentInHotel) { ;
__p += '\n				<h2 class="b-cabinet-order-payment__title">\n					' +
((__t = ( L10N.get("cabinet.orders.paymentInHotelFull") )) == null ? '' : __t) +
'\n				</h2>\n				<div class="b-cabinet-order-payment__block">\n					<div class="b-payment__item">\n						<div class="b-payment__item-row b-payment__item-row--with-border">\n							<span class="b-payment__item-cell b-payment__item-cell--type-title b-payment__item-cell-select">\n								<span class="js-widget"  onclick=\'return new Widgets.Select({ bindingProperty: "initiatePayment.sabreCard", values: "' + sabrePaymentCardsCollection + '", placeholder: L10N.get("cabinet.orders.payment_selectCard") })\'></span>\n							</span>\n							<div class="b-payment__item-cell b-payment__item-cell--width-full">\n								<div class="b-payment__item-border"></div>\n							</div>\n							<span class="b-payment__item-cell b-payment__item-cell--type-amount">\n								' +
((__t = ( Formatter.formatMoney(payments.sabrePaymentType.price.amount, Formatter.getDecimal(payments.sabrePaymentType.price.amount)) )) == null ? '' : __t) +
'\n								' +
((__t = ( Formatter.formatCurrency(payments.sabrePaymentType.price.currencyCode) )) == null ? '' : __t) +
'\n							</span>\n						</div>\n					</div>\n				</div>\n				<h2 class="b-cabinet-order-payment__title">\n					' +
((__t = ( L10N.get("cabinet.orders.paymentInAgency") )) == null ? '' : __t) +
'\n				</h2>\n			';
 } ;
__p += '\n			<div class="b-cabinet-order-payment__block">\n				';
 if (payments.cashOfficePaymentTypes && payments.cashOfficePaymentTypes.CASH) {
					var payment = payments.cashOfficePaymentTypes.CASH; ;
__p += '\n					<div class="b-payment__item">\n						<div class="b-payment__item-row b-payment__item-row--with-border">\n							<span class="b-payment__item-cell b-payment__item-cell--type-title">\n								<span class="js-widget"  onclick=\'return new Widgets.Radiobutton({bindingProperty: "initiatePayment.paymentType", label: L10N.get("payment.paymentCashOffice"), value: "CASH"})\'></span>\n							</span>\n							<div class="b-payment__item-cell b-payment__item-cell--width-full">\n								<div class="b-payment__item-border"></div>\n							</div>\n							<span class="b-payment__item-cell b-payment__item-cell--type-amount">\n								' +
((__t = ( Formatter.formatMoney(payment.price.amount, Formatter.getDecimal(payment.price.amount)) )) == null ? '' : __t) +
'\n								' +
((__t = ( Formatter.formatCurrency(payment.price.currencyCode) )) == null ? '' : __t) +
'\n							</span>\n						</div>\n						<div class="b-payment__block-row js-offices">\n							';
 _.each(_.keys(offices), function(key) { ;
__p += '\n								<div class="b-payment__office">\n									<h2 class="b-payment__office-title">' +
((__t = ( offices[key] && offices[key][0] && offices[key][0].city && offices[key][0].city.caption )) == null ? '' : __t) +
'</h2>\n									';
 _.each(offices[key], function(office) { ;
__p += '\n										<div class="b-payment__office-variant">\n											<span class="js-widget"  onclick=\'return new Widgets.Radiobutton({bindingProperty: "initiatePayment.salesOfficeUid", label: "' + (office.address != null ? office.address.replace(/(?:\r\n|\r|\n)/g, '') : '') + '", value: "' + office.uid + '"})\'></span>\n										</div>\n									';
 }); ;
__p += '\n								</div>\n							';
 }); ;
__p += '\n						</div>\n					</div>\n				';
 } ;
__p += '\n\n				';
 if (payments.invoicePaymentTypes && payments.invoicePaymentTypes.INVOICE) {
					var payment = payments.invoicePaymentTypes.INVOICE; ;
__p += '\n					<div class="b-payment__item">\n						<div class="b-payment__item-row b-payment__item-row--with-border">\n							<span class="b-payment__item-cell b-payment__item-cell--type-title">\n								<span class="js-widget"  onclick=\'return new Widgets.Radiobutton({bindingProperty: "initiatePayment.paymentType", label: L10N.get("payment.paymentInvoice"), value: "INVOICE"})\'></span>\n							</span>\n							<div class="b-payment__item-cell b-payment__item-cell--width-full">\n								<div class="b-payment__item-border"></div>\n							</div>\n							<span class="b-payment__item-cell b-payment__item-cell--type-amount">\n								' +
((__t = ( Formatter.formatMoney(payment.price.amount, Formatter.getDecimal(payment.price.amount)) )) == null ? '' : __t) +
'\n								' +
((__t = ( Formatter.formatCurrency(payment.price.currencyCode) )) == null ? '' : __t) +
'\n							</span>\n						</div>\n					</div>\n				';
 } ;
__p += '\n\n\n				';
 if (payments.cashPaymentTypes) {
					if (payments.cashPaymentTypes.CASH_DELIVERY) {
						var payment = payments.cashPaymentTypes.CASH_DELIVERY; ;
__p += '\n						<div class="b-payment__item">\n							<div class="b-payment__item-row b-payment__item-row--with-border">\n								<span class="b-payment__item-cell b-payment__item-cell--type-title">\n									<span class="js-widget"  onclick=\'return new Widgets.Radiobutton({bindingProperty: "initiatePayment.paymentType", label: L10N.get("payment.paymentCashDelivery"),  value: "CASH_DELIVERY"})\'></span>\n								</span>\n								<div class="b-payment__item-cell b-payment__item-cell--width-full">\n									<div class="b-payment__item-border"></div>\n								</div>\n								<span class="b-payment__item-cell b-payment__item-cell--type-amount">\n									' +
((__t = ( Formatter.formatMoney(payment.price.amount, Formatter.getDecimal(payment.price.amount)) )) == null ? '' : __t) +
'\n									' +
((__t = ( Formatter.formatCurrency(payment.price.currencyCode) )) == null ? '' : __t) +
'\n								</span>\n							</div>\n							';
 if (STATE.getSettings().fourthStepSettings.deliveryInputEnabled) { ;
__p += '\n								<div class="b-payment__block-content">\n									<span class="js-widget"  onclick=\'return new Widgets.Textarea({bindingProperty: "initiatePayment.cashDeliveryAddress", label: L10N.get("payment.paymentCashDeliveryAddress") })\'></span>\n								</div>\n							';
 } ;
__p += '\n						</div>\n					';
 }
					if (payments.cashPaymentTypes.CASH) {
						var payment = payments.cashPaymentTypes.CASH; ;
__p += '\n						<div class="b-payment__item">\n							<div class="b-payment__item-row b-payment__item-row--with-border">\n								<span class="b-payment__item-cell b-payment__item-cell--type-title">\n									<span class="js-widget"  onclick=\'return new Widgets.Radiobutton({bindingProperty: "initiatePayment.paymentType", label: L10N.get("payment.paymentCash"),  value: "CASH"})\'></span>\n								</span>\n								<div class="b-payment__item-cell b-payment__item-cell--width-full">\n									<div class="b-payment__item-border"></div>\n								</div>\n								<span class="b-payment__item-cell b-payment__item-cell--type-amount">\n									' +
((__t = ( Formatter.formatMoney(payment.price.amount, Formatter.getDecimal(payment.price.amount)) )) == null ? '' : __t) +
'\n									' +
((__t = ( Formatter.formatCurrency(payment.price.currencyCode) )) == null ? '' : __t) +
'\n								</span>\n							</div>\n						</div>\n					';
 } ;
__p += '\n				';
 } ;
__p += '\n\n				';
 if (payments.creditCardsPaymentTypes) {
					_.each(_.keys(payments.creditCardsPaymentTypes), function (key) {
						var payment = checkBooking.paymentInfo.creditCardsPaymentTypes[key]; ;
__p += '\n						<div class="b-payment__item">\n							<div class="b-payment__item-row b-payment__item-row--with-border">\n								<span class="b-payment__item-cell b-payment__item-cell--type-title">\n									';
 var label = _.compact([L10N.get("payment.paymentCreditCard"), payment.pgName != null && payment.pgName.caption]).join(' - '); ;
__p += '\n									<span class="js-widget"  onclick=\'return new Widgets.Radiobutton({bindingProperty: "initiatePayment.paymentType", label: "' + label + '", value: "' + key + '"})\'></span>\n								</span>\n								<div class="b-payment__item-cell b-payment__item-cell--width-full">\n									<div class="b-payment__item-border"></div>\n								</div>\n								<span class="b-payment__item-cell b-payment__item-cell--type-amount">\n									' +
((__t = ( Formatter.formatMoney(payment.price.amount, Formatter.getDecimal(payment.price.amount)) )) == null ? '' : __t) +
'\n									' +
((__t = ( Formatter.formatCurrency(payment.price.currencyCode) )) == null ? '' : __t) +
'\n								</span>\n							</div>\n						</div>\n					';
 }) ;
__p += '\n				';
 } ;
__p += '\n\n				<div class="b-payment__rules"></div>\n			</div>\n	';
 } ;
__p += '\n</div>\n';

}
return __p
}