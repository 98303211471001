// eslint-disable-next-line banned-modules
'use strict';

import store from 'store';

const Store = {
	set(name, data, time) {
		try {
			const __time = time || 0;
			return store.set(name, {
				data,
				time: __time,
				dateCreated: new Date().getTime(),
			});
		} catch (error) {
			logger.error(`Error set value to stor:`, error);
			return {};
		}
	},

	get(...args) {
		const temp = store.get(...args);
		if (
			!temp ||
			(temp.time &&
				temp.dateCreated + temp.time * 60 * 1000 < new Date().getTime())
		) {
			this.remove(...args);
			return undefined;
		}
		return temp.data;
	},

	remove(...args) {
		return store.remove(...args);
	},

	clear(...args) {
		return store.clear(...args);
	},

	getAll() {
		return store.getAll();
	},

	forEach(...args) {
		return store.forEach(...args);
	},
};

// Fix for ProvidePlugin which properly works only with old module definition
/* eslint-disable */
// module.exports = Store;

/* eslint-enable */

export default Store;
