// eslint-disable-next-line banned-modules
'use strict';

import './style.less';

import BaseView from '@/classes/base.view';
import template from './template.ejs';

import $ from 'jquery';

export default BaseView.extend({

	template,

	ui: {
		slider: '.b-slider__container',
	},

	events: {
		'click .b-slider__control--type-prev': 'moveSlideLeft',
		'click .b-slider__control--type-next': 'moveSlideRight',
	},

	render(...args) {
		BaseView.prototype.render.apply(this, args);
		_.defer(this.initializeSlider.bind(this));
	},

	initializeSlider(withoutAnimate = false) {
		const $slidesList = this.ui.slider.find('.b-slider__list');

		this.ui.slider.css({ width: '', height: '' });
		$slidesList.css({width: '', marginLeft: ''});
		$slidesList.find('.b-slider__slide').css({width: ''});

		const slidesCount = $slidesList.children().length;
		const sliderWidth = $slidesList.width() || 170;
		const sliderHeight = $slidesList.height() || 170;
		const sliderUlWidth = slidesCount * sliderWidth;

		if (slidesCount === 0) {
			return this;
		}

		this.ui.slider.css({ 
			width: sliderWidth,
			height: sliderHeight,
		});
		$slidesList.css({
			width: sliderUlWidth, 
		});
		$slidesList.find('.b-slider__slide').css('width', sliderWidth);

		this.currentSlide = this.currentSlide == null ? 0 : this.currentSlide;
		this.moveToSlide(this.currentSlide, withoutAnimate);

		return this;
	},

	moveToSlide(index, withoutAnimate = false) {
		const $slidesList = this.ui.slider.find('.b-slider__list');

		const slidesCount = $slidesList.children().length;
		const slidesWidth = $slidesList.children().width();

		if (this.sliderAnimate === true) {
			return this;
		}

		this.sliderAnimate = true;
		this.currentSlide = index;
		if (_.isNaN(index) || index >= slidesCount) {
			this.currentSlide = 0;
		} else if (index < 0) {
			this.currentSlide = slidesCount - 1;
		}

		const $slide = $($slidesList.children()[this.currentSlide]);
		if ($slide.length !== 0) {
			const imageUid = $slide.attr('data-image-uid');

			if (imageUid) {
				this.loadImage(`/midoffice/ibecorp-b2b/hotels/image?uid=${imageUid}`).then((img) => {
					this.$el.find('.b-slider__control').show();
					$slide.removeAttr('data-image-uid');
					$slide.html(img);
				});
			}
		}

		$slidesList.animate({
			marginLeft: this.currentSlide * slidesWidth * -1,
		}, withoutAnimate ? 0 : 300, () => {
			this.sliderAnimate = false;
		});

		return this;
	},

	loadImage(url) {
		return new Promise((resolve) => {
			const img = new Image();
			img.addEventListener('load', () => resolve(img));
			img.src = url;
		});
	},

	// EVENTS
	moveSlideLeft(e) {
		if (e != null) {
			e.preventDefault();
		}

		this.moveToSlide(--this.currentSlide);
	},

	moveSlideRight(e) {
		if (e != null) {
			e.preventDefault();
		}

		this.moveToSlide(++this.currentSlide);
	},

});
