module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="p-search__direction">\n	<span class="js-widget"  onclick=\'return new Widgets.Places({ bindingProperty: "departure", placeholder: L10N.get("searchForm.from") })\'></span>\n	<div class="p-search__direction-swap" data-route-cid="' +
((__t = ( this.model.cid )) == null ? '' : __t) +
'">\n		<div class="p-search__direction-swap-img"></div>\n	</div>\n    <span class="js-widget"  onclick=\'return new Widgets.Places({ bindingProperty: "arrival", placeholder: L10N.get("searchForm.to") })\'></span>\n    <span class="b-search-route__remove"></span>\n</div>\n\n<div class="p-search-form__element__dates-container">\n	<div class="p-search__options-date">\n		<div class="p-search__options-date-element pikaday-modal_show-date-to">\n			';
 var placeholder = searchModel.isComplex() ? L10N.get("searchForm.departureDateComplexFlight") : L10N.get("searchForm.placeholderThereDate") ;
__p += '\n			<span class="js-widget"  onclick=\'return new Widgets.Datepicker({ bindingProperty: "dateTo", dateFormat: "DD MMM", placeholder: "' + placeholder + '", pikadayModalCid: "'+ cid +'" })\'></span>\n		</div>\n		<div class="p-search__options-date-element date-from-datepicker pikaday-modal_show-date-from">\n			<span class="js-widget"  onclick=\'return new Widgets.Datepicker({ bindingProperty: "dateFrom", dateFormat: "DD MMM", depend: "dateTo", dependTime: "timeTo", diff: 7, removable: true, placeholder: L10N.get("searchForm.placeholderBackDate"), pikadayModalCid: "'+ cid +'" })\'></span>\n		</div>\n	</div>\n</div>\n\n';
 if (searchModel.isComplex()) { ;
__p += '\n<div class="p-search-form__additional-options-container">\n    <div class="p-search-form__additional-options-element">\n        <span class="js-widget"  onclick=\'return new Widgets.AirlinesInput({ bindingProperty:"airline", placeholder: L10N.get("searchForm.airline"), label: null })\'></span>\n    </div>\n    <div class="p-search-form__additional-options-element">\n        <span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "flightNumber", placeholder: L10N.get("searchForm.flightNumberShort"), classes: "b-flight-number-input" })\'></span>\n    </div>\n</div>\n';
 } ;
__p += '\n\n\n';

}
return __p
}