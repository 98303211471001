module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var settings = STATE.getSettings() || {}
    var isParamsBlockExpanded = this.isAdditionalParamsBlockExpanded();
;
__p += '\r\n<div class="b-attachment-wrapper">\r\n	<div class="b-attachment-avia">\r\n		<div class="b-attachment-avia__wrapper">\r\n			<div\r\n                class=\'b-search-form__params js-attachment-avia-group b-attachment-avia__group ' +
((__t = ( this.model.isComplex() ? "b-attachment-avia__group--complex" : "" )) == null ? '' : __t) +
'\'\r\n            >\r\n                <div class="b-attachment-avia__group-airlines">\r\n                    <div\r\n                        class="b-attachment-avia__additional-params-header collapse--with-anchor--white"\r\n                        data-toggle="collapse"\r\n                        data-target=".toggle-additional-params"\r\n                        ';
 if (isParamsBlockExpanded) { ;
__p += 'aria-expanded="true"';
 } ;
__p += '\r\n                    >\r\n                        ' +
((__t = ( L10N.get("searchForm.additionalParams") )) == null ? '' : __t) +
'\r\n                    </div>\r\n                    <div class=\'toggle-additional-params collapse ' +
((__t = ( isParamsBlockExpanded ? "show" : "" )) == null ? '' : __t) +
'\'>\r\n                        <div>\r\n                            <div class="b-attachment-avia__additional-params-header">' +
((__t = ( L10N.get("nearestMatrix.to") )) == null ? '' : __t) +
'</div>\r\n                            <div class="b-attachment-avia__group-airlines--container">\r\n                                <span class="b-search-form__param-airlines">\r\n                                    <span class="js-widget"  onclick=\'return new Widgets.AirlinesInput({ bindingProperty:"airlineTo", placeholder: L10N.get("searchForm.airline"), label: null })\'></span>\r\n                                </span>\r\n                                <span class="b-search-form__param-flight-number">\r\n                                    <span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "flightNumberTo", placeholder: L10N.get("searchForm.flightNumber") })\'></span>\r\n                                </span>\r\n                                <div class="p-search__options-time p-search__options-time_position p-search__options-time-from">\r\n                                    <div class="p-search__options-bridge"></div>\r\n                                    <span class="js-widget"  onclick=\'return new Widgets.Timerange({ bindingProperty: "time", classes: "p-search__input" })\'></span>\r\n                                </div>\r\n                            </div>\r\n                        </div>\r\n                        <div class="js-route-type-roundtrip ' +
((__t = ( this.model.get('routeType') === 'ONEWAY' ? 'b-attachment-avia__group-airlines--dn' : '')) == null ? '' : __t) +
'">\r\n                            <div class="b-attachment-avia__additional-params-header">' +
((__t = ( L10N.get("nearestMatrix.from") )) == null ? '' : __t) +
'</div>\r\n                            <div class="b-attachment-avia__group-airlines--container">\r\n                                <span class="b-search-form__param-airlines">\r\n                                    <span class="js-widget"  onclick=\'return new Widgets.AirlinesInput({ bindingProperty:"airlineFrom", placeholder: L10N.get("searchForm.airline"), label: null })\'></span>\r\n                                </span>\r\n                                <span class="b-search-form__param-flight-number">\r\n                                    <span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "flightNumberFrom", placeholder: L10N.get("searchForm.flightNumber") })\'></span>\r\n                                </span>\r\n                                <div class="p-search__options-time p-search__options-time_position p-search__options-time-from">\r\n                                    <div class="p-search__options-bridge"></div>\r\n                                    <span class="js-widget"  onclick=\'return new Widgets.Timerange({ depend: "routeType", bindingProperty: "timeTo", classes: "p-search__input" })\'></span>\r\n                                </div>\r\n                            </div>\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n                <div class="b-attachment-avia__group-checkboxes">\r\n                    <span class="b-search-form__param-complex">\r\n                        <span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "isComplex", label: L10N.getUnescaped("searchForm.complexFlight")})\'></span>\r\n                    </span>\r\n                    ' +
((__t = ( require("./template-group-checkboxes-content.ejs")({ settings: settings }) )) == null ? '' : __t) +
'\r\n                </div>\r\n            </div>\r\n			\r\n			<div class="p-search-form__group-button b-search-form__param-buttons">\r\n				<div class="b-search-form__param-button p-search-form__add">\r\n					<a href="#" class="p-search-form__add-button">' +
((__t = ( L10N.get('searchForm.addButton') )) == null ? '' : __t) +
'</a>\r\n				</div>\r\n			</div>\r\n		</div>\r\n	</div>\r\n</div>\r\n';

}
return __p
}