// eslint-disable-next-line banned-modules
'use strict';

import $ from 'jquery';
import Formatter from '@/utils/formatter';
import SliderView from '@/blocks/elements/b-slider/';

export default (() => {
	class GMarker {
		static getCustomOverlay(args) {
			function customOverlay(options) {
				this.map_ = options.map;
				this.div_ = null;
				this.args_ = options.args;
				this.latlng_ = options.position;
				this.setMap(this.map_);
			}

			try {
				customOverlay.prototype = new window.google.maps.OverlayView();
			} catch (e) {
				throw e;
			}

			customOverlay.prototype.getBlock = args.getBlock != null ? args.getBlock : function getBlock() {
				return document.createElement('div');
			};

			customOverlay.prototype.setPosition = args.setPosition != null ? args.setPosition : function setPosition(div, d) {
				div.style.left = `${(d.x - parseInt($(div).width() / 2) - 2)}px`;
				div.style.top = `${(d.y - 26)}px`;
			};

			customOverlay.prototype.onAdd = function onAdd() {
				const div = this.getBlock(this.args_, this.map_);
				this.div_ = div;

				const panes = this.getPanes();
				panes.overlayImage.appendChild(div);
			};

			customOverlay.prototype.draw = function draw() {
				const overlayProjection = this.getProjection();
				if (!overlayProjection) {
					return;
				}
				const d = overlayProjection.fromLatLngToDivPixel(this.latlng_);
				const div = this.div_;

				this.setPosition(div, d);
			};

			customOverlay.prototype.onRemove = function onRemove() {
				if (this.div_ != null && this.div_.parentNode != null) {
					this.div_.parentNode.removeChild(this.div_);
				}
				this.div_ = null;
			};

			customOverlay.prototype.setVisible = function setVisible(bool) {
				if (this.div_) {
					this.div_.style.display = bool ? 'block' : 'none';
				}
			};

			customOverlay.prototype.getPosition = function getPosition() {
				return this.latlng_;
			};

			customOverlay.prototype.remove = function remove() {
				if (this.div) {
					this.div.parentNode.removeChild(this.div);
					this.div = null;
				}
			};

			return customOverlay;
		}

		static getHotelsMarker(view) {
			const parent = view;
			const infoboxHotel = GMarker.getInfoboxHotel(view);

			return GMarker.getCustomOverlay({
				getBlock: function getBlock(hotel, map) {
					const div = document.createElement('div');
					div.className = 'hotels-map-marker js-hotels-map-marker';
					div.id_ = hotel.number;
					div.setAttribute('data-stars', hotel.hotelStars);
					div.setAttribute('data-hotel-id', hotel.number);

					const innerDiv = document.createElement('div');
					const firstOffer = _.first(hotel.hotelOffers);
					innerDiv.innerHTML = (firstOffer)
						? `${Formatter.formatMoney(firstOffer.price.total.amount, 0)} ${Formatter.formatCurrency(firstOffer.price.total.currencyCode)}`
						: L10N.get('hotels.map.unknown');
					div.appendChild(innerDiv);

					$(div).tooltip({
						title: `
							${L10N.get('hotels.map.generalHotelWord')}
							<strong>${hotel.name}</strong>
							${(hotel.hotelStars && hotel.hotelStars > 0) ? `${hotel.hotelStars}*` : `(${L10N.get('hotels.map.starsUndefined')})`}
						`,
						html: true,
						placement: 'bottom',
						container: 'body',
					});

					try {
						window.google.maps.event.addDomListener(div, 'click', () => {
							parent.$el.find('.js-hotels-map .js-hotels-map-infobox').remove();
							const ibox = new infoboxHotel({
								position: hotel.latLng,
								args: hotel,
								map,
							});
							map.panTo(hotel.latLng);
							ibox.getPanes().overlayImage.style.zIndex = 1001;
						});
					} catch (e) {
						throw e;
					}

					return div;
				},
				setPosition: function setPosition(div, d) {
					div.style.left = `${d.x}px`;
					div.style.top = `${d.y}px`;
				},
			});
		}

		static getInfoboxHotel(view) {
			const parent = view;
			return GMarker.getCustomOverlay({
				getBlock: function getBlock(hotel) {
					const div = document.createElement('div');
					div.className = 'hotels-map-infobox js-hotels-map-infobox ';
					div.setAttribute('data-stars', hotel.hotelStars);

					const infoboxHead = document.createElement('div');
					infoboxHead.className = 'hotels-map-infobox-head clearfix';

					const infoboxHeadContainer = document.createElement('div');
					infoboxHeadContainer.className = 'hotels-map-infobox-head-container';

					const infoboxHeadH3 = document.createElement('h3');
					infoboxHeadH3.innerHTML = hotel.name;
					infoboxHeadContainer.appendChild(infoboxHeadH3);

					$(infoboxHeadH3).tooltip({
						container: 'body',
						html: true,
						title: hotel.name,
					});

					const infoboxHeadStars = document.createElement('span');
					infoboxHeadStars.className = 'b-hotel-stars clearfix';

					for (let i = 0; i < hotel.hotelStars; i++) {
						const infoboxStar = document.createElement('span');
						infoboxStar.className = 'b-hotel-star';
						infoboxHeadStars.appendChild(infoboxStar);
					}
					infoboxHeadContainer.appendChild(infoboxHeadStars);

					const infoboxHeadClose = document.createElement('div');
					infoboxHeadClose.className = 'hotels-map-infobox-close';

					infoboxHead.appendChild(infoboxHeadContainer);
					infoboxHead.appendChild(infoboxHeadClose);

					try {
						window.google.maps.event.addDomListener(infoboxHeadClose, 'click', () => {
							this.setMap(null);
						});
					} catch (e) {
						throw e;
					}

					div.appendChild(infoboxHead);

					const infoboxBody = document.createElement('div');
					infoboxBody.className = 'hotels-map-infobox-body';

					const infoboxSliderWrap = document.createElement('div');
					infoboxSliderWrap.className = 'hotels-map-infobox-slider-wrap js-hotels-map-infobox-slider-wrap';
					infoboxBody.appendChild(infoboxSliderWrap);

					const slider = new SliderView({
						images: hotel.images || [],
					});
					infoboxSliderWrap.appendChild(slider.$el[0]);

					const infoboxButtonWrap = document.createElement('div');
					infoboxButtonWrap.className = 'hotels-map-infobox-button-wrap';

					const firstOffer = _.first(hotel.hotelOffers);
					const infoboxButton = document.createElement('button');
					infoboxButton.className = 'hotels-map-infobox-button';
					infoboxButton.innerHTML = `${L10N.get('hotels.map.selectRoom')} `;
					infoboxButton.innerHTML = (firstOffer)
						?
							`
								${L10N.get('hotels.map.selectRoom')}
								${Formatter.formatMoney(firstOffer.price.total.amount, 0)}
								${Formatter.formatCurrency(firstOffer.price.total.currencyCode)}
							`
						: L10N.get('hotels.selectRoom');

					try {
						window.google.maps.event.addDomListener(infoboxButton, 'click', () => {
							this.setMap(null);
							parent.mapClose();
							parent.selectHotelOnMap(hotel);
						});
					} catch (e) {
						throw e;
					}

					infoboxButtonWrap.appendChild(infoboxButton);
					infoboxBody.appendChild(infoboxButtonWrap);
					div.appendChild(infoboxBody);

					return div;
				},
				setPosition: function setPosition(div, d) {
					div.style.left = `${(d.x - parseInt($(div).width() / 2) - 2)}px`;
					div.style.top = `${(d.y - 15)}px`;
				},
			});
		}
	}

	return GMarker;
}
)();
