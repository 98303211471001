module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div\n    class="b-hotel-offers-offer b-hotel-offer ' +
((__t = ( manyRooms ? 'b-hotel-offer--selection-room' : '' )) == null ? '' : __t) +
'"\n    data-offer-id="' +
((__t = ( offer.id )) == null ? '' : __t) +
'"\n    data-offer-index="' +
((__t = ( offer.__index )) == null ? '' : __t) +
'"\n>\n	<div class="b-hotel-offer__row b-hotel-offer__common-wrapper">\n		<div class="b-hotel-offer__name">\n			<div data-toggle="tooltip"\n				 data-placement="bottom"\n				 data-html="true"\n				 data-original-title="\n				 <div>\n					 <div>' +
((__t = ( _.escape(offer.roomName)  )) == null ? '' : __t) +
'</div>\n					 <br>\n					 <div>' +
((__t = ( _.escape(offer.roomDescription)  )) == null ? '' : __t) +
'</div>\n				 </div>"\n			>\n				' +
((__t = ( offer.roomName )) == null ? '' : __t) +
'\n			</div>\n		</div>\n        <div class="b-hotel-offer__info-container">\n            <div>\n                ';
 if (offer.online !== true) { ;
__p += '\n                    <span class="b-hotel-offer__online b-hotel-offer__important b-hotel-offer__info-tag">' +
((__t = ( L10N.get('hotels.byRequest') )) == null ? '' : __t) +
'</span>\n                ';
 } ;
__p += '\n                ';
 if (offer.roomsNumber > 0) { ;
__p += '\n                    <span class="b-hotel-offer__rooms-count b-hotel-offer__info-tag">\n                    ' +
((__t = ( offer.roomsNumber )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.getNumEnding(offer.roomsNumber, L10N.get('formatter.endings.room')) )) == null ? '' : __t) +
'\n                    </span>\n                ';
 } ;
__p += '\n            </div>\n\n            ';
 if (offer.paymentInHotel === true) { ;
__p += '\n                <div class="b-hotel-offer__important b-hotel-offer__info-tag">\n                    ' +
((__t = ( L10N.get('hotels.paymentInHotel') + (
                            (offer.guarantees && offer.guarantees.length > 0) ? ', ' + L10N.get('hotels.guarantees.' + offer.guarantees[0].type.uid) : ''
                    ) )) == null ? '' : __t) +
'\n                </div>\n            ';
 } ;
__p += '\n\n            ';
 if (showGdsAccountName) { ;
__p += '\n                <div class="b-hotel-offer__mobile-provider b-hotel-offer__info-tag">\n                    ' +
((__t = ( offer.provider.caption )) == null ? '' : __t) +
'\n                </div>\n            ';
 } ;
__p += '\n        </div>\n\n        ';
 if (!_.isEmpty(offer.essentialInfoList)) {
            const result = {};
			_.each(offer.essentialInfoList, function(el) {
				if (!el) return;
				if (!result[el.type.uid]) {
					result[el.type.uid] = el.infoValue;
					return;
				}
				result[el.type.uid].push(...el.infoValue);
			});
        ;
__p += '\n            <div class="b-hotel-offer b-hotel-offer__info-icons">\n				';
 _.each(_.keys(result), function (key) {
					const value = _.sortBy(result[key], function(el) { return el.length});
						if (key === "ADDITIONAL_INFORMATION") { ;
__p += '\n								<span class="b-hotel-offer__information" data-toggle="tooltip" data-html="true" data-original-title="<div>' +
((__t = ( _.escape(_.map(value).join('</br></br>')) )) == null ? '' : __t) +
'</div>">';
 ;
__p += '</span>\n						';
 }
            if (key === "ROOM_AMENITIES") { ;
__p += '\n              <span class="b-hotel-offer__agencyInfo" data-toggle="tooltip" data-html="true" data-original-title="<div>' +
((__t = ( _.escape(_.map(value).join(', ')) )) == null ? '' : __t) +
'</div>">';
 ;
__p += '</span>\n            ';
 }
						if (key === "CHECK_IN_POLICIES") { ;
__p += '\n								<span class="b-hotel-offer__passport" data-toggle="tooltip" data-html="true" data-original-title="<div>' +
((__t = ( _.escape(_.map(value).join('</br></br>')) )) == null ? '' : __t) +
'</div>">';
 ;
__p += '</span>\n						';
 }
						if (key === "ANTI_COVID_PROGRAM") { ;
__p += '\n								<span class="b-hotel-offer__anti-covid" data-toggle="tooltip" data-html="true" data-original-title="<div>' +
((__t = ( _.escape(_.map(value).join('</br></br>')) )) == null ? '' : __t) +
'</div>">';
 ;
__p += '</span>\n						';
 }
						if (key === "AGENCY_INFORMATION") { ;
__p += '\n								<span class="b-hotel-offer__agencyInfo" data-toggle="tooltip" data-html="true" data-original-title="<div>' +
((__t = ( _.escape(_.map(value).join('</br></br>')) )) == null ? '' : __t) +
'</div>">';
 ;
__p += '</span>\n						';
 }
                    });
__p += '\n				</div>\n        ';
} ;
__p += '\n\n		';
 if (group.length > 1) { ;
__p += '\n			<div class="b-hotel-offer__additional-prices">\n				' +
((__t = ( L10N.get('hotels.more') )) == null ? '' : __t) +
'\n				' +
((__t = ( Formatter.getNumEnding(2, L10N.get('formatter.endings.offer')) )) == null ? '' : __t) +
'\n			</div>\n		';
 } ;
__p += '\n	</div>\n\n	<div class="b-hotel-offer__row b-hotel-offer__essential-wrapper b-hotel-offer__auto-width js-offer-essential-wrapper">\n		' +
((__t = ( require('./template-offer-essential')({offer:offer}))) == null ? '' : __t) +
'\n	</div>\n\n	';
 if (offer.meal != null || !_.isEmpty(offer.options)) { ;
__p += '\n		<div class="b-hotel-offer__row b-hotel-offer__room-wrapper b-hotel-offer__auto-width">\n			';
 if (offer.meal != null) { ;
__p += '\n				';
 var captionMeal = (offer.meal != null && !['-1', 'nomeal'].includes(offer.meal.uid) && !['-', L10N.get('hotels.withoutMeal')].includes(offer.meal.caption)) ? offer.meal.caption : L10N.get('hotels.withoutMeal'); ;
__p += '\n				<div class="b-hotel-offer__meal" data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( _.escape(captionMeal) )) == null ? '' : __t) +
'">\n					' +
((__t = ( captionMeal )) == null ? '' : __t) +
'\n				</div>\n			';
 }

			if (_.some(offer.options, function (o) {
				return _.isArray(o) && !_.isEmpty(o);
			})) {
				var optionsHTML = '<div>';
				var options = offer.options;
				var checkOptions = [];

				if (!_.isEmpty(options.earlierCheckIn)) {
					checkOptions.push(L10N.get('hotels.earlyCheckIn'));
				}
				if (!_.isEmpty(options.laterCheckOut)) {
					checkOptions.push(L10N.get('hotels.lateCheckout'));
				}

				if (!_.isEmpty(checkOptions)) {
					// Capitalize for perfect look
					checkOptions[0] = checkOptions[0].charAt(0).toUpperCase() + checkOptions[0].slice(1);
					optionsHTML += checkOptions.join(', ');
				}

				if (!_.isEmpty(options.meals)) {
					optionsHTML += '<br />' + L10N.get('hotels.meal') + ': <ul class="options-list">';
					optionsHTML += _.map(options.meals, function (m) {
						return '<li>' + m.caption + '</li>';
					}).join('');
					optionsHTML += '</ul>'
				}

				optionsHTML += '</div>'; ;
__p += '\n\n				<div data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="' +
((__t = ( _.escape(optionsHTML) )) == null ? '' : __t) +
'">\n					<span class="additional-services-link action-link action-link--color-blue">' +
((__t = ( L10N.get('cabinet.orders.hotelAddServices') )) == null ? '' : __t) +
'</span>\n				</div>\n			';
 } ;
__p += '\n		</div>\n	';
 } ;
__p += '\n\n	<div class="b-hotel-offer__line b-hotel-offer__line--long"></div>\n	';
 if (showGdsAccountName) { ;
__p += '\n		<div class="b-hotel-offer__row b-hotel-offer__provider-wrapper" style="flex: 0">\n			<div class="b-hotel-offer__provider">' +
((__t = ( offer.provider.caption )) == null ? '' : __t) +
' <br>\n				';
if (offer.subProvider) { ;
__p += '\n					<span class="b-hotel-offer__sub-provider">' +
((__t = ( offer.subProvider )) == null ? '' : __t) +
'</span>\n				';
};
__p += '\n		</div>\n		</div>\n	';
 } ;
__p += '\n	<div class="b-hotel-offer__line b-hotel-offer__line--short"></div>\n\n	<div class="b-hotel-offer__row b-hotel-offer__select ' +
((__t = ( manyRooms === true ? 'many-rooms' : '' )) == null ? '' : __t) +
' ' +
((__t = ( (issueAllowed === true && manyRooms !== true) ? 'b-hotel-offer__select--offer' : '' )) == null ? '' : __t) +
'">\n		';
 if (manyRooms) {
			var roomsNumber = offer.roomsNumber;
			var maxRooms = (+roomsNumber > roomsCount) ? roomsCount : roomsNumber;
			var name = 'roomsCountOffer.' + offer.__index;
			var additionalData = "";
			if (offer.id) {
				additionalData = "offerId:::" + offer.id;
			}
		;
__p += '\n			<span class="js-widget"  onclick=\'return new Widgets.NumerablePicker({ bindingProperty: "' + name + '", classes: "b-numerable-picker-hotels js-b-numerable-picker-hotels", plurarForm: "searchForm.rooms", max: "' + maxRooms + '", additionalData: "' + additionalData + '" })\'></span>\n		';
 } ;
__p += '\n        ';
 if (!manyRooms) { ;
__p += '\n            <div class="b-hotel-offer__vat desktop-hide">\n                ';
 if (offer.price.vat || offer.price.vat === 0) { ;
__p += '\n                    <span>' +
((__t = ( L10N.get('hotels.vatBraces') )) == null ? '' : __t) +
' ' +
((__t = ( offer.price.vat )) == null ? '' : __t) +
'%)</span>\n                ';
 } else { ;
__p += '\n                    <span>' +
((__t = ( L10N.get('hotels.withOutVat') )) == null ? '' : __t) +
'</span>\n                ';
 } ;
__p += '\n            </div>\n            ';
 if (offer.dailyAveragePrice != null) { ;
__p += '\n                <div class="b-hotel-offer__daily-average-price desktop-hide">\n                    ' +
((__t = ( L10N.get('filters.pricePerDay') )) == null ? '' : __t) +
':\n                    <span class="b-hotel-offer__daily-average-price-amount">\n                        ' +
((__t = ( Formatter.formatMoney(offer.dailyAveragePrice.amount, Formatter.getDecimal(offer.dailyAveragePrice.amount)) )) == null ? '' : __t) +
'\n                    </span>\n                    ' +
((__t = ( Formatter.formatCurrency(offer.dailyAveragePrice.currency) )) == null ? '' : __t) +
'\n                </div>\n            ';
 } ;
__p += '\n        ';
 } ;
__p += '\n		    <div class="b-hotel-offer__select-container">\n            ';
 if (manyRooms) { ;
__p += '\n				<div class="many-rooms b-hotel-offer__select-button-price"\n                     data-toggle="tooltip" data-placement="bottom" data-html="true"\n                     data-original-title="' +
((__t = ( _.escape(require('../b-offer-pricing.ejs')({ price: offer.price, taxInfoList: offer.taxInfoList, offer: offer })) )) == null ? '' : __t) +
'"\n                >\n                    <span>\n                        ' +
((__t = ( Formatter.formatMoney(offer.price.total.amount, Formatter.getDecimal(offer.price.total.amount)) )) == null ? '' : __t) +
'\n                        ' +
((__t = ( Formatter.formatCurrency(offer.price.total.currencyCode) )) == null ? '' : __t) +
'\n                    </span>\n					<span>\n                        ';
 if (offer.price.vat || offer.price.vat === 0) { ;
__p += '\n                            ' +
((__t = ( L10N.get('hotels.vatBraces') )) == null ? '' : __t) +
' ' +
((__t = ( offer.price.vat )) == null ? '' : __t) +
'%)\n                        ';
 } else { ;
__p += '\n                            ' +
((__t = ( L10N.get('hotels.withOutVat') )) == null ? '' : __t) +
'\n                        ';
 } ;
__p += '\n                    </span>\n				</div>\n                ';
 if (offer.dailyAveragePrice != null) { ;
__p += '\n                    <div class="many-rooms b-hotel-offer__daily-average-price">\n                        ' +
((__t = ( L10N.get('filters.pricePerDay') )) == null ? '' : __t) +
':\n                        <span class="b-hotel-offer__daily-average-price-amount">\n                            ' +
((__t = ( Formatter.formatMoney(offer.dailyAveragePrice.amount, Formatter.getDecimal(offer.dailyAveragePrice.amount)) )) == null ? '' : __t) +
'\n                        </span>\n                        ' +
((__t = ( Formatter.formatCurrency(offer.dailyAveragePrice.currency) )) == null ? '' : __t) +
'\n                    </div>\n                ';
 } ;
__p += '\n			';
 } else { ;
__p += '\n				    ';
 if (issueAllowed) { ;
__p += '\n                        <div class="b-hotel-offer__select-button"\n                             data-toggle="tooltip" data-placement="bottom" data-html="true"\n                             data-original-title="' +
((__t = ( _.escape(require('../b-offer-pricing.ejs')({ price: offer.price, taxInfoList: offer.taxInfoList, offer: offer })) )) == null ? '' : __t) +
'"\n                             data-offer-id="' +
((__t = ( offer.id )) == null ? '' : __t) +
'"\n                             data-offer-index="' +
((__t = ( offer.__index )) == null ? '' : __t) +
'">\n                            <div class="b-hotel-offer__select-button-price">\n                                ' +
((__t = ( Formatter.formatMoney(offer.price.total.amount, Formatter.getDecimal(offer.price.total.amount)) )) == null ? '' : __t) +
'\n                                ' +
((__t = ( Formatter.formatCurrency(offer.price.total.currencyCode) )) == null ? '' : __t) +
'\n                            </div>\n                        </div>\n                    ';
 } else { ;
__p += '\n                            <div class="b-hotel-offer__select-button-price price-only"\n                                 data-toggle="tooltip" data-placement="bottom" data-html="true"\n                                 data-original-title="' +
((__t = ( _.escape(require('../b-offer-pricing.ejs')({ price: offer.price, taxInfoList: offer.taxInfoList, offer: offer })) )) == null ? '' : __t) +
'"\n                                 data-offer-id="' +
((__t = ( offer.id )) == null ? '' : __t) +
'"\n                                 data-offer-index="' +
((__t = ( offer.__index )) == null ? '' : __t) +
'"\n                            >\n                                ' +
((__t = ( Formatter.formatMoney(offer.price.total.amount, Formatter.getDecimal(offer.price.total.amount)) )) == null ? '' : __t) +
'\n                                ' +
((__t = ( Formatter.formatCurrency(offer.price.total.currencyCode) )) == null ? '' : __t) +
'\n                            </div>\n                    ';
 } ;
__p += '\n                    ';
 if (offer.dailyAveragePrice != null) { ;
__p += '\n                        <div class="b-hotel-offer__daily-average-price mobile-hide">\n                            ' +
((__t = ( L10N.get('filters.pricePerDay') )) == null ? '' : __t) +
':\n                            <span class="b-hotel-offer__daily-average-price-amount">\n                                ' +
((__t = ( Formatter.formatMoney(offer.dailyAveragePrice.amount, Formatter.getDecimal(offer.dailyAveragePrice.amount)) )) == null ? '' : __t) +
'\n                            </span>\n                            ' +
((__t = ( Formatter.formatCurrency(offer.dailyAveragePrice.currency) )) == null ? '' : __t) +
'\n                        </div>\n                    ';
 } ;
__p += '\n					<div class="b-hotel-offer__vat mobile-hide">\n					';
 if (offer.price.vat || offer.price.vat === 0) { ;
__p += '\n							<span>' +
((__t = ( L10N.get('hotels.vatBraces') )) == null ? '' : __t) +
' ' +
((__t = ( offer.price.vat )) == null ? '' : __t) +
'%)</span>\n					';
 } else { ;
__p += '\n							<span>' +
((__t = ( L10N.get('hotels.withOutVat') )) == null ? '' : __t) +
'</span>\n					';
 } ;
__p += '\n					</div>\n\n				';
 if (approvalAllowed) { ;
__p += '\n					<div class="b-hotel-offer__select-button--offer ' +
((__t = ( manyRooms ? 'b-hotel-offer__select-button--disabled' : '' )) == null ? '' : __t) +
'"\n						data-toggle="tooltip" data-placement="bottom" data-html="true"\n						data-original-title="' +
((__t = ( _.escape(require('../b-offer-pricing.ejs')({ price: offer.price, taxInfoList: offer.taxInfoList, offer: offer })) )) == null ? '' : __t) +
'"\n						data-offer-id="' +
((__t = ( offer.id )) == null ? '' : __t) +
'"\n						data-offer-index="' +
((__t = ( offer.__index )) == null ? '' : __t) +
'"\n					>\n						<div class="b-hotel-offer__select-button-price--offer">\n							' +
((__t = ( L10N.get('hotels.addToAgree') )) == null ? '' : __t) +
'\n						</div>\n					</div>\n				';
 } ;
__p += '\n			';
 } ;
__p += '\n		</div>\n	</div>\n	<div class="b-hotel-offer__select-info">\n		';
 if ((offer.travelPolicyCompliance != null && !_.isEmpty(offer.travelPolicyCompliance.rulesApplied)) || offer.tripartiteContract === true) { ;
__p += '\n			<span class="b-hotel-offer__travel-policy b-travel-policy">\n				' +
((__t = ( require('../b-travel-policy.ejs')({
					travelPolicyCompliance: offer.travelPolicyCompliance,
					tripartiteContract: offer.tripartiteContract
				}) )) == null ? '' : __t) +
'\n			</span>\n		';
 } ;
__p += '\n		';
 if (offer.rackRate != null) { ;
__p += '\n			<span class="b-hotel-offer__RP">\n				RR\n				' +
((__t = ( Formatter.formatMoney(offer.rackRate.amount, 0) )) == null ? '' : __t) +
'\n				' +
((__t = ( Formatter.formatCurrency(offer.rackRate.currencyCode) )) == null ? '' : __t) +
'\n			</span>\n		';
 } ;
__p += '\n	</div>\n</div>\n';

}
return __p
}