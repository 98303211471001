import React from "react";
import { Reservation } from "../../../../../../helpers/interfaces/Reservation";
import { Service } from "../../../../../../helpers/interfaces/Service";
import { Container, StatisticsPopupServices } from "./styled";
import StatisticsPopupHeader from "./components/StatisticsPopupHeader";
import StatisticsPopupService from "./components/StatisticsPopupService";

interface StatisticsPopupContainerProps {
	reservation: Reservation;
	closePopup: () => void;
}

const StatisticsPopupContainer: React.FC<StatisticsPopupContainerProps> = (
	props
) => {
	const { reservation, closePopup } = props;
	const { services = [] } = reservation || {};

	return (
		<Container>
			<StatisticsPopupHeader
				closePopup={closePopup}
				reservation={reservation}
			/>
			<StatisticsPopupServices>
				{services.map((service: Service) => {
					return (
						<StatisticsPopupService service={service} key={service?.uid} />
					);
				})}
			</StatisticsPopupServices>
		</Container>
	);
};
export default StatisticsPopupContainer;
