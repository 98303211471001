module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<label class="b-input incomplete">\r\n	<span class="b-input__label">' +
((__t = (label )) == null ? '' : __t) +
'</span>\r\n	<input class="b-input__value b-masked-input-date__value" type="text">\r\n</label>\r\n';

}
return __p
}