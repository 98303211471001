module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var position = position; ;
__p += '\r\n<label class="b-toggle ' +
((__t = (classes )) == null ? '' : __t) +
'">\r\n	';
 if (position === 'left') { ;
__p += '\r\n		<input type="checkbox" class="b-toggle__input">\r\n		<span class="b-toggle__label"><i class="b-toggle__view"></i>' +
((__t = ( label )) == null ? '' : __t) +
'</span>\r\n	';
 } else { ;
__p += '\r\n		<input type="checkbox" class="b-toggle__input">\r\n		<span class="b-toggle__label">\r\n			' +
((__t = ( label )) == null ? '' : __t) +
'\r\n			<i class="b-toggle__view position-right"></i>\r\n		</span>\r\n	';
 } ;
__p += '\r\n</label>\r\n';

}
return __p
}