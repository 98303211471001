// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view.js';
import ItemView from './itemView';
import template from './template.ejs';

export default BaseView.extend({
	template,

	events: {
		'click .b-selection__more': 'toggleMore',
	},

	ui: {
		items: '.b-selection__values',
		more: '.b-selection__more',
		attribute: '.b-selection__attribute',
	},

	initialize() {
		const { selectByDefault = true, disableOnSingularValue = true } =
			this.model.options || {};
		this.selectByDefault = selectByDefault;
		this.disableOnSingularValue = disableOnSingularValue;
		this.render();
		this.listenTo(this.model.get('values'), 'add', (item) => {
			this.ui.items.append(
				new ItemView({
					model: item,
				}).$el,
			);
		});
	},

	updateItems(searchFinished = false) {
		if (searchFinished) {
			// this.values = this.values || this.model.get('values');
			// const isSingularValue = _.size(this.values) === 1;
			// if (this.selectByDefault && isSingularValue) {
			// 	// this.values[0].set('value', true, {silent: true});
			// }
		}
	},

	render(...args) {
		BaseView.prototype.render.apply(this, args);

		this.values = _.isFunction(this.model.formatter)
			? this.model.formatter(this.model.get('values'))
			: null;
		this.values = this.values || this.model.get('values');

		this.values.each((item) => {
			const isSingularValue = _.size(this.values) === 1;
			// if (this.selectByDefault && isSingularValue) item.set("value", true);
			this.ui.items.append(
				new ItemView({
					model: item,
					disabled: this.disableOnSingularValue && isSingularValue,
					selectByDefault: this.selectByDefault,
				}).$el,
			);
		});

		this.toggleMore();
	},

	// EVENTS
	toggleMore(e) {
		if (e != null) {
			e.preventDefault();
		}

		if (this.ui.more.hasClass('collapsed')) {
			const { options = {} } = this.model;
			const limits = parseInt(options.limits, 10);
			if (!_.isNaN(limits) && this.values.length > limits) {
				this.ui.items.children().slice(limits).hide();
				this.ui.more
					.removeClass('dn collapsed')
					.text(options.limitLabel || L10N.get('hotels.more'));
			}
		} else {
			this.ui.items.children().show();
			this.ui.more.addClass('collapsed').text(L10N.get('hotels.hide'));
		}
	},
});
