// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';
import BaseCollection from '@/classes/base.collection';

export default BaseModel.extend({

	defaults: {
		departure: '',
		arrival: '',
		routeType: 'ONEWAY',
		serviceTypes: new BaseCollection({
			uid: 'AVIA',
			caption: 'AVIA',
		}),
		direct: false,
	},

	initialize() {
		if (!this.get('date')) {
			const time = new Time();
			this.set('date', time.format('YYYY-MM-DD'));
		}
		if (!this.get('depature') || this.get('departure') === '') {
			const settings = STATE.getSettings();
			this.set('departure', settings && settings.firstStepSettings && settings.firstStepSettings.defaultDepartureLocation);
		}
	},

	toJSON() {
		const result = {
			parameters: BaseModel.prototype.toJSON.call(this),
		};
		delete result.parameters.routeType;
		delete result.parameters.serviceTypes;
		if (!result.parameters.time) {
			result.parameters.departureTimeWindowStart = '10:00:00';
			result.parameters.departureTimeWindowEnd = '20:00:00';
		}
		return result;
	},

});
