import styled from "styled-components";

export const Container = styled.div`
	text-align: left;
	text-transform: initial;
`;

export const StatisticsPopupServices = styled.div`
	overflow: auto;
	max-height: 50vh;
  margin-top: 24px;
  
	> div:not(:first-child) {
		margin-top: 24px;
	}
`;
