import moment from "moment";

type DateValue = Date | null | undefined;

export const isSameDates = (oldDate: DateValue, newDate: DateValue) => {
  if (!oldDate && !newDate) {
    return true;
  }
  if (!oldDate || !newDate) {
    return false;
  }
  const isSameDay = moment(oldDate).isSame(moment(newDate), 'day');
  return isSameDay;
}