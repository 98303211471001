module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-pdf-view">\n	<div class="pdf-toolbar">\n		<div class="clearfix">\n			<div class="pdf-toolbar-center">\n				<div class="split-toolbar-button pdf-zoom-button clearfix">\n					<button id="zoomOut" class="toolbarButton zoomOut js-pdf-zoom-out" title="Zoom Out" tabindex="21">\n						<span>Zoom Out</span>\n					</button>\n					<div class="splitToolbarButtonSeparator"></div>\n					<button id="zoomIn" class="toolbarButton zoomIn js-pdf-zoom-in" title="Zoom In" tabindex="22">\n						<span>Zoom In</span>\n					</button>\n				</div>\n				<span id="scaleSelectContainer" class="dropdownToolbarButton">\n					<select id="scaleSelect" class="js-pdf-select-zoom" title="Zoom" tabindex="23">\n						 <option id="pageAutoOption" title="" value="auto" selected="selected">' +
((__t = ( L10N.get('Common.maps.auto') )) == null ? '' : __t) +
'</option>\n						 <option title="" value="0.5">50%</option>\n						 <option title="" value="0.75">75%</option>\n						 <option title="" value="1">100%</option>\n						 <option title="" value="1.25">125%</option>\n						 <option title="" value="1.5">150%</option>\n						 <option title="" value="2">200%</option>\n					</select>\n				</span>\n			</div>\n		</div>\n	</div>\n\n	<div class="pdf-container-docs">\n		';
 this.pdfs.forEach(function(document) {
			if (document.numPages) {
				_.times(document.numPages, function(numPage) {;
__p += '\n						<div class="pdf-container-doc"><canvas class="js-doc-canvas"></canvas></div>\n				';
 });
			}
		}); ;
__p += '\n	</div>\n</div>';

}
return __p
}