// eslint-disable-next-line banned-modules
'use strict';

import BaseView from '@/classes/base.view';
import template from './template.ejs';

export default BaseView.extend({

	template,

	initialize() {
		this.model = this.options.model;
		this.render(this.options);
	},
});
