module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<label class="b-input ' +
((__t = (classes )) == null ? '' : __t) +
'">\r\n	';
 if (label) { ;
__p += '\r\n		<span class="b-input__label">' +
((__t = (label )) == null ? '' : __t) +
'</span>\r\n	';
 } ;
__p += '\r\n	<input class="b-input__value' +
((__t = ( (label) ? '' : ' b-input__value--without-label' )) == null ? '' : __t) +
'" ' +
((__t = ( elementName != null ? 'name="' + elementName + '"' : '' )) == null ? '' : __t) +
' ' +
((__t = ( elementId != null ? 'id="' + elementId + '"' : '' )) == null ? '' : __t) +
' ' +
((__t = ( autocomplete != null ? 'autocomplete="' + autocomplete + '"' : '' )) == null ? '' : __t) +
' type="' +
((__t = (type)) == null ? '' : __t) +
'" placeholder="' +
((__t = (placeholder )) == null ? '' : __t) +
'">\r\n	';
 if (deletableField) { ;
__p += '\r\n		<div class="b-input__remove"></div>\r\n	';
 } ;
__p += '\r\n</label>\r\n';

}
return __p
}