module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-datepicker-modal" data-modal-id="' +
((__t = ( cid )) == null ? '' : __t) +
'">\n    <div class="b-datepicker-modal__header">\n        <div class="b-datepicker-modal__title">' +
((__t = ( L10N.get('searchForm.placeholderThereDate') )) == null ? '' : __t) +
'</div>\n        <div class="b-datepicker-modal__close-button"></div>\n    </div>\n    <div class="b-datepicker-modal__wrapper">\n        <div class="b-datepicker-modal__controls">\n            <div class="p-search__options-date">\n                <div class="p-search__options-date__element dateTo"></div>\n                <div class="p-search__options-date__element dateFrom"></div>\n            </div>\n        </div>\n        <div class="b-datepicker-modal__pikaday-container js-pikaday-container show-date-to"></div>\n        ';
 if (travelSubject.uid !== 'HOTEL') { ;
__p += '\n            <div class="b-datepicker-modal__one-way-button" style="display: none">' +
((__t = ( L10N.get('searchForm.noReturnTicketNeeded'))) == null ? '' : __t) +
'</div>\n        ';
 } ;
__p += '\n    </div>\n</div>\n';

}
return __p
}