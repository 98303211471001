module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	var finance = obj;
	function isActiveTab(value) {
		return finance.financeTab === value ? 'b-block-container__tab-btn_active' : '';
    }
	var currency = Formatter.formatCurrency(finance.currency || 'RUB');
	var createPrepaymentInvoice = createPrepaymentInvoice || false;

	var handleFieldColor = function(number) {
		if (number && typeof number === "number") {
			var result;
			if (number > 0) {
				result = '#099C9D';
			}
			if (number < 0) {
				result = '#D70000';
			}
			if (number === 0) {
				result = '#4A5767';
			}
			return result;
		}
	};


;
__p += '\n<div class="b-cabinet-finance">\n	<div class="b-block-container">\n		<div class="b-block-container__header">\n			<div class="b-block-container__header-label">' +
((__t = ( L10N.get('cabinet.finance.title') )) == null ? '' : __t) +
'</div>\n			<div class="b-block-container__header-right"></div>\n		</div>\n		<div class="b-block-container__top-controls">\n			<div class="b-block-container__group">\n				<div class="b-block-container__element">\n					<span class="js-widget"  onclick=\'return new Widgets.Select({bindingProperty: "contractor", values: "' + contractor +  '",  label: L10N.get("cabinet.finance.organization"), placeholder: L10N.get("cabinet.finance.organization")})\'></span>\n				</div>\n				<div class="b-block-container__element">\n					<span class="js-widget"  onclick=\'return new Widgets.Select({ bindingProperty: "companyRelationWith", values: "' + relations + '", label: L10N.get("cabinet.finance.relations"), placeholder: L10N.get("cabinet.finance.relations") })\'></span>\n				</div>\n				<div class="b-block-container__element">\n					';
 if (this.isClient()) { ;
__p += '\n						<span class="js-widget"  onclick=\'return new Widgets.FinanceAutocomplete({ bindingProperty: "organization", values: "' + clients + '", label: L10N.get("cabinet.finance.contractor"), placeholder: L10N.get("cabinet.finance.allClients"), deletableField: true })\'></span>\n					';
 } else { ;
__p += '\n						<span class="js-widget"  onclick=\'return new Widgets.FinanceAutocomplete({ bindingProperty: "organization", values: "' + agencies + '", label: L10N.get("cabinet.finance.agency"), placeholder: L10N.get("cabinet.finance.allAgencies"), deletableField: true })\'></span>\n					';
 } ;
__p += '\n				</div>\n			</div>\n			<div class="b-block-container__group">\n				<div class="b-block-container__group_column b-block-container__finance-summary">\n				<span class="b-block-container__element b-block-container__text">\n					' +
((__t = ( L10N.get('cabinet.finance.balance') )) == null ? '' : __t) +
': <span style="color:' +
((__t = ( handleFieldColor(parseInt(finance.currentBalance)) )) == null ? '' : __t) +
'" >' +
((__t = ( finance.currentBalance || 0 )) == null ? '' : __t) +
' ' +
((__t = ( isNaN(finance.currentBalance) ? null : currency )) == null ? '' : __t) +
'</span>\n				</span>\n					<span class="b-block-container__element b-block-container__text">\n					' +
((__t = ( L10N.get('cabinet.finance.credit') )) == null ? '' : __t) +
': <span>' +
((__t = ( finance.credit || 0 )) == null ? '' : __t) +
' ' +
((__t = ( isNaN(finance.credit) ? null : currency )) == null ? '' : __t) +
'</span>\n				</span>\n					<span class="b-block-container__element b-block-container__text">\n					' +
((__t = ( L10N.get('cabinet.finance.availableFunds') )) == null ? '' : __t) +
': <span style="color:' +
((__t = ( handleFieldColor(parseInt(finance.availableFunds)) )) == null ? '' : __t) +
'" >' +
((__t = ( finance.availableFunds || 0 )) == null ? '' : __t) +
' ' +
((__t = ( isNaN(finance.availableFunds) ? null : currency )) == null ? '' : __t) +
'</span>\n				</span>\n				</div>\n				';
 if (finance.isDebtAvailable) { ;
__p += '\n					<div class="b-block-container__group_column b-block-container__debt">\n						<span class="b-block-container__element b-block-container__text">\n					' +
((__t = ( L10N.get('cabinet.finance.shipmentDebt') )) == null ? '' : __t) +
': <span class="bold" style="color: ' +
((__t = ( parseInt(finance.shipmentDebt) > 0 ? '#D70000' : '#2F3842' )) == null ? '' : __t) +
'; font-weight: bold">' +
((__t = ( finance.shipmentDebt )) == null ? '' : __t) +
'  ' +
((__t = ( isNaN(finance.shipmentDebt) ? null : currency )) == null ? '' : __t) +
'</span>\n						</span>\n						<span class="b-block-container__element b-block-container__text">\n					' +
((__t = ( L10N.get('cabinet.finance.outstandingDebt') )) == null ? '' : __t) +
': <span class="bold" style="font-weight: bold">' +
((__t = ( finance.outstandingDebt || 0 )) == null ? '' : __t) +
' ' +
((__t = ( isNaN(finance.outstandingDebt) ? null : currency )) == null ? '' : __t) +
'</span>\n						</span>\n					</div>\n				';
 } ;
__p += '\n			</div>\n            ';
 if (createPrepaymentInvoice) { ;
__p += '\n                <div class="b-block-container__group_column b-block-container__submit-btn">\n                    <div class="l-grid-container">\n                        <div class="l-grid-layout-25">\n                            <div class="p-search__submit">' +
((__t = ( L10N.get('cabinet.finance.createPrepaymentBill') )) == null ? '' : __t) +
'</div>\n                        </div>\n                    </div>\n                </div>\n            ';
 } ;
__p += '\n		</div>\n		<div class="b-block-container__content">\n			<div class="b-block-container__group">\n				<div class="b-block-container__group">\n					<div class="b-block-container__element b-block-container__element-dates">\n						<div class="p-search__options-date p-search__options-date-with-label date-30">\n							<div class="p-search__options-date-element">\n								<div class="p-search__options-date-label">\n									' +
((__t = ( L10N.get('cabinet.finance.dates') )) == null ? '' : __t) +
'\n								</div>\n								<span class="js-widget"  onclick=\'return new Widgets.Datepicker({ bindingProperty: "startDate", dateFormat: "DD MMM YYYY", noMinDate: true, removable: true, placeholder: L10N.get("searchForm.departureDate") })\'></span>\n							</div>\n							<div class="p-search__options-date-element">\n								<span class="js-widget"  onclick=\'return new Widgets.Datepicker({ bindingProperty: "endDate", dateFormat: "DD MMM YYYY", noMinDate: true, depend: "startDate", diff: 7, removable: true, placeholder: L10N.get("searchForm.departureDate") })\'></span>\n							</div>\n						</div>\n					</div>\n\n				</div>\n				<div class="b-block-container__group_column b-block-container__period-balance">\n					<span class="b-block-container__element b-block-container__text">\n						' +
((__t = ( L10N.get('cabinet.finance.periodStartBalance') )) == null ? '' : __t) +
': <span class="b-block-container__date-balance">' +
((__t = ( finance.startDateBalance || '-' )) == null ? '' : __t) +
' ' +
((__t = ( finance.startDateBalance != null ? currency : '' )) == null ? '' : __t) +
'</span>\n					</span>\n					<span class="b-block-container__element b-block-container__text">\n						' +
((__t = ( L10N.get('cabinet.finance.periodEndBalance') )) == null ? '' : __t) +
': <span class="b-block-container__date-balance">' +
((__t = ( finance.endDateBalance || '-' )) == null ? '' : __t) +
' ' +
((__t = ( finance.endDateBalance != null ? currency : '' )) == null ? '' : __t) +
'</span>\n					</span>\n				</div>\n			</div>\n			<div class="b-block-container__group b-block-container__tab-group">\n				<a class="b-block-container__element b-block-container__tab-btn ' +
((__t = ( isActiveTab('transaction') )) == null ? '' : __t) +
'" data-tab="transaction">' +
((__t = ( L10N.get('cabinet.finance.transactionList') )) == null ? '' : __t) +
'</a>\n				<a class="b-block-container__element b-block-container__tab-btn ' +
((__t = ( isActiveTab('document') )) == null ? '' : __t) +
'" data-tab="document">' +
((__t = ( L10N.get('cabinet.finance.exposedDocuments') )) == null ? '' : __t) +
'</a>\n				<a class="b-block-container__element b-block-container__tab-btn ' +
((__t = ( isActiveTab('externalDocuments') )) == null ? '' : __t) +
'" data-tab="externalDocuments">' +
((__t = ( L10N.get('cabinet.finance.externalDocuments') )) == null ? '' : __t) +
'</a>\n			</div>\n		</div>\n		<div class="b-block-container__footer">\n			<div class="l-grid-container">\n				<div class="l-grid-layout-' +
((__t = ( finance.financeTab === 'document' ? '100' : '50')) == null ? '' : __t) +
'">\n					<div class="b-block-container__group">\n                        ';
 if (['document', 'externalDocuments'].includes(finance.financeTab)) { ;
__p += '\n                        <div class="b-block-container__group" style="flex: 1 0;">\n                            <div class="b-block-container__element b-block-container__input">\n							<span class="pos-rel">\n                                <span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "pattern", label: L10N.get("cabinet.menu.search"), placeholder: L10N.get("cabinet.finance.documentSearch"), classes: "b-input__search" })\'></span>\n                            </span>\n                            </div>\n                            <div class="b-block-container__element b-block-container__input">\n							<span class="pos-rel">\n                                <span class="js-widget"  onclick=\'return new Widgets.FinanceSelect({bindingProperty: "document", values: "' + documentTypes + '" })\'></span>\n                            </span>\n                            </div>\n                        </div>\n                        ';
 if (finance.financeTab === 'document') { ;
__p += '\n                        <div class="b-block-container__element b-block-container__input" style="flex: 1 1; min-width: 265px;">\n                            <div class="payed-bill-filter">\n                                <span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "onlyNotPayed", label: L10N.get("cabinet.finance.onlyNotPayed") })\'></span>\n                            </div>\n                        </div>\n                        ';
 } ;
__p += '\n						';
 } else { ;
__p += '\n                        <div class="b-block-container__element b-block-container__input">\n							<span class="pos-rel">\n                                <span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "pattern", label: L10N.get("cabinet.menu.search"), placeholder: L10N.get("cabinet.finance.documentSearch"), classes: "b-input__search"  })\'></span>\n                            </span>\n                        </div>\n						';
 } ;
__p += '\n					</div>\n\n				</div>\n			</div>\n\n			<div class="b-block-container__table"></div>\n			<div class="b-block-container__table-footer"></div>\n		</div>\n	</div>\n</div>\n';

}
return __p
}