module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-hotels-locations__item">\r\n	<span class="b-hotels-locations__item-city">\r\n		';

			var title = _.map(obj.caption.split(','), function (str) {
				return str.trim();
			});
		;
__p += '\r\n		' +
((__t = ( title[0] )) == null ? '' : __t) +
'' +
((__t = ( title[1] ? ", <span>" + title[1] + "</span>" : "" )) == null ? '' : __t) +
'\r\n	</span>\r\n</div>\r\n';

}
return __p
}