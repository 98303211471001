// eslint-disable-next-line banned-modules
'use strict';

import BaseView from '@/classes/base.view';
import Widgets from '@/widgets/widgets';

import template from './template.ejs';
import $ from 'jquery';

export default BaseView.extend({

	template,

	initialize() {
		this.options = _.extend(this.options, {
			showInputValue: this.model.options.showInputValue === true,
		});

		this.render();
		this.addEventListeners();
	},

	addEventListeners() {
		if (this.options.showInputValue === true) {
			this.start = {
				min: this.model.get('min'),
				max: this.model.get('max'),
			};

			const getValue = (_.isFunction(this.options.formatter)) ? this.options.formatter.bind(this) : (value) => value;
			const change = ($element, increment) => {
				const value = getValue($element.val()) + (increment || 1);

				if ($element.parent().hasClass('b-filters__range-min')) {
					this.model.set('__min', value);
				} else if ($element.parent().hasClass('b-filters__range-max')) {
					this.model.set('__max', value);
				}

				$element.val(value);
			};

			this.model.set('__min', this.start.min);
			this.model.set('__max', this.start.max);

			this.listenTo(this.model, 'change:__min change:__max', () => {
				const min = getValue(this.model.get('__min'));
				const max = getValue(this.model.get('__max'));

				this.model.trigger('change:value', this.model, [min, max]);
			});
			this.listenTo(this.model, 'change:value', (model, value) => {
				if (!_.isEmpty(value)) {
					this.model.set('__min', getValue(value[0]));
					this.model.set('__max', getValue(value[1]));
				}
			});

			this.$el.find('.b-input__value').on('change mousedown mouseup', (e) => {
				const $target = $(e.currentTarget);
				const value = getValue($target.val());

				if (value < this.start.min || ($target.parent().hasClass('b-filters__range-min') && _.isNaN(value))) {
					$target.val(this.start.min);
				} else if (value > this.start.max || ($target.parent().hasClass('b-filters__range-max') && _.isNaN(value))) {
					$target.val(this.start.max);
				} else {
					$target.val(value);
				}
			}).on('keydown', (e) => {
				const keyCode = e.keyCode || e.which;
				const arrows = { left: 37, up: 38, right: 39, down: 40 };
				const $target = $(e.currentTarget);
				const value = getValue($target.val());

				switch (keyCode) {
					case arrows.up:
						if (value < this.start.max) {
							change($target, 1);
						}
						break;
					case arrows.down:
						if (value > this.start.min) {
							change($target, -1);
						}
						break;
				}
			})
				.trigger('change');
		}
	},

	render(...args) {
		if (this.model.attributes.formatter) {
			Widgets.Range[this.cid] = this.model.attributes.formatter;
			this.model.set('formatter', this.cid);
		} else {
			this.model.attributes.formatter = null;
		}

		BaseView.prototype.render.apply(this, args);
	},

});
